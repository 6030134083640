import request from '@/utils/request'

// 未读消息 total
export function getUnReadMsgSystemRecordTotal() {
  return request({
    url: '/message/record/unread-total',
    method: 'get'
  })
}

// 全部消息
export function getMsgSystemRecordList() {
  return request({
    url: '/message/record/list',
    method: 'get'
  })
}

// 未读消息
export function getUnReadMsgSystemRecordList() {
  return request({
    url: '/message/record/unread-list',
    method: 'get'
  })
}

// 单条消息设置已读
export function updateMsgSysRecord(id) {
  return request({
    url: `/message/record/${id}`,
    method: 'put'
  })
}

// 单条消息删除
export function deleteMsgSysRecord(id) {
  return request({
    url: `/message/record/${id}`,
    method: 'delete'
  })
}

// 消息 全部已读
export function updateBatchMsgSysRecord() {
  return request({
    url: '/message/record',
    method: 'put'
  })
}

// 已读消息 批量删除
export function deleteBatchReadMsgSysRecord() {
  return request({
    url: '/message/record',
    method: 'delete'
  })
}
