<template>
  <el-input
    v-model="newValue"
    :autosize="{ minRows: 2}"
    :clearable="true"
    size="small"
    type="textarea"
    v-bind="attrs"
    @input="handleChange"
  />
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableInput',
  mixins: [EleEditableMixin]
}
</script>
