export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}
// iOS手机或者浏览器不支持 YYYY-MM-DD 这种类型的日期格式
export function formatDateStr(dateStr, fmt) {
  if (!dateStr) {
    return
  }
  const date = new Date(dateStr.replace(/-/g, '/'))
  return this.formatDate(date, fmt)
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

// 获取最近半年[半年前1号，今天] 日期选择器
export function getRecentHalfYearDate() {
  var dateBefore
  const dateNow = new Date()
  const yearNow = dateNow.getFullYear()
  const monthNow = dateNow.getMonth()
  if (monthNow - 5 >= 0) {
    dateBefore = new Date(yearNow, monthNow - 5, 1)
  } else {
    dateBefore = new Date(yearNow - 1, 6 + monthNow, 1)
  }
  return [formatDate(dateBefore, 'yyyy-MM-dd'), formatDate(dateNow, 'yyyy-MM-dd')]
}
// 获取最近半年月份 月份区间选择器[半年前1号，本月一号]
export function getRecentHalfYearMonth() {
  var dateBefore
  const dateNow = new Date()
  const yearNow = dateNow.getFullYear()
  const monthNow = dateNow.getMonth()
  if (monthNow - 5 >= 0) {
    dateBefore = new Date(yearNow, monthNow - 5, 1)
  } else {
    dateBefore = new Date(yearNow - 1, 6 + monthNow, 1)
  }
  return [formatDate(dateBefore, 'yyyy-MM-dd'), formatDate(new Date(yearNow, monthNow, 1), 'yyyy-MM-dd')]
}

// 获取往后一周的时间，（7天）
export function getRecentHalfYearWeek() {
  const end = new Date()
  const start = new Date()
  end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
  return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')]
}

// 获取最近三个月 日期选择器
export function getRecentThreeMonth() {
  var dateBefore
  const dateNow = new Date()
  const yearNow = dateNow.getFullYear()
  const monthNow = dateNow.getMonth()
  if (monthNow - 2 >= 0) {
    dateBefore = new Date(yearNow, monthNow - 2, 1)
  } else {
    dateBefore = new Date(yearNow - 1, 9 + monthNow, 1)
  }
  return [formatDate(dateBefore, 'yyyy-MM-dd'), formatDate(dateNow, 'yyyy-MM-dd')]
}
