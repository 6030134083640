/**
 * 处理手机号码转成+xxx xxx格式
 */
export function processPhoneNumber(data) {
  if (data) {
    if (typeof (data) === 'string') {
      if (data.trim() === '') {
        return null
      } else if (data.trim().indexOf('+') === -1) {
        // 不是+xx xxxxx这种格式
        if (data.trim().indexOf(' ') !== -1) {
          // 带国家号 加上+
          return '+' + data
        } else {
          // 历史数据不带国家号时
          return data
        }
      } else {
        // 是+xxx xxxx这种格式
        return data
      }
    } else {
      return null
    }
  } else {
    return null
  }
}
