import store from '@/store/index'

const quantityDecimal = store.getters.config.quantityDecimal
const amountDecimal = store.getters.config.amountDecimal
// 处理金额千分位, 保留两位小数
export function processMoney(value) {
  if (value) {
    if (typeof (value) === 'number') {
      // return (value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      return (Math.round(value * 100) / 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    } else {
      // return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      return (Math.round(parseFloat(value) * 100) / 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }
  } else {
    return '0.00'
  }
}
// 处理金额千分位, 保留指定位数（用于处理价格保留位数）
export function processPrice(value, quantity) {
  if (value) {
    if (typeof (value) === 'number') {
      // return (value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      return (Math.round(value * Math.pow(10, quantity)) / Math.pow(10, quantity)).toFixed(quantity).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    } else {
      // return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      return (Math.round(parseFloat(value) * Math.pow(10, quantity)) / Math.pow(10, quantity)).toFixed(quantity).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }
  } else {
    return '0'
  }
}

// 小数转百分比
export function toPercent(number) {
  if (typeof (number) === 'number') {
    let val = Number(number * 100).toFixed(0)
    val += '%'
    return val
  } else {
    return number
  }
}

export function toAmountDecimal(amount) {
  if (typeof (amount) !== 'number') {
    return '0.00'
  }
  return amount.toFixed(amountDecimal)
}

export function toQuantityDecimal(quantity) {
  if (typeof (quantity) !== 'number') {
    return '0.00'
  }
  return quantity.toFixed(quantityDecimal)
}
// 箱规显示通用
export function getProductGaugeQuantity(quantity, boxGaugeValue, productUnit) {
  if (boxGaugeValue && quantity) {
    const boxGaugeQuantity = Math.trunc(quantity / boxGaugeValue)
    if (boxGaugeQuantity > 0) {
      const boxGaugeLeftQuantity = quantity - (boxGaugeQuantity * boxGaugeValue)
      if (boxGaugeLeftQuantity > 0) {
        return boxGaugeQuantity + this.$t('common.boxGaugeQuantity') + this.$t('common.boxGaugeJoin') + boxGaugeLeftQuantity + (productUnit || '')
      } else {
        return boxGaugeQuantity + this.$t('common.boxGaugeQuantity')
      }
    } else {
      return '0' + this.$t('common.boxGaugeQuantity') + this.$t('common.boxGaugeJoin') + quantity + (productUnit || '')
    }
  } else {
    return '--'
  }
}

// 计算产品体积 （=外箱体积+包装体积，没有设置尺寸则不计算）
// 参数：： 数量、箱规、包装尺寸（长宽高，单位mm）、外箱尺寸（长宽高，单位mm）
export function getProductVolume(quantity, boxGaugeValue, packageSizeLength, packageSizeWidth, packageSizeHeight, cartonSizeLength, cartonSizeWidth, cartonSizeHeight) {
  if (quantity) {
    var cartonVolume = 0
    var packageVolume = 0
    var packageQuantity = quantity
    // 先计算外箱体积，没有设置外箱尺寸则不计算外箱体积
    if ((boxGaugeValue && boxGaugeValue > 0) && (cartonSizeLength && cartonSizeLength > 0) && (cartonSizeWidth && cartonSizeWidth > 0) && (cartonSizeHeight && cartonSizeHeight > 0)) {
      const boxGaugeQuantity = Math.trunc(quantity / boxGaugeValue)
      if (boxGaugeQuantity > 0) {
        packageQuantity = quantity - (boxGaugeQuantity * boxGaugeValue)
        cartonVolume = (cartonSizeLength * cartonSizeWidth * cartonSizeHeight) * boxGaugeQuantity
      }
    }
    // 计算了外箱体积则取剩余包装数计算包装体积，没计算外箱体积则全部数量按包装体积计算
    if ((packageSizeLength && packageSizeLength > 0) && (packageSizeWidth && packageSizeWidth > 0) && (packageSizeHeight && packageSizeHeight > 0)) {
      packageVolume = (packageSizeLength * packageSizeWidth * packageSizeHeight) * packageQuantity
    }
    // 立方毫米换算为立方米
    return (cartonVolume + packageVolume) / 1000000000
  } else {
    return 0
  }
}
