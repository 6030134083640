import store from '../index'

const state = {
  routerParams: {
    businessId: '',
    businessCode: '',
    businessType: ''
  }
}

const mutations = {
  SET: (state, businessId) => {
    state.routerParams.businessId = businessId
    state.routerParams.businessType = ''
  },
  SET_BUSINESS_ID_AND_TYPE: (state, data) => {
    state.routerParams.businessId = data.businessId
    state.routerParams.businessCode = data.businessCode
    state.routerParams.businessType = data.businessType
  }
}

const actions = {
  set({ commit }, businessId) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET', '')
        resolve()
        return
      }
      commit('SET', businessId)
    })
  },
  setBusinessIdAndType({ commit }, businessIdAndType) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_BUSINESS_ID_AND_TYPE', '', '')
        resolve()
        return
      }
      commit('SET_BUSINESS_ID_AND_TYPE', businessIdAndType)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
