export function redirectToError() {
  window.location.href = '/error'
}

export function redirectToLogin(query) {
  if (query) {
    window.location.href = '/login?' + query
  } else {
    window.location.href = '/login'
  }
}
