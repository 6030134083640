import store from '../index'
import { getMallLiveConfig } from '@/api/mall/mallLiveConfig'

const state = {
  mallLiveConfig: {
    // 商城直播设置
    liveNo: ''
  }

}

const mutations = {
  SET_CONFIG: (state, mallLiveConfig) => {
    if (mallLiveConfig) {
      // 商城直播设置
      mallLiveConfig.liveNo = mutations.CONVERT_INTS(mallLiveConfig.liveNo)
      state.mallLiveConfig = Object.assign({}, state.mallLiveConfig, mallLiveConfig, false)
    }
  },
  DEFAULT_VALUE(value, defaultValue) {
    if (value) {
      return value
    } else {
      return defaultValue
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value) => {
    return value
  },
  CONVERT_BOOLEAN: (value) => {
    if (value === 'true') {
      return true
    } else {
      return false
    }
  },
  CONVERT_ARRAY: (value, defaultValue) => {
    if (value && value.length) {
      const configVal = value.split(',')
      return configVal
    } else {
      return defaultValue
    }
  }
}

const actions = {

  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getMallLiveConfig().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
