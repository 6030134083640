const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  tenantInfo: state => state.user.tenantInfo,
  loginTenantInfo: state => state.user.loginTenantInfo,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  menus: state => state.user.menus,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  isScreenLock: state => state.user.isLockScreen,
  config: state => state.config.config,
  platformInfo: state => state.platform.platformInfo,
  productConfig: state => state.productConfig.productConfig,
  message: state => state.message.message,
  purchaseConfig: state => state.purchaseConfig.purchaseConfig,
  isLoginPage: state => state.user.isLoginPage,
  salesConfig: state => state.salesConfig.salesConfig,
  pageType: state => state.user.pageType,
  routerParams: state => state.routerParams.routerParams,
  financeConfig: state => state.financeConfig.financeConfig,
  todoListParams: state => state.todoListParams.todoListParams,
  mallCategoryConfig: state => state.mallCategoryConfig.mallCategoryConfig,
  mallRegisterConfig: state => state.mallRegisterConfig.mallRegisterConfig,
  mallAppletConfig: state => state.mallAppletConfig.mallAppletConfig,
  mallPayConfig: state => state.mallPayConfig.mallPayConfig,
  mallBaseConfig: state => state.mallBaseConfig.mallBaseConfig,
  mallDealConfig: state => state.mallDealConfig.mallDealConfig,
  mallSalesAfterConfig: state => state.mallSalesAfterConfig.mallSalesAfterConfig,
  mallConfig: state => state.mallConfig.mallConfig,
  mallLiveConfig: state => state.mallLiveConfig.mallLiveConfig,
  memberConfig: state => state.memberConfig.memberConfig,
  crmConfig: state => state.crmConfig.crmConfig

}
export default getters
