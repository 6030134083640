import request from '@/utils/request'

export function uploadAvatar(data) {
  return request({
    url: '/profile/upload/avatar',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function getFullInfo() {
  return request({
    url: '/profile/fullinfo',
    method: 'get'
  })
}

export function getUserInfo() {
  return request({
    url: '/profile',
    method: 'get'
  })
}

export function updateSex(data) {
  return request({
    url: '/profile/sex',
    method: 'post',
    data
  })
}

export function updateEmail(data) {
  return request({
    url: '/profile/email',
    method: 'post',
    data
  })
}

export function updatePhone(data) {
  return request({
    url: '/profile/phone',
    method: 'post',
    data
  })
}

export function updateMobile(data) {
  return request({
    url: '/profile/mobile',
    method: 'post',
    data
  })
}

export function uploadQyQrcode(data) {
  return request({
    url: '/profile/upload/qy_wx_qrcode',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function removeQyQrcode() {
  return request({
    url: '/profile/remove/qy_wx_qrcode',
    method: 'post'
  })
}

export function changePassword(data) {
  return request({
    url: '/profile/changepassword',
    method: 'post',
    data
  })
}

export function screenUnlock(data) {
  return request({
    url: '/profile/screenunlock',
    method: 'post',
    data
  })
}
