import store from '../index'
import { getFinanceConfigList } from '@/api/finance/common'

const state = {
  financeConfig: {
    inventoryCostType: ''
  }
}

const mutations = {
  SET_CONFIG: (state, financeConfig) => {
    // const defaultInventoryCostType = 1 // 先进先出
    if (financeConfig) {
      // financeConfig.orderAmount = mutations.CONVERT_INT(financeConfig.orderAmount, 0)
      financeConfig.inventoryCostType = mutations.CONVERT_INT(financeConfig.inventoryCostType, '--')
      state.financeConfig = Object.assign({}, state.financeConfig, financeConfig)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getFinanceConfigList().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
