import store from '../index'
import { getSalesConfigList } from '@/api/sales/common'

const state = {
  salesConfig: {
    // fiscalYear: '',
    orderAmount: 0,
    orderReceiptAmount: 0,
    signedOrderQuantity: 0,
    addedCustomerQuantity: 0,
    followupTimes: 0,
    followUpCustomerTimes: 0,
    orderAutoFillAddressEnable: 1,
    deliveryAutoFillAddressEnable: 1,
    salesOrderAuditModel: 1,
    orderCheckStockEnable: 0,
    deliveryCheckStockEnable: 0,
    reconciliationDate: 31, // 对账日
    accountStatementAutoGeneDelayDays: 0, // 自动生成对账单延迟天数
    manualReviewAccountStatement: 1, // 自动生成对账单是否手动复核
    accountStatementAutoConfirmDate: 0 // 对账单自动确认天数
  }
}

const mutations = {
  SET_CONFIG: (state, salesConfig) => {
    if (salesConfig) {
      // salesConfig.fiscalYear = salesConfig.fiscalYear || ''
      salesConfig.orderAmount = mutations.CONVERT_INT(salesConfig.orderAmount, 0)
      salesConfig.orderReceiptAmount = mutations.CONVERT_INT(salesConfig.orderReceiptAmount, 0)
      salesConfig.signedOrderQuantity = mutations.CONVERT_INT(salesConfig.signedOrderQuantity, 0)
      salesConfig.addedCustomerQuantity = mutations.CONVERT_INT(salesConfig.addedCustomerQuantity, 0)
      salesConfig.followupTimes = mutations.CONVERT_INT(salesConfig.followupTimes, 0)
      salesConfig.followUpCustomerTimes = mutations.CONVERT_INT(salesConfig.followUpCustomerTimes, 0)
      salesConfig.orderAutoFillAddressEnable = mutations.CONVERT_INT(salesConfig.orderAutoFillAddressEnable, 1)
      salesConfig.deliveryAutoFillAddressEnable = mutations.CONVERT_INT(salesConfig.deliveryAutoFillAddressEnable, 1)
      salesConfig.salesOrderAuditModel = mutations.CONVERT_INT(salesConfig.salesOrderAuditModel, 1)
      salesConfig.orderCheckStockEnable = mutations.CONVERT_INT(salesConfig.orderCheckStockEnable, 0)
      salesConfig.deliveryCheckStockEnable = mutations.CONVERT_INT(salesConfig.deliveryCheckStockEnable, 0)
      salesConfig.reconciliationDate = mutations.CONVERT_INT(salesConfig.reconciliationDate, 31)
      salesConfig.accountStatementAutoGeneDelayDays = mutations.CONVERT_INT(salesConfig.accountStatementAutoGeneDelayDays, 0)
      salesConfig.manualReviewAccountStatement = mutations.CONVERT_INT(salesConfig.manualReviewAccountStatement, 1)
      salesConfig.accountStatementAutoConfirmDate = mutations.CONVERT_INT(salesConfig.accountStatementAutoConfirmDate, 0)
      state.salesConfig = Object.assign({}, state.salesConfig, salesConfig)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value === 0 || value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getSalesConfigList().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
