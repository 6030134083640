const enumInfo = {
  // 系统模块编码
  SYS_MODULE_CODE: {
    INVOICE_MANAGEMENT_MODULE: { value: 'invoice_management_module' },
    MALL_MODULE: { value: 'mall_module' },
    TENANT_PORTAL_MODULE: { value: 'tenant_portal_module' },
    ENTERPRISE_CONNECT_MODULE: { value: 'enterprise_connect_module' },
    EXPRESS_MODULE: { value: 'express_module' },
    WELFARE_MODULE: { value: 'welfare_module' },
    THIRD_PART_ORDER_MODULE: { value: 'third_part_order_module' }
  },
  SEX: {
    // 性别
    SECRET: {
      value: 0,
      label: 'common.secret'
    },
    MALE: {
      value: 1,
      label: 'common.male'
    },
    FEMALE: {
      value: 2,
      label: 'common.female'
    }
  },
  YES_NO: {
    // 是否
    YES: {
      value: 1,
      label: 'common.yes'
    },
    NO: {
      value: 0,
      label: 'common.no'
    }
  },
  CHECK_STATUS: {
    // 是否校验
    CHECK: {
      value: 1,
      label: 'common.check'
    },
    NOT_CHECK: {
      value: 0,
      label: 'common.notCheck'
    }
  },
  INVENTORY_OR_PURCHASE: {
    // 仓库发货，委外发货
    PURCHASE: {
      value: 1,
      label: 'common.purchaseDelivery'
    },
    INVENTORY: {
      value: 0,
      label: 'common.inventoryDelivery'
    }
  },
  ENABLED_STATUS: {
    // 启用状态
    ENABLED: {
      value: 1,
      label: 'common.enabled'
    },
    DISENABLED: {
      value: 0,
      label: 'common.disabled'
    }
  },
  OPEN_CLOSE: {
    // 启用状态
    CLOSE: {
      value: 0,
      label: 'common.close'
    },
    OPEN: {
      value: 1,
      label: 'common.open'
    }
  },
  INCLUDE_STATUS: {
    // 包含
    INCLUDE: {
      value: 1,
      label: 'common.include'
    },
    EXCLUDE: {
      value: 0,
      label: 'common.exclude'
    }
  },
  LOCK_STATUS: {
    // 锁定状态
    LOCKED: {
      value: 1,
      label: 'common.locked'
    },
    UNLOCKED: {
      value: 0,
      label: 'common.unlocked'
    }
  },
  USER_RANDOM_PASSWORD_STATUS: {
    // 启用状态
    RANDOM: {
      value: 1,
      label: 'common.random'
    },
    FIXED: {
      value: 0,
      label: 'common.fixed'
    }
  },
  DATA_PERMISSION: {
    // 数据权限
    OWN: {
      value: 1,
      label: 'user.ownDataPermission'
    },
    DEPT: {
      value: 2,
      label: 'user.deptDataPermission'
    },
    ALL: {
      value: 3,
      label: 'user.allDataPermission'
    }
  },
  COSTING_METHOD: {
    // 存货计价方式
    MOVING_AVERAGE: {
      value: 0,
      label: 'config.movingAverage'
    },
    FIFO: {
      value: 1,
      label: 'config.fifo'
    }
  },
  SALES_RECONCILIATION: {
    // 销售对账依据
    BY_ORDER: {
      value: 0,
      label: 'config.byOrder'
    },
    BY_INVOICE: {
      value: 1,
      label: 'config.byInvoice'
    }
  },
  PURCHASE_RECONCILIATION: {
    // 采购对账依据
    BY_ORDER: {
      value: 0,
      label: 'config.byOrder'
    },
    BY_RECEIPT: {
      value: 1,
      label: 'config.byReceipt'
    }
  },
  ENUM_CODE_RECONCILIATION: {
    BY_SYSTEM: {
      value: 0,
      label: 'config.bySystem'
    },
    BY_USER: {
      value: 1,
      label: 'config.byUser'
    }
  },
  CUSTOMER_SETTLEMENT_TYPE: {
    // 销售对账依据
    CURRENT: {
      value: 1,
      label: 'crm.customer.currentSettlement'
    },
    MONTH: {
      value: 2,
      label: 'crm.customer.monthSettlement'
    }
  },
  RECEIVABLES_CREATE_RULE: {
    // 应收款生成规则
    MANUAL: {
      value: 0,
      label: 'config.manual'
    },
    // 订单审核自动生成
    ORDER_VERIFY_AUTO: {
      value: 1,
      label: 'config.salesOrderVerifyAutomatic'
    }
    // ORDER_COMPLETE_AUTO: {
    //   value: 4,
    //   label: 'config.salesOrderCompleteAutomatic'
    // },
    // DELIVERY_VERIFY_AUTO: {
    //   value: 5,
    //   label: 'config.salesDeliveryVerifyAutomatic'
    // }
    // DELIVERY_COMPLETE_AUTO: {
    //   value: 2,
    //   label: 'config.salesDeliveryCompleteAutomatic'
    // },
    // COLLECTION_PLAN_AUTO: {
    //   value: 3,
    //   label: 'config.collectionPlanAutomatic'
    // }
  },
  PAYABLE_CREATE_RULE: {
    // 应付款生成规则
    MANUAL: {
      value: 0,
      label: 'config.manual'
    },
    ORDER_VERIFY_AUTO: {
      value: 1,
      label: 'config.purchaseOrderVerifyAutomatic'
    }
  },
  OUT_PAYABLE_CREATE_RULE: {
    // 委外订单应付款生成规则
    MANUAL: {
      value: 0,
      label: 'config.manual'
    },
    OUT_ORDER_VERIFY_AUTO: {
      value: 1,
      label: 'config.purchaseOutOrderVerifyAutomatic'
    }
  },
  INVOICE_TYPE: {
    // 发票类型
    VAT_SPECIAL: {
      label: 'crm.customer.customerBilling.vatSpecial',
      value: 1
    },
    VAT_GENERAL: {
      label: 'crm.customer.customerBilling.vatGeneral',
      value: 2
    },
    OTHER: {
      label: 'crm.customer.customerBilling.other',
      value: 3
    }
  },
  CONTACT_SEX: {
    // 联系人性别
    MALE: {
      value: 1,
      label: 'common.male'
    },
    FEMALE: {
      value: 2,
      label: 'common.female'
    }
  },
  PRODUCT_SOURCE: {
    SELF_PRODUCE: {
      value: 1,
      label: 'product.productSources.selfProduce'
    },
    OUT_SOURCING: {
      value: 0,
      label: 'product.productSources.outsourcing'
    },
    OUT_PRODUCE: {
      value: 2,
      label: 'product.productSources.outProduce'
    }
  },
  PRODUCT_PROPERTY: {
    SALE_PRODUCT: {
      value: 1,
      label: 'product.property.saleProduct'
    },
    PURCHASE_PRODUCT: {
      value: 2,
      label: 'product.property.purchaseProduct'
    }
  },
  PRODUCT_PROPERTY_ALL: {
    ALL_PRODUCT: {
      value: 0,
      label: 'product.property.allProduct'
    },
    SALE_PRODUCT: {
      value: 1,
      label: 'product.property.saleProduct'
    },
    PURCHASE_PRODUCT: {
      value: 2,
      label: 'product.property.purchaseProduct'
    }
  },
  PRODUCT_CODE_TYPE: {
    SYSTEM_PRODUCTION: {
      value: 0,
      label: 'product.productCodeTypes.systemProduction'
    },
    SELF_WRITING: {
      value: 1,
      label: 'product.productCodeTypes.selfWriting'
    }
  },
  CUSTOMER_CHOISE_RANGE: {
    // 客户选择范围
    ONLY_OWN: {
      value: 1,
      label: '客户经理是自己'
    },
    DATA_PERMISSION: {
      value: 2,
      label: '数据权限范围'
    },
    ALL: {
      value: 3,
      label: '所有客户'
    },
    COMMON: {
      value: 4,
      label: '公共客户'
    }
  },
  PURCHASE_PLAN_STATUS: {
    // 采购计划状态
    DRAFT: { label: 'purchase.plan.draft', value: 0 },
    UNCONFIRM: { label: 'purchase.plan.unconfirm', value: 1 },
    CONFIRMED: { label: 'purchase.plan.confirmed', value: 2 }
  },
  // 请购单状态
  PURCHASE_REQUISITION_STATUS: {
    DRAFT: { label: 'purchase.status.draft', value: 0 },
    UN_AUDIT: { label: 'purchase.status.unAudit', value: 1 },
    AUDIT: { label: 'purchase.status.audit', value: 2 }
  },
  // 请购单下单状态
  REQUISITION_ORDER_STATUS: {
    NO_ORDER: { label: 'purchase.requisition.notOrdered', value: 1 },
    PARTIAL_ORDER: { label: 'purchase.requisition.partialOrdered', value: 2 },
    ORDER_PLACED: { label: 'purchase.requisition.fullOrdered', value: 3 }
  },
  PURCHASE_ORDER_STATUS: {
    // 采购订单状态
    // ALL: { label: 'common.all', value: 100 },
    DRAFT: { label: 'purchase.order.draft', value: 0 },
    NOAUDIT: { label: 'purchase.order.unaudit', value: 1 },
    AUDITED: { label: 'purchase.order.audited', value: 2 },
    FINISH: { label: 'purchase.order.finishStatus', value: 3 },
    END: { label: 'purchase.order.endStatus', value: 4 },
    INVALID: { label: 'purchase.order.invalidStatus', value: 5 },
    ALTER: { label: 'purchase.order.alter', value: 6 }
  },
  // 查询日期类型
  PURCHASE_QUERY_DATE_TYPE: {
    ORDER_DATE: { label: 'purchase.order.orderDate', value: 1 },
    DELIVERY_DATE: { label: 'purchase.order.deliveryDate', value: 2 },
    INVENTORY_IN_DATE: { label: 'purchase.order.inventoryInDate', value: 3 }
  },
  // 委外发货单
  PURCHASE_OUT_ORDER_STATUS: {
    DRAFT: { label: 'purchase.order.draft', value: 0 },
    NOAUDIT: { label: 'purchase.order.unaudit', value: 1 },
    AUDITED: { label: 'purchase.order.audited', value: 2 },
    DELIVERY: { label: 'purchase.order.delivered', value: 6 },
    FINISH: { label: 'purchase.order.finishStatus', value: 3 },
    ALTER: { label: 'purchase.order.alter', value: 7 }
    // END: { label: 'purchase.order.endStatus', value: 4 }
    // INVALID: { label: 'purchase.order.invalidStatus', value: 5 },
  },
  PURCHASE_ORDER_BUSINESS_TYPE: {
    // 采购订单子表业务类型
    REQUISITION: { label: 'common.requisition', value: 1 }
  },
  // 委外订单业务类型
  PURCHASE_OUT_ORDER_BUSINESS_TYPE: {
    PURCHASE_OUT_REQUIRE: { label: 'common.purchaseOutRequire', value: 1 },
    PURCHASE_PLAN: { label: 'route.purchasePlan', value: 2 }
  },
  PURCHASE_RETURN_STATUS: {
    // 采购退货单
    // ALL: { label: 'common.all', value: 0 },
    DRAFT: { label: 'purchase.return.draft', value: 1 },
    UNVERIFY: { label: 'purchase.return.pending', value: 2 },
    VERIFY: { label: 'purchase.order.audited', value: 3 },
    DONE: { label: 'purchase.return.finish', value: 4 },
    INVENTORY_OUT: { label: 'purchase.return.inventoryOut', value: 5 },
    INVENTORY_IN: { label: 'purchase.return.inventoryIn', value: 6 },
    TO_BE_COLLECTION: { label: 'purchase.return.toBeCollection', value: 7 },
    FINANCE_COLLECTION: { label: 'purchase.return.financeCollection', value: 8 }
  },
  INVENTORY_BUSSINESS_TYPE: {
    // 出入库类型
    OUT: { label: 'inventory.out.inventoryTitle', value: 0 },
    IN: { label: 'inventory.in.inventoryTitle', value: 1 }
  },
  IN_INVENTORY_STATUS: {
    // 入库状态
    DRAFT: { label: 'inventory.in.draft', value: 10 },
    PENDING: { label: 'inventory.in.pending', value: 11 },
    FINISHED: { label: 'inventory.in.finished', value: 12 }
  },
  IN_INVENTORY_TYPE: {
    // 入库类型
    INITIAL: { label: 'inventory.in.initial', value: 100 },
    PURCHASE_RECEIPT: { label: 'inventory.in.purchaseReceipt', value: 101 },
    SALES_RETURN_IN: { label: 'inventory.in.salesReturn', value: 102 },
    // ALLOCATE_CALL_IN: { label: 'inventory.in.allocateCallIn', value: 103 },
    // INVENTORY_PROFIT: { label: 'inventory.in.inventoryProfit', value: 104 },
    FINISHED_PRODUCT: { label: 'inventory.in.finishedProduct', value: 105 },
    MATERIALS_RETURN: { label: 'inventory.in.materialsReturn', value: 106 },
    OUTSOURCING_ORDER_IN: { label: 'inventory.in.outsourcingOrderIn', value: 107 },
    // UNPACK_IN: { label: 'inventory.in.unpackIn', value: 108 },
    // PACK_IN: { label: 'inventory.in.packIn', value: 109 },
    REPORT_OVERFLOW: { label: 'inventory.in.reportOverflow', value: 110 },
    MALL_RETURN_IN: { label: 'inventory.business.mallReturnIn', value: 111 },
    PURCHASE_RETURN_IN: { label: 'inventory.out.purchaseReturn', value: 112 },
    INVENTORY_SECONDMENT_IN: { label: 'inventory.business.inventorySecondmentIn', value: 113 },
    PURCHASE_OUT_RETURN_IN: { label: 'route.purchaseOutReturn', value: 114 },
    OTHER: { label: 'inventory.in.other', value: 199 }
  },
  OUT_INVENTORY_TYPE: {
    // 出库类型
    SALE_DELIVERY: { label: 'inventory.out.saleDelivery', value: 300 },
    SALES_RETURN_OUT: { label: 'inventory.in.salesReturn', value: 320 },
    PURCHASE_RETURN_OUT: { label: 'inventory.out.purchaseReturn', value: 301 },
    // ALLOCATE_CALL_OUT: { label: 'inventory.out.allocateCallOut', value: 302 },
    // INVENTORY_LOSS: { label: 'inventory.out.inventoryLoss', value: 303 },
    WORKSHOP_PICK: { label: 'inventory.out.workshopPick', value: 304 },
    OUTSOURCING_ORDER_OUT: { label: 'inventory.out.materialsRequest', value: 305 },
    // UNPACK_OUT: { label: 'inventory.out.unpackOut', value: 306 },
    // PACK_OUT: { label: 'inventory.out.packOut', value: 307 },
    MALL_SALES_DELIVERY: { label: 'inventory.in.mallDelivery', value: 308 },
    REPORT_LOSS: { label: 'inventory.out.reportLoss', value: 309 },
    MALL_RETURN_OUT: { label: 'inventory.business.mallReturnOut', value: 310 },
    DISTRIBUTION_DELIVERY: { label: 'inventory.business.distributionDelivery', value: 311 },
    INVENTORY_SECONDMENT_OUT: { label: 'inventory.business.inventorySecondmentOut', value: 312 },
    EXCHANGE_CARD_ORDER_OUT: { label: 'inventory.business.exchangeCardOrderOut', value: 313 },
    EXCHANGE_VOUCHER_ORDER_OUT: { label: 'inventory.business.exchangeVoucherOrderOut', value: 314 },
    STORE_ORDER_OUT: { label: 'store.order.storeOrderInventoryOut', value: 315 },
    OTHER: { label: 'inventory.out.other', value: 399 }
  },
  INVENTORY_BUSINESS_TYPE: {
    SALES_ORDER: { label: 'inventory.business.salesOrder', value: 10 },
    DELIVERY_ORDER: { label: 'inventory.business.salesDelivery', value: 11 },
    SALES_RETURN: { label: 'inventory.in.salesReturn', value: 12 },
    PURCHASE_ORDER: { label: 'inventory.business.purchaseOrder', value: 13 },
    PURCHASE_RETURN: { label: 'inventory.business.purchaseReturn', value: 14 },
    ALLOCATE_ORDER: { label: 'inventory.business.allocate', value: 15 },
    CHECK_ORDER: { label: 'inventory.business.check', value: 16 },
    PACK_ORDER: { label: 'inventory.business.pack', value: 17 },
    OUTSOURCE_ORDER: { label: 'inventory.business.outsourceOrder', value: 18 },
    MALL_DELIVERY: { label: 'inventory.in.mallDelivery', value: 19 },
    MALL_RETURN: { label: 'inventory.business.mallReturn', value: 20 },
    DISTRIBUTION_DELIVERY: { label: 'inventory.business.distributionDelivery', value: 21 },
    INVENTORY_SECONDMENT: { label: 'route.inventorySecondment', value: 22 },
    PURCHASE_OUT_RETURN: { label: 'route.purchaseOutReturn', value: 23 },
    EXCHANGE_ORDER: { label: 'route.exchangeOrder', value: 24 },
    STORE_ORDER: { label: 'store.order.storeOrder', value: 25 }
  },
  ALL_INVENTORY_TYPE: {
    // 入库类型
    INITIAL: { label: 'inventory.in.initial', value: 100 },
    PURCHASE_RECEIPT: { label: 'inventory.in.purchaseReceipt', value: 101 },
    SALES_RETURN_IN: { label: 'inventory.in.salesReturn', value: 102 },
    ALLOCATE_CALL_IN: { label: 'inventory.in.allocateCallIn', value: 103 },
    INVENTORY_PROFIT: { label: 'inventory.in.inventoryProfit', value: 104 },
    FINISHED_PRODUCT: { label: 'inventory.in.finishedProduct', value: 105 },
    MATERIALS_RETURN: { label: 'inventory.in.materialsReturn', value: 106 },
    OUTSOURCING_ORDER_IN: { label: 'inventory.in.outsourcingOrderIn', value: 107 },
    UNPACK_IN: { label: 'inventory.in.unpackIn', value: 108 },
    PACK_IN: { label: 'inventory.in.packIn', value: 109 },
    REPORT_OVERFLOW: { label: 'inventory.in.reportOverflow', value: 110 },
    MALL_RETURN_IN: { label: 'inventory.business.mallReturnIn', value: 111 },
    PURCHASE_RETURN_IN: { label: 'inventory.out.purchaseReturn', value: 112 },
    INVENTORY_SECONDMENT_IN: { label: 'inventory.business.inventorySecondmentIn', value: 113 },
    PURCHASE_OUT_RETURN_IN: { label: 'route.purchaseOutReturn', value: 114 },
    IN_OTHER: { label: 'inventory.in.other', value: 199 },
    // 出库类型
    SALE_DELIVERY: { label: 'inventory.out.saleDelivery', value: 300 },
    PURCHASE_RETURN_OUT: { label: 'inventory.out.purchaseReturn', value: 301 },
    SALES_RETURN_OUT: { label: 'inventory.in.salesReturn', value: 320 },
    ALLOCATE_CALL_OUT: { label: 'inventory.out.allocateCallOut', value: 302 },
    INVENTORY_LOSS: { label: 'inventory.out.inventoryLoss', value: 303 },
    WORKSHOP_PICK: { label: 'inventory.out.workshopPick', value: 304 },
    OUTSOURCING_ORDER_OUT: { label: 'inventory.out.materialsRequest', value: 305 },
    PACK_OUT: { label: 'inventory.out.packOut', value: 306 },
    UNPACK_OUT: { label: 'inventory.out.unpackOut', value: 307 },
    MALL_SALES_DELIVERY: { label: 'inventory.in.mallDelivery', value: 308 },
    REPORT_LOSS: { label: 'inventory.out.reportLoss', value: 309 },
    MALL_RETURN_OUT: { label: 'inventory.business.mallReturnOut', value: 310 },
    DISTRIBUTION_DELIVERY: { label: 'inventory.business.distributionDelivery', value: 311 },
    INVENTORY_SECONDMENT_OUT: { label: 'inventory.business.inventorySecondmentOut', value: 312 },
    EXCHANGE_CARD_ORDER_OUT: { label: 'inventory.business.exchangeCardOrderOut', value: 313 },
    EXCHANGE_VOUCHER_ORDER_OUT: { label: 'inventory.business.exchangeVoucherOrderOut', value: 314 },
    STORE_ORDER_OUT: { label: 'store.order.storeOrderInventoryOut', value: 315 },
    OTHER: { label: 'inventory.out.other', value: 399 }
  },
  OUT_INVENTORY_STATUS: {
    // 出库状态
    DRAFT: { label: 'inventory.out.draft', value: 30 },
    PENDING: { label: 'inventory.out.pending', value: 31 },
    FINISHED: { label: 'inventory.out.finished', value: 32 }
  },
  INVENTORY_ALLOCATE_STATUS: {
    // 调拨状态
    DRAFT: { label: 'inventory.allocate.draft', value: 0 },
    UNAUDITED: { label: 'inventory.allocate.unaudit', value: 1 },
    AUDITED: { label: 'inventory.allocate.audited', value: 2 },
    CALL_OUT: { label: 'inventory.allocate.callOut', value: 3 },
    CALL_IN: { label: 'inventory.allocate.callIn', value: 4 }
  },
  INVENTORY_CHECK_STATUS: {
    // 盘点状态
    DRAFT: { label: 'inventory.check.draft', value: 0 },
    FINISHED: { label: 'inventory.check.finished', value: 2 },
    INVALID: { label: 'inventory.check.invalided', value: 3 }
  },
  // 财务收付款状态
  FINANCE_STUTAS: {
    DRAFT_STUTAS: { label: 'finance.financeStutas.draftStutas', value: 0 },
    TO_BE_CONFIRMED: { label: 'finance.financeStutas.toBeConfirmed', value: 1 },
    CONFIRMED: { label: 'finance.financeStutas.confirmed', value: 2 },
    DESERTED: { label: 'finance.financeStutas.deserted', value: 3 },
    ALL: { label: 'finance.financeStutas.all', value: 4 }
  },
  // 财务应收款状态
  FINANCE_RECEIVABLE_PAY_STUTAS: {
    UNCOLLECTED: { label: 'finance.financeReceivable.status.unCollected', value: 0 },
    RECEIVING_MONEY: { label: 'finance.financeReceivable.status.receivingMoney', value: 1 },
    COMPLETED: { label: 'finance.financeReceivable.status.completed', value: 2 },
    ALL: { label: 'finance.financeStutas.all', value: 3 },
    CANCELED: { label: 'common.canceled', value: 4 }
  },
  // 财务应付款状态
  FINANCE_PAYABLE_STUTAS: {
    PAYNOT: { label: 'finance.financePayable.status.payNot', value: 0 },
    PAYING: { label: 'finance.financePayable.status.paying', value: 1 },
    PAYFINISH: { label: 'finance.financePayable.status.payFinish', value: 2 },
    ALL: { label: 'finance.financePayable.status.all', value: 3 }
  },
  // 财务收付款状态
  FINANCE_SURETY_STUTAS: {
    TO_BE_CONFIRMED: { label: 'finance.financeStutas.toBeConfirmed', value: 0 },
    CONFIRMED: { label: 'finance.financeStutas.confirmed', value: 1 }
  },
  // 财务应付款业务类型
  FINANCE_PAYABLE_BUSINESSTYPE: {
    PURCHASE_ORDER: { label: 'finance.financePayable.businessType.purchaseOrder', value: 0 },
    PURCHASE_OUT_ORDER: { label: 'finance.financePayable.businessType.purchaseOutOrder', value: 10 },
    PURCHASE_RECEIPT: { label: 'finance.financePayable.businessType.purchaseReceipt', value: 2 },
    // PURCHASE_RETURN: { label: 'finance.financePayable.businessType.purchaseReturn', value: 3 },
    SALE_RETURN: { label: 'finance.financePayable.businessType.saleReturn', value: 4 },
    // PAY_REQUEST: { label: 'finance.financePayable.businessType.payRequest', value: 5 },
    // REIMBURSEMENT: { label: 'finance.financePayable.businessType.reimbursement', value: 6 },
    // SALE_ORDER: { label: 'finance.financePayable.businessType.saleOrder', value: 7 },
    // SALE_DELIVERY: { label: 'finance.financePayable.businessType.saleDelivery', value: 8 },
    MALL_REFUND_ORDER: { label: 'finance.financePayable.businessType.mallRefundOrder', value: 9 },
    COMMISSION_EXTRACT: { label: 'finance.financePayable.businessType.commissionExtract', value: 11 },
    CUSTOMER_REBATE_EXTRACT: { label: 'finance.financePayable.businessType.rebateExtract', value: 12 }
  },
  // 财务应收款业务类型
  FINANCE_RECEIVABLE_BUSINESSTYPE: {
    // ADD_MANULLY: { label: 'finance.financeReceivableInfo.businessTypeEeum.addManully', value: 0 },
    SALE_ORDER: { label: 'finance.financeReceivableInfo.businessTypeEeum.saleOrder', value: 1 },
    SALE_DELIVERY: { label: 'finance.financeReceivableInfo.businessTypeEeum.saleDelivery', value: 2 },
    // SALE_PLAN: { label: 'finance.financeReceivableInfo.businessTypeEeum.salePlan', value: 3 },
    MALL_ORDER: { label: 'finance.financeReceivableInfo.businessTypeEeum.mallOrderCollection', value: 4 },
    SALES_INVENTORY_LOCK: { label: 'finance.financeReceivableInfo.businessTypeEeum.salesInventoryLock', value: 5 },
    PURCHASE_RETURN: { label: 'finance.financeReceivableInfo.businessTypeEeum.purchaseReturn', value: 6 },
    MALL_ORDER_PAY_MONTH: { label: 'finance.businessType.mallOrderMonthPay', value: 7 },
    DISTRIBUTION_ORDER: { label: 'finance.businessType.distributionOrder', value: 8 },
    PURCHASE_OUT_RETURN: { label: 'finance.businessType.purchaseOutReturn', value: 9 },
    MALL_ORDER_FREIGHT: { label: 'finance.businessType.mallOrderFreight', value: 10 },
    STORE_ORDER: { label: 'store.order.storeOrder', value: 11 },
    STORE_ORDER_MONTH_PAY: { label: 'store.order.storeMonthPayOrder', value: 12 }
  },
  // 财务往来单位类型
  BUSINESS_UNIT_TYPE: {
    USER: { label: 'finance.financePayable.businessCompanyType.user', value: 0 },
    CUSTOMER: { label: 'finance.financePayable.businessCompanyType.customer', value: 1 },
    SUPPLIER: { label: 'finance.financePayable.businessCompanyType.supplier', value: 2 },
    MEMBER: { label: 'finance.financePayable.businessCompanyType.member', value: 4 },
    OTHER: { label: 'common.other', value: 3 }
  },
  // 财务收付款分摊规则
  FINANCE_APPORTIONMENT_RULE: {
    DATE_NEAR_TO_FAR: { label: 'finance.financeApportionmentRule.dateNearToFar', value: 0 },
    DATE_FAR_TO_NEAR: { label: 'finance.financeApportionmentRule.dateFarToNear', value: 1 },
    AMOUNT_BIG_TO_SMALL: { label: 'finance.financeApportionmentRule.amountBigToSmall', value: 2 },
    AMOUNT_SAMLL_TO_BIG: { label: 'finance.financeApportionmentRule.amountSmallToBig', value: 3 }
  },
  FiNANCE_INVOICE_APPLICATION_STATUS: {
    // 开票申请状态
    DRAFT: { label: 'finance.invoiceApplication.status.draft', value: 0 },
    UN_AUDIT: { label: 'finance.invoiceApplication.status.unAudit', value: 1 },
    AUDIT: { label: 'finance.invoiceApplication.status.audit', value: 2 },
    INVOICED: { label: 'finance.invoiceApplication.status.invoiced', value: 3 },
    DELIVERED: { label: 'finance.invoiceApplication.status.delivered', value: 4 },
    SIGNED: { label: 'finance.invoiceApplication.status.signed', value: 5 }
  },
  FINANCE_INVOICE_CHECK_STATUS: {
    NORMAL: { label: 'finance.invoiceInfo.normal', value: 1 },
    UN_NORMAL: { label: 'finance.invoiceInfo.error', value: 0 },
    UN_CHECKED: { label: 'finance.invoiceInfo.unCheck', value: 2 }
  },
  FINANCE_INVOICE_RECORD_TYPE: {
    MAKE_INVOICE: { label: 'finance.invoiceRecord.makeInvoice', value: 0 },
    RECEIPT_INVOICE: { label: 'finance.invoiceRecord.receiptInvoice', value: 1 }
  },
  // 发票类型
  FINANCE_INVOICE_TYPE: {
    VAT_INVOICE: { label: 'finance.invoiceType.vatInovice', value: 1 },
    VAT_INVOICE_SPECIAL: { label: 'finance.invoiceType.vatInvoiceSpecial', value: 2 },
    VAT_INVOICE_ELECTONIC: { label: 'finance.invoiceType.vatInvoiceElectonic', value: 3 },
    VAT_INVOICE_VOLUME: { label: 'finance.invoiceType.vatInvoiceVolumn', value: 4 },
    VAT_INVOICE_SPECIAL_ELECTRONIC: { label: 'finance.invoiceType.vatInvoiceSpecialElectonic', value: 5 },
    MOTOR_SALES_INVOICE: { label: 'finance.invoiceType.motorSalesInvoice', value: 6 },
    TRAIN_TICKET: { label: 'finance.invoiceType.trainTicket', value: 7 },
    PASSENGER_TICKET: { label: 'finance.invoiceType.passenengerTicket', value: 8 },
    AIR_PASSENGER_TICKET: { label: 'finance.invoiceType.airPassengerTicket', value: 9 },
    ROAD_TOLL: { label: 'finance.invoiceType.roadToll', value: 10 },
    WITHHOLDING_TAX_INVOICE: { label: 'finance.invoiceType.withholdingTaxInvoice', value: 11 },
    IMPORT_TAX_INVOICE: { label: 'finance.invoiceType.importTaxInvoice', value: 12 },
    AGRICULTURAL_PUCHASE_INVOICE: { label: 'finance.invoiceType.agricultralPurchaseInvoice', value: 13 },
    VAT_INVOICE_SPECIAL_TRANSPORTATION: { label: 'finance.invoiceType.vatInvoiceSpecialTransportation', value: 14 },
    OTHER: { label: 'finance.invoiceType.other', value: 15 }
  },
  SALES_AUDIT_CONFIG_MODEL: {
    AUDIT_MODEL: { label: 'sales.common.aditModel', value: 1 },
    FREE_MODEL: { label: 'sales.common.freeModel', value: 2 },
    WORK_FLOW_MODEL: { label: 'sales.common.workFlowModel', value: 3 }
  },
  SALES_AUDIT_CONFIG_TRIGGER_CONDITION_STATUS: {
    NONE_SATISFIED: { label: 'sales.config.noneSatisfied', value: 1 },
    ALL_SATISFIED: { label: 'sales.config.allSatisfied', value: 2 },
    ONE_SATISFIED: { label: 'sales.config.oneSatisfied', value: 3 }
  },
  SALES_AUDIT_CONFIG_BUSINESS_TYPE: {
    SALES_QUOTATION: { label: '', value: 1 },
    SALES_ORDER: { label: '', value: 2 },
    SALES_DELIVERY: { label: '', value: 3 },
    SALES_RETURN: { label: '', value: 4 }
  },
  TODO_LIST_PROCESS_STATUS: {
    NOT_HANDLED: { label: 'todoList.notHandled', value: false },
    HANDLED: { label: 'todoList.handled', value: true }
  },
  TODO_LIST_BUSINESS_TYPE: {
    SALES_ORDER_AUDIT: { label: 'todoList.salesOrderAudit', value: 1 }
  },
  SALES_QUOTATION_STATUS: {
    // 销售报价单状态
    // ALL: { label: 'common.all', value: 0 },
    DRAFT: { label: 'sales.common.draft', value: 1 },
    UNVERIFY: { label: 'sales.common.unVerify', value: 2 },
    VERIFY: { label: 'sales.common.verify', value: 3 },
    QUOTED: { label: 'sales.common.quoted', value: 4 },
    CLOSE_REJECTED: { label: 'sales.common.closedRejected', value: 5 },
    CLOSE_REQUOTE: { label: 'sales.common.closedRequote', value: 6 },
    CLOSE_TO_ORDER: { label: 'sales.common.closedToOrder', value: 7 }
  },
  SALES_ORDER_STATUS: {
    // 销售订单状态
    // ALL: { label: 'common.all', value: 0 },
    DRAFT: { label: 'sales.common.draft', value: 1 },
    UNVERIFY: { label: 'sales.common.unVerify', value: 2 },
    VERIFY: { label: 'sales.common.verify', value: 3 },
    ADJUSTING: { label: 'sales.common.adjusting', value: 8 },
    REJECT: { label: 'sales.common.reject', value: 7 },
    CLOSE_DONE: { label: 'sales.common.closeDone', value: 4 },
    CLOSE_STOP: { label: 'sales.common.closeStop', value: 5 },
    CLOSE_CANCEL: { label: 'sales.common.canceled', value: 6 }
  },
  // 销售订单业务类型
  SALES_ORDER_BUSINESS_TYPE: {
    SALES_QUOTATION: { label: 'route.salesQuotation', value: 1 },
    SALES_INVENTORY_LOCK: { label: 'route.salesInventoryLock', value: 2 },
    SALES_OPPORTUNITY: { label: 'route.salesOpportunity', value: 3 },
    ENTERPRISE_ORDER: { label: 'route.enterpriseOrder', value: 4 }
  },
  SALES_ORDER_B2C_STATUS: {
    // 销售订单B2C状态
    DRAFT: { label: 'sales.common.draft', value: 1 },
    UNAUDITED: { label: 'sales.common.unVerify', value: 2 },
    AUDITED: { label: 'sales.common.verify', value: 3 },
    CANCELED: { label: 'sales.common.canceled', value: 4 }
  },
  SALES_DELIVERY_STATUS: {
    // 销售发货单状态
    // ALL: { label: 'common.all', value: 0 },
    DRAFT: { label: 'sales.common.draft', value: 1 },
    UNVERIFY: { label: 'sales.common.unVerify', value: 2 },
    VERIFY: { label: 'sales.common.verify', value: 3 },
    ADJUSTING: { label: 'sales.common.adjusting', value: 6 },
    DONE: { label: 'sales.common.done', value: 4 },
    CLOSE_STOP: { label: 'sales.common.closeStop', value: 5 },
    CANCELED: { label: 'sales.common.canceled', value: 7 }
  },
  SALES_DELIVERY_BUSINESS_TYPE: {
    // 发货单子表业务类型
    SALES_ORDER: { label: 'sales.common.salesOrder', value: 1 }
  },
  SALES_RETURN_ORDER_STATUS: {
    // 销售退货单状态
    // ALL: { label: 'common.all', value: 0 },
    DRAFT: { label: 'sales.common.draft', value: 1 },
    UNVERIFY: { label: 'sales.common.unVerify', value: 2 },
    VERIFY: { label: 'sales.common.verify', value: 3 },
    INVENTORY_IN: { label: 'sales.common.inventoryIn', value: 5 },
    INVENTORY_OUT: { label: 'sales.common.inventoryOut', value: 6 },
    TO_BE_REFUNDED: { label: 'sales.common.toBeRefunded', value: 7 },
    REFUNDED: { label: 'sales.common.refunded', value: 8 },
    DONE: { label: 'sales.common.done', value: 4 }
  },
  EXPENSE_PAYMENT_STATUS: {
    // 销售/采购费用支付状态
    // ALL: { label: 'common.all', value: 0 },
    UNPAID: { label: 'common.unpaid', value: 1 },
    PAID: { label: 'common.paid', value: 2 }
  },
  SALES_EXPENSE_BUSINESS_TYPE: {
    // 销售费用关联业务类型
    SALES_ORDER: { label: 'sales.common.salesOrder', value: 1 },
    SALES_DELIVERY: { label: 'sales.common.salesDelivery', value: 2 },
    SALES_RETURN: { label: 'sales.common.salesReturn', value: 3 }
  },
  PURCHASE_EXPENSE_BUSINESS_TYPE: {
    // 采购费用关联业务类型
    PURCHASE_ORDER: { label: 'purchase.common.purchaseOrder', value: 1 },
    PURCHASE_RECEIPT: { label: 'purchase.common.purchaseReceipt', value: 2 },
    PURCHASE_RETURN: { label: 'purchase.common.purchaseReturn', value: 3 },
    OUTSOURCING_ORDER: { label: 'purchase.common.outsourcingOrder', value: 4 }
  },
  PURCHASE_OUTSOURCING_STATUS: {
    // 采购委外订单状态
    DRAFT: { label: 'purchase.status.draft', value: 0 },
    UNAUDIT: { label: 'purchase.status.unAudit', value: 1 },
    AUDIT: { label: 'purchase.status.audit', value: 2 },
    CANCELED: { label: 'purchase.status.canceled', value: 3 }
  },
  VAT_INVOICE_TYPE: {
    // 增值税发票类型 1：专用发票 2：货运发票 3：机动车发票 4：普通发票 10：电子发票 11：卷式发票 14：通行费发票 15：二手车发票
    SPECIAL_INVOICE: { label: '专用发票', value: 1 },
    FREIGHT_INVOICE: { label: '货运发票', value: 2 },
    MOTOR_VEHICLE_INVOICE: { label: '机动车发票', value: 3 },
    ODINARY_INVOICE: { label: '普通发票', value: 4 },
    ELECTRONIC_INVOICE: { label: '电子发票', value: 10 },
    ROLL_INVOICE: { label: '卷式发票', value: 11 },
    TOLL_INVOICE: { label: '通行费发票', value: 14 },
    SECOND_HAND_CAR_INVOICE: { label: '二手车发票', value: 15 }
  },
  VAT_INVOINCE_INSPECTION: {
    // 增值税发票查验规则
    MANUANL_INSPECTION: { label: '手动查验', value: 0 }
    // AUTOMATIC_CHECK: { label: '自动查验', value: 1 }
  },
  VAT_INVOICE_CHECK_STATUS: {
    NORMAL: { label: '正常', value: 1 },
    UN_NORMAL: { label: '异常', value: 0 },
    UN_CHECKED: { label: '未查验', value: 2 }
  },
  VEHICLE_SOURCE: {
    // 车辆种类
    MAINLAND: { label: '大陆车辆', value: 1, disabled: false },
    HONGKONG: { label: '香港车辆', value: 2, disabled: false }
  },
  MAINLAND_EXIT: {
    // 大陆出境
    HONGKONG: { label: '出入香港', value: 2, disabled: false }
  },
  HONGKONG_EXIT: {
    // 香港出境
    MAINLAND: { label: '出入内地', value: 1, disabled: false }
  },
  MACAO_EXIT: {
    // 澳门出境
    MAINLAND: { label: '出入内地', value: 1, disabled: false },
    HONGKONG: { label: '出入香港', value: 2, disabled: false }
  },
  IS_UPDATE_PRODUCT_CODE: {
    // 采购费用关联业务类型
    YES: { label: 'product.yes', value: 1 },
    NO: { label: 'product.no', value: 0 }
  },
  SERVICE_ORDER_TIME_TYPE: {
    SPECIFY_TIME: { label: 'sales.service.specifyTime', value: 0 },
    TIME_INTERVAL: { label: 'sales.service.timeInterval', value: 1 },
    UN_SPECIFY_TIME: { label: 'sales.service.unSpecifyTime', value: 2 }
  },
  AUDIT_STATUS: {
    DRAFT: { label: 'sales.service.status.draft', value: 0 },
    UN_AUDIT: { label: 'sales.service.status.unaudit', value: 1 },
    AUDITED: { label: 'sales.service.status.audited', value: 2 }
  },
  SERVICE_STATUS: {
    NOT_START: { label: 'sales.service.status.notStart', value: 0 },
    ONGOING: { label: 'sales.service.status.ongoing', value: 1 },
    FINISH: { label: 'sales.service.status.finish', value: 2 },
    STOPED: { label: 'sales.service.status.stoped', value: 3 },
    CANCEL: { label: 'sales.service.status.cancel', value: 4 }
  },
  POST_SALE_BUSINESS_TYPE: {
    SALES_ORDER: { label: 'postSale.order.salesOrder', value: 1 },
    SALES_DELIVERY: { label: 'postSale.order.salesDelivery', value: 2 }
  },
  POST_SALE_ORDER_STATUS: {
    UNAUDIT: { label: 'postSale.order.unaudit', value: 0 },
    AUDIT: { label: 'postSale.order.audit', value: 1 }
  },
  // 首页广告价格设置 1-商品价格 2-自定义价格
  ADVERTISEMENT_PRICE_CONFIG: {
    GOODS_PRICE: { label: 'dumall.config.goodsPrice', value: 1 },
    CUSTOM_PRICE: { label: 'dumall.config.customPrice', value: 2 }
  },
  // 首页广告上架状态 0-未上线 1-已上线
  ADVERTISEMENT_STATUS: {
    NOT_ONLINE: { label: 'dumall.config.notOnline', value: 0 },
    ONLINE: { label: 'dumall.config.online', value: 1 }
  },
  // 授权范围
  DUMALL_AUTHPRIZATION_TYPE: {
    ALL_GOODS: { label: 'dumall.config.allGoods', value: 1 },
    THE_CUSTOM: { label: 'dumall.config.theCustom', value: 2 }
  },
  // 财务付款申请业务类型
  APPLY_RECEIVABLE_BUSINESSTYPE: {
    SALE_ORDER: { label: 'finance.financeApply.common.saleOrder', value: 0 },
    SALE_DELIVERY: { label: 'finance.financeApply.common.saleDelivery', value: 1 },
    PURCHASE_ORDER: { label: 'finance.financeApply.common.purchaseOrder', value: 2 }
  },
  PAYEE_TYPE: {
    CUSTOMER: { label: 'finance.financeApply.common.customer', value: 0 },
    SUPPLIER: { label: 'finance.financeApply.common.supplier', value: 1 },
    USER: { label: 'finance.financeApply.common.user', value: 2 },
    OTHER: { label: 'finance.financeApply.common.other', value: 99 }
  },
  PRODUCTION_BOM_STATUS: {
    // 生产BOM状态
    DRAFT: { label: 'production.bom.draft', value: 0 },
    UNAUDIT: { label: 'production.bom.unaudit', value: 1 },
    AUDIT: { label: 'production.bom.audit', value: 2 }
  },
  ASSOCIATION_MEMBER_STATUS: {
    // 商协会会员状态
    pengding: { label: 'association.member.pending', value: 0 },
    enabled: { label: 'association.member.enabled', value: 1 },
    disabled: { label: 'association.member.disabled', value: 2 }
  },
  SERVICE_TIME_TYPE: {
    MINUTES: { label: 'service.item.process.minutes', value: 0 },
    HOURS: { label: 'service.item.process.hours', value: 1 },
    NATURALDAYS: { label: 'service.item.process.naturalDays', value: 2 },
    WORKINGDAYS: { label: 'service.item.process.workingDays', value: 3 },
    WEEKS: { label: 'service.item.process.weeks', value: 4 },
    MONTHS: { label: 'service.item.process.months', value: 5 },
    YEARS: { label: 'service.item.process.years', value: 6 }
  },
  DELIVERY_STATUS: {
    // 库存-销售发货单状态
    VERIFY: { label: 'sales.common.verify', value: 3 },
    DONE: { label: 'sales.common.done', value: 4 }
  },
  PRODUCTION_PLAN_STATUS: {
    // 生产计划状态
    ALL: { label: 'production.plan.common.all', value: 4 },
    DRAFT: { label: 'production.plan.common.draft', value: 0 },
    PENDING: { label: 'production.plan.common.pending', value: 1 },
    AUDITED: { label: 'production.plan.common.audited', value: 2 },
    INVALID: { label: 'production.plan.common.invalided', value: 3 }
  },
  FINANC_ACCOUNT_BOOK_BUSINESS_TYPE: {
    // 财务业务类型
    ADD_ACCOUNT: { label: 'finance.businessType.addAccount', value: 100 },
    ADJUST_ACCOUNT_BALANCE: { label: 'finance.businessType.adjustAccountBalance', value: 101 },
    SALES_RECEIPT: { label: 'finance.businessType.salesReceipt', value: 1 },
    PURCHASE_PAYMENT: { label: 'finance.businessType.purchasePayment', value: 2 },
    SALES_RETURN_REFUND: { label: 'finance.businessType.salesReturnRefund', value: 3 },
    PURCHASE_RETURN_REFUND: { label: 'finance.businessType.purchaseReturnRefund', value: 4 },
    DAILY_RECEIPT: { label: 'finance.businessType.dailyReceipt', value: 5 },
    DAILY_PAYMENT: { label: 'finance.businessType.dailyPayment', value: 6 },
    MALL_ORDER_COLLECTION: { label: 'finance.businessType.mallOrderCollection', value: 7 },
    MALL_ORDER_REFUND: { label: 'finance.businessType.mallOrderRefund', value: 8 },
    MALL_RECHARGE: { label: 'finance.businessType.recharge', value: 9 },
    OTHER_COLLECTION: { label: 'finance.businessType.otherCollection', value: 10 },
    OTHER_PAYMENT: { label: 'finance.businessType.otherPayment', value: 11 },
    SALES_INVENTORY_LOCK: { label: 'finance.businessType.salesInventoryLock', value: 12 },
    DEPOSIT_COLLECTION: { label: 'finance.businessType.depositCollection', value: 15 },
    DEPOSIT_PAY: { label: 'finance.businessType.depositPay', value: 16 },
    COMMISSION_EXTRACT: { label: 'finance.financePayable.businessType.commissionExtract', value: 17 },
    REBATE_EXTRACT: { label: 'finance.financePayable.businessType.rebateExtract', value: 18 },
    DEPOSIT_SLIP: { label: 'finance.financePayable.businessType.depositSlip', value: 19 },
    WITHDRAW_SLIP: { label: 'finance.financePayable.businessType.withdrawSlip', value: 20 },
    ADVANCE_RECEIPT: { label: 'finance.financePayable.businessType.advanceReceipt', value: 21 },
    ADVANCE_PAYMENT: { label: 'finance.financePayable.businessType.advacePayment', value: 22 },
    MALL_MEMBER_UPGRADE: { label: 'finance.businessType.mallMemberUpgrade', value: 23 },
    STORE_ORDER_COLLECTION: { label: 'store.order.storeOrderCollection', value: 24 }
  },
  FINANCE_ACCOUNT_BOOK_PURPOSE_BUSINESS_TYPE: {
    SALES_EXPENSE: { label: 'route.salesExpense', value: 1 }
  },
  SCHEDULE_REMIND_TYPE: {
    MINUTES: { label: 'schedule.minutes', value: 1 },
    HOURS: { label: 'schedule.hours', value: 2 },
    DAYS: { label: 'schedule.days', value: 3 }
  },
  // 业务选择器， 业务：业务value
  MULTI_BUSINESS_TYPE: {
    CUSTOMER: { label: 'businessType.customer', value: 11, component: 'MyCustomer' },
    PRODUCT: { label: 'businessType.product', value: 21, component: 'ProductData' },
    SALES_QUOTATION: { label: 'businessType.salesQuotation', value: 31, component: 'SalesQuotation' },
    SALES_ORDER: { label: 'businessType.salesOrder', value: 32, component: 'SalesOrder' },
    SALES_DELIVERY: { label: 'businessType.salesDelivery', value: 33, component: 'SalesDelivery' },
    SALES_RETURN: { label: 'businessType.salesReturn', value: 34, component: 'SalesReturn' },
    SALES_EXPENSE: { label: 'businessType.salesExpense', value: 35, component: 'SsalesExpense' },
    PURCHASE_SUPPLIER: { label: 'businessType.purchaseSupplier', value: 41, component: 'PurchaseSupplierManager' },
    PURCHASE_PLAN: { label: 'businessType.purchasePlan', value: 42, component: 'PpurchasePlan' },
    PURCAHSE_REQUISITION: { label: 'businessType.purchaseRequisition', value: 43, component: 'PurchaseRequisition' },
    PURCHASE_ORDER: { label: 'businessType.purchaseOrder', value: 44, component: 'PurchaseOrder' },
    PURCHASE_ORDER_OUT: { label: 'businessType.purchaseOrderOut', value: 45, component: 'PurchaseOrderOut' },
    PURCHASE_EXPENSE: { label: 'businessType.purchaseExpense', value: 46, component: 'PurchaseExpense' }
  },
  MULTI_BUSINESS_COMPONENTS: {
    11: { component: 'MyCustomer' },
    21: { component: 'ProductData' },
    31: { component: 'SalesQuotation' },
    32: { component: 'SalesOrder' },
    33: { component: 'SalesDelivery' },
    34: { component: 'SalesReturn' },
    35: { component: 'SalesExpense' },
    41: { component: 'PurchaseSupplierManager' },
    42: { component: 'PurchasePlan' },
    43: { component: 'PurchaseRequisition' },
    44: { component: 'PurchaseOrder' },
    45: { component: 'PurchaseOrderOut' },
    46: { component: 'PurchaseExpense' }
  },
  CUSTOMER_VISIT_STATUS: {
    // 客户拜访计划状态
    NOT_FOLLOW_UP: { label: 'crm.visit.notFollowUp', value: 0 },
    DELAYED: { label: 'crm.visit.delayed', value: 1 },
    CANCELED: { label: 'crm.visit.canceled', value: 2 },
    FINISHED: { label: 'crm.visit.finished', value: 3 }
  },
  // 项目范围
  PROJECT_SCOPES: {
    PRIVATE: { label: 'project.common.scopes.private', value: 0 },
    PUBLIC: { label: 'project.common.scopes.public', value: 1 }
  },
  // 项目状态
  PROJECT_STATUS: {
    NOT_START: { label: 'project.common.notBegin', value: 1 },
    ON_GOING: { label: 'project.common.ongoing', value: 2 },
    HANG_UP: { label: 'project.common.hangUp', value: 3 },
    COMPLETE: { label: 'project.common.finish', value: 4 }
  },
  // 项目任务状态
  PROJECT_TASK_STATUS: {
    NOT_START: { label: 'project.common.notBegin', value: 1 },
    ON_GOING: { label: 'project.common.ongoing', value: 2 },
    HANG_UP: { label: 'project.common.hangUp', value: 3 },
    COMPLETE: { label: 'project.common.finish', value: 4 },
    OVERDUE: { label: 'project.common.overdue', value: 5 }
  },
  // 任务优先级
  PROJECT_TASK_PRIORITY: {
    LOW: { label: 'project.task.common.low', value: 1 },
    MIDDLE: { label: 'project.task.common.middle', value: 2 },
    HEIGHT: { label: 'project.task.common.hight', value: 3 }
  },
  SYS_TAG_BUSINESS_TYPE: {
    CUSTOMER: { label: 'sysTag.businessType.customer', value: 1 },
    SUPPLER: { label: 'sysTag.businessType.supplier', value: 2 },
    SERVICE_ORDER: { label: 'sysTag.businessType.serviceOrder', value: 3 },
    SALE_ORDER: { label: 'sysTag.businessType.saleOrder', value: 4 },
    PURCHASE_ORDER: { label: 'sysTag.businessType.purchase', value: 5 }
  },
  // 服务项目，时间格式
  SERVICE_ITEM_TIME_MODE: {
    YMD: { label: 'yyyy-mm-dd', value: 1 },
    YMDHM: { label: 'yyyy-mm-dd HH:mm', value: 2 }
  },
  SALES_GOAL_INDEX_TYPE: {
    // 销售目标指标类型
    ORDER_AMOUNT: { label: 'sales.goal.orderAmount', value: 1 },
    ORDER_RECEIPT_AMOUNT: { label: 'sales.goal.orderReceiptAmount', value: 2 },
    SIGNED_ORDER_QUANTITY: { label: 'sales.goal.signedOrderQuantity', value: 3 },
    ADDED_CUSTOMER_QUANTITY: { label: 'sales.goal.addedCustomerQuantity', value: 4 },
    FOLLOW_UP_TIMES: { label: 'sales.goal.followUpTimes', value: 5 },
    FOLLOW_UP_CUSTOMER_TIMES: { label: 'sales.goal.followUpCustomerTimes', value: 6 }
  },
  SALES_GOAL_BUSINESS_TYPE: {
    PERSON: { label: 'sales.goal.person', value: 0 },
    ORGANIZATION: { label: 'sales.goal.organization', value: 1 }
  },
  COMMON_BUSINESS_TYPE: {
    PURCHASE_RECEIPT_INVENTORY: { label: 'commonBusinessType.purchaseReceiptInventory', value: 1 },
    SALES_RETURN_INVENTORY: { label: 'commonBusinessType.salesReturnInventory', value: 1 },
    OUT_ORDER_INVENTORY: { label: 'commonBusinessType.outOrderInventory', value: 1 },
    PURCHASE_RETURN_INVENTORY: { label: 'commonBusinessType.purchaseReturnInventory', value: 1 },
    PURCHASE_RECEIPT_ADD_INVENTORY: { label: 'commonBusinessType.purchaseReceiptAddInventory', value: 1 },
    SELF_VIEW_INVENTORY: { label: 'commonBusinessType.selfViewInventory', value: 1 }
  },
  INVENTORY_PACK_TYPE: {
    // 库存-组装类型
    UNPACK: { label: 'inventory.pack.unPack', value: 0 },
    PACK: { label: 'inventory.pack.pack', value: 1 }
  },
  INVENTORY_PACK_STATUS: {
    // 库存=装配状态 draft
    DRAFT: { label: 'inventory.pack.draft', value: 0 },
    PENDING: { label: 'inventory.pack.pending', value: 1 },
    FINISHED: { label: 'inventory.pack.finished', value: 2 }
  },
  PRODUCT_SHELF_LIFE_UNIT: {
    DAY: { label: 'product.shelfLifeUnits.day', value: 0 },
    MOUTH: { label: 'product.shelfLifeUnits.mouth', value: 1 },
    YEAR: { label: 'product.shelfLifeUnits.year', value: 2 }
  },
  SALES_PRICE_TYPE_BUSINESS_TYPE: {
    // 销售 - 价格类型 业务类型
    CUSTOMER: { label: 'sales.priceType.customer', value: 1 },
    CUSTOMER_SORT: { label: 'sales.priceType.customerSort', value: 2 },
    CUSTOMER_TAG: { label: 'sales.priceType.customerTag', value: 3 }
  },
  PURCHASE_PRICE_TYPE_BUSINESS_TYPE: {
    // 采购 - 价格类型 业务类型
    SUPPLIER: { label: 'sales.priceType.customer', value: 1 },
    SUPPLIER_TYPE: { label: 'sales.priceType.supplierType', value: 2 }
  },
  // 快递信息业务类型
  EXPRESS_RECORD_BUSINESS_TYPE: {
    SALES_ORDER: { label: 'businessType.salesOrder', value: 1 },
    SALES_DELIVERY: { label: 'businessType.salesDelivery', value: 2 },
    INVENTORY_OUT: { label: 'businessType.inventoryOut', value: 3 }
  },
  SALES_SETTLEMENT_PERIOD: {
    STANDARD_PERIOD: { label: 'sales.basicData.period.standardPeriod', value: 0 },
    MONTH_PERIOD: { label: 'sales.basicData.period.monthPeriod', value: 1 }
  },
  AUTO_DELIVERY: {
    RECEIPT_PLAN_AUTO: { label: 'sales.basicData.period.receiptPlanAuto', value: 0 },
    ORDER_VERIFY_AUTO: { label: 'sales.basicData.period.orderAuditAuto', value: 1 }
  },
  ADVANCE_RECEIPT_AUDIT: {
    NOT_AUDIT: { label: 'finance.advanceReceipt.status.notAudit', value: 0 },
    AUDIT: { label: 'finance.advanceReceipt.status.audit', value: 1 },
    REVOKED: { label: 'finance.advanceReceipt.status.revoked', value: 2 }
  },
  ADVANCE_RECEIPT_DIRECT: {
    NO: { label: 'finance.advanceReceipt.status.no', value: 0 },
    YES: { label: 'finance.advanceReceipt.status.yes', value: 1 }
  },
  ADVANCE_RECEIPT_WRITE_OFF: {
    NOT_WRITE_OFF: { label: 'finance.advanceReceipt.status.notWriteOff', value: 0 },
    PARTIAL_WRITE_OFF: { label: 'finance.advanceReceipt.status.partialWriteOff', value: 1 },
    WRITE_OFF: { label: 'finance.advanceReceipt.status.writeOff', value: 2 }
  },
  ADVANCE_RECEIPT_BUSINESS_TYPE: {
    SALE_ORDER: { label: 'finance.advanceReceipt.status.saleOrder', value: 0 },
    PRODUCT_CATEGORY: { label: 'finance.advanceReceipt.status.productCategory', value: 1 },
    PRODUCT_BRAND: { label: 'finance.advanceReceipt.status.productBrand', value: 2 },
    PRODUCT: { label: 'finance.advanceReceipt.status.product', value: 3 }
  },
  INVENTORY_CHECK_TYPE: {
    // 盘点单 盘点类型
    PART_CHECK: { label: 'inventory.check.partCheck', value: 0 },
    ALL_CHECK: { label: 'inventory.check.allCheck', value: 1 }
  },
  SALE_RETURN_BUSINESS_TYPE: {
    SALE_ORDER: { label: 'sales.salesReturn.businessType.saleOrder', value: 0 },
    SALE_DELIVERY: { label: 'sales.salesReturn.businessType.saleDelivery', value: 1 },
    OUT_INVENTORY: { label: 'sales.salesReturn.businessType.outInventory', value: 2 }
  },
  INVENTORY_NOTIFICATION_STATUS: {
    NOT_STOCK: { label: 'inventory.status.notStock', value: 0 },
    PARTIAL_STOCK: { label: 'inventory.status.partialStock', value: 1 },
    OUT_OF_STOCK: { label: 'inventory.status.outOfStock', value: 2 }
  },
  INVENTORY_IN_NOTIFICATION_STATUS: {
    NOT_WAREHOUS: { label: 'inventory.status.notWarehous', value: 0 },
    PARTIAL_WAREHOUS: { label: 'inventory.status.partialWarehous', value: 1 },
    OUT_OF_WAREHOUS: { label: 'inventory.status.outOfWarehous', value: 2 }
  },
  INVENTORY_COST_TYPE: {
    // 库存成本核算方式
    FIFO: { label: 'finance.config.fifo', value: 1 }
    // MOVE_WEIGHTED_AVG: { label: 'finance.config.moveWeightedAvg', value: 2 }
  },
  // 商品状态
  MALL_GOODS_STATUS: {
    DELETED: { label: 'mall.goods.status.deleted', value: 0 },
    OFF_SHELF: { label: 'mall.goods.status.offShelf', value: 1 },
    ON_SHELF: { label: 'mall.goods.status.onShelf', value: 2 },
    SOLD_OUT: { label: 'mall.goods.status.soldOut', value: 3 }
  },
  // 商品规格
  MALL_GOODS_SPECS_STATUS: {
    SINGLE_SPECS: { label: 'mall.goods.specs.singleSpecs', value: 1 },
    MULTI_SPECS: { label: 'mall.goods.specs.multiSpecs', value: 2 }
  },
  // 商品类型
  MALL_GOODS_TYPE: {
    REAL_GOODS: { label: 'mall.goods.realGoods', value: 1 },
    VIRTUAL_GOODS: { label: 'mall.goods.virtualGoods', value: 2 }
  },
  // 商品虚拟发货方式
  MALL_GOODS_VIRTUAL_DELIVERY_TYPE: {
    NONE_DELIVERY: { label: 'mall.goods.noneDelivery', value: 1 },
    AUTO_DELIVERY: { label: 'mall.goods.autoDelivery', value: 2 }
  },
  // 商品虚拟发货方式
  MALL_GOODS_VIRTUAL_DELIVERY_GOODS_TYPE: {
    VOUCHER: { label: 'mall.goods.virtualVoucher', value: 1 },
    VIRTUAL_RESOURCE: { label: 'mall.goods.virtualResource', value: 2 }
  },
  // 商品销量显示设置
  MALL_SALES_QUANTITY_TYPE: {
    NOT_DISPLAY_SALES_QUANTITY: { label: 'mall.goods.notDisplaySalesQuantity', value: 2 },
    ACTUAL_SALES_QUANTITY: { label: 'mall.goods.actualSalesQuantity', value: 3 },
    VIRTUAL_SALES_QUANTITY: { label: 'mall.goods.virtualSalesQuantity', value: 0 },
    SUM_SALES_QUANTITY: { label: 'mall.goods.sumSalesQuantity', value: 1 }
  },
  // 商品可见范围 1无限制，2客户可见， 3都不可见
  MALL_VISIVEL_TYPE: {
    NO_LIMIT: { label: 'mall.goods.noLimit', value: 1 },
    CUSTOMER_VISIBLE: { label: 'mall.goods.specifiedCustomerVisible', value: 2 },
    NO_VISIBLE: { label: 'mall.goods.noVisible', value: 3 },
    CUSTOMER_SORT_VISIBLE: { label: 'mall.posters.customerSort', value: 4 }
  },
  // 支付类型
  MALL_CONFIG_PAYMENT: {
    WE_CHAT_PAY: { label: 'mall.payConfig.weChatPay', value: '1' },
    WE_CHAT_CHILE_PAY: { label: 'mall.payConfig.weChatPayChild', value: '2' }
  },
  // 订单状态
  ORDER_STATUS: {
    OBLIGATION: { label: 'order.obligation', value: 1 },
    PENDING: { label: 'order.pending', value: 2 },
    WAITR_ECEIVING: { label: 'order.waitReceiving', value: 3 },
    COMPLETED: { label: 'order.competed', value: 4 },
    CLOSED: { label: 'order.closed', value: 5 },
    REFUND_COMPLETED: { label: 'order.refundCompleted', value: 6 },
    DELETED: { label: 'order.buyerDelete', value: 7 },
    PART_DELIVERY: { label: 'order.partDelivery', value: 8 },
    WAITR_COMFIRMED: { label: 'order.tobeConfirmed', value: 9 },
    COMPLETED_DELETE: { label: 'order.competed', value: 10 },
    REFUNDED_DELETE: { label: 'order.refundCompleted', value: 11 }
  },
  // 自定义运费模板类型
  GOODS_CUSTOM_FREIGHT_BUSINESS_TYPE: {
    CUSTOMER_SORT: { label: 'common.customerSort', value: 1 },
    MEMBER_LEVEL: { label: 'common.memberLevel', value: 2 }
  },
  // 订单自动完成经办人
  MALL_ORDER_COMPLETE_OPERATOR_TYPE: {
    SYS_AUTO: { label: 'common.sysAutoComplete', value: 1 },
    SYS_USER: { label: 'common.sysUser', value: 2 },
    MALL_MEMBER: { label: 'common.memberComplete', value: 3 }
  },
  // 快递结算方式
  EXPRESS_SETTLEMENT_METHOD: {
    CASH_BY_POST: { label: 'expressSettlement.cashByPost', value: 1 },
    PAY_ON_DELIVERY: { label: 'expressSettlement.payOnDelivery', value: 2 },
    MONTHLY_BY_POST: { label: 'expressSettlement.monthlyByPost', value: 3 }
  },
  // 门户审核状态
  TENANT_PORTAL_STATUS: {
    NOT_AUDIT: { label: 'portal.manage.notAudit', value: 1 },
    AUDITED: { label: 'portal.manage.audited', value: 2 },
    REJECT: { label: 'portal.manage.reject', value: 3 }
  },
  GOODS_PORTAL_STATUS: {
    NOT_AUDIT: { label: 'portal.productReview.notAudit', value: 1 },
    PENDING_REVIEW: { label: 'portal.productReview.pendingReview', value: 2 },
    REVIEWED: { label: 'portal.productReview.review', value: 3 },
    REJECT: { label: 'portal.productReview.reject', value: 4 }
  },
  // 商城退货单状态
  RETURN_STATUS: {
    TO_CONFIRMED: { label: 'mall.return.toConfirmed', value: 1 },
    CONFIRMED: { label: 'mall.return.beConfirmed', value: 2 },
    REJECTED: { label: 'mall.return.beRejected', value: 3 },
    COMPLETED: { label: 'mall.return.completed', value: 4 },
    BUYER_NOT_DELIVERY: { label: 'mall.return.buyerNotDelivery', value: 5 },
    BUYER_DELIVERED: { label: 'mall.return.buyerDelivered', value: 6 },
    SELLER_RECEIPT: { label: 'mall.return.sellerReceipt', value: 7 },
    SELLER_DELIVERED: { label: 'mall.return.sellerDelivered', value: 8 },
    BUYER_RECEIPT: { label: 'mall.return.buyerReceipt', value: 9 },
    NOT_REFUND: { label: 'mall.return.notRefund', value: 10 },
    REFUNDED: { label: 'mall.return.refunded', value: 11 },
    CANCELED: { label: 'mall.return.canceled', value: 12 }
  },
  // 退货单类型   1-商品退款 2-商品退货退款 3-商品换货 4 订单退款
  RETURN_TYPE: {
    REFUND: { label: 'mall.return.refund', value: 1 },
    REFUNDS: { label: 'mall.return.refunds', value: 2 },
    EXCHANGE: { label: 'mall.return.exchange', value: 3 },
    ORDER_RETURN: { label: 'mall.return.orderReturn', value: 4 },
    REFUND_FREIGHT: { label: 'mall.return.freightReturn', value: 5 },
    REFUND_BALANCE: { label: 'mall.return.refundBalance', value: 6 }
  },
  // 退货商家退回方式
  MALL_RETURN_BUYER_RETURN_METHOD: {
    EXPRESS: { label: 'order.buyerReturnByExpress', value: 1 },
    OTHER: { label: 'order.buyerReturnByOther', value: 2 }
  },
  MALL_GOODS_DISCOUNT_CONFIG: {
    NONE: { label: 'mall.goods.discount.none', value: 1 },
    MEMBER_DISCOUNT: { label: 'mall.goods.discount.memberDiscount.', value: 2 }
  },
  MALL_GOODS_DISCOUNT_TYPE: {
    RATE: { label: 'mall.goods.discount.rate', value: 1 },
    PRICE: { label: 'mall.goods.discount.price', value: 2 }
  },
  MALL_COMPONENT_TYPE: {
    // 在线商城 组件类型
    SEARCH: { label: 'mall.indexDecorate.search', value: 0 },
    CAROUSEL: { label: 'mall.indexDecorate.carousel', value: 1 },
    BUTTON_GROUP: { label: 'mall.indexDecorate.buttonGroup', value: 2 },
    ANNOUNCEMENT: { label: 'mall.indexDecorate.announcement', value: 3 },
    TITLE: { label: 'mall.indexDecorate.title', value: 4 },
    PRODUCT_GROUP: { label: 'mall.indexDecorate.productGroup', value: 5 }
  },
  CATEGORY_LEVEL: {
    PRIMARY: { lavel: 'mall.goods.category.primaryLevel', value: '1' },
    SECONDARY: { lavel: 'mall.goods.category.secondaryLevel', value: '2' },
    THIRD: { lavel: 'mall.goods.category.tertiaryLevel', value: '3' },
    FIRST_WITH_GOODS: {label: "mall.goods.category.firstLevelWithGoods",value: "5"},
    THIRD_WITH_GOODS: {label: "mall.goods.category.tertiaryLevelWithGoods",value: "4"}
  },
  // INVENTORY_ORDER_WAREHOUSE_STATE: {
  //   ENABLED: {
  //     value: 1,
  //     text: 'inventory.status.notStock'
  //   },
  //   DISENABLED: {
  //     value: 0,
  //     text: 'inventory.status.outOfStock'
  //   }
  // },

  // 商品类型
  MALL_GOODS_TYPES: {
    REAL_GOODS: { label: 'order.realGoods', value: 1 },
    VIRTUAL_GOODS: { label: 'order.virtualGoods', value: 2 }
  },
  // 订单发货状态
  MALL_ORDER_DELIVERY_STATUS: {
    NO_DELIVERY: { label: 'order.noDelivery', value: 0 },
    PART_DELIVEY: { label: 'order.partDelivery', value: 1 },
    TO_DELIVERY: { label: 'order.toDelivery', value: 2 },
    SUPER_DELIVERY: { label: 'order.superDelivery', value: 3 }
  },
  // 快递状态
  EXPE_STATUS: {
    NO_TRACE: { label: 'expressStatus.noTrace', value: '0' },
    RECEIVED: { label: 'expressStatus.received', value: '1' },
    IN_TRANSIT: { label: 'expressStatus.inTransit', value: '2' },
    SIGNED: { label: 'expressStatus.expSigned', value: '3' },
    ERROR: { label: 'expressStatus.error', value: '4' },
    FORWARD: { label: 'expressStatus.forward', value: '5' },
    CLEARANCE: { label: 'expressStatus.clearance', value: '6' },
    PENDING: { label: 'expressStatus.pending', value: '10' }

  },
  // 任务导入状态
  TASK_IMPORT_STATUS: {
    WAITING: { label: 'thirdPartOrder.taskImportStatusEnum.waiting', value: 0 },
    IMPORTING: { label: 'thirdPartOrder.taskImportStatusEnum.importing', value: 1 },
    COMPLETED: { label: 'thirdPartOrder.taskImportStatusEnum.completed', value: 2 }

  },
  // 任务导入操作
  TASK_IMPORT_OPERATION_TYPE: {
    OVERWRITE: { label: 'thirdPartOrder.importTask.overwrite', value: 0 },
    APPEND: { label: 'thirdPartOrder.importTask.append', value: 1 }

  },
  // 任务导入状态
  TASK_RECORD_IMPORT_STATUS: {
    WAITING: { label: 'thirdPartOrder.taskRecordImportStatusEnum.waiting', value: 0 },
    IMPORTING: { label: 'thirdPartOrder.taskRecordImportStatusEnum.success', value: 1 },
    COMPLETED: { label: 'thirdPartOrder.taskRecordImportStatusEnum.fail', value: -1 }

  },
  // 库存扣减设置
  REDUCE_STOCK_CONFIG: {
    ORDERED: { label: 'mall.goods.orderedReduce', value: 1 },
    NONE: { label: 'mall.goods.notReduce', value: 2 }
  },
  // 卡券商品发货方式
  WELFARE_GOODS_SHIPPING_METHOD: {
    WAREHOUSE: { label: 'common.inventoryDelivery', value: 1 },
    OUTSOURCING: { label: 'common.purchaseDelivery', value: 2 }
  },
  INVENTORY_STOCK_ADD_CONFIG: {
    // 预计入库量
    AUDITED_PURCHASE_ORDER: { label: 'inventory.config.auditedPurchaseOrder', value: 100 },
    PENDING_INITIAL_IN: { label: 'inventory.config.pendingInitialIn', value: 101 },
    PENDING_OTHER_IN: { label: 'inventory.config.pendingOtherIn', value: 102 },
    PENDING_FINISHED_PRODUCT: { label: 'inventory.config.pendingFinishedProduct', value: 103 },
    PENDING_REPORT_OVERFLOW: { label: 'inventory.config.pendingReportOverflow', value: 105 },
    PENDING_INTERCONNECT_IN: { label: 'inventory.config.pendingInterconnectIn', value: 106 }
  },
  INVENTORY_STOCK_REDUCE_CONFIG: {
    // 预计出库量
    AUDITED_SALES_ORDER: { label: 'inventory.config.auditedSalesOrder', value: 200 },
    PENDING_OTHER_OUT: { label: 'inventory.config.pendingOtherOut', value: 201 },
    PENDING_REPORT_LOSS: { label: 'inventory.config.pendingReportLoss', value: 202 },
    PENDING_INTERCONNECT_OUT: { label: 'inventory.config.pendingInterconnectOut', value: 203 },
    PAID_WELFARE_ORDER: { label: 'inventory.config.paidWelfareOrder', value: 204 }
  },
  INVENTORY_STOCK_MALL_ORDER_CONFIG: {
    NONE: { label: 'inventory.config.none', value: 0 },
    UNPAID_ORDER: { label: 'inventory.config.unpaidOrder', value: 1 },
    PAID_ORDER: { label: 'inventory.config.paidOrder', value: 2 }
  },
  INVENTORY_STOCK_LOCK_CONFIG: {
    // 库存配置-锁定库存
    SALES_INVENTORY_LOCK: { label: 'inventory.config.salesInventoryLock', value: 300 }
  },
  // 锁定库存-排除业务类型
  INVENTORY_STOCK_EXCLUDE_BUSINESS_TYPE: {
    SALES_ORDER: { label: 'route.salesOrder', value: 1 },
    MALL_ORDER: { label: 'route.mallOrder', value: 2 },
    PURCHASE_ORDER: { label: 'route.purchaseOrder', value: 3 },
    WELFARE_ORDER: { label: 'route.exchangeOrder', value: 4 }
  },
  PURCHASE_RETURN_BUSINESS_TYPE: {
    // 采购退上级业务
    PURCHASE_ORDER: { label: 'purchase.return.purchaseOrder', value: 1 }
  },
  PURCHASE_OUT_RETURN_BUSINESS_TYPE: {
    // 采购委外退订单上级业务
    SALES_ORDER: { label: 'route.salesOrder', value: 1 },
    MALL_ORDER: { label: 'common.mallOrder', value: 2 }
  },
  // 配送方式 1-快递 2-自提
  DELIVERY_TYPE: {
    COURIER: { label: 'order.courier', value: 1 },
    PICK: { label: 'order.pick', value: 2 }
  },

  // 支付方式 1-微信支付 2-线下支付,3余额支付,4货到付款 5挂账月结 6：定向预收款支付
  PAYMENT_TYPE: {
    WECHAT: { label: 'order.weChat', value: 1 },
    OFFLINE_PAYMENT: { label: 'order.offlinePayment', value: 2 },
    REMAIN_PAY: { label: 'mall.payConfig.remainPay', value: 3 },
    DELIVERY_CRASH_PAY: { label: 'mall.payConfig.deliveryCash', value: 4 },
    PAYMENT_MONTH_STATEMENT: { label: 'mall.payConfig.paymentMonthStatement', value: 5 },
    DIRECT_ADVANCE_RECEIPT_PAY: { label: 'mall.payConfig.otherPayment', value: 6 }
  },
  // 订单类型 1-普通订单  2-礼盒订单
  MALL_ORDER_TYPE: {
    GENERAL_ORDER: { label: 'mall.common.generalOrder', value: 1 },
    GIFT_ORDER: { label: 'mall.common.giftOrder', value: 2 }
  },
  ADVANCE_RECEIPT_ADD_METHOD: {
    MANUAL: { label: 'finance.advanceReceipt.manual', value: 1 },
    COLLECTION_GENE: { label: 'finance.advanceReceipt.collectionGene', value: 2 },
    SALES_RETURN_PAYMENT_GENE: { label: 'finance.advanceReceipt.salesReturnPaymentGene', value: 3 },
    COMMISSION_EXTRACT_GENE: { label: 'finance.advanceReceipt.commissionExtractGene', value: 4 }
  },
  ADVANCE_PAYMENT_ADD_METHOD: {
    MANUAL: { label: 'finance.advanceReceipt.manual', value: 1 },
    PAYMENT_GENE: { label: 'finance.advancePayment.paymentGene', value: 2 },
    PURCHASE_RETURN_PAYMENT_GENE: { label: 'finance.advancePayment.purchaseReturnPaymentGene', value: 3 }
  },
  PRODUCT_BUSINESS_UNIT_TYPE: {
    CUSTOMER: { label: 'product.alias.customer', value: 1 },
    SUPPLIER: { label: 'product.alias.supplier', value: 2 }
  },
  // 绑定状态 1待审核 2已审核 3已驳回 4已解绑
  BINDING_STATUS: {
    NO_BINDDING: { label: 'member.binding.waitAudit', value: 1 },
    BE_BINDDING: { label: 'member.binding.throughed', value: 2 },
    REJECT: { label: 'member.binding.beReject', value: 3 }
    // HAVE_UNBINDDING: { label: 'member.binding.haveUnbundling', value: 4 }
  },
  FREIGHT_BILL_WAY: {
    PIECE: { label: 'config.freight.piece', value: 1 },
    WEIGHT: { label: 'config.freight.weight', value: 2 }
  },
  // 退回方式  1快递 2其他方式
  RETURN_METHOD: {
    EXPRESS: { label: 'mall.return.courier', value: 1 },
    WEIGHT: { label: 'mall.return.otherWay', value: 2 }
  },
  MALL_RECHARGE_METHOD: {
    ONLINE: { label: 'mall.recharge.online', value: 1 },
    OFFLINE: { label: 'mall.recharge.offline', value: 2 }
  },
  MALL_RECHARGE_STATUS: {
    SUBMIT: { label: 'mall.recharge.submit', value: 1 },
    NOT_PAID: { label: 'mall.recharge.notPaid', value: 2 },
    CANCELED: { label: 'mall.recharge.cancaled', value: 3 },
    SUCCESS: { label: 'mall.recharge.success', value: 4 },
    FAIL: { label: 'mall.recharge.fail', value: 5 }
  },
  // 维权类型
  ACTIVIST_STATUS: {
    ACTIVISTING: { label: 'order.activisting', value: 1 },
    ACTIVISTED: { label: 'order.activisted', value: 2 }
  },
  NOTIFY_BUSINESS_TYPE: {
    MALL_ORDER_OFFLINE_SUBMIT: { label: 'notifyConfig.mallOrderOfflineSubmit', value: 1 },
    PAY_SUCCESS: { label: 'notifyConfig.paySuccess', value: 2 },
    RECHARGE_OFFLINE_SUBMIT: { label: 'notifyConfig.rechargeOfflineSubmit', value: 3 },
    RECHARGE_SUCCESS: { label: 'notifyConfig.rechargeSuccess', value: 4 },
    FINANCE_CONFIRM: { label: 'notifyConfig.financeConfirm', value: 5 },
    REFUND_APPLY: { label: 'notifyConfig.refundApply', value: 6 },
    MONTH_PAY: { label: 'notifyConfig.monthPay', value: 7 },
    INVENTORY_IN: { label: 'notifyConfig.inventoryIn', value: 8 },
    INVENTORY_OUT: { label: 'notifyConfig.inventoryOut', value: 9 },
    PENDING_FINANCE_RECEIPT: { label: 'notifyConfig.pendingFinanceReceipt', value: 10 },
    FINISHED_FINANCE_RECEIPT: { label: 'notifyConfig.finishedFinanceReceipt', value: 11 },
    PENDING_SALES_ORDER: { label: 'notifyConfig.pendingSalesOrder', value: 12 },
    AUDITED_SALES_ORDER: { label: 'notifyConfig.auditedSalesOrder', value: 13 },
    DAILY_PAPER: { label: 'notifyConfig.dailyPaper', value: 14 }
  },
  CRM_DISTRIBUTOR_AUDIT_STATUS: {
    PENDING: { label: 'crm.distributorApply.pending', value: 0 },
    AUDITED: { label: 'crm.distributorApply.audited', value: 1 },
    REJECT: { label: 'crm.distributorApply.reject', value: -1 }
  },
  FINANCE_COMMISSION_BUSINESS_TYPE: {
    MALL_ORDER: { label: 'finance.commission.mallOrder', value: 1 },
    SALES_ORDER: { label: 'finance.commission.salesOrder', value: 2 },
    STORE_ORDER: { label: 'store.order.storeOrder', value: 3 }
  },
  // 分佣状态
  FINANCE_COMMISSION_STATUS: {
    UNDIVIDED: { label: 'finance.commission.undivided', value: 0 },
    ALLOCATED: { label: 'finance.commission.allocated', value: 1 }
  },
  // 佣金对象
  FINANCE_COMMISSION_BUSINESS_UNIT_TYPE: {
    CUSTOMER: { label: 'finance.commission.customer', value: 1 },
    MEMBER: { label: 'finance.commission.member', value: 2 }
  },
  CORRELATION_TYPE: {
    MEMBER: { label: 'mall.goods.posters.member', value: 1 },
    CUSTOMER: { label: 'mall.goods.posters.customer', value: 2 },
    CUSTOMER_SORT: { label: 'mall.goods.posters.customerSort', value: 3 }
  },
  PRODUCTION_ORDER_STATUS: {
    // 生产订单状态
    DRAFT: { label: 'common.draft', value: 0 },
    PENDING: { label: 'production.order.pending', value: 1 },
    AUDITED: { label: 'production.order.audited', value: 2 },
    IN_PRODUCTION: { label: 'production.order.inProduction', value: 3 },
    FINISHED: { label: 'production.order.finished', value: 4 }
  },
  ENTERPRISE_TYPE: {
    // 企业身份
    SALES: { label: 'enterprise.common.sales', value: 1 },
    PURHCASE: { label: 'enterprise.common.purchase', value: 2 }
  },
  ENTERPRISE_STATUS: {
    // 企业状态
    PENDING: { label: 'enterprise.common.pending', value: 0 },
    AUDITED: { label: 'enterprise.common.audited', value: 1 },
    REJECT: { label: 'enterprise.common.reject', value: -1 }
  },
  ENTERPRISE_INTERCONNECT_STATUS: {
    // 互联状态
    NO_CONNECT: { label: 'enterprise.interconnect.noConnect', value: 0 },
    CONNECT: { label: 'enterprise.interconnect.connect', value: 1 }
  },
  // 短信签名来源
  SMS_SIGN_SOURCE: {
    ENTERPRISE_NAME: { label: 'config.sms.enterpriseName', value: 0 },
    FILED_WEBSITE: { label: 'config.sms.filedWebsite', value: 1 },
    ONLINE_APP: { label: 'config.sms.onlineApp', value: 2 },
    APPLET: { label: 'config.sms.applet', value: 3 },
    STORE_NAME: { label: 'config.sms.storeName', value: 4 },
    TRADE_NAME: { label: 'config.sms.tradeName', value: 5 }
  },
  SMS_SIGN_STATUS: {
    AUDITING: { label: 'config.sms.auditing', value: 1 },
    AUDITED_PASS: { label: 'config.sms.auditedPass', value: 2 },
    AUDITED_REJECT: { label: 'config.sms.auditedReject', value: 3 }
  },
  SMS_ALI_TEMPLATE_TYPE: {
    NOTIFICATION: { label: 'message.template.notification', value: 1 },
    PROMOTION: { label: 'message.template.promotion', value: 2 }
  },
  // 优惠券是否使用 0未使用 1已使用
  MEMBER_COUPONS_STATUS: {
    NOT_USED: { label: 'mall.marketing.notUsed', value: 0 },
    HAS_USED: { label: 'mall.marketing.hasUsed', value: 1 }
  },
  // 优惠券类型 0满减卷 1折扣券
  MALL_COUPONS_TYPE: {
    FULL_CUT_COUPONS: { label: 'mall.marketing.fullVolumeReduction', value: 0 },
    DISCOUNT_COUPONS: { label: 'mall.marketing.discountCoupons', value: 1 }
  },
  // 优惠券领取限制 0不限制 1客户 2会员 3私有
  MALL_COUPONS_CLAIMANT_LIMIT: {
    // NO_LIMIT: { label: 'mall.marketing.nolimit', value: 0 },
    CUSTOMER: { label: 'mall.marketing.customer', value: 1 },
    MEMBER: { label: 'mall.marketing.member', value: 2 },
    PRIVATELY: { label: 'mall.marketing.privately', value: 3 }
  },
  // 优惠券领取客户限制 0全部客户 1指定客户分类
  MALL_COUPONS_CLAIMANT_LIMIT_CUSTOMER: {
    ALL_CUSTOMER: { label: 'mall.marketing.allCustomer', value: 0 },
    CUSTOMER_SORT: { label: 'mall.marketing.designatedCustomerCategory', value: 1 }
  },
  // 优惠券关联 类型 关联类型（1-指定客户分类,2-指定会员等级,3-允许以下商品使用-允许以下商品分类使用 5-不允许以下商品使用）
  MALL_COUPONS_CORRELATION_TYPE: {
    CUSTOMER_SORT: { label: 'mall.marketing.designatedCustomerCategory', value: 1 },
    MEMBER_LEVEL: { label: 'mall.marketing.designatedMemberLevel', value: 2 },
    GOODS: { label: 'mall.marketing.allowFollowingGoodsUse', value: 3 },
    GOODS_CATE: { label: 'mall.marketing.allowFollowGoodsCategoryUse', value: 4 },
    NO_ALLOW_GOODS: { label: 'mall.marketing.noAllowFollowingGoodsUse', value: 5 }
  },
  // 优惠券领取会员限制 0全部会员 1指定会员分类
  MALL_COUPONS_CLAIMANT_LIMIT_MEMBER: {
    ALL_MEMBER: { label: 'mall.marketing.allMember', value: 0 },
    MEMBER_SORT: { label: 'mall.marketing.designatedMemberLevel', value: 1 }
  },
  // 商品使用限制：0全部商品、1允许以下商品使用、2不允许以下商品使用、3允许以下商品分类使用
  MALL_COUPONS_GOODS_LIMIT: {
    ALL_GOODS_ALLOWED: { label: 'mall.marketing.allGoods', value: 0 },
    SOME_GOODS_ALLOWED: { label: 'mall.marketing.allowFollowingGoodsUse', value: 1 },
    SOME_GOODS_NOT_ALLOWED: { label: 'mall.marketing.noAllowFollowingGoodsUse', value: 2 },
    SOME_GOODS_CATE_ALLOWED: { label: 'mall.marketing.allowFollowGoodsCategoryUse', value: 3 }
  },
  // 优惠券发布状态 0-未发布 1-已发布 2-已下架
  MALL_COUPONS_RELEASE_STATUS: {
    FULL_CUT_COUPONS: { label: 'mall.marketing.noRelease', value: 0 },
    DISCOUNT_COUPONS: { label: 'mall.marketing.yesRelease', value: 1 },
    SOLD_OUT: { label: 'mall.marketing.soldOutEd', value: 2 }
  },
  // 限时特惠状态 1 未进行 2-进行中 3-已结束
  LIMIT_PERFER_STATUS: {
    DRAFT: { label: 'mall.perfer.draft', value: 0 },
    NOT_TO: { label: 'mall.perfer.notTo', value: 1 },
    ONGOING: { label: 'mall.perfer.ongoing', value: 2 },
    END: { label: 'mall.perfer.end', value: 3 }
  },
  ENTERPRISE_AUTHPRIZATION_TYPE: {
    // 授权范围
    DEFAULT: { label: 'common.default', value: -1 },
    NONE: { label: 'enterprise.apply.none', value: 0 },
    ALL: { label: 'dumall.config.allGoods', value: 1 },
    CUSTOM: { label: 'enterprise.apply.custom', value: 2 }
  },
  ENTERPRISE_ITEM_AUTHPRIZATION_TYPE: {
    // 产品授权类型
    PRODUCT_SORT: { label: 'enterprise.apply.assignedSort', value: 1 },
    PRODUCT_BRAND: { label: 'enterprise.apply.productBrand', value: 2 },
    PRODUCT: { label: 'enterprise.apply.assignedProduct', value: 3 },
    LALBEL: { label: 'mall.goods.salesLabel', value: 4 }
  },
  // 用户企业微信关联状态
  USER_QY_WECHAT_SYNC_STATUS: {
    NOT_RELATED: { label: 'user.notRelated', value: 0 },
    RELATED_SUCCESS: { label: 'user.relatedSuccess', value: 1 },
    RELATED_FAIL: { label: 'user.relatedFail', value: 2 }
  },
  // 快递支付方式
  EXPRESS_PAY_TYPE: {
    SHIPPER: { label: 'expressManage.payType.shipper', value: 1 },
    CONSIGNEE: { label: 'expressManage.payType.consignee', value: 2 },
    MONTHLY: { label: 'expressManage.payType.monthly', value: 3 },
    THIRD_PARTY: { label: 'expressManage.payType.thirdParty', value: 4 }
  },
  // 运费支付方式
  EXPRESS_FREIGHT_PAY_TYPE: {
    CASH_PAY: { label: 'expressManage.freightPayType.cashPay', value: 1 },
    ARRIVED_PAY: { label: 'expressManage.freightPayType.arrivedPay', value: 2 },
    MONTHLY_PAY: { label: 'expressManage.freightPayType.monthlyPay', value: 3 },
    THIRD_PAY: { label: 'expressManage.freightPayType.thirdPay', value: 4 }
  },
  // 快递订单状态
  EXPRESS_ORDER_STATUS: {
    FAIL: { label: 'expressManage.orderStatus.fail', value: -1 },
    WAIT: { label: 'expressManage.orderStatus.wait', value: 0 },
    SUCCESS: { label: 'expressManage.orderStatus.success', value: 1 },
    CANCEL: { label: 'expressManage.orderStatus.cancel', value: 2 }
  },
  // 快递电子面单业务类型
  EXPRESS_FACE_ORDER_TYPE: {
    SALES_DELIVERY: { label: 'route.salesDelivery', value: 1 },
    MALL_DELIVERY: { label: 'route.mallDelivery', value: 2 },
    EXCHANGE_CARD_ORDER: { label: 'route.exchangeCardOrder', value: 3 },
    EXCHANGE_VOUCHER_ORDER: { label: 'route.exchangeVoucherOrder', value: 4 }
  },
  ENTERPRISE_SYNC_TYPE: {
    // 互联 产品同步类型
    ALL: { label: 'enterpirse.apply.syncAllPrice', value: 1 },
    EMPTY_PRICE: { label: 'enterpirse.apply.syncEmptyPrice', value: 2 }
  },
  // 销售调价方案计算方式
  SALES_PRICE_SCHEME_ITEM_CALC_TYPE: {
    ADDITION: { label: '+', value: 1 },
    SUBTRACTION: { label: '-', value: 2 },
    MULTIPLICATION: { label: '*', value: 3 },
    DIVISION: { label: '/', value: 4 }
  },
  ADJUST_SALES_PRICE_RANGE: {
    ALL_PRODUCT: { label: 'sales.common.allProduct', value: 1 },
    QUERY_PRODUCT: { label: 'sales.common.queryProduct', value: 2 },
    SELECTED_PRODUCT: { label: 'sales.common.selectedProduct', value: 3 }
  },
  // 订单查询范围
  QUERY_ORDER_RANGE: {
    ALL_ORDER: { label: 'sales.common.allOrder', value: 1 },
    MY_ORDER: { label: 'sales.common.myOrder', value: 2 },
    ORG_USER_ORDER: { label: 'sales.common.orgUserOrder', value: 3 }
  },
  ENTERPRISE_ORDER_STATUS: {
    // 互联订单状态
    PENDING: { label: 'enterprise.order.pending', value: 0 },
    AUDITED: { label: 'enterprise.order.audited', value: 1 }
  },
  // 互联订单业务类型
  ENTERPRISE_ORDER_BUSINESS_TYPE: {
    PURCHASE_ORDER: { label: 'route.purchaseOrder', value: 1 },
    PURCHASE_OUT_ORDER: { label: 'route.purchaseOutOrder', value: 2 }
  },
  // 批量调整会员等级
  ADJUST_GRADE: {
    ALL_MEMBER: { label: 'member.allMember', value: 1 },
    QUERY_MEMBER: { label: 'member.queryMember', value: 2 },
    SELECTED_MEMBER: { label: 'member.selectedMember', value: 3 }
  },
  // 首页图表时间类型
  DATA_RANGE: {
    NOW: {
      value: 1,
      label: 'dashboard.salesPresentation.dateRange.now'
    },
    YESTERDAY: {
      value: 2,
      label: 'dashboard.salesPresentation.dateRange.yesterday'
    },
    CURRENT_WEEK: {
      value: 3,
      label: 'dashboard.salesPresentation.dateRange.currentWeek'
    },
    LAST_WEEK: {
      value: 4,
      label: 'dashboard.salesPresentation.dateRange.lastWeek'
    },
    CURRENT_MONTH: {
      value: 5,
      label: 'dashboard.salesPresentation.dateRange.currentMonth'
    },
    LAST_MONTH: {
      value: 6,
      label: 'dashboard.salesPresentation.dateRange.lastMonth'
    },
    CURRENT_QUARTER: {
      value: 7,
      label: 'dashboard.salesPresentation.dateRange.currentQuarter'
    },
    LAST_QUARTER: {
      value: 8,
      label: 'dashboard.salesPresentation.dateRange.lastQuarter'
    },
    CURRENT_YEAR: {
      value: 9,
      label: 'dashboard.salesPresentation.dateRange.currentYear'
    },
    LAST_YEAR: {
      value: 10,
      label: 'dashboard.salesPresentation.dateRange.lastYear'
    }
  },
  // 订单变更状态
  SALES_ORDER_ADJUST_STATUS: {
    DRAFT: { label: 'sales.common.draft', value: 1 },
    NOT_AUDIT: { label: 'sales.common.unVerify', value: 2 },
    AUDITED: { label: 'sales.common.verify', value: 3 },
    REJECTED: { label: 'sales.common.rejected', value: 4 }
  },
  // 订单变更状态
  SALES_DELIVERY_ADJUST_STATUS: {
    DRAFT: { label: 'sales.common.draft', value: 1 },
    NOT_AUDIT: { label: 'sales.common.unVerify', value: 2 },
    AUDITED: { label: 'sales.common.verify', value: 3 },
    REJECTED: { label: 'sales.common.rejected', value: 4 }
  },
  // 审核状态：0：未审核，1：审核中，2:审核通过，3审核失败
  LIVE_GOODS_AUDIT_STATUS: {
    NOT_AUDIT: { label: 'mall.goods.notAudit', value: 0 },
    AUDITING: { label: 'mall.goods.auditing', value: 1 },
    AUDITED: { label: 'mall.goods.audited', value: 2 },
    AUDIT_FAIL: { label: 'mall.goods.auditFail', value: 3 }
  },
  RELATED_STATUS: {
    NOT_RELATED: { label: 'common.notRelated', value: 0 },
    RELATED: { label: 'common.related', value: 1 }
  },
  PURCHASE_ORDER_ALTER_STATUS: {
    // 采购变更单状态
    PENDING: { label: 'purchase.orderAlter.pending', value: 0 },
    AUDITED: { label: 'purchase.orderAlter.audited', value: 1 },
    REJECT: { label: 'purchase.orderAlter.reject', value: -1 }
  },
  // 预收款业务类型
  ADVANCE_RECEIPT_LIMIT_TYPE: {
    PRODUCT: { label: 'finance.advanceReceiptType.product', value: 1 },
    PRODUCT_BRAND: { label: 'finance.advanceReceiptType.productBrand', value: 2 }
  },
  // 销售退货类型 退货、换货
  SALES_RETURN_TYPE: {
    RETURN_PRODUCT: { label: 'sales.common.returnProduct', value: 1 },
    EXCHANGE_PRODUCT: { label: 'sales.common.exchangeProduct', value: 2 }
  },
  // 销售退货资金处理方式： 转预收款、付款单付款
  SALES_RETURN_FUND_TYPE: {
    TO_ADVANCE_RECEIPT: { label: 'sales.common.toAdvanceReceipt', value: 1 },
    TO_PAYMENT: { label: 'sales.common.toPayment', value: 2 }
  },
  // 采购退货类型 退货、换货
  PURCHASE_RETURN_TYPE: {
    RETURN_PRODUCT: { label: 'sales.common.returnProduct', value: 1 },
    EXCHANGE_PRODUCT: { label: 'sales.common.exchangeProduct', value: 2 }
  },
  // 采购退货资金处理方式： 转预付款、收款单收款
  PURCHASE_RETURN_FUND_TYPE: {
    TO_ADVANCE_PAYABLE: { label: 'sales.common.toAdvancePayable', value: 1 },
    TO_COLLECTION: { label: 'sales.common.toCollection', value: 2 }
  },
  WEWORK_EXTERNAL_CONTACT_TYPE: {
    //  企业微信外部联系人类型
    WX: { label: 'crm.externalContact.wx', value: 1 },
    WEWORK: { label: 'crm.externalContact.wework', value: 2 }
  },
  // 礼盒方案状态 0草稿 1 已启用
  PLAN_GIFT_STATUS: {
    DRAFT: { label: 'mall.giftBox.draft', value: 0 },
    ENABLE: { label: 'mall.giftBox.enable', value: 1 }
  },
  // 运费类型 (0-无邮费 1-满额包邮 2-满量包邮)
  PLAN_GIFT_FREIGHT_TYPE: {
    NO_PACKAGE: { label: 'mall.giftBox.noPackage', value: 0 },
    PACKAGE_QUOTA: { label: 'mall.giftBox.packageQuota', value: 1 },
    PACKAGE_NUMBER: { label: 'mall.giftBox.packageNumber', value: 2 },
    SET_FREIGHT_TEMPLATE: { label: 'mall.giftBox.setFreightTemplat', value: 3 }
  },
  // 折扣配置 (0-无折扣 1-满额减 2-满额折 3-满量减)
  PLAN_GIFT_DISCOUNT_TYPE: {
    NO_DISCOUNT: { label: 'mall.giftBox.noDiscount', value: 0 },
    FULL_MINUS: { label: 'mall.giftBox.fullMinus', value: 1 },
    FULL_FOLD: { label: 'mall.giftBox.fullFold', value: 2 },
    FULL_AMOUNT_MINUS: { label: 'mall.giftBox.FullAmountMinus', value: 3 }
  },
  // 取整
  ROUND_METHOD: {
    UP_ROUND: { label: 'sales.common.upRound', value: 1 },
    DOWN_ROUND: { label: 'sales.common.downRound', value: 2 }
  },
  // 门户审核状态
  PORTAL_INVENTORY_AUTH: {
    ALL_PRODUCT: { label: 'portal.manage.allProduct', value: 0 },
    CUSTOM: { label: 'portal.manage.custom', value: 1 }
  },
  PURCHASE_REQUIRE_STATUS: {
    // 采购需求 状态
    DRAFT: { label: 'common.draft', value: 0 },
    PROCESSING: { label: 'purchase.outRequire.processing', value: 4 },
    FINISH: { label: 'purchase.outRequire.finish', value: 1 },
    END: { label: 'purchase.outRequire.end', value: 2 },
    TRASH: { label: 'purchase.outRequire.trash', value: 3 }
  },
  // 委外需求 业务类型
  PURCHASE_REQUIRE_BUSINESS_TYPE: {
    SALES_ORDER: { label: 'common.salesOrder', value: 1 },
    MALL_ORDER: { label: 'common.mallOrder', value: 2 },
    DISTRIBUTION_ORDER: { label: 'common.distributionOrder', value: 3 },
    WELFARE_EXCHANGE_VOUCHER_ORDER: { label: 'route.exchangeOrder', value: 4 },
    WELFARE_EXCHANGE_CARD_ORDER: { label: 'route.exchangeOrder', value: 5 }
  },
  // 查询日期类型
  QUERY_DATE_TYPE: {
    ORDER_DATE: { label: 'sales.order.orderDate', value: 1 },
    DELIVERY_DATE: { label: 'sales.order.deliveryDate', value: 2 },
    AUDIT_DATE: { label: 'sales.order.auditDate', value: 3 }
  },
  // 多维销售分析，查询条件类型
  MULTI_REPORT_QUERY_TYPE: {
    BY_OPERATOR: { label: 'sales.common.orderCollectionOperatorAmount', value: 1 },
    BY_CUSTOMER_MANAGER: { label: 'sales.common.orderCollectionCustomerManagerAmount', value: 2 }
  },
  DISTRIBUTION_B2C_APPLY_STATUS: {
    PENDING: { label: 'distribution.b2c.common.pending', value: 0 },
    AUDITED: { label: 'distribution.b2c.common.audited', value: 1 },
    REJECT: { label: 'distribution.b2c.common.reject', value: -1 }
  },
  // 会员等级升级类型 0-不自动升级 1-订单金额 2-订单数量 3-购买指定商品 4-支付金额
  MEMBER_LEVEL_UPGRADE_TYPE: {
    NO_UPGRADE: { label: 'member.noUpgrade', value: 0 },
    ORDER_AMOUNT: { label: 'member.orderAmount', value: 1 },
    ORDER_PAID_AMOUNT: { label: 'member.orderPaidAmount', value: 4 },
    ORDER_NUMBER: { label: 'member.orderNumber', value: 2 },
    BUY_SPECIFIED_GOODS: { label: 'member.buySpecifiedGoods', value: 3 }
  },
  // 有效期类型：1-永久有效 2-指定天数有效
  MEMBER_LEVEL_EXPIRATION_TYPE: {
    PERMANENT_VALIDITY: { label: 'member.permanentValidity', value: 1 },
    EFFECTIVE_DAYS: { label: 'member.effectiveDays', value: 2 }
  },
  // 会员折扣全息
  MEMBER_LEVEL_DISCOUNT_TYPE: {
    NO_DISCOUNT: { label: 'member.noDiscountSet', value: 0 },
    CUSTOM_DISCOUNT: { label: 'member.theCustom', value: 1 },
    PRICE_TYPE_DISCOUNT: { label: 'member.priceType', value: 2 }
  },
  // 会员折扣全息
  MEMBER_UPGRADE_REWARD_TYPE: {
    NO_REWARD: { label: 'member.noReward', value: 0 },
    POINT: { label: 'member.rewardPoint', value: 1 },
    COUPON: { label: 'member.rewardCoupon', value: 2 },
    VOUCHER: { label: 'member.rewardVoucher', value: 3 }
  },
  // 会员升级首单奖励
  MEMBER_UPGRADE_FIRST_REWARD_CONDITION: {
    NO_REWARD: { label: 'member.noReward', value: 0 },
    SPECIFIC_GOODS: { label: 'member.buySpecifiedGoods', value: 1 }
  },
  // 会员积分关联业务类型
  MEMBER_POINT_BUSINESS_TYPE: {
    POINT_RECHARGE: { label: 'member.integral.pointRecharge', value: 1 },
    MALL_ORDER: { label: 'member.integral.mallOrder', value: 2 },
    SALES_ORDER: { label: 'common.salesOrder', value: 3 }
  },
  // 会员积分交易类型
  MEMBER_POINT_TRANSACTION_TYPE: {
    PLATFORM_ADD: { label: 'member.integral.platformAdd', value: 1 },
    RECHARGE: { label: 'member.integral.recharge', value: 2 },
    UPGRADE: { label: 'member.integral.upgrade', value: 3 },
    MALL_ORDER: { label: 'member.integral.mallConsume', value: 4 },
    PAY_ORDER_REWARD: { label: 'member.integral.payOrderReward', value: 5 },
    UPGRADE_FIRST_ORDER: { label: 'member.integral.upgradeFirstOrder', value: 6 },
    OTHER_MEMBER_GIVE: { label: 'member.integral.otherMemberGive', value: 7 },
    DISTRIBUTION_REWARD: { label: 'member.integral.distributionReward', value: 8 },
    ORDER_REFUND_RETURN: { label: 'member.integral.orderRefundReturn', value: 9 },
    MEMBER_POINT_TRANSFER_CUSTOMER: { label: 'member.integral.memberPointTransferCustomer', value: 10 }
  },
  INVENTORY_LOCK_EFFECT_TYPE: {
    DIRECT: { label: 'sales.inventoryLock.direct', value: 0 },
    ASSIGN_DATE: { label: 'sales.inventoryLock.assignDate', value: 1 },
    DELIVEY_ON: { label: 'sales.inventoryLock.deliveryOn', value: 2 }
  },
  // 销售锁定库存状态
  INVENTORY_LOCK_STATUS: {
    DRAFT: { label: 'sales.inventoryLock.draft', value: 0 },
    PENDING: { label: 'sales.inventoryLock.pending', value: 1 },
    AUIDTED: { label: 'sales.inventoryLock.audited', value: 2 },
    EFFECTED: { label: 'sales.inventoryLock.effected', value: 3 },
    UNLOCKED: { label: 'sales.inventoryLock.unlocked', value: 4 }
  },
  // 产品白名单  （1-全部产品，2-指定产品，3-产品分类）
  PROMOTION_PRODUCT_TYPE: {
    ALL_PRODUCT: { label: 'sales.promotion.allProduct', value: 1 },
    THE_PRODUCT: { label: 'sales.promotion.theProduct', value: 2 },
    PRODUCT_SORT: { label: 'sales.promotion.productSort', value: 3 }
  },
  // 关联类型 1-指定客户 2-白名单指定产品 3-白名单产品分类  4-黑名单指定产品 5-黑名单产品分类
  PROMOTION_CORRELATION_TYPE: {
    THE_CUSTOMER: { label: 'sales.promotion.theCustomSort', value: 1 },
    WHITE_THE_PRODUCT: { label: 'sales.promotion.theProduct', value: 2 },
    WHITE_PRODUCT_SORT: { label: 'sales.promotion.productSort', value: 3 },
    BLACK_THE_PRODUCT: { label: 'sales.promotion.theProduct', value: 4 },
    BLACK_PRODUCT_SORT: { label: 'sales.promotion.productSort', value: 5 }
  },
  // 对账单订单类型
  SALES_STATEMENT_ORDER_TYPE: {
    SALES_ORDER: { label: 'sales.statement.salesOrderType', value: 1 },
    MALL_ORDER: { label: 'sales.statement.mallOrderType', value: 2 }
  },
  PRODUCT_ENABLED_STATUS: {
    // 产品启用状态 1-启用；0-不启用
    ENABLED: { label: 'common.enabled', value: 1 },
    DISENABLED: { label: 'common.disabled', value: 0 }
  },
  // 模板审核状态，0：待审核，1：审核通过，-1:审核不通过
  SMS_TEMPLATE_STATUS: {
    TO_AUDIT: { label: 'message.template.toAudit', value: 0 },
    AUDIT_SUCCESS: { label: 'message.template.auditSuccess', value: 1 },
    AUDIT_ERROR: { label: 'message.template.auditError', value: -1 }
  },
  // * 短信任务状态-1. 终止发送0. 等待发送 1. 发送完成
  SMS_TASK_STATUS: {
    END_SEND: { label: 'message.send.endSend', value: -1 },
    WAIT_SEND: { label: 'message.send.waitSend', value: 0 },
    COMPLETE_SEND: { label: 'message.send.completeSend', value: 1 }
  },
  // 短信发送记录发送状态 0. 待发送 1. 等待回执 2. 发送成功 -1. 发送失败
  SMS_TASK_DETAIL_STATUS: {
    SEND_FAIL: { label: 'message.send.sendFail', value: -1 },
    WAIT_SEND: { label: 'message.send.toSend', value: 0 },
    WAITE_RECEIPT: { label: 'message.send.waitingReceipt', value: 1 },
    SEND_SUCCESS: { label: 'message.send.sendSuccess', value: 2 }
  },
  // 消息发送任务类型
  SMS_TASK_TYPE: {
    MESSAGE: { label: 'route.messageService', value: 'message' },
    THIRD_PART_ORDER: { label: 'route.thirdPartOrder', value: 'thirdPart' },
    CARD_EXCHANGE: { label: 'route.cardExchange', value: 'cardExchange' }
  },
  // 佣金计算规则
  COMMISSION_CALC_METHOD: {
    BY_ORDER_AMOUNT: { label: 'crm.config.byOrderAmount', value: 1 },
    BY_PRICE_DIFFERENCE: { label: 'crm.config.byPriceDifference', value: 2 }
  },
  // 对账单状态
  SALES_RECONCILIATION_STATUS: {
    TO_BE_REVIEW: { label: 'sales.reconciliation.toBeReview', value: 1 },
    TO_BE_CONFIRMED: { label: 'sales.reconciliation.toBeConfirmed', value: 2 },
    CONFIRMED_BY_CUSTOMER: { label: 'sales.reconciliation.confirmedByCustomer', value: 3 },
    CONFIRMED_AUTO: { label: 'sales.reconciliation.confirmedAuto', value: 4 }
  },
  // 业务流水业务类型
  BUSINESS_FLOW_BUSINESS_TYPE: {
    SALES_ORDER: { label: 'sales.reconciliation.salesOrder', value: 1 },
    SALES_ORDER_ADJUST: { label: 'sales.reconciliation.salesOrderAdjust', value: 2 },
    SALES_RETURN: { label: 'sales.reconciliation.salesReturn', value: 3 },
    MALL_ORDER: { label: 'sales.reconciliation.mallOrder', value: 4 },
    MALL_RETURN: { label: 'sales.reconciliation.mallReturn', value: 5 },
    COLLECTION: { label: 'sales.reconciliation.collection', value: 6 },
    PAYMENT: { label: 'sales.reconciliation.payment', value: 7 },
    ADVANCE_RECEIPT: { label: 'sales.reconciliation.advanceReceipt', value: 8 },
    DEPOSIT_COLLECTION: { label: 'sales.reconciliation.depositCollection', value: 9 },
    DEPOSIT_PAYMENT: { label: 'sales.reconciliation.depositPayment', value: 10 },
    MALL_RECHARGE: { label: 'sales.reconciliation.mallRecharge', value: 11 }
  },
  // 福利方案状态
  WELFARE_EXCHANGES_STATUS: {
    DRAFT: { label: 'welfare.draft', value: 1 },
    // RELEASE: { label: 'welfare.release', value: 2 },
    OFFLINE: { label: 'welfare.offlineed', value: 3 },
    WAIT_START: { label: 'welfare.waitStart', value: 4 },
    ONGOING: { label: 'welfare.ongoing', value: 5 },
    ENDED: { label: 'welfare.ended', value: 6 }
  },
  // 福利卡片小程序生成状态
  WELFARE_EXCHANGES_APP_CODE_STATUS: {
    NOT_GENERATE: { label: 'welfare.notGenerate', value: 0 },
    IN_GENERATE: { label: 'welfare.inGenerate', value: 1 },
    RENERATE_FAIL: { label: 'welfare.generateFail', value: -1 }
    // ALL: { label: 'welfare.all', value: '' }
  },
  // 福利卡片类型
  WELFARE_EXCHANGES_CARD_TYPE: {
    EXCHANGE_CARD: { label: 'welfare.exchangeCard', value: 1 },
    VOUCHER_CARD: { label: 'welfare.voucherCard', value: 2 }
  },
  // 福利卡片兑换状态
  WELFARE_EXCHANGES_CARD_STATUS: {
    DRAFT: { label: 'welfare.hasChange', value: true },
    OFFLINE: { label: 'welfare.noChange', value: false }
  },
  // 卡券订单类型
  WELFARE_CARD_TYPE: {
    EXCHANGE: { label: 'welfare.exchangeCardOrderOrder', value: 1 },
    VOUCHER: { label: 'welfare.exchangeVoucherOrderOrder', value: 2 }
  },
  // 卡券兑换订单状态
  WELFARE_EXCHANGE_ORDER_STATUS: {
    NOT_PREPARED: { label: 'welfare.order.notPrepared', value: 1 },
    CONFIRMED: { label: 'welfare.order.waitDelivery', value: 2 },
    DELIVERED: { label: 'welfare.order.delivered', value: 3 },
    COMPLETED: { label: 'welfare.order.completed', value: 4 },
    NOT_PAID: { label: 'welfare.order.notPaid', value: 5 },
    REFUNDED: { label: 'welfare.order.refunded', value: 6 },
    CANCELED: { label: 'welfare.order.canceled', value: 7 },
    PART_DELIVERED: { label: 'order.partDelivery', value: 8 }
  },
  // 福利卡片退货类型
  WELFARE_RETURN_TYPE: {
    CANCEL_ORDER: { label: 'welfare.cancelOrder', value: 1 },
    ORDER_RETURN: { label: 'welfare.orderReturn', value: 2 }
  },
  // 福利卡片退货类型
  WELFARE_RETURN_STATUS: {
    NOT_AUDIT: { label: 'welfare.notAudit', value: 1 },
    REFUNDED: { label: 'welfare.refunded', value: 2 },
    REJECTED: { label: 'welfare.rejected', value: 3 }
  },
  // 福利兑换订单发货方式
  WELFARE_DELIVERY_TYPE: {
    NOT_INVENTORY_OUT: { label: 'welfare.notInventoryOut', value: 0 },
    INVENTORY_OUT: { label: 'welfare.inventoryOut', value: 1 }
    // PURCHASE_OUT: { label: 'welfare.purchaseOut', value: 2 }
  },
  RETURN_GOODS_STATUS: {
    NO_RECEIVED_GOODS: { label: 'order.noReceivedGoods', value: 1 },
    RECEIVED_GOODS: { label: 'order.receivedGoods', value: 2 }
  },
  // 浏览记录类型1
  MALL_BROWSE_RECORD_TYPE: {
    GOODS: { label: 'mall.browseRecord.goods', value: 1 },
    POSTER: { label: 'mall.browseRecord.poster', value: 2 }
  },
  // 三方订单平台
  THIRD_PART_ORDER_PLATFORM: {
    GUANAITONG: { label: 'thirdPartOrder.guanaitong', value: 1 }
  },
  // 三方订单，系统订单状态
  THIRD_PART_ORDER_SYS_STATUS: {
    NOT_PREPARE: { label: 'thirdPartOrder.notPrepare', value: 1 },
    NOT_DELIVERY: { label: 'thirdPartOrder.notDelivery', value: 2 },
    DELIVERED: { label: 'thirdPartOrder.delivered', value: 3 },
    COMPLETED: { label: 'thirdPartOrder.completed', value: 4 },
    CLOSED: { label: 'thirdPartOrder.closed', value: 5 }
  },
  // 三方订单，超时类型
  THIRD_PART_ORDER_OVERTIME_TYPE: {
    NOT_PREPARE: { label: 'thirdPartOrder.notOvertime', value: 0 },
    NOT_DELIVERY: { label: 'thirdPartOrder.notDelivery', value: 1 },
    DELIVERD: { label: 'thirdPartOrder.delivered', value: 2 }
  },
  // 导入状态
  THIRD_PART_ORDER_IMPORT_STATUS: {
    IMPORTING: { label: 'thirdPartOrder.importing', value: 1 },
    COMPLETED: { label: 'thirdPartOrder.importCompleted', value: 2 },
    ERROR: { label: 'thirdPartOrder.importError', value: 3 }
  },
  // 佣金提取方式
  FINANCE_COMMISSION_EXTRACT_TYPE: {
    TO_ADVANCE_RECEIPT: { label: 'finance.commission.toAdvanceReceipt', value: 1 },
    TO_CASH: { label: 'finance.commission.toCash', value: 2 },
    TO_BANK_CARD: { label: 'crm.performanceRebate.toBankCard', value: 3 }
  },
  // 佣金提取状态
  FINANCE_COMMISSION_EXTRACT_STATUS: {
    NOT_AUDIT: { label: 'common.notAudit', value: 1 },
    AUDITED: { label: 'common.audited', value: 2 },
    REJECTED: { label: 'common.rejected', value: 3 }
  },
  // 业绩返点设置审核状态
  REBATE_CONFIG_AUDIT_STATUS: {
    DRAFT: { label: 'common.draft', value: 1 },
    NOT_AUDIT: { label: 'common.notAudit', value: 2 },
    AUDITED: { label: 'common.audited', value: 3 },
    CANCELED: { label: 'common.canceled', value: 4 },
    ONGOING: { label: 'common.ongoing', value: 5 },
    IN_SETTLEMENT: { label: 'common.inSettlement', value: 6 },
    COMPLETED: { label: 'common.completed', value: 7 }
  },
  // 返点提现
  PERFORMANCE_REBATE_EXTRACT_STATUS: {
    NOT_AUDIT: { label: 'common.notAudit', value: 1 },
    AUDITED: { label: 'common.audited', value: 2 },
    REJECTED: { label: 'common.rejected', value: 3 }
  },
  // 返点提现方式
  PERFORMANCE_REBATE_EXTRACT_TYPE: {
    TO_BANK_CARD: { label: 'crm.performanceRebate.toBankCard', value: 1 },
    TO_ADVANCE_RECEIPT: { label: 'crm.performanceRebate.toAdvanceReceipt', value: 2 }
  },
  // 核销订单状态
  WELFARE_CARD_ACTIVATE_STATUS: {
    NOT_ENABLE: { label: 'welfare.activate.notEnable', value: 1 },
    ENABLED: { label: 'welfare.activate.enabled', value: 2 }
  },
  // 校验设置
  WELFARE_CARD_ACTIVATE_CHECK_CONFIG: {
    NOT_CHECK: { label: 'welfare.activate.notCheck', value: 0 },
    CHECK_AFTER_CODE_SCANNING: { label: 'welfare.activate.checkAfterCodeScanning', value: 1 },
    CHECK_AFTER_ORDERED: { label: 'welfare.activate.checkAfterOrdered', value: 2 }
  },
  // 核销短信设置
  WELFARE_CARD_ACTIVATE_SMS_CONFIG: {
    NOT_SEND: { label: 'welfare.activate.notSend', value: 0 },
    SAME_AS_PLAN: { label: 'welfare.activate.sameAsPlan', value: 1 },
    SELF_CONFIG: { label: 'welfare.activate.selfConfig', value: 2 }
  },
  // 导入任务状态
  IMPORT_TASK_STATUS: {
    WAITING: { label: 'portal.distributionOrder.waiting', value: 0 },
    IMPORTING: { label: 'portal.distributionOrder.importing', value: 1 },
    IMPORT_COMPLETED: { label: 'portal.distributionOrder.importCompleted', value: 2 },
    IMPORT_FAIL: { label: 'portal.distributionOrder.importFail', value: 3 },
    PORTAL_SUBMIT: { label: 'portal.distributionOrder.portalSubmit', value: 4 },
    TENANT_CONFIRM: { label: 'portal.distributionOrder.tenantConfirm', value: 5 },
    PORTAL_CANCELED: { label: 'portal.distributionOrder.portalCanceled', value: 6 },
    TENANT_DELIVERY: { label: 'portal.distributionOrder.tenantDelivery', value: 7 },
    COMPLETED: { label: 'portal.distributionOrder.completed', value: 8 }
  },
  // 任务明细导入状态
  IMPORT_TASK_RECORD_STATUS: {
    WAITING: { label: 'portal.distributionOrder.waiting', value: 0 },
    SUCCESS: { label: 'portal.distributionOrder.importSuccess', value: 1 },
    FAIL: { label: 'portal.distributionOrder.importFail', value: -1 }
  },
  // 代发订单状态
  DISTRIBUTION_ORDER_STATUS: {
    NOT_CONFIRM: { label: 'portal.distributionOrder.notConfirm', value: 1 },
    NOT_DELIVERY: { label: 'portal.distributionOrder.notDelivery', value: 2 },
    DELIVERED: { label: 'portal.distributionOrder.delivered', value: 3 },
    COMPLETED: { label: 'portal.distributionOrder.completed', value: 4 }
  },
  // 销售机会状态
  SALES_OPPORTUNITY_STATUS: {
    CREATED: { label: 'sales.opportunity.created', value: 1 },
    FOLLOWING_UP: { label: 'sales.opportunity.followingUp', value: 2 },
    TO_SALES_ORDER: { label: 'sales.opportunity.toSalesOrder', value: 3 },
    COMPLETED: { label: 'sales.opportunity.completed', value: 4 }
  },
  // 销售机会开票要求
  SALES_OPPORTUNITY_INVOICE_TYPE: {
    NON_INVOICING: { label: 'sales.opportunity.nonInvoicing', value: 0 },
    SAMLL_SCALE: { label: 'sales.opportunity.smallScale', value: 1 },
    GENERAL_TAXPAYER: { label: 'sales.opportunity.generalTaxpayer', value: 2 }
  },
  // 销售机会发货方式
  SALES_OPPORTUNITY_DELIVERY_TYPE: {
    CENTRALIZED: { label: 'sales.opportunity.centralizedDelivery', value: 0 },
    ONE_PIECE_DELIVERY: { label: 'sales.opportunity.onePieceDelivery', value: 1 },
    OTHER: { label: 'sales.opportunity.otherDelivery', value: 2 }
  },
  // 客户
  CRM_FOLLOW_UP_BUSINESS_TYPE: {
    SALES_OPPORTUNITY: { label: 'route.salesOpportunity', value: 1 }
  },
  // 代金券类型
  MALL_VOUCHER_TYPE: {
    ONE_TIME: { label: 'mall.marketing.oneTime', value: 1 },
    MORE_TIME: { label: 'mall.marketing.moreTime', value: 2 },
    LIMIT_TIME: { label: 'mall.marketing.limitTime', value: 3 }
  },
  // 代金券使用期限
  MALL_VOUCHER_USAGE_TIME: {
    NO_LIMIT: { label: 'mall.marketing.noLimit', value: 0 },
    GET_DAY_AFTER: { label: 'mall.marketing.getDayAfter', value: 1 },
    DATE_RANGE: { label: 'mall.marketing.dateRange', value: 2 }
  },
  // 代金券商品使用限制：0全部商品、1允许以下商品使用、2不允许以下商品使用、3允许以下商品分类使用
  MALL_VOUCHER_GOODS_LIMIT: {
    ALL_GOODS_ALLOWED: { label: 'mall.marketing.allGoods', value: 0 },
    SOME_GOODS_ALLOWED: { label: 'mall.marketing.allowFollowingGoodsUse', value: 1 },
    SOME_GOODS_NOT_ALLOWED: { label: 'mall.marketing.noAllowFollowingGoodsUse', value: 2 },
    SOME_GOODS_CATE_ALLOWED: { label: 'mall.marketing.allowFollowGoodsCategoryUse', value: 3 }
  },
  // 代金券关联类型
  MALL_VOUCHER_CORRELATION_TYPE: {
    GOODS: { label: 'mall.marketing.goods', value: 1 },
    GOODS_CATEGORY: { label: 'mall.marketing.goodsCategory', value: 2 },
    CUSTOMER_SORT: { label: 'common.customerSort', value: 3 },
    MEMBER_LEVEL: { label: 'common.memberLevel', value: 4 }
  },
  // 咨询关联类型
  MALL_NEWS_CORRELATION_TYPE: {
    CUSTOMER_SORT: { label: 'common.customerSort', value: 1 },
    MEMBER_LEVEL: { label: 'common.memberLevel', value: 2 }
  },
  // 库存借调状态
  INVENTORY_SECONDMENT_STATUS: {
    DRAFT: { label: 'common.draft', value: 0 },
    NOT_AUDIT: { label: 'common.notAudit', value: 1 },
    PURCHASE_AUDIT: { label: 'common.audited', value: 2 },
    NOT_INVENTORY_OUT: { label: 'common.notInventoryOut', value: 3 },
    NOT_INVENTORY_IN: { label: 'common.notInventoryIn', value: 4 },
    COMPLETED: { label: 'common.completed', value: 5 },
    REJECTD: { label: 'common.rejected', value: 6 }
  },
  // 包邮规则作用对象 0-所有 1-客户 2-会员
  MALL_PACKAGE_RULE_ACTIVE_TYPE: {
    ALL: { label: 'mall.packageRuleConfig.all', value: 0 },
    CUSTOMER: { label: 'mall.packageRuleConfig.customer', value: 1 },
    MEMBER: { label: 'mall.packageRuleConfig.member', value: 2 }
  },
  // 包邮规则作用范围 0-所有 1-全部客户 2-指定客户分类 3-指定客户 4-全部会员 5-指定会员等级 6-指定会员
  MALL_PACKAGE_RULE_RANGE_TYPE: {
    ALL: { label: 'mall.packageRuleConfig.all', value: 0 },
    ALL_CUSTOMER: { label: 'mall.packageRuleConfig.allCustomer', value: 1 },
    CUSTOMER_CATEGORY: { label: 'mall.packageRuleConfig.appointCustomerCategory', value: 2 },
    CUSTOMER: { label: 'mall.packageRuleConfig.appointCustomer', value: 3 },
    ALL_MEMBER: { label: 'mall.packageRuleConfig.allMember', value: 4 },
    MEMBER_LEVEL: { label: 'mall.packageRuleConfig.appointMemberLevel', value: 5 },
    MEMBER: { label: 'mall.packageRuleConfig.appointMember', value: 6 }
  },
  // 交货方式
  INVENTORY_DELIVERY_TYPE: {
    EXPRESS: { label: '快递发货', value: 1 },
    SELF_PICKUP: { label: '自主提货', value: 2 },
    LOGISTIC: { label: '物流发货', value: 3 },
    HUOLALA: { label: '货拉拉', value: 4 },
    CITY_DIRECT: { label: '同城闪送', value: 5 }
  },
  // 例外日期维度类型
  DIMENSION_TYPE: {
    IS_YEAR: { label: 'inventory.deliveryType.year', value: 1 },
    MONTH: { label: 'inventory.deliveryType.month', value: 2 },
    DAY: { label: 'inventory.deliveryType.day', value: 3 },
    WEEK: { label: 'inventory.deliveryType.week', value: 4 },
    INTERVAL: { label: 'inventory.deliveryType.interval', value: 5 }
  },
  // 星期
  ALL_MONTH: {
    EVERY_MONDAY: { label: 'inventory.deliveryType.everyMonday', value: 1 },
    EVERY_TUESDAY: { label: 'inventory.deliveryType.everyTuesday', value: 2 },
    EVERY_WEDNESDAY: { label: 'inventory.deliveryType.everyWednesday', value: 3 },
    EVERY_THURSDAY: { label: 'inventory.deliveryType.everyThursday', value: 4 },
    EVERY_FRIDAY: { label: 'inventory.deliveryType.everyFriday', value: 5 },
    EVERY_SATURDAY: { label: 'inventory.deliveryType.everySaturday', value: 6 },
    EVERY_SUNDAY: { label: 'inventory.deliveryType.everySunday', value: 7 }
  },
  // 批量更新协作人添加模式
  CUSTOMER_TRANSFER_ADD_MODE: {
    APPEND: { label: 'crm.customer.append', value: 1 },
    COVER: { label: 'crm.customer.cover', value: 2 }
  },
  // 商城商品管理批量调整
  BATCH_ADJUSTMENT: {
    ADJUST_CATEGORY: { label: 'mall.goods.adjustCategory', value: 1 },
    BATCH_FREIGHT_TEMPLATE_SET: { label: 'mall.goods.batchFreightTemplateSet', value: 2 },
    BATCH_QUANTITY_CONFIG_INFO: { label: 'mall.goods.batchQuantityConfigInfo', value: 3 },
    BATCH_PRESALE: { label: 'mall.goods.batchPresale', value: 4 },
    BATCH_IS_OUT_DELIVERY: { label: 'mall.goods.adjustIsOutDelivery', value: 5 },
    ADJUST_DELIVERY_TYPE: { label: 'mall.goods.adjustDeliveryType', value: 6 }
  },
  // 仓库分仓规则
  ENABLE_STOCK_SPLIT_WAREHOUSE_RULES: {
    BY_WAREHOUSE_SORT: { label: 'inventory.config.byWarehouseSort', value: 1 },
    BY_ENABLE_STOCK: { label: 'inventory.config.byEnableStock', value: 2 }
  },
  // 经营有数-项目数据范围
  BUSINESS_ANALYSIS_PROJECT_DATA_RANGE: {
    ALL: { label: 'businessAnalysis.digital.allData', value: 0 },
    CUSTOMER: { label: 'businessAnalysis.digital.customerData', value: 1 }
  },
  // 经营有数-项目可见范围
  BUSINESS_ANALYSIS_PROJECT_VISIBLE_TYPE: {
    IN_CUSTOMER_SORT: { label: 'businessAnalysis.digital.inCustomerSort', value: 1 },
    IN_CUSTOMER: { label: 'businessAnalysis.digital.inCustomer', value: 2 },
    OUT_CUSTOMER_SORT: { label: 'businessAnalysis.digital.outCustomerSort', value: 3 },
    OUT_CUSTOMER: { label: 'businessAnalysis.digital.outCustomer', value: 4 }
  },
  // 经营有数-项目分析状态
  BUSINESS_ANALYSIS_PROJECT_CALC_STATUS: {
    NONE: { label: 'businessAnalysis.digital.noCalc', value: 0 },
    PROCESSING: { label: 'businessAnalysis.digital.calcProcessing', value: 1 },
    COMPLETE: { label: 'businessAnalysis.digital.calcComplete', value: 2 },
    FAIL: { label: 'businessAnalysis.digital.calcFail', value: 3 }
  },
  // b2c主体类型
  B2C_SHOP_ORGANIZATION_TYPE: {
    SMALL_BUSINESSES: { label: 'distribution.b2c.common.smallBusinesses', value: '2401' },
    INDIVIDUAL_SELLERS: { label: 'distribution.b2c.common.individualSellers', value: '2500' },
    INDIVIDUAL_BUSINESS: { label: 'distribution.b2c.common.individualBusiness', value: '4' },
    ENTERPRISE: { label: 'distribution.b2c.common.enterprise', value: '2' },
    PARTY_AND_GOVERNMENT: { label: 'distribution.b2c.common.partyAndGovernment', value: '3' },
    OTHER_ORGANIZATIONS: { label: 'distribution.b2c.common.otherOrganizations', value: '1708' }
  },
  // 邀请人类型
  INVITATION_TYPE: {
    USER: { label: 'distribution.b2c.common.user', value: 0 },
    CUSTOMER: { label: 'distribution.b2c.common.customer', value: 1 }
  },
  // 使用价格类型
  PRICE_LEVEL: {
    REGULAR_PRICE: { label: 'sales.price.regularPrice', value: 0 },
    SPECIAL_PRICE: { label: 'sales.price.specialPrice', value: 1 },
    COST_PRICE: { label: 'sales.price.costPrice', value: 2 }
  },
  // 发票类型
  MALL_INVOICE_TYPE: {
    GENERAL: { label: 'invoice.generalInvoice', value: 1 },
    SPECIAL: { label: 'invoice.specialInvoice', value: 2 }
  },
  // 发票抬头类型
  MALL_INVOICE_TITLE_TYPE: {
    COMPANY: { label: 'invoice.companyInvoice', value: 1 },
    PERSONAL: { label: 'invoice.personalInvoice', value: 2 }
  },
  // 开票状态
  MALL_INVOICE_STATUS: {
    NOT_DEAL: { label: 'invoice.notDeal', value: 0 },
    INVOICED: { label: 'invoice.invoiced', value: 1 }
  },
  // 开票接收方式
  MALL_INVOICE_DELIVERY_TYPE: {
    DIGITAL: { label: 'invoice.digitalInvoice', value: 1 },
    PAPER: { label: 'invoice.paperInvoice', value: 2 }
  },
  // 开票接收方式
  MALL_INVOICE_BUSINESS_TYPE: {
    MALL_ORDER: { label: 'invoice.mallOrder', value: 1 },
    SALES_ORDER: { label: 'invoice.salesOrder', value: 2 }
  },
  // 发货进度查询
  QUERY_DELIVERY_RATE: {
    NOT_DELIVERY: { label: 'common.notDelivery', value: 1 },
    PART_DELIVERED: { label: 'common.partDelivered', value: 2 },
    COMPLETE_DELIVERY: { label: 'common.completeDelivery', value: 3 }
  },
  // 出库进度查询
  QUERY_INVENTORY_OUT_RATE: {
    NOT_INVENTORY: { label: 'common.notInventoryOutRate', value: 1 },
    PART_INVENTORY: { label: 'common.partInventoryOut', value: 2 },
    COMPLETE_INVENTORY: { label: 'common.completeInventoryOut', value: 3 }
  },
  // 出库进度查询
  QUERY_INVENTORY_IN_RATE: {
    NOT_INVENTORY: { label: 'common.notInventoryInRate', value: 1 },
    PART_INVENTORY: { label: 'common.partInventoryIn', value: 2 },
    COMPLETE_INVENTORY: { label: 'common.completeInventoryIn', value: 3 }
  },
  // 收款进度查询
  QUERY_FINANCE_COLLECTION_RATE: {
    NOT_COLLECTION: { label: 'common.notCollection', value: 1 },
    PART_COLLECTION: { label: 'common.partCollection', value: 2 },
    COMPLETE_COLLECTION: { label: 'common.completeCollection', value: 3 }
  },
  // 付款进度查询
  QUERY_FINANCE_PAYMENT_RATE: {
    NOT_PAYMENT: { label: 'common.notPayment', value: 1 },
    PART_PAYMENT: { label: 'common.partPayment', value: 2 },
    COMPLETE_PAYMENT: { label: 'common.completePayment', value: 3 }
  },
  // 订单下单奖励设置
  MALL_ORDER_REWARD_TYPE: {
    NONE: { label: 'mall.config.noneReward', value: 0 },
    VOUCHER: { label: 'mall.config.voucherReward', value: 1 }
  },
  // 订单下单奖励金额设置
  MALL_ORDER_REWARD_AMOUNT_TYPE: {
    STANDARD_AMOUNT: { label: 'mall.config.standardAmountReward', value: 1 },
    ORDER_AMOUNT_RATE: { label: 'mall.config.orderAmountRateReward', value: 2 }
  },
  // 订单下单奖励金额计算精度
  MALL_ORDER_REWARD_AMOUNT_PRECISION: {
    ONE: { label: 'mall.pointConfig.one', value: 1 },
    TEN: { label: 'mall.pointConfig.ten', value: 2 },
    HUNDRED: { label: 'mall.pointConfig.hundreds', value: 3 },
    THOUSAND: { label: 'mall.pointConfig.thousands', value: 4 }
  },
  // 订单下单奖励关联类型
  MALL_ORDER_REWARD_RELATED_TYPE: {
    CUSTOMER_SORT: { label: 'common.customerSort', value: 1 },
    MEMBER_LEVEL: { label: 'common.memberLevel', value: 2 }
  },
  // 商城商品查询排序
  MALL_COMPONENT_GOODS_ORDER_BY_TYPE: {
    DEFAULT: { label: 'fitment.default', value: 0 },
    PRICE_ASC: { label: 'fitment.priceAsc', value: 1 },
    PRICE_DESC: { label: 'fitment.priceDesc', value: 2 },
    SALES_QUANTITY_ASC: { label: 'fitment.salesQuantityAsc', value: 3 },
    SALES_QUANTITY_DESC: { label: 'fitment.salesQuantityDesc', value: 4 }
  },
  // 商城商品查询排序
  REPORT_STATEMENT_SUMMARY_TYPE: {
    BY_CUSTOMER: { label: 'sales.statement.byCustomer', value: 1 },
    BY_CUSTOMER_MANAGER: { label: 'sales.statement.byCustomerManager', value: 2 },
    BY_BUSINESS_MANAGER: { label: 'sales.statement.byBusinessManager', value: 3 }
  },
  // 门店营业状态
  STORE_BUSINESS_STATUS: {
    OPEN: { label: 'store.info.open', value: 1 },
    STOP: { label: 'store.info.stop', value: 2 }
  },
  // 门店类型
  STORE_TYPE: {
    SELF: { label: 'store.info.self', value: 1 },
    JOIN: { label: 'store.info.join', value: 2 }
  },
  // 门店订单支付方式
  STORE_ORDER_PAYMENT_TYPE: {
    CASH: { label: 'store.order.cashPay', value: 1 },
    ADVANCE_RECEIPT: { label: 'store.order.advanceReceiptPay', value: 2 },
    WECHAT_NATIVE: { label: 'store.order.wechatNativePay', value: 3 },
    WECHAT_SCAN: { label: 'store.order.wechatScanPay', value: 4 },
    MONTH_PAY: { label: 'store.order.monthPay', value: 5 }
  },
  // 门店订单状态
  STORE_ORDER_STATUS: {
    NOT_PAID: { label: 'store.order.notPaid', value: 1 },
    PART_PAID: { label: 'store.order.partPaid', value: 5 },
    PAID: { label: 'store.order.paid', value: 2 },
    CANCELED: { label: 'store.order.canceled', value: 3 },
    REFUNDED: { label: 'store.order.refuned', value: 4 }
  },
  // 门店订单支付方式
  STORE_BUYER_TYPE: {
    VISITOR: { label: 'store.order.visitor', value: 0 },
    MEMBER: { label: 'store.order.member', value: 1 },
    CUSTOMER: { label: 'store.order.customer', value: 2 }
  },
  // 会员代金券领取状态
  MEMBER_VOUCHER_RECEIVE_STATUS: {
    NOT_RECEIVE: { label: 'mall.memberVoucherShareRecord.notReceive', value: 0 },
    RECEIVE: { label: 'mall.memberVoucherShareRecord.receive', value: 1 },
    RECOVER: { label: 'mall.memberVoucherShareRecord.recover', value: 2 }
  },
  // 产品计价方式
  PRODUCT_PRICING_METHOD: {
    BY_QUANTITY: { label: 'product.pricingByQuantity', value: 0 },
    BY_WEIGHT: { label: 'product.pricingByWeight', value: 1 }
  },
  // 多地址拆分发货单方式
  MULTI_ADDRESS_SPLIT_DELIVERY_TYPE: {
    BY_ADDRESS: { label: 'portal.distributionOrder.splitDeliveryByAddress', value: 0 },
    BY_PRODUCT: { label: 'portal.distributionOrder.splitDeliveryByProduct', value: 1 },
    NONE: { label: 'portal.distributionOrder.splitDeliveryNone', value: 2 }
  },
  // 毛利订单类型
  SALES_PROFIT_BUSINESS_TYPE: {
    SALES_ORDER: { label: 'sales.profit.salesOrder', value: 1 },
    MALL_ORDER: { label: 'sales.profit.mallOrder', value: 2 },
    MALL_GIFT_ORDER: { label: 'sales.profit.mallGiftOrder', value: 3 }
  },
  // 客服类型
  KF_TYPE: {
    APPLET_KF: { label: 'mall.config.appletKf', value: 1 },
    WEWORK_KF: { label: 'mall.config.weworkKf', value: 2 }
  },
  // 积分商品上架状态 0-未上架 1-已上架
  MALL_POINT_GOODS_STATUS: {
    OFF_SHELF: { label: 'mall.marketing.soldOutEd', value: 0 },
    ON_SHELF: { label: 'mall.marketing.onShelfEd', value: 1 }
  },
  // 积分商品自定义使用范围 1-客户分类 2-会员等级
  MALL_POINT_GOODS_CORRELATION_TYPE: {
    CUSTOMER_SORT: { label: 'mall.marketing.customerSort', value: 1 },
    MEMBER_LEVEL: { label: 'mall.marketing.memberLevel', value: 2 }
  },
  // 积分商品客户兑换设置 0-不限制 1-客户分类可兑换 2-客户分类不可兑换
  MALL_POINT_GOODS_CUSTOMER_LIMIT_CONFIG: {
    NOT_LIMIT: { label: 'mall.marketing.notLimit', value: 0 },
    INCLUDE_CUSTOMER_SORT: { label: 'mall.marketing.includeCustomerSort', value: 1 },
    EXCLUDE_CUSTOMER_SORT: { label: 'mall.marketing.excludeCustomerSort', value: 2 }
  },
  // 积分商品运费设置 0-同商品设置 1-包邮
  MALL_POINT_GOODS_FREIGHT_CONFIG: {
    FOLLOW_GOODS: { label: 'mall.marketing.followGoods', value: 0 },
    NONE: { label: 'mall.marketing.none', value: 1 }
  },
  // 积分商品会员兑换设置 0-不限制 1-会员等级可兑换 2-会员等级不可兑换
  MALL_POINT_GOODS_MEMBER_LIMIT_CONFIG: {
    NOT_LIMIT: { label: 'mall.marketing.notLimit', value: 0 },
    INCLUDE_MEMBER_LEVEL: { label: 'mall.marketing.includeMemberLevel', value: 1 },
    EXCLUDE_MEMBER_LEVEL: { label: 'mall.marketing.excludeMemberLevel', value: 2 }
  },
  // 快递来源类型 1：销售订单, 2：商城订单，3：三方订单， 4：委外订单，5：兑换订单，6：发票，7：批量查询，99：手动添加/导入
  EXPRESS_SOURCE_TYPE: {
    SALES_ORDER: { label: 'expressManage.monitor.salesOrder', value: 1 },
    MALL_ORDER: { label: 'expressManage.monitor.mallOrder', value: 2 },
    THIRD_ORDER: { label: 'expressManage.monitor.thirdOrder', value: 3 },
    OUT_SOURCE_ORDER: { label: 'expressManage.monitor.outSourceOrder', value: 4 },
    EXCHANGE_ORDER: { label: 'expressManage.monitor.exchangeOrder', value: 5 },
    INVOICE: { label: 'expressManage.monitor.invoice', value: 6 },
    BATCH_QUERY: { label: 'expressManage.monitor.batchQuery', value: 7 },
    MANUALLY: { label: 'expressManage.monitor.manually', value: 99 },
  },
  // 兑换券商品首单必选设置
  WELFARE_REQUIRED_GOODS_CHECK_CONFIG: {
    PER_ORDER: { label: 'welfare.perOrderRequireGoodsCheck', value: 0 },
    FIREST_ORDER: { label: 'welfare.firstOrderRequireGoodsCheck', value: 1 }
  }
}
export default enumInfo
