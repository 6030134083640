import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event'
import scrollbarWidth from 'element-ui/src/utils/scrollbar-width'
import { toObject } from 'element-ui/src/utils/util'
import Bar from './bar'

/* istanbul ignore next */
export default {
  name: 'CaracalScrollbar',

  components: { Bar },

  props: {
    native: {
      type: Boolean
    },
    wrapStyle: {},
    wrapClass: {},
    viewClass: {},
    viewStyle: {},
    showVertical: {
      type: Boolean,
      default: true
    },
    showHorizontal: {
      type: Boolean,
      default: true
    },
    noresize: {
      type: Boolean
    }, // 如果 container 尺寸不会发生变化，最好设置它可以优化性能
    tag: {
      type: String,
      default: 'div'
    }
  },

  data() {
    return {
      sizeWidth: '0',
      sizeHeight: '0',
      moveX: 0,
      moveY: 0
    }
  },

  computed: {
    wrap() {
      return this.$refs.wrap
    }
  },

  render(h) {
    const gutter = scrollbarWidth()
    let style = this.wrapStyle
    if (gutter) {
      const gutterWith = `-${gutter}px`
      const gutterStyle = `margin-bottom: ${gutterWith}; margin-right: ${gutterWith};`

      if (Array.isArray(this.wrapStyle)) {
        style = toObject(this.wrapStyle)
        style.marginRight = style.marginBottom = gutterWith
      } else if (typeof this.wrapStyle === 'string') {
        style = gutterStyle + style
      } else {
        style = gutterStyle
      }
    }
    if (!this.showHorizontal) {
      style = 'overflow-x: hidden !important;' + style
    }
    if (!this.showVertical) {
      style = 'overflow-y: hidden !important;' + style
    }

    const view = h(this.tag, {
      class: ['el-scrollbar__view', this.viewClass],
      style: this.viewStyle,
      ref: 'resize'
    }, this.$slots.default)
    const wrap = (
      <div
        ref='wrap'
        style={ style }
        onScroll={ this.handleScroll }
        class={ [this.wrapClass, 'el-scrollbar__wrap', gutter ? '' : 'el-scrollbar__wrap--hidden-default'] }>
        { [view] }
      </div>
    )
    let nodes

    if (!this.native) {
      const wrapNodes = [wrap]
      if (this.showHorizontal) {
        wrapNodes.push(<Bar
          move={ this.moveX }
          size={ this.sizeWidth }></Bar>)
      }

      if (this.showVertical) {
        wrapNodes.push(<Bar
          vertical
          move={ this.moveY }
          size={ this.sizeHeight }></Bar>)
      }
      nodes = (wrapNodes)
    } else {
      nodes = ([
        <div
          ref='wrap'
          class={ [this.wrapClass, 'el-scrollbar__wrap'] }
          style={ style }>
          { [view] }
        </div>
      ])
    }
    return h('div', { class: 'el-scrollbar' }, nodes)
  },

  methods: {
    handleScroll() {
      const wrap = this.wrap

      this.moveY = ((wrap.scrollTop * 100) / wrap.clientHeight)
      this.moveX = ((wrap.scrollLeft * 100) / wrap.clientWidth)
      this.$emit('scrollbarScroll', {
        scrollTop: wrap.scrollTop,
        scrollLeft: wrap.scrollLeft,
        moveY: this.moveY,
        moveX: this.moveX
      })
    },

    update() {
      const wrap = this.wrap
      if (!wrap) return

      const heightPercentage = (wrap.clientHeight * 100 / wrap.scrollHeight)
      const widthPercentage = (wrap.clientWidth * 100 / wrap.scrollWidth)

      this.sizeHeight = (heightPercentage < 100) ? (heightPercentage + '%') : ''
      this.sizeWidth = (widthPercentage < 100) ? (widthPercentage + '%') : ''
    },
    scrollToTop(scrollTop) {
      const wrap = this.wrap
      if (!wrap) return
      wrap.scrollTop = scrollTop
    }
  },

  mounted() {
    if (this.native) return
    this.$nextTick(this.update)
    if (!this.noresize) {
      addResizeListener(this.$refs.resize, this.update)
      addResizeListener(this.$refs.wrap, this.update)
    }
  },

  beforeDestroy() {
    if (this.native) return
    if (!this.noresize) {
      removeResizeListener(this.$refs.resize, this.update)
      removeResizeListener(this.$refs.wrap, this.update)
    }
  }
}
