import { getAllConfigList } from '@/api/purchase/common'
import store from '../index'
import { getChinaCountryId } from '@/utils/constant'

const state = {
  purchaseConfig: {
    defaultAddress: {
      countryId: getChinaCountryId(),
      areaIds: [],
      deliveryAddress: ''
    }
  }
}

const mutations = {
  SET_CONFIG: (state, purchaseConfig) => {
    if (purchaseConfig) {
      purchaseConfig.defaultAddress = mutations.SET_ADDRESS(purchaseConfig.defaultAddress)
      state.purchaseConfig = Object.assign({}, state.purchaseConfig, purchaseConfig)
    }
  },
  SET_ADDRESS: (purchaseConfig) => {
    if (purchaseConfig) {
      const data = JSON.parse(purchaseConfig)
      data.countryId = data.countryId || getChinaCountryId()
      return data
    } else {
      const object = {}
      object.countryId = getChinaCountryId()
      object.areaIds = []
      object.deliveryAddress = ''
      return object
    }
  },
  SET_CONFIG_ITEM: (state, item) => {
    if (item && item.configKey) {
      state.purchaseConfig[item.configKey] = item.configValue
    }
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getAllConfigList().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateConfigItem({ commit }, item) {
    return new Promise(() => {
      commit('SET_CONFIG_ITEM', item)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
