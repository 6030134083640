import request from '@/utils/request'

export function getAllConfigs() {
  return request({
    url: '/config/list',
    method: 'get'
  })
}

export function getWxAuthApps() {
  return request({
    url: '/config/wx_auth_app',
    method: 'get'
  })
}

export function saveConfig(data) {
  return request({
    url: '/config',
    method: 'post',
    data
  })
}

export function uploadSystemLogo(data) {
  return request({
    url: '/config/upload/logo',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function getWechatTags() {
  return request({
    url: '/config/wechat/tag',
    method: 'get'
  })
}

// 系统名称
export function getTenantSystemName() {
  return request({
    url: '/caracal/common/system-name',
    method: 'get'
  })
}
