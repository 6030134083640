<template>
  <el-input-number
    v-model="newValue"
    :clearable="true"
    size="small"
    v-bind="attrs"
    @input="handleChange"
  />
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableNumber',
  mixins: [EleEditableMixin]
}
</script>
