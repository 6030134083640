import request from '@/utils/request'

export function getAllConfigList() {
  return request({
    url: `/common/purchase-config/list`,
    method: 'get'
  })
}

export function getTaxRates() {
  return request({
    url: `/common/purchase/taxRates`,
    method: 'get'
  })
}

export function getPurchaseOrderList(data) {
  return request({
    url: '/common/purchase-order/page',
    method: 'post',
    data
  })
}

export function calPrintCount(id) {
  return request({
    url: `/common/purchase-order/print-count/${id}`,
    method: 'put'
  })
}

export function getPurchaseOrderById(id) {
  return request({
    url: `/common/purchase-order/${id}`,
    method: 'get'
  })
}
// 返回 已完成的采购订单
export function getFinishedPurchaseOrders(data) {
  return request({
    url: `/common/purchase/finished-order`,
    method: 'post',
    data
  })
}
// 收货单产品选择器
export function getPurchaseReceiptProductByReceiptId(data) {
  return request({
    url: `/common/purchase/receipt-product`,
    method: 'post',
    data
  })
}
// 收货单查询退货单
export function getReturnOrderByReceiptId(id) {
  return request({
    url: `/common/purchase/receipt-return/${id}`,
    method: 'get'
  })
}
// 收货单查询退货单
export function getReturnOrderProductsByReturnOrderId(id) {
  return request({
    url: `/common/purchase/return-product/${id}`,
    method: 'get'
  })
}
// 采购退货单（库存使用）
export function getReturnOrderList(data) {
  return request({
    url: `/common/purchase/return/list`,
    method: 'post',
    data
  })
}
// 采购退货单（库存使用）
export function getOutReturnOrderForPage(data) {
  return request({
    url: `/common/purchase/out-return/page`,
    method: 'post',
    data
  })
}
// 采购退货单（库存使用）
export function getReturnOrderById(id) {
  return request({
    url: `/common/purchase/return/${id}`,
    method: 'get'
  })
}
// 采购订单选择器
export function getPurchaseOrderSelect(data) {
  return request({
    url: `/common/purchase-order/select`,
    method: `post`,
    data
  })
}
// 采购收货单选择器
export function getPurchaseReceiptSelect(data) {
  return request({
    url: `/common/purchase-receipt/select`,
    method: `post`,
    data
  })
}
// 采购退货单选择器
export function getPurchaseReturnSelect(data) {
  return request({
    url: `/common/purchase-return/select`,
    method: `post`,
    data
  })
}
// 查看采购收货单
export function getPurchaseReceiptById(id) {
  return request({
    url: `/common/purchase/receipt/${id}`,
    method: `get`
  })
}
// 查看采购退货单
export function getPurchaseReturnById(id) {
  return request({
    url: `/common/purchase/return/${id}`,
    method: `get`
  })
}

// 查詢采购应付款
export function getFinancePayable(id) {
  return request({
    url: `/common/purchase/order-payable/${id}`,
    method: 'get'
  })
}
// 查询采购订单（库存采购收货使用）
export function inventoryGetPurchaseOrderForPgae(data) {
  return request({
    url: `/common/purchase/order/page`,
    method: 'post',
    data
  })
}
// 产品选择器+采购价格
export function purchaseProductPriceSelect(data) {
  return request({
    url: `/common/purchase/product-price/select`,
    method: 'post',
    data
  })
}
// 供应商联系人选择器
export function supplierContactSelect(data) {
  return request({
    url: `/common/purchase/supplier-contact/select`,
    method: 'post',
    data
  })
}

// 账户类型
export function getAccountTypes() {
  return request({
    url: '/common/purchase/account_types',
    method: 'get'
  })
}

// 供应商账户选择器
export function getSupplierAccountSelect(data) {
  return request({
    url: '/common/purchase/supplier-account/select',
    method: 'post',
    data
  })
}

// // 查询采购收货入库
// export function getPurchaseReceiptForPage(data) {
//   return request({
//     url: `/common/purchase-receipt/page`,
//     method: 'post',
//     data
//   })
// }

export function OutsourceSelect(data) {
  return request({
    url: `/common/purchase-outsource/select`,
    method: `post`,
    data
  })
}

export function OutsourceSelectWithBom(data) {
  return request({
    url: `/common/purchase-outsource/select/bom`,
    method: `post`,
    data
  })
}
// 采购计划选择器
export function getPurchasePlanSelectForPage(data) {
  return request({
    url: `/common/purchase/plan/select`,
    method: `post`,
    data
  })
}
// 请购单选择器
export function getPurchaseRequisitionSelectForPage(data) {
  return request({
    url: `/common/purchase/requisition/select`,
    method: `post`,
    data
  })
}
// 委外订单选择器
export function getPurchaseOrderOutSelectForPage(data) {
  return request({
    url: `/common/purchase/order-out/select`,
    method: `post`,
    data
  })
}
// 采购费用选择器
export function getPurchaseExpenseSelectForPage(data) {
  return request({
    url: `/common/purchase/expense/select`,
    method: `post`,
    data
  })
}

// 返回 采购订单产品单list 符合 出入库产品list
export function getInventoryProductInfosForPurchaseOrder(data) {
  return request({
    url: `/common/purchase/inventory-products`,
    method: `post`,
    data
  })
}

// 返回 采购退货产品单list 符合 出入库产品list
export function getInventoryProductInfosForReturnOrder(data) {
  return request({
    url: `/common/purchase/return/inventory-products`,
    method: `post`,
    data
  })
}

// 获取租户id
export function getTenantId() {
  return request({
    url: `/common/purchase/tenant`,
    method: `get`
  })
}
// 获取供应商
export function getSupplierByIds(data) {
  return request({
    url: `/common/purchase/supplier-list`,
    method: `post`,
    data
  })
}
// 采购退货产品选择器
export function getPurchaseReturnProductSelect(data) {
  return request({
    url: `/common/purchase/return-product/select`,
    method: `post`,
    data
  })
}
// 采购订单产品选择器
export function getPurchaseOrderProductSelect(data) {
  return request({
    url: `/common/purchase/order-product/select`,
    method: `post`,
    data
  })
}
// 委外订单产品选择器
export function getPurchaseOutOrderProductSelect(data) {
  return request({
    url: `/common/purchase/out-order-product/select`,
    method: `post`,
    data
  })
}
//  return map key:productId value:suppliers
export function getSupplierMapByProductIds(data) {
  return request({
    url: `/common/purchase/supplier-map`,
    method: `post`,
    data
  })
}

// 基础数据
export function getBasicData() {
  return request({
    url: `/common/purchase/basicData`,
    method: 'get'
  })
}

// 产品-关联业务-采购单
export function getPurchaseOrderInfosForPageByProductId(id, data) {
  return request({
    url: `/common/purchase/order-page/${id}`,
    method: 'post',
    data
  })
}

export function getPurchaseOutOrderInfosForPageByProductId(id, data) {
  return request({
    url: `/common/purchase/out-order-page/${id}`,
    method: 'post',
    data
  })
}
export function purchaseReturnHasFinanceReceivable(id) {
  return request({
    url: `/common/purchase/return/receivable/${id}`,
    method: 'get'
  })
}

// 查询采购价格
export function getPurchasePrice(data) {
  return request({
    url: `/common/purchase-price`,
    method: 'post',
    data
  })
}
