<template>
  <div class="drawer">
    <div :class="maskClass" @click="closeByMask" />
    <div :class="mainClass" :style="mainStyle" class="main">
      <div class="drawer-head">
        <span>{{ title }}</span>
        <span v-show="closable" class="close-btn" @click="closeByButton"><i class="el-icon-close" /></span>
      </div>
      <div class="drawer-body" :class="{ 'has-footer': showFooter }">
        <caracal-scrollbar class="draw-scrollbar" :show-horizontal="false">
          <div class="drawer-content">
            <slot />
          </div>
        </caracal-scrollbar>
      </div>
      <div v-if="showFooter" class="drawer-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 是否打开
    display: {
      type: Boolean
    },

    beforeClose: {
      type: Function,
      default: null
    },

    // 标题
    title: {
      type: String,
      default: ''
    },

    // 是否显示关闭按钮
    closable: {
      type: Boolean,
      default: true
    },

    // 是否显示遮罩
    mask: {
      type: Boolean,
      default: true
    },

    // 是否点击遮罩关闭
    maskClosable: {
      type: Boolean,
      default: true
    },

    // 宽度(支持百分比)
    width: {
      type: String,
      default: '400px'
    },

    // 是否在父级元素中打开
    inner: {
      type: Boolean,
      default: false
    },
    // 是否在AppContainer元素中打开
    innerAppContainer: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    maskClass: function() {
      return {
        'mask-show': (this.mask && this.display),
        'mask-hide': !(this.mask && this.display),
        'inner': this.inner || this.innerAppContainer
      }
    },
    mainClass: function() {
      return {
        'main-show': this.display,
        'main-hide': !this.display,
        'inner': this.inner || this.innerAppContainer
      }
    },
    mainStyle: function() {
      return {
        width: this.width,
        right: this.display ? '0' : `-${this.width}`,
        borderLeft: this.mask ? 'none' : '1px solid #eee'
      }
    }
  },
  mounted() {
    if (this.innerAppContainer) {
      const appContainer = this.dispatch(true)
      if (appContainer) {
        this.$el.setAttribute('data-drawer-move', '')
        let appContainerDrawerExist = true
        const appContainerChildNodeLength = appContainer.$el.childNodes.length
        // 过滤第一个滚动条节点
        if (appContainerChildNodeLength === 1) {
          appContainer.$el.appendChild(this.$el)
        } else {
          for (let m = 1; m < appContainerChildNodeLength; m++) {
            const appContainerChildNode = appContainer.$el.childNodes[m]
            if (this.isParent(appContainerChildNode, this)) {
              appContainerDrawerExist = false
              break
            }
          }
          if (!appContainerDrawerExist) {
            appContainer.$el.appendChild(this.$el)
          } else {
            let isInsertAppContainer = false
            for (let m = 1; m < appContainerChildNodeLength; m++) {
              const appContainerChildNode = appContainer.$el.childNodes[m]
              if (!appContainerChildNode.hasAttribute || !appContainerChildNode.hasAttribute('data-drawer-move')) {
                if (appContainer.$el && this.$el) {
                  appContainer.$el.insertBefore(this.$el, appContainerChildNode)
                }
                isInsertAppContainer = true
                break
              }
            }
            if (!isInsertAppContainer) {
              appContainer.$el.appendChild(this.$el)
            }
          }
        }

        // appContainer.$el.appendChild(this.$el)
        appContainer.$el.style.position = 'relative'
        return
      }
    } else if (this.inner) {
      const rootDrawer = this.dispatch(false)
      if (rootDrawer) {
        this.$el.setAttribute('data-drawer-move', '')
        let rootDrawerExist = true
        const rootDrawerChildNodeLength = rootDrawer.$parent.$el.childNodes.length
        for (let n = 0; n < rootDrawerChildNodeLength; n++) {
          const rootDrawerChildNode = rootDrawer.$parent.$el.childNodes[n]
          if (this.isParent(rootDrawerChildNode, this)) {
            rootDrawerExist = false
            break
          }
        }
        if (!rootDrawerExist) {
          rootDrawer.$parent.$el.appendChild(this.$el)
        } else {
          let isInsertDrawer = false
          for (let n = 0; n < rootDrawerChildNodeLength; n++) {
            const rootDrawerChildNode = rootDrawer.$parent.$el.childNodes[n]
            if (!rootDrawerChildNode.hasAttribute || !rootDrawerChildNode.hasAttribute('data-drawer-move')) {
              if (rootDrawer && rootDrawer.$parent && rootDrawer.$parent.$el && this.$el) {
                rootDrawer.$parent.$el.insertBefore(this.$el, rootDrawerChildNode)
                isInsertDrawer = true
              }
              break
            }
          }
          if (!isInsertDrawer) {
            rootDrawer.$parent.$el.appendChild(this.$el)
          }
        }

        // rootDrawer.$parent.$el.appendChild(this.$el)
      } else {
        const box = this.$el.parentNode
        box.style.position = 'relative'
      }
    }
  },
  methods: {
    closeByMask() {
      this.maskClosable
      if (this.maskClosable) {
        this.closeDrawer()
      }
    },
    closeByButton() {
      this.closeDrawer()
    },
    closeDrawer() {
      if (typeof this.beforeClose === 'function') {
        this.beforeClose(this.hide)
      } else {
        this.hide()
      }
    },
    hide(cancel) {
      if (cancel !== false) {
        this.$emit('update:display', false)
        this.$emit('close')
      }
    },
    isParent(parentEl, child) {
      if (!child || !parentEl) {
        return false
      }
      while (child) {
        if (child.$el === parentEl) {
          return true
        }
        child = child.$parent
      }
      return false
    },
    dispatch(appContainer) {
      var parent = this.$parent || this.$root
      let rootDrawer = null
      let componentName = ''
      while (parent) {
        if (parent) {
          componentName = parent.$options._componentTag
        }
        if (appContainer) {
          if (componentName && componentName.toLowerCase() === 'app-container') {
            rootDrawer = parent
          }
        } else {
          if (componentName && componentName.toLowerCase() === 'drawer') {
            rootDrawer = parent
          }
        }

        parent = parent.$parent
      }
      return rootDrawer
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  /* 遮罩 */
  .mask-show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1010;
    background-color: rgba(0,0,0,.5);
    opacity: 1;
    transition: opacity .5s;
  }
  .mask-hide {
    opacity: 0;
    transition: opacity .5s;
  }

  /* 滑块 */
  .main {
    position: fixed;
    z-index: 1010;
    top: 0;
    height: 100%;
    background: #fff;
    transition: all 0.5s;
  }
  .main-show {
    opacity: 1;
  }
  .main-hide {
    opacity: 0;
  }

  /* 其他样式 */
  .drawer-head {
    display: flex;
    justify-content: space-between;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    .close-btn {
      display: inline-block;
      cursor: pointer;
      height: 100%;
      padding-left: 20px;
    }
  }
  .drawer-body {
    height: calc(100vh - 45px);
    &.has-footer {
      height: calc(100vh - 45px - 84px);
    }
    .draw-scrollbar {
      height: 100%;
      .drawer-content {
        padding: 15px;
      }
    }
  }

  /* 某个元素内部显示 */
  .inner {
    position: absolute;
    .drawer-body {
      height: calc(100vh - 45px);
      &.has-footer {
        height: calc(100vh - 45px - 84px);
      }
    }
  }
  .drawer-footer {
    padding: 15px;
    height: 82px;
    border-top: 1px solid #eff2f6;
  }
}
.fixed-header{
  +.app-main {
    .drawer {
      .inner {
        .drawer-body {
          height: calc(100vh - 50px - 45px);
          &.has-footer {
            height: calc(100vh - 84px - 45px - 82px);
          }
        }
      }
    }
  }
}

.hasTagsView {
  .fixed-header{
    +.app-main {
      .drawer {
        .inner {
          .drawer-body {
            height: calc(100vh - 84px - 45px);
            &.has-footer {
              height: calc(100vh - 84px - 45px - 82px);
            }
          }
        }
      }
    }
  }
}
</style>
