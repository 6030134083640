export default {
  route: {
    login: '系統登錄',
    lock: '系統鎖屏',
    error: '系統錯誤',
    changePassword: '修改密碼',
    dashboard: '首頁',
    systemSetting: '系統設置',
    orgManagement: '組織機構',
    userManagement: '用戶管理',
    roleManagement: '角色管理',
    menuManagement: '功能表管理',
    syncToWework: '同步到企業微信',
    profile: '個人中心',
    add: '添加',
    edit: '編輯',
    delete: '刪除',
    audit: '審核',
    confirm: '確認',
    check: '查驗',
    assigned: '指派',
    start: '開始',
    stop: '暫停',
    cancel: '取消',
    finish: '完成',
    activation: '啟動',
    submit: '提交審核',
    revoke: '撤銷',
    invalid: '作廢',
    delay: '延期',
    pay: '付款',
    collect: '收款',
    details: '收支明細',
    adjust: '調整分類',
    adjustConfirm: '批量確認',
    setRole: '設置角色',
    configManagement: '參數設置',
    basicDataSetting: '基礎資料',
    customerManage: '客戶管理',
    basicDataCustomerLevel: '客戶級別',
    basicDataCustomerSource: '客戶來源',
    basicDataCustomerStatus: '客戶狀態',
    basicDataAddressType: '網址類別型',
    basicDataContactType: '連絡人類型',
    basicDataFollowUpType: '跟進類型',
    basicDataFollowUpWay: '跟進方式',
    basicDataIndustryType: '行業類型',
    basicDataVisitPurpose: '拜訪目的',
    productManager: '產品管理',
    productData: '產品資訊',
    productCategory: '產品分類',
    productUnit: '計價單位',
    productBrand: '產品品牌',
    financeManager: '財務管理',
    invoiceType: '開票類型',
    receivePayment: '收付款方式',
    settlementMethod: '結算方式',
    currencyType: '貨幣類型',
    taxRate: '稅率',
    accountType: '帳戶類型',
    financeAccount: '資金帳戶',
    financeDeposit: '存取款單',
    salesReceipt: '銷售回款',
    purchasePayment: '採購付款',
    financeReceivable: '應收款',
    financeAdvanceReceipt: '預收款',
    financeAdvancePayment: '預付款',
    financeApply: '付款申請',
    financePayable: '應付款',
    commonCustomer: '公共客戶',
    customerAssignment: '客戶分配',
    customerClaim: '客戶認領',
    customerTransfer: '客戶轉移',
    inventoryInType: '入庫類型',
    inventoryInStatus: '入庫狀態',
    salesManage: '銷售管理',
    basicDataDeliveryType: '交貨方式',
    salesQuotation: '報價單',
    salesInventoryLock: '鎖定庫存',
    salesOrder: '銷售訂單',
    salesDelivery: '銷售發貨',
    purchaseManage: '採購管理',
    basicDataSupplierType: '供應商類型',
    basicDataPurchaseDeliveryType: '送貨方式',
    purchaseSupplierManager: '供應商管理',
    supplierContact: '供應商連絡人',
    purchasePlan: '採購計畫',
    purchaseOrder: '採購訂單',
    purchaseOrderAlter: '采購變更單',
    purchaseReturn: '採購退貨',
    purchaseAccount: '採購對賬',
    purchasePay: '採購付款',
    purchaseInvoice: '採購發票',
    purchaseConfigSetting: '參數設置',
    inventoryManage: '庫存管理',
    warehouse: '倉庫管理',
    inventoryInNotification: '入庫通知',
    inventoryOutNotification: '出庫通知',
    inventoryIn: '入庫單',
    inventoryOut: '出庫單',
    inventoryAllocate: '調撥單',
    inventoryPack: '組裝/拆裝',
    purchaseReceipt: '採購收貨',
    saleDelivery: '銷售發貨',
    inventoryStock: '庫存餘額',
    inventoryDetails: '出入庫明細',
    inventoryCheck: '庫存盤點',
    inventoryPurchaseReturn: '採購退貨',
    inventorySalesReturn: '銷售退貨',
    inventoryFinishedProduct: '成品入庫',
    inventoryCargoValue: '庫存貨值',
    inventoryEnableStock: '可用庫存',
    inventoryWarning: '庫存預警',
    crmClue: '線索管理',
    clueFollowUp: '跟進',
    clueTransfer: '轉客戶',
    myCustomer: '我的客戶',
    contact: '連絡人',
    sysCodeManage: '編碼管理',
    customerFollowUp: '客戶跟進',
    customerVisit: '拜訪計畫',
    verify: '審核',
    quote: '報價',
    toOrder: '轉訂單',
    delivery: '發貨',
    collection: '收款',
    invoice: '開票',
    done: '完成',
    salesPresentation: '銷售簡報',
    scheduleCalendar: '日程日曆',
    payableAndReceivable: '應收應付款',
    performanceRanking: '業績排行',
    salesReturn: '銷售退貨',
    return: '退貨',
    salesOrderView: '銷售一覽表',
    salesDeliveryView: '銷售發貨一覽',
    salesOrderReport: '訂單金額統計',
    salesDeliveryReport: '發貨金額統計',
    financeReceivableTop: '客戶應收款餘額排行Top10',
    financePayableTop: '供應商應付款餘額排行Top10',
    salesOrderRecentAmountAndCount: '銷售訂單銷售額和訂單數（近6個月）',
    salesOrderProductTop: '產品銷售額排行Top10',
    salesOrderMonthOnYear: '銷售額月度同比',
    financeAccountBookMonthOnYear: '銷售回款金額月度同比',
    salesOrderCustomerAmount: '多維度銷售分析',
    invoiceManage: '發票服務',
    invConfig: '參數設置',
    vatInvoice: '增值稅發票',
    quotaInvoice: '定額發票',
    taxiInvoice: '計程車票',
    trainTicket: '火車發票',
    airTicket: '行程單發票',
    expenseType: '費用類型',
    salesExpense: '銷售費用',
    purchaseExpense: '採購費用',
    purchasePrice: '採購價格',
    purchasePriceType: '價格類型',
    salesPrice: '銷售價格',
    salesConfig: '參數設置',
    salesPriceType: '價格類型',
    salesGoal: '銷售目標',
    reportConfigSetting: '列印範本',
    customerAreaCount: '用戶端區域分佈',
    salesReport: '統計報表',
    purchaseReport: '統計報表',
    financeReport: '統計報表',
    paymentSummary: '收支匯總',
    purchaseOrderView: '採購訂單一覽',
    purchaseMonthlyReport: '訂單月度統計',
    postSaleManage: '售後管理',
    postSaleOrder: '售後訂單',
    serviceManage: '服務管理',
    serviceCategory: '服務分類',
    serviceOrder: '服務訂單',
    serviceItem: '服務專案',
    productionManage: '生產管理',
    productionBom: '生產BOM',
    productionPlan: '生產計畫',
    requisition: '請購單',
    purchaseOutRequire: '委外需求',
    productConfig: '參數設置',
    orderDeliverySummary: '銷售匯總',
    orderReceiptSummary: '採購匯總',
    scheduleManage: '日程管理',
    calendarSchedule: '日程日曆',
    basicDataScheduleType: '日程類型',
    manufacture: '生產',
    outSource: '委外',
    batchAudit: '批量審核',
    purchaseOrderOut: '委外訂單',
    todayCustomerFollowUp: '客戶跟進',
    inventoryAccount: '庫存台賬',
    projectManagement: '專案管理',
    basicDataGroup: '專案分組',
    projectList: '項目列表',
    projectListAdd: '創建項目',
    projectListSetting: '設置',
    projectListArchive: '歸檔',
    projectArchive: '歸檔項目',
    projectReactivation: '重新啟動',
    inventoryInOutsourcing: '委外入庫',
    inventoryOutOutsourcing: '委外領料',
    crmReport: '統計報表',
    userBehaviorReport: '業務員行為統計',
    projectTask: '任務列表',
    postSaleProduct: '售後產品',
    receipt: '收款單',
    payment: '付款單',
    incomeType: '收入類型',
    expenditureType: '支出類型',
    basicDataCustomerSort: '客戶分類',
    basicDataInvoiceCategory: '發票類型',
    invoiceApplication: '開票申請',
    invoiceDelivery: '交付',
    sign: '簽收',
    invoiceInfo: '發票資訊',
    invoiceRecord: '發票登記',
    multiAddress: '多地址發貨',
    expireManage: '臨期管理',
    aboutToExpire: '即將到期',
    expiredProduct: '已到期',
    currencyExchangeRate: '貨幣匯率',
    dynamicSalesRate: '商品動銷率',
    financeConfig: '參數設置',
    customerCredit: '客戶授信',
    orderExport: '訂單匯出',
    orderDetailExport: '訂單明細匯出',
    deliveryDetailExport: '發貨單明細匯出',
    salesSettlementPeriod: '結算期限',
    inventoryStockExport: '庫存餘額匯出',
    addPackScheme: '添加組合方案',
    financeAdvanceReceiptManage: '預收款',
    salesOrderImport: 'b2c訂單導入',
    inventoryInPurchaseReceiptAdd: '採購收貨添加',
    inventoryInPurchaseReceiptEdit: '採購收貨編輯',
    inventoryInPurchaseReceiptDelete: '採購收貨刪除',
    inventoryInPurchaseReceiptRevoke: '採購收貨撤銷',
    inventoryInSalesReturnAdd: '銷售退貨添加',
    inventoryInSalesReturnEdit: '銷售退貨編輯',
    inventoryInSalesReturnDelete: '銷售退貨刪除',
    inventoryInSalesReturnRevoke: '銷售退貨撤銷',
    inventoryInOutsourcingAdd: '委外入庫添加',
    inventoryInOutsourcingEdit: '委外入庫編輯',
    inventoryInOutsourcingDelete: '委外入庫刪除',
    inventoryInOutsourcingRevoke: '委外入庫撤銷',
    inventoryOutSalesDeliveryAdd: '銷售發貨添加',
    inventoryOutSalesDeliveryEdit: '銷售發貨編輯',
    inventoryOutSalesDeliveryDelete: '銷售發貨刪除',
    inventoryOutSalesDeliveryRevoke: '銷售發貨撤銷',
    inventoryOutPurchaseReturnAdd: '採購退貨添加',
    inventoryOutPurchaseReturnEdit: '採購退貨編輯',
    inventoryOutPurchaseReturnDelete: '採購退貨刪除',
    inventoryOutPurchaseReturnRevoke: '採購退貨撤銷',
    inventoryOutOutsourcingAdd: '委外出庫添加',
    inventoryOutOutsourcingEdit: '委外出庫刪除',
    inventoryOutOutsourcingDelete: '委外出庫刪除',
    inventoryOutOutsourcingRevoke: '委外出庫撤銷',
    salesOrderB2c: 'B2C訂單',
    import: '導入',
    salesOrderB2cGeneDelivery: '生成銷售發貨單',
    salesOrderB2cGeneReceivable: '生成應收款',
    salesOrderB2cGeneCollection: '生成收款單',
    salesOrderB2cGeneInventoryOut: '生成出庫單',
    todoList: '待辦事項',
    onlineMall: '線上商城',
    mallConfig: '商城設置',
    export: '匯出',
    memberManagement: '會員管理',
    memberList: '會員列表',
    memberLevel: '會員等級',
    mallCategory: '商品分類',
    mallGoods: '商品管理',
    mallOrder: '訂單管理',
    editAddress: '修改發貨地址',
    close: '訂單關閉',
    changePrice: '訂單改價',
    theGoods: '確認收貨',
    onShelf: '上架',
    offShelf: '下架',
    recovery: '恢復',
    tenantPortal: '商業門戶',
    recommenderReview: '推薦人管理',
    productReview: '商品審核',
    parameterConfig: '參數設置',
    tenantPortalManage: '門戶管理',
    pushdown: '小店必選商品管理',
    mallNews: '資訊管理',
    mallReturn: '退換貨',
    inventoryLock: '鎖定庫存',
    reCalc: '重算',
    mallSelfPick: '自提點',
    memberBinding: '會員綁定',
    freightTemplate: '運費範本',
    memberConfig: '參數設置',
    financeCommission: '傭金一覽',
    crmConfig: '參數設置',
    mallInvoice: '發貨單',
    distributionApply: '分銷申請',
    productionOrder: '生產訂單',
    productionPick: '生產領料',
    productionReturn: '生產退料',
    productionFinishIn: '成品入庫',
    begin: '開始',
    enterpriseManage: '企業互聯',
    enterpriseConfig: '參數設置',
    enterpriseConnectApply: '互聯申請',
    enterpriseInterconnect: '企業互聯',
    outOrder: '委外訂單',
    smsSign: '短信簽名',
    permissions: '許可權設置',
    view: '查看',
    mallMarketingCampaign: '营銷活動',
    expressManage: '快遞服務',
    expressAddressNote: '地址簿',
    expressOrderManage: '快遞訂單',
    expressAccountInfo: '帳號資訊',
    expressCompanyAccount: '電子面單配置',
    expressRechargeRecord: '充值記錄',
    operate: '操作',
    expressMonitor: '物流監控',
    enterpriseOrder: '互聯訂單',
    mallLiveConfig: '直播設置',
    salesOrderAdjust: '銷售訂單變更',
    adjustOrder: '變更訂單',
    print: '打印',
    advanceReceiptType: '預收款類型',
    inventoryOutNotificationPrint: '出庫通知打印',
    messageNotify: '消息通知',
    salesDeliveryAdjust: '銷售發貨變更',
    externalContact: '企微聯系人',
    mallGiftBox: '禮盒定制',
    distributionManage: '分銷管理',
    b2cDistributionApply: 'B2C分銷審核',
    b2cDistributionDetail: 'B2C分銷小店',
    auth: '授權',
    salesActivityPromotion: '活動促銷',
    salesStatement: '銷售對賬單',
    wechatKf: '微信客服',
    messageService: '消息服務',
    smsRecharge: '短信充值',
    smsTemplate: '短信模板',
    smsSend: '短信發送',
    welfareManage: '福利兌換',
    parameterSettings: '參數設置',
    end: '終止',
    trash: '作廢',
    salesReconciliation: '銷售對賬',
    cooperationDeposit: '合作保證金',
    welfarePlan: '福利方案',
    appSettings: '小程序設置',
    welfareGoods: '卡券商品',
    exchangeOrder: '兌換訂單',
    welfareIssue: '福利發放',
    memberVoucherShareRecord: '代金券贈送記錄',
    browseRecord: '訪問記錄',
    promotionalPoster: '推廣海報',
    mallPoster: '多商品海報',
    mallProfilePhotoAlbum: '簡介相冊',
    mallQualificationFile: '資質文件',
    thirdPartOrder: '三方訂單',
    thirdPartOrderConfig: '參數設置',
    guanAiTong: '關愛通',
    overtimeOrder: '訂單監控',
    performanceRebate: '業績返點',
    commissionExtract: '傭金提現',
    rebateExtract: '返點提現',
    customerRebate: '返點一覽',
    cardActivate: '卡券發放',
    projectConfig: '參數設置',
    dashboardInventoryWaring: '库存预警',
    distributionOrder: '代發訂單',
    distributionDelivery: '代發發貨單',
    complete: '完成',
    salesOpportunity: '銷售機會',
    salesOpportunitySource: '商機來源',
    productPrice: '產品價格',
    inventoryConfig: '參數設置',
    applicationApi: '應用API',
    purchaseOutOrderAlter: '委外變更單',
    dumallManage: '嘟麥集肆',
    dumallConfig: '參數設置',
    dumallApplyment: '店鋪審核',
    dumallShop: '授權店鋪',
    dumallInvitation: '邀請註冊',
    offlineStore: '線下門店',
    storeCategory: '門店分類',
    storeInfo: '門店列表',
    inventorySecondment: '庫存借調',
    purchaseOutReturn: '委外退貨',
    inventorySecondmentInWarehouseStock: '可用庫存不足，請借調雙方協商處理',
    printTitle: '打印庫存借調單',
    orderAdjust: '變更',
    adjustAudit: '變更審核',
    openPlatform: '開放平臺',
    openAuthorized: '開放授權',
    salesOrderPreview: '報價單',
    welfareDelivery: '兌換發貨單',
    customerType: '客戶類型',
    businessAnalysis: '經營分析',
    businessAnalysisConfig: '參數設置',
    businessDigital: '經營有數',
    businessAnalysisCalc: '分析',
    dumallRegisterCode: '嘟麥集肆註冊碼',
    invoiceApply: '開票申請',
    databoard: '數據大屏',
    databoardInfo: '大屏訊息',
    excel: '導入導出',
    productKind: '產品品類',
    welfareConfig: '參數設置',
    productReport: '統計報表',
    productQuantity: '產品銷售統計',
    storeConfig: '參數設置',
    storeCash: '門店收銀',
    storeOrder: '門店訂單',
    supplier: '供應商管理',
    salesProfit: '銷售毛利',
    interconnectGoods: '互聯商品',
    customPrice: '自定義價格'
  },
  navbar: {
    dashboard: '首頁',
    logOut: '退出登錄',
    profile: '個人中心',
    lockScreen: '鎖屏',
    message: {
      title: '消息',
      markAsRead: '標記為已讀',
      allMessage: '全部消息',
      showReadMessageOnly: '僅顯示未讀消息',
      empty: '暫無數據',
      markAllAsRead: '全部標記為已讀',
      deleteAllRead: '刪除全部已讀消息',
      deleteTips: '確定刪除全部已讀消息嗎'
    }
  },
  notification: {
    read: '已讀'
  },
  common: {
    enabledState: '啟用狀態',
    isEnabled: '是否啟用',
    enabled: '啟用',
    disabled: '禁用',
    open: '開啟',
    close: '關閉',
    include: '包含',
    exclude: '不包含',
    lockedState: '鎖定狀態',
    isLocked: '是否鎖定',
    locked: '鎖定',
    unlocked: '未鎖定',
    choose: '選擇',
    view: '查看',
    list: '列表',
    add: '添加',
    edit: '編輯',
    delete: '刪除',
    search: '查詢',
    actions: '操作',
    save: '保存',
    cancel: '取消',
    revoke: '撤銷',
    ok: '確定',
    submit: '提交',
    reset: '重置',
    refresh: '刷新',
    saveDraft: '保存草稿',
    done: '完成',
    pay: '付款',
    print: '列印',
    design: '設計',
    printWithPrice: '列印（含價格）',
    printNoPrice: '列印（不含價格）',
    preview: '預覽',
    rename: '重命名',
    download: '下載',
    downloadTemplate: '下載範本',
    upload: '上傳',
    checkAll: '全選',
    cancelCheckAll: '取消全選',
    enterKeyQuery: '請輸入關鍵字進行查詢',
    saveSuccess: '保存成功',
    deleteSuccess: '刪除成功',
    addSuccess: '添加成功',
    updateSuccess: '更新成功',
    operateSuccess: '操作成功',
    operateFail: '操作失敗',
    tips: '提示',
    uploadSuccess: '上傳成功',
    uploadFail: '上傳失敗',
    uploaded: '已上傳',
    sex: '性別',
    male: '男',
    female: '女',
    secret: '保密',
    unlockScreen: '解鎖',
    more: '更多',
    returnLogin: '返回登錄',
    changePasswordDesc: '當您第一次登錄或重置密碼之後，需要修改密碼才能繼續使用',
    selectDefaultText: '請選擇',
    yes: '是',
    no: '否',
    codeCreateAuto: '編碼自動生成',
    pleaseInputContent: '請輸入內容',
    isCancel: '暫未保存，是否取消？',
    isDelete: '是否刪除？',
    remark: '備註',
    pleaseSelect: '請選擇',
    pleaseSelectSubordinateCompany: '請選擇所屬公司',
    copy: '複製',
    back: '返回',
    attachment: '附件',
    pleaseSelectSupplier: '請選擇供應商',
    selectProduct: '選擇產品',
    selectProductMaterial: '選擇物料產品',
    pleaseChooseCustomer: '請選擇客戶',
    pleaseChooseContact: '請選擇連絡人',
    pleaseChooseCustomerManager: '請選擇業務員',
    pleaseChooseOperator: '請選擇經辦人',
    to: '至',
    deleteError: '刪除失敗',
    pleaseChooseProduct: '請選擇產品',
    country: '國家',
    area: '區域',
    address: '詳細地址',
    selectCustomer: '選擇客戶',
    startDate: '開始日期',
    dateTo: '至',
    endDate: '結束日期',
    pastWeek: '最近一周',
    pastMonth: '最近一個月',
    pastQuarter: '最近三個月',
    pastHalfYear: '最近半年',
    pastYear: '最近一年',
    thisWeek: '本周',
    thisMonth: '本月',
    thisYear: '今年',
    lastYear: '去年',
    error: '錯誤',
    total: '合計',
    all: '全部',
    productsNotEmpty: '至少選擇一條產品',
    reportTitle: '報表',
    noData: '暫無數據',
    saveAndSubmit: '保存並提交',
    saveAndAuditing: '保存並審核',
    saveAndConfirm: '保存並確認',
    saveAndOnShelf: '保存並上架',
    pleaseChooseWarehouseManager: '請選擇倉管員',
    isTaxPrice: '單價是否含稅',
    verify: '審核',
    unpaid: '未支付',
    paid: '已支付',
    businessUnitPlease: '請選擇往來單位',
    year: '年',
    month: '月',
    dateRangeOut: '選擇日期範圍不能超過一年',
    production: '生產',
    purchase: '採購',
    pageTotal: '本頁合計',
    allPageTotal: '全頁合計',
    invalid: '作廢',
    details: '收支明細',
    fullAddress: '詳細地址',
    productAssistValue: '輔助屬性',
    uploadImage: '上傳圖片',
    screenshotUpload: '截圖上傳',
    screenshotholder: '請按Ctrl+V或在此處右鍵選擇【粘貼】上傳截圖圖片',
    screenshotError: '請截圖後再操作',
    version: '版本',
    notification: '通知',
    basicInfo: '基本資訊',
    relatedBusiness: '關聯業務',
    disassociate: '取消關聯',
    other: '其它',
    pleaseChooseUser: '請選擇用戶',
    company: '所屬公司',
    pleaseChooseCompany: '請選擇所屬公司(在財務參數設置中的開票資訊添加公司)',
    status: '狀態',
    customer: '客戶',
    currency: '貨幣',
    model: '規格型號',
    unit: '單位',
    quantity: '數量',
    unitPrice: '單價',
    unitPriceTax: '含稅單價',
    amount: '金額',
    taxRate: '稅率',
    tax: '稅額',
    baseInfo: '基本資訊',
    export: '數據匯出',
    import: '數據導入',
    stockExport: '庫存數據匯出',
    stockImport: '庫存數據導入',
    supplier: '供應商',
    clear: '清空',
    revokeContinue: '繼續撤銷',
    quit: '退出',
    autoDeliverySuccess: '自動生成發貨單成功',
    autoDeliveryError: '自動生成發貨單失敗',
    selected: '已選',
    handle: '處理',
    inventoryRemark: '倉庫備註',
    noOptionAvailable: '無可用選項',
    creatorId: '創建者',
    createTime: '創建時間',
    modifyTime: '更新時間',
    sort: '排序',
    pass: '通過',
    config: '配置',
    reCalc: '重算',
    lastUse: '最近使用',
    emptyText: '暫無信息',
    audit: '審核',
    reject: '駁回',
    agree: '同意',
    refuse: '拒絕',
    unbound: '解綁',
    bind: '綁定',
    draft: '草稿',
    pleaseChooseProductSort: '請選擇產品分類',
    pleaseChooseProductBrand: '請選擇產品品牌',
    setDefault: '設置為默認',
    default: '默認',
    recalRate: '重算進度',
    salesDeliveryProductSelect: '銷售發貨單產品選擇',
    purchaseReturnProductSelect: '採購退貨單產品選擇',
    mallDeliveryProductSelect: '商城發貨單產品選擇',
    purchaseOrderProductSelect: '採購訂單產品選擇',
    salesReturnProductSelect: '銷售退貨單產品選擇',
    optional: '選填',
    saveAndCreate: '保存並新增',
    update: '更新',
    generate: '生成',
    notRelated: '未關聯',
    related: '已關聯',
    freightTemplate: '運費模板',
    canceled: '已作廢',
    pleaseSelectTemplate: '請選擇運費模板',
    freight: '運費',
    amountWithTax: '金額含稅',
    requireTabInfo: '委外需求',
    salesOrder: '銷售訂單',
    b2cOrder: 'B2C訂單',
    currentEnterpriseName: '當前企業名稱',
    qrcode: '二維碼',
    taxRateEmpty: '請選擇產品稅率',
    confirm: '確認',
    expressTips: '該快遞信息僅做倉庫出庫參考，快遞詳情以出庫單為準',
    expressFullInfoPlease: '請完整填寫快遞信息，快遞公司 + 快遞單號',
    notAudit: '待審核',
    audited: '已審核',
    rejected: '已駁回',
    prevStep: '上一步',
    nextStep: '下一步',
    success: '成功',
    fail: '失敗',
    submitAudit: '提交審核',
    saveAndAudit: '保存並審核',
    ongoing: '進行中',
    inSettlement: '結算中',
    extracted: '已提現',
    completed: '已完成',
    serialNumber: '序號',
    attentionTips1: '建議使用官方Excel模板導入數據',
    attentionTips2: '上傳EXCEL後，請準確填寫標題行號和數據起始行號',
    attentionTips3: '請準確填寫數據映射關係並檢查數據預覽是否相符',
    startMonth: '開始年月',
    endMonth: '結束年月',
    startYear: '開始年份',
    endYear: '結束年份',
    removeImageConfirmTip: '是否確定删除該圖片？',
    mallOrder: '商城訂單',
    distributionOrder: '代發訂單',
    generateRsaKey: '生成RSA金鑰',
    random: '隨機',
    fixed: '固定',
    systemUpdateTitle: '系統更新',
    systemUpdateContent: '系統已更新，請刷新瀏覽器或點擊下方的【更新】按鈕',
    systemUpdateContentTip: '如您還有未完成操作，可以點擊【稍後更新】，待您操作完成後點擊荧幕右下方的【更新】按鈕',
    updateLater: '稍後更新',
    productVolume: '預估體積(m³)',
    boxGaugeValue: '箱數',
    boxGaugeQuantity: '箱',
    boxGaugeJoin: '又',
    boxGaugeLeftQuantity: '個',
    checkBoxGaugeQuantity: '實盤箱數',
    customerName: '客戶',
    unitPriceType: '計算類型',
    byUnitPrice: '按單價',
    bySubtotal: '按小計',
    amountExcludeTax: '小計(不含稅)',
    amountIncludeTax: '小計(含稅)',
    adjustAmountIncludeTax: '調整小計',
    currentCustomerManagerName: '現業務員',
    toSalesDelivery: '轉銷售發貨',
    toOutRquire: '轉委外需求',
    toOutOrder: '轉委外訂單',
    batchToOutOrder: '批量轉委外訂單',
    aboutReturnQuantity: '退貨數量',
    purchaseNotReceivedProduct: '未入庫退貨',
    salesNotDeliveryProduct: '未出庫退貨',
    check: '校驗',
    notCheck: '不校驗',
    inventoryDelivery: '倉庫發貨',
    purchaseDelivery: '委外發貨',
    inventoryOrPurchaseDelivery: '發貨管道',
    salesOrderStockLessCheck: '銷售訂單可用庫存不足是否提示',
    salesDeliveryStockLessCheck: '銷售發貨單可用庫存不足是否提示',
    importSuccess: '導入成功',
    importTaskSuccessTips: '導入任務創建成功，請稍後查看導入結果',
    notInventoryOut: '待出庫',
    notInventoryIn: '待入庫',
    recall: '撤回',
    mallVoucherSelectTitle: '請選擇代金券',
    mallCouponSelectTitle: '請選擇優惠券',
    brandImage: '品牌圖片',
    orderDate: '訂單日期',
    deliveryDate: '交貨日期',
    sms: '簡訊',
    wechatQy: '企業微信',
    dataImport: '數據導入',
    dataMap: '數據映射',
    dataPreview: '數據預覽',
    complete: '完成',
    executeResultInfo: '共{0}條記錄，成功：{1}條，跳過：{2}條',
    emptyImportData: '導入數據為空',
    fileError: '文件類型錯誤或者未按模板格式填寫',
    none: '無',
    expressSmsScheme: '物流短信發送策略',
    moveUp: '上移',
    moveDown: '下移',
    member: '會員',
    visitor: '遊客',
    operatorName: '經辦人',
    creatorName: '創建人',
    sysAutoComplete: '系統自動完成',
    memberComplete: '會員確認收貨',
    editExpress: '修改快遞',
    notDelivery: '未發貨',
    partDelivered: '發貨中',
    completeDelivery: '已發貨',
    notInventoryOutRate: '未出庫',
    partInventoryOut: '部分出庫',
    completeInventoryOut: '已出庫',
    notInventoryInRate: '未入庫',
    partInventoryIn: '部分入庫',
    completeInventoryIn: '已入庫',
    notCollection: '未回款',
    partCollection: '部分回款',
    completeCollection: '已回款',
    notPayment: '未付款',
    partPayment: '部分付款',
    completePayment: '已付款',
    queryDeliveryRate: '發貨進度',
    queryInventoryOutRate: '出庫進度',
    queryInventoryInRate: '入庫進度',
    queryCollectionRate: '回款進度',
    queryPaymentRate: '付款進度',
    customerSort: '客戶分類',
    memberLevel: '會員等級',
    productCode: '產品編碼',
    productName: '產品名稱',
    expressCompany: '快遞公司',
    expressNumber: '快遞單號',
    noDataNeedImport: '沒有需要導入的數據',
    deleteTips: '是否確定刪除該數據?',
  },
  expressStatus: {
    noTrace: '暫無軌跡信息',
    received: '已攬收',
    inTransit: '在途中',
    expSigned: '簽收',
    error: '問題件',
    forward: '轉寄',
    clearance: '清關',
    pending: '待攬件'
  },
  screenLock: {
    screenLockTitle: '系統鎖屏',
    entryPassword: '請輸入密碼',
    logoutConfirm: '是否確定退出系統？'
  },
  dashboard: {
    dashboardCustom: '首頁自訂',
    dashboardComponent: '首頁組件',
    dashboardLayout: '首頁佈局',
    dashboardDefaultLayout: '默認佈局',
    todayCustomerFollowup: '今日客戶跟進',
    salesPresentation: {
      title: '銷售簡報',
      dataType: {
        own: '我的簡報',
        department: '部門簡報',
        company: '公司簡報'
      },
      dateRange: {
        now: '今天',
        yesterday: '昨天',
        currentWeek: '本周',
        lastWeek: '上周',
        currentMonth: '本月',
        lastMonth: '上月',
        currentQuarter: '本季度',
        lastQuarter: '上季度',
        currentYear: '今年',
        lastYear: '去年'
      },
      newCustomerCount: '新增客戶',
      newQuoteCount: '新增報價',
      newOrderCount: '新增訂單',
      newPaymentCount: '新增回款',
      newContactCount: '新增連絡人',
      newFollowUpCount: '跟進次數',
      newTaskCount: '處理任務',
      newWorkSheetCount: '處理工單',
      salesOrderAmount: '訂單金額',
      allCustomerAndContact: '全部客戶/連絡人',
      salesCollectionAmount: '回款金額'
    },
    scheduleCalendar: {
      title: '日程日曆',
      scheduleType: {
        my: '我的日程'
      },
      createSchedule: '新建日程',
      scheduleManage: '日程管理'
    },
    payableAndReceivable: {
      title: '應收應付款',
      amountType: {
        receivable: '應收款',
        payable: '應付款'
      },
      targetAmount: '目標金額',
      completedAmount: '完成金額',
      accounted: '占比',
      overAmount: '完成金额'
    },
    performanceRanking: {
      title: '業績排行',
      rankType: {
        forManager: '按業務員',
        forBillPerson: '按開單員'
      }
    },
    financeReceivableTop: {
      title: '客戶應收款餘額排行Top10',
      sort: '排序',
      businessUnitName: '客戶',
      amountBalance: '餘額',
      receivableDate: '期限'
    },
    financePayableTop: {
      title: '供應商應付款餘額排行Top10',
      sort: '排序',
      businessCompanyName: '供應商',
      amountBalance: '餘額',
      payableDate: '期限'
    },
    salesOrderRecent: {
      title: '銷售訂單銷售額和訂單數（近6個月）',
      count: '訂單數',
      amount: '銷售額'
    },
    salesOrderProductTop: {
      title: '產品銷售額排行Top10'
    },
    salesOrderMonthOnYear: {
      title: '銷售額月度同比',
      xName: '月份'
    },
    financeAccountBookMonthOnYear: {
      title: '銷售回款金額月度同比',
      xName: '月份'
    },
    inventoryWarning: {
      title: '預警信息',
      minStock: '發現小於最小庫存產品',
      safetyStock: '發現小於安全庫存產品',
      maxStock: '發現超出最大庫存產品'
    }
  },
  login: {
    title: '系統登錄',
    tenantKey: '租戶Id/編碼',
    login: '登錄',
    loginName: '帳號',
    password: '密碼',
    capsLock: '大寫鎖定已打開',
    website: '官網簡介',
    contactUs: '聯繫我們',
    useManual: '使用手冊',
    aboutUs: '關於我們',
    servicePhone: '服務支援電話',
    serviceEmail: '服務支援郵箱',
    address: '地址',
    screenResolutionTip: '為方便您更好的使用本系統，建議使用穀歌流覽器且螢幕解析度不小於 1366 * 768',
    browserDownload: '穀歌流覽器下載',
    accountLogin: '帳號密碼登錄'
  },
  profile: {
    aboutMe: '個人中心',
    personalData: '個人資料',
    changePassword: '修改密碼',
    sex: '性別',
    originalPassword: '原密碼',
    newPassword: '新密碼',
    confirmPassword: '確認密碼',
    email: '電子郵箱',
    phone: '電話',
    mobile: '手機號碼',
    entryPassword: '請輸入密碼',
    passwordChangeSuccess: '密碼修改成功，請重新登錄'
  },
  config: {
    companyInfo: '企業資訊',
    businessParams: '業務參數',
    commonModule: '公共參數設置',
    customerModule: '客戶參數設置',
    productModule: '產品參數設置',
    crmModule: '客戶參數設置',
    customerCodeReconciliation: '客戶編碼生成依據',
    customerCodeReset: '客戶編碼是否允許修改',
    supplierCodeReconciliation: '供應商編碼生成依據',
    supplierCodeReset: '供應商編碼是否允許修改',
    salesOrderCodeReconciliation: '銷售訂單編碼生成依據',
    salesModule: '銷售參數設置',
    codeCommonModule: '編碼自定義設置',
    purchaseModule: '採購參數設置',
    inventoryModule: '庫存參數設置',
    financeModule: '財務參數設置',
    weworkModule: '企業微信設置',
    systemParam: '系統參數',
    systemName: '系統名稱',
    systemLogo: '系統Logo',
    companyName: '企業名稱',
    address: '地址',
    phone: '電話',
    fax: '傳真',
    website: '網站',
    email: '郵箱',
    costingMethod: '存貨計價方法',
    movingAverage: '移動平均法',
    fifo: '先進先出法',
    salesReconciliation: '銷售對賬依據',
    byOrder: '按訂單',
    byInvoice: '按發貨單',
    purchaseReconciliation: '採購對賬依據',
    byReceipt: '按收貨單',
    checkNegativeInventory: '檢查負庫存',
    multiTerminalLogin: '允許多處登錄',
    passwordErrorLockCount: '允許密碼錯誤次數',
    userPasswordCreateType: '用戶密碼生成',
    userDefaultPassword: '用戶默認密碼',
    userNeedChangePassword: '強制修改密碼',
    dataDesensitization: '數據脫敏',
    amountDecimal: '金額資料精度',
    quantityDecimal: '數量資料精度',
    automaticallyClearRecipients: '自動清除收件人',
    automaticallyClearRecipientsTip: '開啟後會自動清除90天前的，銷售、采購、商城、兌換訂單的收件人信息',
    decimalTip: '數字輸入項保留有效數字位元數，如輸入2，則表示保留兩位小數,最小0位，最大6位',
    productCodeReconciliation: '產品編碼生成依據',
    bySystem: '系統自動生成',
    byUser: '自寫',
    manual: '手動生成',
    orderAutomatic: '按訂單自動生成',
    deliveryAutomatic: '按發貨單自動生成',
    collectionPlanAutomatic: '按回款計畫自動生成',
    receiptAutomatic: '按收貨單自動生成',
    paymentPlanAutomatic: '按付款計畫自動生成',
    receivablesCreateRule: '銷售訂單應收款生成規則',
    payableCreateRule: '採購訂單應付款生成規則',
    sales: '銷售列印模版',
    purchase: '採購列印範本',
    salesDelivery: '銷售發貨單範本',
    salesDeliveryNoPrice: '銷售發貨單範本（不帶價格）',
    purchaseOrder: '採購訂單範本',
    salesQuotation: '銷售報價單範本',
    salesOrder: '銷售訂單範本',
    reportType: '範本類型',
    reportName: '範本名稱',
    reportTypeTitle: '範本類型清單',
    viewTypeTemplate: '查看範本配置',
    editTypeTemplate: '編輯範本配置',
    reportTemplate: '範本清單',
    salesOrderVerifyAutomatic: '按銷售訂單審核自動生成',
    salesOrderCompleteAutomatic: '按銷售訂單完成自動生成',
    salesDeliveryVerifyAutomatic: '按銷售發貨單審核自動生成',
    salesDeliveryCompleteAutomatic: '按銷售發貨單完成自動生成',
    purchaseOrderVerifyAutomatic: '按採購訂單審核自動生成',
    purchaseOrderCompleteAutomatic: '按採購訂單完成自動生成',
    inventoryInAutomatic: '按採購收貨確認入庫自動生成',
    standardCurrency: '本位幣',
    corpId: '企業ID',
    weworkSyncSecret: '通訊錄同步Secret',
    wechatExternalUserTag: '外部用戶標籤',
    weworkAppAgentId: '應用AgentId',
    weworkAppSecret: '應用Secret',
    weworkAppToken: 'Token',
    weworkAppAesKey: 'EncodingAESKey',
    weworkOrgSyncApp: '企業微信同步應用',
    caracalToolsApp: '獰貓雲工具應用',
    authorized: '已授權',
    unauthorized: '未授權',
    outPayableCreateRule: '委外訂單應付款生成規則',
    purchaseOutOrderVerifyAutomatic: '按委外訂單審核自動生成',
    purchaseOutOrderCompleteAutomatic: '按委外訂單完成自動生成',
    overLoadInventoryIn: '超額入庫',
    overLoadInventoryOut: '超額出庫',
    weworkLogin: '企業微信登錄',
    weworkLoginTip: '開啟企業微信登錄 需配置企業ID 應用AgentId 應用Secret',
    kfToken: '微信客服Token',
    kfAESKey: '微信客服AESKey',
    kfSecret: '微信客服Secret',
    weworkKf: '微信客服',
    kfid: '客服ID',
    freight: {
      templateName: '範本名稱',
      billWay: '計費方式',
      expressCompany: '快遞公司',
      region: '配送範圍',
      firstPiece: '首件',
      firstWeight: '首重',
      followPiece: '續件',
      followWeight: '續重',
      freight: '運費',
      addRegion: '添加配送區域',
      chooseRegion: '選擇配送區域',
      piece: '件數',
      weight: '重量',
      isDefault: '設為默認',
      defaultRegion: '全國統一配送運費',
      addTitle: '添加運費範本',
      editTitle: '編輯運費範本',
      deleteTips: '是否確認刪除該運費範本?',
      default: '預設範本',
      setDefault: '設置為默認',
      templateNameTips: '範本名稱不能為空',
      defaultTemplateTips: '預設範本狀態預設啟用',
      choosedRegion: '已選擇',
      searchRegion: '搜索區域',
      fullPackageMail: '滿額包郵',
      inOrderPackage: '參與訂單滿額包郵',
      fullAmount: '滿額金額',
      pleasefullAmount: '開啓滿額包郵後，請填寫滿額金額',
      sortTips: '請輸入排序',
      addRange: '添加計件區間'
    },
    sms: {
      signName: '簽名名稱',
      signSource: '簽名來源',
      businessLicense: '營業執照',
      powerAttorney: '委託授權書',
      status: '審核狀態',
      remark: '簽名用途',
      rejectRemark: '審核失敗原因',
      defaultSmsSign: '默認',
      setDefault: '設置為默認',
      enterpriseName: '企事業單位名',
      filedWebsite: '已備案網站',
      onlineApp: '已上線APP',
      applet: '公眾號或小程式',
      storeName: '電商平臺店鋪名',
      tradeName: '已注冊商標名',
      auditing: '審核中',
      auditedPass: '審核通過',
      auditedReject: '審核失敗',
      signNameTips1: '建議為用戶真實應用名/網站名/公司名',
      signNameTips2: '簽名發送自帶【】符號，無須添加【】、()、[]符號，避免重複',
      signNameTips3: '不支援個人用戶申請未上線業務，若產品未上線建議先升級企業帳號',
      signNameTips4: '不支援如“客戶服務”、“友情提醒”等過於寬泛內容、不支援“測試”字樣的簽名',
      businessLicenseTips1: '支持jpg、png、gif、jpeg格式的圖片，每張圖片不大於2MB',
      powerAttorneyTips1: '支持jpg、png、gif、jpeg格式的圖片，每張圖片不大於2MB',
      remarkTips1: '簽名來源為已備案網站時，請在簽名用途中補充網站功能變數名稱，加快審核速度',
      otherTips1: '預計兩小時完成審核，政企簽名預計在48小時工作時間內審核',
      otherTips2: '審核工作時間: 週一至周日9:00-23:00（法定節日順延）',
      signNamePlease: '請填寫簽名名稱',
      signSourcePlease: '請選擇簽名來源',
      businessLicensePlease: '請上傳營業執照',
      powerAttorneyPlease: '請上傳委託授權書',
      remarkPlease: '請填寫簽名用途',
      download: '下載',
      powerAttorneyTemplate: '授權委託書範本',
      addTitle: '提交短信簽名申請',
      editTitle: '重新提交短信簽名申請',
      viewTitle: '查看短信簽名申請',
      deleteTips: '確認【刪除】該短信簽名，短信簽名刪除後不可恢復，請謹慎操作!',
      addTips: '確認提交該短信簽名申請，審核通過後可設置該短信簽名',
      editTips: '重新提交該短信簽名申請，審核通過後可設置該短信簽名',
      tips: '重要提示',
      otherTips3: '短信簽名審核為阿裡雲，系統只負責提交申請至阿裡雲',
      signLicensee: '被授權方',
      signLicensor: '授權方',
      bindCustomer: '綁定客戶'
    }
  },
  user: {
    userListTitle: '用戶列表',
    loginName: '登錄名',
    realName: '姓名',
    sex: '性別',
    password: '密碼',
    confirmPassword: '確認密碼',
    email: '電子郵箱',
    phone: '電話',
    mobile: '手機號碼',
    org: '所屬部門',
    dataPermission: '數據許可權',
    loginNamePlaceholder: '請輸入登錄名',
    pleaseChooseOrg: '請選擇所屬部門',
    deleteUserTips: '是否確定刪除該用戶？',
    passwordTip: '編輯使用者時，如果不需要修改密碼則不填寫',
    loginNameTip: '用戶名只能為英文字母,數位和"_"，且長度不少於4位',
    entryCurrentLoginName: '請輸入正確的用戶名',
    addUser: '添加用戶',
    editUser: '編輯用戶',
    ownDataPermission: '本人資料',
    deptDataPermission: '部門資料',
    allDataPermission: '所有資料',
    setRole: '設置角色',
    setRoleTitle: '設置角色',
    resetPassword: '重置密碼',
    createUserMessage: '使用者創建成功，該使用者密碼是：',
    resetPasswordMessage: '密碼重置成功，新密碼是：',
    resetPasswordTips: '是否確定重置該使用者的密碼？',
    createUserSuccess: '用戶創建成功',
    resetPasswordSuccess: '密碼重置成功',
    userTip: '為保證資料的完整性，請不要隨意刪除用戶，除非該使用者沒有產生業務資料。可以通過禁用或鎖定防止該使用者登錄系統',
    syncWework: '企業微信',
    syncFromWework: '關聯用戶',
    syncFromWeworkTip: '系統組織機構和用戶將會關聯企業微信, 企業微信數據不會被修改，是否【確認】操作',
    syncToWework: '同步用戶',
    syncToWeworkTip: '將會刪除企業微信原有用戶，並將本系統用戶同步到企業微信，企業微信數據將被覆蓋，是否【確認】操作',
    qyWechatSyncStatus: '企業微信關聯狀態',
    notRelated: '未關聯',
    relatedSuccess: '關聯成功',
    relatedFail: '關聯失敗',
    cancelUserRelevance: '取消關聯',
    cancelUserRelevanceTips: '是否確認【取消關聯】企業微信',
    qyWechatSyncTip: '同步到企業微信，將會刪除企業微信原有使用者，並將本系統使用者同步到企業微信，請謹慎操作',
    contactMe: '聯系我',
    deleteContactMeTips: '是否確定刪除該用戶的「聯系我」二維碼？',
    qrcode: '企業微信二維碼',
    qrcodeTips: '生成企業微信二維碼,需配置企微聯系人(客戶管理-參數設置-企微聯系人配置)'
  },
  org: {
    addChild: '添加子部門',
    orgListTitle: '部門列表',
    orgName: '部門名稱',
    orgType: '部門類型',
    orgTypeOrg: '機構',
    orgTypeDept: '部門',
    viewOrgTitle: '查看部門',
    editOrgTitle: '編輯部門',
    addOrgTitle: '添加部門',
    orgNamePlaceholder: '請輸入部門名稱',
    enterOrgNameQuery: '請輸入部門名稱進行查詢',
    deleteOrgTips: '是否確定刪除該部門？',
    needChooseOrg: '請先選擇部門節點',
    dropOrgTips: '是否確定將該部門移動到指定部門下？'
  },
  role: {
    roleListTitle: '角色列表',
    roleName: '角色名稱',
    viewRoleTitle: '查看角色',
    editRoleTitle: '編輯角色',
    addRoleTitle: '添加角色',
    roleNamePlaceholder: '請輸入角色名稱',
    enterRoleNameQuery: '請輸入角色名稱進行查詢',
    menuPermission: '菜單許可權',
    addRole: '添加角色',
    deleteRoleTips: '是否確定刪除該角色？'
  },
  menu: {
    menuName: '菜單名稱',
    menuUrl: '菜單URL',
    menuIcon: '功能表圖示',
    permission: '菜單許可權',
    enterMenuNameQuery: '請輸入功能表名稱進行查詢'
  },
  attachment: {
    attachmentName: '檔案名',
    attachmentSize: '大小',
    uploadTime: '上傳時間',
    creatorId: '上傳人',
    deleteAttachmentTips: '是否確定刪除該附件?',
    attahmentNameRename: '附件名稱重命名'
  },
  crm: {
    config: {
      newCustomerAutoEnableDistribution: '新客戶開啟分銷',
      distributionConfig: '分銷設置',
      distributionInternalEnable: '分銷內購',
      commissionConfig: '自訂傭金',
      distributionTitle: '協議標題',
      distributionAgreement: '分銷申請協議',
      wechatContactConfig: '企微聯繫人配置',
      wechatMsgAuditConfig: '企微會話存檔配寘',
      callbackUrl: 'Callback Url',
      customerOrder: '客戶訂單',
      secret: 'secret',
      secretTip: '請填寫secret',
      token: '接收事件Token',
      tokenTip: '請填寫接收事件Token',
      aeskey: '接收事件EncodingAESKey',
      aeskeyTip: '請填寫接收事件EncodingAESKey',
      messageEncryPublicKey: '消息加密公鑰',
      messageEncryPrivateKey: '消息加密私鑰',
      messageEncryPrivateKeyTip: '請填寫消息加密私鑰',
      wechatDeleteTip: '企業微信刪人提醒',
      wechatAccountDisable: '企業微信賬號禁用',
      addMemberState: '添加成員',
      chooseMemberState: '選擇成員',
      wechatDeleteTips: '當有企業成員刪除好友，配置提醒的企業成員會在企業微信內收到一條通知推送',
      disableText: '企業成員當日刪除好友達',
      disableTextTwo: '個，自動將該企業成員的企業微信賬號禁用',
      setDisableMember: '設置非風控企業成員（不受企業微信賬號禁用風控）',
      empty: '清空',
      wechatAccountDisableTip: '企業微信賬號禁用開啟，請填寫企業成員當日刪除好友達到的個數',
      memberOrder: '會員訂單',
      firstCommissionRate: '一級傭金比例',
      secondCommissionRate: '二級傭金比例',
      distributionEnable: '分銷許可權',
      distributionExpireDate: '有效期止',
      customerConfig: '分銷',
      byOrderAmount: '按訂單金額比例',
      byPriceDifference: '按價差比例',
      commssionCalcMethod: '傭金計算方式',
      performanceRebate: '業績返點',
      performanceRebateDateConfigName: '業績返點類型',
      performanceRebateDateConfigDateRange: '返點時間區間',
      errorPerformanceRebateDate: '請選擇時間區間',
      configRebateRate: '返點比例',
      rebateRateConfigTitle: '返點比例配置',
      rebateAmountRange: '業績金額區間',
      rebateRate: '返點比例（%）',
      rateConfigError: '返點比例配置錯誤',
      commonConfig: '通用設置',
      reconciliationDate: '對賬日',
      reconciliationDateTip: '若所選對賬日大於當月最大天數，則對賬日為當月最後一天',
      accountStatementAutoConfirmDate: '銷售對賬單自動確認天數',
      accountStatementAutoConfirmDateTip: '從對賬單生成之日起多少天後對賬單自動更新狀態為已確認',
      basicConfig: '基本設置',
      customerInfoRequiredSetting: '客戶資料必填項設置',
      notifyConfig: '通知設定',
      customerFollowUpNotify: '客戶跟進通知',
      customerVisitNotify: '客戶拜訪通知',
      commissionExcludeConfig: '傭金計算',
      commissionExcludeFreight: '排除運費部分',
      commissionExcludeVoucher: '排除代金券部分',
      commissionExcludePoint: '排除積分支付部分'
    },
    customer: {
      assignment: '客戶分配',
      transfer: '批量轉移',
      claim: '認領',
      customerCode: '客戶編碼',
      customerName: '客戶名稱',
      customerShortName: '簡稱',
      customerContactsPhone: '聯繫人手机',
      allCustomer: '全部客戶',
      queryCustomer: '已查詢客戶',
      checkCustomer: '已勾選客戶',
      customerPhone: '電話',
      customerFax: '傳真',
      customerMobilePhone: '手機號碼',
      associateSupplier: '關聯供應商',
      associateSupplierPlease: '請選擇關聯供應商',
      website: '網站',
      country: '國家',
      area: '區域',
      address: '地址',
      customerLevel: '客戶級別',
      customerSource: '客戶來源',
      customerStatus: '客戶狀態',
      customerSort: '客戶分類',
      settlementType: '結算方式',
      currentSettlement: '現結',
      monthSettlement: '月結',
      billingDay: '帳單日',
      accountPeriod: '賬期(天)',
      customerManager: '業務員',
      lastContactTime: '最近聯繫時間',
      addCustomer: '添加客戶',
      customerNamePlaceholder: '請輸入客戶名稱',
      editCustomer: '編輯客戶',
      viewCustomer: '查看客戶',
      deleteCustomerTips: '是否確定刪除該客戶？',
      claimCustomerTips: '是否確定認領該客戶？',
      claimMultipleCustomerTips: '是否確定認領選擇的客戶？',
      pleaseChooseClaimCustomer: '請選擇要認領的客戶',
      pleaseChooseAssignmentCustomer: '請選擇要分配的客戶',
      pleaseChooseManager: '請選擇客戶經理',
      customerInfo: '客戶資訊',
      customerContact: '連絡人',
      followUpRecord: '跟進記錄',
      billingInfo: '開票信息',
      logisticsAddress: '物流地址',
      followUp: '客戶跟進',
      customerSelect: '選擇客戶',
      pleaseChooseCustomer: '請選擇客戶',
      tag: '標籤',
      priceTypeConfig: '價格類型配置',
      defaultPriceType: '結算價格類型',
      relatedPriceType: '可見價格類型',
      priceTypeName: '價格類型',
      customerMember: '商城會員',
      customerCodePlease: '請輸入客戶編碼',
      changeCategory: '調整分類',
      customerSortPlease: '請選擇客戶分類',
      changeCompany: '調整所屬公司',
      customerCompanyPlease: '請選擇所屬公司',
      customerLevelPlease: '請選擇客戶級別',
      customerShortNamePlease: '請輸入簡稱',
      customerPhonePlease: '請輸入電話',
      customerFaxPlease: '請輸入傳真',
      customerMobilePhonePlease: '請輸入手機號碼',
      customerIndustryTypePlease: '請選擇所屬行業',
      customerAreaPlease: '請選擇區域',
      customerAddressPlease: '請輸入地址',
      dumallRegisterCode: '嘟麥集肆註冊碼',
      geneRegisterCode: '生成註冊碼',
      reGeneCode: '重新生成',
      distributionEnabled: '分銷開啟',
      updateParticipants: '更新協作人',
      addMode: '協作人添加模式',
      append: '追加',
      cover: '覆蓋',
      customerType: '客戶類型',
      distributePoint: '發放積分',
      importCustomerPoint: '導入積分',
      customerBilling: {
        invoiceType: '發票類型',
        vatSpecial: '增值稅專用發票',
        vatGeneral: '增值稅普通發票',
        other: '其他',
        companyName: '企業名稱',
        taxNumber: '稅號',
        address: '地址',
        phone: '電話號碼',
        depositBank: '開戶銀行',
        bankAccount: '銀行帳戶',
        companyNamePlaceholder: '請輸入企業名稱',
        taxNumberPlaceholder: '請輸入企業稅號',
        addBilling: '添加開票信息',
        editBilling: '編輯開票資訊',
        viewBilling: '查看開票信息',
        deleteBillingTips: '是否確定刪除該開票資訊？'
      },
      customerAddress: {
        addressType: '網址類別型',
        country: '國家',
        area: '區域',
        address: '地址',
        addressee: '收件人',
        addresseePhone: '收件人電話',
        addressTypePlaceholder: '請選擇網址類別型',
        countryPlaceholder: '請選擇國家',
        areaPlaceholder: '請選擇區域',
        addressPlaceholder: '請輸入詳細地址',
        addAddress: '添加物流地址',
        editAddress: '編輯物流地址',
        viewAddress: '查看物流地址',
        deleteAddressTips: '是否確定刪除該物流位址？'
      },
      pleaseChoosetransferCustomer: '請選擇要轉移的客戶',
      salesQuotation: '報價',
      salesOrder: '訂單',
      salesDelivery: '發貨',
      salesReturn: '退貨',
      financeReceivable: '應收',
      financeCollection: '回款',
      businessQueryKeyword: '編碼',
      superiorCustomer: '上級',
      pleaseChooseSuperiorCustomer: '請選擇客戶上級',
      mergerBusinessEnable: '合併業務',
      juniorCustomer: '下級',
      participant: '協作人',
      addParticipant: '添加協作人',
      clearParticipant: '清空協作人',
      priceType: '價格類型',
      industryType: '所屬行業',
      creditLimit: '授信額度',
      settlementPeriod: '結算方式',
      customerAuthCode: '客戶碼',
      customerAuthCodeTip: '客戶碼自動生成',
      baseInfo: '基本信息',
      otherInfo: '其它信息',
      contactInfo: '聯系人',
      saveAndAddContact: '保存並繼續添加聯系人',
      customerRange: '請選擇客戶範圍'
    },
    contact: {
      nickName: '稱呼',
      contactName: '姓名',
      customer: '所屬客戶',
      position: '職位',
      contactType: '類型',
      businessPhone: '電話',
      mobile: '手機',
      email: '郵箱',
      fax: '傳真',
      wechat: '微信',
      qq: 'qq',
      otherInfo: '其他',
      lastContactTime: '最近聯繫時間',
      addContact: '添加連絡人',
      contactNamePlaceholder: '請輸入連絡人名稱',
      nickNamePlaceholder: '請輸入連絡人稱呼',
      editContact: '編輯連絡人',
      viewContact: '查看連絡人',
      deleteContactTips: '是否確定刪除該連絡人？',
      contactInfo: '連絡人信息',
      contactSelect: '選擇連絡人',
      birthday: '生日'
    },
    followUp: {
      followUpDateStart: '跟進日期起',
      followUpDateEnd: '跟進日期止',
      customer: '客戶名稱',
      contact: '連絡人',
      followUpType: '跟進類型',
      followUpWay: '跟進方式',
      followUpTime: '跟進時間',
      followUpPerson: '跟進人',
      followUpContent: '跟進內容',
      nextFollowUpTime: '下次跟進時間',
      nextFollowUpWay: '下次跟進方式',
      addFollowUp: '添加跟進記錄',
      customerPlaceholder: '請選擇要跟進的客戶',
      contactPlaceholder: '請選擇連絡人',
      followUpTypePlaceholder: '請選擇跟進類型',
      followUpWayPlaceholder: '請選擇跟進方式',
      followUpContentPlaceholder: '請填寫跟進內容',
      followUpTimePlaceholder: '請填寫跟進時間',
      editFollowUp: '編輯跟進記錄',
      viewFollowUp: '查看跟進記錄',
      deleteFollowUpTips: '是否確定刪除該跟進記錄？',
      followUpInfo: '跟進記錄',
      syncCustomerVisit: '同步完成拜訪計畫',
      customerVisit: '拜訪計畫',
      visitPlanTips: '請選擇拜訪計畫',
      businessType: '關聯業務類型',
      businessCode: '關聯業務'
    },
    clueFollowUp: {
      followUpDateStart: '跟進日期起',
      followUpDateEnd: '跟進日期止',
      customer: '客戶名稱',
      contact: '聯系人',
      followUpType: '跟進類型',
      followUpWay: '跟進方式',
      followUpTime: '跟進時間',
      followUpPerson: '跟進人',
      followUpContent: '跟進內容',
      nextFollowUpTime: '下次跟進時間',
      nextFollowUpWay: '下次跟進方式',
      addFollowUp: '添加跟進記錄',
      customerPlaceholder: '請選擇要跟進的客戶',
      contactPlaceholder: '請選擇聯系人',
      followUpTypePlaceholder: '請選擇跟進類型',
      followUpWayPlaceholder: '請選擇跟進方式',
      followUpContentPlaceholder: '請填寫跟進內容',
      followUpTimePlaceholder: '請填寫跟進時間',
      editFollowUp: '編輯跟進記錄',
      viewFollowUp: '查看跟進記錄',
      deleteFollowUpTips: '是否確定刪除該跟進記錄？',
      followUpInfo: '跟進記錄',
      syncCustomerVisit: '同步完成拜訪計劃',
      customerVisit: '拜訪計劃',
      visitPlanTips: '請選擇拜訪計劃'
    },
    multi: {
      dateStart: '開始時間',
      dateEnd: '結束時間',
      customerMantenance: '客戶維護',
      addedCustomerCount: '新增客戶',
      addedContactCount: '新增連絡人',
      addedFollowUpCount: '新增跟進',
      addedQuotationCount: '新增報價單',
      schedule: '日程',
      relatedScheduleCount: '關聯日程數量',
      salesOrder: '銷售訂單',
      salesOrderQuantity: '訂單數量',
      unAudit: '待審核',
      audit: '已審核',
      finish: '已完成',
      salesOrderAmount: '簽約金額',
      salesCollectionAmount: '回款金額',
      allReport: '全部統計',
      customerCount: '客戶數量',
      contactCount: '連絡人數量',
      followUpCount: '跟進數量',
      quotationCount: '報價單數量',
      scheduleCount: '日程數量',
      orderCount: '訂單數量',
      subtotal: '小計',
      pleaseChooseOperator: '請選擇業務員'
    },
    visit: {
      visitName: '拜訪計畫名稱',
      customerName: '客戶名稱',
      contactName: '連絡人',
      businessPhone: '聯繫電話',
      mobile: '手機',
      visitTypeId: '拜訪方式',
      visitPurpose: '拜訪目的',
      visitTimePlanned: '計畫拜訪時間',
      directorId: '負責人',
      participantId: '參與人',
      visitStatus: '狀態',
      description: '描述',
      isRemind: '是否提醒',
      remindTime: '提醒時間',
      delayTime: '延期時間',
      delayResult: '延期原因',
      delayRemark: '延期備註',
      notFollowUp: '未跟進',
      addTitle: '添加拜訪計畫',
      viewTitle: '查看拜訪計畫',
      delayTitle: '延期拜訪計畫',
      finishTips: '是否確定完成該拜訪計畫?',
      cancelTips: '是否確定取消該拜訪計畫?',
      delelteTips: '是否確定刪除該拜訪計畫?',
      delayed: '已延期',
      canceled: '已取消',
      finished: '已完成',
      delay: '延期',
      finish: '完成',
      followUp: '跟進記錄',
      addParticipants: '添加參與人',
      queryWords: '客戶名稱 拜訪計畫名稱',
      visitNameNotEmpty: '拜訪計畫名稱不能為空',
      customerNameNotEmpty: '拜訪客戶不能為空',
      contactNameNotEmpty: '拜訪連絡人不能空',
      visitPurposeNotEmpty: '拜訪目的不能為空',
      visitTimePlannedNotEmtpy: '拜訪計畫時間不能為空',
      directorIdNotEmpty: '負責人不能為空',
      participantIdNotEmpty: '參與人不能為空',
      delayTimeNotEmpty: '延期時間不能為空',
      delayResultNotEmpty: '延期原因不能為空',
      delayRemarkNotEmpty: '延期備註不能為空',
      participantsNotEmtpy: '參與人不能為空',
      visitTypeIdNotEmpty: '拜訪方式不能為空',
      remindTimeError: '提醒時間不能空且小於計畫時間',
      notRemind: '不提醒',
      advance15min: '提前15分鐘',
      advance30min: '提前30分鐘',
      advance1hour: '提前1小時',
      advance3hours: '提前3小時',
      advance1day: '提前1天',
      custom: '自訂',
      visitSelect: '選擇拜訪計畫',
      operatorName: '操作人'
    },
    customerTag: {
      common: {
        title: '選擇標籤',
        addTag: '創建新標籤',
        tagManage: '標籤管理',
        addForTag: '+添加新標籤'
      }
    },
    creditLimit: {
      customerName: '客戶名稱',
      customerCode: '客戶編碼',
      creditLimit: '授信額度',
      receivable: '當前應收款餘額',
      ableCreditLimit: '可用授信額度',
      pleaseChooseCustomer: '請選擇用戶',
      pleaseInputCreditLimit: '請輸入授信額度',
      creditLimitGreaterThanZero: '授信額度必須大於0',
      addCreditLimit: '添加客戶授信',
      creditTip: '授信額度按本位幣控制，請按本位幣金額錄入;未設置授信額度或者授信額度為0，默認不進行授信額度的控制'
    },
    distributorApply: {
      queryStatus: '審核狀態',
      applyName: '企業名稱/個人名稱',
      certificate: '證件號碼',
      phone: '手機號碼',
      member: '會員',
      customer: '客戶',
      applyTime: '申請時間',
      auditStatus: '審核狀態',
      reject: '駁回',
      pending: '待審核',
      audited: '已審核',
      editTitle: '審核分銷申請',
      viewTitle: '查看分銷申請',
      rejectReason: '駁回理由',
      expire: '有效期'
    },
    externalContact: {
      name: '名稱',
      corpName: '所在企業的簡稱',
      corpFullName: '所在企業的主體名稱',
      type: '類型',
      wx: '微信用戶',
      wework: '企業微信用戶',
      queryKeyword: '關鍵字 ',
      sync: '同步企微客戶',
      addCustomer: '添加客戶',
      bindCustomer: '綁定客戶',
      viewCustomer: '查看客戶',
      followup: '企業成員',
      customer: '客戶',
      tips: '企業微信參數沒有配置，請先在【系統設置-參數配置】中完善企業微信設置(企業ID、應用AgentId、應用Secret)',
      wechatCorpInfo: '企微信息',
      relatedCustomer: '關聯客戶',
      showLost: '顯示已流失的',
      lost: '已流失',
      unlost: '未流失',
      addTime: '添加時間',
      lossTime: '丟失時間',
      description: '描述'
    },
    performanceRebate: {
      customerName: '客戶名稱',
      performanceRebateName: '返點類型名稱',
      startDate: '業績計算開始時間',
      endDate: '業績計算結束時間',
      performanceAmount: '業績金額',
      rebateRate: '返點比例（%）',
      rebateAmount: '返點金額',
      queryKeywords: '返點類型',
      performanceRange: '業績區間(左不包含，右包含)',
      commonRateConfig: '默認返點設置',
      customerSortRateConfig: '指定客戶分類返點設置',
      customerRateConfig: '指定客戶返點設置',
      notInCustomerSortConfig: '不參與業績返點的客戶分類',
      notInCustomerConfig: '不參與業績返點的客戶',
      selectCustomer: '選擇客戶',
      performanceRebateNamePlease: '請填寫返點類型名稱',
      performanceDateRange: '業績計算時間區間',
      rebateCalcDateRange: '業績結算時間區間',
      cashRealTime: '實時提現',
      performanceDateRangePlease: '請選擇業績計算時間區間',
      rebateCalcDateRangePlease: '請選擇業績結算時間區間',
      auditStatus: '狀態',
      customerSortGroup: '指定客戶分類設置',
      customerGroup: '指定客戶設置',
      auditTips: '是否確認審核該業績返點設置',
      commonRateConfigError: '默認返點比例設置錯誤',
      customerRateConfigError: '客戶返點比例設置錯誤',
      customerSortRateConfigError: '客戶分類返點比例設置錯誤',
      customerNotConfig: '客戶未設置',
      customerSortNotConfig: '客戶分類未設置',
      deleteTips: '是否確認刪除該返點類型設置',
      cancelTips: '是否確認作廢該返點類型設置',
      revokeTips: '是否確認撤銷該返點類型設置',
      addPerformanceRebateConfig: '添加業績返點設置',
      editPerformanceRebateConfig: '編輯業績返點設置',
      viewPerformanceRebateConfig: '查看業績返點設置',
      resetRebateConfig: '業績返點設置',
      performanceDateRangeTips: '業績統計範圍為：在時間區間內的有效訂單（已審核銷售訂單/已支付商城訂單）',
      rebateCalcDateRangeTips: '時間區間結束後，返點設置將被標記完成，返點金額以完成時計算結果為最終結果',
      notEndRebateAmount: '待結算',
      endRebateAmount: '已結算',
      extractAmount: '提現金額',
      applyingExtractAmount: '申請中',
      extractedAmount: '已提現',
      rebateBalance: '剩余',
      rebateDetails: '返點明細',
      rebateExtract: '提現申請',
      rebateStatus: '提現狀態',
      extractType: '提現方式',
      extractStatus: '狀態',
      operator: '經辦人',
      auditTime: '審核時間',
      rejectReason: '駁回原因',
      toBankCard: '轉銀行卡',
      toAdvanceReceipt: '轉預收款',
      auditRebateExtractApply: '提現申請審核',
      viewRebateExtractApply: '查看提現申請',
      member: '會員',
      customer: '客戶',
      extractPassTips: '確認通過該提現申請',
      extractRejectTips: '確認駁回該提現申請',
      emptyRejectReason: '請輸入駁回原因',
      paymentInfo: '付款信息',
      rebateConfigInfo: '返點設置',
      rebateExtractInfo: '提現信息',
      customerAccountInfo: '客戶賬戶信息',
      customerAccountName: '戶名',
      customerAccountBank: '開戶銀行',
      customerAccountNumber: '賬號',
      paymentInfoPlease: '請填寫付款信息',
      rebateCalcDateTipPrefix: '業績計算日期截止至',
      rebateCalcDateTipSubfix: '，統計範圍包含銷售訂單和商城訂單',
      calcDateRangeError: '請正確設置業績結算時間區間，結算時間區間需大於計算時間區間',
      businessQueryKeywords: '訂單編碼',
      viewRebateBusiness: '查看關聯業務',
      orderCode: '訂單編碼',
      orderDate: '訂單日期',
      orderAmount: '訂單金額',
      memberPerformanceDateRangeTips: '業績統計範圍為：在時間區間內已支付商城訂單',
      memberRebateCalcDateRangeTips: '時間區間結束後，返點設置將被標記完成，返點金額以完成時計算結果為最終結果',
      memberLevelGroup: '指定會員等級設置',
      memberGroup: '指定會員設置',
      memberRateConfigError: '會員返點比例設置錯誤',
      memberLevelRateConfigError: '會員等級返點比例設置錯誤',
      memberNotConfig: '會員未設置',
      memberLevelRateConfig: '指定會員等級返點設置',
      memberRateConfig: '指定會員返點設置',
      notInMemberLevelConfig: '不參與業績返點的會員等級',
      notInMemberConfig: '不參與業績返點的會員',
      selectMember: '選擇會員',
      customerRebateDetails: '客戶返點',
      memberRebateDetails: '會員返點',
      memberName: '會員名稱',
      calcPriceLevel: '返點價格標簽',
      priceLevelPlease: '請勾選返點價格標簽'
    },
    clue: {
      clueCode: '線索編碼',
      clueName: '線索名稱',
      customerName: '客戶名稱',
      customerShortName: '簡稱',
      contactName: '聯系人名稱',
      allClue: '全部線索',
      queryClue: '已查詢線索',
      checkClue: '已勾選線索',
      customerPhone: '客戶電話',
      customerMobilePhone: '客戶手機號碼',
      wechat: '微信',
      email: '郵箱',
      industryType: '所屬行業',
      budgetAmount: '項目預算',
      customerDemand: '需求描述',
      country: '國家',
      area: '區域',
      address: '地址',
      clueSource: '線索來源',
      clueStatus: '線索狀態',
      clueSort: '線索分類',
      customerManager: '客戶經理',
      lastContactTime: '最近聯系時間',
      nextContactTime: '下次聯系時間',
      addClue: '添加線索',
      clueNamePlaceholder: '請輸入線索名稱',
      customerNamePlaceholder: '請輸入客戶名稱',
      editClue: '編輯線索',
      clueInfo: '線索詳情',
      viewClue: '查看線索',
      deleteCustomerTips: '是否確定刪除該線索？',
      pleaseChooseManager: '請選擇客戶經理',
      crmClueInfo: '線索信息',
      customerContact: '聯系人',
      followUpRecord: '跟進記錄',
      followUp: '線索跟進',
      customerSelect: '選擇客戶',
      pleaseChooseCustomer: '請選擇客戶',
      remark: '備註',
      transfer: '轉客戶'
    }
  },
  purchase: {
    common: {
      purchaseOrder: '採購訂單',
      purchaseReceipt: '採購收貨',
      purchaseReturn: '採購退貨',
      orderSupplierAmount: '按供應商統計-採購訂單',
      orderProductAmount: '按產品統計-採購訂單',
      orderExpenseInfo: '費用資訊',
      orderSummary: '採購訂單金額匯總',
      receiptSummary: '採購收貨金額匯總',
      orderAmountDetails: '訂單明細',
      receiptAmountDetails: '收貨明細',
      outsourcingOrder: '委外訂單',
      addressee: '收貨人',
      addresseePhone: '收貨人電話',
      deliveryAddress: '收貨人地址',
      addresseeTips: '請填寫收貨人',
      addresseePhoneTips: '請填寫收貨人聯系電話',
      countryTips: '請選擇國家',
      areaTips: '請選擇地區',
      deliveryAddressTips: '請填寫詳細地址',
      orderExpressInfo: '快遞信息',
      receiptWarehouse: '收貨倉庫',
      exportOrderTitle: '導出采購訂單',
      exportOutOrderTitle: '導出委外訂單'
    },
    exception: {
      supplierPlease: '請選擇供應商',
      purchaserPlease: '請選擇採購員',
      purchaseDatePlease: '請選擇採購日期',
      currencyPlease: '請選擇貨幣'
    },
    status: {
      draft: '草稿',
      unAudit: '待審核',
      audit: '已審核',
      noOrder: '未下單',
      partialOrder: '部分下單',
      orderPlaced: '下單完成',
      canceled: '已作廢'
    },
    product: {
      product: '產品',
      quantity: '數量',
      unitPrice: '單價',
      taxRate: '稅率',
      tax: '稅金',
      amountExcludedTax: '小計(不含稅)',
      amountAddedTax: '小計(含稅)',
      productCode: '產品編碼',
      productName: '產品名稱',
      productModel: '規格型號',
      productUnit: '單位',
      supplier: '供應商'
    },
    supplier: {
      supplierCode: '供應商編碼',
      supplierType: '供應商類型',
      supplierName: '供應商名稱',
      supplierShortName: '簡稱',
      supplierPhone: '電話',
      supplierFax: '傳真',
      website: '網站',
      remark: '備註',
      country: '國家',
      area: '區域',
      address: '詳細地址',
      supplierPayType: '結算方式',
      supplierDeliveryType: '送貨方式',
      addSupplierTitle: '添加供應商',
      editSupplierTitle: '編輯供應商',
      viewSupplierTitle: '查看供應商',
      deleteSupplierTips: '是否確定刪除該供應商',
      supplierNameNotEmpty: '請輸入供應商名稱',
      supplierInfo: '供應商資訊',
      product: '供應商產品',
      contact: '連絡人',
      account: '供應商帳戶',
      brand: '供應品牌',
      relatedBrand: '關聯供應品牌',
      relatedCustomer: '關聯客戶',
      addRelatedBrand: '添加供應品牌',
      supplierBrandTips: '至少關聯一個品牌',
      supplierCodePlease: '請輸入供應商編碼'
    },
    contact: {
      nickName: '稱呼',
      contactName: '姓名',
      customer: '所屬供應商',
      position: '職位',
      contactType: '類型',
      businessPhone: '電話',
      mobile: '手機',
      email: '郵箱',
      fax: '傳真',
      wechat: '微信',
      qq: 'qq',
      otherInfo: '其他',
      lastContactTime: '最近聯繫時間',
      pleaseChooseSupplier: '請選擇供應商',
      addContact: '添加連絡人',
      contactNamePlaceholder: '請輸入連絡人名稱',
      nickNamePlaceholder: '請輸入連絡人稱呼',
      editContact: '編輯連絡人',
      viewContact: '查看連絡人',
      deleteContactTips: '是否確定刪除該連絡人？',
      contactInfo: '連絡人信息',
      contactSelect: '選擇連絡人',
      birthday: '生日'
    },
    plan: {
      planSn: '採購計畫編碼',
      userName: '採購人',
      status: '狀態',
      salesOrder: '銷售訂單',
      deliveryDate: '期望到貨日期',
      purpose: '用途',
      remark: '描述',
      addPlanTitle: '添加採購計畫',
      editPlanTitle: '編輯採購計畫',
      viewPlanTitle: '查看採購計畫',
      deletePurchasePlanTips: '是否確定刪除該採購計畫',
      planInfo: '採購計畫資訊',
      chooseProduct: '添加採購產品',
      addProductTitle: '選擇採購產品',
      purchase: '採購',
      draft: '草稿',
      unconfirm: '待下單',
      confirmed: '已下單',
      userIdNotEmpty: '請選擇採購人',
      deliveryDateNotEmpty: '請選擇到貨日期',
      purposeNotEmpty: '請填寫用途',
      remarkNotEmpty: '請填寫備註',
      submitAndOrder: '提交並下單',
      requisition: '轉請購單',
      purchaseOrder: '轉采購訂單',
      purchaseOutOrder: '轉委外訂單'
    },
    order: {
      queryKeyword: '編碼、供應商、連絡人',
      detailsQueryKeyword: '編碼、客戶、產品資訊',
      orderCode: '採購訂單編碼',
      orderAmount: '訂單金額',
      orderSn: '採購訂單編碼',
      deliveryDate: '交貨日期',
      createTime: '訂單下單日期',
      supplier: '供應商名稱',
      contactName: '連絡人',
      user: '採購員',
      deliveryType: '交貨方式',
      auditDate: '審核日期',
      auditId: '審核人',
      payType: '結算方式',
      orderDate: '下單日期',
      currency: '貨幣',
      invoice: '開票類型',
      delivery: '交貨方式',
      address: '交貨地址',
      contact: '連絡人',
      amountExcludedTax: '總金額(未稅)',
      amountAddedTax: '總金額(含稅)',
      paidAmount: '已付金額',
      paidBill: '已付票據',
      status: '訂單狀態',
      remark: '備註',
      taxRate: '稅率',
      itemAddedTax: '小計（含稅）',
      addOrderTitle: '添加採購訂單',
      editOrderTitle: '編輯採購訂單',
      viewOrderTitle: '查看採購訂單',
      orderInfo: '訂單資訊',
      deleteOrderTips: '是否確定【刪除】該訂單？',
      invalidOrderTips: '是否確定【作廢】該訂單？',
      stopOrderTips: '是否確定【終止】該訂單？',
      chooseProduct: '從供應商產品選擇',
      chooseAllProduct: '從全部產品選擇',
      number: '數量',
      unitPrice: '單價(不含稅)',
      receipt: '收貨',
      finish: '完成',
      end: '終止',
      invalid: '作廢',
      modify: '變更',
      alter: '變更中',
      draft: '草稿',
      unaudit: '待審核',
      audited: '已審核',
      finishStatus: '完成',
      endStatus: '終止',
      invalidStatus: '作廢',
      delivered: '已發貨',
      finishTips: '是否確認【完成】該訂單？',
      revokeTips: '是否【撤銷】該訂單',
      userIdNotEmpty: '請選擇供應商',
      supplierIdNotEmpty: '請選擇供應商',
      warehouseIdNotEmpty: '請選擇倉庫',
      deliveryTypeNotEmpty: '請選擇交貨方式',
      invoiceTypeNotEmpty: '請選擇發票類型',
      deliveryDateNotEmpty: '請選擇交貨日期',
      orderDateNotEmpty: '請選擇下單日期',
      payTypeNotEmpty: '請選擇結算方式',
      currencyTypeNotEmpty: '請選擇貨幣類型',
      deliveryAddressNotEmpty: '請填寫交貨地址',
      verifyOrderTips: '確認【審核】該訂單',
      financePayInfo: '付款資訊',
      financePayableDetails: '應付款',
      financePayDetails: '付款詳情',
      financePayableInfo: '應付款',
      createFinancePayable: '創建應付款',
      orderHasPayable: '該採購訂單已經關聯應付款，點擊確認【查看】已關聯應收款',
      deliveryAddr: '交貨地址',
      payableCreateAuto: '應付款由系統自動生成',
      startDate: '下單日期起',
      endDate: '下單日期止',
      supplierName: '供應商',
      amountReceived: '已收貨金額',
      amountPaid: '已付金額',
      amountConfirmed: '已確認',
      amountUnConfirmed: '未確認',
      viewInventoryInTitle: '查看入庫詳情',
      viewOrderPayableTitle: '查看付款詳情',
      orderDetails: '訂單明細',
      printTitle: '列印採購訂單',
      verifySelectedOrdersTips: '確認【審核】已選中採購訂單',
      deliverySelectedOrdersTips: '確認【發貨】已選中採購訂單',
      completeSelectedOrdersTips: '確認【完成】已選中採購訂單',
      pleaseSelectVerfyOrder: '請選擇已審核採購訂單',
      pleaseSelectUnVerifyOrder: '請選擇待審核採購訂單',
      pleaseSelectDeliveredOrder: '請選擇已發貨訂單',
      inventoryProcessRate: '收貨進度',
      paymentProcessRate: '付款進度',
      inventoryQueryKeyword: '編碼、供應商',
      finishedQuantity: '已收貨數量',
      purchaseReturn: '退貨',
      purchaseReturnOrder: '採購退貨',
      outOrderCode: '委外訂單編碼',
      addOutOrderTitle: '添加委外訂單',
      editOutOrderTitle: '編輯委外訂單',
      viewOutOrderTitle: '查看委外訂單',
      salesOrderCode: '銷售訂單編碼',
      exportOrderDetail: '採購訂單明細匯出',
      enterpriseOrder: '轉互聯訂單',
      enterpriseOrderTips: '是否確認該采購訂單轉互聯訂單?',
      enterpriseOrderSuccessTips: '互聯訂單已生成 待銷售方審核',
      alterRecords: '變更記錄',
      deliveryTips: '是否確認更新該采購委外訂單?',
      requireCode: '委外需求編碼',
      outOrderDelivery: '委外訂單發貨',
      batchInventoryIn: '批量入庫',
      deliveryWarehouse: '入庫倉庫',
      plesaseSelectPurchaseOrder: '請選擇采購訂單',
      isInventoryIn: '是否收貨',
      warehousePlease: '請選擇收貨倉庫',
      purchaseOrderAlter: '訂單變更',
      purchaseOutOrderAlter: '訂單變更',
      payableReturnAmount: '應付款退款',
      inventoryInDate: '入庫日期',
      aboutReturnAmount: '退款金額'
    },
    out: {
      orderCode: '委外訂單編碼',
      orderDate: '下單日期',
      finishDate: '完成日期',
      settlementMethod: '結算方式',
      invoiceType: '開票類型',
      agent: '經辦人',
      audit: '審核人',
      auditTime: '審核時間',
      pleaseChooseAudit: '請選擇審核人',
      pleaseChooseAgent: '請選擇經辦人',
      address: '交貨地址',
      amount: '總金額',
      BOM: 'BOM',
      queryKeyword: '編碼、供應商、連絡人',
      orderInfo: '訂單資訊',
      orderDetails: '訂單明細',
      orderStatus: '狀態',
      startDate: '下單時間起',
      endDate: '下單時間止',
      baseInfo: '基本資訊',
      addOrder: '添加委外訂單',
      editOrder: '編輯委外訂單',
      viewOrder: '查看委外訂單',
      orderDelivery: '訂單發貨',
      editProductBom: '編輯產品BOM',
      viewProductBom: '查看產品BOM',
      selectProductBom: '選擇產品物料',
      deleteOrderTips: '是否【刪除】該訂單',
      auditOrderTips: '是否【審核】該訂單',
      revokeOrderTips: '是否【撤銷】該訂單',
      cancelOrderTips: '是否【作廢】該訂單',
      orderDatePlease: '請選擇下單日期',
      finishDatePlease: '請選擇完成日期',
      agentPlease: '請選擇經辦人',
      otherExpense: '其它費用',
      outsourcingOut: '委外領料',
      outsourcingIn: '委外入庫',
      addInventoryIn: '添加入庫單',
      addInventoryOut: '添加出庫單',
      inventoryprocessrate: '入庫進度',
      paymentprocessrate: '付款進度',
      exportMultiAddress: '代發訂單導出',
      importOutOrderExpress: '委外訂單',
      multiAddressOutOrderExpress: '代發委外訂單'
    },
    receipt: {
      receiptCode: '編碼',
      supplierName: '供應商',
      receiptDate: '收貨日期',
      purchaser: '採購員',
      amountExcludedTax: '總額(不含稅)',
      amountAddedTax: '總額(含稅)',
      return: '退貨',
      viewPurchaseReceiptTitle: '查看採購收貨',
      receiptBaseInfo: '基本資訊',
      purchaseOrder: '採購訂單',
      receiptReturnDetails: '退貨單詳情',
      receiptReturnProductDetails: '退貨單產品詳情',
      receiptReturnInfo: '退貨信息',
      receiptProductSelector: '收貨單產品選擇器',
      queryKeyword: '編碼、供應商',
      startDate: '下單日期起',
      endDate: '下單日期止',
      detailsqueryKeyword: '採購訂單編碼、入庫單編碼、供應商、產品資訊',
      purchaseReceipt: '採購收貨',
      purchaseReceiptDetails: '採購收貨明細'
    },
    return: {
      queryKeyword: '編碼、供應商',
      returnOrderCode: '退貨單編碼',
      purchaseOrderCode: '採購編碼',
      operator: '經辦人',
      supplier: '供應商名稱',
      currency: '貨幣',
      returnDate: '退貨日期',
      returnStatus: '退貨單狀態',
      returnReason: '退貨理由',
      orderAmount: '訂單金額',
      amountExcludedTax: '總額(不含稅)',
      amountAddedTax: '總額(含稅)',
      remark: '備註',
      draft: '草稿',
      pending: '待審核',
      audit: '已審核',
      finish: '完成',
      returnDateNotEmpty: '請選擇退貨日期',
      operatorIdNotEmpty: '請選擇經辦人',
      returnReasonNotEmpty: '請填寫退貨原因',
      supplierPlease: '請選擇供應商',
      currencyPlease: '請選擇貨幣',
      addTitle: '添加採購退貨單',
      editTitle: '編輯採購退貨單',
      viewTitle: '查看採購退貨單',
      orderTitle: '採購訂單',
      startDate: '退貨日期起',
      endDate: '退貨日期止',
      pleaseChooseSupplier: '請選擇供應商',
      supplierProduct: '供應商產品',
      purchaseReceipt: '收貨單',
      returnBaseInfo: '基本資訊',
      pleaseSelectOperator: '請選擇經辦人',
      receiptProduct: '收貨單產品',
      verifyReturnOrderTips: '確認審核該退貨單',
      doneReturnOrderTips: '確認完成該退貨單',
      revokeReturnOrderTips: '確認撤銷該退貨單',
      returnInventoryInfo: '出庫信息',
      inventoryOutDetails: '出庫單詳情',
      inventoryOutProductDetails: '出庫單產品詳情',
      inventoryOutCode: '出庫單編碼',
      inventoryOutDate: '出庫日期',
      warehouse: '倉庫',
      warehouseOperator: '倉庫經辦人',
      purchaseOrder: '採購訂單',
      inInventory: '入庫單',
      inventoryOut: '倉庫已發貨',
      inventoryIn: '倉庫已收貨',
      toBeCollection: '待財務收款',
      financeCollection: '財務已收款',
      inventoryOutTips: '確認倉庫已發貨',
      financeCollectionTips: '確認財務已收款',
      purchaseOrderProduct: '采購單產品',
      pleaseChoosePurchaseOrder: '請選擇采購訂單',
      pleaseChoosePurchaseOutOrder: '請選擇委外訂單',
      inventoryOutProcessRate: '出庫進度',
      inventoryInProcessRate: '入庫進度',
      financeProcessRate: '退款進度',
      printTitle: '采購退貨打印',
      returnQuantityOver: '退貨數量超出訂單數量',
      purchaseOutOrderProduct: '委外訂單產品',
      purchaseOutOrderProductSelect: '委外訂單產品選擇',
      addOutReturnTitle: '添加委外退貨單',
      editOutReturnTitle: '編輯委外退貨單',
      viewOutReturnTitle: '查看委外退貨單',
      isOutDelivery: '委外方是否已發貨',
      isReturnInventory: '是否退回倉庫',
      outNotDelivery: '未發貨',
      outHasDelivery: '已發貨',
      returnToOut: '退回委外方',
      returnToInventory: '退回倉庫'
    },
    expense: {
      addExpense: '添加採購費用',
      editExpense: '編輯採購費用',
      viewExpense: '查看採購費用',
      deleteExpenseTips: '是否刪除該費用單'
    },
    price: {
      viewProductPrice: '查看產品採購價格',
      editProductPrice: '編輯產品採購價格',
      addProductPrice: '添加產品採購價格',
      deletePriceTips: '確認【刪除】該價格',
      attentionTipsTitle: '*銷售價格導入註意事項*',
      attentionTips1: '1.建議使用官方Excel模板導入數據 ',
      attentionTips2: '2.上傳EXCEL後，請準確填寫標題行號和數據起始行號',
      attentionTips3: '3.請準確填寫數據映射關係並檢查數據預覽是否相符',
      priceImport: '銷售價格導入',
      submitData: '提交數據',
      importData: '數據導入',
      dataMap: '數據映射',
      previewData: '數據預覽',
      previewResult: '導入結果',
      excelFile: 'Excel文件',
      excelTitleRowNumber: '標題行號',
      excelDataRowNumber: '數據起始行號',
      sourceTitleName: '導入列標題',
      targetTitleName: '映射對象',
      currencyType: '幣種',
      taxRate: '稅率',
      nextStep: '下一步',
      lastStep: '上一步',
      fileError: '文件類型錯誤或者未按模板格式填寫',
      fileUploading: '文件加載中',
      ignoreEmptyPrice: '忽略價格為空或為0的數據',
      productCodeMapColumnCanNotEmpty: '產品編碼映射列不能為空',
      noDataNeedImport: '沒有需要導入的數據',
      serialNumber: '序號',
      importStatus: '導入狀態',
      importErrorReason: '原因',
      success: '成功',
      fail: '失敗',
      executeResultInfo: '共{0}條記錄，成功：{1}條，跳過：{2}條'
    },
    account: {
      accountNickname: '帳戶別稱',
      accountBank: '開戶銀行',
      accountType: '帳戶類型',
      accountName: '帳戶戶名',
      addTitle: '添加供應商帳戶',
      editTitle: '編輯供應商帳戶',
      viewTitle: '查看供應商帳戶',
      accountNicknameNotEmpty: '帳戶昵稱不能為空',
      accountBankNotEmpty: '開戶銀行不能為空',
      accountTypeNotEmpty: '帳戶類型不能為空',
      accountNameNotEmpty: '帳戶名稱不能為空',
      deleteTips: '是否刪除該供應商帳戶 ?',
      accountNumber: '銀行帳號',
      accountNumberNotEmpty: '銀行帳號不能為空'
    },
    requisition: {
      queryKeyword: '編碼、供應商、關聯訂單編碼',
      startDate: '申請日期起',
      endDate: '申請日期止',
      requisitionCode: '請購單編碼',
      suggestSupplier: '建議供應商',
      supplier: '供應商',
      contact: '連絡人',
      applicationDate: '申請日期',
      applicant: '申請人',
      salesOrder: '關聯銷售訂單',
      salesOrderStatus: '訂購狀態',
      contactName: '連絡人',
      contactPhone: '連絡人電話',
      auditStatus: '審核狀態',
      auditor: '審核人',
      auditDate: '審核日期',
      deliveryDate: '交貨日期',
      currency: '貨幣',
      addRequisitionTitle: '添加請購單',
      editRequisitionTitle: '編輯請購單',
      viewRequisitionTitle: '查看請購單',
      baseInfo: '基本資訊',
      auditTips: '確認【保存並審核】該請購單',
      deleteTips: '確認【刪除】該請購單',
      revokeTips: '確認【撤銷】該請購單',
      amountTax: '價稅總計',
      amountSubtotal: '價稅小計',
      purchase: '採購',
      multiPurchase: '多供應商採購',
      deliveryAddress: '交貨地址',
      notOrdered: '未訂購',
      partialOrdered: '部分訂購',
      fullOrdered: '全部訂購',
      purchaseOrder: '採購訂單',
      applicantTips: '申請人不能為空',
      applicationDateTips: '申請日期不能為空',
      currencyIdTips: '貨幣不能為空'
    },
    tooltip: {
      purchaseOrderView: {
        source: '資料來源: 採購管理-採購訂單',
        rule: '取數規則: 已入庫的採購收貨，已確認、待確認的採購付款',
        dimension: '統計維度: 訂單按月按統計已收金額、付款金額'
      },
      orderSupplierAmount: {
        source: '資料來源: 採購管理-採購訂單',
        rule: '取數規則: 已入庫的採購收貨，已確認、待確認的採購付款',
        dimension: '統計維度: 供應商按月按統計訂單'
      },
      orderProductAmount: {
        source: '資料來源: 採購管理-採購訂單',
        rule: '取數規則: 已審核、已完成的採購訂單',
        dimension: '統計維度: 產品按月按統計訂單'
      },
      orderReceiptSummary: {
        source: '資料來源: 採購管理-採購訂單',
        rule: '取數規則: 已審核、已完成的採購訂單，已入庫的採購收貨',
        dimension: '統計維度: 訂單按月（預設：半年）按統計訂單'
      }
    },
    config: {
      title: '採購參數設置',
      defaultAddress: '默認地址',
      popoverTitle: '收貨地址',
      updateInventoryProcessRate: '重新計算全部採購訂單收貨進度和付款進度',
      updateOutOrderProcessRate: '重新計算全部委外訂單付款進度',
      orderCompleteConfig: '訂單完成設置',
      autoCompleteTipPrefix: '收貨和付款完成時',
      autoCompleteTipSubfix: '天之後自動完成采購訂單',
      autoCompleteTip: '自動完成滿足條件的采購訂單，當設定為0時，表示不自動完成'
    },
    priceType: {
      priceTypeName: '價格名稱',
      relatedSupplier: '關聯供應商',
      relatedSupplierType: '關聯供應商類型',
      addRelatedSupplier: '添加關聯供應商',
      addRelatedSupplierType: '添加關聯供應商類型',
      addTitle: '添加價格類型',
      editTitle: '編輯價格類型',
      viewTitle: '查看價格類型',
      supplier: '供應商',
      supplierType: '供應商類型',
      priceTypeNameTips: '價格類型名稱必填',
      relatedTips: '至少關聯一種業務'
    },
    orderAlter: {
      queryKeyword: '關鍵字',
      alterVersion: '變更版本號',
      versionCreateAuto: '變更版本號自動創建',
      alterUserId: '變更人',
      alterReason: '變更原因',
      alterTime: '變更時間',
      alterStatus: '變更狀態',
      rejectReason: '拒絕原因',
      auditorName: '審核人',
      before: '變更前',
      after: '變更後',
      addTitle: '添加采購變更單',
      editTitle: '編輯采購變更單',
      viewTitle: '查看采購變更單',
      pending: '待審核',
      audited: '已審核',
      reject: '駁回',
      alterReasonNotEmpty: '變更原因不能為空',
      deleteTips: '是否確認刪除該采購變更單?',
      newPayableAmount: '應付金額（變更後）',
      newQuantityLessThanInventoryQuantity: '變更後數量不能小於已入庫數量',
      newPayableAmountLessThenPaymentAmount: '變更後應付款金額不能小於已付款金額',
      payableAmountGreatThenOrderAmountTips: '應付金額大於訂單金額，請調整底部應付款金額',
      orderAmountGreatThenPayableAmountTips: '訂單金額大於應付金額，變更成功後需手動添加剩余應付款，是否確認變更',
      alterAuditTips: '確認【保存並審核】該變更單，確認後原采購訂單將被修改',
      saveSuccessAndAuditTips: '保存成功，是否確定【審核】該變更單，確認後原採購訂單將被修改',
      alterAuditPassTips: '確認審核【通過】該變更單，確認後原采購訂單將被修改',
      orderAdjusterPlease: '請選擇變更人'
    },
    outRequire: {
      orderCode: '委外需求編碼',
      businessType: '關聯業務類型',
      businessCode: '關聯業務編碼',
      toOutOrder: '轉委外訂單',
      finish: '完成',
      end: '終止',
      trash: '作廢',
      processing: '處理中',
      batchOutOrder: '批量委外訂單',
      addTitle: '添加委外需求',
      editTitle: '編輯委外需求',
      viewTitle: '查看委外需求',
      pleaseChooseOutRequire: '請選擇委外需求',
      deleteTips: '是否確認刪除該委外需求?',
      finishTips: '是否確認完成該委外需求, 完成後委外需求數量將按委外訂單數量之和更新',
      endTips: '是否確認終止該委外需求?',
      trashTips: '是否確認作廢該委外需求?',
      addBatchOutOrderTips: '是否確認批量生成委外訂單?',
      queryKeyword: '委外需求編碼、銷售訂單編碼、收貨人',
      process: '委外需求進度',
      summaryProduct: '委外需求產品匯總',
      divideOrder: '委外需求分單',
      outRequire: '委外需求',
      outOrder: '委外訂單',
      addOutOrderSuccess: '成功生成委外訂單',
      prev: '上一步',
      next: '下一步',
      supplierOrTaxRateOrQuantityTips: '供應商 稅率 數量不能為空',
      atLeastOneOutOrder: '至少一條委外訂單',
      recommendSupplier: '建議供應商',
      overViewDeleteTips: '是否確認【刪除】',
      printTitle: '打印委外單',
      businessOperator: '銷售錄單人',
      batchSetSupplier: '批量設置',
      pleaseSelectProduct: '請選擇產品',
      pleaseSelectSupplier: '請選擇供應商',
      suggestedSupplier: '建議供應商',
      addOutOrderBatch: '確認添加所有委外訂單，添加後可前往【委外訂單】查看詳情',
      requireQuantity: '需求數量',
      outOrderQuantity: '委外訂單數量',
      supplierEmpty: '未選供應商'
    },
    outOrder: {
      addTitle: '添加委外訂單'
    }
  },
  inventory: {
    deliveryType: {
      year: '年',
      month: '月',
      day: '日',
      week: '星期',
      interval: '區間',
      orderSetting: '下單設置',
      notAutomatic: '不自動下單',
      automatic: '發貨單審核自動下單',
      defaultShippingCourierCompany: '默認發貨快遞公司',
      configDeliveryType: '配置交貨方式信息',
      dimensionDate: '例外日期',
      dateType: '日期類型',
      addDimensionDate: '添加例外日期',
      everyMonday: '每個星期一',
      everyTuesday: '每個星期二',
      everyWednesday: '每個星期三',
      everyThursday: '每個星期四',
      everyFriday: '每個星期五',
      everySaturday: '每個星期六',
      everySunday: '每個星期天',
      dimensionDateTip: '請選擇日期',
      dimensionWeekTip: '請選擇星期'
    },
    config: {
      enabledStock: '可用庫存',
      allEnabledStock: '全部可用庫存',
      realTimeStock: '即時庫存',
      addAdvanceStock: '加項-預計入庫量',
      reduceAdvanceStock: '減項-預計出庫量',
      lockStock: '減項-鎖定量',
      mallOrderStockConfig: '減項-商城訂單',
      auditedPurchaseOrder: '已審核採購訂單',
      pendingInitialIn: '待入庫-期初',
      pendingOtherIn: '待入庫-其他入庫',
      pendingFinishedProduct: '待入庫-成品入庫',
      auditedInventoryCheck: '已審核盤點單',
      pendingInterconnectOut: '待出庫-互聯調撥',
      pendingInterconnectIn: '待入庫-互聯調撥',
      paidWelfareOrder: '已兌換訂單',
      auditedSalesOrder: '已審核銷售訂單',
      pendingOtherOut: '待出庫-其他出庫',
      none: '無',
      unpaidOrder: '待付款商城訂單',
      paidOrder: '已付款商城訂單',
      pendingReportOverflow: '待入庫-報溢入庫',
      pendingReportLoss: '待出庫-報損出庫',
      salesInventoryLock: '銷售-鎖定庫存',
      confirmDistributionOrder: '已確認代發訂單',
      inventoryInAutoEnabled: '入庫通知自動生成入庫單',
      inventoryInAutoNotConfirmWarehouseId: '生成待確認入庫單',
      inventoryInAutoConfirmWarehouseId: '生成已確認入庫單',
      inventoryInDefaultOperatorId: '倉庫默認經辦人',
      inventoryOutAutoEnabled: '出庫通知自動生成出庫單',
      inventoryOutAutoNotConfirmWarehouseId: '生成待確認出庫單',
      inventoryOutAutoConfirmWarehouseId: '生成已確認出庫單',
      inventoryOutDefaultOperatorId: '倉庫默認經辦人',
      inventoryOutAutoChooseWarehouse: '是否自動匹配滿足條件的倉庫',
      inventoryOutAutoSplitOrder: '是否自動拆單',
      inventoryOutAutoRules: '出庫規則',
      defaultInWarehousePlease: '選擇默認入庫倉庫',
      defaultOutWarehousePlease: '選擇默認出庫倉庫',
      autoInventoryInConfig: '自動入庫設置',
      autoInventoryOutConfig: '自動出庫設置',
      inventoryInAutoGeneStatus: '生成入庫單狀態',
      inventoryOutAutoGeneStatus: '生成出庫單狀態',
      inventoryInAutoDefaultWarehouseId: '默認入庫倉庫',
      inventoryOutAutoDefaultWarehouseId: '默認出庫庫倉庫',
      inventoryInAutoStatusPlease: '請選擇生成入庫單狀態',
      inventoryInDefaultWarehouseIdPlease: '請選擇默認入庫倉庫',
      inventoryInDefaultOperatorIdPlease: '請選擇默認入庫經辦人',
      inventoryOutAutoStatusPlease: '請選擇生成出庫單狀態',
      inventoryOutDefaultWarehouseIdPlease: '請選擇默認出庫倉庫',
      inventoryOutDefaultOperatorIdPlease: '請選擇默認出庫經辦人',
      inventoryInTips1: '默認入庫倉庫默認為前置業務中選擇的倉庫，前置業務未選擇時使用配置的倉庫',
      inventoryOutTips1: '默認出庫倉庫默認為前置業務中選擇的倉庫，前置業務未選擇時使用配置的倉庫',
      inventoryOutTips2: '自動出庫時，負庫存校驗將會失效，可能出現負庫存',
      inventoryStockConfig: '庫存設置',
      enableStockSplitWarehouse: '可用庫存分倉'
    },
    warehouse: {
      warehouseCode: '倉庫編碼',
      warehouseName: '倉庫名稱',
      warehousePhone: '倉庫電話',
      warehouseFax: '倉庫傳真',
      warehouseManager: '倉管員',
      area: '所屬區域',
      address: '詳細地址',
      isEnabled: '啟用狀態',
      searchPlaceholder: '倉庫編碼，倉庫名稱',
      remark: '備註',
      addWarehouseTitle: '添加倉庫',
      editWarehouseTitle: '編輯倉庫',
      viewWarehouseTitle: '查看倉庫',
      deleteWarehouseTips: '是否確定刪除該倉庫?',
      warehouseNotEmpty: '請輸入倉庫名稱',
      countryIdNotEmpty: '請選擇倉庫所屬國家',
      areaIdNotEmpty: '請選擇倉庫所屬區域',
      managerIdNotEmpty: '請選擇倉庫員',
      warehouseInfo: '倉庫資訊',
      isCalcStock: '是否計算可用庫存',
      isStoreWarehouse: '是否門店倉庫',
      shippeingInfo: '發貨信息',
      shipper: '發貨人',
      shipperPhone: '發貨人電話',
      shipperMobilePhone: '發貨人手機',
      shipperArea: '發貨所屬區域',
      shipperAddress: '發貨地址',
      shipperTips: '提示：如需使用電子面單功能，該信息必須填寫，否則將無法使用電子面單',
      storeWarehouseTips: '提示：門店倉庫不計入可用庫存'
    },
    details: {
      purchaseOrder: '採購訂單',
      salesReturn: '銷售退貨單',
      outOrderIn: '委外訂單',
      salesDelivery: '銷售發貨單',
      purchaseReturn: '採購退貨單',
      outOrderOut: '委外出庫',
      mallDeliveryOrder: '商城發貨單',
      allocateOrder: '調撥單',
      checkOrder: '盤點單',
      packOrder: '裝配單',
      businessCode: '上級業務編碼',
      inventoryType: '出入庫類型',
      bussinessType: '業務類型',
      operatorId: '業務經辦人',
      warehouseOperatorId: '倉庫經辦人',
      bussinessTypeNotEmpty: '請選擇業務類型',
      operatorIdNotEmpty: '請選擇業務經辦人',
      warehouseOperatorIdNotEmpty: '請選擇倉庫經辦人',
      warehouseTips: '請選擇倉庫',
      keywords: '產品編碼、產品名稱',
      inventoryDate: '出入庫日期',
      inventoryCode: '出入庫單編碼',
      quantity: '數量',
      amount: '小計(含稅)',
      currency: '貨幣類型',
      currencyIdNotEmpty: '貨幣不能為空',
      exlcudeTaxUnitPrice: '單價(不含稅)',
      includeTaxUnitPrice: '單價(含稅)',
      warehouse: '倉庫',
      targetName: '往來單位',
      productionDate: '生產日期',
      due: '到期日期',
      productionDateTips: '請填寫生產日期',
      cargoValue: '按存貨核算演算法核算',
      costPrice: '單價',
      purchaseCargoValue: '設置採購價格核算',
      mallReturnIn: '商城退換貨',
      mallReturnOut: '商城退換貨',
      viewExpress: '查看物流信息',
      productionOrder: '生產訂單',
      inventoryInInfo: '入庫資訊',
      inventoryOutInfo: '出庫信息',
      clearProductTips: '是否確定清除產品子表',
      overProductOutTips: '產品存在超額出庫，是否繼續？，點擊確定，繼續提交，點擊取消，則不提交',
      overProductInTips: '產品存在超額入庫，是否繼續？，點擊確定，繼續提交，點擊取消，則不提交',
      saveSuccessAndWarehousingTips: '保存成功，是否確認入庫？',
      saveSuccessAndDeliveryTips: '保存成功，是否確認出庫？',
      submitStayInventoryIn: '提交待入庫',
      submitStayInventoryOut: '提交待出庫',
      updateExpress: '修改快遞',
      outProductStockBatches: '出庫批次',
      inProductStockBatches: '入庫批次',
      outProductStockBatchesQuantity: '出庫數量',
      inProductStockBatchesQuantity: '入庫數量'
    },
    in: {
      title: '入庫單',
      receiptCode: '入庫單編碼',
      inventoryDate: '入庫時間',
      warehouse: '入庫倉庫',
      inventoryType: '入庫類型',
      inventoryStatus: '入庫狀態',
      supplierName: '供應商名稱',
      keywordQuery: '入庫單編碼、往來單位',
      targetNameNotEmpty: '請選擇供應商',
      warehouseIdNotEmpty: '請選擇入庫倉庫',
      inventoryTypeNotEmpty: '請選擇入庫類型',
      inventoryDateNotEmpty: '請選擇入庫日期',
      draft: '入庫-草稿',
      pending: '待入庫',
      finished: '已入庫',
      initial: '期初',
      purchaseReceipt: '採購收貨',
      salesReturn: '銷售退貨',
      allocateCallIn: '調撥調入',
      inventoryProfit: '盤點盤盈',
      other: '其他入庫',
      finishedProduct: '成品入庫',
      materialsReturn: '生產退料',
      outsourcingOrderIn: '委外入庫',
      unpackIn: '拆裝入庫',
      packIn: '組裝入庫',
      reportOverflow: '報溢入庫',
      addTitle: '添加入庫單',
      editTitle: '編輯入庫單',
      viewTitle: '查看入庫單',
      deleteTips: '確認刪除該入庫單?',
      finishTips: '確認該入庫單入庫?',
      revokeTips: '確認撤銷該入庫單?',
      warehouseOperatorIdNotEmpty: '請選擇倉庫經辦人',
      inventoryInfo: '入庫單資訊',
      inventoryTitle: '入庫單',
      submitAndIn: '確認入庫',
      quantity: '入庫數量',
      businessUnit: '往來單位',
      serialNumber: '序號',
      generateSerial: '生成序號',
      productSerialNotEnough: '產品序號不滿足',
      manualCreate: '手工生成',
      autoCreate: '自動生成',
      codeAutoCreate: '按序號生成規則自動生成',
      serialPrefix: '序號首碼',
      serialStartNumber: '起始號',
      serialIncrement: '遞增量',
      serialTotal: '總數',
      clearSerial: '清空序號',
      inputManualSerial: '請輸入手工序號',
      replaceSerial: '存在重複序號',
      onceMaxTips: '一次生成數量不能大於1000',
      productBomTitle: '產品物料',
      mallDelivery: '商城發貨',
      orderNumber: '應入庫數量',
      deliveryNumber: '待入庫+已入庫數量'
    },
    out: {
      title: '出庫單',
      issueCode: '出庫單編碼',
      inventoryDate: '出庫時間',
      warehouse: '出庫倉庫',
      inventoryType: '出庫類型',
      inventoryStatus: '出庫狀態',
      customerName: '客戶名稱',
      keywordQuery: '出庫單編碼、往來單位',
      targetNameNotEmpty: '請選擇客戶',
      warehouseIdNotEmpty: '請選擇出庫倉庫',
      inventoryTypeNotEmpty: '請選擇出庫類型',
      inventoryDateNotEmpty: '請選擇出庫日期',
      draft: '出庫-草稿',
      pending: '待出庫',
      finished: '已出庫',
      saleDelivery: '銷售發貨',
      purchaseReturn: '採購退貨',
      allocateCallOut: '調撥調出',
      inventoryLoss: '盤點盤虧',
      other: '其他出庫',
      workshopPick: '生產領料',
      materialsRequest: '委外領料',
      unpackOut: '拆裝出庫',
      packOut: '組裝出庫',
      reportLoss: '報損出庫',
      saleDeliveryTitle: '銷售發貨單',
      saleDeliveryInventoryout: '銷售出庫單',
      addTitle: '添加出庫單',
      editTitle: '編輯出庫單',
      viewTitle: '查看出庫單',
      deleteTips: '確認刪除該出庫單?',
      finishTips: '確認該出庫單出庫?',
      revokeTips: '確認撤銷該出庫單?',
      warehouseOperatorIdNotEmpty: '請選擇倉庫經辦人',
      orderNumber: '應出庫數量',
      deliveryNumber: '待出庫+已出庫數量',
      currentNumber: '本次出庫數量',
      inventoryInfo: '出庫單信息',
      inventoryTitle: '出庫單',
      submitAndOut: '確認出庫',
      quantity: '出庫數量',
      expressSettlementMethod: '結算方式',
      recipientPhone: '收件人手機號',
      updateDeliveryWarehouse: '調整發貨倉庫',
      confirmUpdate: '確認調整',
      pleaseSelectTheDeliveryWarehouse: '請選擇發貨倉庫',
      inventoryTypeOrder: '業務單編碼',
      salesOrderAllInventoryOutTips: '確認該訂單下的發貨單全部出庫？',
      salesOrderAllInventoryOut: '全部出庫'
    },
    allocate: {
      allocateCode: '調撥單編碼',
      allocateDate: '調撥日期',
      callOutWarehouse: '調出倉庫',
      callInWarehouse: '調入倉庫',
      operator: '經辦人',
      callInOperator: '調入經辦人',
      callOutOperator: '調出經辦人',
      callInDate: '調入日期',
      callOutDate: '調出日期',
      allocateStatus: '調撥狀態',
      draft: '草稿',
      unaudit: '待審核',
      audited: '已審核',
      callIn: '已調入',
      callOut: '已調出',
      revokeAudit: '撤銷審核',
      revokeCallIn: '撤銷調入',
      revokeCallOut: '撤銷調出',
      confirmCallIn: '確認調入',
      confirmCallOut: '確認調出',
      addTitle: '添加調撥單',
      editTitle: '編輯調撥單',
      viewTitle: '查看調撥單',
      allocateInfo: '調撥單信息',
      allocateDateNotEmpty: '請選擇調撥日期',
      operatorIdNotEmpty: '請選擇經辦人',
      callOutOperatorIdNotEmpty: '請選擇調出確認人',
      callInOperatorIdNotEmpty: '請選擇調入確認人',
      callOutDateNotEmpty: '請選擇調出日期',
      callInDateNotEmpty: '請選擇調入日期',
      callOutWarehouseNotEmpty: '請選擇調出倉庫',
      callInWarehouseNotEmpty: '請選擇調入倉庫',
      allocateWarehouseNotEqual: '調入、調出倉庫不能相同',
      auditTips: '確認審核該調撥單?',
      deleteTips: '確認刪除該調撥單?',
      revokeAuditedTips: '確認撤銷審核該調撥單?',
      confirmCallOutTips: '確認調出該調撥單?',
      confirmCallInTips: '確認調入該調撥單?',
      revokeCallOutTips: '確認撤銷調出該調撥單?',
      revokeCallInTips: '確認撤銷調入該調撥單?',
      enabledOutWarehouseStock: '調出倉庫庫存',
      saveAndNotAllocate: '提交待調撥',
      saveCompleteAllocate: '完成調撥',
      allocateOutSuccess: '調出成功',
      allocateInSuccess: '調入成功'
    },
    stock: {
      product: '產品',
      warehouse: '倉庫',
      onOrder: '新增在途',
      inWarehouse: '新增在庫',
      onOrderStock: '在途庫存',
      lockStock: '鎖定庫存',
      inWarehouseStock: '在庫庫存',
      enabledStock: '可用庫存',
      onTheWayStock: '採購在途',
      salesOccupyStock: '銷售佔用',
      salesPendingShipmentStock: '銷售待發',
      otherBeInStock: '其他待入庫',
      otherBeOutStock: '其他待出庫',
      thirdEnableStock: '三方可用庫存',
      number: '出入庫數量',
      addTitle: '新增庫存',
      chooseStockProduct: '選擇庫存產品',
      warehouseIdNotEmpty: '請選擇倉庫',
      selectableDate: '可選生產日期',
      allInWarehouseStock: '總庫存',
      byProductionDate: '按生產日期',
      details: '明細',
      salesOccupyStockDetails: '銷售占用明細',
      salesPendingShipmentStockDetail: '銷售待發明細',
      lockStockDetails: '鎖定庫存明細',
      onTheWayStockDetails: '采購在途明細',
      enableBoxGaugeValue: '可用箱數',
      splitWarehouseStock: '分倉庫存',
      allWarehouseStock: '匯總庫存'
    },
    check: {
      checkCode: '盤點單號',
      checkDate: '盤點日期',
      operatorId: '經辦人',
      warehouseId: '倉庫',
      warehouseOperatorId: '倉庫經辦人',
      checkType: '盤點類型',
      partCheck: '部分盤點',
      allCheck: '全部盤點',
      checkStatus: '盤點狀態',
      quantity: '庫存數量',
      checkQuantity: '實盤數量',
      profitQuantity: '損益數量',
      checkDateNotEmpty: '請選擇盤點日期',
      warehouseIdNotEmpty: '請選擇倉庫',
      operatorIdNotEmpty: '請選擇經辦人',
      warehouseOperatorIdNotEmpty: '請選擇倉庫經辦人',
      draft: '草稿',
      finished: '已完成',
      invalided: '已作廢',
      addTitle: '添加盤點單',
      editTitle: '編輯盤點單',
      viewTitle: '查看盤點單',
      checkInfo: '盤點資訊',
      finish: '完成',
      invalid: '作廢',
      saveAndFinish: '保存並完成',
      deleteTips: '確定刪除該盤點單?',
      finishTips: '確定完成該盤點單?',
      invalidTips: '確定作廢該盤點單?',
      orderTab: '盤點單',
      detailsTab: '盤點明細'
    },
    account: {
      warehouse: '倉庫',
      startDate: '開始時間',
      endDate: '結束時間',
      productCode: '產品編碼',
      productName: '產品名稱',
      productModel: '規格型號',
      productUnit: '計價單位',
      productAssistValue: '輔助屬性',
      initialQuantity: '期初庫存數量',
      duringInQuantity: '期間入庫數量',
      duringOutQuantity: '期間出庫數量',
      endQuantity: '期末庫存數量'
    },
    common: {
      selectFromPurchaseOrder: '從採購訂單選擇產品',
      selectFromSalesDelivery: '從銷售發貨單選擇產品',
      selectFromOutsource: '從委外訂單選擇產品',
      pleaseChooseTarget: '請選擇往來單位',
      pleaseChooseOutsourceOrder: '請選擇委外訂單',
      selectFromSaleOrder: '從銷售訂單選擇產品',
      inPrintTitle: '打印入庫單',
      outPrintTitle: '打印出庫單'
    },
    business: {
      salesOrder: '銷售訂單',
      salesDelivery: '銷售發貨',
      salesReturn: '銷售貨物',
      purchaseOrder: '採購訂單',
      purchaseReturn: '採購退貨',
      allocate: '調撥單',
      check: '盤點單',
      pack: '裝配單',
      outsourceOrder: '委外訂單',
      mallReturn: '商城退換貨',
      mallReturnIn: '商城退換貨',
      mallReturnOut: '商城退換貨',
      distributionDelivery: '代發訂單發貨',
      inventorySecondmentIn: '庫存借調調入',
      inventorySecondmentOut: '庫存借調調出',
      exchangeCardOrderOut: '兌換訂單',
      exchangeVoucherOrderOut: '兌換發貨單'
    },
    scheme: {
      schemeCode: '方案編碼',
      schemeName: '方案名稱',
      schemeType: '方案類型',
      schemeStatus: '方案狀態',
      keywords: '方案編碼、方案名稱',
      isBackward: '反向拆裝',
      addPartProduct: '添加零件產品',
      addFinishedProduct: '添加成品產品',
      addTitle: '添加組合方案',
      editTitle: '編輯組合方案',
      viewTitle: '查看組合方案',
      partProductTips: '零件產品不能為空',
      finishedProductTips: '成品產品不能為空',
      deleteTips: '是否確認刪除改組裝方案?',
      schemeNameNotEmpry: '方案名稱不能為空',
      limitOnefinishedProduct: '只能成品一個產品',
      productQuantityLessThenPackQuantity: '產品數量不能小於組裝/拆裝數量'
    },
    pack: {
      packCode: '裝配單號',
      packType: ' 組裝類型',
      packDate: '裝配日期',
      packScheme: '組裝方案',
      inWarehouse: '入庫倉庫',
      outWarehouse: '出庫倉庫',
      packQuantity: '組裝數量',
      unPackQuantity: '拆裝數量',
      packStatus: '裝配狀態',
      pack: '組裝',
      unPack: '拆裝',
      addPack: '添加組裝',
      addUnpack: '添加拆裝',
      scheme: '組合方案',
      inWarehouseDetails: '入庫明細',
      outWarehouseDetails: '出庫明細',
      addUnpackTitle: '添加拆裝單',
      editUnpackTitle: '編輯拆裝單',
      viewUnpackTitle: '查看拆裝單',
      addPackTitle: '添加組裝單',
      editPackTitle: '編輯組裝單',
      viewPackTitle: '查看組裝單',
      draft: '草稿',
      pending: '待確認',
      finished: '已確認',
      deleteTips: '是否刪除裝配單?',
      revokeTips: '是否撤銷裝配單?',
      packDateTips: '請選擇裝配日期不能為空',
      packTypeTips: '裝配類型不能為空',
      inWarehouseTips: '入庫倉庫不能為空',
      outWarehouseTips: '出庫倉庫不能為空',
      inventoryPackSchemeId: '裝配方案不為空',
      partProduct: '零件產品',
      finishedProduct: '成品產品',
      saveSuccessAndConfirmPackTips: '保存成功，是否確認完成組/拆裝？',
      printTitle: '打印組裝拆裝單',
      maxUnPackQuantity: '最大可拆裝數量',
      maxPackQuantity: '最大可組裝數量'
    },
    expireManage: {
      keywords: '請輸入產品名稱或者產品編碼',
      warehouseName: '倉庫',
      productName: '產品名稱',
      productCode: '產品編碼',
      productModel: '規格型號',
      productConfig: '輔助屬性',
      productUnit: '計價單位',
      count: '數量',
      InventoryDate: '入庫日期',
      productDate: '生產日期',
      expireData: '保質期',
      due: '過期日期',
      expired: '已到期',
      expireAbout: '即將到期'
    },
    tab: {
      saleDelivery: '銷售發貨',
      purchaseReturn: '採購退貨',
      outsourcing: '委外領料',
      outsourcingIn: '委外入庫',
      purchaseReceipt: '採購收貨',
      saleReturn: '銷售退貨',
      mallDelivery: '商城發貨',
      mallReturn: '商城退換貨',
      mallDeliverySelf: '商城自提發貨',
      distributionDelivery: '代發訂單發貨',
      inventorySecondment: '庫存借調',
      exchangeCardOrder: '兌換訂單',
      exchangeVoucherOrder: '兌換發貨單'
    },
    inventoryOutStatus: '出庫狀態',
    inventoryInStatus: '入庫狀態',
    inventoryProcessRate: '入庫進度',
    status: {
      notStock: '未出庫',
      partialStock: '部分出庫',
      outOfStock: '已出庫',
      notWarehous: '未入庫',
      partialWarehous: '部分入庫',
      outOfWarehous: '已入庫'
    },
    cargoValue: {
      warehouseCargoValue: '倉庫貨值',
      catsCargoValue: '分類貨值',
      brandCargoValue: '品牌貨值',
      productCargoValue: '產品貨值',
      warehouseQueryKeyword: '倉庫名稱',
      productQueryKeyword: '產品名稱 產品編碼 產品型號',
      detail: '貨值明細'
    },
    lock: {
      inventoryLockCode: '申請編碼',
      applicantId: '申請人',
      applyDate: '申請日期',
      effectType: '鎖定類型',
      customer: '客戶',
      receiptAmount: '收款金額',
      lockDate: '鎖定日期',
      unlockDate: '解鎖日期',
      lockReason: '鎖定原因',
      lockStatus: '狀態',
      direct: '直接生效',
      assignDate: '指定日期生效',
      deliveryOn: '款到生效',
      draft: '草稿',
      pending: '待審核',
      audited: '已審核',
      effected: '已生效',
      unlocked: '已解除',
      deleteTips: '是否刪除該鎖定庫存記錄?'
    },
    warning: {
      ltMinStock: '小于最小库存',
      ltSafetyStock: '小于安全库存',
      gtMaxStock: '大于最大库存'
    }
  },
  theme: {
    change: '換膚',
    documentation: '換膚文檔',
    tips: 'Tips: 它區別於 navbar 上的 theme-pick, 是兩種不同的換膚方法，各自有不同的應用場景，具體請參考文檔。'
  },
  tagsView: {
    refresh: '刷新',
    close: '關閉',
    closeOthers: '關閉其它',
    closeAll: '關閉所有'
  },
  settings: {
    title: '系統佈局配置',
    theme: '主題色',
    tagsView: '開啟 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '側邊欄 Logo'
  },
  basicdata: {
    dataListTitle: '基礎資料',
    keyNameZh: '名稱(簡體)',
    keyNameEn: '名稱(英文)',
    keyNameZhTw: '名稱(繁體)',
    keyValue: '值',
    remark: '備註',
    dataSort: '排序',
    customerLevel: '客戶級別',
    customerSource: '客戶來源',
    customerStatus: '客戶狀態',
    addressType: '網址類別型',
    contactType: '連絡人類型',
    followUpType: '跟進類型',
    followUpWay: '跟進方式',
    productKind: '產品分類',
    productUnit: '計價單位',
    supplierType: '供應商類型',
    purchaseDeliveryType: '採購送貨方式',
    invoiceType: '開票類型',
    receivePayment: '收付款方式',
    settlementMethod: '結算方式',
    currencyType: '貨幣類型',
    taxRate: '稅率',
    accountType: '帳戶類型'
  },
  syscode: {
    code: '編碼',
    nameZh: '名稱(簡體)',
    nameEn: '名稱(英文)',
    nameZhTw: '名稱(繁體)',
    express: '運算式',
    sort: '排序',
    remark: '備註',
    statictext: '編號首碼',
    bussinessproperty: '業務屬性',
    dateexpress: '日期格式',
    seqexpress: '序列',
    seqminlength: '最小長度',
    seqstartvalue: '起始值',
    seqrandom: '亂數',
    ranvaluetype: '亂數類型',
    ranonlynum: '數字',
    ranonlychar: '字母',
    ranmix: '數位字母混合',
    ranlength: '亂數長度',
    createexpress: '生成運算式',
    fullexpress: '完整運算式',
    example: '示例'
  },
  system: {
    switchLanguageSuccess: '語言切換成功'
  },
  exception: {
    tenantInfoNull: '未獲取到租戶資訊',
    tenantExpired: '租戶已過期,請聯繫管理員',
    noPermission: '您沒有相關許可權，請聯繫系統管理員進行授權',
    tenantExpiredOrWebsiteError: '功能變數名稱不正確或租戶已過期',
    jwtAuthFail: '登錄超時，請重新登錄',
    jwtAuthOverdue: '登錄超時，請重新登錄',
    networkError: '系統異常，請聯繫系統管理員或平臺運營方',
    unknownError: '未知錯誤',
    loginNameError: '請輸入正確的登錄名',
    realNameEmpty: '請輸入姓名',
    passwordTooShort: '密碼長度不能少於6位元',
    passwordErrorLock: '密碼錯誤，超過{0}次將鎖定，你還有{1}次機會',
    passwordErrorLock1: '密碼錯誤，超過5次將鎖定，你還有4次機會',
    passwordErrorLock2: '密碼錯誤，超過5次將鎖定，你還有3次機會',
    passwordErrorLock3: '密碼錯誤，超過5次將鎖定，你還有2次機會',
    passwordErrorLock4: '密碼錯誤，超過5次將鎖定，你還有1次機會',
    confirmPasswordError: '兩次輸入的密碼不一致',
    tenantKeyError: '租戶資訊不能為空',
    loginIncorrect: '登錄名或密碼不正確',
    userNotExist: '用戶不存在',
    passwordIncorrect: '密碼不正確',
    userDisabled: '用戶已被禁用，請聯繫管理員',
    userLocked: '用戶已被鎖定，請聯繫管理員',
    multiTerminalLoginError: '您的帳號於{0}在別處登錄。如非本人操作，則密碼可能已洩漏，建議修改密碼！',
    wechatOrgSyncFail: '企業微信通訊錄同步失敗，請聯繫管理員',
    wechatCorpIdEmpty: '未配置企業微信的企業Id，請先配置',
    wechatOrgSyncSecretEmpty: '未配置企業微信通訊錄同步Secret，請先配置',
    wechatOrgSyncNotAuth: '企業微信通訊錄同步未授權，請先聯系系統運維人員進行授權',
    wechatCaracalToolsNotAuth: '獰貓雲工具應用未授權，請先聯系系統運維人員進行授權',
    getWeworkServiceError: '獲取企業微信服務失敗',
    weworkLoginError: '開啟企業微信登錄 需要配置企業id、企業應用id、企業應用Secret',
    weworkConfigError: '請配置企業id、企業應用id、企業應用Secret',
    wechatAppIdEmpty: '未配置企業微信的應用Id，請先配置',
    wechatAppSecretEmpty: '未配置企業微信的應用Secret，請先配置',
    getWechatOrgFail: '獲取企業微信部門資訊失敗',
    getWechatUserFail: '獲取企業微信使用者資訊失敗',
    getWechatTagFail: '獲取企業微信標籤資訊失敗',
    getWechatTagUserFail: '獲取企業微信標籤用戶資訊失敗',
    wechatContactmeError: '生成企業微信[聯系我]二維碼失敗,請檢查用戶是否加入企業微信',
    wechatExternalContactError: '獲取外部聯系人失敗',
    securityAuthFail: '您沒有許可權，請聯繫管理員',
    paramError: '參數錯誤',
    addDataFail: '添加數據失敗',
    updateDataFail: '更新資料失敗,資料不存在或已被修改，請刷新後重試',
    deleteDataFail: '刪除資料失敗,資料可能操作過,請刷新後重試',
    getDataFail: '獲取資料失敗',
    dataNotExist: '資料不存在',
    roleNameEmpty: '角色名稱不能為空',
    orgNameEmpty: '部門名稱不能為空',
    orgTypeError: '部門類型不正確',
    hasOrgChildren: '該部門下存在子部門，不允許刪除',
    hasOrgUser: '該部門下存在用戶，不允許刪除',
    orgNameRepeatError: '同一部門下部門名稱不能重複',
    loginNameEmpty: '登錄名不能為空',
    passwordEmpty: '密碼不能為空',
    emailError: '請輸入正確的郵箱',
    imageTypeError: '請選擇正確的圖片(jpg,gif,png)',
    avatarSizeError: '上傳頭像圖片大小不能超過 2MB!',
    logoSizeError: 'Logo圖片大小不能超過 2MB!',
    imageSizeError: '圖片大小不能超過 {0}MB!',
    fileSizeError: '檔大小不能超過 {0}MB!',
    passwordSame: '新密碼和舊密碼不能相同',
    loginNameExist: '登錄名已存在',
    saveFileFail: '保存檔失敗',
    uploadFileError: '上傳檔失敗',
    noUploadFile: '請選擇要上傳的檔',
    getFilePathFail: '獲取檔路徑失敗',
    basicDataNameEmpty: '名稱不能為空',
    basicDataValueEmpty: '值不能為空',
    attachmentTypeError: '附件不是指定類型的檔',
    attachmentSizeTooLarge: '附件超過指定大小',
    companyNameEmpty: '企業名稱不能為空',
    taxNumberEmpty: '企業稅號不能為空',
    noChooseAnyData: '未選擇任何資料',
    noAddressTypeSelected: '未選擇網址類別型',
    noCountrySelected: '未選擇國家',
    noAreaSelected: '未選擇區域',
    addressEmpty: '詳細地址不能為空',
    customerNameEmpty: '客戶名稱不能為空',
    customerNameExisted: '客戶名稱已存在',
    clueNameEmpty: '線索名稱不能為空',
    noCustomerChoose: '沒有選擇客戶',
    managerNoExisted: '客戶經理不存在',
    targetManagerNoExisted: '目標客戶經理不存在',
    nickNameEmpty: '昵稱不能為空',
    noCustomerSelected: '未選擇客戶',
    noContactSelected: '未選擇連絡人',
    noFollowUpTypeSelected: '未選擇跟進類型',
    noFollowUpWaySelected: '未選擇跟進方式',
    followUpContentEmpty: '未填寫跟進內容',
    followUpTimeEmpty: '未填寫跟進時間',
    resubmitFail: '您操作太快了，請喝口水再操作',
    productTypeValueOrNameExist: '產品分類類名或鍵值已存在',
    typeExistProduct: '該產品分類存在產品',
    bussinessIdNotEmpty: '業務id不能為空',
    bussinessTypeNotEmpty: '業務類型不能為空',
    inventoryTypeNotEmpty: '出入庫類型不能為空',
    inventoryStatusNotEmpty: '出入庫狀態不能為空',
    targetIdNotEmpty: '往來對象不能為空',
    targetNameNotEmpty: '往來對象不能為空',
    productsNotEmpty: '產品清單不能為空',
    existCollectionOrDelivery: '該訂單存在收款或發貨',
    exitDelivery: '訂單存在發貨記錄',
    existCollection: '訂單存在已產生應收款記錄或者存在收款記錄',
    existPay: '訂單存在付款記錄',
    existInventory: '訂單存在出入庫記錄',
    existReturnOrder: '訂單存在退貨單',
    supplierNameExist: '供應商名稱已存在',
    supplierShortNameExist: '供應商簡稱已存在',
    deleteMaterialFail: '刪除產品物料清單失敗',
    currencyNeeded: '請選擇貨幣查詢對應產品價格',
    productExistCurrencyPrice: '該產品存在當前貨幣的價格',
    systemReportConfigEmpty: '系統範本為空，請聯繫管理員',
    getSystemReportError: '該業務未配置列印模版，請聯繫管理員配置',
    systemReportTypeEmpty: '系統範本類型為空，請聯繫管理員',
    systemDefaultReportEmpty: '系統預設範本為空，請聯繫管理員',
    updateRequisitionStatusFail: '更新請購單狀態失敗',
    existOrderDataNotAllowRevoke: '存在訂單資料，不允許撤銷',
    orderExistDelivery: '訂單存在發貨資料',
    orderExistExpense: '訂單存在銷售費用',
    orderAmountLessThan: '修改後的訂單金額不能小於銷售發貨金額或銷售回款金額，請確認',
    selectedOrderAmountLessThan: '選中訂單中存在修改後的訂單金額小於銷售發貨金額或銷售回款金額，請確認',
    orderExistReceivable: '訂單存在應收款記錄',
    orderExistAccountReceivable: '訂單存在收款記錄',
    salesOrderExistInventoryOutProduct: '訂單存在關聯出庫單, 不能刪除',
    salesOrderProductDeleteLessThenDelivery: '缺少銷售訂單關聯的發貨單中的產品，不能審核通過',
    salesOrderProductQuantityLessThenDelivery: '銷售訂單的產品數量小於關聯的發貨單中的產品，不能審核通過',
    salesOrderProductDeleteLessThenInventory: '缺少銷售訂單關聯的出庫單中的產品，不能審核通過',
    salesOrderProductQuantityLessThenInventory: '銷售訂單的產品數量小於關聯的出庫單中的產品，不能審核通過',
    salesOrderExistInventory: '銷售訂單存在出庫記錄，不能撤銷，請與倉庫協調處理',
    salesOrderDeliveryExistInventory: '銷售訂單的發貨單存在出庫記錄，不能撤銷，請與倉庫協調處理',
    salesOrderExistCollection: '銷售訂單存在收款記錄, 請與財務協調處理',
    salesOrderDeliveryExistCollection: '銷售訂單的發貨單存在收款記錄，不能撤銷, 請與財務協調處理',
    b2cOrderDetailsEmpty: 'b2c訂單明細為空',
    b2cCompanyEmpty: 'b2c明細中包含系統不存在公司',
    b2cCustomerEmpty: 'b2c明細中包含系統不存在客戶',
    b2cProductCodeEmpty: 'b2c明細中包含系統不存在產品編碼',
    b2cCompanyCountError: '文檔中包含錯誤公司名稱',
    b2cCustomerCountError: '文檔中包含錯誤客戶編碼',
    b2cProductCountEmpty: '文檔中包含錯誤產品編碼',
    addSalesOrderB2cFail: '添加B2C主表失敗',
    addSalesOrderB2cDetailsFail: '添加B2C明細失敗',
    b2cOrderAddressEmpty: '文檔中包含位址為空資料',
    productQuantityEmpty: '文檔中包含產品數量為空資料',
    productStockNotExist: '文檔中包含產品開啟保質期管理無庫存資料',
    unitPriceEmpty: '文檔中包含單價為空數據',
    productStockOut: '庫存不足',
    addStockFail: '添加庫存失敗',
    addInventoryOutFail: '添加出庫單失敗',
    inventoryInIsFinishedError: '該入庫單已完成入庫',
    inventoryOutIsFinishedError: '該出庫單已完成出庫',
    companyNameError: '公司名稱為空',
    deleteTodoListFail: '刪除待辦事項失敗',
    todoListProcessorEmpty: '待辦事項處理人為空',
    addTodoListFail: '添加待辦事項失敗',
    addTodoListProcessorFail: '添加待辦事項處理人失敗',
    updateTodoListFail: '更新待辦事項失敗',
    updateSalesOrderB2cDetailsFail: '更新b2c明細失敗',
    deleteSalesOrderB2cDetailsFail: '刪除b2c明細失敗',
    updateSalesOrderB2cFail: '更新b2c訂單失敗',
    salesOrderContainDelivery: '部分銷售訂單已發貨，請重新選擇',
    businessOrderContainReceivable: '部分單據已存在應收款，請重新選擇',
    receivableOrderEmpty: '未生成訂單，無法生成應收款',
    receivableDeliveryEmpty: '未生成發貨單， 無法成應收款',
    receivableContainCollection: '部分應收款已收款',
    auditProcessorEmpty: '訂單審核處理人不能為空',
    salesAuditConfigEmpty: '自由審核流程，審核設置為空，請設置流程',
    triggerConditionError: '自由審核流程，觸發條件錯誤',
    productTypePriceNotExist: '自由審核模式，產品未設置價格類型對應價格',
    salesOrderIsAuditing: '自由審核模式，銷售訂單正在審核中',
    salesOrderRecallFail: '撤回銷售訂單失敗',
    purchaseOrderExistPayment: '採購訂單存在付款記錄，請刪除付款單後撤銷',
    taxRateEmpty: '稅率為空',
    orderStatusChange: '訂單狀態已更改，請刷新頁面資料',
    categoryLevelConfigError: '分類層級設置錯誤',
    parentCategoryNotExist: '父級分類不存在',
    categoryNameOrKeyValueExist: '同級分類下存在名稱或鍵值相同',
    categoryExistChildren: '分類下存在子級，不能刪除',
    categoryExistGoods: '分類下存在商品',
    specsStatusEmpty: '商品規格類型為空',
    goodsSingleSkuError: '商品單規格資訊錯誤',
    goodsSpecsTypeEmpty: '商品多規格類型不能為空',
    goodsMultiSkuSpecsEmpty: '商品多規格不能為空',
    specsTypeParamError: '規格類型參數錯誤',
    goodsMultiSkuSpecsMapError: '商品多規格映射錯誤',
    noSkuForItem: '沒有此商品SKU',
    shoppingCartDataIncorrect: '購物車數據異常',
    orderHasBeenShipped: '訂單已發貨,不能修改位址',
    mallNameNotConfig: '未配置商城名稱',
    overMaxCategoryLevel: '分類層級最大為3級，請重新調整',
    portalPhoneIsRegister: '手機號已註冊',
    advanceReceiptIsUsed: '預收款正在被使用',
    collectionAmountLessThanReceivable: '實收金額小於應收款金額總和',
    advanceReceiptNotEnough: '可抵扣預收款不足',
    productAliasExist: '產品別名已存在',
    productBarcodeExist: '產品條碼已存在',
    advancePaymentIsUsed: '預付款正在被使用',
    paymentAmountLessThanPayable: '實付金額小於應付款金額總和',
    advancePaymentNotEnough: '可抵扣預付款不足',
    financePayableNotExist: '應付款不存在',
    payablePaidGreatThenBalance: '存在應付款本次付款金額大於應付款餘額',
    financeReceivableNotExist: '應收款不存在',
    receivableCollectionGreatThenBalance: '存在應收款本次收款金額大於應收款餘額',
    rechargeAmountDiffPaidAmount: '充值金額與支付金額不一致',
    selfPickUpCodeError: '提貨碼錯誤',
    userMobileExist: '用戶手機號碼已存在',
    notRelatedSalesOrder: '未關聯銷售訂單',
    existOrderIsAdjusting: '該訂單存在正在變更記錄',
    salesOrderAdjustProductEmpty: '變更訂單產品不能為空',
    inventoryQuantityGreatThenOrderQuantity: '變更後數量小於已發貨數量',
    advanceReceiptTypeLimitEmpty: '預收款類型使用範圍不能為空',
    advanceReceiptTypeIsUsed: '預收款類型已被使用, 不能刪除',
    salesDeliveryExistInventory: '銷售發貨單存在出庫記錄',
    salesDeliveryExistCollection: '銷售發貨單存在收款記錄',
    advanceReceiptLessThanBalance: '預收款余額不足',
    defaultAdvanceReceiptTypeNotExist: '默認預收款類型不存在',
    getAdvanceReceiptFail: '查詢預收款失敗',
    receivableItemDirectTypeEmpty: '未選擇定向預收款類型',
    orderCollectionGreatThenOrderAmount: '訂單變更後金額不能小於訂單已收款金額',
    advanceReceiptDefaultAccountEmpty: '預收款類型未設置默認資金賬戶',
    salesOrderExistOutOrder: '銷售訂單存在委外發貨',
    cancelOutRequireExistOutOrder: '委外需求存在委外訂單不能直接作廢',
    outRequireExistOrder: '委外需求存在委外訂單，請刪除數據後操作',
    queryDateRangeEmpty: '查詢日期範圍不能為空',
    smsAccountNotExist: '短信賬號信息不存在',
    smsUsableCountNotEnough: '短信可用剩餘量不足',
    addContactIsName: '添加聯繫人，需填寫稱呼',
    financeConfirmed: '收款單已確認，不能駁回，請刷新重試',
    financeReceivableConfirmed: '應收款已確認，不能駁回，請刷新重試',
    isAfterSalesOrder: '存在進行中的售後單',
    qrCodeCreateFail: '小程序碼生成失敗',
    welfareExchangeCardInfoError: '卡券信息錯誤，兌換失敗',
    exchangeCardFail: '兌換卡券失敗',
    welfareExchangeCardIsUsed: '卡券已被兌換',
    orderStatusChangedPleaseRefresh: '订单状态状态已变更，请刷新界面',
    noTemplateCompany: '所屬公司下不存在選擇的微信支付模板，請刷新重試',
    salesOrderNotDelivery: '銷售訂單未發貨',
    salesOrderNotInventory: '銷售訂單未出庫',
    salesOrderExistNotHandleDelivery: '銷售訂單存在未處理完成的發貨單',
    salesOrderExistNotHandleInventory: '銷售訂單存在未處理完成的出庫單',
    salesOrderExistNotHandleOutRequire: '銷售訂單存在未處理完成的委外需求',
    salesOrderExistNotHandleOutOrder: '銷售訂單存在未處理完成的委外訂單',
    salesOrderNotAllDelivery: '銷售訂單未完成發貨',
    salesOrderNotAllCollection: '銷售訂單未完成收款',
    salesOrderExistNotHandleCollection: '銷售訂單存在未處理完成的收款',
    salesOrderAdjustReceivableAmountLessCollectionAmount: '銷售訂單變更應收款金額小於已收款金額',
    salesDeliveryAdjustReceivableAmountLessCollectionAmount: '銷售發貨變更應收款金額小於已收款金額',
    salesOrderFinishCanNotOperate: '銷售訂單已完結不能進行操作',
    salesRetailPriceEmpty: '零售價不能爲空',
    appHaveOnlinePlanNotDelete: '小程序存在上線的方案不能刪除',
    appNotHave: '關聯小程序不存在，請刷新重試',
    salesAccountStatementStatusChanged: '对账单状态已更改，请刷新页面重试',
    salesAccountStatementHasGeneNew: '新对账单已经生成不能撤销',
    planCodeNotNull: '方案编号不能重复',
    existNotDealOutOrder: '存在未處理的委外訂單，不能進行完成操作',
    returnOrderStatusChange: '退貨單狀態已修改，請刷新頁面重試',
    salesOrderOutDeliveryQuantityEmpty: '委外數量不能為空',
    salesOrderOutDeliveryQuantityGreatThen: '委外數量大於訂單數量',
    mallOrderDeliveredCanNotRevokeFinance: '商城訂單已發貨，財務不能進行撤銷作廢操作',
    salesOrderOutDeliveryNotComplete: '銷售訂單委外發貨未完成，銷售訂單不能操作完成',
    salesOrderInventoryNotComplete: '銷售訂單出庫未完成, 銷售訂單不能操作完成',
    existMemberBindCanNotDelete: '存在會員綁定關系不允許刪除',
    orderPaymentGreatThenNewOrderAmount: '訂單變更後金額不能小於訂單已付款金額',
    orderAlterPayableAmountLessPaymentAmount: '訂單變更應付款金額小於已付款金額',
    outOrderNotDeliveryCanNotComplete: '請選擇已發貨數據進行批量完成',
    outOrderNotDeliveryCanNotAudit: '請選擇待審核訂單進行批量審核',
    purchaseOutOrderExistPayment: '委外訂單存在付款記錄，請刪除付款單後撤銷',
    syncSupplierProductFailed: '同步供應商產品失敗',
    wechatTransferErrorCode: '微信轉賬失敗',
    commissionNotEnough: '可提現傭金余額不足',
    commissionSummaryError: '傭金匯總數據錯誤',
    orderHasOverReturn: '訂單超額退貨',
    deliveryHasOverReturn: '發貨單超額退貨',
    dataStatusChanged: '狀態已變更，請重繪介面重試',
    customerRebateNotEnough: '客戶可提現返點金額不足',
    existImportingOrder: '存在正在導入的任務，系統繁忙，請稍後重試',
    orderIsAfterSales: '訂單正在發起售後，請先完成相關售後單再操作,若已退款請提醒用戶在小程序關閉相關售後單',
    orderReturnAmountOver: '訂單退款金額大於訂單金額，申請退款失敗',
    returnGoodsEmpty: '退換商品為空',
    cardHasExchange: '卡券已經發生兌換',
    cardHasActivateNotAllowDelete: '該方案已關聯了核銷訂單，不允許删除',
    activateCardExist: '【{0}】卡券已被核銷',
    existChildAttachment: '文件夾內包含附件，請先刪除附件',
    appIdNotConfig: '未配置小程序app_id',
    appSecretNotConfig: '未配置小程序secret_id',
    orderDeliveryOver: '订单已超额发货，请检查发货信息后操作',
    customerCreditCheckError: '客户授信额度不足，不能提交订单。请联络客户支付相关款项或者提升授信额度后再来操作',
    installRateConfigError: '分期比例设置错误，全部比例之和为100',
    customerCodeEmpty: '請輸入客戶編碼',
    customerCodeExist: '客戶編碼已存在',
    partReceivableNotExist: '部分應收款已不存在，請檢查應收款數據重新提交',
    partPayableNotExist: '部分應付款已不存在，請檢查應付款數據重新提交',
    salesOrderCodeEmpty: '請輸入銷售訂單編碼',
    salesOrderCodeExist: '銷售訂單編碼已存在',
    salesOrderSelfCodeExist: '自編訂單號已存在或與訂單編碼重復',
    overloadInventoryIn: '【{0}】超額入庫',
    overloadInventoryOut: '【{0}】超額出庫',
    salesReturnNotInventoryInComplete: '銷售退貨未完成入庫',
    salesReturnNotInventoryOutComplete: '銷售退貨未完成出庫',
    salesReturnNotRefundComplete: '銷售退貨未完成退款',
    inventoryInAutoWarehouseNotConfig: '未配置倉庫，無法自動生成入庫單',
    inventoryInAutoStatusNotConfig: '未配置自動生成入庫單狀態',
    inventoryOutAutoWarehouseNotConfig: '未配置倉庫，無法自動生成出庫單',
    inventoryOutAutoStatusNotConfig: '未配置自動生成出庫單狀態',
    purchaseReturnExistInventory: '存在出入庫數據，不能撤銷',
    purchaseReturnExistFinance: '存在財務退款數據，不能撤銷',
    salesReturnExistInventory: '銷售退貨存在出入庫數據',
    existFinanceReceivableCanNotOutRequire: '存在應收款，不能轉委外需求，請先變更發貨單',
    deliveryOutRequireQuantityGreatThen: '超額發貨, 請檢查倉庫發貨或委外發貨情況',
    salesOrderExistReturn: '銷售訂單存在銷售退貨',
    salesDeliveryExistReturn: '發貨單存在銷售退貨',
    purchaseOrderExistReturn: '采購訂單存在采購退貨',
    memberVoucherIsUsed: '代金券已被使用',
    memberVoucherIsExpired: '代金券已過期',
    memberVoucherNotEnough: '代金券余額不足',
    memberVoucherAvailableNotEnough: '代金券抵扣金額超出可抵扣商品金額',
    memberVoucherDeductError: '代金券抵扣失敗',
    memberVoucherExistDeleteError: '代金券已發放，刪除失敗',
    voucherCardReceived: '代金券卡已被領取',
    voucherCardError: '代金券卡號信息錯誤',
    outOrderQuantityGreatThenOutRequire: '委外訂單數量超出委外需求數量',
    financeHasSalesRefund: '銷售退款，財務已退款',
    salesReturnOutQuantityNotEnough: '銷售委外可退數量不足，請變更委外訂單',
    salesReturnNotInventoryQuantityNotEnough: '未發貨可退數量不足',
    salesReturnInventoryQuantityNotEnough: '已發貨可退數量不足',
    mallReturnQuantityError: '退換總數錯誤',
    mallOrderOverReturn: '訂單超額退貨',
    mallReturnNotInventoryQuantityError: '未出庫退貨數量錯誤',
    mallReturnInventoryQuantityError: '已出庫退貨數量錯誤',
    mallReturnOutQuantitySumError: '委外退貨總數錯誤',
    mallReturnOutOrderQuantityError: '委外已發退貨數量錯誤',
    mallOrderHasDelivery: '訂單已發貨',
    orderStatusCanNotFreeGoods: '當前訂單狀態不能添加贈品',
    orderHasReturnCanNotFreeGoods: '訂單存在售後，不能添加贈品',
    exchangeQuantityEmpty: '換品數量不能為空',
    exchangeQuantityOver: '換品數量超出已發貨數量',
    orderHasReturnCanNotExchangeGoods: '訂單存在售後，不能換品',
    mallOrderExchangeAmountError: '訂單換品金額不一致',
    authRelateProductNotExist: '互聯授權產品不存在',
    inventoryInError: '入庫失敗, 請檢查庫存借調訂單',
    inventoryOutError: '出庫失敗, 請檢查庫存借調訂單',
    inventorySecondmentOutOver: '庫存借調超額出庫',
    inventorySecondmentInOver: '庫存借調超額入庫',
    salesReturnNotExistOutReturn: '請先進行委外退貨操作',
    mallReturnNotExistOutReturn: '請先進行委外退貨操作',
    purchaseOutOrderExistReturn: '委外訂單存在退貨',
    salesOrderReturnNotDeal: '銷售訂單退換貨未處理',
    salesOrderReturnNotDealInventory: '銷售訂單退換貨未處理出入庫',
    salesOrderReturnNotDealFinance: '銷售訂單退貨未處理退款',
    salesDeliveryReturnNotDeal: '銷售發貨單退換貨未處理',
    salesDeliveryReturnNotDealInventory: '銷售發貨單退換貨未處理出入庫',
    salesDeliveryReturnNotDealFinance: '銷售發貨單退貨未處理退款',
    salesOrderExistAdjust: '銷售訂單存在變更單',
    salesDeliveryExistAdjust: '銷售發貨單存在變更單',
    salesDeliveryNotDealInventory: '銷售發貨單未處理完出庫',
    salesDeliveryNotDealFinance: '銷售發貨未處理完收款',
    outRequireNotOutOrder: '委外需求未處理，請轉委外訂單',
    purchaseOrderNotInventory: '采購訂單未入庫',
    purchaseOrderExistNotHandleInventory: '采購訂單存在未完成的入庫單',
    purchaseOrderNotInventoryComplete: '采購訂單未完成入庫',
    purchaseOrderNotFinance: '采購訂單未付款',
    purchaseOrderNotCompleteFinance: '采購訂單未完成付款',
    purchaseOrderExistAdjust: '采購訂單存在變更單',
    purchaseOrderExistNotDealReturn: '采購訂單存在未處理退貨單',
    purchaseOutOrderExistAdjust: '委外訂單存在變更單',
    purchaseOutOrderNotFinance: '委外訂單未完成付款',
    customerSuperiorRepeat: '客戶上級關系循環',
    mallExchangeGoodsNotDelivery: '換貨商品未發貨',
    mallOrderOverExchangeReturn: '換貨商品已退完',
    mallExchangeGoodsNotOutReturn: '換貨商品未進行委外換貨',
    mallOrderNotDelivery: '訂單未發貨',
    multiAccountAmountError: '收付款賬戶金額合計與收付款總額不相等',
    projectLastDateError: '項目時間區間錯誤',
    projectAnalysisIsCalc: '項目正在生成數據中',
    projectDateNotCome: '該統計區間未開始或尚未結束，請再統計區間結束後再操作',
    inventorySecondmentInWarehouseStock: '可借調庫存不足',
    syscode: {
      editErrorDateCheck: '運算式格式有誤，請參考說明',
      submitErrorExpressBlank: '請生成運算式後再提交',
      submitErrorTextCheck: '啟用編號首碼後請填寫相關值',
      submitErrorDateCheck: '啟用日期後請選擇日期格式',
      checkExpressTextFailed: '請輸入數位或字母'
    },
    inventory: {
      expireDateFieldsLack: '保質期等信息缺失',
      notExistProductCode: '【{0}】產品編碼不存在',
      notExistProduct: '【{0}】產品不存在',
      costTypeError: '庫存成本類型錯誤'
    },
    inventoryIn: {
      paramsError: '入庫單參數錯誤',
      addDataFail: '添加入庫單錯誤',
      updateDataFail: '更新入庫單錯誤',
      deleteDataFail: '刪除入庫單錯誤',
      existProducts: '入庫產品記錄已存在',
      purchaseReceiptAmountLess: '確認入庫金額不能小於已付款金額',
      repeatProductSerials: '存在重複產品序號',
      productSerialNumberNotEnough: '產品序號不滿足入庫數量'
    },
    inventoryOut: {
      paramsError: '出庫單參數錯誤',
      addDataFail: '添加出庫單錯誤',
      updateDataFail: '更新出庫單錯誤',
      deleteDataFail: '刪除出庫單錯誤',
      existProducts: '出庫產品記錄已存在'
    },
    stock: {
      updateDataFail: '更新庫存失敗',
      emptyProducts: '庫存記錄不存在',
      overloadInventoryIn: '【{0}】超額入庫',
      overloadInventoryOut: '【{0}】超額出庫'
    },
    existBusinessDataNotAllowDelete: '存在使用該資料的業務資料不允許刪除',
    existBusinessDataNotAllowUpdate: '存在使用該資料的業務資料不允許更新',
    existBusinessDataNotAllowDeleteFormat: '{0}, 存在使用該資料的業務資料不允許刪除',
    existBusinessDataNotAllowUpdateFormat: '{0}, 存在使用該資料的業務資料不允許更新',
    businessDataPreventAdd: '存在業務資料阻止添加資料',
    financeAccountName: '帳戶名稱不能為空',
    financeAccountUserName: '帳戶戶名不能為空',
    financeAccountNumber: '帳戶帳號不能為空',
    financeAccountDeleteFailed: '資料可能被操作過，請刷新後重試',
    currentTypeIsNull: '沒有可用幣種',
    financeDepositAddFailed: '存取款添加失敗',
    financeDepositUpdateFailed: '資料可能被操作過，請刷新後重試',
    financeDepositDeleteFailed: '資料可能被操作過，請刷新後重試',
    financeCollectBookAddFailed: '收款單添加失敗',
    financeCollectBookUpdateFailed: '資料可能被操作過，請刷新後重試',
    collectIdIsNull: '參數為空,刪除失敗',
    financeCollectBookDeleteFailed: '資料可能被操作過，請刷新後重試',
    financeCollectBookIsNull: '收款單數據為空',
    financeCollectBookFormIsNull: '收款單添加失敗,表單不能為空',
    financePayBookAddFailed: '付款單添加失敗',
    financePayBookUpdateFailed: '資料可能被操作過，請刷新後重試',
    financePayBookDeleteFailed: '資料可能被操作過，請刷新後重試',
    financePayBookIsNull: '付款單數據為空',
    financePayBookFormIsNull: '付款單添加失敗,表單不能為空',
    isNotGetId: '未獲取到ID，請聯繫技術人員',
    isNotGetAmount: '應收金額為空',
    addFailed: '添加失敗',
    updateFailed: '資料可能被操作過，請刷新页面重試',
    childDataUpdateFailed: '子表資料更新失敗,資料不存在或已被修改,請刷新後重試',
    childDataAddFailed: '子表添加失敗,資料可能已存在,請刷新後重試',
    childDataDeleteFailed: '子表刪除失敗，資料可能已被刪除，請刷新後重試',
    collectCodeIsNull: '收款單編碼不能為空',
    childDataIsNull: '子表資料為空,操作失敗',
    receivableDeleteFailed: '參數為空,刪除失敗',
    childHaveData: '資料正在進行收款,刪除失敗',
    receivableFailed: '資料可能被操作過,請刷新後重試',
    parameterIsNull: '傳入的參數為空',
    decideSaleId: '銷售ID不能空',
    receivableCodeIsNull: '應收款編碼不能為空',
    selectCollectCodeIsNull: '收款單編碼為空',
    customerOrCurrencyIsNull: '客戶參數或者貨幣參數不能為空',
    addAdvanceReceiptFailed: '添加預收款失敗，請檢查參數',
    saleOrderIdIsNull: '銷售訂單ID不能為空',
    saleBusinessIdIsNull: '業務id不能為空',
    saleDeliveryIdIsNull: '銷售回款單ID不能為空',
    advanceLessThanBalance: '抵扣金額只能小於可抵扣金額',
    amountLessThanAmountThisTime: '本次收款只能小於等於應收金額',
    typeValueRepeat: '移入的目標級別下面的類別的鍵值不能重複',
    existNotAllowDelete: '存在使用該資料的業務資料不允許刪除',
    addItemFailed: '增值稅發票專案資料添加失敗',
    updateItemFailed: '增值稅發票專案資料更新失敗',
    deleteItemFailed: '增值稅發票專案資料刪除失敗',
    addDetailFailed: '行程單數據添加失敗',
    updateDetailFailed: '行程單數據更新失敗',
    deleteDetailFailed: '行程單數據刪除失敗',
    serialNumberNeedOnly: '填寫的印刷序號已存在',
    invoiceCodeNeedOnly: '填寫的發票代碼或發票號碼已存在',
    invoiceNumberNeedOnly: '填寫的發票號碼已存在',
    updateCategoryFailed: '批量調整產品分類失敗',
    typeExistChildren: '該分類存在子級',
    typeExistName: '分類名稱與目標分類同一等級下的分類名稱相同',
    notChangeExistTypeName: '與相同等級的分類名稱重複',
    notChangeExistTypeValue: '與相同等級的分類鍵值重複',
    maxNode: '最大分類不可超過5級',
    liveGoodsStatusNoSelect: '直播商品狀態未查詢到',
    sameProductCode: '產品編碼已存在',
    geneReceivableBatchAmountOut: '批量添加/更新應收款時，存在應收款總額大於業務總金額的資料，添加/更新失敗',
    geneReceivableAmountOut: '添加/更新應收款時，應收款總額不能大於業務總金額，添加/更新失敗',
    deliveryExistExpense: '發貨單存在費用',
    deliveryExistReturn: '發貨單存在退貨單',
    deliveryAmountLessThan: '修改後的發貨單金額不能小於銷售回款金額，請確認',
    selectedDeliveryAmountLessThan: '選中發貨單中存在修改後的發貨單金額小於銷售回款金額，請確認',
    orderBomEmpty: '物料數據為空，添加失敗',
    outBomDeleteFailed: '物料資料刪除失敗',
    revokeOrderOutFailed: '委外訂單撤銷失敗',
    invalidOrderOutFailed: '委外訂單作廢失敗',
    productEmpty: '產品資料不能空',
    planBomEmpty: '物料數據為空，添加失敗',
    planProductDeleteFailed: '生產計畫的產品資料刪除失敗',
    planBomDeleteFailed: '生產計畫的物料資料刪除失敗',
    revokePlanFailed: '生產計畫撤銷失敗',
    production: {
      bom: {
        replaceVersion: '重複的生產版本號'
      }
    },
    orderExistPayable: '訂單存在付款記錄',
    orderExistAccountPayable: '訂單存在應付款記錄',
    genePayableBatchAmountOut: '批量添加/更新應付款時，存在應付款總額大於業務總金額的資料，添加/更新失敗',
    genePayableAmountOut: '添加/更新應付款時，應付款總額不能大於業務總金額，添加/更新失敗',
    purchaseOrderExistExpense: '訂單存在費用記錄',
    purchaseOrderExistInventory: '訂單存在收貨記錄',
    purchaseOrderAmountLess: '修改後的訂單金額不能小於已收貨金額或已付款金額，請確認',
    selectedPurchaseOrderAmountLess: '已選擇訂單中，修改後的訂單金額不能小於已收貨金額或已付款金額，請確認',
    purchaseReceiptOrderExistReturn: '該採購收貨入庫單存在退貨記錄',
    existNotAllowDeleteTag: '改標籤有客戶引用',
    repeatFiscalYear: '存在重複財年',
    existTagValue: '標籤值已重複',
    companyIsExist: '所屬公司已存在開票資訊配置',
    customerCreditLimitIsNotEnough: '該客戶可用授信額度不足，請提高授信額度後再操作',
    salesPriceTypeThreshold: '銷售價格類型已有10條,無法再新增',
    expiryDateEnabledError: '存在引用資料,無法更新保質期開關',
    wxSdkError: '小程式碼生成失敗,請檢查小程式是否上線',
    notExistProduct: '【{0}】產品不存在',
    notExistProductFormat: '【{0}】產品不存在',
    mall: {
      emptyPaymentTemplate: '支付範本不存在',
      emptyPaymentAccountId: '支付範本沒有關聯帳戶',
      noExistPaymentAccountId: '支付範本關聯帳戶不存在',
      noRemainPayAccount: '餘額支付關聯賬戶不存在',
      noMonthPayAccount: '掛賬月結關聯賬戶不存在',
      noDeductionPayAccount: '定向預收款支付關聯賬戶不存在'
    },
    goodsSalesUnitPriceNotEmpty: '商品沒有設定零售價，請在【銷售管理-基礎數據-銷售價格】中設定',
    goodsPriceLevelNotEmpty: '商品沒有設置客戶結算價格標簽，請在【銷售管理-基礎數據-銷售價格】中設置',
    bindingPayTemplateFinanceAccount: '支付範本已綁定該資金帳戶,請先解除綁定再刪除',
    bindingOfflinePayMentFinanceAccount: '該帳號已在線上商城—線下付款中綁定,請先解除綁定再刪除',
    bindingRemainPayFinanceAccount: '該帳號已在線上商城—餘額付款中綁定,請先解除綁定再刪除',
    wxRefundFail: '微信退款失敗【{0}】',
    deliveryBusinessNoDelete: '發貨單已出庫,不可刪除',
    addressNoteFullNameEmpty: '姓名不能為空',
    addressNoteAddressEmpty: '位址不能為空',
    expressCompanyCodeEmptyError: '請選擇快遞公司',
    auditOrgIdCanNotBeCurrentEnterprise: '互聯方不能是當前企業',
    enterpriseApplyNotExist: '互聯申請記錄不存在',
    auditEnterpriseNotExist: '接收方企業不存在',
    enterpriseInterconnectNotExist: '互聯記錄不存在',
    enterpriseInterconnectInvalid: '互聯記錄已失效',
    oppStandardEmpty: '對方本位幣為空',
    standardEmpty: '己方本位幣為空',
    enterprise: {
      applyNotExist: '互聯申請記錄不存在',
      applyAudited: '互聯申請記錄已審核 無法刪除',
      oppStandardEmpty: '對方本位幣為空',
      applyNotAudit: '互聯申請記錄待審核',
      productMappingExist: '產品互聯映射關系已存在',
      productMappingNotExist: '【{0}】產品互聯映射關系不存在',
      generatedOrder: '當前采購訂單已生成互聯訂單',
      supplierEnterpriseMappingNotEXist: '當前供應商沒有對應的互聯企業'
    },
    memberLevelAssociatedMemberNoDelete: '該會員等級下存在會員，請先批量調整會員等級後再刪除',
    referrerCustomerCanNotBeBindCustomer: '上級（推薦人）不能是當前綁定客戶',
    purchaseAlterAmountLess: '采購變更單變更金額不能小於已收款金額',
    purchaseAlterQuantityLess: '采購變更單產品數量更不能小於已入庫產品數量',
    purchaseAlterOncePending: '采購訂單已存在一條待審核變更記錄',
    existExternalContactBinding: '該客戶 企微客戶綁定關系已存在',
    purchaseRequireProductLessThanDeliveryQuantity: '委外需求數量小於委外訂單已發貨數量',
    existDeliveryOutOrderNotAllowDelete: '存在已發貨委外訂單 【{0}】',
    noBoundCustomer: '沒有綁定客戶',
    b2cShopCodeNotExist: '小店碼不存在',
    b2cShopNotExist: 'b2c小店不存在',
    rejectReasonIsEmpty: '拒絕原因不能為空',
    b2cDistributionApplyExist: 'b2c小店分銷申請已存在，請等待審核',
    b2cDistributionApplyNoExist: 'b2c小店分銷申請記錄不存在',
    b2cDistributionDetailExist: '該小店的分銷記錄已存在',
    b2cDistributionDetailNoExist: '該小店的分銷記錄不存在',
    salesInventoryLockExistFinanceAccountBook: '【{0}】銷售鎖定庫存存在收款單',
    autoCollectionNotSetDefaultAccount: '默認預收款類型未設置默認賬戶，不能進行預收款自動抵扣，請前往財務管理-基礎數據-預收款類型進行設置',
    queryDateEmpty: '請選擇日期範圍',
    sendSmsFailFormat: '{0}',
    onlineCount: '上線廣告數已達上限',
    customPriceEmpty: '存在未設置價格商品，請設置價格後重新上線',
    imagePathEmpty: '請上傳廣告圖片',
    requestError: '數據請求失敗'
  },
  product: {
    productCode: '產品編碼',
    productName: '產品名稱',
    shortName: '產品簡稱',
    productCategory: '產品分類',
    productModel: '規格型號',
    productUnit: '計價單位',
    productMultipleUnitEnabled: '多計價單位',
    multipleUnitTips: '請輸入多計價單位或單位數量',
    multipleUnitClear: '清空',
    productSize: '包裝尺寸',
    packageSizeLength: '包裝尺寸長',
    packageSizeWidth: '包裝尺寸寬',
    packageSizeHeight: '包裝尺寸高',
    productManufacture: '生產商',
    productBrand: '產品品牌',
    productBarcode: '條碼',
    productIncode: '內部條碼',
    productDescription: '備註',
    productProperty: '產品屬性',
    productSource: '產品來源',
    productImage: '產品圖片',
    productConfig: '輔助屬性',
    invoiceName: '開票名稱',
    eCommerceLink: '電商網址',
    eCommercePlatform: '電商平臺',
    eCommerceUrl: 'Url',
    taxRate: '稅率',
    taxCategoryCode: '稅收分類編碼',
    financeInfo: '財務資訊',
    shelfLife: '保質期管理',
    shelfLifeUnit: '保質期單位',
    shelfLifeRange: '保質期',
    warningDays: '預警天數',
    stockInfo: '庫存資訊',
    minStock: '最小庫存',
    maxStock: '最大庫存',
    safetyStock: '安全庫存',
    remark: '備註',
    isEnable: '是否啟用',
    isSerialNumber: '序號管理',
    typeSort: '排序',
    parentTree: '父級',
    typeValue: '鍵值',
    typeName: '類別名稱',
    productCategoryTitle: '產品分類',
    productTypeInfo: '產品類別詳情',
    addChild: '添加子級',
    pleaseChooseProducts: '請選擇產品',
    pleaseChooseType: '請選擇產品分類',
    typeValueSpan: '同一等級鍵值不可重複',
    typeValueEmpty: '鍵值不能為空',
    pleaseInputTypeName: '請輸入類別名稱',
    pleaseInputTypeValue: '請輸入鍵值',
    pleaseChooseParentId: '請選擇父級',
    pleaseChooseDeleteProduct: '請選擇要刪除的產品',
    pleaseInputProductCode: '請輸入產品編碼',
    pleaseInputProductName: '請輸入產品名稱',
    pleaseInputInvoiceName: '請輸入開票名稱',
    pleaseChooseUnit: '請選擇計價單位',
    pleaseChooseProductCategory: '請選擇產品分類',
    adjust: '調整分類',
    pleaseChooseProduct: '請勾選要調整的產品',
    chooseNeedToType: '選擇需要調整的目標分類：',
    updateProductCode: '是否需要同時更新產品編碼：',
    previewImage: '預覽圖片',
    yes: '是',
    no: '否',
    deleteImage: '是否刪除該圖片？',
    updateProductCodeTips: '同步更新產品編碼會導致產品編碼發生變更，但不會影響歷史業務資料，請確認是否要同步更新產品編碼',
    pleaseSelectCate: '（請選擇產品分類添加產品）',
    confirmDeleteText: '請確認要删除產品已無庫存，否則部分系統數據將受影響，是否【確定】删除',
    purchasePrice: '采購價格',
    salesPrice: '銷售價格',
    enabledStatus: '启用状态',
    productionLicenseCode: '生產許可證編碼',
    outerBoxSize: '外箱尺寸',
    cartonSizeLength: '外箱尺寸長',
    cartonSizeWidth: '外箱尺寸寬',
    cartonSizeHeight: '外箱尺寸高',
    boxGauge: '箱規',
    weight: '計重重量',
    g: '克',
    mm: '毫米',
    invoiceInfo: '開票信息',
    productStock: '可用庫存',
    boxGaugeSubfix: '/箱',
    recentSalesPrice: '最近銷售價格',
    minSalesPrice: '最低銷售價格',
    maxSalesPrice: '最高銷售價格',
    standardPurchasePrice: '采購價格',
    recentPurchasePrice: '最近采購價格',
    minPurchasePrice: '最低采購價格',
    maxPurchasePrice: '最高采購價格',
    orderQuantity: '訂單數量',
    outDeliveryQuantity: '委外數量',
    deliveredQuantity: '已發數量',
    productKind: '產品品類',
    pricingMethod: '計價方式',
    pricingByQuantity: '計件',
    pricingByWeight: '計重',
    productCodeCover: '是否導入產品編碼',
    sameProductCodeConfig: '相同產品編碼導入方式',
    sameProductCodeSkip: '跳過',
    sameProductCodeUpdate: '更新',
    productSources: {
      selfProduce: '自生產',
      outsourcing: '外購',
      outProduce: '委外生產'
    },
    productCodeTypes: {
      systemProduction: '系統生成',
      selfWriting: '自寫'
    },
    property: {
      saleProduct: '銷售產品',
      purchaseProduct: '採購產品',
      allProduct: '採購產品、銷售產品'
    },
    number: '數量',
    price: '單價',
    productInfo: '產品資訊',
    editProduct: '產品資訊編輯',
    addProduct: '添加產品',
    config: {
      configKey: '屬性鍵名',
      configName: '屬性名稱',
      configValue: '屬性值',
      isEnable: '是否啟用輔助屬性',
      addConfig: '添加輔助屬性',
      tips: '注意：屬性值如有多個，請用分號";"隔開',
      baseConfig: '基本設置',
      productCheckBarCode: '是否校驗條形碼唯一性',
      attribute: {
        attribute1: '輔助屬性1',
        attribute2: '輔助屬性2',
        attribute3: '輔助屬性3',
        attribute4: '輔助屬性4'
      }
    },
    shelfLifeUnits: {
      day: '天',
      mouth: '月',
      year: '年'
    },
    supplier: '供應商',
    notSupplier: '暫無供應商',
    alias: {
      productAlias: '產品別名',
      queryKeyword: '產品編碼，產品名稱，規格型號',
      businessUnitName: '關聯客戶/供應商',
      deleteTips: '是否確認刪除產品別名',
      pleaseSelectOne: '請選擇要刪除資料',
      businessUnit: '關聯客戶/供應商',
      customer: '客戶',
      supplier: '供應商',
      addProductAlias: '添加產品別名',
      editProductAlias: '編輯產品別名',
      productNamePlease: '請輸入產品名稱'
    },
    inventoryStock: '庫存占用查詢',
    viewInventoryReduce: '庫存占用',
    viewRelatedBusiness: '關聯業務',
    inventoryReduce: {
      businessCode: '業務編碼',
      businessType: '業務類型',
      deliveryCode: '發貨單編碼'
    },
    relatedBusiness: {
      salesOrder: '銷售訂單',
      salesDelivery: '銷售發貨單',
      purchaseOrder: '采購訂單',
      purchaseOutOrder: '采購委外訂單',
      inventoryIn: '入庫單',
      inventoryOut: '出庫單',
      salesInventoryLock: '銷售-鎖定庫存'
    },
    disabledBatch: '批量不啟用',
    disabledTips: '是否確認不啟用如下產品?',
    quantityReport: {
      queryKeywords: '產品編碼，產品名稱',
      salesQuantity: '銷售數量',
      purchaseQuantity: '采購數量',
      inventoryOutQuantity: '出庫數量',
      inventoryInQuantity: '入庫數量'
    }
  },
  sales: {
    name: '名稱',
    defaultType: '默認方式',
    salesOrder: '銷售訂單',
    creatorId: '開單員',
    common: {
      draft: '草稿',
      unVerify: '待審核',
      verify: '已審核',
      quoted: '已報價',
      closedRejected: '已關閉-拒絕',
      closedRequote: '已關閉-重新報價',
      closedToOrder: '已關閉-轉訂單',
      closeDone: '完成',
      closeStop: '終止',
      closeCancel: '作廢',
      canceled: '已作廢',
      done: '已完成',
      close: '已關閉',
      orderCustomerAmount: '按客戶統計-銷售訂單',
      deliveryCustomerAmount: '按客戶統計-銷售發貨',
      orderProductAmount: '按產品統計-訂單',
      deliveryProductAmount: '按產品統計-發貨',
      allOrderCustomerAmount: '按客戶統計-銷售業績',
      orderBrandAmount: '按品牌統計-訂單',
      salesOrder: '銷售訂單',
      salesDelivery: '銷售發貨單',
      salesReturn: '銷售退貨單',
      pleaseSelectBusinessTypeFirst: '請先選擇關聯業務類型',
      clearAddress: '清空',
      deliveryExpenseInfo: '費用資訊',
      orderExpenseInfo: '費用資訊',
      yearMonth: '年月',
      orderSummary: '銷售訂單金額匯總',
      deliverySummary: '銷售發貨金額匯總',
      orderAmountDetails: '訂單明細',
      deliveryAmountDetails: '發貨明細',
      startYearMonth: '開始年月',
      endYearMonth: '結束年月',
      orderProductSelector: '訂單產品選擇',
      amount: '總金額',
      salesCollectionSummary: '銷售回款金額統計',
      salesCollectionAmountDetails: '回款明細',
      collectionCustomertAmount: '按客戶統計-銷售回款',
      collectionDateStart: '回款日期起',
      collectionDateEnd: '回款日期止',
      orderCollectionOperatorAmount: '按開單業務員統計',
      orderCollectionCustomerManagerAmount: '按客戶業務員統計',
      operator: '業務員',
      org: '部門',
      salesOrderAmount: '銷售訂單金額',
      salesCollectionAmount: '銷售回款金額',
      salesOrderQuantity: '銷售訂單數量',
      exportOrderDetail: '匯出銷售訂單明細資料',
      exportDeliveryDetail: '匯出銷售發貨單明細資料',
      priceTypeName: '價格類型',
      subtotalPriceType: '小計價格類型',
      withTax: '(含稅)',
      withoutTax: '(不含稅)',
      expressRecordInfo: '快遞信息',
      exportOrderTitle: '匯出銷售訂單資料',
      auditor: '審核人',
      auditDate: '審核時間',
      reject: '已駁回',
      pleaseChooseOrderRange: '請選擇訂單範圍',
      allOrder: '全部訂單',
      myOrder: '我的訂單',
      orgUserOrder: '組織機構用戶訂單',
      allProduct: '全部產品',
      queryProduct: '查詢範圍產品',
      selectedProduct: '已選產品',
      needAdjustSalesPriceCount: '需調整價格總數量',
      adjustAddSuccessCount: '新增價格數量',
      adjustUpdateSuccessCount: '更新價格數量',
      adjustFailCount: '跳過數量',
      adjustPriceTips: '價格計算結果小於等於零時，將跳過調價',
      rejected: '已駁回',
      adjusting: '變更中',
      reLoadStock: '重新加載庫存',
      returnProduct: '退貨',
      exchangeProduct: '換貨',
      toAdvanceReceipt: '轉預收款',
      toPayment: '退款',
      returnType: '退換貨方式',
      returnRefundType: '退款方式',
      returnProductStatus: '客戶是否收貨',
      isProductInventoryOut: '倉庫是否發貨',
      isProductInventoryIn: '倉庫是否收貨',
      isProductLost: '貨物狀態',
      notLost: '未遺失',
      lost: '已遺失',
      notInventoryOut: '未發貨',
      hasInventoryOut: '已發貨',
      productReceived: '客戶已收貨',
      productNotReceived: '客戶未收貨',
      customerReceived: '客戶已收貨',
      customerNotReceived: '客戶未收貨',
      returnAdvanceReceiptType: '退款去向',
      inventoryIn: '倉庫已收貨',
      inventoryOut: '倉庫已發貨',
      toBeRefunded: '待財務退款',
      refunded: '財務已退款',
      returnAdvanceReceiptTypeEmpty: '退款預收款類型不能為空',
      dataPrecision: '計算保留小數位數',
      roundMethod: '取整',
      upRound: '向上取整',
      downRound: '向下取整',
      discountAmount: '優惠金額',
      biller: '開單員',
      toAdvancePayable: '轉預付款',
      toCollection: '退款',
      barcodeInputPlaceholder: '商品條碼，按回車輸入',
      deliveryWarehouse: '發貨倉庫',
      warehouseProductStock: '發貨倉庫庫存',
      creditBalance: '可用授信額度',
      baseInfo: '基本信息',
      deliveryInfo: '發貨信息',
      productInfo: '產品信息',
      amountInfo: '費用及收款',
      otherInfo: '其它信息',
      salesOrderCodeReconciliation: '銷售訂單編碼生成依據',
      purchaser: '采購員',
      autoGenePurchasePlan: '庫存不足自動生成采購計劃',
      productQuickQueryPlaceholder: '產品快捷輸入',
      isProductPurchaseOut: '是否委外退貨',
      notPurchaseOut: '否',
      hasPurchaseOut: '是',
      pleaseChooseOutOrderProducts: '請選擇委外訂單產品',
      outOrderTaxUnitPrice: '委外單價',
      salesOrderTaxUnitPrice: '銷售單價',
      purchaseOutOrderCode: '委外訂單編碼',
      selectPurchaseOutOrderProduct: '委外訂單產品',
      outOrderTaxPrice: '委外單價',
      outOrderPrice: '委外單價',
      purchaseOutReturnOrderAmount: '委外退款金額',
      deliveryQuantity: '發貨單數量',
      inventoryQuantity: '已出庫數量',
      outRequireQuantity: '委外需求數量',
      outOrderQuantity: '委外訂單數量',
      hasReturnQuantity: '已退貨數量',
      isReturnInventory: '是否退回倉庫',
      returnPurchaseOut: '退回委外方',
      returnInventory: '退回倉庫',
      orderInfoScreenCapture: '訂單資訊截圖',
      summaryProduct: '銷售發貨產品匯總',
      divideOrder: '銷售發貨分單',
      pleaseSelectWarehouse: '請選擇發貨倉庫',
      warehouseEmpty: '未選發貨倉庫',
      addDeliveryBatch: '確認添加所有銷售發貨單',
      productVolumeSum: '預估總體積(m³)',
      refreshVolume: '重新計算體積',
      pleaseChooseQueryRange: '請選擇查詢範圍',
      orderCreator: '開單員',
      customerManager: '客戶經理'
    },
    exception: {
      quotationOutTaxPlease: '報價總額(不含稅)不能為空',
      quotationWithTaxPlease: '報價總額(含稅)不能為空',
      customerPlease: '請選擇客戶',
      contactPlease: '請選擇連絡人',
      quotationDatePlease: '請選擇報價日期',
      deliveryDatePlease: '請選擇交貨日期',
      currencyPlease: '請選擇貨幣',
      customerManagerPlease: '請選擇業務員',
      operatorPlease: '請選擇經辦人',
      orderDatePlease: '請選擇訂單日期',
      invoiceTypePlease: '請選擇開票類型',
      deliveryTypePlease: '請選擇交貨方式',
      paymentMethodPlease: '請選擇付款方式',
      settlementMethodPlease: '請選擇結算方式',
      productOver: '發貨數量超出訂單數量',
      productEmpty: '產品表為空',
      addProductFail: '添加產品失敗',
      deleteProductFail: '刪除產品失敗',
      updateProductFail: '更新產品失敗',
      existCollectionOrDelivery: '存在收款記錄或發貨記錄',
      operateError: '操作失敗',
      orderProductEmpty: '查詢銷售訂單產品失敗',
      pleaseChooseDeliveryProduct: '請選擇發貨單產品',
      returnDatePlease: '請選擇退貨日期',
      deliveryHasReceivable: '該銷售發貨單已經關聯應收款，點擊確認【查看】已關聯應收款',
      orderHasReceivable: '該銷售訂單已經關聯應收款，點擊確認【查看】已關聯應收款',
      receiptCreateAuto: '應收款由系統自動生成',
      occurrenceDatePlease: '請選擇發生日期',
      expenseTypePlease: '請選擇費用類型',
      expenditureTypePlease: '請選擇支出類型',
      businessUnitPlease: '請選擇往來單位',
      amonutPlease: '請輸入金額',
      businessTypePlease: '請選擇關聯業務類型',
      businessPlease: '請選擇關聯業務',
      taxRatePlease: '請選擇稅率',
      unitPricePlease: '請輸入單價',
      priceStatusPlease: '請選擇單價是否含稅',
      pleaseSelectVerfyOrder: '請選擇已審核發貨單',
      pleaseSelectUnVerifyOrder: '請選擇待審核發貨單',
      pleaseChoooseDateToExport: '請先選擇匯出資料的日期範圍',
      exportFail: '匯出失敗',
      pleaseSelectSaleOrder: '請選擇銷售訂單',
      outInventoryProductCount: '退貨產品數量不能大於出庫單產品數量',
      saleDeliveryProductCount: '產品數量不能大於銷售發貨單的產品數量',
      saleOrderProductCount: '產品數量不能大於銷售訂單的產品數量',
      emptySalesOrderId: '銷售訂單id不存在',
      amountGreatThenAdvanceReceiptAmount: '預收款余額不足',
      greatThenAvailableAdvanceReceipt: '超出可抵扣產品總額（折扣後）',
      returnAmount: '請輸入退款金額',
      returnAmountGreatThenProductAmount: '退款總額不能大於產品總額',
      outReturnAmountGreatThenOutProductAmount: '委外退款總額不能大於委外產品總額',
      quantityGreatThenBusinessOrder: '退換數量不能超過業務單數量',
      returnRefundTypeEmpty: '退款方式不能為空',
      dataPrecisionPlease: '小數位數不能為空',
      roundMethodPlease: '計算取整方式不能為空',
      salesOrderNotEmpty: '請選擇銷售訂單',
      salesOrderCodePlease: '請輸入訂單編碼',
      returnQuantityOver: '退貨數量超出訂單數量',
      returnOutRequireCheckError: '委外可退數量不足，請先進行委外訂單的退貨/變更操作',
      returnAmountPlease: '請填寫退款金額',
      salesQuotationPriceTypePlease: '請選擇價格類型',
      returnProductAmountPlease: '請填寫退產品金額',
      returnFreightAmountPlease: '請填寫退運費金額'
    },
    salesOperate: {
      draft: '保存為草稿',
      submit: '提交',
      verify: '保存並審核',
      delivery: '發貨',
      collection: '收款',
      invoice: '發票',
      done: '完成',
      stop: '終止',
      cancel: '作廢',
      rejectQuote: '拒絕報價',
      selectProduct: '選擇產品',
      revoke: '撤銷',
      quote: '報價',
      toOrder: '轉訂單',
      requote: '重新報價',
      toInventoryOut: '出庫單',
      save: '保存',
      allProduct: '所有產品',
      deliveryProduct: '發貨單產品',
      saleOrderProduct: '銷售訂單產品',
      return: '退貨',
      createFinanceReceivable: '創建應收款',
      financeReceivableInfo: '應收款',
      monthlyReport: '月報',
      annualReport: '年報',
      orderMonthlyReport: '銷售訂單金額統計-月度',
      deliveryMonthlyReport: '銷售發貨金額統計-月度',
      orderAnnualReport: '銷售訂單金額統計-年度',
      deliveryAnnualReport: '銷售發貨金額統計-年度',
      config: '配置',
      selectProductMaterial: '選擇物料產品',
      selectOrderProduct: '從訂單選擇產品',
      multiAddress: '多地址發貨',
      afterProductNext: '下一步',
      beforeProduct: '上一步',
      upload: '上傳',
      uploadAddress: '導入',
      downloadExcel: '範本下載',
      uploadAgain: '重新上傳',
      confirmDelivery: '確認發貨',
      deliveryAgain: '繼續發貨',
      deleteAddress: '刪除地址',
      invoiceApplication: '開票',
      recall: '撤回',
      pass: '通過',
      reject: '駁回',
      outRequire: '委外需求',
      adjust: '變更',
      financeCollectionInfo: '收款單',
      step: {
        selectProduct: '選擇產品',
        uploadAddress: '地址上傳',
        addressInfo: '位址資訊',
        addSuccess: '發貨成功',
        pleaseUserExcelModel: '請使用官方提供的Excel範本來導入資料',
        pleaseChooseProduct: '至少選擇一條產品進行發貨',
        addresseeIsNotEmpty: '收件人姓名不能為空',
        deliveryQuantityMoreThanOrder: '產品發貨數量不能大於訂單產品數量',
        fileError: '檔案類型錯誤或者未按範本格式填寫',
        addLine: '添加行',
        deleteLine: '刪除行',
        tooltip: {
          attention: '*地址導入注意事項：',
          fileType: '1.請使用官方Excel範本導入資料;',
          dataFormat: '2.使用官方Excel範本時，請勿更改範本裡面的英文和中文表頭資料'
        }
      },
      address: {
        addressee: '收件人姓名',
        addresseePhone: '收件人電話',
        addresseeCompanyName: '收件人公司名稱',
        deliveryAddr: '收件人詳細地址',
        addresseePostCode: '郵編'
      }
    },
    saleTooltip: {
      orderMonthlyReport: {
        source: '資料來源: 銷售管理-銷售訂單',
        rule: '取數規則: 已審核、終止、已完成的銷售訂單',
        dimension: '統計維度: 按每月統計'
      },
      annualOrderReportChart: {
        source: '資料來源: 銷售管理-銷售訂單',
        rule: '取數規則: 已審核、終止、已完成的銷售訂單',
        dimension: '統計維度: 按每年統計'
      },
      listOfSalesOrders: {
        source: '資料來源: 銷售管理-銷售訂單',
        rule: '取數規則: 已審核、已完成的銷售訂單',
        dimension: '統計維度: 按日期區間（默認：半年）統計'
      },
      monthlyDeliveryReportChart: {
        source: '資料來源: 銷售管理-銷售發貨',
        rule: '取數規則: 已審核、已完成的銷售發貨單',
        dimension: '統計維度: 按每月統計'
      },
      annualDeliveryReportChart: {
        source: '資料來源: 銷售管理-銷售發貨',
        rule: '取數規則: 已審核、已完成的銷售發貨單',
        dimension: '統計維度: 按每年統計'
      },
      orderCustomerAmount: {
        source: '資料來源: 銷售管理-銷售訂單',
        rule: '取數規則: 已審核、已完成的銷售訂單',
        dimension: '統計維度: 按月份統計客戶的銷售訂單總額'
      },
      deliveryCustomerAmount: {
        source: '資料來源: 銷售管理-銷售發貨',
        rule: '取數規則: 已完成的銷售發貨單',
        dimension: '統計維度: 按月份統計客戶的銷售發貨總額'
      },
      orderProductAmount: {
        source: '資料來源: 銷售管理-銷售訂單',
        rule: '取數規則: 已審核、已完成的銷售訂單',
        dimension: '統計維度: 產品按月統計銷售總額'
      },
      deliveryProductAmount: {
        source: '資料來源: 銷售管理-銷售發貨',
        rule: '取數規則: 已完成的銷售發貨單',
        dimension: '統計維度: 產品按月統計銷售發貨總額'
      },
      saleSummary: {
        source: '資料來源: 銷售管理-銷售訂單、銷售發貨',
        rule: '取數規則: 已審核、已完成的銷售訂單，已完成的銷售發貨單',
        dimension: '統計維度: 按月統計銷售訂單總額、銷售發貨總額'
      }
    },
    quotation: {
      queryKeyword: '編碼、客戶、連絡人',
      quotationCode: '報價單編碼',
      customer: '客戶',
      contact: '連絡人',
      quotationDate: '報價日期',
      currency: '貨幣',
      discount: '總體折扣',
      reductionAmount: '調減金額',
      amountOutTax: '總額(不含稅)',
      amountWithTax: '總額(含稅)',
      invoiceType: '開票類型',
      paymentMethod: '付款方式',
      settlementMethod: '結算方式',
      deliveryType: '交貨方式',
      deliveryDate: '交貨日期',
      expiryDate: '報價有效期限',
      customerManager: '業務員',
      deliveryNote: '交付說明',
      paymentNote: '付款說明',
      transportNote: '包裝運輸說明',
      quotationStatus: '狀態',
      quotationEdit: '編輯報價單',
      quotationAdd: '添加報價單',
      quotationView: '查看報價單',
      deleteQuotationTips: '是否刪除該報價單',
      revokeQuotationTips: '是否撤銷該報價單',
      quoteQuotationTips: '是否確定向客戶提供報價單',
      requoteQuotationTips: '是否重新報價該報價單',
      rejectedQuotationTips: '是否拒絕報價該訂單',
      quotation2OrderTips: '是否將報價單轉訂單',
      quotationBaseInfo: '基本資訊',
      viewSalesQuotationTitle: '查看銷售報價單',
      editSalesQuotationTitle: '編輯銷售報價單',
      addSalesQuotationTitle: '添加銷售報價單',
      startDate: '報價日期起',
      endDate: '報價日期止',
      productMaterial: '子件',
      selectProductMaterial: '選擇子件',
      editProductMaterial: '編輯產品子件',
      viewProductMaterial: '查看產品子件',
      saveProductPackScheme: '保存為組合方案',
      pleaseSelectProductMaterial: '請選擇產品子件'
    },
    quotationStatus: {
      draft: '草稿',
      unVerify: '待審核',
      verify: '已審核',
      quoted: '已報價',
      closedRejected: '已關閉-拒絕',
      closedRequote: '已關閉-重新報價',
      closedToOrder: '已關閉-轉訂單'
    },
    quotationProduct: {
      product: '產品',
      quantity: '數量',
      price: '單價',
      taxRate: '稅率',
      tax: '稅金',
      amountOutTax: '小計(不含稅)',
      amountWithTax: '小計(含稅)'
    },
    product: {
      product: '產品名稱',
      quantity: '數量',
      deliveredQuantity: '已發貨',
      price: '單價(不含稅)',
      taxRate: '稅率',
      tax: '稅金',
      amountOutTax: '小計(不含稅)',
      amountWithTax: '小計(含稅)',
      productCode: '產品編碼',
      productName: '產品名稱',
      productModel: '規格型號',
      productUnit: '單位',
      supplier: '供應商',
      productsAmountOutTax: '總額（不含稅）',
      productsAmountWithTax: '總額（含稅）',
      bussinessCode: '訂單編碼',
      orderQuantity: '訂單數量',
      deliveryQuantity: '發貨單數量',
      warehouseName: '倉庫',
      productStock: '庫存',
      amountExcludedTax: '小計（不含稅）',
      amountAddedTax: '小計（含稅）',
      taxPrice: '單價（含稅）',
      materialCode: '物料編碼',
      materialName: '物料名稱',
      materialModel: '規格型號',
      materialUnit: '單位',
      materialQuantity: '數量',
      editProductMaterial: '編輯產品物料清單',
      viewProductMaterial: '查看產品物料清單',
      subtotal: '小計',
      currency: '貨幣',
      orderAmount: '訂單總額',
      outQuantity: '已出庫',
      advanceReceiptType: '定向抵扣預收款',
      directAdvanceReceiptAmount: '可抵扣產品總額（折扣後）',
      directAdvanceReceiptAmountAvailable: '可抵扣產品總額（折扣後）',
      returnOrderAmount: '退款總額',
      productAmount: '產品總額',
      outDelivery: '委外發貨數量',
      customerSalesPrice: '銷售價格',
      customSalesCustomerPrice: '自定義價格',
      realSalesCustomerPrice: '客戶身份價格',
      recentSalesCustomerPrice: '最近銷售價格',
      minSalesCustomerPrice: '最小銷售價格',
      maxSalesCustomerPrice: '最大銷售價格',
      taxRatePlease: '請選擇稅率',
      outRequireQuantity: '委外需求數量',
      outOrderQuantity: '委外訂單數量',
      inventoryQuantity: '出庫數量',
      deliveryQuantitySum: '發貨數量',
      inventoryQuantitySum: '出庫數量',
      notDeliveryQuantitySum: '未發貨數量',
      notInventoryQuantitySum: '未出庫數量',
      inventoryInQuantitySum: '入庫數量',
      notInventoryInQuantitySum: '未入庫數量',
      returnProductAmount: '退產品金額',
      returnFreightAmount: '退運費金額',
      productBrandName: '產品品牌'
    },
    b2cOrder: {
      b2cOrder: 'B2C訂單導入',
      beforeStep: '上一步',
      nextStep: '下一步',
      submitData: '提交資料',
      importData: '數據導入',
      dataMap: '資料映射',
      previewData: '數據預覽',
      orderConfig: '訂單設置',
      previewResult: '導入結果',
      rowNumber: '序號',
      companyName: '單位主體',
      customerCode: '客戶編碼',
      customerName: '客戶名稱',
      productCode: '產品編碼',
      productName: '產品名稱',
      productModel: '規格型號',
      productUnit: '計價單位',
      unitPriceTax: '單價',
      quantity: '數量',
      addressee: '收件人',
      addresseePhone: '收件電話',
      address: '地址',
      customerOrderCode: '協力廠商訂單號',
      expressCompanyName: '承運商',
      expressNumber: '快遞單號',
      auditStatus: '銷售訂單審核狀態',
      deliveryStatus: '銷售發貨狀態',
      collectionStatus: '銷售回款狀態',
      inventoryOutStatus: '銷售發貨出庫狀態',
      collected: '已收款',
      unCollected: '未收款',
      inventoryOut: '已出庫',
      unInventoryOut: '未出庫',
      financeAccount: '收款帳戶',
      warehouse: '倉庫',
      submitDataTips: '確認提交資料',
      fileUploading: '檔載入中',
      collectionAccountEmpty: '收款帳號不能為空',
      warehouseEmpty: '倉庫不能為空',
      save2Draft: '保存為草稿',
      save2UnAudited: '提交',
      save2Audited: '提交並審核',
      orderCode: '訂單編碼',
      importDate: '業務日期',
      recordCount: '記錄條數',
      orderStatus: '訂單狀態',
      operator: '經辦人',
      queryKeyword: '訂單編碼',
      startDate: '業務日期起',
      endDate: '業務日期止',
      baseInfo: 'B2C訂單',
      salesOrderInfo: '銷售訂單',
      salesDeliveryInfo: '銷售發貨',
      inventoryOutInfo: '出庫單',
      financeReceivableInfo: '應收款',
      addB2cOrder: '導入B2C訂單',
      editB2cOrder: '編輯B2C訂單',
      viewB2cOrder: '查看B2C訂單',
      deleteTips: '確認【刪除】該B2C訂單',
      cancelTips: '確認【作廢】該B2C訂單',
      save2DraftTips: '確認【保存為草稿】,該操作不會自動生成下級業務資料',
      save2UnAuditedTips: '確認【提交】,該操作不會自動生成下級業務資料',
      saveAndAuditedTips: '確認【提交並審核】,該操作將根據設置自動生成下級業務資料',
      geneDelivery: '生成銷售發貨單',
      geneReceivable: '生成應收款',
      geneInventoryOut: '生成出庫單',
      geneCollection: '生成收款單',
      pleaseSelectSalesOrder: '請選擇銷售訂單',
      pleaseSelectSalesDelivery: '請選擇銷售發貨單',
      pleaseSelectReceivable: '請選擇應收款',
      updateSalesOrderB2cDetailsFail: '更新b2c明細失敗',
      deleteSalesOrderB2cDetailsFail: '刪除b2c明細失敗',
      updateSalesOrderB2cFail: '更新b2c訂單失敗',
      salesOrderContainDelivery: '部分銷售訂單已發貨，請重新選擇',
      businessOrderContainReceivable: '部分單據已存在應收款，請重新選擇',
      receivableOrderEmpty: '未生成訂單，無法生成應收款',
      receivableDeliveryEmpty: '未生成發貨單， 無法成應收款',
      receivableContainCollection: '部分應收款已收款',
      cancelSuccess: '作廢成功',
      geneSalesDeliverySuccess: '生成銷售發貨單成功',
      geneReceivableSuccess: '生成應收款成功',
      geneCollectionSuccess: '生成收款單成功',
      geneInventoryOutSuccess: '生成出庫單成功',
      attentionTipsTitle: '*B2C訂單導入注意事項*',
      attentionTips1: '1.建議使用官方Excel範本導入資料 ',
      attentionTips2: '2.上傳EXCEL後，請準確填寫標題行號和資料起始行號',
      attentionTips3: '3.請準確填寫資料映射關係並檢查資料預覽是否相符',
      excelTitleRowNumber: '導入EXCEL標題行號',
      excelDataRowNumber: '導入EXCEL數據起始行號',
      sourceTitleName: '導入列標題',
      targetTitleName: '映射對象',
      productMap: '產品映射',
      autoGeneProductAlias: '是否自動生成產品別名',
      sysProduct: '系統產品',
      importDataError: '導入資料錯誤，請檢查錯誤資訊',
      productMapError: '映射產品錯誤，請檢查錯誤資訊',
      autoGeneProductAliasTips: 'b2c審核通過後，自動生成不存在產品的別名',
      autoGeneTips: '生成的銷售訂單中包含委外發貨時，將不會按配置生成對應發貨單、應收款、收款單、出庫單',
      geneOutDelivery: '生成委外需求單',
      existOutDelivery: '是否包含委外發貨',
      orderExistOutDeliveryGeneDeliveryError: '含委外發貨訂單不能進行該操作，請前往【銷售管理-銷售訂單】進行發貨操作',
      orderExistOutDeliveryPlease: '請選擇包含委外發貨的訂單'
    },
    promotion: {
      activityName: '活動名稱',
      pleaseActivityName: '請填寫活動名稱',
      pleaseActivityTime: '请請填活動时间',
      activityTime: '活動時間',
      isEnable: '是否啟用',
      effectiveCustomer: '有效客戶',
      allCustomer: '全部客戶',
      theCustomSort: '指定客戶分類',
      productWhitelist: '產品白名單',
      productBlacklist: '產品黑名單',
      allProduct: '全部產品',
      theProduct: '指定產品',
      productSort: '產品分類',
      promotionInstructions: '促銷說明',
      effectiveCustomerTip: '有效客戶請選擇客戶分類',
      productWhitelistTipOne: '產品白名單,請選擇指定產品',
      productWhitelistTipTwo: '產品白名單,請選擇產品分類',
      productBlacklistTipOne: '產品黑名單,請選擇指定產品',
      productBlacklistTipTwo: '產品黑名單,請選擇產品分類',
      promotionType: '促銷類型',
      fullAmount: '滿量折',
      repeatFullReduction: '重複滿額減',
      addActivityPromotion: '添加活動促銷',
      editActivityPromotion: '編輯活動促銷',
      viewActivityPromotion: '查看活動促銷',
      eachFull: '每滿',
      yuan: '元',
      jian: '減',
      repeatFullReductionTip: '促銷類型，重複滿額減請填寫金額',
      repeatFullReductionTips: '促銷類型，請填寫滿減區間',
      promotionDiscountTipOne: '促銷類型：區間範圍左區間不能為空',
      promotionDiscountTipTwo: '促銷類型：區間範圍右區間不能為空',
      promotionDiscountTipThree: '促銷類型：達到區間範圍的滿減金額（折扣）不爲空',
      promotionDiscountTipFour: '促銷類型：區間範圍左區間不能大於右區間',
      promotionDiscountTipFive: '促銷類型：區間範圍左區間不能為空和0',
      promotionDiscountTipSix: '促銷類型：區間範圍不能為空或0',
      promotionDiscountTipSeven: '會促銷類型：區間範圍請閉環添加,不要交叉',
      no: '無'
    },
    order: {
      queryKeyword: '編碼、客戶、連絡人',
      detailsQueryKeyword: '編碼、客戶、產品資訊',
      orderCode: '訂單編碼',
      orderCodeSelf: '自編訂單號',
      orderDate: '簽約日期',
      customer: '客戶',
      customerName: '客戶',
      contact: '連絡人',
      customerManager: '業務員',
      confirmedAmount: '已確認',
      unConfirmedAmount: '未確認',
      deliveryAmount: '已發貨金額',
      invoiceAmount: '已開票金額',
      deliveryDate: '交貨日期',
      orderDateStart: '開始時間',
      orderDateEnd: '結束時間',
      paymentMethod: '付款方式',
      invoiceType: '開票類型',
      deliveryType: '交貨方式',
      settlementMethod: '結算方式',
      deliveryAddr: '交貨地址',
      currency: '貨幣',
      amountOutTax: '總額(不含稅)',
      amountWithTax: '總額(含稅)',
      amountReceived: '已收款金額',
      collectionBill: '收款票據',
      orderStatus: '狀態',
      addOrder: '添加銷售訂單',
      editOrder: '編輯銷售訂單',
      viewOrder: '查看銷售訂單',
      addOrderTips: '確認【添加】該訂單？',
      updateOrderTips: '確認【更新】該訂單？',
      deleteOrderTips: '確認【刪除】該訂單？',
      revokeOrderTips: '確認【撤銷】該訂單？ 撤銷該訂單，訂單下的【發貨單變成關閉狀態】',
      closeDoneOrderTips: '確認【完成】該訂單？',
      closeStopOrderTips: '確認【終止】該訂單？',
      closeCancelOrderTips: '確認【作廢】該訂單？',
      verifyOrderTips: '確認【審核】該訂單？',
      recallOrderTips: '確認【撤回】該訂單到草稿狀態？',
      baseInfo: '基本資訊',
      salesDeliveryCode: '發貨單編號',
      salesReceiptPlanCode: '收款單編號',
      revokeOrderImpossibleTips: '該訂單存在收款或發貨記錄不能撤銷',
      financeCollectionInfo: '收款記錄',
      financeReceivableDetails: '應收款詳情',
      financeCollectionDetails: '收款單詳情',
      startDate: '簽約日期起',
      endDate: '簽約日期止',
      orderSelector: '銷售訂單選擇',
      orderDeliveryInfo: '發貨單',
      orderReturnInfo: '退貨信息',
      customerAddressSelector: '客戶位址選擇器',
      customerOrderCode: '客戶訂單號',
      salesOrderAmount: '銷售訂單金額',
      verifySelectedOrdersTips: '確認【審核】已選銷售訂單',
      orderInfo: '訂單資訊',
      orderDetails: '訂單明細',
      lookMore: '查看更多',
      projectName: '項目名稱',
      deliveryProcessRate: '發貨進度',
      collectionProcessRate: '回款進度',
      inventoryProcessRate: '出庫進度',
      expenseByCustomer: '其它費用',
      orderAmount: '訂單總額',
      productAmountTax: '產品總額',
      discount: '折扣率（%）',
      amountAfterDiscount: '折後產品總額',
      freight: '運費',
      amountDetails: '其它金額',
      advanceDeposit: '預收定金',
      revokeOrderCheckTips: '撤銷操作，將【刪除與該銷售訂單關聯的銷售發貨、應收款等業務資料】，請確認是否要撤銷？',
      auditOrder: '訂單審核',
      printTitle: '列印銷售訂單',
      freeProduct: '贈品',
      selectFreeProduct: '選擇贈品',
      manualGeneDelivery: '手動生成發貨單',
      manualGeneReceivable: '手動生成應收款',
      outDeliveryQuantityPlease: '請填寫委外數量',
      outDeliveryQuantityGreatThen: '委外數量不能大於訂單數量',
      outDeliveryAddressInfoPlease: '存在委外發貨，請完整填寫收貨人、收貨地址等詳細信息',
      receiptAmountBalance: '應收款余額',
      customerTip: '更換客戶需重新選擇促銷活動',
      customerCompanyTip: '所屬公司與客戶關聯的所屬公司不一致，是否將所屬公司更換爲客戶關聯所屬公司',
      activityPromotion: '活動促銷',
      promotionDiscount: '促銷滿減折扣',
      orderStopCheck: '銷售訂單終止確認',
      orderQuantity: '訂單總數量',
      outDeliveryQuantity: '委外數量',
      inventoryDeliveredQuantity: '倉庫已發數量',
      outDeliveredQuantity: '委外已發數量',
      orderProduct: '訂單產品明細',
      orderReceivableInfo: '應收款明細',
      hasCollectionAmount: '已收款金額：',
      stopOrderTips: '確認【終止】該訂單？確認操作後，訂單將按已收款金額變更訂單，操作後不可撤銷，請謹慎操作',
      stockLessTips: '存在存庫不足產品，是否【確認】繼續提交訂單',
      defaultAdvanceReceiptBalance: '默認預收款余額',
      customerCreditNotEnoughTips: '客戶授信額度不足，不能提交訂單。請聯絡客戶支付相關款項或者提升授信額度後再來操作！ ',
      outsourceManualGeneDeliveryTips: '訂單存在委外需求，訂單稽核通過後請手動生成發貨單',
      manualGeneDeliveryTips: '您勾選了手動生成發貨單，訂單稽核通過後請手動生成發貨單，點【取消】可返回修改',
      isOutDeliveryOrder: '是否委外',
      receivableReturnAmount: '未收款退款',
      balanceAmount: '餘額',
      multiAddressAdd: '添加多地址',
      multiAddress: '多地址查看',
      editProductAddress: '訂單地址',
      multiAddressAddDelivery: '添加發貨單',
      addressDeliveryMode: '發貨方式',
      selectDeliveryProduct: '選擇地址',
      deliveryProductPreview: '數據預覽',
      deliveryResult: '發貨結果',
      deliveryByAddress: '按地址',
      deliveryByProduct: '按產品',
      deliveryProductEmpty: '請選擇發貨產品',
      selectProduct: '選擇產品',
      viewProductAddress: '產品地址',
      orderAdjust: '訂單變更',
      deliveryDateLessThenOrderDate: '交貨日期不能早於簽約日期',
      muiltiAddressOrderDetails: '代發訂單',
      addMultiAddressOrder: '添加代發訂單',
      orderDeliveryProductInfo: '發貨產品',
      updateWarehouse: '修改倉庫',
      auditDate: '審核日期',
      parseAddress: '地址識別',
      parseAddressPlease: '請輸入要識別的地址'
    },
    orderAdjust: {
      queryKeyword: '訂單編碼、客戶',
      createTime: '變更時間',
      startDate: '變更時間起',
      endDate: '變更時間止',
      adjustVersion: '版本號',
      versionCreateAuto: '版本號自動生成',
      salesOrderCode: '銷售訂單編碼',
      adjuster: '變更發起人',
      auditorName: '審核人',
      status: '審核狀態',
      adjustReason: '變更原因',
      rejectReason: '駁回原因',
      oldDeliveryAddr: '收貨地址（ 變更前）',
      newDeliveryAddr: '收貨地址（變更後）',
      old: '變更前',
      new: '變更後',
      oldAmountInfo: '費用信息（變更前）',
      newAmountInfo: '費用信息（變更後）',
      addOrderAdjustTitle: '添加銷售訂單變更',
      editOrderAdjustTitle: '編輯銷售訂單變更',
      viewOrderAdjustTitle: '查看銷售訂單變更',
      salesOrderAdjusterPlease: '請選擇變更人',
      salesOrderAdjustReasonPlease: '請輸入變更原因',
      rejectReasonEmpty: '請輸入駁回原因',
      adjustAuditTips: '確認【保存並審核】該變更單，確認後原銷售訂單將被修改',
      deleteAdjustAuditTips: '確認【刪除】該變更單',
      adjustAuditPassTips: '確認審核【通過】該變更單，確認後原銷售訂單將被修改',
      adjustAuditRejectTips: '確認【駁回】該變更單，確認後原銷售訂單不會被修改，可重新發起變更',
      orderAdjustRecord: '變更記錄',
      newQuantityLessThanDeliveriedQuantity: '變更後產品數量不能小於已發貨數量',
      newReceivableAmount: '應收金額（變更後）',
      newDirectAdvanceReceiptAmount: '定向抵扣金額（變更後）',
      newReceivableAmountGreatThenCollectionAmount: '變更應收款金額不能大於已收款金額',
      receivableAmountGreatThenOrderAmountTips: '變更後應收款總額大於訂單總額，請調整底部應收款金額',
      orderAmountGreatThenReceivableAmountTips: '變更後訂單總額大於應收款總額, 確認變更後需手動添加應收款，確認繼續變更?',
      autoAdjust: '自動調整',
      autoAdjustSalesOrderReceivableTips: '該訂單應收款已發生變更，是否自動調整？',
      autoAdjustSalesDeliveryReceivabelTips: '該發貨單應收款已發生變更，是否自動調整？',
      autoAdjustPurchaseOrderPayableTips: '該訂單應付款已發生變更，是否自動調整？'
    },
    deliveryAdjust: {
      salesDeliveryCode: '發貨單編碼',
      editDeliveryAdjustTitle: '編輯發貨單變更',
      viewDeliveryAdjustTitle: '查看發貨單變更',
      addDeliveryAdjustTitle: '添加發貨單變更'
    },
    salesDelivery: {
      queryKeyword: '編碼、客戶、連絡人',
      detailsQueryKeyword: '編碼、客戶、產品資訊',
      inventoryInfo: '出庫信息',
      deliveryCode: '發貨單編碼',
      deliveryDate: '發貨日期',
      customer: '客戶',
      contact: '連絡人',
      currency: '貨幣',
      deliveryType: '發貨方式',
      settlementMethod: '結算方式',
      customerManager: '業務員',
      operator: '經辦人',
      deliveryStatus: '發貨單狀態',
      inventoryInStatus: '入庫單狀態',
      InventoryOutOrder: '生成出庫單',
      addSalesDeliveryTitle: '添加銷售發貨單',
      multiAddDeliveryTitle: '多地址發貨',
      addresseeCompanyName: '收件人公司名稱',
      addresseePostCode: '郵編',
      editSalesDeliveryTitle: '編輯銷售發貨單',
      viewSalesDeliveryTitle: '查看銷售發貨單',
      salesDeliveryBaseInfo: '基本資訊',
      salesDeliveryProductInfo: '銷售發貨產品資訊',
      deleteSalesDeliveryTips: '確認【刪除】銷售發貨單？',
      revokeTips: '確認【撤銷】該銷售發貨單, 撤銷後將刪除發貨單下應收款',
      doneTips: '確認【完成】該銷售發貨單？',
      inventoryOutTips: '確認生成出庫單？',
      inventoryOutDetails: '出庫單詳情',
      inventoryOutProductDetails: '出庫單產品詳情',
      startDate: '發貨日期起',
      endDate: '發貨日期止',
      taxRate: '稅率',
      price: '單價',
      deliveryReturnInfo: '退貨信息',
      deliveryReturnDetails: '退貨單詳情',
      deliveryReturnProductDetails: '退貨單產品詳情',
      deliveryProductSelector: '發貨單產品選擇器',
      inventoryOutCode: '出庫單編碼',
      inventoryOutDate: '出庫日期',
      inventoryInCode: '入庫單編碼',
      inventoryInDate: '入庫日期',
      warehouse: '倉庫',
      warehouseOperator: '倉庫經辦人',
      deliveryAddr: '收貨地址',
      deliveryOrder: '銷售發貨單',
      deliveryDetails: '銷售發貨明細',
      deliverySelector: '銷售發貨單選擇',
      reportTitle: '打印發貨單',
      reportReturnTitle: '打印退貨單',
      selectAddress: '選擇位址',
      verifySelectedSalesDeliveryTips: '確認【審核】已選待審核發貨單',
      doneSelectedSalesDeliveryTips: '確認【完成】已選已審核發貨單',
      autoAddOrderSuccess: '自動生成訂單成功',
      autoAddOrderFail: '自動生成訂單失敗',
      addVerifyTips: '是否確認【保存並審核】發貨單',
      cancelDeliveryTips: '是否確認【作廢】發貨單',
      batchInventory: '批量出庫',
      inventoryOutStatus: '出庫狀態',
      plesaseSelectSalesDelivery: '請選擇發貨單',
      deliveryAdjust: '發貨單變更',
      expressPlaceOrder: '快遞下單',
      expressPrintOrder: '打印電子面單',
      expressPrintOrderAndDelivery: '打印電子面單 + 發貨單',
      placeAllOrder: '全部下單',
      placeSelectedOrder: '勾選下單',
      printAllOrder: '全部打印',
      printSelectedOrder: '勾選打印',
      cencelExpressOrder: '取消下單',
      allInventoryOut: '全部出庫',
      deliveryProductGroupBy: '發貨產品',
      batchInventoryOut: '出庫預覽',
      selectInventoryProductEmpty: '請選擇要出庫的產品',
      defaultInventoryWarehouse: '默認出庫倉庫',
      defaultInventoryWarehouseTips: '發貨單未配置倉庫時，以默認倉庫進行出庫',
      confirmBatchInventoryOut: '確認出庫',
      currentInventoryQuantitySum: '本次出庫數量',
      confirmBatchInventoryOutTips: '確認已選產品全部出庫',
      hasInventoryQuantity: '已出庫數量',
      saleDeliveryQuantity: '發貨單數量',
      defaultInventoryWarehousePlease: '請選擇默認出庫倉庫',
      addExpressStatus: '已快遞下單'
    },
    salesReturn: {
      queryKeyword: '編碼、客戶',
      detailsQueryKeyword: '編碼、客戶、產品資訊',
      addSalesReturnTitle: '添加銷售退貨單',
      viewSalesReturnTitle: '查看銷售退貨單',
      editSalesReturnTitle: '編輯銷售退貨單',
      returnBaseInfo: '基本資訊',
      returnOrderCode: '退貨單編碼',
      salesDelivery: '發貨單',
      business: '關聯業務',
      customer: '客戶',
      returnDate: '退貨日期',
      warehouseName: '倉庫',
      currency: '貨幣',
      orderAmount: '訂單金額',
      amountOutTax: '總額（不含稅）',
      amountWithTax: '總額（含稅）',
      warehouseManager: '倉管員',
      operator: '經辦人',
      returnOrderStatus: '退貨狀態',
      returnReason: '退貨原因',
      remark: '備註',
      startDate: '退貨日期起',
      endDate: '退貨日期止',
      pleaseChooseCustomerManager: '請選擇經辦人',
      pleaseChooseDelivery: '請選擇發貨單',
      verifyReturnOrderTips: '確認【審核】該退貨單',
      revokeReturnOrderTips: '確認【撤銷】該退貨單',
      doneReturnOrderTips: '確認【完成】該退貨單',
      deleteReturnOrderTips: '確認【刪除】該退貨單',
      inventoryOutDetails: '入庫單詳情',
      inventoryOutProductDetails: '入庫單產品詳情',
      returnInventoryInfo: '入庫資訊',
      inventoryInCode: '入庫單編碼',
      inventoryInDate: '入庫日期',
      warehouse: '倉庫',
      warehouseOperator: '倉庫經辦人',
      orderSelector: '銷售退貨單選擇',
      autoAddOrderSuccess: '自動生成訂單成功',
      autoAddOrderFail: '自動生成訂單失敗',
      Updateorderprocessrate: '重算全部訂單的發貨進度和收款進度',
      Updatedeliveryprocessrate: '重算全部發貨單的發貨進度和收款進度',
      Update: '更新',
      Recalculation: '重算',
      printTitle: '打印退貨單',
      businessType: {
        saleOrder: '銷售訂單',
        saleDelivery: '銷售發貨單',
        outInventory: '出庫單'
      },
      purchaseOrderSelect: '採購訂單選擇器',
      pleaseChooseSupplier: '請選擇供應商',
      pleaseChooseCurrency: '請選擇貨幣',
      inventoryInTips: '確認該退貨單【倉庫已收貨】',
      inventoryOutTips: '確認該退貨單【倉庫已發貨】',
      refundedTips: '確認該退貨單【財務已退款】',
      returnInventoryInInfo: '入庫信息',
      returnInventoryOutInfo: '出庫信息',
      inventoryInProcessRate: '入庫進度',
      returnOrder: '銷售退貨單',
      returnDetails: '銷售退貨明細',
      inventoryOutProcessRate: '出庫進度',
      financeProcessRate: '退款進度',
      returnAmountCheckTips: '退換金額和產品總額不一致，是否確定按該金額退款？',
      productAmount: '產品總額'
    },
    salesView: {
      deliveryProductTitle: '銷售訂單已發貨產品',
      confirmAmountTitle: '銷售訂單已收金額',
      unConfirmAmountTitle: '銷售訂單未確認金額',
      paidAmount: '實收金額',
      relatedBusiness: '關聯業務',
      viewCollectionInfo: '查看收款詳情'
    },
    expense: {
      queryKeyword: '往來單位、關聯業務編碼',
      occurrenceDate: '發生日期',
      expenseType: '費用類型',
      expenditureType: '支出類型',
      businessUnitId: '供應商',
      amount: '金額',
      operator: '經辦人',
      paymentStatus: '支付狀態',
      businessType: '關聯業務類型',
      businessCode: '關聯業務編碼',
      business: '關聯業務',
      startDate: '發生日期起',
      endDate: '發生日期止',
      currency: '貨幣',
      addExpense: '添加銷售費用',
      editExpense: '編輯銷售費用',
      viewExpense: '查看銷售費用',
      deleteExpenseTips: '是否刪除該費用單',
      paymentMethod: '支付方式',
      paymentDate: '支付日期',
      payee: '收款方'
    },
    price: {
      viewProductPrice: '查看產品銷售價格',
      editProductPrice: '編輯產品銷售價格',
      addProductPrice: '添加產品銷售價格',
      deletePriceTips: '確認【刪除】該價格',
      attentionTipsTitle: '*銷售價格導入注意事項*',
      attentionTips1: '1.建議使用官方Excel範本導入數據 ',
      attentionTips2: '2.上傳EXCEL後，請準確填寫標題行號和數據起始行號',
      attentionTips3: '3.請準確填寫數據映射關係並檢查數據預覽是否相符',
      priceImport: '銷售價格導入',
      submitData: '提交數據',
      importData: '數據導入',
      dataMap: '數據映射',
      previewData: '數據預覽',
      previewResult: '導入結果',
      excelFile: 'Excel檔',
      excelTitleRowNumber: '標題行號',
      excelDataRowNumber: '數據起始行號',
      sourceTitleName: '導入列標題',
      targetTitleName: '映射對象',
      currencyType: '幣種',
      taxRate: '稅率',
      nextStep: '下一步',
      lastStep: '上一步',
      fileError: '檔類型錯誤或者未按範本格式填寫',
      fileUploading: '檔加載中',
      ignoreEmptyPrice: '忽略價格為空或為0的數據',
      productCodeMapColumnCanNotEmpty: '產品編碼映射列不能為空',
      noDataNeedImport: '沒有需要導入的數據',
      serialNumber: '序號',
      importStatus: '導入狀態',
      importErrorReason: '原因',
      success: '成功',
      fail: '失敗',
      executeResultInfo: '共{0}條記錄，成功：{1}條，跳過：{2}條',
      regularPrice: '正價',
      specialPrice: '特價',
      costPrice: '裸價',
      priceLevel: '價格標簽',
      importPriceMaxCountTips: '導入價格最大處理條數為1000，如有超出請分批處理'
    },
    deliveryView: {
      startDate: '簽約日期起',
      endDate: '簽約日期止',
      queryKeyword: '編碼、客戶、連絡人',
      deliveredAmount: '已發貨金額',
      receiptAmount: '回款金額'
    },
    config: {
      title: '銷售參數設置',
      fiscalYear: '財年設置',
      indexTitle: '銷售指標設置',
      orderAmount: '訂單金額',
      orderReceiptAmount: '訂單回款',
      signedOrderQuantity: '簽約訂單數量',
      addedCustomerQuantity: '新增客戶數量',
      followupTimes: '跟進次數',
      followUpCustomerTimes: '跟進客戶數',
      commonSalesConfig: '通用參數',
      autoGeneOrderEnable: '直接添加的銷售發貨單保存時同步生成已審核銷售訂單',
      updateOrderProcessRate: '重新計算全部訂單發貨進度和回款進度',
      updateDeliveryProcessRate: '重新計算全部發貨單出庫進度和回款進度',
      update: '更新',
      recalculation: '重新計算',
      orderAutoFillAddressEnable: '銷售訂單自動填充收貨位址',
      deliveryAutoFillAddressEnable: '銷售發貨自動填充收貨位址',
      enableSalesPriceAudit: '是否啟用價格審核',
      salesOrderAuditModel: '銷售訂單審核模式',
      freeFlowModel: '自由流程模式',
      orderAuditModel: '審核模式',
      auditProcess: '審核流程',
      businessType: '業務類型',
      salesQuotation: '銷售報價',
      salesOrder: '銷售訂單',
      salesShipment: '銷售發貨',
      salesReturns: '銷售退貨',
      sort: '序號',
      auditConfigNodeName: '審核節點名稱',
      triggerCondition: '觸發條件',
      noneSatisfied: '無條件觸發',
      allSatisfied: '全部滿足',
      oneSatisfied: '任意一條滿足',
      belowSalesPrice: '產品單價低於',
      maxCustomerReceivableBalance: '客戶應收款餘額大於',
      maxOrderAmount: '訂單總額大於',
      up: '上移',
      down: '下移',
      processor: '處理人',
      addAuditConfig: '添加審核流程',
      editAuditConfig: '編輯審核流程',
      priceType: '價格類型',
      salesAuditConfig: '審核設置',
      orderCheckStockEnable: '創建銷售訂單校驗庫存',
      deliveryCheckStockEnable: '創建銷售發貨單校驗庫存',
      pleaseSelectOne: '請配置觸發條件',
      salesPriceScheme: '銷售調價方案',
      salesPriceSchemeName: '方案名稱',
      targetPriceType: '價格類型',
      basePriceType: '調價依據',
      equal: '等於',
      calcType: '關系',
      calcValue: '數值',
      percentage: '%',
      value: '數值',
      addSalesPriceScheme: '添加調價方案',
      editSalesPriceScheme: '編輯調價方案',
      viewSalesPriceScheme: '查看調價方案',
      adjustAllSalesPrice: '全部調價',
      adjustSalesPriceBatch: '批量調價',
      allProduct: '全部產品',
      selectedProduct: '已選產品',
      pleaseSelectProduct: '請選擇產品',
      adjustPriceConfigNotContent: '請正確填寫計算規則',
      adjustPriceSubmitTips: '是否【確定】批量調整產品價格，系統將按表達式規則自動計算價格',
      accountStatementConfig: '銷售對賬單設置',
      opportunityQuotationNotify: '銷售機會報價通知',
      opportunityBidNotify: '銷售機會投標通知',
      accountStatementAutoGeneDelayDays: '自動生成對賬單延遲天數',
      manualReviewAccountStatement: '自動生成對賬單是否手動復核',
      accountStatementAutoConfirmDate: '對賬單自動確認天數',
      nextConfirmDateTips: '未確認的對賬單將在下次生成賬單時自動確認',
      salesStockIncludePurchase: '可用庫存包含在途',
      salesStockIncludeThird: '可用庫存包含三方庫存',
      orderCompleteConfig: '訂單完成設置',
      autoCompleteTipPrefix: '發貨和回款完成時',
      autoCompleteTipSubfix: '天之後自動完成銷售訂單',
      autoCompleteTip: '自動完成滿足條件的銷售訂單，當設定為0時，表示不自動完成',
      priceLevelEnable: '是否開啟價格標簽（正價、特價、裸價）',
      orderCustomerManagerChangeEnable: '訂單業務員是否可修改',
      salesPriceAutoFillRecent: '銷售訂單價格是否默認獲取最近成交價格'
    },
    goal: {
      fiscalYear: '財年',
      fiscalYearSettings: '財年設置',
      fiscalYearBegin: '財年開始月',
      orderAmount: '訂單金額',
      orderReceiptAmount: '訂單回款',
      signedOrderQuantity: '簽約訂單數量',
      addedCustomerQuantity: '新增客戶數量',
      followUpTimes: '跟進次數',
      followUpCustomerTimes: '跟進客戶次數',
      goalSetting: '目標值設置',
      person: '個人目標',
      organization: '部門目標',
      chooseObject: '選擇物件',
      checkedObject: '已選擇物件',
      fullYear: '全年',
      userName: '姓名',
      orgName: '部門',
      firstHalfYear: '上半年',
      latterHalfYear: '下半年',
      firstQuarter: '一季度',
      secondQuarter: '二季度',
      thirdQuarter: '三季度',
      fourthQuarter: '四季度',
      addTitle: '設置銷售目標',
      viewTitle: '查看銷售目標',
      goalIndexTypeTips: '請選擇銷售指標類型',
      businessInfosTips: '請選擇物件',
      fiscalYearBeginTips: '請選擇財年開始月份',
      fiscalYearTips: '請選擇財年',
      deleteFiscalYearTips: '是否確定刪除該財年?',
      addFiscalYearTitle: '添加財年',
      editFiscalYearTitle: '編輯財年',
      fiscalYearBeginError: '財年起始月年份需等於財年'
    },
    dynamicSalesRate: {
      warehouseName: '倉庫',
      productCategoryCount: '庫存商品總數',
      monthOrderProductCategoryCount: '本月動銷品種',
      yearOrderProductCategoryCount: '本年動銷品種',
      monthDynamicSalesRate: '本月動銷率',
      yearDynamicSalesRate: '本年動銷率',
      allWarehouse: '所有倉庫'
    },
    priceType: {
      priceTypeName: '價格名稱',
      relatedCustomer: '關聯客戶',
      relatedCustomerSort: '關聯客戶分類',
      relatedCustomerTag: '關聯客戶標籤',
      addRelatedCustomer: '添加關聯客戶',
      addRelatedCustomerSort: '添加關聯客戶分類',
      addRelatedCustomerTag: '添加關聯客戶標籤',
      addTitle: '添加價格類型',
      editTitle: '編輯價格類型',
      viewTitle: '查看價格類型',
      customer: '客戶',
      customerSort: '客戶分類',
      customerTag: '客戶標籤',
      priceTypeNameTips: '價格類型名稱必填',
      relatedTips: '至少關聯一種業務',
      deleteTips: '是否刪除這條價格類型',
      adjustPriceConfig: '價格自動計算',
      calcRules: '計算規則',
      expressNotContent: '請正確填寫計算規則'
    },
    service: {
      pleaseChooseStatus: '請選擇服務狀態',
      orderMessage: '訂單資訊',
      addService: '添加服務訂單',
      editService: '編輯服務訂單',
      viewService: '查看服務訂單',
      isDelete: '是否刪除該服務訂單？',
      assignedAgain: '該訂單存已經存在處理人，是否重新指派處理人？',
      chooseAssigned: '請選擇要指派的處理人',
      assignedSuccess: '指派成功',
      auditSucess: '審核成功',
      startSuccess: '該服務訂單已開始服務',
      stopSuccess: '暫停成功',
      cancelSuccess: '取消成功',
      activationSuccess: '啟動成功',
      finishSuccess: '訂單已完成',
      editor: '請輸入內容',
      uploading: '圖片插入中，請稍後',
      specifyTime: '指定時間',
      unSpecifyTime: '不指定時間',
      timeInterval: '時間區間',
      confirm: '確認',
      description: '服務內容',
      addServiceItem: '添加服務專案',
      common: {
        assigned: '指派',
        start: '開始',
        stop: '暫停',
        cancel: '取消',
        finish: '完成',
        activation: '啟動',
        saveDraft: '保存草稿',
        submit: '提交審核',
        audit: '審核'
      },
      orderCode: '訂單編碼',
      serviceItem: '服務專案',
      orderDate: '下單日期',
      customerName: '客戶',
      contactName: '連絡人',
      currency: '貨幣',
      amountWithTax: '金額',
      settlementMethod: '結算方式',
      serviceContent: '服務內容',
      timeType: '時間類型',
      serviceStatus: '服務狀態',
      startTime: '開始時間',
      endTime: '結束時間',
      auditStatus: '審核狀態',
      auditorName: '審核人',
      auditDate: '審核時間',
      currentHandlerName: '當前處理人',
      finishTime: '完成時間',
      serviceRemark: '備註',
      status: {
        draft: '草稿',
        unaudit: '待審核',
        audited: '已審核',
        notStart: '未開始',
        ongoing: '進行中',
        finish: '已完成',
        stoped: '已暫停',
        cancel: '已取消'
      },
      rules: {
        pleaseChooseServiceItem: '請選擇服務專案',
        pleaseChooseHandler: '請選擇處理人',
        pleaseChooseCustomer: '請選擇客戶',
        pleaseChooseContact: '請選擇連絡人',
        pleaseChooseTimeType: '請選擇一種時間類型',
        pleaseChooseStartTime: '請選擇開始時間',
        pleaseChooseEndTime: '請選擇結束時間',
        pleaseChooseCurrency: '請選擇貨幣',
        pleaseChooseAmount: '請輸入含稅價金額',
        pleaseChooseorderDate: '請選擇下單日期',
        pleaseChooseMethod: '請選擇付款方式',
        pleaseChooseContent: '請輸入服務內容'
      }
    },
    basicData: {
      period: {
        settlementPeriodCode: '編碼',
        nameZh: '名稱（中文）',
        nameEn: '英文',
        nameZhTw: '名稱（繁體）',
        periodType: '期限類別',
        periodData: '結算期限',
        settlementDate: '每月',
        settlementScope: '結算',
        reconciliationDate: '對賬日',
        isAutoDelivery: '自動生成銷售發貨單',
        receiptPlanAuto: '按回款進度',
        orderAuditAuto: '按銷售訂單審核',
        autoDelivery: '自動生成銷售發貨單',
        standardPeriod: '標準期限',
        monthPeriod: '月結期限',
        day: '天',
        monthDay: '日, 結算',
        reconciliationDay: '日',
        business: '天前的待結算業務',
        sort: '排序',
        isAutoDeductAdvanceReceipt: '預收款自動扣減',
        useCredit: '是否佔用授信額度設置',
        installmentEnable: '是否分期付款',
        installmentName: '分期階段',
        rate: '比例（%）',
        installmentRateOver: '分期比例超過100，請重新配置',
        installmentRateError: '分期比例設置錯誤',
        installmentRateConfig: '分期設置',
        autoDeliveryDay: '銷售發貨單自動生成時間',
        autoDeliveryDayPrefix: '交貨日期前',
        autoDeliveryDaySubfix: '天',
        autoDeliveryDayTips: '-1 表示直接生成'
      },
      addPeriod: '添加結算期限',
      editPeriod: '編輯結算期限',
      pleaseInputName: '請輸入結算名稱',
      pleaseInputCorrectValue: '請輸入0-100範圍內的值'
    },
    inventoryLock: {
      queryKeyword: '編碼、申請人、客戶',
      detailsQueryKeyword: '編碼、申請人、客戶、產品資訊',
      inventoryLockCode: '申請編碼',
      applicantId: '申請人',
      applyDate: '申請日期',
      effectType: '鎖定類型',
      customer: '客戶',
      receiptAmount: '收款金額',
      lockDate: '鎖定日期',
      unlockDate: '解鎖日期',
      lockReason: '鎖定原因',
      lockStatus: '狀態',
      direct: '直接生效',
      assignDate: '指定日期生效',
      deliveryOn: '款到生效',
      draft: '草稿',
      pending: '待審核',
      audited: '已審核',
      effected: '已生效',
      unlocked: '已解除',
      lockQuantity: '鎖定數量',
      unlockQuantity: '解鎖數量',
      addTitle: '添加鎖定庫存',
      editTitle: '編輯鎖定庫存',
      viewTitle: '查看鎖定庫存',
      unlockTitle: '解除鎖定庫存',
      unlockAll: '全部解除',
      unlock: '解除鎖定',
      collect: '收款',
      effect: '確認生效',
      transterSalesOrder: '轉銷售訂單',
      applicatIdTips: '申請人不能為空',
      applyDateTips: '申請日期不能為空',
      effectTypeTips: '鎖定類型不能為空',
      lockReasonTips: '鎖定原因不能為空',
      auditTips: '是否審核該鎖定庫存記錄?',
      deleteTips: '是否刪除該鎖定庫存記錄?',
      unlockAllTips: '是否解除全部該鎖定庫存記錄',
      revokeTips: '是否撤銷該鎖定庫存記錄',
      effectTips: '是否確認該鎖定庫存記錄生效',
      lockDateOrUnlockDateTips: '指定日期鎖定需要選擇鎖定日期和解鎖日期',
      enabledStockLtLockQuantity: '可用庫存小於鎖定庫存',
      unlockDateLtlockDate: '解除日期不能小於等於鎖定日期',
      customerTips: '款到鎖定需要選擇客戶',
      receiptAmountTips: '款到鎖定需要輸入金額',
      inventoryLockOrder: '鎖定庫存單',
      inventoryLockDetails: '鎖定庫存明細',
      locakWarehouse: '鎖定倉庫',
      lockQuantityLeft: '剩余鎖定'
    },
    statement: {
      orderType: '訂單類型',
      orderCode: '單據編號',
      companyName: '所屬公司',
      createTime: '下單時間',
      orderPersonName: '開單人/下單人',
      orderDate: '單據日期',
      deliveryDate: '交貨日期',
      customerName: '客戶名稱',
      orderRemark: '備註',
      receiptorName: '收件人',
      receiptorPhone: '收件人電話',
      receiptorAddress: '收件地址',
      productRemark: '說明',
      productName: '產品名稱/商品名稱',
      productModel: '規格型號',
      productUnit: '單位',
      quantity: '訂單數量',
      returnQuantity: '已退貨數量',
      aboutReturnQuantity: '應退貨數量',
      unitPriceTax: '單價(含稅)',
      productSubtotal: '金額(含稅)',
      freight: '運費',
      productAmountAfterDiscount: '折後產品總額',
      orderAmount: '訂單總額',
      returnAmount: '已退款金額',
      aboutReturnAmount: '應退款金額',
      collectionStatus: '收款狀態',
      collectionAmount: '已收金額',
      collectionAccount: '收款賬戶',
      collectionLeftAmount: '欠款金額',
      salesOrderType: '銷售訂單',
      mallOrderType: '商城訂單',
      costUnitPrice: '成本單價(含稅)',
      costSubtotal: '成本金額(含稅)',
      costAmount: '成本總額',
      profitAmount: '利潤',
      profitRate: '利潤率(%)',
      aboutOutQuantity: '預計委外數量',
      realOutQuantity: '實際委外數量',
      orderViewDetails: '訂單明細',
      byCustomer: '客戶',
      byCustomerManager: '開單員',
      byBusinessManager: '業務員',
      customerManagerName: '開單員',
      businessManagerName: '業務員',
      orderCount: '訂單數量',
      productAmountSum: '產品總額',
      freightSum: '運費總額',
      orderAmountSum: '訂單總額',
      aboutReturnAmountSum: '應退總額',
      returnAmountSum: '已退總額',
      viewSummaryDetails: '明細',
      realOrderAmountSum: '實際成交總額'
    },
    reconciliation: {
      status: '對賬狀態',
      queryKeyword: '客戶名稱',
      customerName: '客戶名稱',
      accountStatementPeriod: '賬單周期',
      accountStatementAmount: '賬單金額',
      confirmTime: '確認時間',
      review: '複核',
      reGene: '重新生成',
      revoke: '撤銷',
      toBeReview: '待複核',
      toBeConfirmed: '待確認',
      confirmedByCustomer: '已確認（客戶確認）',
      confirmedAuto: '已確認（自動確認）',
      reviewTips: '確認對賬單【已複核】，確認後將發送客戶確認',
      reGeneTips: '是否重新生成對賬單',
      businessDetails: '業務明細',
      salesOrder: '銷售訂單',
      salesOrderAdjust: '銷售變更單',
      salesReturn: '銷售退貨單',
      mallOrder: '商城訂單',
      mallReturn: '商城退貨單',
      collection: '收款單',
      payment: '付款單',
      advanceReceipt: '預收款單',
      accountStatementDetails: '賬單業務明細',
      businessType: '業務類型',
      businessDate: '業務日期',
      businessCode: '業務編碼',
      transactionAmount: '發生金額',
      advanceReceiptBalance: '預收款余額',
      lastBillAmount: '上期賬單金額',
      currentOrderAmount: '本期訂單金額',
      currentReturnOrderAmount: '本期退貨金額',
      currentCollectionAmount: '本期收款金額',
      currentPaymentAmount: '本期退款金額',
      currentBillAmount: '本期賬單金額',
      viewAccountStatementDetails: '查看賬單',
      viewAccountStatement: '查看賬單業務明細',
      depositBalance: '保證金余額',
      depositCollection: '保證金收款',
      depositPayment: '保證金付款',
      mallRecharge: '商城充值',
      confirm: '確認',
      confirmTips: '是否確認本期賬單,確認後將不能更改',
      revokeTips: '是否撤銷對賬單重新復核',
      reconciliationMail: '賬單接收郵箱',
      aboutConfirmTime: '自動確認時間',
      isQueryZero: '是否查詢為0的賬單',
      reviewBatch: '批量復核',
      confirmBatch: '批量確認',
      batchReviewTips: '確認對賬單【已復核】，確認後將發送客戶確認',
      batchConfirmTips: '是否批量確認本期賬單,確認後將不能更改',
      selectStatement: '已選賬單',
      queryRange: '查詢範圍',
      pleaseSelectStatement: '請選擇賬單'
    },
    opportunity: {
      queryKeywords: '商機編碼 客戶 聯系人',
      opportunityCode: '商機編碼',
      opportunityName: '商機名稱',
      customerName: '客戶',
      opportunitySource: '商機來源',
      industry: '所屬行業',
      operator: '經辦人',
      contactName: '聯系人',
      contactPosition: '聯系人職位',
      contactPhone: '聯系人手機',
      contactMobile: '聯系人電話',
      contactWechat: '聯系人微信',
      contactEmail: '聯系人郵箱',
      decisionMakerName: '關鍵決策人',
      projectBudget: '預算總額',
      projectRequireQuantity: '預計數量',
      deliveryDate: '項目交期',
      invoiceRequire: '開票要求',
      deliveryType: '交貨方式',
      deliveryAddr: '交貨地址',
      opportunityStatus: '狀態',
      requireDesc: '需求描述',
      nonInvoicing: '不開票',
      smallScale: '小規模',
      generalTaxpayer: '一般納稅人',
      centralizedDelivery: '集中發貨',
      onePieceDelivery: '一件代發',
      otherDelivery: '其它',
      created: '已創建',
      followingUp: '跟進中',
      toSalesOrder: '已轉銷售訂單',
      completed: '已完成',
      addOpportunity: '添加銷售機會',
      editOpportunity: '編輯銷售機會',
      viewOpportunity: '查看銷售機會',
      addFollowUp: '跟進',
      addSalesOrder: '轉訂單',
      opportunityNamePlease: '請輸入商機名稱',
      customerPlease: '請選擇客戶',
      operatorPlease: '請選擇經辦人',
      projectBudgetPlease: '請填寫預算總額',
      budgetUnitPricePlease: '請填寫預算單價',
      biddingDatePlease: '請填寫投標日期',
      projectRequireQuantityPlease: '請填寫預計數量',
      opportunityInfo: '基本信息',
      followUp: '客戶跟進',
      deleteOpportunityTips: '確認刪除該銷售機會？',
      contactPlease: '請選擇聯系人',
      isBidding: '是否招投標',
      biddingDate: '招投標日期',
      budgetUnitPrice: '預算單價',
      latestQuotationDate: '最晚報價日期',
      budgetOrUnitPricePlease: '請填寫預算單價/預算總額'
    },
    profit: {
      salesOrder: '銷售訂單',
      mallOrder: '商城訂單',
      mallGiftOrder: '禮盒訂單',
      profitForOrder: '訂單毛利',
      profitForProduct: '產品毛利',
      startDate: '訂單日期起',
      endDate: '訂單日期止',
      orderType: '訂單類型',
      businessCode: '訂單編號',
      createTime: '下單時間',
      operatorName: '開單人/下單人',
      orderDate: '單據日期',
      productSubtotal: '產品銷售總額',
      orderAmount: '訂單金額',
      aboutReturnAmount: '退款金額',
      aboutReturnQuantity: '退貨數量',
      quantity: '訂單數量',
      costSubtotal: '成本總額',
      freight: '運費',
      productCost: '產品成本總額',
      subtotal: '合計',
      profit: '毛利',
      profitRate: '毛利率',
      orderQueryKeywords: '訂單編號、客戶',
      productQueryKeywords: '訂單編號、客戶、產品'
    }
  },
  finance: {
    adjust: '批量確認',
    pleaseChooseData: '請選擇要確認的資料',
    amountNeedThanZero: '金額必須大於0',
    confirmAdjustText: '是否確認',
    startDate: '開始日期',
    endDate: '結束日期',
    dateTo: '至',
    accountName: '帳戶名稱',
    accountType: '帳戶類型',
    accountUserName: '帳戶戶名',
    accountNumber: '帳號',
    accountAddress: '開戶行',
    currencyType: '幣種',
    isEnable: '是否啟用',
    remark: '備註',
    accountInfo: '帳戶信息',
    editFinance: '編輯資金帳戶',
    addFinance: '添加資金帳戶',
    viewFinance: '查看資金帳戶',
    pleaseInputAccountName: '請輸入帳戶名稱',
    pleaseInputAccountUserName: '請輸入帳戶戶名',
    pleaseInputAccountNumber: '請輸入帳號',
    pleaseChooseFinanceAccount: '請選擇要刪除的資金帳戶',
    pleaseInputAccountAddress: '請輸入開戶行位址',
    depositCode: '存取款編碼',
    depositDate: '日期',
    accountOutId: '轉出帳戶',
    currencyOutId: '轉出幣種',
    amountOut: '轉出金額',
    accountInId: '轉入帳戶',
    currencyInId: '轉入幣種',
    amountIn: '轉入金額',
    currencyCommissionId: '手續費幣種',
    amountCommission: '手續費金額',
    depositAbstract: '摘要',
    operatorId: '經辦人',
    status: '狀態',
    beginBalance: '期初餘額',
    currentBalance: '當前餘額',
    pleaseInputBeginBalance: '請輸入期初餘額',
    pleaseInputCurrentBalance: '請輸入當前餘額',
    purcheseOrder: '採購訂單',
    saleAccountBook: '銷售回款',
    purchaseReceipt: '採購收貨',
    payPlan: '付款計畫',
    saleReturn: '銷售退貨',
    payRequest: '付款申請',
    reimbursement: '費用報銷',
    rejectMallRechargeOfflineTips: '是否駁回該收款單',
    financeStutas: {
      draftStutas: '草稿',
      toBeConfirmed: '待確認',
      confirmed: '已確認',
      deserted: '已作廢',
      all: '全部'
    },
    common: {
      startYearMonth: '開始年月',
      endYearMonth: '結束年月',
      yearMonth: '年月',
      accountBookSummary: '收入',
      payBookSummary: '支出',
      accountBookAmount: '收入金額',
      payBookAmount: '支出金額',
      accountBookDetails: '收入明細',
      payBookDetails: '支出明細',
      financeTooltip: {
        financeSummary: {
          source: '資料來源：財務管理-銷售回款、採購付款',
          rule: '取數規則：已確認的銷售回款，已確認的採購付款',
          dimension: '統計維度：按月統計收入、支出'
        }
      },
      audit: '審核',
      revoke: '撤銷',
      invoiceConfigSelector: '開票資訊選擇'
    },
    businessType: {
      purchasePayment: '採購付款',
      salesReceipt: '銷售回款',
      salesReturnRefund: '銷售退貨退款',
      purchaseReturnRefund: '採購退貨退款',
      dailyReceipt: '日常收款',
      dailyPayment: '日常付款',
      mallOrderCollection: '商城訂單',
      mallOrderRefund: '商城退貨/退款單',
      recharge: '商城充值',
      otherCollection: '其他收款',
      otherPayment: '其他付款',
      salesInventoryLock: '銷售鎖定庫存',
      mallOrderMonthPay: '掛賬月結',
      depositCollection: '保證金收款',
      depositPay: '保證金付款',
      depositSlip: '存款單',
      withdrawSlip: '取款單',
      advanceReceipt: '預收款',
      advancePayment: '預付款',
      distributionOrder: '代發訂單',
      addAccount: '添加資金賬戶',
      adjustAccountBalance: '賬戶余額調整',
      purchaseOutReturn: '委外退款',
      mallMemberUpgrade: '會員升級支付',
      mallOrderFreight: '商城訂單運費'
    },
    financeOperate: {
      submit: '提交',
      confirm: '確認',
      submitAndReview: '保存並確認',
      revoke: '撤銷',
      invalid: '作廢',
      print: '列印',
      view: '查看',
      saveAsDraft: '保存為草稿',
      return: '返回',
      reject: '駁回'
    },
    paymentDetails: {
      businessCode: '單據編碼',
      detailDate: '日期',
      businessUnitName: '往來單位',
      incomeAmount: '收入金額',
      payAmount: '支出金額',
      balanceAmount: '帳戶餘額',
      operatorName: '經辦人',
      startDate: '開始時間',
      endDate: '結束時間',
      details: '查看明細',
      businessType: '單據類型'
    },
    editDeposit: '編輯存取款',
    addDeposit: '添加存取款',
    viewDeposit: '查看存取款',
    editCollectBook: '編輯銷售回款',
    addCollectBook: '添加銷售回款',
    viewCollectBook: '查看銷售回款',
    collectBookInfo: '基本資訊',
    editPayBook: '編輯付款單',
    addPayBook: '添加付款單',
    viewPayBook: '查看付款單',
    payBookInfo: '付款單資訊',
    confirmText: '暫未保存，是否取消？',
    confirmInvalidText: '是否確認作廢？',
    confirmRevokeText: '是否確認撤銷？',
    confirmAuditText: '是否確認審核？',
    confirmSaveAsDraftText: '是否確認保存為草稿？',
    confirmSubmitAndReviewText: '是否提交並確認？',
    confirmSubmitText: '是否確認提交？',
    confirmDeleteText: '是否確認刪除？',
    pleaseChoosedOperatorId: '請選擇經辦人',
    pleaseChooseStatus: '請選擇狀態',
    pleaseInputAmountCommission: '請正確輸入手續費',
    pleaseChooseCurrencyCommissionId: '請選擇手續費幣種',
    pleaseInputAmountIn: '請輸入正確的轉入金額',
    pleaseChooseCurrencyInId: '請選擇轉入幣種',
    pleaseChooseAccountInId: '請選擇轉入帳戶',
    pleaseInputAmountOut: '請正確輸入金額',
    pleaseChooseCurrencyOutId: '請選擇轉出幣種',
    pleaseChooseAccountOutId: '請選擇轉出帳戶',
    pleaseChooseDepositDate: '請選擇日期',
    receivableInfo: '關聯應收款',
    financeCollectBookInfo: {
      businessCode: '單據編碼',
      company: '所屬公司',
      businessDate: '收款日期',
      operatorId: '經辦人',
      businessUnitId: '客戶ID',
      businessType: '業務類型',
      businessUnitName: '客戶名稱',
      businessUnitAccount: '客戶賬號',
      accountId: '資金賬戶',
      accountNumber: '收款賬號',
      currencyId: '幣種',
      creditableAmount: '可用預收款',
      receivableAmount: '應收金額',
      amountSum: '實收金額',
      amount: '本次收款',
      mallAmount: '商城訂單金額',
      amountAdvance: '抵扣預收款',
      financeAbstract: '摘要',
      status: '狀態',
      pleaseChooseBusinessDate: '請選擇收款日期',
      pleaseChooseBusinessUnitName: '請選擇往來對象',
      pleaseInputBusinessUnitAccount: '請輸入賬號',
      pleaseChooseAccountId: '請選擇資金賬戶',
      pleaseChooseCurrencyId: '請選擇幣種',
      pleaseInputAmount: '請輸入本次收款金額',
      pleaseChooseOperatorId: '請選擇經辦人',
      pleaseChooseReceivable: '請選擇應收款',
      collectionAccount: '收款賬戶',
      pleaseChoosePayable: '請選擇應付款',
      collectionDateStart: '收款日期起',
      collectionDateEnd: '收款日期止',
      allReceipt: '全部收款',
      salesReceipt: '銷售回款',
      dailyReceipt: '日常收款',
      otherReceipt: '收款金額',
      allPayment: '全部付款',
      purchasePayment: '采購付款',
      dailyPayment: '日常付款',
      otherPayment: '其它付款',
      editCollectBook: '編輯收款單',
      addCollectBook: '添加收款單',
      viewCollectBook: '查看收款單',
      collectionTarget: '收款對象',
      pleaseChooesCollectionTarget: '請選擇收款對象',
      mallOrderReceipt: '商城訂單收款',
      mallOrderCode: '商城訂單號',
      mallOrderRefund: '商城退款',
      mallRecharge: '商城充值',
      mallRechargeReceipt: '商城充值收款',
      defaultAdvanceReceiptGreatThenBalance: '抵扣預收款不能大於可用預收款',
      receivableItemGreatThenBalance: '列表【收款總額】不能大於應收款余額',
      directAdvanceReceiptTypeEmpty: '定向抵扣預收款類型未選擇',
      receivableSumGreatThenColelctionSum: '列表【收款總額】不能大於實收金額',
      directAdvanceReceiptType: '定向預收款類型',
      directAdvanceReceiptAmount: '定向抵扣金額',
      otherAmount: '收款金額',
      amountNow: '收款總額',
      salesReturnRefund: '銷售退款',
      directAdvancePayableAmount: '轉預付款金額',
      purchaseReturn: '采購退款',
      directAdvanceReceiptPay: '定向抵扣金額',
      mallPayType: '商城支付方式',
      hasReturnFromWx: '已從微信商戶後臺退款',
      otherCollection: '其它收款',
      commissionExtract: '傭金提現',
      rebateExtract: '返點提現',
      greatThenDefaultAdvanceReceipt: '可用預收款余額不足',
      toAdvancePayment: '轉預付款',
      toAdvanceReceiptAmount: '轉預收款(含定向)',
      returnAdvanceReceiptAmount: '退預收款(含定向)',
      returnAmountGreatThen: '實收金額大於列表應退總額，請調整本次收款金額',
      withRefundAmount: '含退款金額：',
      refundPayableList: '退款明細',
      refundAmountGreatThen: '退款明細存在退款金額大於應退款余額',
      otherExpense: '其它費用',
      discountAmount: '優惠金額',
      toAdvanceReceipt: '轉預收款金額',
      collectAmountSum: '實收總額',
      isMultiAccount: '多賬戶',
      collectionAccountAmount: '收款賬戶金額',
      collectToAdvancePayment: '收款轉預付款',
      refundToAdvancePayment: '退款轉預付款',
      collectionAmountSumLessThenZero: '實收總額不能為空'
    },
    financePayBookInfo: {
      businessCode: '付款單編碼',
      company: '所屬公司',
      businessDate: '付款日期',
      operatorId: '經辦人',
      businessUnitId: '單位ID',
      businessUnitName: '往來對象',
      businessUnitType: '單位類型',
      businessUnitAccount: '往來對象賬號',
      accountId: '資金賬戶',
      accountNumber: '付款賬戶',
      currencyId: '幣種',
      creditableAmount: '可用預付款',
      receivableAmount: '應付金額',
      amountSum: '實付金額',
      amount: '本次付款',
      amountAdvance: '抵扣預付款',
      financeAbstract: '摘要',
      status: '狀態',
      pleaseChooseBusinessDate: '請選擇付款日期',
      pleaseChooseBusinessUnitName: '請選擇往來對象',
      pleaseInputBusinessUnitAccount: '請輸入供應商賬號',
      pleaseChooseAccountId: '請選擇資金賬戶',
      pleaseChooseCurrencyId: '請選擇幣種',
      pleaseInputAmount: '請輸入本次付款金額',
      pleaseChooseOperatorId: '請選擇經辦人',
      paymentAccount: '付款賬戶',
      supplier: '供應商',
      businessUnitAccountBank: '開戶銀行',
      businessUnitAccountName: '賬戶戶名',
      expenditureNotEmpty: '支出明細不能為空',
      incomeNotEmpty: '收入明細不能為空',
      paymentTarget: '付款對象',
      pleaseChooesPaymentTarget: '請選擇付款對象',
      mallRefundCode: '商城退款單號',
      mallRefundAmount: '退款金額',
      mallRechargeCode: '充值訂單號',
      mallRechargeAmount: '充值金額',
      directAdvanceReceiptReturn: '定向抵扣退款',
      otherAmount: '本次付款',
      payAmountSum: '付款總額',
      returnAmountGreatThen: '退款金額大於列表應退總額，請調整本次付款金額',
      payableAmountSumGreatThen: '實付金額大於列表付款總額之和，請調整本次付款金額',
      payableEmpty: '請輸入付款金額',
      toAdvancePayment: '轉預付款',
      paymentAccountAmount: '付款賬戶金額',
      payToAdvanceReceipt: '付款轉預收款',
      refundToAdvanceReceipt: '退預收款（含定向）',
      paymentAmountSumLessThenZero: '實付總額不能為空'
    },
    financeReceivable: {
      detail: '明細',
      summary: '匯總',
      receivable: '收款',
      status: {
        completed: '已完成',
        receivingMoney: '收款中',
        unCollected: '未收款'
      },
      collect: '添加收款',
      businessType: {
        salesOrder: '銷售訂單',
        salesDelivery: '銷售發貨單'
      },
      receivableDateStart: '收款期限起',
      receivableDateEnd: '收款期限止',
      pleaseBusinessType: '請選擇業務類型'
    },
    financeReceivableInfo: {
      receivableCode: '應收款編碼',
      companyId: '所屬公司',
      businessUnitId: '單位ID',
      businessUnitName: '客戶名稱',
      currencyId: '幣種',
      amount: '應收金額',
      amountNow: '本次收款',
      receivableDate: '期限',
      salesOrderId: '銷售ID',
      salesOrderCode: '銷售單號',
      salesDeliveryId: '銷售發貨ID',
      salesDeliveryCode: '銷售發貨單',
      salesReceiptPlanId: '銷售回款計畫ID',
      salesReceiptPlanCode: '銷售回款單',
      businessCode: '業務編碼',
      businessType: '關聯業務',
      amountReceived: '收款金額',
      receivableBalance: '應收款餘額',
      advancePayment: '預收款金額',
      advancePaymentSum: '預收款餘額',
      ableAdvancePaymentSum: '可用預收款',
      unConfirmedAmount: '待確認金額',
      confirmedAmount: '已確認金額',
      status: '狀態',
      pleaseChooseOperatorId: '請選擇經辦人',
      addCollectBookSuccess: '收款成功',
      addCollectBookError: '收款失敗，請檢查資料',
      pleaseChooseData: '請選擇期限',
      remark: '備註',
      isConfirmSave: '是否保存',
      pleaseInputBusinessUnitName: '請輸入單位名稱',
      pleaseInputBusinessUnitId: '請選擇客戶',
      pleaseChooseCurrencyId: '請選擇幣種',
      pleaseInputAmount: '請輸入應收款金額',
      pleaseChooseReceivableDate: '請選擇期限',
      pleaseInputSalesOrderCode: '請輸入銷售編號',
      pleaseInputSalesDeliveryCode: '請輸入銷售發貨單',
      pleaseInputSalesReceiptPlanCode: '請輸入銷售回款單',
      receivableInfo: '應收款信息',
      editReceivable: '編輯應收款',
      addReceivable: '添加應收款',
      viewReceivable: '查看應收款',
      delete: '是否刪除',
      warningMasseage: '本次收款金額總和，只能小於實收金額',
      amountNowGreaterThanZero: '本次收款金額總和不能為負數',
      advanceLessThanBalance: '預收款余額不足',
      amountLessThanReceivableAmount: '本次收款金額不能大於應收金額',
      saveAdvanceAmount: '實收金額大於本次收款總金額部分，將會存入到預收款裡面',
      pleaseInputCollectionAmount: '請輸入表格中的本次收款',
      collectionAmountGreaterThanZero: '本次收款金額總和不能為負數',
      receivableCollectGreatThenBalance: '應收款存在本次收款金額大於應收餘額',
      directAdvanceReceiptAmount: '抵扣預收款',
      directAdvanceReceiptAmountGreatThenAmountNow: '抵扣預收款金額不能大於本次收款金額',
      directAdvanceReceiptAmountGreatThenReceivable: '抵扣預收款金額不能大於應收金額',
      receivableCollection: '收款單',
      businessDate: '業務日期',
      businessUnitSummary: '匯總',
      businessSummary: '按訂單',
      notZero: '應收款餘額不爲0',
      business: '業務編碼',
      collectionAmount: '收款金額',
      aboutReturnAmount: '退款單金額',
      returnAmount: '已退款金額',
      businessTypeEeum: {
        addManully: '手動添加',
        saleOrder: '銷售訂單',
        saleDelivery: '銷售發貨單',
        salePlan: '銷售回款計畫',
        mallOrder: '商城訂單',
        mallOrderCollection: '商城訂單收款',
        mallOrderRefund: '商城訂單退款',
        salesInventoryLock: '銷售鎖定庫存',
        purchaseReturn: '采購退貨'
      },
      businessCodeSelect: '業務編碼，請先選擇關聯業務',
      receivableSelect: '應收款選擇',
      allReceivable: '全部',
      salesOrderReceivable: '銷售訂單',
      salesDeliveryReceivable: '銷售發貨單',
      mallOrderReceivable: '商城訂單',
      salesInventoryLockReceivable: '銷售鎖定庫存',
      directAdvancePayableAmount: '轉預付款金額'
    },
    financeAdvanceReceipt: {
      viewAdvance: '查看預收款',
      accountCode: '收款單編碼',
      businessUnitId: '客戶ID',
      businessUnitName: '客戶名稱',
      businessDate: '發生日期',
      currencyId: '貨幣',
      amount: '金額',
      amountBalance: '餘額',
      remark: '備註'
    },
    financePayable: {
      pleaseChoosePayable: '請選擇應付款',
      summaryList: '匯總',
      detail: '明細',
      pay: '付款',
      editPayable: '編輯應付款',
      addPayable: '添加應付款',
      viewPayable: '查看應付款',
      payableInfo: '應付款資訊',
      payableCode: '系統自動生成',
      pleaseChooseCustomer: '請選擇客戶',
      pleaseChooseSupplier: '請選擇供應商',
      pleaseChooseUser: '請選擇用戶',
      isDelete: '是否刪除',
      payablePayment: '付款單',
      businessType: {
        saleOrder: '銷售訂單',
        saleDelivery: '銷售發貨',
        saleReturn: '銷售退貨',
        purchaseOrder: '採購訂單',
        purchaseReceipt: '採購收貨',
        purchaseReturn: '採購退貨',
        payRequest: '付款申請',
        reimbursement: '費用報銷',
        mallRefundOrder: '商城退款',
        purchaseOutOrder: '委外訂單',
        commissionExtract: '傭金提現',
        rebateExtract: '返點提現',
        advanceReceipt: '預收款',
        advacePayment: '預付款',
        depositSlip: '存款單',
        withdrawSlip: '取款單'
      },
      status: {
        all: '全部',
        payNot: '未付款',
        paying: '付款中',
        payFinish: '已完成'
      },
      businessCompanyType: {
        customer: '客戶',
        user: '用戶',
        supplier: '供應商',
        member: '商城會員'
      },
      rules: {
        pleaseChoosePayableDate: '請選擇付款期限',
        pleaseChooeseBusinessCompanyType: '請選擇單位類型',
        pleaseChooseBusinessCompanyName: '請選擇往來物件',
        pleaseChooseCurrencyId: '請選擇幣種',
        pleaseInputAmount: '請輸入金額',
        pleaseChooseBusinessType: '請選擇業務類型',
        pleaseInputBusinessCode: '請選擇關聯的業務'
      }
    },
    financePayableInfo: {
      payableCode: '應付款編碼',
      paymentCode: '關聯付款單編碼',
      company: '所屬公司',
      businessCompanyType: '往來對象類型',
      businessCompanyId: '往來單位ID',
      businessCompanyName: '往來對象',
      currencyId: '貨幣',
      amount: '應付金額',
      amountPaid: '已付金額',
      confirmedAmount: '已確認金額',
      unConfirmedAmount: '未確認金額',
      amountBalance: '應付餘額',
      unConfirmAmountSum: '待確認金額',
      prepayment: '預付款',
      ablePrepayment: '可用預付款',
      advancePayment: '預付款餘額',
      payAmount: '本次付款',
      payableDate: '期限',
      businessType: '業務類型',
      businessId: '業務ID',
      businessCode: '關聯業務',
      status: '狀態',
      remark: '備註',
      amountLessThanPayAmount: '本次付款金額不能大於應付金額',
      warningMasseage: '本次付款金額不能大於實付金額',
      payAmountGreaterThanZero: '本次付款金額總和不能為負數',
      advanceLessThanBalance: '抵扣金額只能小於可抵扣金額',
      pleaseInputPayAmount: '請輸入表格中的本次付款',
      saveAdvanceAmount: '實付金額大於本次付款總金額部分，將會存入到預付款裡面',
      supplierName: '供應商名稱',
      accountName: '帳戶戶名',
      accountNumber: '帳號',
      payablePaidGreatThenBalance: '應付款存在本次付款金額大於應付餘額',
      payableSelect: '應付款選擇',
      allPayable: '全部應付款',
      purchaseOrderPayable: '采購訂單',
      salesReturnPayable: '銷售退款',
      mallOrderReturnPayable: '商城退款',
      purchaseOutOrderPayable: '委外訂單',
      returnAdvanceReceiptType: '退預收款類型',
      returnAdvanceReceiptAmount: '退預收款金額',
      amountRefund: '付款金額',
      confirmedRefundAmount: '已確認金額',
      unConfirmedRefundAmount: '待確認金額',
      paidAmount: '已付金額',
      notZero: '應付款余額不為0'
    },
    financeAdvancePayment: {
      viewPayment: '查看預付款',
      accountCode: '付款單編碼',
      businessCompanyType: '單位類型',
      businessCompanyId: '單位ID',
      businessCompanyName: '單位名稱',
      businessDate: '發生日期',
      currencyId: '貨幣',
      amount: '金額',
      amountBalance: '餘額',
      remark: '備註'
    },
    financeApportionmentRule: {
      payableInfo: '關聯應付款',
      saleApportionmentRule: '銷售回款平攤規則:',
      purchaseApportionmentRule: '採購付款平攤規則:',
      dateNearToFar: '按期限由近到遠',
      dateFarToNear: '按期限由遠到近',
      amountBigToSmall: '按金額從大到小',
      amountSmallToBig: '按金額從小到大',
      pleaseGoOnChooseCustomer: '請選擇客戶'
    },
    financeApply: {
      applyCode: '申請編碼',
      company: '所屬公司',
      applyDate: '申請日期',
      applycantName: '申請人',
      departmentName: '部門',
      payeeType: '收款方類型',
      payeeName: '收款方',
      payeeBankNumber: '收款方帳號',
      payeeBankName: '收款方銀行',
      businessType: '關聯業務類型',
      businessCode: '關聯業務',
      receive: '付款方式',
      currencyId: '貨幣',
      totalAmount: '總金額',
      totalAmountWords: '金額大寫',
      auditorName: '審核人',
      auditTime: '審核時間',
      status: '狀態',
      applyRemark: '備註',
      illegalData: '請輸入有效資料',
      businessCodeSelect: '業務編碼，請先選擇關聯業務',
      common: {
        addApply: '添加付款申請',
        editApply: '編輯付款申請',
        viewApply: '查看付款申請',
        isDeleta: '是否刪除該付款申請？',
        autoInput: '編碼系統自動生成',
        saleOrder: '銷售訂單',
        saleDelivery: '銷售發貨單',
        purchaseOrder: '採購訂單',
        saveDraft: '保存為草稿',
        submit: '提交審核',
        audit: '審核',
        customer: '客戶',
        supplier: '供應商',
        user: '內部用戶',
        other: '其他',
        applyMessage: '付款申請資訊',
        isNotNull: '上一條記錄不能為空，請先填寫上一條記錄',
        pleaseChooseType: '請先選擇收款方類型'
      },
      rules: {
        pleaseChooseDate: '請選擇申請日期',
        pleaseChooseCant: '請選擇申請人',
        pleaseChooseDepartment: '請選擇部門',
        pleaseChoosePayee: '請選擇收款方',
        pleaseChooseSettle: '請選擇付款方式'
      },
      item: {
        amount: '金額',
        paymentItem: '摘要',
        expenditureType: '支出類別',
        incomeType: '收入類別'
      }
    },
    financeApplyItem: {
      amount: '金額',
      paymentItem: '項目摘要'
    },
    accountBookPurpose: {
      incomeType: '收入類型',
      expenditureType: '支出類型',
      business: '關聯業務',
      amount: '金額',
      details: '摘要'
    },
    invoiceApplication: {
      status: {
        draft: '草稿',
        unAudit: '待審核',
        audit: '已審核',
        invoiced: '已開票',
        delivered: '已交付',
        signed: '已簽收'
      },
      operate: {
        draft: '保存為草稿',
        unAudit: '提交',
        audit: '提交並審核',
        invoice: '開票',
        delivery: '交付',
        sign: '簽收'
      },
      queryKeyword: '申請編碼、客戶名稱',
      applicationCode: '申請編碼',
      invoiceCategory: '發票類型',
      customer: '客戶',
      buyerInfo: '買方信息',
      buyerName: '買方名稱',
      buyerTaxNumber: '買方納稅人識別號',
      buyerAddress: '買方地址',
      buyerPhone: '買方電話',
      buyerBank: '買方開戶行',
      buyerAccountNumber: '買方帳號',
      sellerInfo: '賣方信息',
      sellerName: '賣方名稱',
      sellerTaxNumber: '賣方納稅人識別號',
      sellerAddress: '賣方地址',
      sellerPhone: '賣方電話',
      sellerBank: '賣方開戶行',
      sellerAccountNumber: '賣方帳號',
      totalAmountTax: '價稅合計',
      pleaseItemType: '請選擇明細來源',
      selectProduct: '全部產品',
      selectSalesOrder: '銷售訂單',
      selectSalesDelivery: '銷售發貨單',
      applicationItemName: '貨物或應稅勞務、服務名稱',
      addApplicationTitle: '添加開票申請',
      editApplicationTitle: '編輯開票申請',
      viewApplicationTitle: '查看開票申請',
      verifyTips: '確認【審核】該開票申請',
      invoicedTips: '確認該申請【開票】',
      deliveredTips: '確認該開票申請【已交付】',
      signedTips: '確認該開票申請【已簽收】',
      deleteTips: '確認【刪除】該開票申請',
      revokeTips: '確認【撤銷】該開票申請',
      pleaseChooseInvoiceCategory: '請選擇發票類型',
      pleaseChooseCustomer: '請選擇客戶',
      pleaseInputBuyerName: '請填寫購買方名稱',
      pleaseInputBuyerTaxNumber: '請填寫購買方納稅人識別號',
      pleaseInputSellerName: '請填寫銷售方名稱',
      pleaseInputSellerTaxNumber: '請填寫銷售方納稅人識別號',
      pleaseChooseApplicationItem: '請填寫開票明細'
    },
    invoiceInfo: {
      invoiceCode: '發票代碼',
      invoiceNumber: '發票號碼',
      invoiceDate: '開票日期',
      amountTax: '稅額合計',
      totalAmountTax: '價稅合計',
      totalAmount: '金額合計(不含稅)',
      checkStatus: '查驗狀態',
      filePath: '發票附件',
      addInvoiceTitle: '添加發票',
      editInvoiceTitle: '編輯發票',
      viewInvoiceTitle: '查看發票',
      deleteTips: '確認【刪除】該發票',
      normal: '正常',
      error: '異常',
      unCheck: '未查驗',
      invoiceSelector: '請選擇發票',
      fileName: '檔案名',
      pleaseUploadInvoiceFile: '請上傳發票附件',
      fileTypeError: '僅支援 PNG/JPG/JPEG/PDF/OFD 格式檔',
      uploadFile: '上傳文件',
      ofdNotAllowPreview: 'ofd檔暫不支援預覽'
    },
    invoiceRecord: {
      startDate: '登記日期起',
      endDate: '登記日期止',
      makeInvoice: '開票登記',
      receiptInvoice: '收票登記',
      queryKeyword: '登記編碼',
      recordDate: '登記日期',
      totalAmountTax: '發票總金額',
      recordCode: '登記編碼',
      addInvoiceRecordTitle: '添加開票登記',
      editInvoiceRecordTitle: '編輯開票登記',
      viewInvoiceRecordTitle: '查看開票登記',
      deleteTips: '確認【刪除】該開票登記',
      pleaseChooseBusinessType: '選擇關聯業務單據',
      selectInvoice: '選擇發票',
      occurDate: '發生日期',
      totalAmountTaxBusiness: '本次開票金額',
      business: '關聯業務',
      addInvoice: '新增發票',
      businessAmount: '業務金額',
      invoicedAmount: '已開票金額'
    },
    invoiceType: {
      vatInovice: '增值稅普通發票',
      vatInvoiceSpecial: '增值稅專用發票',
      vatInvoiceElectonic: '增值稅普通發票（電子)',
      vatInvoiceVolumn: '增值稅普通發票（卷式）',
      vatInvoiceSpecialElectonic: '增值稅專用發票（電子）',
      motorSalesInvoice: '機動車銷售統一發票',
      trainTicket: '火車票',
      passenengerTicket: '客運發票',
      airPassengerTicket: '航空客運機票行程單',
      roadToll: '過路費',
      withholdingTaxInvoice: '代扣代繳稅收通用繳款憑證',
      importTaxInvoice: '海關進口增值稅專用繳款書',
      agricultralPurchaseInvoice: '農產品收購發票',
      vatInvoiceSpecialTransportation: '貨物運輸業增值稅專用發票',
      other: '其它'
    },
    currencyExchangeRate: {
      currencyName: '貨幣名稱',
      currencySymbol: '符號',
      exchangeRateValue: '固定匯率值',
      remark: '備註',
      add: '添加貨幣匯率',
      edit: '編輯貨幣匯率',
      delete: '是否刪除該貨幣匯率?',
      pleaseInputCurrencySymbol: '請輸入貨幣符號',
      pleaseInputRateValue: '請輸入固定匯率值',
      pleaseChooseCurrency: '請選擇貨幣'
    },
    config: {
      invoice: {
        headerInfo: '多公司及開票資訊',
        company: '公司名稱',
        taxpayerName: '納稅人名稱',
        taxpayerNumber: '納稅人識別號',
        companyAddress: '公司地址',
        companyPhone: '公司電話',
        bank: '開戶銀行',
        bankAccount: '銀行帳號',
        pleaseInputCompleteData: '請選擇所屬公司和輸入納稅人名稱、納稅人識別號',
        isDeleteConfig: '是否刪除該配置？'
      },
      inventoryConfig: '庫存參數設置',
      inventoryCostType: '庫存成本核算方式',
      fifo: '先進先出',
      moveWeightedAvg: '移動加權平均'
    },
    deposit: {
      depositDeatil: '保證金明細',
      depositSummary: '保證金匯總',
      addDeposit: '添加保證金',
      editDeposit: '編輯保證金',
      viewDeposit: '查看保證金',
      returnDeposit: '保證金退款',
      refundAmount: '已退金額',
      waitReturnAmount: '待退金額',
      return: '退款',
      amount: '保證金金額',
      balance: '保證金餘額',
      ableReturnAmount: '可退金額',
      returnAmount: '退款金額',
      returnSuccess: '退款成功',
      confirmSuccess: '確認成功',
      returnDate: '退款日期',
      returnDetial: '退款明細',
      confirm: '確認',
      priceTip: '退款金額不能大於可退金額',
      customerName: '客戶名稱',
      bussinessCode: '業務單據',
      depositBalanceDeatil: '保證金餘額明細',
      bussinessData: '收付款日期'
    },
    advanceReceipt: {
      advanceReceiptCode: '預收款編碼',
      companyName: '所屬公司',
      businessDate: '發生日期',
      customerCode: '客戶編碼',
      customerName: '客戶名稱',
      customer: '客戶',
      managerName: '業務員',
      currencyId: '貨幣類型',
      amount: '預收金額',
      financialCollectionAmount: '財務收款金額',
      receiptAmount: '預收款金額',
      ableAmountBalance: '可用預收款餘額',
      notAuditAmount: '未審核預收款金額',
      advanceDetail: '預收款明細',
      advanceSummary: '預收款餘額',
      accountId: '收款帳戶',
      receiptMethod: '收款方式',
      auditStatus: '審核狀態',
      auditId: '審核人',
      auditDate: '審核時間',
      isDirect: '是否定向抵扣',
      writeOffStatus: '核銷狀態',
      writeOffAmount: '核銷金額',
      addAdvance: '添加預收款',
      message: '基本資訊',
      remark: '備註',
      manual: '手動添加',
      collectionGene: '收款單生成',
      addMethod: '來源',
      advanceReceiptType: '預收款類型',
      salesReturnPaymentGene: '銷售退款付款單生成',
      defaultAdvanceReceiptBalance: '默認預收款余額',
      directAdvanceReceiptBalance: '定向預收款余額',
      balanceDetails: '明細',
      balanceExpnditure: '收支',
      advanceBalanceTitle: '預收款余額明細',
      advanceDetailsTitle: '預收款收支明細',
      transactionTime: '交易時間',
      transactionAmount: '交易金額',
      relatedBusiness: '業務單',
      importAdvanceReceipt: '預收款導入',
      attentionTipsTitle: '預收款導入註意事項',
      attentionTips1: '1.建議使用官方Excel模板導入數據',
      attentionTips2: '2.上傳EXCEL後，請準確填寫標題行號和數據起始行號',
      attentionTips3: '3.請準確填寫數據映射關系並檢查數據預覽是否相符',
      batchSet: '批量設置',
      executeResultInfo: '共{0}條記錄，成功：{1}條，跳過：{2}條',
      batchSetTips: '請批量設置好數據進行下一步導入操作，否則將導入失敗',
      commissionExtractGene: '傭金提現生成',
      targetAdvanceReceiptType: '目標預收款類型',
      transferAdvanceReceipt: '轉移預收款',
      pleaseSelectAdvanceReceiptBalance: '請選擇要轉移預收款',
      pleaseSelectSameCompanyAdvanceReceiptBalance: '請選擇同一所屬公司預收款',
      confirmTransferAdvanceReceipt: '確認轉移預收款',
      sourceAdvanceReceiptType: '要轉移預收款類型',
      advanceReceiptRefund: '退款',
      receiptRefundAmount: '退款金額',
      addAdvanceRefund: '添加預收款退款',
      status: {
        notAudit: '未審核',
        audit: '已審核',
        notWriteOff: '未核銷',
        partialWriteOff: '部分核銷',
        writeOff: '已核銷',
        productBrand: '產品品牌',
        saleOrder: '銷售訂單',
        productCategory: '產品分類',
        product: '產品',
        yes: '是',
        no: '否',
        revoked: '已撤銷'
      },
      rules: {
        pleaseChooseCompany: '請選擇所屬公司',
        pleaseChooseDate: '請選擇發生日期',
        pleaseChooseCustomer: '請選擇客戶',
        pleaseChooseCurrency: '請選擇貨幣',
        pleaseInputAmount: '請輸入金額',
        pleaseChooseAccount: '請選擇收款帳戶',
        pleaseChooseMethod: '請選擇收款方式',
        pleaseChooseAdvanceReceiptType: '請選擇預收款類型',
        collectedAmountPlease: '財務收款金額不能為空',
        advanceReceiptAmountPlease: '預收款金額不能為空'
      }
    },
    advancePayment: {
      advancePaymentCode: '預付款編碼',
      companyName: '所屬公司',
      businessDate: '發生日期',
      businessUnitCode: '供應商編碼',
      businessUnitName: '供應商名稱',
      businessUnit: '供應商',
      managerName: '業務員',
      currencyId: '貨幣類型',
      amount: '預付金額',
      paymentAmount: '財務付款金額',
      advancePaymentAmount: '預付款金額',
      ableAmountBalance: '可用預付款餘額',
      notAuditAmount: '未審核預付款金額',
      advanceDetail: '預付款明細',
      advanceSummary: '預付款餘額',
      accountId: '付款帳戶',
      paymentMethod: '付款方式',
      auditStatus: '審核狀態',
      auditId: '審核人',
      auditDate: '審核時間',
      addAdvance: '添加預付款',
      message: '基本資訊',
      remark: '備註',
      manual: '手動添加',
      collectionGene: '付款單生成',
      addMethod: '來源',
      paymentGene: '付款單自動生成',
      purchaseReturnPaymentGene: '采購退款自動生成',
      status: {
        notAudit: '未審核',
        audit: '已審核'
      },
      rules: {
        pleaseChooseCompany: '請選擇所屬公司',
        pleaseChooseDate: '請選擇發生日期',
        pleaseChooseBusinessUnit: '請選擇供應商',
        pleaseChooseCurrency: '請選擇貨幣',
        pleaseInputAmount: '請輸入金額',
        pleaseChooseAccount: '請選擇付款帳戶',
        pleaseChooseMethod: '請選擇付款方式'
      }
    },
    commission: {
      keywordQuery: '業務編碼 客戶名稱 會員名稱',
      businessType: '業務類型',
      businessCode: '業務編碼',
      orderAmount: '訂單金額',
      refundAmount: '退款金額',
      orderBalance: '訂單餘額',
      member: '會員',
      customer: '客戶',
      firstCustomer: '一級客戶',
      secondCustomer: '二級客戶',
      customerName: '客戶名稱',
      commissionRate: '分傭比例',
      commission: ' 傭金',
      purchaser: '購買方',
      mallOrder: '商城訂單',
      endDate: '傭金計算截至時間',
      salesOrder: '銷售訂單',
      undivided: '未分傭',
      allocated: '已分傭',
      orderDateStart: '訂單時間起',
      orderDateEnd: '訂單時間止',
      commissionStatus: '分傭狀態',
      orderDate: '訂單時間',
      commissionDetails: '傭金明細',
      commissionSummary: '傭金匯總',
      firstCommission: '一級傭金',
      secondCommission: '二級傭金',
      notAvailableCommission: '待分傭',
      availableCommission: '已分傭',
      commissionSubtotal: '小計',
      commissionTotal: '合計',
      summaryQueryKeywords: '客戶',
      toAdvanceReceipt: '轉預收款',
      toCash: '微信轉賬',
      commissionExtract: '提現申請',
      extractType: '提現方式',
      extractAmount: '提現金額',
      extractStatus: '提現狀態',
      operator: '審核人',
      auditTime: '審核時間',
      rejectReason: '駁回原因',
      applicationTime: '申請時間',
      auditStatus: '審核狀態',
      auditCommissionExtractApply: '傭金提現審核',
      passTips: '確認【通過】該提現申請',
      rejectTips: '確認【駁回】該提現審核',
      emptyRejectReason: '請填寫駁回原因',
      extractCommissionTotal: '已提現',
      commissionBalance: '余額',
      businessUnitType: '類型',
      businessUnitName: '類型名稱',
      detail: '明細',
      memberOrCustomerName: '會員/客戶名稱',
      commissionCalcMethod: '計算方式',
      extractBankCard: '提現銀行卡',
      extractAccountBank: '銀行',
      extractAccountName: '戶名',
      extractAccountNumber: '賬號',
      commissionDetailsExport: '傭金明細導出',
      customerOrMember: '會員/客戶'
    },
    advanceReceiptType: {
      queryKeyword: '類型名稱',
      advanceReceiptTypeName: '類型名稱',
      product: '產品',
      productBrand: '產品品牌',
      selectProduct: '選擇產品',
      selectProductBrand: '選擇產品品牌',
      addTitle: '添加預收款類型',
      editTitle: '編輯預收款類型',
      viewTitle: '查看預收款類型',
      advanceReceiptTypeNameNotEmpty: '請填寫預收款類型名稱',
      limitEmpty: '預收款使用範圍不能為空',
      usedRange: '使用範圍',
      deleteTips: '是否【刪除】該預收款類型',
      financeAccount: '關聯賬戶',
      financeAccountNotEmpty: '關聯賬戶不能為空',
      defaultAccount: '默認資金賬戶'
    }
  },
  postSale: {
    order: {
      postSaleCode: '售後編碼',
      customerName: '客戶名稱',
      customerCode: '客戶編碼',
      businessType: '業務類型',
      businessCode: '業務編碼',
      auditStatus: '審核狀態',
      title: '售後單',
      addTitle: '添加售後單',
      editTitle: '編輯售後單',
      viewTitle: '查看售後單',
      deleteTips: '是否刪除該售後單?',
      revokeTips: '是否撤銷該售後單?',
      salesOrder: '銷售訂單',
      salesDelivery: '銷售發貨單',
      customerNameNotEmtpy: '客戶名稱不能為空',
      businessTypeNotEmtpy: '業務類型不能為空',
      businessCodeNotEmtpy: '業務編碼不能為空',
      pleaseChooseBusinessType: '請選擇業務類型',
      keywordPlaceHolder: '售後編碼、客戶名稱、客戶編碼、業務編碼',
      unaudit: '未審核',
      audit: '已審核'
    },
    product: {
      common: {
        startDate: '發貨日期開始時間',
        endDate: '發貨日期結束時間',
        keywordPlaceHolder: '產品資訊、客戶名稱',
        toExpire: '即將到期',
        expired: '已過期',
        all: '全部',
        normal: '正常',
        day: '天',
        add: '添加售後產品',
        isDeleteProduct: '是否刪除該售後產品？',
        chooseCustomer: '請先選擇一個客戶',
        chooseDelivery: '請先選擇一筆發貨單',
        productInfo: '產品資訊'
      },
      info: {
        productCode: '產品編碼',
        serialNumber: '序號',
        productName: '產品名稱',
        productModel: '規格型號',
        productAssistValue: '輔助屬性',
        productUnit: '計價單位',
        customerName: '客戶名稱',
        deliveryDate: '發貨日期',
        businessCode: '發貨單',
        quantity: '數量',
        duringDate: '售後期間',
        during: '至',
        startDate: '開始日期',
        endDate: '結束日期',
        postSaleStatus: '售後狀態',
        remark: '備註'
      },
      rules: {
        pleasechooseCustomer: '請選擇客戶',
        pleasechooseBusiness: '請選擇發貨單',
        pleasechooseDeliveryDate: '請選擇發貨日期',
        pleaseInputProductCode: '請選擇產品',
        pleaseInputProductName: '請輸入產品名稱',
        pleaseInputProductUnit: '請輸入計價單位',
        pleaseInputSerialNumber: '請輸入序號',
        pleaseChooseStartDate: '請選開始日期',
        pleaseChooseEndDate: '請選擇結束日期'
      }
    }
  },
  production: {
    bom: {
      bomCode: '產品BOM編碼',
      bomName: '物料清單名稱',
      bomVersion: '版本',
      dwgNo: '圖號',
      customerName: '客戶',
      productionCost: '參考生產費用',
      laborCost: '參考人工費用',
      auditor: '審核人',
      materielName: '物料名稱',
      materielCode: '物料編碼',
      materielModel: '物料規格',
      materielUnit: '物料單位',
      quantity: '數量',
      lossQuantity: '損耗數量',
      lossRate: '損耗率',
      bomStatus: 'BOM狀態',
      craft: '工藝',
      draft: '草稿',
      unaudit: '待審核',
      audit: '已審核',
      title: '產品BOM',
      addTitle: '添加產品BOM',
      editTitle: '編輯產品BOM',
      viewTitle: '查看產品BOM',
      bomVersionNotEmpty: '版本不能為空',
      bomNameNotEmpty: 'BOM名稱不能為空',
      productNameNotEmpty: '產品名稱不能為空',
      deleteTips: '是否刪除該生產BOM ?',
      revokeTips: '是否撤銷該生產產品BOM ?',
      updateVersionTips: '是否該生產BOM更新版本 ?',
      keywordQuery: 'BOM名稱、BOM編碼、產品資訊',
      updateVersion: '更新',
      bomNameCreateAuto: '物料清單名稱自動生成',
      historyVersion: '歷史版本'
    },
    plan: {
      status: '狀態',
      planCode: '計畫編碼',
      finishDate: '完成日期',
      agentName: '經辦人',
      auditorName: '審核人',
      auditTime: '審核時間',
      department: '生產部門',
      common: {
        startDate: '完成時間起',
        endDate: '完成時間止',
        invalid: '作廢',
        all: '全部',
        draft: '草稿',
        pending: '待審核',
        invalided: '已作廢',
        audited: '已審核',
        addPlan: '添加生產計畫',
        editPlan: '編輯生產計畫',
        viewPlan: '查看生產計畫',
        baseInfo: '基本資訊',
        chooseProduct: '選擇產品',
        auditTips: '是否確認審核該生產計畫？',
        deleteTips: '是否刪除該生產計畫？',
        planIsEmpty: '請選擇要審核的生產計畫',
        revokeTips: '是否撤銷該生產計畫？',
        invalidTips: '是否作廢該生產計畫？',
        process: '工藝說明',
        editor: '請輸入工藝說明',
        uploading: '圖片上傳中...'
      },
      rules: {
        pleaseChooseDate: '請選擇完成時間',
        pleaseChooseAgent: '請選擇經辦人',
        pleaseChooseDepartment: '請選擇部門'
      },
      operates: {
        draft: '保存為草稿',
        submit: '提交',
        audit: '保存並審核'
      },
      product: {
        productCode: '產品編碼',
        productName: '產品名稱',
        productModel: '規格型號',
        productUnit: '單位',
        quantity: '數量',
        config: 'BOM'
      },
      bom: {
        editProductBom: '編輯產品物料清單',
        viewProductBom: '查看產品物料清單',
        selectProductMateriel: '選擇物料產品',
        materielCode: '物料編碼',
        materielName: '物料名稱',
        materielModel: '規格型號',
        materielUnit: '單位',
        quantity: '數量',
        lossQuantity: '損耗數量'
      }
    },
    order: {
      orderCode: '訂單編碼',
      salesOrder: '銷售訂單',
      materiel: '物料',
      audited: '已審核',
      begin: '開始',
      finish: '完成',
      inProduction: '生產中',
      finished: '已完成',
      bomName: 'BOM單名稱',
      pending: '待審核',
      pick: '領料',
      return: '退料',
      finishIn: '成品入庫',
      addTitle: '添加生產訂單',
      editTitle: '編輯生產訂單',
      viewTitle: '查看生產訂單',
      revokeTips: '是否確認撤銷該生產訂單',
      deleteTips: '是否確認刪除該生產訂單',
      pleaseChooseProduct: '請選擇產品',
      pleaseChooseBom: '請選擇Bom',
      productionOrderInfo: '生產訂單資訊'
    }
  },
  service: {
    serviceCategoryTitle: '服務分類',
    addCategory: '添加分類',
    categoryMessage: '分類資訊',
    amountWithTax: '金額',
    serviceItem: '服務專案',
    revokeTips: '是否確認撤銷該服務訂單',
    category: {
      categoryCode: '分類編碼',
      categoryName: '分類名稱',
      parentId: '父級',
      typeSort: '排序',
      isEnable: '是否啟用',
      remark: '備註'
    },
    common: {
      systemProduct: '系統自動生成',
      pleaseChooseParent: '選擇父級',
      confirmDeleteText: '是否確認刪除？'
    },
    rules: {
      pleaseInputCategoryName: '請輸入分類名稱'
    },
    item: {
      itemCode: '服務專案編碼',
      itemName: '服務專案名稱',
      categoryName: '類別名稱',
      serviceCategory: '服務分類',
      addItem: '添加服務專案',
      editItem: '編輯服務專案',
      viewItem: '查看服務專案',
      itemContent: '服務專案說明',
      itemMessage: '基本資訊',
      processMessage: '服務流程',
      remark: '備註',
      isDelete: '是否刪除該專案？',
      startTime: '開始時間',
      endTime: '結束時間',
      common: {
        pleaseInputItemName: '請填寫項目名稱',
        pleaseChooseCategory: '請選擇服務分類'
      },
      process: {
        start: '開始',
        finish: '完成',
        nodeName: '節點名稱',
        expectedTime: '預計耗時',
        timeType: '時間類型',
        minutes: '分鐘',
        hours: '小時',
        naturalDays: '自然日',
        workingDays: '工作日',
        weeks: '周',
        months: '月',
        years: '年',
        processContent: '節點說明',
        isDelete: '是否刪除該節點？',
        isMax: '節點不能超過20個',
        pleaseInputNodeName: '請輸入節點名稱',
        pleaseInputTime: '請輸入預計時間'
      }
    }
  },
  schedule: {
    createSchedule: '創建日程',
    scheduleTitle: '日程標題',
    scheduleType: '日程類型',
    startTime: '開始時間',
    endTime: '結束時間',
    advance: '提前',
    participants: '參與人',
    addParticipants: '+ 添加參與人',
    selectParticipants: '選擇參與人',
    showMoreInfo: '更多資訊',
    hideMoreInfo: '隱藏更多資訊',
    addSchedule: '添加日程',
    editSchedule: '編輯日程',
    location: '地點',
    details: '描述',
    today: '今天',
    month: '月',
    week: '周',
    day: '日',
    minutes: '分鐘',
    hours: '小時',
    days: '天數',
    relatedBusiness: '相關資訊',
    selectRelatedBusiness: '+ 關聯業務',
    disassociate: '取消關聯',
    exception: {
      scheduleTitlePlease: '請輸入日程標題',
      scheduleTypePlease: '請選擇日程類型',
      startTimePlease: '請選擇日程開始時間',
      endTimePlease: '請選擇日程結束時間'
    }
  },
  businessType: {
    customer: '客戶',
    product: '產品',
    sales: '銷售',
    salesQuotation: '報價單',
    salesOrder: '銷售訂單',
    salesDelivery: '銷售發貨',
    salesReturn: '銷售退貨',
    salesExpense: '銷售費用',
    purchase: '採購',
    purchaseSupplier: '供應商',
    purchasePlan: '採購計畫',
    purchaseRequisition: '請購單',
    purchaseOrder: '採購訂單',
    purchaseOrderOut: '委外訂單',
    purchaseExpense: '採購費用'
  },
  project: {
    common: {
      addProject: '創建項目',
      editProject: '設置',
      sysProductCode: '系統自動生成',
      addMembers: '添加專案成員',
      archive: '歸檔',
      reactivation: '啟動',
      projectInfo: '專案資訊',
      confirmArchive: '是否確認歸檔？',
      isDeleteProject: '是否刪除該專案？',
      confirmReactivation: '是否確認啟動？',
      queryContent: '專案編碼、專案名稱',
      addTaskMembers: '添加任務成員',
      addTask: '創建任務',
      pleaseChooseProject: '請選擇項目',
      notBegin: '未開始',
      finish: '已完成',
      overdue: '已逾期',
      taskInfo: '任務資訊',
      isDeleteTask: '是否刪除該任務？',
      confirmFinish: '改任務是否確認完成？',
      dateRangePlease: '請選擇任務時間',
      ongoing: '進行中',
      hangUp: '已掛起',
      addDirectory: '新建文件夾',
      directoryName: '文件夾名稱',
      fileDirectory: '文件夾',
      firstDirectory: '附件文件夾',
      cuurentDirectory: '當前文件夾',
      projectTaskList: '任務',
      startTaskTips: '確認開始任務',
      hangUpTaskTips: '確認掛起任務',
      start: '開始',
      hangUpTask: '掛起',
      customer: '客戶',
      startTime: '計劃開始時間',
      endTime: '計劃結束時間',
      realStartTime: '實際開始時間',
      realEndTime: '實際結束時間',
      updateStartTime: '修改計劃開始時間',
      updateEndTime: '修改計劃結束時間',
      updateRealStartTime: '修改實際開始時間',
      updateRealEndTime: '修改實際結束時間',
      workingHours: '工時',
      addChildTask: '添加子任務',
      taskProduct: '關聯產品',
      projectProductGantt: '產品進度',
      projectTaskGantt: '項目進度',
      rules: {
        pleaseChooseStatus: '請選擇專案狀態',
        pleaseInputName: '請輸入項目名稱',
        pleaseChooseStartTime: '請選擇開始時間',
        pleaseChooseEndTime: '請選擇結束時間',
        pleaseChooseGroup: '請選擇項目分組',
        pleaseChooseScope: '請選擇項目可見範圍',
        pleaseChooseMember: '請選擇項目成員',
        pleaseChooesManager: '請選擇項目負責人'
      },
      status: {
        normal: '正常',
        risks: '有風險',
        outOfControl: '失控'
      },
      scopes: {
        private: '私有: 只有加入項目的成員才可看見此項目',
        public: '公開: 企業所有成員都可以看見此項目'
      }
    },
    info: {
      projectCode: '項目編號',
      projectName: '項目名稱',
      projectGroup: '專案分組',
      projectScope: '可見範圍',
      projectManager: '專案負責人',
      startTime: '開始時間',
      endTime: '結束時間',
      projectSchedule: '專案進度',
      projectStatus: '專案狀態',
      projectArchive: '歸檔',
      projectDescription: '項目描述',
      member: '專案成員',
      remark: '備註',
      projectDateRange: '項目時間',
      projectDateRangePlease: '請選擇項目時間',
      addTask: '添加任務',
      addChildTask: '添加子任務'
    },
    task: {
      taskCode: '任務編碼',
      taskName: '任務名稱',
      projectName: '所屬項目',
      taskManager: '負責人',
      startTime: '開始時間',
      endTime: '結束時間',
      priority: '優先順序',
      taskStatus: '狀態',
      description: '描述',
      member: '任務成員',
      business: '關聯業務',
      remark: '備註',
      selectRelatedBusiness: '相關資訊',
      disassociate: '取消關聯',
      taskTime: '任務時間',
      myTaskOnly: '只看我的',
      addBusiness: '添加關聯業務',
      parentTask: '上級任務名稱',
      common: {
        finish: '完成',
        hight: '高',
        middle: '中',
        low: '低',
        not: '無'
      },
      rules: {
        pleaseInputName: '請輸入任務名稱',
        pleaseChooseManage: '請選擇任務負責人',
        pleaseChooseStartTime: '請選擇開始時間',
        pleaseChooseEndTime: '請選擇結束時間'
      }
    },
    config: {
      projectPublicMemberConfig: '全部項目可見用戶'
    }
  },
  sysTag: {
    common: {
      title: '標籤管理',
      addTag: '創建新標籤',
      isDeleteTag: '是否確認刪除改標籤？',
      pleaseInputValue: '輸入標籤值'
    },
    tagInfo: {
      tableName: '標籤',
      businessType: '所屬業務',
      isGlobal: '是否全域標籤',
      tagValue: '標籤值',
      tagColor: '標籤顏色'
    },
    businessType: {
      customer: '客戶',
      supplier: '供應商',
      serviceOrder: '服務訂單',
      saleOrder: '銷售訂單',
      purchase: '採購訂單'
    }
  },
  commonBusinessType: {
    purchaseReceiptInventory: '庫存引用採購收貨',
    salesReturnInventory: '庫存引用銷售退貨',
    outOrderInventory: '庫存引用委外訂單',
    purchaseReturnInventory: '庫存引用採購退貨',
    purchaseReceiptAddInventory: '入庫通知的採購收貨引用添加入庫單',
    selfViewInventory: '庫存模組本身引用查看'
  },
  expressRecord: {
    business: '關聯業務',
    mailingDate: '寄件日期',
    expressCompany: '快遞公司',
    expressNumber: '快遞單號',
    currency: '貨幣',
    freight: '運費',
    recipientName: '收件人姓名',
    recipientPhone: '收件人電話',
    recipientCompany: '收件人公司',
    recipientAddress: '收件人詳細地址',
    deleteExpressRecordTips: '確認【刪除】該快遞資訊',
    addExpressRecord: '添加快遞信息',
    editExpressRecord: '編輯快遞資訊',
    viewExpressRecord: '查看快遞信息',
    pleaseChooseMailingDate: '請選擇寄件日期',
    pleaseChooseExpressCompany: '請選擇快遞公司',
    pleaseChooseExpressNumber: '請輸入快遞單號',
    pleaseChooseCurrency: '請選擇貨幣'
  },
  express: {
    config: {
      goOauth: '去往授權頁',
      jdSetting: '京東參數設置',
      customerName: '商家名稱',
      customerCode: '商家編碼'
    },
    order: {
      senderName: '姓名',
      senderMobile: '手機號碼',
      senderTel: '固定電話',
      senderAddress: '所在地區',
      receiveName: '姓名',
      receiveMobile: '手機號碼',
      receiveTel: '固定電話',
      receiveAddress: '所在地區'
    }
  },
  todoList: {
    businessType: '待辦業務類型',
    businessCode: '業務編碼',
    applicant: '申請人',
    applicantDate: '申請日期',
    currentNode: '當前節點',
    businessStatus: '業務狀態',
    processStatus: '處理狀態',
    notHandled: '未處理',
    handled: '已處理',
    salesOrderAudit: '銷售訂單審核',
    startDate: '申請日期起',
    endDate: '申請日期止',
    queryKeyword: '業務編碼，申請人'
  },
  mall: {
    photo: {
      addPhoto: '添加簡介相冊',
      addPhotoTip: '請上傳圖片',
      img: '圖片',
      addFile: '添加資質文件'
    },
    marketing: {
      coupons: '優惠券',
      couponsType: '優惠券類型',
      couponsName: '優惠券名稱',
      couponsImg: '優惠券圖片',
      distributionTotal: '發放總量',
      distributionTime: '發放時間',
      distributionStartEnd: '發放起止時間',
      privately: '私有',
      distributionObject: '發放對象',
      pleaseChooseDistributionObject: '请选择發放對象',
      distributionObjectTip: '确定将此优惠券发放给选择的對象吗',
      receiveObject: '领取對象',
      distributionSuccess: '发放成功',
      distribution: '发放',
      chooseMember: '选择会员',
      chooseCustomer: '选择客户',
      memberName: '會員名稱',
      customerName: '客戶名稱',
      distributionCoupons: '发放優惠券',
      haveReceive: '已領取',
      remainingNumber: '剩餘數量 ',
      usage: '使用率',
      getWay: '領取方式 ',
      fullVolumeReduction: '滿減券',
      discountCoupons: '折扣券',
      nolimit: '不限制',
      limit: '限制',
      spend: '消費滿 ',
      yuan: '元',
      reduction: '減',
      enjoy: '享',
      fold: '折',
      limitNumber: '限制張數',
      zhang: '張',
      afterGet: '獲得後 ',
      withinDate: '日期內',
      limitDay: '限制天數',
      validFor: '內有效 ',
      day: '天',
      no: '無',
      chooseTime: '選擇時間',
      takeTimeLimit: '使用時間限制',
      sort: '排序值',
      perPersonQuantity: '每人領取數量',
      claimantLimit: '領取人限制',
      theCustom: '自訂 ',
      eachLimitGet: '每人限領',
      allCustomer: '全部客戶',
      designatedCustomerCategory: '指定客戶分類',
      customer: '客戶',
      allMember: '全部會員',
      designatedMemberLevel: '指定會員等級',
      member: '會員',
      chooseCustomerCategory: '選擇客戶分類',
      chooseMemberLevel: '選擇會員等級',
      chooseGoodsCategory: '選擇商品分類',
      goodsUseLimit: '商品使用限制',
      allGoods: '全部商品',
      allowFollowingGoodsUse: '允許以下商品使用',
      noAllowFollowingGoodsUse: '不允許以下商品使用',
      allowFollowGoodsCategoryUse: '允許以下商品分類使用',
      useDirections: '使用說明',
      addCoupons: '添加優惠券',
      editCoupons: '編輯優惠券',
      viewCoupons: '查看優惠券',
      couponsImgTip: '圖片比例1：1',
      pleaseCouponsName: '請填寫優惠券名稱',
      pleaseCouponsImg: '請填寫上傳優惠券圖片',
      release: '發佈',
      releaseTip: '確定發佈嗎 ',
      releaseSuccess: '發佈成功',
      releaseStatus: '發佈狀態',
      noRelease: '未發佈',
      yesRelease: '已發佈',
      soldOut: '下架',
      soldOutTip: '確定下架吗 ',
      soldOutSuccess: '下架成功 ',
      soldOutEd: '已下架',
      pleaseWriteCouponsType: '優惠券類型，請填寫消費滿減金額',
      pleaseWriteDistributionQuantity: '發放總量，請填寫限制張數',
      pleaseWriteUsageTime: '使用時間限制，請選擇時間',
      pleaseWriteUsageLimitDays: '使用時間限制，請填寫限制天數',
      pleaseWritePerPersonQuantity: '每人領取數量，請填寫每人限領張數',
      pleaseDesignatedCustomerCategory: '領取人限制，請選擇指定客戶分類',
      pleaseDesignatedMemberLevel: '領取人限制，請選擇指定會員等級',
      pleaseAllowGoodsUse: '商品使用限制，請添加"允許以下商品使用"的商品',
      pleaseNoAllowGoodsUse: '領取人限制，請添加"不允許以下商品使用"的商品',
      pleaseAllowGoodsSortUse: '領取人限制，請添加"允許以下商品分類使用"的商品分類',
      pleaseDistributionTime: '發放時間，請選擇起止時間',
      limitDetail: '領取明細',
      useDetail: '使用明細',
      receiveName: '領取人名稱',
      receiveTime: '領取時間',
      useName: '使用人名稱',
      useTime: '使用時間',
      receiveMember: '領取會員',
      receiveCustomer: '領取客戶',
      voucher: '代金券',
      voucherType: '使用方式',
      voucherTitle: '代金券名稱',
      voucherAmount: '面值',
      voucherImage: '封面',
      voucherDesc: '使用說明',
      oneTime: '單次使用',
      moreTime: '不限次數',
      limitTime: '限製次數',
      voucherImgTip: '圖片比例1：1',
      noLimit: '不限制',
      getDayAfter: '獲得後',
      dateRange: '日期內',
      addVoucher: '添加代金券',
      editVoucher: '編輯代金券',
      viewVoucher: '查看代金券',
      voucherDistributionCount: '發放數量',
      voucherOrderCount: '使用數量',
      cardQuantity: '已生成卡數量',
      receivedCardQuantity: '已綁定卡數量',
      phoneImportTitle: '代金券發放',
      phoneImportExchangeVoucher: '兌換券發放',
      pleaseSelectMemberVoucher: '請選擇代金券',
      geneQrCodeSuccessTips: '代金券二維碼正在後臺生成，請稍後重新查詢生成情況',
      geneQrCode: '生成二維碼',
      phoneNumber: '手機號',
      memberVoucherDeductAmount: '已使用金額',
      distributeDetails: '發放明細',
      distributeDetailsQuery: '會員名稱 手機號',
      isUsedStatus: '是否使用',
      notUsed: '未使用',
      hasUsed: '已使用',
      isExpiredStatus: '是否過期',
      notExpired: '未過期',
      hasExpired: '已過期',
      isNotCompletedVoucher: '只顯示余額大於0的代金券',
      startDate: '開始時間',
      endDate: '結束時間',
      voucherTitlePlease: '請輸入代金券名稱',
      voucherAmountPlease: '請輸入代金券面值',
      voucherImagePlease: '請上傳代金券封面',
      distributeCard: '卡券',
      memberImport: '會員發放',
      memberImportTitle: '會員發放代金券',
      selectMember: '選擇會員',
      pleaseChooseMember: '請選擇會員',
      voucherDistribute: '發放',
      phoneImport: '手機號發放',
      viewCard: '卡券發放',
      viewVoucherCard: '查看代金券卡',
      distributeCardQuery: '卡號 領取人名稱 手機號',
      cardNumber: '卡號',
      cardSecret: '卡密',
      notReceived: '未領取',
      hasReceived: '已領取',
      isReceivedStatus: '是否領取',
      geneVoucherCard: '生成卡券',
      geneVoucherCardQuantity: '生成數量',
      voucherCardStatus: '領取狀態',
      voucherQrCode: '領取碼',
      voucherDetail: '基本信息',
      voucherCardDetail: '卡券明細',
      voucherMemberDetail: '領取明細',
      voucherDeductionDetail: '訂單詳情',
      queryMallOrderStatus: '訂單狀態',
      queryMallOrderKeywords: '訂單編號 下單人 手機號',
      deductFreightConfig: '是否可抵扣運費',
      userLimitConfig: '使用身份限製',
      userNoLimit: '不限製',
      userLimit: '指定身份使用',
      addCustomerSort: '添加客戶分類',
      addMemberLevel: '添加會員等級',
      userLimitType: '身份類型',
      customerSortOrMemberLevel: '客戶分類/會員等級',
      userLimitConfigError: '請正確配置使用身份限製，選擇客戶分類/會員等級',
      exchangeVoucher: '兌換券',
      exchangeVoucherTitle: '兌換券名稱',
      isExchanged: '是否已兌換',
      batchSetUsageTime: '使用期限設置',
      pleaseSelectData: '請勾選列表數據',
      addExchangeVoucher: '添加兌換券',
      editExchangeVoucher: '編輯兌換券',
      viewExchangeVoucher: '查看兌換券',
      voucherShareRecord: '代金券贈送記錄',
      exchangeVoucherShareRecord: '兌換券贈送記錄',
      pointGoods: '積分兌換商品',
      remainingConvertibleQuantity: '剩余可兌換數量',
      customerSort: '客戶分類',
      memberLevel: '會員等級',
      notLimit: '不限製',
      includeCustomerSort: '客戶分類可兌換',
      excludeCustomerSort: '客戶分類不可兌換',
      followGoods: '同商品設置',
      none: '包郵',
      includeMemberLevel: '會員等級可兌換',
      excludeMemberLevel: '會員等級不可兌換',
      onShelf: '上架',
      onShelfEd: '已上架',
      onShelfTips: '確定上架嗎？',
      onShelfSuccess: '上架成功',
      addMallPointGoods: '添加積分商品',
      editMallPointGoods: '編輯積分商品',
      viewMallPointGoods: '查看積分商品',
      freightConfig: '運費設置',
      buyLimitConfig: '限購設置',
      customerLimitConfig: '客戶兌換設置',
      memberLimitConfig: '會員兌換設置',
      appointCustomerCategoryCan: '指定客戶分類可兌換',
      appointCustomerCategoryCannot: '指定客戶分類不可兌換',
      appointMemberLevelCan: '指定會員等級可兌換',
      appointMemberLevelCannot: '指定會員等級不可兌換',
      pleaseSelectCustomerCategory: '客戶兌換設置，請選擇客戶分類',
      pleaseSelectMemberLevel: '會員兌換設置，請選擇會員等級',
      residueStock: '現庫存',
      price: '價格',
      pointPrice: '積分價',
      point: '積分',
      batchDelete: '批量刪除',
      batchOnShelf: '批量上架',
      batchSoldOut: '批量下架'
    },
    perfer: {
      limitPerfer: '限時特惠',
      perferName: '特惠名稱',
      notTo: '未進行',
      ongoing: '進行中',
      end: '已結束',
      startTime: '開始時間',
      endTime: '結束時間',
      ends: '結束',
      isOver: '是否結束？ ',
      isOverSuccess: '結束成功',
      goods: '商品',
      goodsSort: '商品分類',
      addGoods: '添加商品',
      addGoodsSort: '添加商品分類',
      pleasePreferName: '請填寫特惠名稱',
      pleaseStartTime: '請填寫開始時間',
      pleaseEndTime: '請填寫結束時間',
      preferAmount: '優惠後金額',
      discount: '折扣',
      choosePreferGoods: '選擇特惠商品',
      goodsList: '商品列表',
      customerIsParticipate: '客戶參與設置',
      participate: '參與',
      participateTip: '提示：參與後客戶在活動期間商品價格按照活動價展示，不參與商品價格按自身價格展示',
      addGoodsTip: '請添加特惠商品',
      dataCompare: '開始時間不能大於結束時間',
      downPrice: '降價',
      application: '應用',
      goodsDownPrice: '有商品降價調整後價格為0，請注意是否需要調整',
      addPrefer: '添加限時特惠',
      viewPrefer: '查看限時特惠',
      editPrefer: '編輯限時特惠',
      release: '發布',
      saveAndRelease: '保存並發布',
      draft: '草稿',
      preferTime: '特惠起止時間'
    },
    giftBox: {
      addGroup: '新增分組',
      viewPlanGift: '查看禮盒方案',
      planName: '方案名稱',
      planImage: '方案主圖',
      planSize: '方案尺寸',
      visible: '可見範圍',
      period: '有效期',
      addPlan: '添加禮盒方案',
      specifiedCustomerSort: '指定客戶分類',
      specifiedCustomer: '指定客戶',
      specifiedArea: '指定區域',
      specifiedLabel: '指定標籤',
      memberLevel: '會員等級',
      specifiedMember: '指定會員',
      childNumberLimit: '子件數量限制',
      childLimitNumber: '子件限制數量',
      childNumberTip: '提示：啟用限制後輸入限制值，在禮盒子件選擇時，超過該限制值不能再選擇新的子件',
      orderNumberLimit: '下單數量限制',
      userPrice: '用戶身份對應價格',
      priceLevel: '價格等級',
      theCustom: '自定義',
      allowedRepeatOrder: '允許重複下單配置',
      allow: '允許',
      noAllow: '不允許',
      priceType: '子件價格獲取類型',
      sort: '排序',
      planText: '方案說明',
      memberDiscount: '會員折扣配置',
      customerDiscount: '客戶折扣配置',
      fullMinus: '滿額減',
      fullFold: '滿額折',
      FullAmountMinus: '滿量減',
      rangeAmount: '區間範圍(含最小區間)',
      rangeDiscountAmount: '達到區間範圍的折扣金額（或折扣）',
      rangeDiscount: '達到區間範圍的折扣',
      memberFreightType: '會員運費類型',
      customerFreightType: '客戶運費類型',
      packageQuota: '滿額包郵',
      packageNumber: '滿量包郵',
      noPackage: '包郵',
      freightTemplate: '運費模板',
      limitValue: '額度值',
      numberValue: '數量值',
      giftBoxConfig: '禮盒包裝',
      productName: '禮盒關聯商品',
      giftBoxName: '禮盒名稱',
      giftBoxImage: '禮盒圖片',
      giftBoxAmount: '禮盒金額',
      goodsName: '商品名稱',
      goodsSkuImg: '商品sku圖片',
      unitPrice: '銷售價',
      specsValue: '規格',
      virtual: '虛擬庫存',
      willChooseChild: '必選子件',
      quantity: '數量',
      ableChooseChild: '可選子件',
      groupType: '分組類型',
      categoryGroup: '種類分組',
      numberGroup: '數量分組',
      groupName: '分組名稱',
      draft: '草稿',
      enable: '已發布',
      planStatus: '方案狀態',
      creatTime: '創建時間',
      chooseGiftMaterial: '選擇禮盒包裝',
      enableSuccess: '啟用成功',
      editPlan: '編輯禮盒方案',
      freightTemplateTip: '提示:運費模板必選，未達到包郵的數值，將按照運費模板計算運費',
      visibleTip: '可見範圍:（客戶，會員）請選擇對應的限制類型',
      periodConfigTip: '有效期:請選擇有效期起止時間',
      periodStartEnd: '有效期起止時間',
      orderNumberTip: '提示:填寫左區間，訂單數量起訂多少個（右區間清空）；填寫右區間，限購多少個（左區間清空）；填寫左右區間，下單數量在區間數量之內，左區間為最小數量，右區間為最大數量，不可寫反',
      salesPriceLevel: '銷售價格標簽',
      priceTypeTip: '價格類型:請選擇價格等級',
      giftBoxTip: '禮盒配置:請選擇禮盒包裝',
      requireChildTip: '必選子件,可選子件中選擇其中一個添加商品',
      customerFreightTemplatTip: '客戶運費類型: 請選擇客戶運費模板',
      memberFreightTemplatTip: '會員運費類型: 請選擇會員運費模板',
      noDiscount: '無折扣',
      setFreightTemplat: '設置折扣模板',
      addDiscountTip: '請先填寫完整區間範圍(區間範圍不能都為0或空)',
      addDiscountOneTip: '參數右區間請先填寫大於0的數值形成閉環，再添加參數',
      addDiscountTwoTip: '區間範圍左區間不能為空',
      setOut: '下架',
      setOutTip: '確定下架嗎',
      release: '發布',
      setOutSuccess: '下架成功',
      planImageTip: '方案主圖不能為空',
      planSizeTip: '方案尺寸不能為空',
      requireGoodsPriceTip: '必選子件: 未填寫銷售價格',
      willGoodsPriceTip: '可選子件: 未填寫銷售價格',
      groupCategoryTip: '可選子件: 選擇品類分組時，填寫數量不能大於添加商品的數量',
      groupQuantityTip: '可選子件：數量的左區間不能大於添加商品的數量',
      childNumberLimitTips: '子件總數量限制不能小於選擇的子件數量(可選子件分組填寫最小數量之和+必選子件選擇的商品數量)',
      groupTypeTip: '分組類型:選擇種類，是在添加的商品中選擇幾種商品，選中數量為1；選擇數量，是在添加的商品中選擇幾個商品，數量可變化',
      groupNameTip: '可選子件:請填寫分組名稱',
      groupGoodsTip: '可選子件:請添加商品',
      planNamePlease: '請輸入方案名',
      planImagePlease: '請上傳方案主圖',
      planSizePlease: '請填寫方案尺寸',
      goodsEmpty: '必選子件和可選子件不能同時為空',
      memberDiscountEmptyTip: '會員折扣設置錯誤',
      customerDiscountEmptyTip: '客戶折扣設置錯誤',
      orderNumberTipNull: '下單數量限制:下單數量不能為空,並且左右區間不能同時為0',
      scopeOrderNumber: '下單數量限制:填寫範圍時，左區間不能大於右區間',
      childNumberLimitTip: '子件總數量限制：請填寫子件限制數量（不能爲空或0）',
      orderNumberLeftTip: '下單數量限制：下單數量限制填寫左區間',
      orderNumberRightTip: '下單數量限制：下單數量限制填寫右區間',
      groupNumberTip: '提示: 填寫左區間（右區間為0），固定可選子件商品的數量；填寫左右區間，可選子件商品數量在填寫範圍之間，左區間為最小數量，右區間為最大數量',
      categoryGroupNumberTip: '提示：左區間為最小選擇商品種類數（0-可不選品類商品）； 右區間為最大選擇商品種類數（0-不限制選擇品類商品數量）',
      quantityGroupNumberTip: '提示：左區間為最小選擇商品數（0-可不選商品）； 右區間為最大選擇商品數（0-不限制選擇商品數量）',
      numberNotNull: '可選子件:選擇數量分組時，數量不能爲空或左右區間都爲0',
      numberCategoryNotNull: '可選子件:選擇品類分組時，數量不能爲空或0',
      numberGroupRightTip: '可選子件：選擇數量分組時，右區間不能爲空',
      numberNotZero: '可選子件:數量不能為0',
      groupNumberCompareTip: '可選子件：數量的左區間不能大於右區間',
      groupMaxQuantityCompareTip: '可選子件：選擇品類分組時，數量的右區間不能大於商品總數',
      boxAmountTip: '禮盒包裝:禮盒金額不能為空',
      boxNameTip: '禮盒包裝: 禮盒名稱不能為空',
      boxImageTip: '禮盒包裝:禮盒圖片不能為空',
      requireQuantityTip: '必選子件: 數量不能為空',
      memberDiscountTipOne: '會員折扣配置：區間範圍左區間不能為空或0',
      memberDiscountTipTwo: '會員折扣配置：區間範圍右區間不能為空',
      memberDiscountTipThree: '會員折扣配置：折扣金額（折扣）不為空',
      memberDiscountTipFour: '會員折扣配置：區間範圍左區間不能大於右區間',
      memberDiscountTipFive: '會員折扣配置：區間範圍左區間不能為空和0',
      memberDiscountTipSix: '會員折扣配置：區間範圍不能為空或0',
      memberDiscountTipSeven: '會員折扣配置：區間範圍請閉環添加,不要交叉',
      customerDiscountTipOne: '客戶折扣配置：區間範圍左區間不能為空或0',
      customerDiscountTipTwo: '客戶折扣配置：區間範圍右區間不能為空',
      customerDiscountTipThree: '客戶折扣配置：折扣金額（折扣）不為空',
      customerDiscountTipFour: '客戶折扣配置：區間範圍左區間不能大於右區間',
      customerDiscountTipFive: '客戶折扣配置：區間範圍左區間不能為空和0',
      customerDiscountTipSix: '客戶折扣配置：區間範圍不能為空或0',
      customerDiscountTipSeven: '客戶折扣配置：區間範圍請閉環添加,不要交叉',
      discountTips: '提示：添加多個參數區間範圍請閉環填寫(示例：1-20,20-40)，閉環區間內左區間不能大於右區間。最後一個參數右區間為0（示例：20-0），代表大於等於20',
      deleteCoupe: '刪除分組',
      moneyPackage: '元包郵',
      numberPackage: '件包郵',
      full: '滿',
      fullFolds: '滿足',
      yuan: '元',
      ge: '個',
      jian: '減',
      da: '打',
      zhe: '折',
      releaseTime: '发布时间',
      noQuantityPrefix: '不限製數量',
      minQuantityPrefix: '最少購買',
      minQuantitySubfix: '件商品',
      maxQuantityPrefix: '最多購買',
      maxQuantitySubfix: '件商品',
      canSelectPrefix: '可選',
      canSelectSubfix: '件商品',
      deliveryDesc: '交期說明',
      unLimitStock: '允許超庫存下單'
    },
    live: {
      liveNoConfig: '直播號設置',
      liveNo: '直播房間號',
      pleaseWriteLiveNo: '請填寫直播房間號'
    },
    pick: {
      addTitle: '添加自提點',
      editTitle: '編輯自提點',
      viewTitle: '查看自提點',
      contactName: '連絡人',
      mobile: '聯繫電話',
      areaIds: '請輸入區域位址',
      addressTips: '請輸入詳細地址',
      contactNameTips: '請輸入連絡人',
      mobileTips: '請輸入聯繫電話',
      deleteTips: '是否確認刪除該自提點?',
      pickAdress: '自提點詳細地址',
      warehousePlease: '請選擇發貨倉庫'
    },
    exception: {
      categoryImagePlease: '分類圖片不能為空',
      categoryNamePlease: '分類名不能為空',
      keyValuePlease: '鍵值不能為空',
      sortPlease: '排序不能為空'
    },
    indexDecorate: {
      tips: '提示文字',
      textAlign: '文字對齊',
      backgroundColor: '背景顏色',
      iconColor: '圖示顏色',
      textColor: '文字顏色',
      radius: '圓角'
    },
    config: {
      mallName: '商城名稱',
      mallLogo: '商城LOGO',
      mallLogoTip: '圖片為正方形，建議尺寸800*800，用於商城首頁分享的系統預設宣傳圖',
      sellOutIcon: '售罄圖示',
      sellOutIconTip: '建議尺寸比例為1:1，用於商品售罄後的提示圖示',
      mallBrief: '商城簡介',
      loadIcon: '載入圖示',
      loadIconTip: '圖片為正方形，建議尺寸200*200，用於網路較慢圖片未載入出來時的占點陣圖',
      mallPicturePreview: '商城圖片預覽',
      isShowVirtualInventory: '是否顯示虛擬庫存',
      isAutoDelivery: '到店自提',
      phone: '客服電話',
      phoneTip: '請填寫客服電話',
      companyTip: '所属公司有变更，将关闭所有支付方式，需重新配置支付方式',
      onlineMallPrice: '在線商城價格類型',
      isBindPhone: '是否強制綁定手機號',
      isBindPhoneTip: '開啟後將在提交訂單時強制要求綁定手機號',
      pptCoverPicture: 'PPT封面圖片',
      pptCoverPictureTip: '建議尺寸比例為16:9，且不小於1024 * 768，用於生成多商品海報PPT封面',
      mallStockIncludePurchase: '可用庫存是否包含在途',
      mallStockIncludeThird: '可用庫存是否包含三方庫存',
      include: '包含',
      exclude: '不包含',
      dealAnnouncement: '成交播報',
      open: '開啟',
      close: '關閉',
      salesDisplay: '是否顯示銷量',
      show: '顯示',
      noShow: '不顯示',
      salesSetting: '銷量設置',
      actualSales: '實際銷量',
      virtualSales: '虛擬銷量',
      viewStock: '顯示庫存',
      viewSales: '顯示銷量',
      mallOrderGeneCustomCode: '商城訂單生成自定義編號',
      mallOrderGeneCustomCodeTips: '自定義編號僅用於導入第三方系統',
      customerFreightTips: '客戶運費提示',
      customerFreightAfterOrderPay: '客戶運費後結',
      customerFreightAfterOrderPayTips: '當開啟客戶運費後結時，客戶下單時，可以選擇運費在下單之後再結算',
      mallSmsLoginEnable: '短信驗證碼登錄',
      mallSmsLoginEnableTips: '需要聯系平臺進行短信充值',
      mallWxPhoneLoginEnable: '微信手機號快捷登錄',
      mallWxPhoneLoginEnableTips: '需要在微信公眾平臺中【付費管理】中購買【手機號快速驗證組件】',
      voucherGiftTime: '代金券贈送領取時效',
      orderRewardType: '下單獎勵',
      noneReward: '無',
      voucherReward: '代金券',
      orderRewardVoucher: '獎勵代金券',
      standardAmountReward: '固定金額',
      orderAmountRateReward: '按訂單金額比例',
      orderRewardAmountType: '獎勵金額計算方式',
      orderRewardAmountExclude: '訂單金額',
      rewardAmountRange: '訂單金額區間(左不包含，右包含，右為0代表無窮)',
      rewardAmount: '獎勵金額',
      addMallOrderReward: '添加自定設置',
      excludeFreight: '排除運費',
      excludeVoucher: '排除代金券支付部分',
      excludePoint: '排除積分支付部分',
      orderRewardAmountRate: '獎勵訂單比例',
      orderRewardAmountPrecision: '獎勵計算精度',
      editRewardConfig: '編輯下單獎勵獎勵',
      orderRewardVoucherPlease: '請選擇獎勵代金券',
      customerSortPlease: '請選擇客戶分類',
      memberLevelPlease: '請選擇會員等級',
      orderRewardAmountRangePlease: '請填寫訂單範圍獎勵金額',
      enableKf: '是否開啟在線客服',
      appletKf: '小程序客服',
      weworkKf: '企業微信客服',
      weworkCorpIdPlease: '請輸入企業ID',
      weworkKfIdPlease: '請輸入客服ID',
      goodsParamConfig: '商品參數設置',
      goodsStockConfig: '小程序庫存展示設置',
      viewEnableStockTotal: '在庫可用庫存（總數）',
      viewEnableStockWarehouseTotal: '在庫可用庫存（按倉庫）',
      viewPurchaseStockTotal: '在途可用庫存（總數）',
      viewPurchaseStockTotalAboutTime: '預計到貨時間（取最早的采購訂單到貨時間）',
      viewPurchaseStockWarehouseTotal: '在途可用庫存（按倉庫',
      viewPurchaseStockWarehouseAboutTime: '預計到貨時間（取最早的采購訂單到貨時間）',
      viewStockConfig: '庫存顯示設置'
    },
    dealConfig: {
      autoCompleteTime: '訂單自動完成時間',
      autoCompleteTimePrefix: '訂單全部發貨後',
      autoCompleteTimeSuffix: '天內未確認將自動完成',
      autoCompleteTimeTips: '訂單全部發貨後0-30天內自動完成',
      unpaidOrder: '未付款訂單',
      automaticReceiving: '自動收貨',
      notAutomaticReceiving: '不自動收貨',
      customizeDeliveryTime: '自訂收貨時間',
      tradingEnhance: '交易增強',
      rradingEnhanceTip: '開啟後進入商城首頁時會自動彈出未支付訂單提示。',
      invoiceSetting: '發票設置',
      paperInvoice: '紙質發票',
      electronicInvoice: '電子發票',
      orderEvaluate: '訂單評價',
      accordingEvaluation: '顯示評價',
      evaluationAudit: '評價審核',
      takeUnpaidOrder: '拍下未付款訂單 ',
      minute: '分鐘',
      takeUnpaidOrderText: '內未付款，自動關閉訂單',
      takeUnpaidOrderTip: ' 訂單取消時間必須在10-1440分鐘之間',
      theDeliver: '發貨',
      day: '天',
      theDeliverText: '後自動收貨',
      theDeliverTip: '自動確認收貨時間必須在7-30天之間。',
      undeliveredRefundReason: '未收到貨退款原因',
      deliveredRefundReason: '收到貨退款原因',
      exChangeReason: '換貨原因',
      refundReason: '退款原因',
      reasonTip: '每一行表示一個退換貨原因',
      noInPackage: '不參與滿額包郵的地區',
      customerPackage: '自定義滿額包郵設置',
      yuan: '元',
      package: '包郵',
      discountAmout: '商品優惠後金額滿',
      packageConfig: '訂單滿額包郵設置',
      pleaseWritePackageAmount: '請填寫滿額包郵金額',
      commissionExtractToWxConfig: '傭金提現到微信零錢',
      commissionExtractToWxConfigTip: '啟用傭金提現到微信零錢條件：商城連續交易記錄滿30天',
      allowViewAllOrder: '允許查看所有訂單',
      allowViewAllOrderTip: '設定後，該用戶可以在【線上商城-訂單管理】中查看全部的訂單，含客戶訂單和會員訂單，如果用戶的數據許可權為【所有數據】，則無需單獨設定該項',
      allowViewMemberOrder: '允許查看會員訂單',
      allowViewMemberOrderTip: '設定後，該用戶可以在【線上商城-訂單管理】中查看會員提交的訂單，如果用戶的數據許可權為【所有數據】，則無需單獨設定該項'
    },
    pointConfig: {
      pointName: '積分單位名稱',
      pointNamePrompt: '請輸入單位名稱',
      pointConversionRatio: '積分兌換比例',
      point: '積分',
      yuan: '元',
      pointOrderRatio: '訂單積分支付比例',
      pointOrderRatioPrompt: '請輸入支付比例',
      pointOrderRatioTips: '可輸入0~100的數值， 默認：0 即，不可以使用積分',
      orderPointRewardType: '下單積分獎勵',
      none: '無',
      theOrderAmountRatio: '按訂單金額比例',
      thePaymentAmountRatio: '按實際支付金額比例',
      orderAmountRatio: '訂單金額比例',
      paymentAmountRatio: '實際支付金額比例',
      orderPointRewardMax: '積分獎勵上限',
      orderPointRewardMaxPrompt: '請輸入積分獎勵上限',
      orderPointRewardMaxTips: '默認0，表示無上限',
      orderPointRewardPrecision: '積分獎勵精度',
      one: '個位',
      ten: '十位',
      hundreds: '百位',
      thousands: '千位',
      orderPointRewardPrecisionTips: '如果按個位，則表示256* 10% 按個位取整，=25，即表示可以獲得25積分',
      orderPointRewardPrecisionTipss: '如果按十位，則表示256* 10% 按十位取整，=20，即表示可以獲得20積分 .依次類推',
      orderPointRewardTypeMember: '下單積分獎勵（會員）',
      orderPointRewardTypeCustomer: '下單積分獎勵（客戶）',
      customerPointDeductEnable: '客戶訂單支付時使用積分',
      memberPointDeductEnable: '會員訂單支付時使用積分'
    },
    afterSalesConfig: {
      afterSalesApply: '售後維權申請',
      notAfterSales: '完成訂單後無售後',
      customAfterSales: '自訂時間',
      refundInstructions: '退款說明',
      refundInstructionsTip: '使用者在申請退款頁面的說明',
      singleReturnExchange: '單品退換貨',
      singleReturnExchangeTip: '開啟後，訂單支援單件商品退換，訂單優惠金額按比例分攤至每件商品',
      timeoutCancelActivist: '超時取消維權',
      orderFinish: '訂單完成',
      orderFinishText: '內可發起售後維權',
      orderFinishTip: '售後維權時間必須在訂單完成0-30天之間。',
      agreeReturnExchange: '商家同意退貨退款/換貨申請後',
      agreeReturnExchangeText: '內買家未提交快遞單號的，自動取消售後維權',
      agreeReturnExchangeTip: '取消售後維權時間必須在1-30天之間。',
      closeTime: '售後訂單關閉',
      notAutoCloseTime: '退貨/退款後不關閉',
      autoCloseTime: '自訂時間',
      autoCloseTimePrefix: '退貨/退款',
      autoCloseTimeText: '後自動關閉',
      closeTimeTips: '售後訂單退貨/退款完成後1-30天內自動關閉',
      orderCompany: '訂單固定所屬公司'
    },
    news: {
      coverPicture: '封面圖片',
      sort: '資訊排序',
      sortTip: '請輸入資訊排序',
      title: '資訊標題',
      titleTip: '請輸入資訊標題',
      state: '顯示狀態',
      digest: '資訊摘要',
      digestTip: '提示：未填寫摘要，預設取資訊內容前200個字元',
      content: '資訊內容',
      createTime: '創建時間',
      addNews: '添加資訊',
      editNews: '編輯資訊',
      viewNews: '查看資訊',
      coverPictureTip: '請上傳封面圖片',
      coverPictureTips: '提示：圖片長寬比例 2：1 , 最小尺寸 100圖元 * 50圖元',
      userLimitConfig: '咨詢可見範圍設置',
      userNoLimit: '不限製',
      userLimit: '指定身份可見'
    },
    packageRuleConfig: {
      sort: '規則排序',
      ruleName: '包郵規則',
      ruleNameTip: '請填寫包郵規則',
      activeType: '作用對象',
      rangeType: '作用範圍',
      isFullPackageType: '是否參與滿額包郵',
      fullPackageAmount: '滿額包郵金額',
      addPackageRule: '添加規則',
      editPackageRule: '編輯規則',
      viewPackageRule: '查看規則',
      all: '所有',
      allCustomer: '全部客戶',
      appointCustomerCategory: '指定客戶分類',
      appointCustomer: '指定客戶',
      allMember: '全部會員',
      appointMemberLevel: '指定會員等級',
      appointMember: '指定會員',
      notFullPackage: '否',
      isFullPackage: '是',
      customer: '客戶',
      member: '會員',
      defaultRule: '默認規則',
      sortTips: '數字越小, 排名越靠前, 默認排序為規則修改時間'
    },
    announcementManage: {
      sort: '公告排序',
      title: '公告標題',
      state: '公告狀態',
      content: '公告內容',
      createTime: '發佈時間',
      addAnnouncement: '添加公告',
      editAnnouncement: '編輯公告',
      viewAnnouncement: '查看公告'
    },
    payConfig: {
      paymentMonthAccount: '掛賬月結資金帳戶',
      paymentMonthStatement: '掛賬月結',
      advancePayment: '定向預收款支付 ',
      paymentMonthStatementTip: '掛賬月結只支援綁定系統客戶的會員，且綁定客戶時開啟了掛賬月結設置',
      weChatApplet: '微信小程式',
      weChatPay: '微信支付',
      remainPay: ' 餘額支付',
      balancePaymentTip: '余額支付只支持綁定系統客戶的會員，余額數據來源於預收款數據。會員也需要同時開通余額支付才能使用',
      rechargeOfflinePayment: '余額充值',
      balanceRechargeAccount: '線下轉賬充值可用資金賬戶',
      deliveryCash: '貨到付款',
      deliveryCashTip: '如果需支援貨到付款，也需要將商品設置成貨到付款.',
      offlinePayment: '資金帳戶',
      offlineAccount: '資金帳戶',
      remainPayAccount: '餘額支付資金帳戶',
      offlinePaymentTip: '如果需支援線下付款，也需要將商品設置成線下付款',
      payType: '支付類型',
      merchantName: '商戶名稱',
      serviceProviderOfficial: '服務商公眾號 (AppId)',
      serviceProviderPayMerchant: '服務商支付商戶號 (Mch_Id)',
      publicAppID: '公眾號/小程式 (AppID)',
      payMerchant: '支付商戶號 (Mch_Id)',
      payKey: '支付金鑰',
      certificateFile: 'CERT檔證書(P12格式)',
      keyFile: 'KEY金鑰文件',
      rootFile: 'ROOT文件',
      paymentName: '支付名稱',
      writePaymentName: '請輸入支付名稱',
      writePaymentTemplate: '請選擇支付範本',
      paymentMethod: '支付類型',
      templateName: '範本名稱',
      time: '創建時間',
      weChatPayChild: '微信支付子商戶',
      alipayPay: '支付寶支付',
      edit: '支付範本編輯',
      add: '支付範本添加',
      weChatPayType: '請選擇支付類型',
      accountId: '資金帳戶',
      accountIdTips: '請選擇資金帳戶',
      offlineAccountIdTips: '請選擇線下付款資金帳戶',
      remainPayAccountIdTips: '請選擇餘額付款資金帳戶',
      offlineAccountPlease: '請選擇線下支付資金帳戶',
      remainPayAccountPlease: '請選擇餘額支付資金帳戶',
      monthPayAccountPlease: '請選擇掛賬月結資金帳戶',
      weChatCertNotEmpty: '請上傳CERT證書證書',
      directAdvanceReceiptPayment: '定向抵扣',
      directAdvanceReceiptPaymentTip: '定向抵扣只支持綁定系統客戶的會員，且商品在定向抵扣類型範圍內',
      directAdvanceReceiptCompanyIdPlease: '請選擇所屬公司',
      accountName: '資金賬戶名稱',
      account: '賬號',
      payKeyV2: '支付密鑰V2',
      payKeyV3: '支付密鑰V3',
      otherPayment: '其它支付',
      rechargeAdvanceReceiptType: '充值預收款類型',
      rechargeAdvanceReceiptTypePlease: '請選擇充值預收款類型'
    },
    paramConfig: {
      Parameters: '參數配置',
      SmallProgram: '小程式APPID',
      SmallSecret: '小程式APP Secret',
      paramConfig: '支付設置',
      Jump: '立即前往設置',
      Skip: '跳過當前小程式',
      add: ' 添加你當前小程式網站需要跳轉的Appid 資訊可在裝修中進行配置跳轉小程式。',
      Gdecoration: '前往裝修配置',
      SmallPrograms: '小程式名單',
      WeChatSmallProgram: '微信小程式',
      UnderlyingSettings: '基礎設置',
      MaintainSmallProgram: '維護小程式',
      common: {
        SmallProgram: '請輸入小程式APPID',
        SmallSecret: '請輸入小程式APP Secret'
      }
    },
    registerConfig: {
      register: '註冊登錄',
      registerTip: '使用者訪問商城時，是否需要授權/登錄後才可進行流程商城。',
      mandatoryLogin: '強制授權登錄',
      mandatoryLoginTip1: '系統預設關閉強制授權登錄，當觸發加入購物車、立即購買、提交表單、領取優惠券時需要獲取使用者使用者登錄資訊。',
      mandatoryLoginTip2: '開啟強制授權登錄時，用戶訪問商城必須授權後才可進行商城流覽及下單操作。',
      logWay: '手機流覽器H5 登錄方式',
      logWayOne: '短信驗證碼登錄',
      logWayTwo: '帳號密碼登錄',
      bindPhone: '綁定手機號',
      bindPhoneExplain: '（適用于全管道帳號統一）使用全網管道商城，強烈推薦綁定手機號',
      bindPhoneTip: '提示：買家通過微信授權登錄商城後，引導買家綁定手機號，識別買家唯一身份，解決買家在微信（公眾號和小程式）等不同管道帳號不同的問題',
      bindingType: '綁定類型',
      bindingTypeOne: '強制綁定',
      triggerTypeOne: '點擊加入購物車',
      triggerTypeTwo: '點擊立即購買',
      triggerTypeThird: '買家進行分享',
      triggerTypeFour: '買家提交表單',
      triggerTypeFive: '買家領取優惠券',
      bindingTypeOneTip: '買家進入商城授權後，必須進行綁定手機號成功後才可訪問商城',
      bindingTypeTwo: '手動綁定',
      bindingTypeTwoTip: '買家通過授權登錄商城後，需要使用者在個人中心主動操作綁定手機號',
      weChatAccount: '微信帳號體系',
      weChatAccountExplain: '（需配置微信開放平臺）',
      zeroOne: '01',
      zeroOneText: '需要同時開啟微信小程式管道 、開啟微信公眾號管道',
      zeroOneTip: '同時開啟時，用戶可進行多管道訪問商城。',
      zeroTwo: '02',
      zeroTwoText: '在微信開放平臺進行綁定配置',
      zeroTwoTip: '未配置開放平臺前，同一個微信生成的不同會員，配置開放平臺後也無法統一帳號，只能通過手機號進行綁定。',
      zeroTwoTips: '注：未配置開發平臺前，同一個微信生成一個會員時，配置開放平臺後生成另一個會員時會自動執行微信帳號統一，識別買家唯一微信身份。',
      immediatelyConfiguration: '立即配置',
      configurationGuide: '配置引導'
    },
    tab: {
      mallconfig: '商城設置',
      store: '首頁設計',
      dealConfig: '交易設置',
      pointConfig: '積分設置',
      afterSalesConfig: '售後設置',
      packageConfig: '包郵設置',
      announcementManage: '公告管理',
      payConfig: '支付設置',
      registeredConfig: '註冊設置',
      smallProgramConfig: '小程式設置',
      paymentTemplateManagement: '支付範本管理',
      pickPoint: '自提點 ',
      notifyConfig: '通知配置',
      msgTemplateConfig: '小程序通知配置',
      mallOrderSubmit: '訂單提交模板',
      mallOrderPaySuccess: '訂單支付成功模板',
      mallOrderConfirm: '訂單確認模板',
      mallOrderDelivery: '訂單發貨模板',
      mallOrderPreparedCompleted: '訂單備貨完成模板',
      addressManage: '地址管理',
      returnAddress: '退貨地址',
      orderRewardConfig: '下單獎勵',
      goodsParamConfig: '商品詳情設置',
      clearGoodsParam: '清空商品參數',
      clearGoodsParamTips: '是否確定清空所有商品【{0}】屬性的參數值'
    },
    returnAddress: {
      returnAddressName: '地址標題',
      receiverName: '收件人',
      receiverPhone: '收件人電話',
      address: '收件地址',
      addTitle: '添加退貨地址',
      editTitle: '編輯退貨地址',
      returnAddressNamePlease: '地址標題不能為空',
      areaPlease: '區域不能為空',
      addressPlease: '地址不能為空',
      receiverNamePlease: '收件人不能為空',
      receiverPhonePlease: '收件人電話不能為空',
      deleteTips: '確認刪除該退貨地址'
    },
    common: {
      open: '開啟',
      close: '關閉',
      save: '保存',
      allowSwitching: '是否允許切換店鋪',
      neverShutDown: '永不關閉',
      customShutdownTime: '自訂關閉時間',
      day: '天',
      show: '顯示',
      hide: '隱藏',
      remark: '備註',
      isDelete: '是否刪除',
      announcemenTitle: '請填寫公告標題',
      writeMallName: '請填寫商城名稱',
      writeMallLogo: '請填寫商城LOGO',
      writeMallBrief: '請填寫商城簡介',
      writeTakeUnpaidOrder: '請填寫訂單關閉時間',
      writeTheDeliver: '請填寫發貨時間',
      uploadFile: '上傳文件',
      againUpload: '重新上傳',
      writeMerchantName: '請填寫商戶名稱',
      writeServiceProviderOfficial: '請填寫服務商公眾號 (AppId)',
      writeServiceProviderPayMerchant: '請填寫服務商支付商戶號 (Mch_Id)',
      writePublicAppID: '請填寫公眾號/小程式 (AppID)',
      writePayMerchant: '請填寫支付商戶號 (Mch_Id)',
      writePayKey: '請填寫支付金鑰',
      writePayType: '請選擇支付類型',
      customer: '客戶',
      member: '會員',
      chooseGoods: '選擇商品',
      selectSku: '選擇sku',
      selectSpu: '選擇商品spu',
      goodsCategory: '商品分類',
      generalOrder: '普通訂單',
      giftOrder: '禮盒訂單'
    },
    goods: {
      categoryTree: '分類樹',
      categoryDetails: '分類詳情',
      goodsType: '商品類型',
      realGoods: '實體產品',
      virtualGoods: '虛擬產品',
      goodsTitle: '商品標題',
      goodsSubtitle: '商品副標題',
      goodsShortName: '商品簡稱',
      mainImage: '商品主圖',
      rotationImage: '商品輪播圖',
      video: '商品視頻',
      goodsCategory: '商品分類',
      salesLabel: '行銷標籤',
      isHot: '熱賣',
      isNew: '新品',
      isRecommend: '推薦',
      sort: '排序',
      specsConfig: '規格設置',
      singleSpecs: '單規格',
      multiSpecs: '多規格',
      salesUnitPrice: '銷售價',
      markingUnitPrice: '劃線價',
      goodsStatusConfig: '上架設置',
      price: '價格',
      stock: '庫存',
      createTime: '創建時間',
      addGoodsTitle: '添加商品',
      editGoodsTitle: '編輯商品',
      relatedProduct: '關聯產品',
      skuSpecsValues: '規格',
      skuCode: 'SKU 編碼',
      quantity: '數量',
      subtotal: '小計',
      picture: '圖片',
      startDate: '修改時間起',
      endDate: '修改時間止',
      modifyDate: '修改時間',
      goodsPushdownConfig: '小店必選商品',
      discountConfig: '折扣設置',
      morePrice: '更多價格',
      pleaseSelectRelatedProduct: '請選擇關聯產品',
      pleaseChooseGoods: '請選擇商品',
      allCategory: '全部分類',
      notDisplaySalesQuantity: '不顯示銷量',
      actualSalesQuantity: '實際銷量',
      virtualSalesQuantity: '虛擬銷量',
      sumSalesQuantity: '實際銷量 + 虛擬銷量',
      salesQuantityType: '顯示銷量',
      salesQuantityTips: '是：顯示銷量=實際銷量+虛擬銷量   否：虛擬銷量',
      sortTips: '數字越大, 排名越靠前, 默認排序為商品修改時間',
      imageTips: '建議圖片比例為1：1',
      tradeConfig: '交易設置',
      goodsTitlePlease: '請輸入商品標題',
      mainImagePlease: '請上傳商品主圖',
      virtualStock: '虛擬庫存',
      realStock: '實際庫存',
      goodsStatus: '商品狀態',
      viewStockConfig: '顯示庫存設置',
      reduceStockConfig: '庫存扣減設置',
      orderedReduce: '下單減庫存',
      notReduce: '永不減庫存',
      viewStockConfigTips: '虛擬庫存：商品設置的庫存 實際庫存：關聯系統產品商品對應的庫存',
      reduceStockConfigTips: '下單減庫存：買家提交訂單, 扣減庫存數量, 可能會存在 惡意佔用庫存風險 永不減庫存：無論在下單還是在支付成功, 該商品永遠不會扣減庫存數量',
      configGoodsTitle: '商品資訊配置',
      mallName: '門戶名稱',
      viewGoodsTitle: '查看商品資訊',
      batchPriceSet: '批量設置',
      applyPriceSet: '應用',
      memberFreightConfig: '會員運費範本設置',
      defaultFreightTemplate: '預設運費範本',
      pleaseSelectFreightTemplate: '請選擇運費範本',
      customerFreightConfig: '客戶運費範本設置',
      memberCustomFreightConfig: '會員運費模板自定義設置',
      customerCustomFreightConfig: '客戶運費模板自定義設置',
      customFreightBusinessEmpty: '自定義運費模板請選擇會員等級/客戶分類',
      addCustomFreightConfig: '添加自定義設置',
      customFreightConfig: '自定義設置',
      freeFreight: '包郵',
      notFreeFreight: '不包郵',
      goodsVisibleConfig: '商品可見範圍設置',
      noLimit: '不限制',
      specifiedCustomerVisible: '指定客戶可見',
      specifiedCustomerSortVisible: '指定客戶分類可見',
      goodsSortVisibleConfigTip: '指定客戶分類可見:添加後，只有會員綁定了指定客戶分類才可瀏覽該商品',
      goodsVisibleConfigTip: '指定客戶可見:添加後，只有會員綁定了指定客戶才可瀏覽該商品',
      goodsVisibleConfigNoTip: '都不可見: 設置後對所有人都不可見，對禮盒商品無影響',
      noVisible: '都不可見',
      noChooseGoodsVisibleConfig: '商品可見範圍設置中,您未指定客戶',
      noChooseGoodsSortVisibleConfig: '商品可見範圍設置中,您未指定客戶分類',
      noLimitBuy: '不限購',
      limitBuy: '限購',
      limitBuyConfig: '限購設置',
      limitDay: '天限購',
      limitBuyTip: '天數輸入0爲永久限購; 輸入1爲當天,按自然天計算',
      limitQutity: '  个',
      limitBuySaveTip: '限購設置：選擇了限購，需填寫天數和個數',
      pleaseDay: '請輸入天數',
      pleaseQutity: '請輸入個數',
      submitProgramLiveLibrary: '小程序直播商品',
      remandTheCase: '提審',
      priceType: '價格類型',
      priceOne: '一口價',
      priceRange: '價格區間',
      displayDiscountPrices: '顯示折扣價',
      priceTypeTip: '提示：價格區間（左輸入框爲左邊界，右輸入框爲右邊界,左邊界不能大於右邊界） ，顯示折扣價（左輸入框爲原價，右輸入框爲現價,現價不能大於原價）',
      remandTheCaseEdit: '提審編輯',
      remandTheCaseSuccess: '提審成功',
      notAudit: '未審覈',
      auditing: '審覈中',
      audited: '審覈通過',
      auditFail: '審覈失敗',
      withdrawAudit: '撤回審覈',
      withdrawSuccess: '撤回成功',
      againRemand: '重新提審',
      deleteTip: '確定刪除小程序的直播商品嗎？刪除後不可恢復（每天限1000次）',
      modifyPrice: '修改價格',
      withdrawTip: '確定撤銷直播商品的審覈嗎？本次提審不生效（每天限500次）',
      priceTip: '價格區間，左邊界不能大於右邊界',
      priceTipTwo: '顯示折扣價，現價不能大於原價',
      checkGoodsAudit: '勾選後，同時提交商品審覈',
      more: '更多',
      yuan: '元',
      mainImageTip: '建議尺寸：300像素 * 300像素，圖片大小不得超過1M',
      submitTip: '確認提示：新增商品審覈，每天限500次；更新商品，每天限1000次',
      addGoodsAudit: '添加直播商品',
      editGoodsAudit: '編輯直播商品',
      againRemandTip: '確定重新提交直播商品審覈嗎，審覈需要1-7天（每天限500次）',
      presentPrice: '現價',
      originalPrice: '原價',
      priceNotNull: '左右輸入框都不能爲空',
      auditSucess: '審覈已通過,不能撤回',
      batchMpcode: '生成商品碼',
      batchAllSpuMpcode: '生成全部商品碼',
      batchSelectSpuMpcode: '生成已查询商品碼',
      batchCheckSpuMpcode: '生成已选择商品碼',
      spuMpcode: '商品碼',
      batchmpCodeTips: '請選擇商品',
      batchDeletePosterTips: '請選擇需刪除的海報',
      batchOutSuccess: '批量下架成功',
      batchOffShelf: '下架',
      batchSellout: '售罄',
      batchOffShelfTips: '確認下架已選擇的商品?',
      isDeleteChooseGoods: '確認刪除已選擇的商品?',
      isDeleteChoosePoster: '確認刪除已選擇的海報?',
      batchSellOutTips: '確認已選商品已售罄',
      isRecoveryChooseGoods: '確認將已選擇的商品恢復到待上架狀態？ ',
      recoverySuccess: '恢復成功',
      allRemove: '清除回收站全部商品',
      deleteChoose: '清除回收站勾選商品',
      removeGoods: '清空回收站',
      isAllRemove: '確認清空回收站全部商品嗎? 清空後將不可恢復',
      isDeleteChoose: '確認刪除回收站勾選商品嗎? 刪除後將不可恢復',
      noRemoveGoods: '沒有可清除的商品',
      removeSuccess: '清除成功',
      batchAllSpuMpcodeTips: '是否確認全部已上架商品生成商品碼?',
      inputNotNull: '輸入框不能爲空',
      minimumQuantityConfig: '起訂數量設置',
      minimumQuantityTip: '起訂數量：0即無限制，可設置起訂數量範圍或單獨設置起訂數量',
      minimumQuantityLimit: '起訂數量不能大於限購數量',
      minimumLimit: '限制起訂數量',
      noMinimumLimit: '不限制起訂數量',
      minimumLimitTip: '限制起訂數量,最小起訂數量不能大於最大起訂數量',
      minimumNoNullTip: '起訂數量設置，請填寫要限制的起訂數量',
      minMinimumTip: '最小起訂數量不能爲0',
      min: '最小',
      max: '最大',
      mallCategory: '商品分類',
      productName: '產品名稱',
      batchAdjustment: '批量調整',
      adjustCategory: '調整分類',
      selectGoodsEmpty: '請選擇商品',
      selectedCategoryEmpty: '請選擇分類',
      sellOutStatus: '是否售罄',
      isSellOut: '已售罄',
      notSellOut: '未售罄',
      batchFreightTemplateSet: '調整運費模板',
      batchQuantityConfigInfo: '調整銷量或庫存',
      batchPresale: '調整預售',
      selectEmpty: '請選擇商品',
      batchFreightTemplateSetTips: '確認批量調整運費模板,已選商品數量：',
      batchQuantityConfigInfoTips: '確認批量調整銷量或庫存,已選商品數量：',
      batchPresaleTips: '確認批量調整預售,已選商品數量：',
      stockNullTips: '虛擬庫存為空則不更新庫存信息，需要清空庫存則輸入0',
      productBrand: '品牌',
      pleaseSelectRelatedProductBrand: '請選擇商品品牌',
      relatedMallGoods: '引入商城商品',
      batchOnShelf: '上架',
      batchOnShelfTips: '是否確認上架商品，未配置零售價的商品將會跳過',
      aboutSaleTimePlease: '預售商品，請選擇預計到貨時間',
      presale: '是否預售',
      aboutSaleTime: '預計到貨時間',
      customerPriceLevel: '客戶結算價格',
      isOutDelivery: '委外發貨',
      adjustIsOutDelivery: '調整委外發貨商品',
      batchIsOutDeliveryTips: '確認批量調整委外發貨商品,已選商品數量：',
      virtualDeliveryType: '虛擬發貨方式',
      virtualDeliveryGoodsType: '虛擬發貨商品類型',
      virtualDeliveryGoods: '虛擬發貨商品',
      selectVoucher: '選擇代金券',
      noneDelivery: '無需發貨',
      autoDelivery: '自動發貨',
      virtualVoucher: '代金券',
      virtualResource: '網絡資源',
      virtualDeliveryQuantity: '發放數量',
      virtualDeliveryGoodsPlease: '請配置虛擬發貨商品',
      expressDeliveryEnable: '可快遞',
      selfPickEnable: '可自提',
      deliveryType: '發貨方式',
      goodsDeliveryTypePlease: '請配置發貨方式',
      adjustDeliveryType: '調整發貨方式',
      batchAdjustDeliveryTypeTips: '確認批量調整商品發貨方式,已選商品數量：',
      skuBindProductPlease: 'SKU未綁定產品',
      param: {
        editParam: '商品參數編輯',
        enable: '開啟',
        disabled: '關閉',
        paramName: '參數',
        paramValue: '內容',
        addParam: '添加參數',
        writePatam: '未填寫參數',
        brand: '品牌',
        productionPlace: '產地',
        netWeight: '凈含量',
        internalConfig: '內配',
        custom: '自定義',
        barcode: '條形碼',
        boxGauge: '箱規',
        internalName: '名稱',
        internalNetWeight: '凈含量',
        internalQuantity: '數量',
        addInternalConfig: '添加內配',
        otherParam: '其它參數',
        syncSkuProductInfo: '同步SKU產品參數信息',
        sync: '同步',
        internalConfigParse: '內配解析規則',
        parse: '解析',
        batchSetInternal: '批量設置',
        parseTips1: '內配輸入格式 = 名稱 + 空格 + 凈含量 + * + 數量',
        parseTips2: '內配之間用換行進行區分',
        parseTips3: '如：',
        parseTips4: '內配名稱1  100g  * 1',
        parseTips5: '內配名稱2  200g  * 1',
        parseTips6: '內配名稱3 * 1',
        parseTips7: '內配名稱4'
      },
      tabs: {
        onShelfGoods: '出售中',
        soldOutGoods: '已售罄',
        offShelfGoods: '待上架',
        deletedGoods: '回收站',
        baseInfo: '基本資訊',
        detailsInfo: '詳情',
        paramInfo: '參數',
        categoryInfo: '分類資訊',
        categoryConfig: '分類設置',
        discount: '會員折扣',
        promotionPoster: '推廣海報'
      },
      posters: {
        download: '下載',
        poster: '海報',
        code: '海報編碼',
        title: '標題',
        mainImage: '主圖',
        endTime: '有效期止',
        visibleRange: '可見範圍 ',
        createTime: '創建時間',
        creatPeople: '創建人',
        updateTime: '修改時間',
        automaticFilling: '自動生成',
        uploadImg: '請上傳圖片',
        tip: '建議尺寸：比例1：1',
        titleTip: '請填寫標題',
        close: '關閉',
        customer: '客戶',
        customerSort: '客戶分類',
        member: '會員',
        generatePoster: '生成海報',
        generatePpt: '生成PPT',
        errorTips: '載入超時，請稍後再試',
        chooseGoods: '選擇商品',
        remove: '移除',
        posterGoods: '海報商品',
        lookCode: '微信掃描查看',
        downloadSuccess: '下載成功',
        setMainImage: '設為主圖',
        visibleRangeChoose: '可見範圍選擇',
        addPostersGoods: '請添加海報商品',
        selectPptTemplate: 'PPT模板配置',
        pptTemplate: 'PPT模板',
        pptTemplatePlease: '請選擇PPT模板'
      },
      tips: {
        deleteGoodsTips: '確認【刪除】該商品',
        onShelfTips: '確認【上架】該商品',
        offShelfTips: '確認【下架】該商品',
        pushdownTips: '該商品將在所有小店可見',
        salesPriceNotEmpty: '請前往【銷售管理-銷售價格】,設置產品價格。設置完成後，請重新選擇產品',
        recoveryTips: '商品將恢復到待上架狀態'
      },
      operate: {
        onShelf: '上架',
        offShelf: '下架',
        recovery: '恢復',
        pushdown: '小店必選',
        saveAndOnShelf: '保存並上架'
      },
      status: {
        offShelf: '待上架',
        onShelf: '已上架'
      },
      specs: {
        specsType: '規格項',
        specsValue: '規格值',
        addSpecsValue: '添加規格值',
        addSpecsType: '添加規格項'
      },
      menu: {
        goodsInfo: '商品管理',
        categoryInfo: '商品分類',
        categoryConfig: '設置分類層級'
      },
      category: {
        categoryImage: '分類圖片',
        categoryName: '分類名稱',
        keyValue: '鍵值',
        parentCategory: '父級分類',
        sort: '排序',
        isEnable: '是否啟用',
        categoryLevel: '分類級別',
        primaryLevel: '一級分類',
        secondaryLevel: '二級分類',
        tertiaryLevel: '三級分類',
        deleteTips: '確認刪除該分類',
        tertiaryLevelWithGoods: '分類+產品',
        firstLevelWithGoods: '一級分類+產品'
      },
      discount: {
        none: '無折扣',
        memberDiscount: '會員折扣'
      }
    },
    userLevel: {
      memberLevel: '請輸入關鍵字進行查詢',
      nameOfLevel: '名稱',
      gradeValue: '值',
      gradeState: '默認會員',
      stateEnabled: '啟用',
      notEnabled: '未啟用',
      Information: '備註',
      addRating: '添加',
      editRating: '編輯',
      deleteRating: '是否刪除該等級',
      memberLevelName: '名稱',
      sort: '排序',
      pleaseGradeValue: '請輸入等級值',
      pleasememberName: '請輸入名稱',
      remark: '備註',
      enable: '是否啟用',
      limitsize: '最大限制127',
      discountTip: '折扣權益，請填寫折扣數值',
      discountTip2: '折扣權益，請選擇價格類型'
    },
    delivery: {
      deliveryCode: '發貨單編碼',
      receiptorName: '收件人',
      receiptorPhone: '收件人電話',
      express: '快遞',
      address: '收件地址',
      buyerRemark: '買家備註',
      sellerRemark: '賣家備註',
      createTime: '單據時間',
      mallDeliveryBaseInfo: '基本資訊',
      inventoryInfo: '出庫信息',
      viewTitle: '查看商城發貨單',
      startDate: '創建時間起',
      endDate: '創建時間止',
      editMallDelivery: '編輯商城發貨單',
      giftDeliveryQuantity: '禮盒發貨數量',
      giftOrderQuantity: '訂單禮盒數量',
      orderQuantity: '訂單數量',
      editTips: '確認修改發貨單',
      mallDelliveryRevokeTips: '訂單已確認收貨，確認撤銷發貨單'
    },
    return: {
      returnId: '退換貨單號',
      returnType: '退換貨單類型',
      returnStatus: '退換貨單狀態',
      returnAmount: '退款金額',
      createTime: '創建時間',
      toConfirmed: '待確認',
      confirmed: '確認',
      rejected: '駁回',
      beConfirmed: '已確認',
      beRejected: '已駁回',
      refunds: '商品退貨退款',
      exchange: '商品換貨',
      refund: '商品退款(僅退款)',
      orderReturn: '訂單退款',
      freightReturn: '運費退款',
      lookDeail: '查看詳情',
      returnReason: '退款原因',
      exchangeReason: '換貨原因',
      orderNumber: '訂單號',
      goodsStatus: '貨物狀態',
      additionalDescription: '補充描述',
      credentials: '憑證 ',
      address: '買家地址',
      confirmedSuccess: '確認成功',
      rejectedSuccess: '駁回成功',
      rejectedReseaon: '駁回理由',
      sellAddress: '商家退貨地址',
      tip: '提示',
      confirmTip: '此退換貨單是否確認審核通過',
      beCancel: '已取消',
      queryTip: '退換貨單號 訂單號',
      returnGoodsInfo: '退換商品資訊',
      completed: '已完成',
      buyerNotDelivery: '買家待發貨',
      buyerDelivered: '買家已發貨',
      sellerReceipt: '商家已收貨',
      sellerDelivered: '商家已發貨',
      buyerReceipt: '買家已收貨',
      notRefund: '待退款',
      refunded: '已退款',
      canceled: '用戶取消',
      viewTitle: '查看退換貨單號',
      mallReturnBaseInfo: '退換貨信息',
      inventoryInInfo: '入庫單',
      inventoryOutInfo: '出庫單',
      startDate: '創建時間起',
      endDate: '創建時間止',
      through: '通過',
      keyPlaceholder: '訂單號 姓名 電話號碼 商品名稱 退換貨單號',
      courier: '快遞',
      otherWay: '其他方式',
      returnWay: '退貨方式',
      wayRemark: '方式說明',
      SendBackExpressInformation: '快遞信息',
      returnAddress: '退換貨地址',
      returnGoodsTable: '退換貨商品',
      deliveryGoodsTable: '已發貨商品',
      returnQuantity: '退換數量',
      deliveryQuantity: '發貨單數量',
      inventoryQuantity: '已出庫數量',
      giftItemQuantity: '子件數量',
      deliveryCode: '發貨單編碼',
      alterDeliveryQuantityTips: '訂單退貨退款後可調整發貨單商品數量，請按實際情況調整',
      returnAddressPlease: '請選擇退貨地址',
      orderPaymentType: '支付方式',
      userName: '下單會員',
      customerName: '客戶',
      orderPaidAmount: '訂單總額',
      freight: '運費',
      orderQuantity: '訂單數量',
      returnedQuantity: '已退數量',
      abountReturnAmount: '預計可退金額',
      returnTypeEmpty: '請選擇退換類型',
      returnQuantityEmpty: '請填寫退換商品數量',
      returnAmountEmpty: '請填寫退款金額',
      returnAddressEmpty: '請選擇退換貨地址',
      fillReturnAmount: '填入金額',
      refundBalance: '退差價',
      abountReturnGoodsAmount: '已選退款商品總額',
      hasReturnAmount: '已退款金額',
      returnAmountGreatThenAbaleReturnAmount: '退款金額大於訂單可退金額',
      deliveredQuantity: '已發數量',
      completeTip: '確認關閉改售後單？',
      closeReturnOrder: '關閉',
      deliveryGoodsInfo: '發貨明細',
      outDeliveryGoodsInfo: '委外發貨明細',
      returnQuantitySum: '退換總數',
      purchaseOutReturn: '委外退貨',
      outRequireQuantity: '委外需求數量',
      outOrderQuantity: '已發數量',
      outReturnQuantitySum: '委外退貨總數量',
      outOrderReturnQuantity: '委外已發退數量',
      mallDeliveryReturn: '商城發貨退貨',
      mallDeliveryQuantity: '發貨單數量',
      mallInventoryQuantity: '已出庫數量',
      notInventoryReturnQuantity: '未出庫退數量',
      inventoryReturnQuantity: '已出庫退數量',
      splitReturnQuantityMallDeliveryFirst: '優先退商城發貨單',
      splitReturnQuantityOutFirst: '優先退委外單',
      greatThenAbleReturnQuantity: '超出可退數量',
      returnQuantityNotEqual: '退換總數 != 未發貨退數量 + 商城發貨退數量 + 委外發貨退數量',
      notDeliveryReturnQuantity: '未發貨退',
      inventoryReturnExchangeQuantity: '換貨數量',
      outReturnExchangeQuantity: '換貨數量',
      mallOrderInventoryProcessRate: '訂單出庫進度',
      rollBackTip: '確認撤回售後單，撤回後需重新審核',
      addReturnTips: '確認通過後，訂單包含代金券/積分支付將實時退還，剩余部分需財務操作付款單退回',
      maxReturnAmount: '最大可退金額',
      rejectedAndComplete: '駁回並關閉',
      rejectReturnTips: '確認駁回該售後',
      rejectAndCompleteReturnTips: '確認駁回並關閉該售後？',
      returnPointAmount: '退積分數量',
      hasReturnPoint: '已退積分'
    },
    recharge: {
      memberName: '會員',
      customerName: '客戶',
      rechargeMethod: '充值方式',
      rechargeAmount: '充值金額',
      status: '充值狀態',
      accountName: '帳戶名',
      accountNumber: '帳號',
      weChatOrderCode: '微信支付號',
      certificate: '支付憑證',
      online: '微信支付',
      offline: '線下轉帳',
      submit: '已提交',
      notPaid: '未付款',
      cancaled: '已取消',
      success: '充值成功',
      fail: '充值失敗',
      viewRecharge: '查看充值信息'
    },
    memberVoucherShareRecord: {
      shareMemberName: '贈送代金券人',
      receiveMemberName: '領取代金券人',
      receiveStatus: '領取狀態',
      noReceiveRemark: '未領取原因',
      voucherTitle: '代金券標題',
      voucherAmount: '代金券面值',
      shareTime: '贈送時間',
      notReceive: '未領取',
      receive: '已領取',
      recover: '已收回'
    },
    memberExchangeVoucherShareRecord: {
      shareMemberName: '贈送兌換券人',
      receiveMemberName: '領取兌換券人',
      receiveStatus: '領取狀態',
      noReceiveRemark: '未領取原因',
      voucherTitle: '兌換券標題',
      shareTime: '贈送時間',
      notReceive: '未領取',
      receive: '已領取',
      recover: '已收回'
    },
    browseRecord: {
      recordType: '類型',
      goods: '商品',
      poster: '海報',
      title: '標題',
      sourceType: '來源',
      clearBrowseRecord: '清空瀏覽記錄',
      clearRange: '清空範圍',
      all: '全部',
      someSourceType: '指定來源',
      dateRange: '時間區間',
      queryKeyword: '標題',
      sourceTypePlease: '請選擇來源',
      dateRangePlease: '請選擇時間區間',
      subtotal: '合計',
      visitor: '遊客',
      viewUserDetailsInfo: '用戶訪問明細',
      user: '用戶昵稱',
      customerName: '客戶名稱',
      recordQuantity: '訪問次數',
      recentRecordDate: '最近訪問時間',
      viewUserDetailsQuantityInfo: '用戶訪問數量明細',
      recordDate: '訪問時間',
      customerSort: '客戶分類',
      quantityDetailQueryKeyword: '用戶昵稱、客戶名稱',
      detailQueryKeyword: '用戶昵稱、標題',
      recordDetailsCount: '訪問統計',
      recordDetails: '訪問明細'
    },
    searchHistory: {
      searchHistoryDetails: '搜索明細',
      queryKeyword: '分類名 關鍵字',
      searchKeyword: '搜索關鍵字',
      category: '搜索分類',
      searchPriceRange: '搜索價格區間',
      lowestPrice: '查詢最小價格',
      searchDate: '搜索時間'
    },
    promotionalPoster: {
      background: '背景圖片',
      backgroundTip: '背景圖片建議尺寸 1024*1625',
      miniProgramCodePosition: '小程式碼位置',
      showMemberAvatar: '顯示會員頭像',
      miniProgramLeft: '左邊距',
      miniProgramTop: '上邊距',
      miniProgramWidth: '寬度',
      miniProgramHeight: '高度',
      miniProgramCircle: '裁剪為圓形',
      addText: '新增文字',
      textContentTip: '樣式設定（範本實際效果以預覽為准）',
      textContent: '文字內容',
      fontSize: '字體大小',
      fontAlign: '對齊管道',
      fontStyle: '字體樣式',
      fontColor: '字體顏色',
      deleteText: '删除文字',
      deleteTextTip: '是否確定删除該文字？'
    }
  },
  welfare: {
    exchangeReduce: '兌換減庫存',
    exchangedQuantity: '已兌換數量',
    exchangeQuantityReport: '兌換數量',
    generateCode: '生成方案二維碼',
    addWelfareIssue: '添加福利發放',
    editWelfareIssue: '編輯福利發放',
    viewWelfareIssue: '查看福利發放',
    exchangeAddress: '兌換地址',
    exchangeAddressPlease: '請填寫兌換地址',
    planName: '方案名稱',
    planStartTime: '方案開始時間',
    planEndTime: '方案結束時間',
    addPlan: '添加卡券方案',
    editPlan: '編輯卡券方案',
    viewPlan: '查看福卡券方案',
    planText: '方案描述',
    goods: '卡券商品',
    chooseGoods: '選擇卡券商品',
    addGoods: '添加商品',
    startEndTime: '方案起止時間',
    releaseTime: '發佈時間',
    release: '發布',
    saveAndRelease: '保存並發布',
    addGoodsTip: '未添加商品',
    pleasePlanName: '請填寫方案名稱',
    pleasePlanTime: '請選擇方案起止時間',
    draft: '草稿',
    offline: '下線',
    waitStart: '未開始',
    ongoing: '進行中',
    ended: '已結束',
    offlineed: '已下線',
    planInfo: '方案信息',
    planConfig: '方案設置',
    exchangeInformation: '兌換信息',
    exchangeOrder: '兌換訂單',
    exchangeSuccess: '兌換成功',
    delivered: '已發貨',
    received: '已收貨',
    smsPlanConfig: '福利方案短信發送節點設置',
    cardNumber: '卡券編號',
    passWord: '卡券密碼',
    appCode: '小程序碼',
    appCodeStatus: '小程序碼狀態',
    exchangeStatus: '兌換狀態',
    notGenerate: '未生成',
    inGenerate: '已生成',
    generateFail: '生成失敗',
    hasChange: '已兌換',
    noChange: '未兌換',
    exchangeNumber: '生成卡券數量',
    generateSuccess: '生成成功',
    exchangeNumberTip: '請填寫生成卡券數量',
    generateCard: '生成卡券',
    generateAll: '全部小程序碼',
    generateNo: '未生成的小程序碼',
    generateFails: '生成失敗的小程序碼',
    batchGenerateAppCode: '批量生成小程序碼',
    exChange: '兌換',
    generateAppCode: '生成卡券兌換碼',
    skuImage: '商品sku圖片',
    speci: '規格',
    exchangeWelfare: '兌換福利',
    chooseAppCodeStatus: '選擇要生成小程序碼的狀態',
    all: '全部',
    noAppCodeNeedGene: '沒有要生成小程序碼的卡券',
    appCodeIsGenerate: '小程序碼正在後台生成，請稍後進行查看',
    submitTip: '請選擇兌換的商品',
    deliveryTip: '請填寫快遞公司和快遞單號',
    isOver: '確認【下線】該福利方案',
    isOverSuccess: '方案下線成功',
    associatedApplets: '關聯小程序',
    pleaseAssociatedApplets: '請選擇關聯小程序',
    associatedAppletsName: '關聯小程序名稱',
    pleaseWriteSmsCode: '請填寫短信編碼',
    smsCodeTip: '提示：需勾選短信發送節點並正確填寫短信編碼，短信發送才會生效',
    customCardId: '自定義卡券編號',
    cardCodeLength: '編碼流水號長度',
    cardCodeStart: '編碼流水號起始值',
    cardAfter: '編碼後綴',
    expression: '表達式',
    noChinese: '編號前綴，不能輸入漢字',
    cardCodeLengthTip: '編碼流水號長度不能為空',
    cardCodeStartTip: '編碼流水號起始值不能為空',
    planCode: '方案編碼',
    pleasePlanCode: '請填寫方案編碼',
    planAppCode: '方案兌換碼',
    batchGoods: '批量關聯商品',
    pleaseRelatedGoods: '請選擇要關聯的商品',
    relateGoods: '關聯商品',
    noChooseCard: '未選擇卡券',
    successCount: '成功數量',
    repeatCount: '重複數量',
    cardBefore: '編號前綴',
    cardRepeatTip: '同一方案中，不能有重複卡券編號，重複時將跳過',
    noCardGenerate: '沒有卡券生成',
    exchangeProgress: '兌換進度',
    exchangeUserNotifyConfig: '兌換人小程序通知配置',
    receiverUserNotifyConfig: '收件人短信通知配置',
    deliveryDelay: '延遲發貨',
    appSmsTip: '提示：需勾選發送節點並選擇發送模板，小程序通知才會生效',
    appExpressSmsConfigTip: '提示：未選擇物流短信發送策略將按默認策略進行短信發送',
    activateTotalRate: '發放進度',
    activateRate: '激活進度',
    exchangeRate: '兌換進度',
    viewActivateRate: '查看進度',
    addActivate: '發放',
    appTips: '小程序溫馨提示',
    appTipsPlease: '請輸入小程序溫馨提示語',
    smsSignConfig: '短信簽名',
    relateRealStockConfig: '關聯繫統庫存',
    sellOutConfig: '售罄',
    relateRealStockTip: '兌換商品庫存取自系統庫存，下架/售罄將不能兌換該商品',
    sellOutTip: '標記售罄後將不能兌換該商品',
    geneSelectedAppCode: '已選擇卡券',
    geneAllAppCode: '全部卡券',
    importCard: '卡券導入',
    smsEnableConfig: '是否發送短信',
    genePlanAppCode: '生成方案碼',
    geneCardAppCode: '生成卡券兌換碼',
    addExchangeOrder: '添加兌換訂單',
    exchangeCard: '兌換券',
    exchangeVoucher: '代金券',
    addVoucher: '添加代金券方案',
    editVoucher: '編輯代金券方案',
    viewVoucher: '查看代金券方案',
    voucherOrderFreight: '兌換每單運費',
    freeFreightOrderAmount: '滿額包郵金額',
    voucherPriceType: '商品價格',
    voucherGoods: '可兌商品',
    goodsCategory: '分類',
    goodsCategoryName: '分類名稱',
    voucherCardAmount: '卡券面值',
    voucherCardUsedAmount: '已使用金額',
    voucherGoodsPrice: '默認價格',
    voucherGoodsCustomPrice: '自定義價格',
    addVoucherCategory: '添加分類',
    addVoucherGoods: '添加商品',
    deleteVoucherGoodsTips: '確認刪除該商品',
    alterCategory: '調整分類',
    alterVoucherGoodsCategoryTips: '確認調整商品分類',
    voucherCard: '代金券',
    isRequired: '必選商品',
    setIsRequired: '設為必選',
    unSetIsRequired: '取消必選',
    selectGoodsPlease: '請選擇商品',
    goodsTitle: '商品名稱',
    goodsSpecs: '商品規格',
    orderUnitPrice: '單價',
    quantity: '數量',
    subtotal: '小計',
    exchangeCardOrderOrder: '兌換券訂單',
    exchangeVoucherOrderOrder: '代金券訂單',
    warehouseDeliveryOrder: '倉庫發貨單',
    outDeliveryOrder: '委外發貨單',
    inventoryOutInfo: '出庫單列表',
    welfareReturnInfo: '售後',
    detailInfo: '訂單明細',
    cancelOrder: '取消訂單',
    orderReturn: '訂單退款',
    notAudit: '待審核',
    refunded: '已退款',
    rejected: '已駁回',
    returnOrderId: '售後單號',
    returnType: '退換類型',
    returnAmount: '退款金額',
    returnReason: '退貨原因',
    rejectReason: '駁回原因',
    returnStatus: '售後狀態',
    returnQueryKeywords: '訂單號，售後單號',
    viewReturn: '售後詳情',
    rejectReturnTips: '是否駁回該售後申請',
    passReturnTips: '通過該售後申請，通過後將會退換用戶兌換卡券或支付金額',
    exportOrderTitle: '導出兌換訂單',
    exportWelfareOutOrderTitle: '導出卡券委外發貨單',
    goodsCategoryPlease: '請輸入分類名稱',
    notInventoryOut: '虛擬發貨',
    inventoryOut: '倉庫發貨',
    notInventoryOutTips: '虛擬發貨：僅標記發貨狀態，需上傳快遞單號，無需操作倉庫出庫/委外發貨',
    inventoryOutTips: '倉庫發貨：需操作倉庫出庫/委外發貨',
    purchaseOut: '委外發貨',
    prepareGoods: '備貨',
    orderQuantity: '訂單數量',
    deliveryQuantity: '發貨數量',
    hasDeliveryQuantity: '已發貨數量',
    notDeliveryQuantity: '未發貨數量',
    hasInventoryOutQuantity: '已出庫數量',
    deliveryCode: '發貨單編碼',
    addDelivery: '添加發貨',
    editDelivery: '編輯發貨單',
    viewDelivery: '查看發貨單',
    deliveryGoodsEmpty: '請選擇發貨商品',
    deliveryGoodsIsOver: '訂單超額發貨',
    deliveryQueryKeywords: '訂單編碼 發貨單編碼 方案 收件人',
    voucherPageConfig: '首頁設置',
    pageTopCategoryConfig: '首頁顯示商品分類',
    pageSlideCategoryConfig: '首頁顯示商品',
    editVoucherCategory: '編輯分類',
    importOneGoods: '單個引入',
    importMultiGoods: '批量引入',
    selectImportGoodsEmpty: '請選擇要引入商品',
    batchImportMallGoodsTips: '確認引入商城商品，操作將在後臺進行，請稍後刷新查詢',
    batchImportMallGoodsResult: '共{0}條記錄，成功：{1}條，跳過：{2}條',
    appBackgroundConfig: '卡券背景',
    customBackgroundImage: '自定義背景圖片',
    backgroundImagePlease: '請上傳背景圖片',
    customProductBackgroundImage: '自定義產品列表背景圖',
    customLogingroundImage: '自定義登錄背景圖',
    customLoginBackgroundTips: '背景尺寸為750*1624',
    customBackgroundTips: '背景尺寸為750*500',
    customAppCode: '兌換碼',
    geneBackgroundCode: '生成兌換碼',
    noCategory: '未分類',
    welfareStockIncludePurchase: '可用庫存是否包含在途',
    plesaseSelectWelfareOrder: '請選擇兌換訂單',
    deliveryWarehouse: '發貨倉庫',
    pleaseSelectNotPreparedOrder: '請選擇待備貨訂單',
    pleaseSelectWaitDeliveryOrder: '請選擇倉庫發貨的待發貨訂單',
    allOrderGoodsDelivery: '所有訂單商品已發貨',
    voucherPriceTypePlease: '請選擇商品價格',
    baseInfo: '基本信息',
    requiredGoodsCheck: '必選商品校驗規則',
    perOrderRequireGoodsCheck: '每單校驗',
    firstOrderRequireGoodsCheck: '首單校驗',
    addressLimitConfig: '地址攔截設置',
    welfareExchangeActivateAddressLimitTips: '攔截提示語',
    addLimitAddress: '添加攔截地址',
    addressLimit: '攔截地址',
    addressLimitArea: '攔截區域',
    addressLimitKeywords: '詳細地址關鍵字（多個用，隔開）',
    addressLimitAreaIdError: '請選擇攔截區域',
    addressLimitKeywordsError: '請輸入攔截地址關鍵字',
    privacyAgreement: '隱私協議',
    activate: {
      activateInfo: '發放訂單',
      activateDetailsInfo: '卡券明細',
      queryStatus: '狀態',
      queryKeywords: '訂單編碼、客戶名稱、方案名稱',
      activateCode: '訂單編碼',
      customer: '客戶',
      exchangeDateRange: '兌換起止時間',
      planName: '方案名稱',
      enableStatus: '狀態',
      activateStatus: '激活狀態',
      planAppCode: '方案兌換碼',
      cardAppCode: '卡券兌換碼',
      cardQuantity: '卡券數量',
      activateRate: '激活進度',
      exchangeRate: '兌換進度',
      activateDate: '發放日期',
      customerPlease: '請選擇客戶',
      contact: '聯系人',
      contactPlease: '請輸入聯系人',
      dateRange: '兌換起止時間',
      dateRangePlease: '請選擇兌換起始日期',
      checkExchangeUserConfig: '是否校驗兌換人信息',
      collectExchangeUserConfig: '是否收集兌換人信息',
      operator: '經辦人',
      notCollect: '不收集',
      collect: '收集',
      notCheck: '不校驗',
      checkAfterCodeScanning: '掃碼後校驗',
      checkAfterOrdered: '下單後校驗',
      jobNumber: '工號',
      userName: '姓名',
      phoneNumber: '手機號碼',
      email: '郵箱',
      checkOrCollectUserInfoConfig: '校驗/收集兌換人信息',
      cardPlanPlease: '請選擇卡券方案',
      cardPlan: '卡券方案',
      selectCard: '選擇卡券',
      selectCardQuantity: '隨機選擇',
      selectCardTitle: '請選擇卡券',
      saveAndActivate: '保存並生效',
      notEnable: '未生效',
      enabled: '已生效',
      notActivate: '未激活',
      activated: '已激活',
      notExchange: '未兌換',
      exchanged: '已兌換',
      exchangeStatus: '兌換狀態',
      geneCardAppCode: '生成兌換碼',
      geneCardAppCodeTips: '兌換碼正在後臺生成，請稍後查看',
      activateCard: '激活',
      deactivateCard: '取消激活',
      activateCardTips: '確認激活該卡券，卡券激活後用戶可在小程序兌換',
      deactivateCardTips: '確認取消激活卡券，取消激活後用戶在小程序只能瀏覽不能兌換',
      activateSuccessTips: '卡券激活成功',
      deactivateSuccessTips: '取消激活成功',
      addActivate: '添加發放訂單',
      editActivate: '編輯發放訂單',
      viewActivate: '查看發放訂單',
      baseInfo: '基本信息',
      cardInfo: '卡券信息',
      revokeTips: '確認撤銷該發放訂單',
      deleteTips: '確認刪除該發放訂單',
      cardInfoQuery: '卡券編號',
      detailsQuery: '客戶名稱、方案信息、卡券信息',
      activateAll: '全部激活',
      deactivateAll: '全部取消激活',
      activateAllTips: '激活卡券發放單下全部卡券，將會跳過已激活卡券',
      deactivateAllTips: '卡券發放單內卡券全部取消激活，存在已兌換卡券時將不能進行取消操作',
      checkOrCollectUserTips: '保存卡券發放訂單後，請在查看頁面導入兌換人信息',
      importUser: '導入校驗信息',
      attentionTipsTitle: '校驗信息導入註意事項',
      userImportResult: '共{0}條記錄，成功：{1}條，跳過：{2}條',
      deleteUserTips: '確認刪除該校驗信息',
      clearAllUserTips: '確認清空兌校驗信息',
      exchangeUserInfo: '校驗信息',
      clearAll: '清空',
      checkInfoConfigEmpty: '請勾選要校驗/收集的兌換人信息',
      activateConfig: '訂單設置',
      deleteSelected: '已選擇',
      notSend: '不發送',
      sameAsPlan: '同方案設置',
      selfConfig: '自定義',
      activateCardEnable: '是否激活卡券',
      replaceCard: '替換',
      deleteCardTips: '確認刪除卡券',
      addActivateCard: '添加卡券',
      viewPlanName: '顯示名稱',
      userSignatureEnable: '訂單提交附加手繪簽名',
      checkPhoneNumber: '是否憑手機號領取',
      importCheckPhoneNumber: '導入領取手機號',
      updateExchangePhone: '修改領取手機號',
      exchangePhoneNumber: '領取手機號'
    },
    order: {
      withdrawTip: '確定將訂單撤回到待發貨嗎',
      withdraw: '撤回',
      logistic: '物流信息',
      code: '訂單編碼',
      queryStatus: '訂單狀態',
      startDate: '兌換日期起',
      endDate: '兌換日期止',
      queryKeyword: '卡券編號、卡券密碼、收件人、電話、商品',
      queryKeywords: '卡券編號、方案名稱、收件人、電話、商品',
      queryKeywordissus: '收件人、電話、商品',
      receiverName: '收件人',
      receiverPhone: '收件電話',
      receiveAddress: '收件地址',
      areaIds: '所屬地區',
      goodsTitle: '商品',
      orderStatus: '狀態',
      confirm: '確認',
      delivery: '發貨',
      toBeConfirm: '待確認',
      waitDelivery: '待發貨',
      confirmed: '已確認',
      delivered: '已發貨',
      received: '已收貨',
      completed: '已完成',
      complete: '完成',
      completeTip: '訂單是否確認完成',
      distribute: '派件中',
      expressCompany: '快遞公司',
      expressNumber: '快遞單號',
      addLogistics: '添加物流',
      changeGoods: '修改商品',
      editOrder: '編輯訂單',
      viewOrder: '查看訂單',
      orderDelivery: '訂單發貨',
      comfireOrder: '確認訂單',
      confirmOrderTips: '是否確認該兌換訂單有效',
      deleteOrderTips: '是否刪除該兌換訂單',
      selectGoods: '選擇商品',
      deliveryTime: '發貨時間',
      payType: '支付方式',
      voucherPay: '代金券支付',
      wxPay: '微信支付',
      orderTotal: '訂單總額',
      voucherDeductAmount: '代金券支付金額',
      paidAmount: '微信支付金額',
      merchantOrderCode: '支付商戶訂單號',
      paymentOrderCode: '微信支付交易號',
      createTime: '創建時間',
      orderId: '訂單編碼',
      importOrderExpress: '快遞信息導入',
      orderExpressImport: '訂單導入快遞信息',
      importData: '數據導入',
      dataMap: '數據映射',
      previewData: '數據預覽',
      previewResult: '導入結果',
      excelFile: 'Excel 文件',
      attentionTipsTitle: '訂單快遞信息導入註意事項',
      downloadExpressTemplate: '下載快遞公司模板',
      attentionTips2: '建議使用官方Excel模板導入數據',
      attentionTips3: '上傳EXCEL後，請準確填寫標題行號和數據起始行號',
      attentionTips4: '請準確填寫數據映射關係並檢查數據預覽是否相符',
      excelTitleRowNumber: '標題行號',
      excelDataRowNumber: '數據起始行號',
      nextStep: '下一步',
      lastStep: '上一步',
      targetTitleName: '映射對象',
      sourceTitleName: '導入列標題',
      serialNumber: '序號',
      success: '成功',
      fail: '跳過',
      executeResultInfo: '共{0}條記錄，成功：{1}條，跳過：{2}條',
      importStatus: '導入狀態',
      importErrorReason: '跳過原因',
      batchSetExpressCompany: '批量設置快遞公司',
      config: '設置',
      rollBack: '恢復',
      importModeConfig: '導入模式',
      coverMode: '覆蓋模式',
      appendMode: '追加模式',
      skipEmptyExpress: '跳過快遞單號為空數據',
      notPrepared: '待備貨',
      prepareRangeOperate: '批量備貨',
      selectedOrderRange: '勾選訂單',
      queryOrderRange: '查詢範圍訂單',
      preprareOrderTips: '確認訂單已備貨',
      queryExpressStatus: '快遞狀態',
      expressInfo: '快遞信息',
      viewExpressDetails: '查看快遞信息',
      notPaid: '待支付',
      refunded: '已退款',
      canceled: '已取消',
      outDelivery: '委外發貨',
      exchangeCardOrderOutDeliveryTips: '確認訂單轉委外發貨，確認後將生成委外需求',
      printOrder: '打印兌換訂單',
      orderReturnStatus: '售後狀態',
      afterSalesOngoing: '售後中',
      productCode: '產品編碼',
      productName: '產品名稱',
      productModel: '產品規格',
      productUnit: '單位',
      quantity: '數量',
      orderUnitPrice: '單價',
      subtotal: '小計',
      exchangeTime: '兌換時間',
      cardType: '訂單類型',
      detailQueryKeywords: '產品編碼，產品名稱，訂單編號',
      batchDelivery: '批量發貨',
      userSignature: '兌換人手繪簽名'
    },
    config: {
      logo: '小程序logo',
      logoPlease: '請上傳小程序logo',
      name: '名稱',
      namePlease: '請填寫小程序名稱',
      describe: '描述',
      addConfig: '添加小程序設置',
      editConfig: '編輯小程序設置',
      viewConfig: '查看小程序設置',
      servicePhone: '客服電話'
    }
  },
  member: {
    config: {
      commonConfig: '通用參數',
      customerBindExpireRemindPrefix: '會員客戶綁定到期前',
      customerBindExpireRemindSubfix: '天提醒，向會員手機發送短信提醒',
      distributionConfig: '分銷設置',
      memberDistributionEnable: '會員分銷',
      memberDistributionRelationAutoTransfer: '分銷關系自動轉移',
      memberPerformanceCalcLevel: '業績計算層級',
      memberDistributionEnableTips: '如果沒有開啟會員分銷，會員分享的推薦關系將自動轉移到該會員的推薦客戶上',
      memberDistributionRelationAutoTransferTips: '若會員存在分銷關系，當會員綁定客戶時，是否自動將會員的分銷關系綁定到客戶上',
      memberPerformanceCalcLevelTips: '0：表示該會員業績僅計算自己。1：表示將本身和直接下級的交易作為自己的業績。以此類推。-1：表示不限層級',
      distributionDisable: '關閉分銷',
      distributionRelationAutoTransfer: '分銷關系是否自動向上轉移',
      distributionDisableTips: '關閉分銷，則該會員等級不會綁定分銷關系',
      distributionRelationAutoTransferTips: '如果自動向上轉移，當該會員沒有分銷權限時，會員的推薦關系將轉移到該會員的上級',
      performanceCalcLevelTips: '0：表示該會員業績僅計算自己。1：表示將本身和直接下級的交易作為自己的業績。以此類推。-1：表示不限層級',
      customerPerformanceCalcLevelTips: '0：表示該客戶業績僅計算自己。1：表示將本身和直接下級的交易作為自己的業績。以此類推。-1：表示不限層級',
      performanceCalcLevel: '業績計算層級',
      distributionRewardConfig: '會員升級分銷獎勵',
      memberUpgradeLevelName: '會員升級等級',
      firstDistributionReward: '一級分銷獎勵',
      secondDistributionReward: '二級分銷獎勵',
      agreement: '升級協議',
      memberPointAutoTransferCustomer: '積分轉移',
      memberPointAutoTransferCustomerTips: '會員綁定客戶後，將會員全部積分轉移到客戶'
    },
    memberLevel: '會員等級',
    enableMember: '設為默認會員',
    defaultMember: '默認會員',
    list: {
      keyWords: '關鍵字',
      userSource: '用戶來源',
      userLevel: '會員等級',
      labelSet: '標籤組',
      registrationTime: '註冊時間',
      blackList: '黑名單',
      member: '會員',
      integral: '積分',
      memberIntegral: '會員/積分',
      balance: '餘額',
      clinchDeal: '成交',
      coupons: '優惠券',
      nickName: '昵稱',
      phone: '手機號碼',
      order: '成交訂單數',
      money: '成交金額',
      edit: '會員編輯',
      view: '會員查看',
      startDate: '註冊時間起',
      endDate: '註冊時間止',
      name: '名稱',
      level: '會員等級',
      yes: '是',
      no: '否',
      levelTip: '請選擇會員等級',
      classify: '分類',
      relatedPlatformClients: '關聯平臺客戶 ',
      connectedPlatformSuppliers: '關聯平臺供應商 ',
      shop: '小店id',
      customerManage: '服務經理',
      bindTime: '簽約時間',
      expire: '有效期止',
      indefinite: '無期限',
      customerSort: '客戶分類',
      paymentMonth: '掛賬月結 ',
      balancePayment: '餘額支付',
      distributionRecommender: '分銷推薦人',
      administrator: '是否为管理員',
      superior: '上級（推薦人）',
      referMember: '推薦會員',
      referCustomer: '推薦客戶',
      subordinate: '下級',
      applyCustomerName: '申請客戶名稱',
      customerType: '客戶類型',
      chooseCustomer: '請先選擇關聯客戶',
      syncCustomerSuperior: '同步修改客戶上級為【上級（推薦人）】',
      showAdminOnly: '只顯示管理員',
      memberCustomerUnbind: '解除綁定',
      unbindTips: '確認解除該會員的客戶綁定關係,解除綁定後會員將不在享有客戶優惠',
      isMemberExpire: '會員已到期',
      queryKeywords: '會員昵稱 會員手機號 客戶名稱',
      memberKeywords: '會員',
      refererKeywords: '推薦人',
      setMemberBlack: '加入黑名單',
      removeMemberBlack: '解除黑名單',
      setMemberBlackTips: '確認將該會員加入黑名單',
      removeMemberBlackTips: '確認將該會員移除黑名單',
      distributionMemberBindCustomerTips: '下級分銷會員重新綁定到當前客戶',
      distributionMemberBind: '會員分銷關系轉移到客戶',
      memberPointGive: '積分贈送',
      receiverMember: '贈送會員',
      givePointAmount: '贈送積分',
      givePointAmountPlease: '請輸入贈送積分',
      receiverMemberPlease: '請選擇贈送會員',
      memberVoucherGive: '代金券贈送',
      isDefault: '是否默認',
      autoChangeCustomer: '同步將小程序會員身份切換至默認客戶',
      importVoucherQuantity: '導入數量',
      newPassword: '新密碼',
      confirmNewPassword: '確認密碼',
      newPasswordPlease: '請輸入密碼',
      passwordNotEqual: '新密碼和確認密碼不一致',
      changePasswordTips: '確認修改會員密碼',
      changePassword: '修改密碼'
    },
    integral: {
      startDate: '開始時間',
      endDate: '結束時間',
      queryContent: '業務編號、業務單號、會員名稱',
      businessType: '業務類型',
      businessId: '業務編號',
      businessCode: '業務單號',
      memberName: '會員名稱',
      transactionType: '交易類型',
      transactionAmount: '交易積分',
      createTime: '交易時間',
      pointRecharge: '充值單號',
      mallOrder: '商城訂單號',
      platformAdd: '後臺添加',
      recharge: '積分充值',
      upgrade: '升級獎勵',
      mallConsume: '商城消費',
      payOrderReward: '下單獎勵',
      upgradeFirstOrder: '升級首單獎勵',
      otherMemberGive: '會員積分互贈',
      distributionReward: '分銷推薦獎勵',
      orderRefundReturn: '訂單退款返還',
      distributionPoint: '發放積分',
      memberPointTransferCustomer: '會員積分轉客戶積分'
    },
    orderCode: '訂單編號',
    user: '用戶',
    customerName: '客戶名稱',
    orderTime: '下單時間',
    orderMoney: '訂單金額',
    orderStatus: '訂單狀態',
    consumptionAmount: '消費滿足金額',
    reductionAmount: '減免金額',
    orderList: '訂單清單',
    couponsList: '優惠券清單',
    voucherList: '代金券清單',
    memberInfo: '會員資訊',
    integralInfo: '積分資訊',
    binding: {
      memberName: '申請會員名稱',
      customerName: '綁定客戶名稱',
      bindingStatus: '綁定狀態',
      applyTime: '申請時間',
      binding: '綁定',
      unbundling: '解綁',
      bindingSuccess: '綁定成功',
      unbindingSuccess: '解綁成功',
      noBinding: '未綁定',
      beBinding: '已綁定',
      applicationCode: '申請編號',
      rejectionReason: '駁回理由',
      beReject: '已駁回',
      reject: '駁回',
      customerCode: '客戶編碼',
      audit: '審核',
      waitAudit: '待審核',
      through: '通過',
      memberInfo: '會員資訊',
      haveUnbundling: '以解綁 ',
      applicationCustomerName: '申請客戶名稱',
      auditStatus: '審覈狀態',
      pleaseChooseCustomer: '請選擇關聯客戶',
      throughed: '已通過'
    },
    discountInterest: '折扣權益',
    noDiscountSet: '不設置折扣',
    priceType: '指定價格類型',
    theCustom: '自訂',
    discountText: '當前等級可享受',
    discount: '折',
    discountTip: '提示：折扣在0.1 - 9.9之間',
    allMember: '全部會員',
    queryMember: '查詢範圍會員',
    selectedMember: '已選擇會員',
    checkMember: '已勾選會員',
    batchAdjustmentGrade: '批量調整會員等級',
    batchDistributionPoint: '批量發放積分',
    pleaseNewMemberLevel: '請選擇調整後的會員等級',
    pleaseEnterCorrectPoints: '請輸入正確發放積分',
    pleaseAdjustMember: '請選擇要調整的會員',
    pleaseDistributionMember: '請選擇要發放的會員',
    isDetermineAdjust: '是否確定調整？',
    isDetermineDistribution: '是否確定發放？',
    adjustSuccess: '調整成功',
    distributionSuccess: '發放成功',
    noMemberAdjust: '沒有會員可以調整',
    noMemberDistribution: '沒有會員可以發放',
    batchAdjustmentExpireTip: '請選擇調整後的客戶綁定有效期，如果不填寫則有效期為永久',
    batchAdjustmentExpire: '批量調整有效期',
    upgradeConditions: '升級條件',
    orderAmount: '訂單金額',
    orderNumber: '訂單數量',
    buySpecifiedGoods: '購買指定商品',
    noUpgrade: '不自動升級',
    completeOrderAmount: '完成訂單金額',
    completeOrderNumber: '完成訂單數量',
    yuan: '元',
    dan: '單',
    mang: '滿',
    upgradeTip: '滿足升級條件後可升級為此會員等級',
    orderAmountTip: '升級條件請填寫訂單金額',
    orderNumberTip: '升級條件請填寫訂單數量',
    buySpecifiedGoodsTip: '升級條件請選擇指定商品',
    addSpecifiGoods: '添加指定商品',
    noUpgradeTip: '您設置的等級不會自動升級到該等級下，請謹慎設置',
    orderPaidAmount: '支付金額',
    orderPaidAmountTipPrefix: '完成支付金額',
    upgradeContent: '權益描述',
    noReward: '無',
    rewardPoint: '積分',
    rewardCoupon: '優惠券',
    rewardVoucher: '代金券',
    memberExpiration: '會員有效期',
    permanentValidity: '永久有效',
    effectiveDays: '指定天數有效',
    expirationTip: '天後更新為：',
    upgradeReward: '升級獎勵',
    rewardPointPrefix: '升級後可獲得',
    selectCoupon: '選擇優惠券',
    selectVoucher: '選擇代金券',
    rewardType: '獎勵類型',
    rewardItemName: '獎勵名稱',
    firstUpgradeReward: '升級首單獎勵',
    upgradeFirstOrderReward: '獎勵內容',
    firstRewardPointPrefix: '升級後首單完成可獲得',
    replaceSpecificGoods: '替換商品',
    addSpecificGoods: '添加商品',
    specificGoodsName: '指定商品名稱',
    upgradeRewardPointPlease: '請填寫升級獎勵積分',
    upgradeRewardCouponPlease: '請選擇升級獎勵優惠券',
    upgradeRewardVoucherPlease: '請選擇升級獎勵代金券',
    upgradeFirstRewardPointPlease: '請填寫升級首單獎勵積分',
    upgradeFirstRewardCouponPlease: '請選擇升級首單獎勵優惠券',
    upgradeFirstRewardVoucherPlease: '請選擇升級首單獎勵代金券'
  },
  order: {
    noNeedReturn: '无需退货',
    noReceivedGoods: '未收到貨',
    receivedGoods: '已收到貨',
    pending: '待發貨',
    obligation: '待付款',
    waitReceiving: '待收貨',
    completed: '已完成',
    closed: '已關閉',
    allOrders: '全部訂單',
    payWays: '支付方式',
    orderTime: '下單時間',
    goodsType: '商品類型',
    keyWords: '關鍵字',
    goods: '商品',
    unitPrice: '單價',
    quantity: '數量',
    buyer: '買家',
    paidAmount: '實付款',
    paymentType: '支付',
    deliveryType: '配送',
    consignee: '收貨人',
    consigneeAddress: '收貨地址',
    phone: '手機號碼',
    area: '所屬地區',
    address: '詳細地址',
    disposeWay: '處理方式',
    courierCompany: '快遞公司',
    writeCourierCompany: '請選擇快遞公司',
    courierNumber: '快遞單號',
    writeCourierNumber: '請輸入快遞單號',
    singlePackageDeliver: '整單包裹',
    multiplepackages: '分包裹發貨',
    deliverySuccess: '發貨成功',
    paySuccess: '付款成功',
    shippingSuccess: '收貨成功',
    cancelSuccess: '取消成功',
    cancelSuccessTip: '訂單取消退款成功，前往財務管理-付款單進行確認',
    commodityTitle: '商品標題',
    buyers: '買家',
    receiptorInfo: '收件人信息',
    realPayment: '金額',
    payment: '支付',
    delivery: '配送',
    operation: '操作',
    temporarilyNoData: '暫無數據',
    theOrderTime: '下單時間',
    orderCode: '訂單編號',
    copy: '複製',
    invoice: '發貨單',
    orderDetails: '訂單詳情',
    lookMore: '查看更多',
    remark: '備註',
    real: '實',
    theFreight: '含運費:',
    weChatPay: '微信支付',
    courier: '快遞',
    stayPayment: '待付款',
    stayDelivery: '待發貨',
    stayGoods: '待收貨',
    partDelivery: '部分發貨',
    confirmDelivery: '確認發貨',
    confirmHasDelivery: '確認收貨',
    confirmHasDeliveryAndInventoryOut: '確認收貨並生成出庫單',
    hasInventoryConfirmDeliveryTips: '確認該訂單已收貨，將訂單狀態改為已完成嗎？',
    notInventoryConfirmDeliveryTips: '該訂單未完成出庫操作，可能會導致庫存余額不準確，是否確認收貨？',
    rejectReason: '駁回理由',
    rejectedSuccess: '駁回成功',
    modifyShippingAddress: '修改收件地址',
    confirmTheGoods: '確認收貨',
    confirmpayment: '確認付款',
    closeOrder: '關閉訂單',
    buyerRemark: '買家備註',
    goodsNumberTip: '商品總數量',
    lookTip: '可前往商品詳情查看',
    orderChangePrice: '訂單改價',
    refund: '退款',
    buyerTheOrder: '買家下單',
    theBuyerPayment: '買家付款',
    merchantShipping: '商家發貨',
    orderComplete: '訂單完成',
    orderClosed: '訂單關閉',
    theOrderStatus: '訂單狀態',
    deliveryInformation: '發貨信息',
    inDistribution: '配送方式',
    nameConsignee: '收貨人',
    contact: '聯繫方式',
    logisticsSingleNumber: '物流單號',
    theParcel: '包裹',
    invoiceInformation: '發票資訊',
    member: '會員',
    orderType: '訂單類型',
    ordinaryOrder: '普通訂單',
    TransactionNumber: '交易單號',
    sellerRemark: '賣家備註',
    commodityInformation: '商品資訊',
    virtualDeliveryGoodsInfo: '虛擬發貨信息',
    externalTransactionNumber: '外部交易單號',
    specs: '規格',
    salePrice: '售賣價',
    subtotal: '小計',
    status: '狀態',
    deliveryStatus: '出貨狀態',
    orderInfo: '訂單資訊',
    goodsTitle: '商品標題',
    writeReceiptorName: '請填寫收貨人',
    writeReceiptorPhone: '請填寫手機號碼',
    writeAddress: '請填寫詳細地址',
    writeArea: '請選擇所屬地區',
    toDelivery: '已發貨',
    noDelivery: '未發貨',
    choose: '選擇',
    yesChoose: '已選擇',
    changePrice: '改價',
    determine: '確定',
    cancel: '取消',
    enterNumber: '請輸入價格',
    tip: '提示',
    businessmenNote: '商家備註',
    closeReason: '關閉原因',
    deliveryContent: '確認此單已收到貨了嗎 ?',
    payContent: '確認此單已付款嗎？',
    confirmClose: '確認關閉',
    cashDelivery: '貨到付款 ',
    tobeConfirmed: '待確認',
    confirme: '確認',
    through: '通過',
    confirmThrough: '確認通過',
    rejected: '駁回 ',
    startDate: '下單時間起',
    endDate: '下單時間止',
    keyPlaceholder: '訂單號 姓名 電話號碼 商品名稱',
    activistStatus: '維權狀態',
    copySuccess: '複製成功',
    comfirmeContent: '此訂單確認進行了"線下支付"嗎',
    rejectedContent: '此訂單確認駁回嗎',
    confirmRejected: '確認駁回',
    friendlyTip: '友情提示',
    placeholderText: '請輸入內容',
    moneyTip: '您未輸入金額',
    deliveryTip: '請選擇發貨的包裹',
    goodsTip: '選擇需要發貨的商品',
    logisticsInfo: '物流信息',
    totalAmount: '訂單金額',
    deliveryInfo: '出庫信息',
    inventory: '出庫單',
    time: '時間',
    payMoney: '支付金額',
    second: '秒',
    viewLogisticsInfo: '查看物流信息',
    refresh: '刷新',
    realGoods: '實',
    virtualGoods: '虛',
    el: '單號或代碼錯誤',
    noRoute: '暫無軌跡',
    expReceive: '快遞收件',
    expWay: '在途中',
    expErrorPackage: '問題件',
    expTroublePackage: '疑難件',
    expBack: '退回',
    expSigned: '簽收',
    expBackSigned: '退件簽收',
    sending: '派送中',
    unknown: '未知狀態',
    remainingNumber: '剩餘數量',
    deliveryNumber: '發貨數量',
    PleaseEnterNumber: '請輸入數位 ',
    PleaseEnterDeliveryNumber: '請填寫發貨數量',
    residueQuantityInsufficient: '商品剩餘數量不足',
    superDelivery: '超發',
    beenCancelled: '已取消',
    confirmShipment: '確認發貨嗎 ',
    di: '第',
    hang: '行“',
    superDeliveryTip: '”商品超發, 是否繼續?',
    pick: '自提',
    weChat: '微信支付',
    delivetyPay: '貨到付款',
    shopPick: '到店自提',
    competed: '已完成',
    readyComplete: '備貨完成 ',
    verification: '確認提貨',
    checkPick: '查看提貨碼 ',
    pickCode: '提貨碼：',
    pleasePickCode: '請輸入提貨碼',
    pickSuccess: '提貨成功',
    pickError: '提貨失敗',
    StockSuccess: '確認完成備貨嗎？',
    stockCode: '備貨成功，提貨碼為：',
    payCertificate: '支付憑證',
    offlinePayment: '線下轉帳',
    printOrderDelivery: '列印商城發貨單',
    printOrder: '打印商城訂單',
    mallOrderCode: '商城訂單號',
    afterSalesProtection: '售後/維權 ',
    refundCompleted: '已退款完成 ',
    afterSalesDeail: '售後詳情',
    afterSalesApplicationTime: '售後申請時間 ',
    activisting: '維權中',
    deliveryDeail: '發貨單詳情',
    deliverysTip: '選擇的商品中，至少選擇一件數量進行發貨',
    activisted: '維權完成',
    selfDelivery: '倉庫備貨',
    selfDeliverySubmit: '提交備貨，待倉庫處理',
    orderCloseReason: '關閉原因',
    changeTip: '優惠金額不能超過訂單金額,且應付金額不能為0',
    orderTotalAmount: '訂單總額',
    discountAmount: '優惠金額',
    pointDeductAmount: '積分抵扣',
    pointAmount: '積分',
    payableAmount: '應付金額',
    paidAmounts: '實付金額',
    deductibleAmount: '定向预付',
    changeTipNull: '請輸入大於0的優惠金額',
    changeText: '確認修改訂單價格嗎?',
    mang: '滿',
    couponsDetails: '優惠使用',
    deductionUse: '抵扣使用',
    emptyText: '暂无信息',
    preferentialPrice: '優惠單價',
    goodsAmount: '商品總額',
    freight: '運費',
    goodsDiscountAmount: '商品優惠金額',
    freightDiscountAmount: '運費優惠金額',
    adjustGoodsAmount: '調整商品總額',
    adjustFreight: '調整運費金額',
    deliveryTips: '發貨數量大於商品數量',
    giftChildGoods: '禮盒子件商品',
    productName: '产品名称',
    orderOverDelivery: '訂單已超額發貨，請檢查確認發貨信息',
    takeDelivery: '確認收貨',
    takeDeliveryTip: '確認該訂單已收貨，將訂單狀態改為已完成嗎？ ',
    takeDeliverySuccess: '確認收貨成功',
    takeDeliverySelfPickupTip: '該訂單是否確認提貨？',
    closeOrderReturn: '關閉訂單並退款',
    comfireCloseOrderReturn: '確認關閉並退款',
    isOutDelivery: '是否委外發貨',
    outRequireCode: '委外需求編碼',
    outOrderCode: '委外訂單編碼',
    errorExpressNumber: '單號或代碼錯誤',
    exportMallOrder: '商城訂單導出',
    purchaser: '下單人',
    addReturn: '退換貨',
    addReturnTitle: '添加退換貨',
    noInventoryInfo: '暫無出庫信息',
    orderDeliveryCode: '商城發貨單',
    inventoryStatus: '出庫狀態',
    outOrderStatus: '委外單狀態',
    voucherDeductAmount: '代金券抵扣',
    refundPartAmount: '訂單部分退款',
    refundAllAmount: '訂單全額退款',
    buyerDelete: '買家已刪除',
    addFreeGoods: '贈品',
    exchangeGoods: '換品',
    addFreeGoodsTitle: '添加贈品',
    selectSku: '選擇商品',
    addExchangeGoodsTitle: '訂單換品',
    orderGoods: '訂單商品',
    exchangeQuantity: '換品數量',
    orderExchangeAmount: '原訂單更換商品合計',
    newExchangeAmount: '新品合計',
    retailPrice: '零售價',
    memberPrice: '會員價',
    exchangeUnitPrice: '換品單價',
    memberSetttlementPrice: '結算價',
    autoAdjustPrice: '調整單價',
    confirmExchangeGoods: '確認進行訂單換品，換品後需重新操作發貨',
    confirmExchangeGoodsLessAmountGeneReturn: '更換商品與原單商品存在價差，退差價將同步生成售後單，換品後需重新操作發貨',
    confirmExchangeGoodsLessAmountNotReturn: '更換商品與原單商品存在價差，如需退差價勾選退差價或手動操作售後進行差價退款，換品後需重新操作發貨',
    confirmExchangeGoodsOverAmount: '更換商品比原單商品貴，是否確認更換',
    confirmAddFreeGoods: '確認添加贈品',
    freeGoodsPlease: '請選擇贈品',
    exchangeGoodsPlease: '請選擇訂單商品進行更換',
    newExchangeGoodsPlease: '請選擇更換的新品',
    newExchangeRealAmount: '新品會員價合計',
    exchangeGoodsAmountLessThen: '原訂單更換商品合計大於新品合計，請調整新商品單價',
    exchangeGoodsAmountGreatThen: '原訂單更換商品合計小於新品合計, 請調整商品單價',
    exchangeGoodsDifferenceAmount: '換品價差',
    isReturnDifferenceAmount: '是否生成價差售後單',
    editReturnTitle: '審核售後單',
    updateWarehouse: '發貨倉庫',
    updaetWarehouseTips: '請調整商品的發貨倉庫，調整後將影響系統可用庫存',
    batchUpdateWarehouse: '批量設置',
    orderDeliveryType: '發貨方式',
    queryOrderRange: '訂單範圍',
    customerOrder: '客戶訂單',
    memberOrder: '會員訂單',
    importOrderExpress: '訂單發貨導入',
    warehouseMap: '倉庫映射',
    expressCompanyMapTips: '無需快遞公司映射請直接跳轉下一步',
    warehouseMapTips: '無需倉庫映射請直接跳轉下一步',
    sourceWarehouse: '導入倉庫名稱',
    targetWarehouse: '映射倉庫名稱',
    importDeliveryConfig: '導入設置',
    batchSetExpressCompany: '批量設置快遞公司',
    batchSetWarehouse: '批量設置倉庫',
    batchSetDataPlease: '請選擇批量設置訂單',
    batchSetExpressCompanyPlease: '請選擇要設置的快遞公司',
    batchSetWarehousePlease: '請選擇要設置的倉庫',
    mapExpressCompanyPlease: '請選擇映射快遞公司',
    mapWarehousePlease: '請選擇映射倉庫',
    isPresale: '預售商品',
    customOrderCode: '自定義編碼',
    viewFreightCertificate: '運費憑證',
    updateFreightTips: '是否確定提交運費金額，提交之後將不允許修改',
    notConfirm: '未確認',
    hasPaid: '已支付',
    notPaid: '未支付',
    adjustPriceMode: '改價方式',
    adjustOrderAmount: '按訂單',
    adjustProductAmount: '按產品',
    oldOrderUnitPrice: '訂單原價',
    oldDiscountUnitPrice: '優惠單價（改價前）',
    newDiscountUnitPrice: '優惠單價（改價後）',
    oldSubtotal: '小計（改價前）',
    newSubtotal: '小計（改價後）',
    allGoods: '全部商品',
    includeFreight: '含運費',
    buyerHasReturn: '買家已退回',
    buyerReturnMethod: '買家退回方式',
    buyerReturnByExpress: '快遞',
    buyerReturnByOther: '其它',
    realGoodsAmount: '應付商品總額',
    realFreight: '應付運費',
    buyerHasReturnTips: '確認用戶已退回商品',
    paidAmountSum: '實付總額',
    customerManager: '業務員',
    deliveryOperator: '發貨經辦人'
  },
  expressSettlement: {
    cashByPost: '寄付現結',
    payOnDelivery: '到付',
    monthlyByPost: '寄付月結'
  },
  portal: {
    protocol: '小店入駐協議',
    storeSettings: '小店設置',
    storeAgreement: '小店協議',
    isAuditShopGoods: '是否審核小店商品',
    save: '保存',
    setting: '基礎設置',
    registerCode: '小店申請碼',
    generate: '生成',
    regenerate: '重新生成',
    promotionCode: '推廣碼',
    productReview: {
      audit: '審核',
      overrule: '駁回',
      goodsDetails: '詳情',
      reasonDismissal: '請填寫駁回原因',
      passAudit: '確定要通過審核嗎',
      notAudit: '未審核',
      pendingReview: '待審核',
      review: '已審核',
      reject: '已駁回',
      withdraw: '撤回',
      sureDelete: '確定要刪除嗎',
      sureWithdraw: '確定要撤回嗎',
      sureOverrile: '確定要駁回嗎'
    },
    manage: {
      portalLogo: '小店Logo',
      portalTitle: '小店名稱',
      nickName: '小店店主',
      createTime: '創建時間',
      auditDate: '審核時間',
      auditorName: '審核人',
      deleteTips: '是否確認【刪除】該門戶申請？',
      auditTips: '是否確定保存資料並【通過】審核？',
      portalStatus: '狀態',
      startDate: '申請時間起',
      endDate: '申請時間止',
      queryKeyword: '門戶名稱 證件號碼 手機號碼',
      addPortal: '添加門戶',
      editPortal: '編輯門戶',
      viewPortal: '查看門戶',
      notAudit: '待審核',
      audited: '已審核',
      saveData: '保存',
      saveAndAuditData: '保存並通過',
      reject: '駁回',
      rejectRemark: '駁回理由',
      phone: '手機號碼',
      pushdownGoods: '添加必選',
      portalGoodsManage: '必選商品',
      cancelPortalGoods: '取消必選',
      loginName: '登錄名',
      password: '密碼',
      newAccount: '新增小店帳號',
      goodsManage: '必選商品管理',
      subMallRequiredGoods: '小店必選商品',
      applicantName: '名稱',
      certificateNumber: '證件號碼',
      attachmentList: '身份證/營業執照',
      portalInfo: '門戶信息',
      portalGoodsInfo: '小店必選商品',
      applicantNameTips: '姓名/企業名稱',
      certificateNumberTips: '身份證號/統一社會信用代碼',
      phoneTips: '用來創建門戶帳號',
      rejectTips: '是否確認【駁回】該門戶申請',
      rowAuditTips: '是否確認審核【通過】該門戶申請',
      revokeTips: '是否確認【撤回】該門戶申請到待審核狀態',
      revoke: '撤回',
      deletePublicGoodsTips: '是否確定【移除】選中的商品，刪除後小店將不展示',
      deleteGoods: '移除',
      productSort: '產品分類',
      productBrand: '產品品牌',
      specifiedProduct: '指定產品',
      permissionsConfig: '許可權設置 ',
      allProduct: '全部產品',
      custom: '自訂 ',
      passWordTip: '確認重置密碼嗎',
      passWordText: '您的新密碼是',
      passWordSuccess: '密碼重置成功',
      remove: '清空'
    },
    exception: {
      applicantNamePlease: '請輸入姓名/企業名稱',
      certificateNumberPlease: '請輸入身份證/統一社會信用代碼',
      phonePlease: '請輸入手機號碼',
      correctPhonePlease: '請輸入正確手機號碼'
    },
    distributionOrder: {
      importTaskDetails: '訂單信息',
      importOrderDetails: '訂單明細',
      waiting: '等待導入',
      importing: '導入中',
      completed: '已完成',
      importCompleted: '導入完成',
      portalSubmit: '客戶已提交',
      tenantConfirm: '已確認',
      portalCanceled: '已取消',
      tenantDelivery: '已發貨',
      importSuccess: '導入成功',
      importFail: '導入失敗',
      notConfirm: '待確認',
      notDelivery: '待發貨',
      delivered: '已發貨',
      confirmOrderBatch: '訂單確認',
      confirmDeliveryBatch: '訂單發貨',
      taskId: '代發訂單編號',
      customer: '客戶',
      attachment: '附件',
      importCount: '訂單數量',
      successCount: '成功數量',
      failCount: '失敗數量',
      importTime: '創建時間',
      importStatus: '狀態',
      orderId: '子訂單編號',
      orderDate: '訂單日期',
      receiptorName: '收件人',
      receiptorPhone: '收件人電話',
      receiptorAddress: '收件地址',
      productCode: '產品編碼',
      productName: '產品名稱',
      goodsTitle: '商品名稱',
      productModel: '規格型號',
      goodsModel: '商品規格',
      productUnit: '計價單位',
      unitPrice: '單價',
      quantity: '數量',
      outDeliveryQuantity: '委外數量',
      subtotal: '訂單金額',
      orderStatus: '訂單狀態',
      confirmOrder: '確認訂單',
      batchSet: '批量設置',
      setCompany: '設置所屬公司',
      sourceSku: '原商品',
      targetSku: '替換商品',
      setProductType: '商品替換方式',
      setProductTypeDirect: '直接替換',
      setProductTypeBySelect: '相同商品替換',
      pleaseSelectOrder: '請選擇訂單後批量操作',
      deliveryOrder: '訂單發貨',
      productStock: '庫存',
      orderQuantity: '訂單數量',
      outDelivery: '委外數量',
      lastStep: '上一步',
      nextStep: '下一步',
      isGeneDelivery: '是否生成發貨單',
      deliveryType: '發貨方式',
      oneSkuOneDelivery: '一單一品（每個發貨單商品數量為1）',
      oneOrderOneDelivery: '按訂單發貨（每個訂單生成一個發貨單）',
      companyPlease: '請選擇所屬公司',
      dataEmpty: '數據為空',
      taskOrderTab: '訂單明細',
      taskDeliveryTab: '發貨明細',
      taskOutRequireTab: '委外需求',
      taskOutOrderTab: '委外訂單',
      taskReceivableTab: '應收款',
      deliveryId: '發貨單編號',
      orderDetails: '訂單明細',
      productMap: '發貨設置',
      orderConfirm: '訂單確認',
      confirmOrderTips: '代發訂單信息核對完成，是否確認訂單？',
      deliveryOrderTips: '確認發貨？',
      isGeneReceivable: '是否生成應收款',
      currency: '貨幣',
      deliveryDate: '發貨日期',
      taxRate: '稅率',
      taxRatePlease: '請選擇稅率',
      stockNotEmpty: '庫存不足',
      orderAmount: '訂單總額',
      productQuantitySum: '產品總數量',
      inventoryBatch: '批量出庫',
      deliveryDetails: '發貨單明細',
      quantityMap: '數量確認',
      confirmInventory: '確認出庫',
      saveInventory: '保存出庫單',
      confirmInventoryTips: '是否確認批量生成出庫單並完成出庫？',
      saveInventoryTips: '是否確認批量生成出庫單？',
      selectDataEmpty: '請勾選發貨單後操作',
      queryTaskIdEmpty: '請輸入代發訂單編號後操作',
      selectedRange: '勾選發貨單範圍',
      queryTaskIdRange: '代發訂單編號範圍',
      enterQueryTaskId: '請輸入代發訂單編號',
      productQuantitySumOver: '相同產品數量之和不能大於產品總數量',
      completeTaskTips: '確認該代發訂單已完成？',
      notInventory: '未出庫',
      partInventoried: '部分出庫',
      inventoried: '已出庫',
      distributionDelivery: '代發發貨單',
      viewTaskOrder: '查看代發訂單',
      quantityRate: '已出庫數量/產品總數',
      dataImport: '數據導入',
      dataMap: '數據映射',
      goodsMap: '商品映射',
      dataPreview: '數據預覽',
      excelFile: 'Excel文件',
      upLoad: '上傳',
      tipsTitle: '導入註意事項',
      downLoadTemplate: '下載模板',
      tips1: '建議使用官方Excel模板導入數據',
      tips2: '上傳EXCEL後，請準確填寫標題行號和數據起始行號',
      tips3: '請準確填寫數據映射關系並檢查數據預覽是否相符',
      titleRowNum: '標題行號',
      dataRowNum: '數據行號',
      last: '上一步',
      next: '下一步',
      targetTitleName: '導入列標題',
      sourceTitleName: '映射對象',
      sourceGoodsTitle: '導入商品名稱',
      sourceGoodsModel: '導入商品規格',
      targetGoodsTitle: '關聯商品名稱',
      targetGoodsModel: '關聯商品規格',
      rowNum: '序號',
      confirmSubmit: '確認提交',
      fileError: '上傳文件錯誤',
      mapGoodsPlease: '請選擇映射商品',
      emptyImportData: '導入數據為空',
      quantityPlease: '請填寫訂單數量',
      taskRecordTab: '導入記錄',
      recordQueryKeywords: '請輸入查詢條件',
      reImport: '重新導入',
      recordImportStatus: '導入狀態',
      failMsg: '失敗原因',
      failImportRecordPlease: '請選擇導入失敗訂單',
      importOrder: '導入訂單',
      expressCompanyMap: '快遞公司映射',
      addSalesOrder: '添加訂單',
      mapProductPlease: '請選擇映射產品',
      mapExpressPlease: '請選擇映射快遞',
      addressQueryKeywords: '產品 收件人信息',
      sourceProductName: '導入產品名稱',
      sourceProductModel: '導入產品規格型號',
      targetProductName: '導入產品名稱',
      targetProductModel: '導入產品規格型號',
      splitDeliveryType: '自動折開發貨單管道',
      splitDeliveryByAddress: '按收件人信息拆分（相同收件人信息拆分到一個發貨單）',
      splitDeliveryByProduct: '按產品拆分（每個產品拆分到一個發貨單）',
      splitDeliveryNone: '不拆分（只生成一個發貨單）',
      parseAddress: '解析收件地址',
      parseAddressPlease: '請先進行收件地址解析操作',
      parseAddressGoing: '正在解析收件地址',
      parseAddressPart: '部分收件地址解析失敗，請重新解析/手動選擇省市區',
      parseAddressFinish: '收件地址解析完成，請點擊下一步進行',
      parseAddressArea: '解析收件地址省市區',
      parseAddressDetails: '解析收件詳細地址'
    }
  },
  fitment: {
    pageInfo: '頁面資訊',
    save: '保存',
    search: '搜索框',
    banner: '輪播圖',
    menus: '菜單',
    notice: '公告',
    product: '商品（多列）',
    threeProduct: '商品（三列）',
    pageTitle: '頁面標題',
    singleText: '單行文本',
    textAlignment: '文字對齊',
    center: '居中',
    left: '居左',
    textColor: '文字顏色',
    iconColor: '圖示顏色',
    backgroundColor: '背景顏色',
    componentBack: '組件背景',
    imgTip: '添加圖片 (最多10張，可拖動排序)',
    numberTip: '最多10張，可拖動排序',
    sort: '排序',
    name: '名稱',
    addLink: '添加連結',
    updateLink: '修改連結',
    deleteLink: '刪除連結',
    addImg: '添加圖片',
    size: '建議尺寸',
    marginTop: '上邊距',
    marginBottom: '下邊距',
    marginLeftRight: '左右邊距',
    rounde: '圓角',
    lineNum: '每行個數',
    number: '顯示數量',
    goodsColor: '商品名稱顏色',
    markingUnitColor: '劃線價顏色',
    salesColor: '售價顏色',
    quantityColor: '銷量顏色',
    third: '3個',
    four: '4個',
    addMenu: '添加菜單',
    speed: '滾動速度',
    chooseGoods: '選擇商品',
    manuallyChoose: '手動選擇',
    chooseCategory: '選擇分類',
    marketingAttributes: '行銷屬性',
    limitPerfer: '限时特惠',
    distributionApplication: '分销申请',
    goodsList: '商品列表',
    addGoods: '添加商品',
    addCategory: '添加分類',
    newGoods: '新品商品',
    hotGoods: '熱賣商品',
    recommendGoods: '推薦商品',
    categoryName: '分類名稱',
    operation: '操作',
    titleCup: '標題組件',
    titleFontSize: '標題大小',
    titleText: '標題文本',
    update: '變更',
    delete: '刪除',
    goodsTitle: '商品標題',
    goodsImg: '商品圖片',
    goodsPrice: '商品價格',
    goodsInventory: '商品庫存',
    categoryImg: '分類圖片',
    categoryLevel: '分類層級',
    oneLevel: '一級',
    twoLevel: '二級',
    threeLevel: '三級',
    choose: '選擇',
    shoppingCart: '購物車 ',
    order: '訂單管理',
    address: '地址管理',
    goodsCategory: '商品分類',
    myInfo: '我的信息',
    giftPlan: '禮盒定製',
    distributionCenter: '分銷中心',
    giftList: '禮盒列表',
    coupons: '領券中心',
    page: '商城頁面',
    goods: '商品',
    category: '分類',
    titlePage: '頁面標題',
    pageNotes: '頁面備註',
    backGrountPage: '頁面背景',
    newProducts: '新品商品',
    hotsGoods: '熱賣商品',
    recommendedProducts: '推薦商品',
    shoppingCentre: '商城',
    announcement: '資訊',
    sold: '銷量',
    item: '件',
    nameProduct: '這是商品名稱',
    dashPrice: '999',
    salePrice: '劃線價',
    inventory: '99',
    goodsCup: '商品組件',
    goodsBackgroundColor: '商品背景顏色',
    goodsStyle: '商品內容樣式',
    goodsPaddingTopBottom: '商品上下間距',
    goodsPaddingLeftRight: '商品左右間距',
    singlePicture: '單圖',
    video: '視頻',
    imageSpace: '圖片間距 ',
    videos: '視頻',
    coverPhoto: '封面圖片',
    singleProduct: '商品（單列）',
    editGoods: '更換商品',
    videoTip: '您的流覽器不支援視頻播放',
    componentHeight: '組件高度',
    isDisplayTitle: '顯示標題組',
    isMoreLink: '顯示連結文本',
    show: '顯示',
    hidden: '隱藏',
    linkText: '連結文本',
    jumpLink: '跳轉連結',
    titleTextHeight: '文本高度',
    titleTextLocation: '文本對齊',
    right: '居右',
    pleaseCategory: '請選擇分類',
    pleaseJumpMallPage: '請選擇跳轉的商城頁面',
    pleaseJumpGoods: '請選擇跳轉的商品',
    pleaseJumpCategory: '請選擇跳轉的分類',
    pleaseJumpGift: '請選擇跳轉的禮盒',
    more: '更多',
    reduction: '還原',
    goodsColumn: '商品樣式',
    doubleColumn: '雙列',
    threeColumn: '三列',
    picture: '圖片魔方',
    pictureStyle: '樣式',
    customConfig: '自定義篩選',
    priceRange: '價格區間',
    maxGoodsTips: '添加商品 (最多50種，可拖動排序)',
    brand: '品牌',
    brandTitleComponent: '標題組件',
    paddingLeftRight: '左右邊距',
    brandComponent: '品牌組件',
    blockItemMarginTopBottom: '上下間距',
    blockItemPaddingLeftRight: '左右間距',
    blockImageHeight: '圖片高度',
    blockTextHeight: '文本高度',
    showBrandSelect: '選擇品牌',
    addPriceRange: '添加價格區間',
    orderByRule: '排序方式',
    default: '默認',
    priceAsc: '按價格從小到大',
    priceDesc: '按價格從大到小',
    salesQuantityAsc: '按銷量從小到大',
    salesQuantityDesc: '按銷量從大到小',
    pointZone: '積分專區'
  },
  lock: {
    addLock: '添加庫存鎖定',
    viewLock: '查看庫存鎖定',
    editLock: '編輯庫存鎖定',
    lockNumber: '鎖定數量',
    lockReason: '鎖定理由',
    lockProduct: '鎖定產品',
    lockNumberTip: '請填寫鎖定數量',
    lockReasonTip: '請填寫鎖定理由',
    lockProductTip: '請填寫鎖定產品',
    selectProduct: '請選擇產品'
  },
  tinymce: {
    video: '視頻',
    size: '尺寸',
    forSize: '按尺寸',
    forScale: '按百分比',
    image: '圖像',
    imageUpload: '圖片上傳',
    localUpload: '本地上傳',
    webImage: '網路圖片',
    imageUploadTip: '只能上傳jpg/png/gif檔，且不超過',
    pleaseEntryWebImageUrl: '請填寫網路圖片連結',
    pleaseWaitImageUploadCompleted: '請等待圖片上傳完成'
  },
  kf: {
    avatar: '客服頭像',
    kfName: '客服名稱',
    openKfid: '客服ID',
    default: '默認',
    servicer: '接待人',
    addTitle: '添加微信客服',
    editTitle: '編輯微信客服',
    kfInfo: '客服信息',
    deleteTips: '是否確認刪除該微信客服?',
    kfNameNotEmpty: '客服名稱不能為空',
    mediaIdNotEmpty: '缺少mediaId,請重新上傳頭像',
    servicerNotEmpty: '請選擇客服接待人',
    setDefault: '設置默認'
  },
  notifyConfig: {
    scene: '場景',
    customer: '客戶訂單',
    member: '會員訂單',
    mallOrderOfflineSubmit: '訂單-線下轉賬提交',
    paySuccess: '訂單支付成功',
    rechargeOfflineSubmit: '餘額充值-線下轉賬提交',
    rechargeSuccess: '餘額充值成功',
    financeConfirm: '財務確認',
    refundApply: '退換貨申請',
    businessManage: '客戶業務員',
    assign: '指定人',
    role: '角色',
    monthPay: '訂單-掛賬月結提交',
    inventoryIn: '入庫通知',
    inventoryOut: '出庫通知',
    pendingFinanceReceipt: '待確認收款單',
    finishedFinanceReceipt: '已確認收款單',
    pendingSalesOrder: '待審核銷售訂單',
    auditedSalesOrder: '已審核銷售訂單',
    dailyPaper: '業績日報',
    wework: '企業微信',
    customTemplate: '自定義範本',
    templateCode: '範本編碼',
    templateName: '範本名稱',
    templateType: '範本類型',
    templateContent: '範本內容',
    formEmptyText: '從左側拖拽來添加內容',
    component: '組件',
    property: '屬性',
    title: '標題',
    titleBold: '標題加粗',
    titleColor: '標題顏色',
    bold: '加粗',
    value: '文本',
    valueBold: '文本加粗',
    valueColor: '文本顏色',
    hrefTitle: '鏈接文本',
    hrefValue: '鏈接地址',
    primaryTitleNotEmpty: '一級標題不能為空',
    secondaryTxtNotEmpty: '二級普通文本不能為空',
    secondaryTitleTxtNotEmpty: '二級標題、二級文本不能為空',
    secondaryHrefNotEmpty: '二級鏈接不能為空',
    setting: '設置',
    notifyObject: '通知對象',
    systemPresetParam: '系統預設參數'
  },
  enterprise: {
    common: {
      sales: '銷售方',
      purchase: '採購方',
      pending: '待審核',
      audited: '已審核',
      reject: '駁回',
      executeResultInfo: '共{0}條記錄，成功：{1}條，跳過：{2}條',
      supplyPrice: '供货价'
    },
    config: {
      inventorySecondmentSetting: '庫存借調參數設置',
      enableStockIncludeOnTheWay: '可用庫存是否包含在途'
    },
    apply: {
      apply: '互聯申請',
      current: '當前',
      identify: '我方企業身份',
      enterpriseName: '我方企業名稱',
      orgId: '我方OrgID',
      oppositeIdentify: '對方企業身份',
      oppositeEnterpriseName: '對方企業名稱',
      oppositeOrgId: '對方OrgID',
      salesOrgId: '客戶企業組織ID',
      purchaseOrgId: '供應商 企業組織ID',
      enterpriseTypeNotEmpty: '請選擇企業身份',
      relatedCustomer: '關聯客戶',
      relatedSupplier: '關聯供應商',
      addTitle: '添加企業互聯申請',
      auditTitle: '審核企業互聯申請',
      revokeTitle: '撤銷企業互聯申請',
      viewTitle: '查看互聯申請',
      auditOrgIdNotEmpty: '請輸入互聯企業組織ID',
      auditOrgIdCanNotBeCurrentEnterprise: '互聯方不能是當前企業',
      customerIdNotEmpty: '請關聯客戶',
      supplierNotEmpty: '請關聯供應商',
      rejectReason: '駁回原因',
      applyTime: '申請時間',
      rejectReasonNotEmpty: '請輸入駁回理由',
      deleteTips: '是否確認刪除該互聯申請?',
      rejectTips: '是否確認拒絕該互聯申請?',
      authorization: '授權',
      range: '產品授權範圍',
      none: '無',
      all: '全部產品',
      custom: '自訂',
      assignedBrand: '指定產品品牌',
      assignedProduct: '指定產品',
      assignedSort: '指定產品分類',
      mallRange: '商品授權範圍',
      mallAssignedProduct: '指定商品',
      mallAssignedSort: '指定商品分類',
      assignedSalesLabel: '指定行銷標籤',
      authorizationInfo: '授權資訊',
      productRelation: '產品關聯',
      goodsRelation: '商品關聯',
      selfProduct: '我方產品',
      oppositeProduct: '對方產品',
      addSysProduct: '添加系統產品',
      addSpu: '添加商品',
      our: '我方',
      opposite: '對方',
      selfSpuName: '我方商品名稱',
      oppositeSpuName: '對方商品名稱',
      selfSpu: '我方商品',
      oppositeSpu: '對方商品',
      chooseSpu: '選擇商品',
      pleaseChooseProduct: '請選擇產品',
      authorizedProduct: '授權產品',
      authorizedSpu: '授權商品',
      oppositeAuthorizedProduct: '對方授權產品',
      oppositeAuthorizedSpu: '對方授權商品',
      associate: '關聯',
      drawerInto: '引入',
      pass: '審核通過',
      enterpriseApplyAudited: '互聯申請已審核 無法刪除',
      syncPrice: '同步價格',
      syncAllPrice: '同步全部產品價格',
      syncEmptyPrice: '僅同步產品價格為空',
      queryKeyword: '產品編碼、產品名稱',
      syncPurchasePriceConfirmTips: '是否確定同步互聯產品價格到采購價格?',
      syncSalesPriceConfirmTips: '是否確定同步互聯產品價格到銷售價格?',
      productCategoryTips: '請選擇產品分類',
      auto: '自動',
      manual: '手動',
      pleseaChooseProduct: '請選擇商品',
      productUnitTips: '請選擇計價單位',
      batchQuote: '批量引入',
      selectedSpuEmpty: '請選擇要引入商品',
      spuConfig: '引入商品設置',
      pullBatch: '批量引入',
      hasPullSpuNames: '以下商品已被引入',
      errorPullSpuNames: '以下商品引入失敗',
      productNotRelatedSpuNames: '系統產品未關聯',
      productUnitMatch: '計價單位匹配類型',
      productUnitMatchTips: '計價單位自動匹配說明：計價單位默認按名稱進行自動匹配,如果沒有匹配上,則自動創建',
      autoAssociate: '自動關聯',
      syncType: '同步類型',
      matchWithRelation: '按互聯方映射關係自動匹配',
      matchWithCustom: '自定義匹配',
      matchFields: '匹配欄位',
      matchSuccessTip: '匹配完成，共自動匹配{0}條產品資料',
      disassociate: '取消關聯',
      disassociateTips: '確認取消關聯產品'
    },
    interconnect: {
      data: '數據',
      iamSales: '我是銷售方',
      iamPurchase: '我是採購方',
      noConnect: '未連接',
      connect: '已連接',
      auth: '授權',
      viewTitle: '查看互聯信息',
      editTitle: '編輯互聯資訊',
      authTitle: '數據授權',
      syncSalesPrice: '同步銷售價格',
      syncPurchasePrice: '同步採購價格',
      productQuote: '產品引入',
      unboundEnterpriseInterconnectTips: '是否確定取消對該企業的互聯？',
      authSetting: '授權設定',
      stockShare: '庫存共亯',
      stockShareTip: '開啟庫存共亯後將共亯可用庫存數據給互聯方',
      businessAnalysisShareTip: '該數據共享後，互聯方可以獲取到相關的銷售數據用於經營分析',
      warehouseShare: '倉庫授權',
      warehouseRelation: '倉庫互聯',
      salesWarehouse: '對方授權倉庫',
      purchaseWarehouse: '我方關聯倉庫',
      businessAnalysisShare: '經營數據共享',
      batchSyncStock: '批量同步可用庫存到虛擬庫存',
      salesEnterpriseName: '供應商',
      interconnentEnableStock: '供應商可用庫存',
      purchaseVirtualStock: '商品虛擬庫存'
    },
    order: {
      orderCode: '互聯訂單編號',
      businessCode: '采購訂單編碼',
      customer: '客戶名稱',
      queryKeyword: '采購單編碼、客戶名稱',
      editTitle: '編輯互聯訂單',
      viewTitle: '查看互聯訂單',
      pending: '待確認',
      audited: '已確認',
      save: '保存',
      saveAndConfirm: '保存並確認',
      confirmTips: '該訂單是否已確認,確認後將生成待審核銷售訂單',
      successTips: '該訂單已確認,已生成待審核的銷售訂單,請聯系相關人審核',
      confirmOrder: '確認訂單',
      purchaser: '采購方',
      supplier: '供應商',
      salesOrderCode: '銷售訂單編碼',
      deliveryDate: '交貨日期'
    },
    inventorySecondment: {
      id: '借調單號',
      secondmentForPurchase: '調入方',
      secondmentForSales: '調出方',
      secondmentForSalesEnterprise: '調出方',
      secondmentForPurchaseEnterprise: '調入方',
      addTitle: '添加庫存借調',
      editTitle: '編輯庫存借調',
      viewTitle: '查看庫存借調',
      auditTitle: '庫存借調審核',
      secondmentCustomer: '調出方',
      secondmentPurchase: '調入方',
      purchaseAuditTips: '確認審核借調單，審核通過後請等待被借調方受理並出庫',
      salesRecallTips: '確認撤回到草稿狀態',
      deleteTips: '確認刪除該借調單',
      salesAuditTips: '確認審核該借調單，通過後請在【出庫通知-庫存借調】安排出庫',
      salesRejectTips: '確認駁回該借調單',
      purchaseOperator: '經辦人',
      inventoryInRate: '入庫進度',
      inventoryOutRate: '出庫進度',
      salesInWarehouseStock: '在庫庫存',
      printTitle: '庫存借調打印',
      salesEnableStock: '可用庫存',
      orderDate: '借調日期',
      secondmentCustomerPlease: '請選擇調出方',
      baseInfo: '基本信息',
      inventoryOutInfo: '出庫信息',
      inventoryInInfo: '入庫信息',
      salesRevokeTips: '確認撤銷借調單',
      orderDatePlease: '請選擇借調日期',
      operatorName: '經辦人',
      inventoryInWarehousePlease: '請選擇入庫倉庫',
      secondmentDetailsForPurchase: '調入方明細',
      secondmentDetailsForSales: '調出方明細',
      inWarehouse: '調入倉庫'
    }
  },
  excelImportSetting: {
    excelFile: 'Excel檔案',
    upLoad: '上傳',
    tipsTitle: '導入注意事項',
    downLoadTemplate: '下載範本',
    tips1: '建議使用官方Excel範本導入數據',
    tips2: '上傳EXCEL後,請準確填寫標題行號和數據起始行號',
    tips3: '請準確填寫數據映射關係並檢查數據預覽是否相符',
    titleRowNum: '標題行號',
    dataRowNum: '數據行號',
    fileError: '檔案類型錯誤或者未按範本格式填寫',
    fileUploading: '檔案加載中',
    pleaseSelectTheExcelToImport: '請選擇要導入的Excel'
  },
  dataMappingSetting: {
    targetTitleName: '映射對象',
    sourceTitleName: '導入列標題'
  },
  expressCompanyMappingSetting: {
    expressCompanyMap: '快遞公司映射',
    sourceExpressCompany: '導入快遞公司名稱',
    targetExpressCompany: '映射快遞公司名稱'
  },
  expressManage: {
    placeOrder: '快遞下單',
    config: {
      jdExpressConfig: '京東物流配置',
      customerName: '客戶名稱',
      customerCode: '客戶編碼',
      customerCodeTip: '商家編碼/青龍業主號/配送編碼/月結編碼',
      refreshToken: 'Refresh Token',
      auth: '授權',
      refreshTokenTip: '請點擊授權按鈕獲取Refresh Token',
      sfExpressConfig: '順豐物流配置',
      sfMonthlyCard: '月結卡號',
      monitorConfig: '監控設置',
      flywireStartAreaConfig: '飛線圖起始位置',
      overtimeConfig: '超時設置',
      firstDeliveryOvertime: '首次走件超時',
      deliveryStopOvertime: '運輸停止超時',
      dispatchOvertime: '派送超時',
      signOvertime: '簽收超時',
      smsConfig: '通知設置',
      defaultSmsScheme: '物流短信配置',
      smsSign: '短信簽名',
      smsSignTips: '如沒有選擇短信簽名，則使用客戶短信簽名或系統默認短信簽名',
      afterCollection: '攬收後',
      afterSign: '簽收後',
      signTimeout: '簽收超時48H',
      smsScheme: '物流短信通知策略',
      expressSmsScheme: '策略名稱',
      expressSmsSchemeNamePlease: '請填寫策略名稱',
      noExpressScheme: '未設置通知策略',
      expressSmsConfigTips1: '如沒有選擇短信簽名，則使用客戶短信簽名或系統默認短信簽名',
      expressSmsConfigTips2: '勾選物流節點後將會發送相應通知短信',
      expressSmsConfigTips3: '短信內容可以自定義，將預留參數插入文本內容即可',
      expressSmsConfigTips4: '預留參數 :收件人姓名: ${receiptorName}，訂單號: ${orderNumber}， 快遞公司: ${expressCompanyName}，快遞單號: ${expressNumber}',
      smsNotifyType: '通知設置',
      smsNotifyTypePlease: '請配置短信通知人',
      notifyBuyer: '通知下單人',
      notifyReceiver: '通知收件人',
      expressSender: '發件人',
      expressSenderPlease: '請選擇發件人',
      senderName: '發件人名稱',
      senderNamePlease: '請輸入發件人名稱',
      companyName: '發件人公司',
      senderPhone: '發件人號碼',
      senderPhonePlease: '請輸入發件人號碼',
      senderMobile: '發件人電話',
      senderAddressArea: '發件人地址省市區',
      senderAddressAreaPlease: '請輸入發件人地址省市區',
      senderAddress: '發件人詳細地址',
      senderAddressPlease: '請輸入發件人詳細地址',
      addExpressSender: '添加發件人',
      editExpressSender: '編輯發件人',
      deleteExpressSenderTips: '確認刪除該發件人',
      expressAccountPlease: '請選擇下單賬號'
    },
    payType: {
      shipper: '寄付',
      consignee: '到付',
      monthly: '月結',
      thirdParty: '協力廠商支付'
    },
    orderStatus: {
      fail: '下單失敗',
      wait: '待下單',
      success: '已下單',
      cancel: '已取消'
    },
    order: {
      orderCode: '訂單編碼',
      recipient: '收件人',
      sender: '寄件人',
      companyName: '快遞公司',
      cargoName: '物品名稱',
      payType: '支付方式',
      orderStatus: '訂單狀態',
      printStatus: '列印狀態',
      orderTime: '下單時間',
      createTime: '創建時間',
      addOrder: '添加訂單',
      editOrder: '編輯訂單',
      viewOrder: '查看訂單',
      expressCompany: '快遞公司',
      businessType: '業務類型',
      quantity: '數  量',
      weight: '重  量',
      piece: '件',
      packageQuantity: '包裹件數',
      postage: '郵費',
      yuan: '元',
      returnSignBill: '簽收回單',
      need: '需要',
      noNeed: '不需要',
      isInsured: '是否保價',
      insured: '保價',
      noInsured: '不保價',
      insuredAmount: '保價金額',
      insuredCost: '保價費用',
      collection: '代收貨款',
      expandMoreInfo: '展開更多資訊',
      foldMoreInfo: '收起更多信息',
      pleaseEntryRecipientName: '請輸入收件人姓名',
      pleaseEntrySenderName: '請輸入寄件人姓名',
      pleaseEntryCargoName: '請輸入物品名稱',
      cancelOrderTips: '是否確定取消該訂單？',
      printFaceInvoke: '列印電子面單',
      clearAddressNoteInfo: '清空已填信息',
      printTemplate: '列印範本',
      printLogo: '列印Logo',
      noPrintTempalteUse: '暫未配置該快遞公司的列印範本',
      pleaseChoosePrintTemplate: '請選擇列印範本',
      pleaseChooseExpressCompany: '請選擇快遞公司',
      pleaseChooseBusinessType: '請選擇業務類型',
      deleteOrderTips: '是否確定删除該訂單？',
      addExpressOrder: '快遞下單',
      expressCompanyAccount: '下單賬號',
      addExpressOrderTips: '確認快遞下單，下單成功後可打印電子面單',
      selectAddOrderPlease: '請選擇要快遞下單的單據',
      addExpressOrderSuccessTips: '共{0}條記錄，成功：{1}條，失敗：{2}條',
      addExpressOrderFail: '快遞下單失敗',
      notPrintExpressOrder: '請先使用系統快遞下單，下單成功後才能打印電子面單'
    },
    addressNote: {
      addAddressNote: '添加連絡人',
      editAddressNote: '編輯連絡人',
      viewAddressNote: '查看連絡人',
      recipientAddressNote: '收件人地址簿',
      senderAddressNote: '寄件人地址簿',
      name: '姓  名',
      mobile: '手機號碼',
      phone: '固定電話',
      company: '公  司',
      area: '所在地區',
      address: '地  址',
      setDefault: '設為默認',
      isDefault: '是否默認',
      pleaseEntryFullName: '請輸入姓名',
      pleaseEntryMobileOrPhone: '請輸入手機號碼或固定電話',
      pleaseChooseArea: '請選擇所在地區',
      pleaseEntryAddress: '請輸入位址',
      deleteAddressNoteTips: '是否確定刪除該連絡人？'
    },
    account: {
      accountTitle: '帳號資訊',
      accountId: '帳號ID',
      accountFlag: '帳號狀態',
      totalRechargeAmount: '總充值金額',
      totalRechargeCount: '總充值數量',
      usedCount: '已使用數量',
      surplusCount: '剩餘數量',
      preDeductCount: '預扣除數量',
      usableCount: '可用數量'
    },
    companyAccount: {
      expCode: '快遞公司代碼',
      expName: '快遞公司名稱',
      monthCode: '月份編碼',
      customerName: '客戶名稱',
      customerPwd: '客戶密碼',
      sendSite: '發送站點',
      sendStaff: '派遣工作人員',
      wareHouseId: '倉庫編碼',
      freight: '默認運費',
      payType: '運費支付方式',
      volume: '體積',
      weight: '重量',
      expBusinessType: '業務類型',
      expOrderTemplate: '電子面單模板',
      addAccount: '添加賬號信息',
      editAccount: '編輯賬號信息',
      viewAccount: '查看賬號信息',
      deleteAccountTips: '是否確定刪除該賬號信息？'
    },
    freightPayType: {
      cashPay: '現付',
      arrivedPay: '到付',
      monthlyPay: '月結',
      thirdPay: '第三方支付/回單付'
    },
    recharge: {
      startDate: '査詢時間起',
      endDate: '査詢時間止',
      rechargeTime: '充值時間',
      rechargeAmount: '充值金額',
      rechargeQuantity: '充值數量',
      expressAbleNumber: '物流可用條數',
      viewCharge: '查看充值記錄',
      rechargeAccount: '充值帳號',
      rechargeOrderCode: '充值訂單號'
    },
    monitor: {
      monitorStatus: '監控狀態',
      expressStatus: '物流狀態',
      expressCompany: '快遞公司',
      sourceType: '來源',
      startDate: '監控時間起',
      endDate: '監控時間止',
      orderCode: '訂單編號',
      deliveryCode: '發貨單編號',
      outCode: '出庫單編號',
      logisticCode: '快遞單號',
      receiverName: '收貨人名稱',
      receiverPhone: '收貨人手機號',
      receiverCountry: '國家',
      receiverArea: '收貨區域',
      receiverAddr: '收貨地址',
      monitorTime: '監控時間',
      errorReason: '失敗原因',
      expressStatusEx: '詳細物流狀態',
      latestTrack: '最新物流軌跡',
      latestTrackTime: '最新軌跡時間',
      takeUpTime: '耗時',
      pleaseSelectExpCompany: '請選擇物流公司',
      pleaseEntryExpressNumber: '請輸入物流單號',
      addExpressMonitor: '添加監控資訊',
      editExpressMonitor: '編輯監控資訊',
      viewExpressMonitor: '查看物流監控資訊',
      orderCodeTip: '當沒有填寫訂單號時,將自動生成訂單號',
      receiverPhoneTip: '當快遞公司是順豐系時,必須填寫手機號碼,且不能為虛擬號碼,否則可能無法獲取物流軌跡',
      deleteExpressMonitorTip: '是否確定删除該監控資訊？',
      pleeaseSelectMonitorNeedOperateTip: '請選擇要操作的監控資訊',
      dataImport: '導入數據',
      dataMap: '數據映射',
      expressCompanyMap: '快遞公司映射',
      dataPreview: '數據預覽',
      complete: '完成',
      mapExpressPlease: '請選擇映射快遞',
      emptyImportData: '導入數據為空',
      importExpressMonitor: '導入快遞資訊',
      executeResultInfo: '共{0}條記錄,成功：{1}條,跳過：{2}條',
      importMode: '導入模式',
      expressTrace: '物流軌跡',
      monitor: '監控',
      cancelMonitor: '取消監控',
      refreshMonitor: '刷新監控',
      setSpecial: '特別關註',
      unsetSpecial: '取消特別關註',
      pleaseSelectExpress: '請選擇快遞單號',
      allMonitor: '全部監控',
      specialMonitor: '特別關註',
      overtimeMonitor: '異常件',
      overtimeType: '異常件類型',
      pleaseSelectExpPayType: '請選擇運費支付方式',
      pleaseSelectExpBusinessType: '請選擇業務類型',
      pleaseSelectExpOrderTemplate: '請選擇電子面單模板',
      pleaseInputDefaultFreight: '請填寫默認運費',
      pleaseInputVolumn: '請填寫體積',
      pleaseInputWeight: '請填寫重量',
      salesOrder: '銷售訂單',
      mallOrder: '商城訂單',
      thirdOrder: '三方訂單',
      outSourceOrder: '委外訂單',
      exchangeOrder: '兌換訂單',
      invoice: '發票',
      batchQuery: '批量查詢',
      manually: '手動添加/導入'
    },
    batchQuery: {
      batchTasksName: '批量任務名稱',
      pleaseInputBatchTasksName: '請輸入批量任務名稱',
      expressBatchQueryExport: '快遞批量查詢導出'
    }
  },
  distribution: {
    b2c: {
      common: {
        shopCode: '小店碼',
        shopName: '小店名稱',
        shopkeeperName: '小店店主',
        phoneNumber: '手機號碼',
        idCardOrMerchantName: '姓名/企業名稱',
        idCardOrBusinessLicenseNumber: '證件號碼',
        organizationType: '主體類型',
        invitationCode: '邀請碼',
        invitationName: '邀請人',
        invitationType: '邀請人類型',
        user: '員工',
        customer: '客戶',
        pending: '待審核',
        audited: '通過',
        reject: '拒絕',
        relatedCustomer: '關聯客戶',
        phoneNumberCustomerName: '手機號客戶名稱',
        expire: '有效期',
        customerIdNotEmpty: '請關聯客戶',
        pleaseEntryShopCode: '請輸入小店碼',
        smallBusinesses: '小微商戶',
        individualSellers: '個人賣家',
        individualBusiness: '個體工商戶',
        enterprise: '企業',
        partyAndGovernment: '黨政、機關及事業單位',
        otherOrganizations: '其他組織',
        disablePaymentMonth: '關閉掛賬月結',
        disableBalancePayment: '關閉余額支付'
      },
      apply: {
        applyTime: '申請時間',
        auditTime: '審核時間',
        auditStatus: '審核狀態',
        viewTitle: '分銷申請',
        rejectReason: '拒絕理由',
        rejectTips: '是否確認【拒絕】該小店分銷申請',
        rowAuditTips: '是否確認審核【通過】該小店分銷申請',
        rejectReasonNotEmpty: '請填寫拒絕原因'
      },
      detail: {
        addTitle: '添加B2C小店分銷',
        editTitle: '編輯B2C小店分銷',
        authTitle: 'B2C小店分銷授權',
        mallRange: '商品授權範圍',
        mallAssignedProduct: '指定商品',
        mallAssignedSort: '指定商品分類',
        assignedSalesLabel: '指定行銷標籤',
        auth: '授權'
      }
    }
  },
  message: {
    recharge: {
      rechargeTime: '充值時間',
      rechargeAmount: '充值金額',
      rechargeQuantity: '充值數量',
      smsAbleNumber: '短信可用條數',
      viewCharge: '查看充值記錄',
      rechargeAccount: '充值賬號',
      rechargeOrderCode: '充值訂單號'
    },
    template: {
      templateName: '模板名稱',
      templateCode: '模板編碼',
      templateContext: '模板內容',
      applyInstructions: '申請說明',
      templateStatus: '模板狀態',
      templateNameTip: '請輸入名稱 不超過30個字符',
      templateContextPlease: '請輸入模板內容',
      applyInstructionsPlease: '請輸入申請說明',
      applyInstructionsTip: '請提供您已上線業務的對應鏈接（必填）、使用場景說明；暫時無法支持未上線應用、未備案域名的模板申請。 ',
      templateContextTip: '(短信70個字數含以內，按1條短信計費；超出70個字為長短信，按照67個字數記為1條短信費用。)',
      sms: '短信',
      smsTip: '預計收費為',
      tiao: '條',
      addTips: '確認提交該短信模板申請，審核通過後可設置該短信模板',
      editTips: '重新提交該短信模板申請，審核通過後可設置該短信模板',
      toAudit: '待審核',
      auditSuccess: '審核通過',
      auditError: '審核失敗',
      reason: '審核備註',
      creatTime: '創建時間',
      deleteTips: '確認【刪除】該短信模板，短信模板刪除後不可恢復，請謹慎操作!',
      addTemplate: '添加短信模板',
      updateTemplate: '修改短信模板',
      viewTemplate: '查看短信模板',
      otherTips3: '短信模板審核為阿里雲，系統只負責提交申請至阿里雲',
      templateType: '短信類型',
      templateTypeTips: '建議優先選擇通知短信',
      notification: '短信通知',
      promotion: '推廣短信'
    },
    send: {
      sendTaskId: '任務id',
      sendTaskName: '任務名稱',
      smsContent: '短信內容',
      sendNumber: '發送數量',
      successNumber: '成功數量',
      failNumber: '失敗數量',
      billingCount: '計費條數',
      sendStatus: '發送狀態',
      revicePhone: '接收號碼',
      smsSign: '短信簽名',
      smsTemplate: '短信模板',
      phoneTypeOne: '導入接收號碼文件',
      phoneTypeTwo: '手動輸入接收號碼',
      phoneTypeThree: '選擇手機號碼',
      inputPhone: '輸入號碼',
      importPhone: '導入號碼',
      system: '系統',
      customer: '客戶',
      member: '會員',
      taskNamePlease: '請輸入任務名稱',
      pleaseInputSmsContent: '請輸入要發送的短信內容',
      templatePlease: '請選擇簡訊範本',
      signPlease: '請選擇短信簽名',
      endSend: '終止髮送',
      waitSend: '等待發送',
      isSend: '正在發送',
      completeSend: '完成發送',
      detailed: '明細',
      phoneNumber: '手機號碼',
      sendTime: '發送時間',
      receiveTime: '接收時間',
      failReason: '失敗原因',
      toSend: '待發送',
      waitingReceipt: '等待回執',
      sendSuccess: '發送成功',
      sendFail: '發送失敗',
      addPhone: '添加號碼',
      addSendTask: '添加發送任務',
      pleaseChoose: '請選擇',
      upload: '點擊上傳',
      uploadTip: '只能上傳txt/xlsx文件,文件大小不超過2M',
      templateDownload: '模板下載',
      chooseSysPhone: '選擇系統手機號碼',
      chooseCustomerPhone: '選擇客戶手機號碼',
      chooseMemberPhone: '選擇會員手機號碼',
      phoneList: '號碼列表',
      clearData: '清空數據',
      templateContextCount: '計費說明：當前內容字數為',
      according: '按照',
      billingTip: '條短信發送併計費',
      templateContextTip: '此功能僅作為內容預覽，具體情況以實際發送為準',
      addPhoneTip: '提示：一行輸入一個號碼，多個手機號請換行隔開。最多輸入1000個手機號碼',
      phoneNotNull: '手機號不能為空',
      phoneIsTrue: '請輸入正確的手機號，格式有誤號碼：',
      uploadFileType: '上傳頭像圖片只能是 txt,xlsx 格式!',
      uploadFileSize: '上傳頭像圖片大小不能超過 2MB!',
      sendTipBefore: '本次發送短信',
      sendTip: '條，確認發送嗎',
      billingInstructions: '計費規則：短信70個字數含以內，按1條短信計費；超出70個字為長短信，按照67個字數記為1條短信費用',
      billingInstructionsTip: '規則詳情',
      phoneNullTip: '未選擇手機號碼',
      addMember: '篩選會員',
      addCustomer: '篩選客戶',
      addCustomerContact: '篩選客戶聯繫人',
      allCustomer: '全部客戶',
      checkCustomer: '勾選客戶',
      queryCustomer: '查詢客戶',
      uploadTips: '導入的號碼數據，會顯示在下方文本框，可進行修改',
      smsNoCount: '短信可用條數不足,联系平台充值',
      tiao: '條',
      sendCount: '短信數量計算：預計發送短信',
      smsAbleCount: '短信可用條數',
      allContact: '全部連絡人',
      checkContact: '勾選連絡人',
      queryContact: '査詢連絡人',
      noData: '未查询到数据',
      dataMaxSizeTip: '手機號最多添加1000個',
      contactName: '聯繫人姓名',
      contactNickName: '聯繫人稱呼',
      contactCustomer: '聯繫人所屬客戶',
      selectPrefix: '已選',
      selectSubfix: '個手機號碼',
      totalPrefix: '共',
      totalSubfix: '個',
      orderCode: '訂單編號',
      userName: '用戶',
      userPhone: '手機號',
      sendPeopleCount: '发送总人数',
      retrySend: '失敗發送重試'
    }
  },
  thirdPartOrder: {
    sysOrderStatus: '訂單狀態',
    startDate: '支付時間起',
    endDate: '支付時間止',
    queryKeyword: '訂單編號、收件信息、商品信息',
    platformType: '三方平臺',
    sysCompanyName: '所屬公司',
    platformOrderCode: '三方訂單編號',
    buyerName: '買家姓名',
    buyerPhone: '買家手機號',
    receiverName: '收件人姓名',
    receiverPhone: '收件人手機號',
    receiverAddress: '收件地址',
    paymentTime: '支付時間',
    productAmount: '產品總額',
    paidAmount: '支付金額',
    importGuanaitongOrder: '關愛通訂單導入',
    importData: '數據導入',
    dataMap: '數據映射',
    previewData: '數據預覽',
    previewResult: '導入結果',
    excelFile: 'Excel 文件',
    attentionTipsTitle: '導入註意事項',
    attentionTips1: '請使用關愛通系統導出的數據導入',
    attentionTips2: '上傳EXCEL後，請準確填寫標題行號和數據起始行號',
    attentionTips3: '請準確填寫數據映射關系並檢查數據預覽是否相符',
    excelTitleRowNumber: '標題行號',
    excelDataRowNumber: '數據行號',
    lastStep: '上一步',
    nextStep: '下一步',
    targetTitleName: '映射對象',
    sourceTitleName: '導入列標題',
    dataCompanyName: '訂單歸屬公司',
    serialNumber: '序號',
    importStatus: '導入狀態',
    importErrorReason: '跳過原因',
    fileError: '導入文件錯誤',
    noDataNeedImport: '導入數據為空',
    executeResultInfo: '共{0}條記錄，成功：{1}條，失敗：{2}條',
    expressCompanyMap: '快遞公司映射',
    sourceExpressCompany: '導入快遞公司名稱',
    targetExpressCompany: '映射快遞公司名稱',
    sysOrderInfo: '訂單信息',
    guanaitongOrderInfo: '導入明細',
    expressCompanyMapTips: '無快遞公司映射請直接跳轉下一步',
    enterpriseWechatNotification: '訂單超時提醒',
    orderNotPrepareOvertime: '訂單超時未備貨',
    orderNotDeliveryOvertime: '訂單超時未發貨',
    orderNotUpdateExpressOvertime: '物流超時未更新軌跡',
    orderNotSignOvertime: '物流超時未簽收',
    hour: '小時',
    shoppingCode: '購物編號',
    externalOrderCode: '外部交易號',
    sellerOrderCode: '商家訂單編號',
    paymentOrderCode: '支付編號',
    buyerUserName: '買家用戶名',
    orderSourceName: '訂單來源名稱',
    realPayAmount: '訂單實際支付金額',
    orderStatus: '訂單狀態',
    deliveryTime: '發貨時間',
    productBrand: '所屬品牌',
    productName: '商品名稱',
    productAttr: '銷售屬性',
    buyQuantity: '購買數量',
    productSalesUnitPrice: '商品成交價',
    productExpressCompanyName: '商品物流公司',
    productExpressNumber: '商品物流單號',
    orderTotalAmount: '商品成交總價',
    notDeliveryOrder: '超時未發貨',
    notUpdateExpressOrder: '物流進度未更新',
    guanaitong: '關愛通',
    statusUpdateTime: '物流更新時間',
    expressStatus: '快遞狀態',
    expressCompanyName: '物流公司',
    expressNumber: '物流單號',
    notPrepare: '待備貨',
    notDelivery: '待發貨',
    delivered: '已發貨',
    completed: '已完成',
    closed: '已關閉',
    allExpressStatus: '物流狀態',
    recentStatusUpdateTime: '物流更新時間',
    sysOrderQueryKeyword: '訂單編號, 收件信息',
    viewImportRecord: '導入記錄',
    importing: '導入中',
    importCompleted: '導入完成',
    importError: '導入失敗',
    importTime: '導入時間',
    importOperatorName: '操作人',
    importCount: '導入數量',
    importRecord: '導入記錄',
    sendSms: '發送短信',
    selectedRange: '已選擇訂單',
    queryRange: '查詢範圍訂單',
    sendSmsBatch: '批量發送短信',
    pleaseSelectOrder: '請選擇訂單',
    isSendSms: '是否發送短信',
    taskImportStatus: '任務狀態',
    viewImportTask: '導入任務',
    taskImportStatusEnum: {
      waiting: '等待導入',
      importing: '導入中',
      completed: '導入完成'
    },
    taskRecordImportStatusEnum: {
      waiting: '等待導入',
      success: '導入成功',
      fail: '導入失敗'
    },
    importTask: {
      createTime: '創建時間',
      dataOperationType: '導入類型',
      sendSms: '發送短信',
      importCount: '導入數量',
      successCount: '成功數量',
      failCount: '失敗數量',
      startTime: '開始時間',
      endTime: '完成時間',
      status: '狀態',
      overwrite: '覆蓋',
      append: '追加',
      detailRecord: '任務明細'
    }
  },
  dumall: {
    config: {
      advertisement: '首頁廣告',
      authorizedGoods: '授權商品',
      automaticAuthorizationStore: '自動授權店鋪',
      invitationRegistrationCode: '邀請註冊碼',
      goodsPrice: '商品價格',
      customPrice: '自定義價格',
      mainImage: '商品主圖',
      goodsTitle: '商品標題',
      priceTypeName: '價格類型',
      priceConfig: '價格設置',
      advertisementStatus: '上線狀態',
      deleteTips: '是否刪除該首頁商品?',
      addAdvertisementTitle: '添加首頁廣告',
      editAdvertisementTitle: '編輯首頁廣告',
      viewAdvertisementTitle: '查看首頁廣告',
      priceLevel: '價格等級',
      allGoods: '全部商品',
      theCustom: '自定義',
      salesPriceLevel: '銷售價格等級',
      goodsName: '商品名稱',
      goodsImg: '商品圖片',
      specsValue: '規格',
      unitPrice: '銷售價',
      quantity: '數量',
      requireGoodsPriceTip: '未填寫自定義價格',
      editCustomPrice: '編輯自定義價格',
      notOnline: '未上線',
      online: '已上線',
      offline: '下線',
      beOnline: '上線',
      onlineTips: '是否下線該首頁商品?',
      notOnlineTips: '是否上線該首頁商品?',
      advertisementImage: '廣告圖片',
      advertisementImageTip: '建議圖片比例為710 : 300',
      sort: '排序',
      retailPrice: '零售價'
    },
    exception: {
      sortPlease: '排序不能為空',
      advertisementImagePathPlease: '廣告圖片不能為空'
    },
    shop: {
      all: '全部',
      authorized: '已授權',
      expired: '已過期'
    },
    invitation: {
      user: '員工',
      customer: '客戶',
      invitationCode: '邀請碼',
      createInvitationCode: '生成邀請海報',
      invitationPoster: '邀請註冊海報',
      invitationText: '邀請您註冊開通嘟麥集肆',
      invitationCodeText: '邀請註冊碼：'
    }
  },
  open: {
    authorized: {
      customerName: '客戶名稱',
      apiKey: 'Api Key',
      apiSecret: 'Api Secret',
      callbackUrl: '回調地址',
      status: '有效狀態',
      effective: '有效',
      loseEfficacy: '失效',
      expire: '有效期止',
      addAuthorized: '添加授權',
      editAuthorized: '編輯授權',
      deleteAuthorizedTips: '確認刪除該開放授權？',
      customerPlease: '請選擇客戶'
    }
  },
  businessAnalysis: {
    digital: {
      projectName: '項目名稱',
      projectDateRange: '統計區間',
      lastProjectStartDate: '同比區間開始日期',
      lastProjectEndDate: '同比區間結束日期',
      isDefault: '是否默認',
      isVisible: '是否展示',
      dataRange: '數據範圍',
      dataMerge: '互聯數據合並',
      visibleRangeConfig: '可見範圍設置',
      priceRangeConfig: '價位段設置',
      priceRange: '價位段（左不包含，右包含）',
      allData: '全部數據',
      customerData: '僅客戶數據',
      inCustomerSort: '可見客戶分類',
      inCustomer: '可見客戶',
      outCustomerSort: '不可見客戶分類',
      outCustomer: '不可見客戶',
      visibleType: '可見類型',
      visibleName: '客戶/客戶分類',
      projectNamePlease: '請輸入項目名稱',
      dateRangePlease: '請選擇統計區間',
      lastStartDatePlease: '請選擇同比區間開始時間',
      lastEndDatePlease: '請選擇同比區間結束時間',
      isDefaultPlease: '請選擇是否默認',
      isVisiblePlease: '請選擇是否展示',
      dataRangePlease: '請選擇數據範圍',
      dataMergePlease: '請選擇互聯數據合並',
      baseConfig: '基本設置',
      brandName: '品牌名稱',
      brandNameTips: '品牌名稱用於經營白皮書上展示，可以使用公司簡稱或品牌名稱，當沒有填寫時，將使用系統企業名稱',
      addProjectTips: '確認添加項目經營分析，經營分析數據要在統計區間之後才能生成',
      editProjectTips: '修改內容不會即時更新已生成的經營分析數據，需手動重新生成',
      deleteProjectTips: '確認刪除項目經營分析',
      addProjectTitle: '添加項目經營分析',
      editProjectTitle: '編輯項目經營分析',
      viewProjectTitle: '查看項目經營分析',
      lastStartDateError: '同比區間開始日期應小於統計區間開始日期',
      calc: '分析',
      reCalc: '重新分析',
      noCalc: '未生成',
      calcProcessing: '生成中',
      calcComplete: '已生成',
      calcFail: '生成失敗',
      viewProjectDigital: '數據查看',
      clacProjectTips: '確認生成數據，如已生成的數據將會被刪除後重新生成',
      clacProcessingProjectTips: '系統正在生成數據，請稍後刷新進行數據查看',
      geneStatus: '數據生成狀態',
      dataRank: '排名',
      lastDateRange: '同比區間',
      currentDateRange: '統計區間',
      salesAmount: '銷售額（元）',
      salesQuantity: '銷售數量',
      amountRate: '占比',
      increaseRate: '同比增長',
      productName: '產品名稱',
      sort: '序號',
      currentOrderDate: '統計日期',
      lastOrderDate: '同比日期',
      lastAmount: '同比銷售額',
      currentAmount: '當前銷售額',
      weekCount: '周數',
      customerSortName: '客戶分類',
      currentWeekDateRange: '統計周區間',
      lastWeekDateRange: '同比周區間',
      before: '前',
      week: '周'
    }
  },
  invoice: {
    generalInvoice: '普通發票',
    specialInvoice: '專項發票',
    companyInvoice: '企業單位',
    personalInvoice: '個人/非事業單位',
    notDeal: '未處理',
    invoiced: '已開票',
    digitalInvoice: '電子發票',
    paperInvoice: '紙質發票',
    email: '電子郵箱',
    express: '快遞',
    mallOrder: '商城訂單',
    salesOrder: '銷售訂單',
    applicant: '申請人',
    invoiceType: '發票類型',
    titleType: '發票擡頭類型',
    titleContent: '發票擡頭',
    taxNumber: '稅號',
    invoiceAmount: '開票金額',
    bankName: '開戶銀行',
    bankAccountNumber: '銀行賬戶',
    registAddress: '開戶銀行地址',
    uploadInvoice: '發票交付',
    registPhone: '註冊手機號',
    invoiceStatus: '開票狀態',
    invoiceDeliveryType: '交付方式',
    applyTime: '申請時間',
    invoiceAttachment: '發票附件',
    receiverEmail: '收件郵箱',
    receverAddress: '收件地址',
    sendExpressCompany: '寄件快遞公司',
    sendExpressNumber: '寄件快遞單號',
    freight: '運費',
    attachmentPlease: '請上傳發票附件',
    uploadInvoiceTips: '確認提交發票附件，確認後申請人可在小程序查看',
    businessType: '關聯業務類型',
    businessCode: '關聯業務',
    businessInvoiceAmount: '關聯業務開票金額',
    viewInvoiceApply: '查看開票申請',
    invoiceBusiness: '關聯業務',
    applyCode: '申請編碼'
  },
  databoard: {
    databoardTitle: '標題',
    defaultTitle: '默認標題',
    customTitle: '自定義標題',
    databoardUrl: 'Url',
    databoardParam: '參數',
    restoreTitle: '還原標題',
    editTitle: '修改標題',
    editParams: '修改參數',
    restoreParams: '還原參數',
    restoreTitleTip: '是否確定還原大屏標題為默認標題？',
    restoreParamsTip: '是否確定還原大屏參數為默認參數？'
  },
  store: {
    info: {
      imageUrl: '門店照片',
      name: '門店名稱',
      companyName: '所屬公司',
      storeCategory: '門店分類',
      userName: '門店店長',
      phone: '門店電話',
      address: '門店地址',
      businessStatus: '營業狀態',
      open: '營業中',
      stop: '停業',
      addStore: '添加門店信息',
      editStore: '修改門店信息',
      deleteStoreTips: '確認刪除該門店？',
      warehouse: '關聯倉庫',
      orderPriceType: '訂單價格類型',
      storeType: '門店類型',
      self: '自營',
      join: '加盟',
      selfPickup: '到店自提',
      storeUser: '門店店員',
      addStoreUser: '添加門店店員',
      storeCollectionAccount: '收銀賬戶',
      rules: {
        pleaseInputName: '請輸入門店名稱',
        pleaseSelectUserName: '請選擇門店店長',
        pleaseInputPhone: '請輸入門店電話',
        pleaseUploadImage: '請上傳門店照片',
        pleaseSelectCompany: '請選擇所屬公司',
        pleaseSelectStoreCategory: '請選擇門店分類',
        pleaseSelectWarehouse: '請選擇關聯倉庫',
        pleaseSelectOrderPriceType: '請選擇訂單價格類型',
        pleaseSelectStoreType: '請選擇門店類型',
        pleaseInputAddress: '請輸入門店地址',
        pleaseSelectAccount: '請選擇收銀賬戶'
      }
    },
    config: {
      storePriceType: '門店價格類型'
    },
    order: {
      orderCode: '訂單號',
      storeName: '門店',
      buyerName: '買家名稱',
      payableAmountSum: '訂單金額',
      orderStatus: '訂單狀態',
      paymentType: '支付方式',
      operateName: '收銀店員',
      orderDate: '下單時間',
      pleaseChooseOrderOperator: '請選擇要查詢的收銀店員',
      queryKeywords: '訂單號 買家',
      cashPay: '現金支付',
      advanceReceiptPay: '預收款支付',
      wechatNativePay: '微信支付(收款碼)',
      wechatScanPay: '微信支付(支付碼)',
      notPaid: '未支付',
      paid: '已支付',
      canceled: '已取消',
      refuned: '已退款',
      buyerType: '買家類型',
      visitor: '散客',
      member: '會員',
      customer: '客戶',
      viewOrder: '查看訂單',
      orderUnitPrice: '單價',
      quantity: '數量',
      subtotal: '小計',
      rePayOrder: '提單',
      payOrder: '支付訂單',
      monthPay: '掛賬月結',
      storeOrder: '門店訂單',
      storeMonthPayOrder: '門店月結訂單',
      storeOrderCollection: '門店訂單收款',
      storeOrderInventoryOut: '門店發貨',
      printOrder: '打印門店訂單',
      partPaid: '部分支付',
      exportOrderTitle: '訂單導出'
    }
  }
}
