<template>
  <drawer width="528px" :title="$t('navbar.message.title')" :display.sync="message.visible">
    <div v-loading="dataLoading" class="msg-container">
      <template v-if="msgRecordList.length>0">
        <div
          v-for="item in msgRecordList"
          :key="item.id"
          :class="item.readStatus===0 ? 'is-unread' : '' "
          class="msg-cell"
          style="display:flex; align-items: stretch;"
        >
          <div class="msg-cell__hd">
            <svg-icon icon-class="msg-message" />
          </div>
          <div class="msg-cell__bd">{{ item.msgContent }}</div>
          <div class="msg-cell__ft">
            <div>{{ item.createTime }}</div>
            <div class="handle">
              <el-link
                v-if="!item.readStatus"
                size="mini"
                :underline="false"
                type="primary"
                class="check"
                @click="handleUpdate(item)"
              >{{ $t('navbar.message.markAsRead') }}</el-link>
              <el-link
                :underline="false"
                type="danger"
                class="delete"
                @click="handleDelete(item)"
              >{{ $t('common.delete') }}</el-link>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="el-table__empty-block" style=" height: 100%;">
          <span class="el-table__empty-text">{{ $t('navbar.message.empty') }}</span>
        </div>
      </template>
    </div>
    <div slot="footer" class="msg-footer">
      <el-switch
        v-model="showSetting"
        :active-text="$t('navbar.message.allMessage')"
        :inactive-text="$t('navbar.message.showReadMessageOnly')"
        :active-value="1"
        :inactive-value="0"
        @change="handleSwitch"
      />
      <el-popover v-model="popVisible" placement="top">
        <el-menu>
          <el-menu-item index="1">
            <i class="el-icon-check" />
            <el-link
              :underline="false"
              @click="handleUpdateBatch"
            >{{ $t('navbar.message.markAllAsRead') }}</el-link>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-delete-solid" />
            <el-link
              :underline="false"
              @click="handleDeleteBatch"
            >{{ $t('navbar.message.deleteAllRead') }}</el-link>
          </el-menu-item>
        </el-menu>
        <i slot="reference" class="el-icon-more msg-more" />
      </el-popover>
    </div>
  </drawer>
</template>

<script>
import Drawer from '@/components/Drawer'
import { getMsgSystemRecordList, getUnReadMsgSystemRecordList, updateMsgSysRecord, deleteMsgSysRecord, updateBatchMsgSysRecord, deleteBatchReadMsgSysRecord } from '@/api/message/systemRecord'
import { formatDate } from '@/utils/date'
import { mapGetters } from 'vuex'

export default {
  name: 'MessageSystem',
  components: { Drawer },
  data() {
    return {
      gutter: 15,
      dataLoading: false,
      msgRecordList: [],
      showSetting: 1, // 1-全部消息 0-仅显示未读消息
      popVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'message'
    ])
  },
  watch: {
    'message.visible': {
      handler(val) {
        if (val) {
          this.refresh()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.handleGetMsgRecordList()
  },
  methods: {
    formatDate,
    refresh() {
      if (this.showSetting === 1) {
        this.handleGetMsgRecordList()
      } else {
        this.handleGetUnreadMsgRecordList()
      }
      this.popVisible = false
    },
    // 全部消息
    handleGetMsgRecordList() {
      this.dataLoading = true
      getMsgSystemRecordList().then(response => {
        const data = response.data
        this.msgRecordList = data
        setTimeout(() => {
          this.dataLoading = false
        }, this.$timeoutMilliseconds)
      }).catch(() => {
        this.dataLoading = false
      })
    },
    // 未读消息
    handleGetUnreadMsgRecordList() {
      this.dataLoading = true
      getUnReadMsgSystemRecordList().then(response => {
        const data = response.data
        this.msgRecordList = data
        setTimeout(() => {
          this.dataLoading = false
        }, this.$timeoutMilliseconds)
      }).catch(() => {
        this.dataLoading = false
      })
    },
    // 单条消息 已读
    handleUpdate(row) {
      if (!row || !row.id) {
        return
      }
      updateMsgSysRecord(row.id).then(response => {
        this.refresh()
        setTimeout(() => {
          this.dataLoading = false
        }, this.$timeoutMilliseconds)
      }).then(() => {
        this.$store.dispatch('message/getUnreadTotal')
      }).catch(() => {
        this.dataLoading = false
      })
    },
    // 单条消息 删除
    handleDelete(row) {
      if (!row || !row.id) {
        return
      }
      deleteMsgSysRecord(row.id).then(response => {
        this.refresh()
        setTimeout(() => {
          this.dataLoading = false
        }, this.$timeoutMilliseconds)
      }).then(() => {
        this.$store.dispatch('message/getUnreadTotal')
      }).catch(() => {
        this.dataLoading = false
      })
    },
    // 消息 全部已读
    handleUpdateBatch() {
      this.dataLoading = true
      updateBatchMsgSysRecord().then(response => {
        this.refresh()
        setTimeout(() => {
          this.dataLoading = false
        }, this.$timeoutMilliseconds)
      }).then(() => {
        this.$store.dispatch('message/getUnreadTotal')
      }).catch(() => {
        this.dataLoading = false
      })
    },
    // 已读消息 批量删除
    handleDeleteBatch() {
      this.$confirm(
        this.$t('navbar.message.deleteTips'),
        this.$t('common.tips'),
        {
          confirmButtonText: this.$t('common.delete'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }
      ).then(() => {
        this.dataLoading = true
        deleteBatchReadMsgSysRecord().then(response => {
          this.$message({
            showClose: true,
            type: 'success',
            message: this.$t('common.deleteSuccess')
          })
          this.refresh()
          setTimeout(() => {
            this.dataLoading = false
          }, this.$timeoutMilliseconds)
        }).then(() => {
          this.$store.dispatch('message/getUnreadTotal')
        }).catch(() => {
          this.dataLoading = false
        })
      }).catch(e => e)
    },
    handleSwitch(val) {
      this.refresh()
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  ::v-deep {
    .mask-show {
      z-index: 1002 !important;
    }
    .main {
      z-index: 1003 !important;
    }
  }
}
.msg-container {
  .svg-icon {
    width: 28px;
    height: 28px;
  }
  .msg-cell {
    position: relative;
    font-size: 14px;
    color: #333;
    padding: 15px 20px 15px 15px;
    line-height: 1.5;
    &:hover {
      .handle .check {
        visibility: visible;
      }
      .handle .delete {
        visibility: visible;
      }
    }
  }
  .msg-cell.is-unread:before {
    content: "";
    position: absolute;
    top: 20px;
    right: 8px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #f94e4e;
  }
  .msg-cell__hd {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 15px;
    // background-color: #1890ff;
    text-align: center;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
  }
  .msg-cell__bd {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
  }
  .msg-cell__ft {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 12px;
    color: #999;
    width: 120px;
    margin-left: 35px;
    text-align: right;
    position: relative;
    .handle {
      position: absolute;
      top: 20px;
      right: 0;
      font-size: 12px;
      color: #999;
      .check,
      .delete {
        visibility: hidden;
      }
    }
  }
}
.msg-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 45px;
  line-height: 45px;
}
</style>
