const Constant = {}

Constant.install = function(Vue, options) {
  /**
   * 根据枚举值获取描述
   * @param {*} constantName 枚举名
   * @param {*} value          枚举值
   * @returns
   */
  const constantInfo = options.enumInfo || {}
  const Enum = {}
  Enum.getLabelByValue = function(constantName, value) {
    if (!Object.prototype.hasOwnProperty.call(constantInfo, constantName)) {
      return ''
    }

    const constantItem = constantInfo[constantName]
    for (const item in constantItem) {
      if (constantItem[item].value === value) {
        return constantItem[item].label
      }
    }
  }

  /**
   * 根据枚举名获取所有枚举项
   */
  Enum.getEnumItems = function(constantName) {
    if (!Object.prototype.hasOwnProperty.call(constantInfo, constantName)) {
      return {}
    }
    const constantItem = constantInfo[constantName]
    return JSON.parse(JSON.stringify(constantItem))
  }

  /**
   * 根据枚举名和枚举项名称获取枚举项
   */
  Enum.getEnumItem = function(constantName, itemName) {
    if (!Object.prototype.hasOwnProperty.call(constantInfo, constantName)) {
      return {}
    }
    const constantItem = constantInfo[constantName]
    for (const item in constantItem) {
      if (item === itemName) {
        return JSON.parse(JSON.stringify(constantItem[item]))
      }
    }
  }

  /**
   * 根据枚举名和枚举项名称获取枚举项的value值
   */
  Enum.getEnumItemValue = function(constantName, itemName) {
    if (!Object.prototype.hasOwnProperty.call(constantInfo, constantName)) {
      return ''
    }
    const constantItem = constantInfo[constantName]
    for (const item in constantItem) {
      if (item === itemName) {
        return constantItem[item].value
      }
    }
  }

  /**
   * 根据枚举名获取对应的描述键值对[{value:label}]
   * @param {*} constantName 枚举名
   * @returns
   */
  Enum.getValueLabelList = function(constantName) {
    if (!Object.prototype.hasOwnProperty.call(constantInfo, constantName)) {
      return []
    }
    const result = []
    const constantItem = constantInfo[constantName]
    for (const item in constantItem) {
      const value = constantItem[item].value
      const label = constantItem[item].label
      result.push({
        value,
        label
      })
    }
    return result
  }

  /**
   * 根据枚举名获取对应的value描述键值对{value:label}
   * @param {*} constantName 枚举名
   * @returns
   */
  Enum.getValueLabel = function(constantName) {
    if (!Object.prototype.hasOwnProperty.call(constantInfo, constantName)) {
      return {}
    }
    const result = {}
    const constantItem = constantInfo[constantName]
    for (const item in constantItem) {
      result[constantItem[item].value] = constantItem[item].label
    }
    return result
  }
  Vue.prototype.$enum = Enum
}

export default Constant
