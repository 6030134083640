import request from '@/utils/request'
// 查询会员设置
export function getMemberConfig() {
  return request({
    url: '/member/config/list',
    method: 'get'
  })
}
// 保存会员设置
export function saveMemberConfig(data) {
  return request({
    url: '/member/config',
    method: 'post',
    data
  })
}
// 保存会员设置
export function saveConfigList(data) {
  return request({
    url: '/member/config/save/batch',
    method: 'post',
    data
  })
}
// 会员分销设置
export function getDistributionRewardConfigList() {
  return request({
    url: '/member/config/distribution-reward/list',
    method: 'get'
  })
}
// 会员分销设置
export function saveDistributionRewardConfig(data) {
  return request({
    url: '/member/config/distribution-reward/save',
    method: 'post',
    data
  })
}
