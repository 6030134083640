<template>
  <div>
    <el-tooltip :content="$t('navbar.lockScreen')" effect="dark" placement="bottom">
      <svg-icon class-name="international-icon" icon-class="lockScreen" @click="click" />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'Screenlock',
  data() {
    return {
    }
  },

  methods: {
    click() {
      this.$store.dispatch('user/screenLock')
      this.$nextTick(() => {
        this.$router.push({ path: '/lock' })
      })
    }
  }
}
</script>
