import store from '../index'
import { getCommonMallConfig } from '@/api/mall/mallConfig'

const state = {
  mallDealConfig: {
    advanceCompleteTime: 7,
    unpaidOrder: 2,
    takeUnpaidOrder: '60',
    automaticReceiving: 2,
    theDeliver: '7',
    tradingEnhance: 2,
    invoiceSetting: '',
    orderEvaluate: 2,
    accordingEvaluation: 2,
    evaluationAudit: 2
  },
  checkList: [2]
}

const mutations = {
  SET_CONFIG: (state, mallDealConfig) => {
    if (mallDealConfig) {
      mallDealConfig.advanceCompleteTime = mutations.CONVERT_INT(mallDealConfig.advanceCompleteTime, 7)
      mallDealConfig.unpaidOrder = mutations.CONVERT_INT(mallDealConfig.unpaidOrder, 2)
      mallDealConfig.takeUnpaidOrder = mutations.CONVERT_INT(mallDealConfig.takeUnpaidOrder, 60)
      mallDealConfig.automaticReceiving = mutations.CONVERT_INT(mallDealConfig.automaticReceiving, 2)
      mallDealConfig.theDeliver = mutations.CONVERT_INT(mallDealConfig.theDeliver, 7)
      mallDealConfig.tradingEnhance = mutations.CONVERT_INT(mallDealConfig.tradingEnhance, 2)
      mallDealConfig.orderEvaluate = mutations.CONVERT_INT(mallDealConfig.orderEvaluate, 2)
      mallDealConfig.accordingEvaluation = mutations.CONVERT_INT(mallDealConfig.accordingEvaluation, 2)
      mallDealConfig.evaluationAudit = mutations.CONVERT_INT(mallDealConfig.evaluationAudit, 2)
      state.mallDealConfig = Object.assign({}, state.mallDealConfig, mallDealConfig, false)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value) => {
    return value
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      const type = 'deal'
      getCommonMallConfig(type).then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
