import { getDefaultSystemName, getTenantSystemName } from '@/utils/constant'
import store from '../store'
import defaultSettings from '@/settings'
import i18n from '@/lang'

defaultSettings.title = ''
let title = ''

export default async function getPageTitle(key) {
  if (!title) {
    await store.dispatch('platform/getPlatformInfo').then(() => {
      defaultSettings.title = getDefaultSystemName()
      title = defaultSettings.title
    })
  }
  let tenantSystemName = ''
  await store.dispatch('config/getTenantSystemName').then(() => {
    tenantSystemName = getTenantSystemName()
  })

  const hasKey = i18n.te(`route.${key}`)
  if (hasKey) {
    const pageName = i18n.t(`route.${key}`)
    if (`${title}`) {
      if (tenantSystemName) {
        return `${pageName} - ${tenantSystemName} - ${title}`
      } else {
        return `${pageName} - ${title}`
      }
    }
    if (tenantSystemName) {
      return `${pageName} - ${tenantSystemName}`
    } else {
      return `${pageName}`
    }
  }
  return `${title}`
}
