import request from '@/utils/request'

export function getAreasByCountryIdAndLevel(countryId, level) {
  return request({
    url: `/area/${countryId}/${level}`,
    method: 'get'
  })
}

export function getAllCountries() {
  return request({
    url: '/country/list',
    method: 'get'
  })
}

export function getProvinces(countryId) {
  return request({
    url: `/area/first/${countryId}`,
    method: 'get'
  })
}

export function getAreaChildren(parentId) {
  return request({
    url: `/area/children/${parentId}`,
    method: 'get'
  })
}

export function getAreaById(id) {
  return request({
    url: `/area/${id}`,
    method: 'get'
  })
}

export function getAreaTreeByIds(data) {
  return request({
    url: `/area/ids-tree`,
    method: 'post',
    data
  })
}

