import Vue from 'vue'
import router from '@/router/index'
import { Notification } from 'element-ui'
import i18n from '@/lang'

import { updateMsgSysRecord, deleteMsgSysRecord } from '@/api/message/systemRecord'
import store from '../store'

export function popNotification({ id, message, url, title, type }) {
  if (!message) {
    return
  }
  const t_title = title || i18n.t('common.notification')
  const t_type = t_type || 'info'
  const t_message = message

  const instance = new Vue()
  const h = instance.$createElement
  let vnode = null
  let notification = null
  let currentNode = null
  if (url) {
    const jsonObj = JSON.parse(url)
    currentNode = h('a', {
      on: {
        click: () => {
          router.push({ name: jsonObj.component_name, params: jsonObj.params }).catch(() => {})
        }
      }
    }, t_message)
    // currentNode = h('span', null, t_message)
  } else {
    currentNode = h('span', null, t_message)
  }
  if (id) {
    const defaultNode = h('div',
      null,
      [
        h('a', {
          attrs: { class: 'is-underline el-link el-link--primary' }, on: {
            click: () => {
              updateMsgSysRecord(id).finally(() => {
                if (notification) {
                  notification.close()
                }
              })
            }
          }
        }, i18n.t('notification.read')),
        h('a', {
          attrs: { class: 'is-underline el-link el-link--danger' }, on: {
            click: () => {
              deleteMsgSysRecord(id).finally(() => {
                if (notification) {
                  notification.close()
                }
              })
            }
          }
        }, i18n.t('common.delete'))
      ])
    vnode = h('div', { attrs: { class: 'nt-container' }}, [currentNode, defaultNode])
  } else {
    vnode = h('div', { attrs: { class: 'nt-container' }}, [currentNode])
  }

  notification = Notification.info({
    title: t_title,
    dangerouslyUseHTMLString: true,
    message: vnode,
    position: 'bottom-right',
    type: t_type,
    duration: 30000,
    onClose: function() {
      store.dispatch('message/getUnreadTotal')
    }
  })
}

// function setRead(id) {
//   if (!id) {
//     return
//   }
//   updateMsgSysRecord(id).then(() => {
//     store.dispatch('message/getUnreadTotal')
//   })
// }

// function remove(id) {
//   if (!id) {
//     return
//   }

//   deleteMsgSysRecord(id).then(() => {
//     store.dispatch('message/getUnreadTotal')
//   })
// }

