import store from '../index'
import { getCommonMallConfig } from '@/api/mall/mallConfig'

const state = {
  mallPayConfig: {
    weChatPay: 1,
    payType: '',
    merchantName: '',
    serviceProviderOfficial: '',
    serviceProviderPayMerchant: '',
    publicAppID: '',
    payMerchant: '',
    payKey: '',
    certificateFile: '',
    keyFile: '',
    rootFile: '',
    balancePayment: 2,
    deliveryCash: 2,
    offlinePayment: 2
  }
}

const mutations = {
  SET_CONFIG: (state, mallPayConfig) => {
    if (mallPayConfig) {
      mallPayConfig.weChatPay = mutations.CONVERT_INT(mallPayConfig.weChatPay, 1)
      mallPayConfig.payType = mutations.CONVERT_INTS(mutations.CONVERT_INTS())
      mallPayConfig.merchantName = mutations.CONVERT_INTS(mallPayConfig.merchantName)
      mallPayConfig.serviceProviderOfficial = mutations.CONVERT_INTS(mallPayConfig.serviceProviderOfficial)
      mallPayConfig.serviceProviderPayMerchant = mutations.CONVERT_INTS(mallPayConfig.serviceProviderPayMerchant)
      mallPayConfig.publicAppID = mutations.CONVERT_INTS(mallPayConfig.publicAppID)
      mallPayConfig.payKey = mutations.CONVERT_INTS(mallPayConfig.payKey)
      mallPayConfig.certificateFile = mutations.CONVERT_INTS(mallPayConfig.certificateFile)
      mallPayConfig.keyFile = mutations.CONVERT_INTS(mallPayConfig.keyFile)
      mallPayConfig.rootFile = mutations.CONVERT_INTS(mallPayConfig.rootFile)
      mallPayConfig.balancePayment = mutations.CONVERT_INT(mallPayConfig.balancePayment, 2)
      mallPayConfig.deliveryCash = mutations.CONVERT_INT(mallPayConfig.deliveryCash, 2)
      mallPayConfig.offlinePayment = mutations.CONVERT_INT(mallPayConfig.offlinePayment, 2)
      state.mallPayConfig = Object.assign({}, state.mallPayConfig, mallPayConfig, false)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value) => {
    return value
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      const type = 'pay'
      getCommonMallConfig(type).then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
