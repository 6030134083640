
import { getUnReadMsgSystemRecordTotal } from '@/api/message/systemRecord'
import store from '../index'
const state = {
  message: {
    visible: false,
    unreadTotal: 0
  }
}

const mutations = {
  SET_CONFIG: (state, message) => {
    if (message) {
      // message.visible = mutations.SET_VISIBLE(message.visible, false)
      message.unreadTotal = mutations.CONVERT_INT(message.unreadTotal, 0)
      state.message = Object.assign({}, state.message, message)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  SET_VISIBLE(value, defaultValue) {
    if (value) {
      return true
    } else {
      return defaultValue
    }
  },
  UPDATE_VISIBLE: (state, visible) => {
    state.message.visible = visible
  }
}

const actions = {
  getUnreadTotal({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }

      getUnReadMsgSystemRecordTotal().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  setVisible({ commit }, visible) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('UPDATE_VISIBLE', false)
        resolve()
        return
      } else {
        commit('UPDATE_VISIBLE', visible)
        resolve()
        return
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
