<template>
  <el-checkbox-group
    v-model="newValue"
    size="small"
    v-bind="attrs"
    @input="handleChange"
  >
    <el-checkbox
      v-for="item in options"
      :key="item.value"
      :label="item.value"
    >{{ item.text }}</el-checkbox>
  </el-checkbox-group>
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableCheckbox',
  mixins: [EleEditableMixin]
}
</script>
