<template>
  <el-radio-group
    v-model="newValue"
    size="small"
    v-bind="attrs"
    @input="handleChange"
  >
    <el-radio
      v-for="item in options"
      :key="item.value"
      :label="item.value"
    >{{ item.text }}</el-radio>
  </el-radio-group>
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableRadio',
  mixins: [EleEditableMixin]
}
</script>
