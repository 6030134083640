<template>
  <el-date-picker
    v-model="newValue"
    :clearable="true"
    size="small"
    style="max-width: 140px"
    v-bind="attrs"
    @input="handleTimeChange"
  />
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableDate',
  mixins: [EleEditableMixin],
  data() {
    return {
      defaultAttrs: {
        format: 'yyyy-MM-dd'
      }
    }
  },
  methods: {
    setNewValue() {
      this.setTimeNewValue()
    }
  }
}
</script>
