import store from '../index'
import { getCommonMallConfig } from '@/api/mall/mallConfig'

const state = {
  mallSalesAfterConfig: {
    afterApplication: 2,
    orderFinish: '7',
    refundInstructions: '',
    singleReturnExchange: 2,
    timeoutCancelActivist: 2,
    agreeReturnExchange: '1'
  }
}

const mutations = {
  SET_CONFIG: (state, mallSalesAfterConfig) => {
    if (mallSalesAfterConfig) {
      mallSalesAfterConfig.afterApplication = mutations.CONVERT_INT(mallSalesAfterConfig.afterApplication, 2)
      mallSalesAfterConfig.orderFinish = mutations.CONVERT_INTS(mallSalesAfterConfig.orderFinish)
      mallSalesAfterConfig.refundInstructions = mutations.CONVERT_INTS(mallSalesAfterConfig.refundInstructions)
      mallSalesAfterConfig.singleReturnExchange = mutations.CONVERT_INT(mallSalesAfterConfig.singleReturnExchange, 2)
      mallSalesAfterConfig.timeoutCancelActivist = mutations.CONVERT_INT(mallSalesAfterConfig.timeoutCancelActivist, 2)
      mallSalesAfterConfig.agreeReturnExchange = mutations.CONVERT_INTS(mallSalesAfterConfig.agreeReturnExchange)
      state.mallSalesAfterConfig = Object.assign({}, state.mallSalesAfterConfig, mallSalesAfterConfig, false)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value) => {
    return value
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      const type = 'sales_after'
      getCommonMallConfig(type).then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
