<template>
  <div class="app-container">
    <caracal-scrollbar ref="caracalScrollbar" :show-horizontal="false" class="view-scrollbar" @scrollbarScroll="scrollbarScroll">
      <div class="view-container">
        <slot />
      </div>
    </caracal-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
  methods: {
    scrollbarScroll(e) {
      this.$emit('scrollbarScroll', {
        scrollTop: e.scrollTop,
        scrollLeft: e.scrollLeft,
        moveY: e.moveY,
        moveX: e.moveX
      })
    },
    scrollToTop(scrollTop) {
      this.$refs.caracalScrollbar.scrollToTop(scrollTop)
    }
  }
}
</script>
<style lang="scss">
.app-main {
  > .app-container {
    height: 100vh;
  }
}
</style>
<style lang="scss" scoped>
.app-main {
  > .app-container {
    height: 100vh;
    >.view-scrollbar {
      height: 100%;
      .view-container {
        padding: 20px;
      }
    }
  }
}

.fixed-header{
  +.app-main {
    >.app-container{
      height: calc(100vh - 50px);
      .view-container {
        min-height: calc(100vh - 50px);
      }
    }
  }
}

.hasTagsView {
  .fixed-header{
    +.app-main {
      >.app-container{
         height: calc(100vh - 84px);
         .view-container {
           min-height: calc(100vh - 84px);
         }
      }
    }
  }
}
</style>
