import request from '@/utils/request'

// 查询分类设置
export function getMallCategoryConfig() {
  return request({
    url: '/mall/config/category/list',
    method: 'get'
  })
}
// 保存分类设置
export function saveMallCategoryConfig(data) {
  return request({
    url: '/mall/config/category/save',
    method: 'post',
    data
  })
}

export function getCategoryOneById() {
  return request({
    url: '/mall/category/one',
    method: 'get'
  })
}
// 查询全部分类
export function getAllMallCategoryList() {
  return request({
    url: '/mall/category/list',
    method: 'get'
  })
}
// 查询已启用分类
export function getEnableMallCategoryList() {
  return request({
    url: '/mall/category/enable/list',
    method: 'get'
  })
}
// 查询全部分类树
export function getAllMallCategoryTree() {
  return request({
    url: '/mall/category/tree',
    method: 'get'
  })
}
// 查询已启用分类树
export function getEnableMallCategoryTree() {
  return request({
    url: '/mall/category/enable/tree',
    method: 'get'
  })
}

// 查询设置
export function getCategoryConfigList() {
  return request({
    url: '/mall/category/config/list',
    method: 'get'
  })
}

// 查询分类
export function getCategoryById(id) {
  if (!id) {
    return
  }
  return request({
    url: `/mall/category/${id}`,
    method: 'get'
  })
}
// 添加分类
export function addMallCategory(data) {
  return request({
    url: '/mall/category/add',
    method: 'post',
    data
  })
}

// 编辑分类
export function updateMallCategoryById(id, data) {
  return request({
    url: `/mall/category/${id}`,
    method: 'put',
    data
  })
}
// 编辑分类
export function deleteMallCategoryById(id) {
  return request({
    url: `/mall/category/${id}`,
    method: 'delete'
  })
}
