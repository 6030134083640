import request from '@/utils/request'

export function getFinanceConfigList() {
  return request({
    url: '/finance/common/list',
    method: 'get'
  })
}

// 支付模板 获取 资金账户 list
export function getFinanceAccountInfoList() {
  return request({
    url: '/finance/common/accounts',
    method: 'get'
  })
}

// 业务类型+业务id 查应收款
export function getSingleFinanceReceipt(data) {
  return request({
    url: `/finance/common/single-receipt`,
    method: 'post',
    data
  })
}

// 业务类型+业务id 查应收款
export function getFinanceReceiptInfosByBusinessIds(data) {
  return request({
    url: `/finance/common/receipt-list`,
    method: 'post',
    data
  })
}
