import { getAllConfigs, getTenantSystemName } from '@/api/config'
import { getFinanceBasicData } from '@/api/finance/finance'
import store from '../index'

const state = {
  config: {
    systemLogo: '',
    systemName: '',
    companyName: '',
    address: '',
    phone: '',
    fax: '',
    website: '',
    email: '',
    dataDesensitization: 0,
    amountDecimal: 2,
    quantityDecimal: 0,
    automaticallyClearRecipients: 0,
    productCodeReconciliation: 0,
    customerCodeReconciliation: 0,
    customerCodeReset: 0,
    supplierCodeReconciliation: 0,
    supplierCodeReset: 0,
    salesOrderCodeReconciliation: 0,
    checkNegativeInventory: 0,
    costingMethod: 0,
    salesReconciliation: 0,
    purchaseReconciliation: 0,
    receivablesCreateRule: 0,
    payableCreateRule: 0,
    outPayableCreateRule: 0,
    multiTerminalLogin: 1,
    userRandomPassword: 1,
    userDefaultPassword: '123456',
    userNeedChangePassword: 1,
    passwordErrorLockCount: 5,
    standardCurrency: '',
    advanceStockConfig: '',
    mallOrderStockConfig: 2,
    overLoadInventoryIn: 0,
    overLoadInventoryOut: 0,
    wechatOrgSyncSecret: '',
    wechatCorpId: '',
    wechatAppAgentId: '',
    wechatAppSecret: '',
    weworkLogin: 0,
    allowMainPageSetting: 0,
    kfToken: '', // 微信客户 token
    kfAESKey: '', // 微信客户 aes key
    kfSecret: '', // 微信客户secret
    weworkKf: 0, // 微信客服开关
    defaultKf: '',
    wxCpProviderModel: false
  },
  currentId: ''
}
const mutations = {
  SET_CONFIG: (state, config) => {
    if (config) {
      config.dataDesensitization = mutations.CONVERT_INT(config.dataDesensitization, 0)
      config.amountDecimal = mutations.CONVERT_INT(config.amountDecimal, 2)
      config.quantityDecimal = mutations.CONVERT_INT(config.quantityDecimal, 0)
      config.automaticallyClearRecipients = mutations.CONVERT_INT(config.automaticallyClearRecipients, 0)
      config.productCodeReconciliation = mutations.CONVERT_INT(config.productCodeReconciliation, 0)
      config.customerCodeReconciliation = mutations.CONVERT_INT(config.customerCodeReconciliation, 0)
      config.customerCodeReset = mutations.CONVERT_INT(config.customerCodeReset, 0)
      config.supplierCodeReconciliation = mutations.CONVERT_INT(config.supplierCodeReconciliation, 0)
      config.supplierCodeReset = mutations.CONVERT_INT(config.supplierCodeReset, 0)
      config.salesOrderCodeReconciliation = mutations.CONVERT_INT(config.salesOrderCodeReconciliation, 0)
      config.checkNegativeInventory = mutations.CONVERT_INT(config.checkNegativeInventory, 0)
      config.costingMethod = mutations.CONVERT_INT(config.costingMethod, 0)
      config.salesReconciliation = mutations.CONVERT_INT(config.salesReconciliation, 0)
      config.purchaseReconciliation = mutations.CONVERT_INT(config.purchaseReconciliation, 0)
      config.receivablesCreateRule = mutations.CONVERT_INT(config.receivablesCreateRule, 0)
      config.payableCreateRule = mutations.CONVERT_INT(config.payableCreateRule, 0)
      config.outPayableCreateRule = mutations.CONVERT_INT(config.outPayableCreateRule, 0)
      config.multiTerminalLogin = mutations.CONVERT_INT(config.multiTerminalLogin, 1)
      config.passwordErrorLockCount = mutations.CONVERT_INT(config.passwordErrorLockCount, 5)
      config.userRandomPassword = mutations.CONVERT_INT(config.userRandomPassword, 1)
      config.userDefaultPassword = config.userDefaultPassword || '123456'
      config.userNeedChangePassword = mutations.CONVERT_INT(config.userNeedChangePassword, 1)
      config.standardCurrency = config.standardCurrency ? config.standardCurrency : ''
      config.advanceStockConfig = mutations.CONVERT_STR(config.advanceStockConfig, [])
      config.mallOrderStockConfig = mutations.CONVERT_INT(config.mallOrderStockConfig, 0)
      config.overLoadInventoryIn = mutations.CONVERT_INT(config.overLoadInventoryIn, 0)
      config.overLoadInventoryOut = mutations.CONVERT_INT(config.overLoadInventoryOut, 0)
      config.wechatOrgSyncSecret = config.wechatOrgSyncSecret ? config.wechatOrgSyncSecret : ''
      config.wechatCorpId = config.wechatCorpId ? config.wechatCorpId : ''
      config.wechatAppAgentId = config.wechatAppAgentId ? config.wechatAppAgentId : ''
      config.wechatAppSecret = config.wechatAppSecret ? config.wechatAppSecret : ''
      config.weworkLogin = mutations.CONVERT_INT(config.weworkLogin, 0)
      config.allowMainPageSetting = mutations.CONVERT_INT(config.allowMainPageSetting, 0)
      config.weworkKf = mutations.CONVERT_INT(config.weworkKf, 0)
      config.wxCpProviderModel = config.wxCpProviderModel === '1'

      state.config = Object.assign({}, state.config, config)
    }
  },
  CONVERT_STR(str, defaultValue) {
    if (!str) {
      return []
    }
    let array = str.split(',')
    array = array.map(item => {
      return mutations.CONVERT_INT(item)
    })
    return array
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value && value !== '0') {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  SET_CONFIG_ITEM: (state, item) => {
    if (item && item.configKey) {
      state.config[item.configKey] = item.configValue
    }
  },
  SET_SYSTEM_NAME: (state, name) => {
    if (state.config) {
      state.config.systemName = name
    }
  }
}
const actions = {
  getTenantSystemName({ commit }) {
    return new Promise((resolve, reject) => {
      getTenantSystemName().then(response => {
        const { data } = response
        commit('SET_SYSTEM_NAME', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取配置信息
  getAllConfigs({ commit }) {
    var currentId = ''
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getFinanceBasicData().then(response => {
        const { data } = response
        const currencies = data.currencyType
        if (currencies.length > 0) {
          currentId = currencies[0].id
        }
      }).then(() => {
        getAllConfigs().then(response => {
          const { data } = response
          if (data.systemLogo) {
            if (!data.systemLogo.startsWith('http://') && !data.systemLogo.startsWith('https://')) {
              data.systemLogo = process.env.VUE_APP_BASE_API + '/upload/' + data.systemLogo
            }
          }
          if (!data.standardCurrency) {
            data.standardCurrency = currentId
            commit('SET_CONFIG', data)
          } else {
            commit('SET_CONFIG', data)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    })
  },
  updateConfigItem({ commit }, item) {
    commit('SET_CONFIG_ITEM', item)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
