import store from '../index'
import { getCommonMallConfig } from '@/api/mall/mallConfig'

const state = {
  mallAppletConfig: {
    mallAppletAppId: '',
    mallAppletAppValue: '',
    mallAppletAppMaintenance: 1

  }

}

const mutations = {
  SET_CONFIG: (state, mallAppletConfig) => {
    if (mallAppletConfig) {
      mallAppletConfig.mallAppletAppId = mutations.CONVERT_INTS(mallAppletConfig.mallAppletAppId)
      mallAppletConfig.mallAppletAppValue = mutations.CONVERT_INTS(mallAppletConfig.mallAppletAppValue)
      mallAppletConfig.mallAppletAppMaintenance = mutations.CONVERT_INT(mallAppletConfig.mallAppletAppMaintenance, 1)
      state.mallAppletConfig = Object.assign({}, state.mallAppletConfig, mallAppletConfig, false)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value) => {
    return value
  }

}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      const type = 'applet'
      getCommonMallConfig(type).then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
