import request from '@/utils/request'

// 辅助属性
export function getProductAssistConfig() {
  return request({
    url: '/common/product/assist-config'
  })
}

export function getProductInfosByProductIds(data) {
  return request({
    url: '/common/product/product-ids',
    method: 'POST',
    data
  })
}

export function getProductSortInfoForSelect(data) {
  return request({
    url: '/common/product/sort-select',
    method: 'POST',
    data
  })
}

// 产品品牌
export function getProductBrandList() {
  return request({
    url: '/common/product/brand/list',
    method: 'get'
  })
}
