import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import CaracalScrollbar from '@/components/Scrollbar/index'
import EleEditable from '@/plugins/vue-ele-editable/index'
import './styles/element-variables.scss'
import AppContainer from '@/components/AppContainer/index'

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'

import moment from 'moment'

import Enum from '@/lib/enum/index'
import enumInfo from '@/utils/enum-info'

import '@/styles/index.scss' // global css

import '@/assets/ali-font/iconfont.css'

import App from './App'
import store from './store'
import router from './router'

import i18n from './lang' // internationalization
import md5 from 'js-md5'
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import ElTableFooter from 'el-table-footer' // table添加多个底部统计
import vcolorpicker from 'vcolorpicker'

import WlGantt from 'wl-gantt'
import 'wl-gantt/lib/wl-gantt.css'

import { emptyContentFill, emptyContentFillWithMultilingual, emptyContentFillWithEnum, str2Int, str2Array } from '@/utils/data'
import { processMoney, processPrice, toPercent, toAmountDecimal, toQuantityDecimal, getProductGaugeQuantity, getProductVolume } from '@/utils/money'
import { processPhoneNumber } from '@/utils/processData'
import { popNotification } from '@/utils/notification'

import * as filters from './filters' // global filters

Vue.use(Element, {
  size: Cookies.get('size') || '', // set element-ui default size  medium
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(EleEditable, {
  image: {
    lazy: true
  },
  number: {
    step: 10
  }
})

Vue.use(CaracalScrollbar)
Vue.use(AppContainer)

Vue.use(ElTableFooter)
Vue.use(Enum, { enumInfo })

Vue.use(VXETable)
VXETable.use(VXETablePluginElement)
Vue.use(vcolorpicker)

Vue.use(WlGantt)

Vue.prototype.$moment = moment
Vue.prototype.$md5 = md5
Vue.prototype.$timeoutMilliseconds = 0.2 * 1000

Vue.prototype.$emptyContentFill = emptyContentFill
Vue.prototype.$emptyContentFillWithMultilingual = emptyContentFillWithMultilingual
Vue.prototype.$emptyContentFillWithEnum = emptyContentFillWithEnum
Vue.prototype.$processMoney = processMoney
Vue.prototype.$processPrice = processPrice
Vue.prototype.$getProductGaugeQuantity = getProductGaugeQuantity
Vue.prototype.$getProductVolume = getProductVolume
Vue.prototype.$toPercent = toPercent
Vue.prototype.$toAmountDecimal = toAmountDecimal
Vue.prototype.$toQuantityDecimal = toQuantityDecimal
Vue.prototype.$processPhoneNumber = processPhoneNumber
Vue.prototype.$sysNotification = popNotification
Vue.prototype.$str2Int = str2Int
Vue.prototype.$str2Array = str2Array

Vue.prototype.$maxQuantity = 1000000000
Vue.prototype.$maxPrice = 1000000000

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
