export default {
  route: {
    login: '系统登录',
    lock: '系统锁屏',
    error: '系统错误',
    changePassword: '修改密码',
    dashboard: '首页',
    systemSetting: '系统设置',
    orgManagement: '组织机构',
    userManagement: '用户管理',
    roleManagement: '角色管理',
    menuManagement: '菜单管理',
    syncToWework: '同步到企业微信',
    profile: '个人中心',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    audit: '审核',
    confirm: '确认',
    check: '查验',
    assigned: '指派',
    start: '开始',
    stop: '暂停',
    cancel: '取消',
    finish: '完成',
    activation: '激活',
    submit: '提交审核',
    revoke: '撤销',
    invalid: '作废',
    delay: '延期',
    pay: '付款',
    collect: '收款',
    details: '收支明细',
    adjust: '调整分类',
    adjustConfirm: '批量确认',
    setRole: '设置角色',
    configManagement: '参数设置',
    basicDataSetting: '基础数据',
    customerManage: '客户管理',
    basicDataCustomerLevel: '客户级别',
    basicDataCustomerSource: '客户来源',
    basicDataCustomerStatus: '客户状态',
    basicDataAddressType: '地址类型',
    basicDataContactType: '联系人类型',
    basicDataFollowUpType: '跟进类型',
    basicDataFollowUpWay: '跟进方式',
    basicDataIndustryType: '行业类型',
    basicDataVisitPurpose: '拜访目的',
    productManager: '产品管理',
    productData: '产品信息',
    productCategory: '产品分类',
    productUnit: '计价单位',
    productBrand: '产品品牌',
    financeManager: '财务管理',
    invoiceType: '开票类型',
    receivePayment: '收付款方式',
    settlementMethod: '结算方式',
    currencyType: '货币类型',
    taxRate: '税率',
    accountType: '账户类型',
    financeAccount: '资金账户',
    financeDeposit: '存取款单',
    salesReceipt: '销售回款',
    purchasePayment: '采购付款',
    financeReceivable: '应收款',
    financeAdvanceReceipt: '预收款',
    financeAdvancePayment: '预付款',
    financeApply: '付款申请',
    financePayable: '应付款',
    commonCustomer: '公共客户',
    customerAssignment: '客户分配',
    customerClaim: '客户认领',
    customerTransfer: '客户转移',
    inventoryInType: '入库类型',
    inventoryInStatus: '入库状态',
    salesManage: '销售管理',
    basicDataDeliveryType: '交货方式',
    salesQuotation: '报价单',
    salesInventoryLock: '锁定库存',
    salesOrder: '销售订单',
    salesDelivery: '销售发货',
    purchaseManage: '采购管理',
    basicDataSupplierType: '供应商类型',
    basicDataPurchaseDeliveryType: '送货方式',
    purchaseSupplierManager: '供应商管理',
    supplierContact: '供应商联系人',
    purchasePlan: '采购计划',
    purchaseOrder: '采购订单',
    purchaseOrderAlter: '采购变更单',
    purchaseReturn: '采购退货',
    purchaseAccount: '采购对账',
    purchasePay: '采购付款',
    purchaseInvoice: '采购发票',
    purchaseConfigSetting: '参数设置',
    inventoryManage: '库存管理',
    warehouse: '仓库管理',
    inventoryInNotification: '入库通知',
    inventoryOutNotification: '出库通知',
    inventoryIn: '入库单',
    inventoryOut: '出库单',
    inventoryAllocate: '调拨单',
    inventoryPack: '组装/拆装',
    purchaseReceipt: '采购收货',
    saleDelivery: '销售发货',
    inventoryStock: '库存余额',
    inventoryDetails: '出入库明细',
    inventoryCheck: '库存盘点',
    inventoryPurchaseReturn: '采购退货',
    inventorySalesReturn: '销售退货',
    inventoryFinishedProduct: '成品入库',
    inventoryCargoValue: '库存货值',
    inventoryEnableStock: '可用库存',
    inventoryWarning: '库存预警',
    crmClue: '线索管理',
    clueFollowUp: '跟进',
    clueTransfer: '转客户',
    myCustomer: '我的客户',
    contact: '联系人',
    sysCodeManage: '编码管理',
    customerFollowUp: '客户跟进',
    customerVisit: '拜访计划',
    verify: '审核',
    quote: '报价',
    toOrder: '转订单',
    delivery: '发货',
    collection: '收款',
    invoice: '开票',
    done: '完成',
    salesPresentation: '销售简报',
    scheduleCalendar: '日程日历',
    payableAndReceivable: '应收应付款',
    performanceRanking: '业绩排行',
    salesReturn: '销售退货',
    return: '退货',
    salesOrderView: '销售一览表',
    salesDeliveryView: '销售发货一览',
    salesOrderReport: '订单金额统计',
    salesDeliveryReport: '发货金额统计',
    financeReceivableTop: '客户应收款余额排行Top10',
    financePayableTop: '供应商应付款余额排行Top10',
    salesOrderRecentAmountAndCount: '销售订单销售额和订单数（近6个月）',
    salesOrderProductTop: '产品销售额排行Top10',
    salesOrderMonthOnYear: '销售额月度同比',
    financeAccountBookMonthOnYear: '销售回款金额月度同比',
    salesOrderCustomerAmount: '多维度销售分析',
    invoiceManage: '发票服务',
    invConfig: '参数设置',
    vatInvoice: '增值税发票',
    quotaInvoice: '定额发票',
    taxiInvoice: '出租车票',
    trainTicket: '火车发票',
    airTicket: '行程单发票',
    expenseType: '费用类型',
    salesExpense: '销售费用',
    purchaseExpense: '采购费用',
    purchasePrice: '采购价格',
    purchasePriceType: '价格类型',
    salesPrice: '销售价格',
    salesConfig: '参数设置',
    salesPriceType: '价格类型',
    salesGoal: '销售目标',
    reportConfigSetting: '打印模板',
    customerAreaCount: '客户区域分布',
    salesReport: '统计报表',
    purchaseReport: '统计报表',
    financeReport: '统计报表',
    paymentSummary: '收支汇总',
    purchaseOrderView: '采购订单一览',
    purchaseMonthlyReport: '订单月度统计',
    postSaleManage: '售后管理',
    postSaleOrder: '售后订单',
    serviceManage: '服务管理',
    serviceCategory: '服务分类',
    serviceOrder: '服务订单',
    serviceItem: '服务项目',
    productionManage: '生产管理',
    productionBom: '生产BOM',
    productionPlan: '生产计划',
    requisition: '请购单',
    purchaseOutRequire: '委外需求',
    productConfig: '参数设置',
    orderDeliverySummary: '销售汇总',
    orderReceiptSummary: '采购汇总',
    scheduleManage: '日程管理',
    calendarSchedule: '日程日历',
    basicDataScheduleType: '日程类型',
    manufacture: '生产',
    outSource: '委外',
    batchAudit: '批量审核',
    purchaseOrderOut: '委外订单',
    todayCustomerFollowUp: '客户跟进',
    inventoryAccount: '库存台账',
    projectManagement: '项目管理',
    basicDataGroup: '项目分组',
    projectList: '项目列表',
    projectListAdd: '创建项目',
    projectListSetting: '设置',
    projectListArchive: '归档',
    projectArchive: '归档项目',
    projectReactivation: '重新激活',
    inventoryInOutsourcing: '委外入库',
    inventoryOutOutsourcing: '委外领料',
    crmReport: '统计报表',
    userBehaviorReport: '业务员行为统计',
    projectTask: '任务列表',
    postSaleProduct: '售后产品',
    receipt: '收款单',
    payment: '付款单',
    incomeType: '收入类型',
    expenditureType: '支出类型',
    basicDataCustomerSort: '客户分类',
    basicDataInvoiceCategory: '发票类型',
    invoiceApplication: '开票申请',
    invoiceDelivery: '交付',
    sign: '签收',
    invoiceInfo: '发票信息',
    invoiceRecord: '发票登记',
    multiAddress: '多地址发货',
    expireManage: '临期管理',
    aboutToExpire: '即将到期',
    expiredProduct: '已到期',
    currencyExchangeRate: '货币汇率',
    dynamicSalesRate: '商品动销率',
    financeConfig: '参数设置',
    customerCredit: '客户授信',
    orderExport: '订单导出',
    orderDetailExport: '订单明细导出',
    deliveryDetailExport: '发货单明细导出',
    salesSettlementPeriod: '结算期限',
    inventoryStockExport: '库存余额导出',
    addPackScheme: '添加组合方案',
    financeAdvanceReceiptManage: '预收款',
    salesOrderImport: 'b2c订单导入',
    inventoryInPurchaseReceiptAdd: '采购收货添加',
    inventoryInPurchaseReceiptEdit: '采购收货编辑',
    inventoryInPurchaseReceiptDelete: '采购收货删除',
    inventoryInPurchaseReceiptRevoke: '采购收货撤销',
    inventoryInSalesReturnAdd: '销售退货添加',
    inventoryInSalesReturnEdit: '销售退货编辑',
    inventoryInSalesReturnDelete: '销售退货删除',
    inventoryInSalesReturnRevoke: '销售退货撤销',
    inventoryInOutsourcingAdd: '委外入库添加',
    inventoryInOutsourcingEdit: '委外入库编辑',
    inventoryInOutsourcingDelete: '委外入库删除',
    inventoryInOutsourcingRevoke: '委外入库撤销',
    inventoryOutSalesDeliveryAdd: '销售发货添加',
    inventoryOutSalesDeliveryEdit: '销售发货编辑',
    inventoryOutSalesDeliveryDelete: '销售发货删除',
    inventoryOutSalesDeliveryRevoke: '销售发货撤销',
    inventoryOutPurchaseReturnAdd: '采购退货添加',
    inventoryOutPurchaseReturnEdit: '采购退货编辑',
    inventoryOutPurchaseReturnDelete: '采购退货删除',
    inventoryOutPurchaseReturnRevoke: '采购退货撤销',
    inventoryOutOutsourcingAdd: '委外出库添加',
    inventoryOutOutsourcingEdit: '委外出库删除',
    inventoryOutOutsourcingDelete: '委外出库删除',
    inventoryOutOutsourcingRevoke: '委外出库撤销',
    salesOrderB2c: 'B2C订单',
    import: '导入',
    salesOrderB2cGeneDelivery: '生成销售发货单',
    salesOrderB2cGeneReceivable: '生成应收款',
    salesOrderB2cGeneCollection: '生成收款单',
    salesOrderB2cGeneInventoryOut: '生成出库单',
    todoList: '待办事项',
    onlineMall: '在线商城',
    mallConfig: '商城设置',
    export: '导出',
    memberManagement: '会员管理',
    memberList: '会员列表',
    memberLevel: '会员等级',
    mallCategory: '商品分类',
    mallGoods: '商品管理',
    mallOrder: '订单管理',
    editAddress: '修改发货地址',
    close: '订单关闭',
    changePrice: '订单改价',
    theGoods: '确认收货',
    onShelf: '上架',
    offShelf: '下架',
    recovery: '恢复',
    tenantPortal: '商业门户',
    recommenderReview: '推荐人管理',
    productReview: '商品审核',
    parameterConfig: '参数设置',
    tenantPortalManage: '门户管理',
    pushdown: '小店必选商品管理',
    mallNews: '资讯管理',
    mallReturn: '退换货',
    inventoryLock: '锁定库存',
    reCalc: '重算',
    mallSelfPick: '自提点',
    memberBinding: '会员绑定',
    freightTemplate: '运费模板',
    memberConfig: '参数设置',
    financeCommission: '佣金一览',
    crmConfig: '参数设置',
    mallInvoice: '发货单',
    distributionApply: '分销申请',
    productionOrder: '生产订单',
    productionPick: '生产领料',
    productionReturn: '生产退料',
    productionFinishIn: '成品入库',
    begin: '开始',
    enterpriseManage: '企业互联',
    enterpriseConfig: '参数设置',
    enterpriseConnectApply: '互联申请',
    enterpriseInterconnect: '企业互联',
    outOrder: '委外订单',
    smsSign: '短信签名',
    permissions: '权限设置',
    view: '查看',
    mallMarketingCampaign: '营销活动',
    expressManage: '快递服务',
    expressAddressNote: '地址簿',
    expressOrderManage: '快递订单',
    expressAccountInfo: '账号信息',
    expressCompanyAccount: '电子面单配置',
    expressRechargeRecord: '充值记录',
    operate: '操作',
    expressMonitor: '物流监控',
    expressBatchQuery: '批量查询',
    enterpriseOrder: '互联订单',
    mallLiveConfig: '直播设置',
    salesOrderAdjust: '销售订单变更',
    adjustOrder: '变更订单',
    print: '打印',
    advanceReceiptType: '预收款类型',
    inventoryOutNotificationPrint: '出库通知打印',
    messageNotify: '消息通知',
    salesDeliveryAdjust: '销售发货变更',
    externalContact: '企微联系人',
    mallGiftBox: '礼盒定制',
    distributionManage: '分销管理',
    b2cDistributionApply: 'B2C分销审核',
    b2cDistributionDetail: 'B2C分销小店',
    auth: '授权',
    salesActivityPromotion: '活动促销',
    salesStatement: '销售对账单',
    wechatKf: '微信客服',
    messageService: '消息服务',
    smsRecharge: '短信充值',
    smsTemplate: '短信模板',
    smsSend: '短信发送',
    welfareManage: '卡券兑换',
    parameterSettings: '参数设置',
    end: '终止',
    trash: '作废',
    salesReconciliation: '销售对账',
    cooperationDeposit: '合作保证金',
    welfarePlan: '卡券方案',
    appSettings: '小程序设置',
    welfareGoods: '卡券商品',
    exchangeOrder: '兑换订单',
    welfareIssue: '福利发放',
    memberVoucherShareRecord: '代金券赠送记录',
    browseRecord: '访问记录',
    promotionalPoster: '推广海报',
    mallPoster: '多商品海报',
    mallProfilePhotoAlbum: '简介相册',
    mallQualificationFile: '资质文件',
    thirdPartOrder: '三方订单',
    thirdPartOrderConfig: '参数设置',
    guanAiTong: '关爱通',
    overtimeOrder: '订单监控',
    performanceRebate: '业绩返点',
    commissionExtract: '佣金提现',
    rebateExtract: '返点提现',
    customerRebate: '返点一览',
    cardActivate: '卡券发放',
    projectConfig: '参数设置',
    dashboardInventoryWaring: '库存预警',
    distributionOrder: '代发订单',
    distributionDelivery: '代发发货单',
    complete: '完成',
    salesOpportunity: '销售机会',
    salesOpportunitySource: '商机来源',
    productPrice: '产品价格',
    inventoryConfig: '参数设置',
    applicationApi: '应用API',
    purchaseOutOrderAlter: '委外变更单',
    dumallManage: '嘟麦集肆',
    dumallConfig: '参数设置',
    dumallApplyment: '店铺审核',
    dumallShop: '授权店铺',
    dumallInvitation: '邀请注册',
    offlineStore: '线下门店',
    storeCategory: '门店分类',
    storeInfo: '门店列表',
    inventorySecondment: '库存借调',
    purchaseOutReturn: '委外退货',
    inventorySecondmentInWarehouseStock: '可用库存不足，请借调双方协商处理',
    printTitle: '打印库存借调单',
    orderAdjust: '变更',
    adjustAudit: '变更审核',
    openPlatform: '开放平台',
    openAuthorized: '开放授权',
    salesOrderPreview: '报价单',
    welfareDelivery: '兑换发货单',
    customerType: '客户类型',
    businessAnalysis: '经营分析',
    businessAnalysisConfig: '参数设置',
    businessDigital: '经营有数',
    businessAnalysisCalc: '分析',
    dumallRegisterCode: '嘟麦集肆注册码',
    invoiceApply: '开票申请',
    databoard: '数据大屏',
    databoardInfo: '大屏信息',
    excel: '导入导出',
    productKind: '产品品类',
    welfareConfig: '参数设置',
    productReport: '统计报表',
    productQuantity: '产品销售统计',
    storeConfig: '参数设置',
    storeCash: '门店收银',
    storeOrder: '门店订单',
    supplier: '供应商管理',
    salesProfit: '销售毛利',
    interconnectGoods: '互联商品',
    customPrice: '自定义价格'
  },
  navbar: {
    dashboard: '首页',
    logOut: '退出登录',
    profile: '个人中心',
    lockScreen: '锁屏',
    message: {
      title: '消息',
      markAsRead: '标记为已读',
      allMessage: '全部消息',
      showReadMessageOnly: '仅显示未读消息',
      empty: '暂无数据',
      markAllAsRead: '全部标记为已读',
      deleteAllRead: '删除全部已读消息',
      deleteTips: '确定删除全部已读消息吗'
    }
  },
  notification: {
    read: '已读'
  },
  common: {
    enabledState: '启用状态',
    isEnabled: '是否启用',
    enabled: '启用',
    disabled: '禁用',
    open: '开启',
    close: '关闭',
    include: '包含',
    exclude: '不包含',
    lockedState: '锁定状态',
    isLocked: '是否锁定',
    locked: '锁定',
    unlocked: '未锁定',
    choose: '选择',
    view: '查看',
    list: '列表',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    search: '查询',
    actions: '操作',
    save: '保存',
    cancel: '取消',
    revoke: '撤销',
    ok: '确定',
    submit: '提交',
    reset: '重置',
    refresh: '刷新',
    saveDraft: '保存草稿',
    done: '完成',
    pay: '付款',
    print: '打印',
    design: '设计',
    printWithPrice: '打印（含价格）',
    printNoPrice: '打印（不含价格）',
    preview: '预览',
    rename: '重命名',
    download: '下载',
    downloadTemplate: '下载模板',
    upload: '上传',
    checkAll: '全选',
    cancelCheckAll: '取消全选',
    enterKeyQuery: '请输入关键词进行查询',
    saveSuccess: '保存成功',
    deleteSuccess: '删除成功',
    addSuccess: '添加成功',
    updateSuccess: '更新成功',
    operateSuccess: '操作成功',
    operateFail: '操作失败',
    tips: '提示',
    uploadSuccess: '上传成功',
    uploadFail: '上传失败',
    uploaded: '已上传',
    sex: '性别',
    male: '男',
    female: '女',
    secret: '保密',
    unlockScreen: '解锁',
    more: '更多',
    returnLogin: '返回登录',
    changePasswordDesc: '当您第一次登录或重置密码之后，需要修改密码才能继续使用',
    selectDefaultText: '请选择',
    yes: '是',
    no: '否',
    codeCreateAuto: '编码自动生成',
    pleaseInputContent: '请输入内容',
    isCancel: '暂未保存，是否取消？',
    isDelete: '是否删除？',
    remark: '备注',
    pleaseSelect: '请选择',
    pleaseSelectSubordinateCompany: '请选择所属公司',
    copy: '复制',
    back: '返回',
    attachment: '附件',
    pleaseSelectSupplier: '请选择供应商',
    selectProduct: '选择产品',
    selectProductMaterial: '选择物料产品',
    pleaseChooseCustomer: '请选择客户',
    pleaseChooseContact: '请选择联系人',
    pleaseChooseCustomerManager: '请选择业务员',
    pleaseChooseOperator: '请选择经办人',
    to: '至',
    deleteError: '删除失败',
    pleaseChooseProduct: '请选择产品',
    country: '国家',
    area: '区域',
    address: '详细地址',
    selectCustomer: '选择客户',
    startDate: '开始日期',
    dateTo: '至',
    endDate: '结束日期',
    pastWeek: '最近一周',
    pastMonth: '最近一个月',
    pastQuarter: '最近三个月',
    pastHalfYear: '最近半年',
    pastYear: '最近一年',
    thisWeek: '本周',
    thisMonth: '本月',
    thisYear: '今年',
    lastYear: '去年',
    error: '错误',
    total: '合计',
    all: '全部',
    productsNotEmpty: '至少选择一条产品',
    reportTitle: '报表',
    noData: '暂无数据',
    saveAndSubmit: '保存并提交',
    saveAndAuditing: '保存并审核',
    saveAndConfirm: '保存并确认',
    saveAndOnShelf: '保存并上架',
    pleaseChooseWarehouseManager: '请选择仓管员',
    isTaxPrice: '单价是否含税',
    verify: '审核',
    unpaid: '未支付',
    paid: '已支付',
    businessUnitPlease: '请选择往来单位',
    year: '年',
    month: '月',
    dateRangeOut: '选择日期范围不能超过一年',
    production: '生产',
    purchase: '采购',
    pageTotal: '本页合计',
    allPageTotal: '全页合计',
    invalid: '作废',
    details: '收支明细',
    fullAddress: '详细地址',
    productAssistValue: '辅助属性',
    uploadImage: '上传图片',
    screenshotUpload: '截图上传',
    screenshotholder: '请按Ctrl+V或在此处右键选择【粘贴】上传截图图片',
    screenshotError: '请截图后再操作',
    version: '版本',
    notification: '通知',
    basicInfo: '基本信息',
    relatedBusiness: '关联业务',
    disassociate: '取消关联',
    other: '其它',
    pleaseChooseUser: '请选择用户',
    company: '所属公司',
    pleaseChooseCompany: '请选择所属公司(在财务参数设置中的开票信息添加公司)',
    status: '状态',
    customer: '客户',
    currency: '货币',
    model: '规格型号',
    unit: '单位',
    quantity: '数量',
    unitPrice: '单价',
    unitPriceTax: '含税单价',
    amount: '金额',
    taxRate: '税率',
    tax: '税额',
    baseInfo: '基本信息',
    export: '数据导出',
    import: '数据导入',
    stockExport: '库存数据导出',
    stockImport: '库存数据导入',
    supplier: '供应商',
    clear: '清空',
    revokeContinue: '继续撤销',
    quit: '退出',
    autoDeliverySuccess: '自动生成发货单成功',
    autoDeliveryError: '自动生成发货单失败',
    selected: '已选',
    handle: '处理',
    inventoryRemark: '仓库备注',
    noOptionAvailable: '无可用选项',
    creatorId: '创建者',
    createTime: '创建时间',
    modifyTime: '更新时间',
    sort: '排序',
    pass: '通过',
    config: '配置',
    reCalc: '重算',
    lastUse: '最近使用',
    emptyText: '暂无信息',
    audit: '审核',
    reject: '驳回',
    agree: '同意',
    refuse: '拒绝',
    unbound: '解绑',
    bind: '绑定',
    draft: '草稿',
    pleaseChooseProductSort: '请选择产品分类',
    pleaseChooseProductBrand: '请选择产品品牌',
    setDefault: '设置为默认',
    default: '默认',
    recalRate: '重算进度',
    salesDeliveryProductSelect: '销售发货单产品选择',
    purchaseReturnProductSelect: '采购退货单产品选择',
    mallDeliveryProductSelect: '商城发货单产品选择',
    purchaseOrderProductSelect: '采购订单产品选择',
    salesReturnProductSelect: '销售退货单产品选择',
    optional: '选填',
    saveAndCreate: '保存并新增',
    update: '更新',
    generate: '生成',
    notRelated: '未关联',
    related: '已关联',
    freightTemplate: '运费模板',
    canceled: '已作废',
    pleaseSelectTemplate: '请选择运费模板',
    freight: '运费',
    amountWithTax: '金额含税',
    requireTabInfo: '委外需求',
    salesOrder: '销售订单',
    b2cOrder: 'B2C订单',
    currentEnterpriseName: '当前企业名称',
    qrcode: '二维码',
    taxRateEmpty: '请选择产品税率',
    confirm: '确认',
    expressTips: '该快递信息仅做仓库出库参考，快递详情以出库单为准',
    expressFullInfoPlease: '请完整填写快递信息，快递公司 + 快递单号',
    notAudit: '待审核',
    audited: '已审核',
    rejected: '已驳回',
    prevStep: '上一步',
    nextStep: '下一步',
    success: '成功',
    fail: '失败',
    submitAudit: '提交审核',
    saveAndAudit: '保存并审核',
    ongoing: '进行中',
    inSettlement: '结算中',
    extracted: '已提现',
    completed: '已完成',
    serialNumber: '序号',
    attentionTips1: '建议使用官方Excel模板导入数据',
    attentionTips2: '上传EXCEL后，请准确填写标题行号和数据起始行号',
    attentionTips3: '请准确填写数据映射关系并检查数据预览是否相符',
    startMonth: '开始年月',
    endMonth: '结束年月',
    startYear: '开始年份',
    endYear: '结束年份',
    removeImageConfirmTip: '是否确定删除该图片？',
    mallOrder: '商城订单',
    distributionOrder: '代发订单',
    generateRsaKey: '生成RSA密钥',
    random: '随机',
    fixed: '固定',
    systemUpdateTitle: '系统更新',
    systemUpdateContent: '系统已更新，请刷新浏览器或点击下方的【更新】按钮',
    systemUpdateContentTip: '如您还有未完成操作，可以点击【稍后更新】，待您操作完成后点击屏幕右下方的【更新】按钮',
    updateLater: '稍后更新',
    productVolume: '预估体积(m³)',
    boxGaugeValue: '箱数',
    boxGaugeQuantity: '箱',
    boxGaugeJoin: '又',
    boxGaugeLeftQuantity: '个',
    checkBoxGaugeQuantity: '实盘箱数',
    customerName: '客户',
    unitPriceType: '计算类型',
    byUnitPrice: '按单价',
    bySubtotal: '按小计',
    amountExcludeTax: '小计(不含税)',
    amountIncludeTax: '小计(含税)',
    adjustAmountIncludeTax: '调整小计',
    currentCustomerManagerName: '现业务员',
    toSalesDelivery: '转销售发货',
    toOutRquire: '转委外需求',
    toOutOrder: '转委外订单',
    batchToOutOrder: '批量转委外订单',
    aboutReturnQuantity: '退货数量',
    purchaseNotReceivedProduct: '未入库退货',
    salesNotDeliveryProduct: '未出库退货',
    check: '校验',
    notCheck: '不校验',
    inventoryDelivery: '仓库发货',
    purchaseDelivery: '委外发货',
    inventoryOrPurchaseDelivery: '发货方式',
    salesOrderStockLessCheck: '销售订单可用库存不足是否提示',
    salesDeliveryStockLessCheck: '销售发货单可用库存不足是否提示',
    importSuccess: '导入成功',
    importTaskSuccessTips: '导入任务创建成功，请稍后查看导入结果',
    notInventoryOut: '待出库',
    notInventoryIn: '待入库',
    recall: '撤回',
    mallVoucherSelectTitle: '请选择代金券',
    mallCouponSelectTitle: '请选择优惠券',
    brandImage: '品牌图片',
    orderDate: '订单日期',
    deliveryDate: '交货日期',
    sms: '短信',
    wechatQy: '企业微信',
    dataImport: '数据导入',
    dataMap: '数据映射',
    dataPreview: '数据预览',
    complete: '完成',
    executeResultInfo: '共{0}条记录，成功：{1}条，跳过：{2}条',
    emptyImportData: '导入数据为空',
    fileError: '文件类型错误或者未按模板格式填写',
    none: '无',
    expressSmsScheme: '物流短信发送策略',
    moveUp: '上移',
    moveDown: '下移',
    member: '会员',
    visitor: '游客',
    operatorName: '经办人',
    creatorName: '创建人',
    sysAutoComplete: '系统自动完成',
    memberComplete: '会员确认收货',
    editExpress: '修改快递',
    notDelivery: '未发货',
    partDelivered: '发货中',
    completeDelivery: '已发货',
    notInventoryOutRate: '未出库',
    partInventoryOut: '部分出库',
    completeInventoryOut: '已出库',
    notInventoryInRate: '未入库',
    partInventoryIn: '部分入库',
    completeInventoryIn: '已入库',
    notCollection: '未回款',
    partCollection: '部分回款',
    completeCollection: '已回款',
    notPayment: '未付款',
    partPayment: '部分付款',
    completePayment: '已付款',
    queryDeliveryRate: '发货进度',
    queryInventoryOutRate: '出库进度',
    queryInventoryInRate: '入库进度',
    queryCollectionRate: '回款进度',
    queryPaymentRate: '付款进度',
    customerSort: '客户分类',
    memberLevel: '会员等级',
    productCode: '产品编码',
    productName: '产品名称',
    expressCompany: '快递公司',
    expressNumber: '快递单号',
    noDataNeedImport: '没有需要导入的数据',
    deleteTips: '是否确定删除该数据?',
  },
  expressStatus: {
    noTrace: '暂无轨迹信息',
    received: '已揽收',
    inTransit: '在途中',
    expSigned: '签收',
    error: '问题件',
    forward: '转寄',
    clearance: '清关',
    pending: '待揽件'
  },
  screenLock: {
    screenLockTitle: '系统锁屏',
    entryPassword: '请输入密码',
    logoutConfirm: '是否确定退出系统？'
  },
  dashboard: {
    dashboardCustom: '首页自定义',
    dashboardComponent: '首页组件',
    dashboardLayout: '首页布局',
    dashboardDefaultLayout: '默认布局',
    todayCustomerFollowup: '今日客户跟进',
    salesPresentation: {
      title: '销售简报',
      dataType: {
        own: '我的简报',
        department: '部门简报',
        company: '公司简报'
      },
      dateRange: {
        now: '今天',
        yesterday: '昨天',
        currentWeek: '本周',
        lastWeek: '上周',
        currentMonth: '本月',
        lastMonth: '上月',
        currentQuarter: '本季度',
        lastQuarter: '上季度',
        currentYear: '今年',
        lastYear: '去年'
      },
      newCustomerCount: '新增客户',
      newQuoteCount: '新增报价',
      newOrderCount: '新增订单',
      newPaymentCount: '新增回款',
      newContactCount: '新增联系人',
      newFollowUpCount: '跟进次数',
      newTaskCount: '处理任务',
      newWorkSheetCount: '处理工单',
      salesOrderAmount: '订单金额',
      allCustomerAndContact: '全部客户/联系人',
      salesCollectionAmount: '回款金额'
    },
    scheduleCalendar: {
      title: '日程日历',
      scheduleType: {
        my: '我的日程'
      },
      createSchedule: '新建日程',
      scheduleManage: '日程管理'
    },
    payableAndReceivable: {
      title: '应收应付款',
      amountType: {
        receivable: '应收款',
        payable: '应付款'
      },
      targetAmount: '目标金额',
      completedAmount: '完成金额',
      accounted: '占比',
      overAmount: '完成金额'
    },
    performanceRanking: {
      title: '业绩排行',
      rankType: {
        forManager: '按业务员',
        forBillPerson: '按开单员'
      }
    },
    financeReceivableTop: {
      title: '客户应收款余额排行Top10',
      sort: '排序',
      businessUnitName: '客户',
      amountBalance: '余额',
      receivableDate: '期限'
    },
    financePayableTop: {
      title: '供应商应付款余额排行Top10',
      sort: '排序',
      businessCompanyName: '供应商',
      amountBalance: '余额',
      payableDate: '期限'
    },
    salesOrderRecent: {
      title: '销售订单销售额和订单数（近6个月）',
      count: '订单数',
      amount: '销售额'
    },
    salesOrderProductTop: {
      title: '产品销售额排行Top10'
    },
    salesOrderMonthOnYear: {
      title: '销售额月度同比',
      xName: '月份'
    },
    financeAccountBookMonthOnYear: {
      title: '销售回款金额月度同比',
      xName: '月份'
    },
    inventoryWarning: {
      title: '预警信息',
      minStock: '发现小于最小库存产品',
      safetyStock: '发现小于安全库存产品',
      maxStock: '发现超出最大库存产品'
    }
  },
  login: {
    title: '系统登录',
    tenantKey: '租户Id/编码',
    login: '登录',
    loginName: '帐号',
    password: '密码',
    capsLock: '大写锁定已打开',
    website: '官网简介',
    contactUs: '联系我们',
    useManual: '使用手册',
    aboutUs: '关于我们',
    servicePhone: '服务支持电话',
    serviceEmail: '服务支持邮箱',
    address: '地址',
    screenResolutionTip: '为方便您更好的使用本系统，建议使用谷歌浏览器且屏幕分辨率不小于 1366 * 768',
    browserDownload: '谷歌浏览器下载',
    accountLogin: '帐号密码登录'
  },
  profile: {
    aboutMe: '个人中心',
    personalData: '个人资料',
    changePassword: '修改密码',
    sex: '性别',
    originalPassword: '原密码',
    newPassword: '新密码',
    confirmPassword: '确认密码',
    email: '电子邮箱',
    phone: '电话',
    mobile: '手机号码',
    entryPassword: '请输入密码',
    passwordChangeSuccess: '密码修改成功，请重新登录'
  },
  config: {
    companyInfo: '企业信息',
    businessParams: '业务参数',
    commonModule: '公共参数设置',
    customerModule: '客户参数设置',
    productModule: '产品参数设置',
    crmModule: '客户参数设置',
    customerCodeReconciliation: '客户编码生成依据',
    customerCodeReset: '客户编码是否允许修改',
    supplierCodeReconciliation: '供应商编码生成依据',
    supplierCodeReset: '供应商编码是否允许修改',
    salesOrderCodeReconciliation: '销售订单编码生成依据',
    salesModule: '销售参数设置',
    codeCommonModule: '编码自定义设置',
    purchaseModule: '采购参数设置',
    inventoryModule: '库存参数设置',
    financeModule: '财务参数设置',
    weworkModule: '企业微信设置',
    systemParam: '系统参数',
    systemName: '系统名称',
    systemLogo: '系统Logo',
    companyName: '企业名称',
    address: '地址',
    phone: '电话',
    fax: '传真',
    website: '网站',
    email: '邮箱',
    costingMethod: '存货计价方法',
    movingAverage: '移动平均法',
    fifo: '先进先出法',
    salesReconciliation: '销售对账依据',
    byOrder: '按订单',
    byInvoice: '按发货单',
    purchaseReconciliation: '采购对账依据',
    byReceipt: '按收货单',
    checkNegativeInventory: '检查负库存',
    multiTerminalLogin: '允许多处登录',
    passwordErrorLockCount: '允许密码错误次数',
    userPasswordCreateType: '用户密码生成',
    userDefaultPassword: '用户默认密码',
    userNeedChangePassword: '强制修改密码',
    dataDesensitization: '数据脱敏',
    amountDecimal: '金额数据精度',
    quantityDecimal: '数量数据精度',
    automaticallyClearRecipients: '自动清除收件人',
    automaticallyClearRecipientsTip: '开启后会自动清除90天前的，销售、采购、商城、兑换订单的收件人信息',
    decimalTip: '数字输入项保留有效数字位数，如输入2，则表示保留两位小数,最小0位，最大6位',
    productCodeReconciliation: '产品编码生成依据',
    bySystem: '系统自动生成',
    byUser: '自写',
    manual: '手动生成',
    orderAutomatic: '按订单自动生成',
    deliveryAutomatic: '按发货单自动生成',
    collectionPlanAutomatic: '按回款计划自动生成',
    receiptAutomatic: '按收货单自动生成',
    paymentPlanAutomatic: '按付款计划自动生成',
    receivablesCreateRule: '销售订单应收款生成规则',
    payableCreateRule: '采购订单应付款生成规则',
    sales: '销售打印模版',
    purchase: '采购打印模板',
    salesDelivery: '销售发货单模板',
    salesDeliveryNoPrice: '销售发货单模板（不带价格）',
    purchaseOrder: '采购订单模板',
    salesQuotation: '销售报价单模板',
    salesOrder: '销售订单模板',
    reportType: '模板类型',
    reportName: '模板名称',
    reportTypeTitle: '模板类型列表',
    viewTypeTemplate: '查看模板配置',
    editTypeTemplate: '编辑模板配置',
    reportTemplate: '模板列表',
    salesOrderVerifyAutomatic: '按销售订单审核自动生成',
    salesOrderCompleteAutomatic: '按销售订单完成自动生成',
    salesDeliveryVerifyAutomatic: '按销售发货单审核自动生成',
    salesDeliveryCompleteAutomatic: '按销售发货单完成自动生成',
    purchaseOrderVerifyAutomatic: '按采购订单审核自动生成',
    purchaseOrderCompleteAutomatic: '按采购订单完成自动生成',
    inventoryInAutomatic: '按采购收货确认入库自动生成',
    standardCurrency: '本位币',
    corpId: '企业ID',
    weworkSyncSecret: '通讯录同步Secret',
    wechatExternalUserTag: '外部用户标签',
    weworkAppAgentId: '应用AgentId',
    weworkAppSecret: '应用Secret',
    weworkAppToken: 'Token',
    weworkAppAesKey: 'EncodingAESKey',
    weworkOrgSyncApp: '企业微信同步应用',
    caracalToolsApp: '狞猫云工具应用',
    authorized: '已授权',
    unauthorized: '未授权',
    outPayableCreateRule: '委外订单应付款生成规则',
    purchaseOutOrderVerifyAutomatic: '按委外订单审核自动生成',
    purchaseOutOrderCompleteAutomatic: '按委外订单完成自动生成',
    overLoadInventoryIn: '超额入库',
    overLoadInventoryOut: '超额出库',
    weworkLogin: '企业微信登录',
    weworkLoginTip: '开启企业微信登录 需配置企业ID 应用AgentId 应用Secret',
    kfToken: '微信客服Token',
    kfAESKey: '微信客服AESKey',
    kfSecret: '微信客服Secret',
    weworkKf: '微信客服',
    kfid: '客服ID',
    freight: {
      templateName: '模板名称',
      billWay: '计费方式',
      expressCompany: '快递公司',
      region: '配送范围',
      firstPiece: '首件',
      firstWeight: '首重',
      followPiece: '续件',
      followWeight: '续重',
      freight: '运费',
      addRegion: '添加配送区域',
      chooseRegion: '选择配送区域',
      piece: '件数',
      weight: '重量',
      isDefault: '设为默认',
      defaultRegion: '全国统一配送运费',
      addTitle: '添加运费模板',
      editTitle: '编辑运费模板',
      deleteTips: '是否确认删除该运费模板?',
      default: '默认模板',
      setDefault: '设置为默认',
      templateNameTips: '模板名称不能为空',
      defaultTemplateTips: '默认模板状态默认启用',
      choosedRegion: '已选择',
      searchRegion: '搜索区域',
      fullPackageMail: '满额包邮',
      inOrderPackage: '参与订单满额包邮',
      fullAmount: '满额金额',
      pleasefullAmount: '开启满额包邮后，请填写满额金额',
      sortTips: '请输入排序',
      addRange: '添加计件区间'
    },
    sms: {
      signName: '签名名称',
      signSource: '签名来源',
      businessLicense: '营业执照',
      powerAttorney: '委托授权书',
      status: '审核状态',
      remark: '签名用途',
      rejectRemark: '审核失败原因',
      defaultSmsSign: '默认',
      setDefault: '设置为默认',
      enterpriseName: '企事业单位名',
      filedWebsite: '已备案网站',
      onlineApp: '已上线APP',
      applet: '公众号或小程序',
      storeName: '电商平台店铺名',
      tradeName: '已注册商标名',
      auditing: '审核中',
      auditedPass: '审核通过',
      auditedReject: '审核失败',
      signNameTips1: '建议为用户真实应用名/网站名/公司名',
      signNameTips2: '签名发送自带【】符号，无须添加【】、()、[]符号，避免重复',
      signNameTips3: '不支持个人用户申请未上线业务，若产品未上线建议先升级企业账号',
      signNameTips4: '不支持如“客户服务”、“友情提醒”等过于宽泛内容、不支持“测试”字样的签名',
      businessLicenseTips1: '支持jpg、png、gif、jpeg格式的图片，每张图片不大于2MB',
      powerAttorneyTips1: '支持jpg、png、gif、jpeg格式的图片，每张图片不大于2MB',
      remarkTips1: '签名来源为已备案网站时，请在签名用途中补充网站域名，加快审核速度',
      otherTips1: '预计两小时完成审核，政企签名预计在48小时工作时间内审核',
      otherTips2: '审核工作时间: 周一至周日9:00-23:00（法定节日顺延）',
      signNamePlease: '请填写签名名称',
      signSourcePlease: '请选择签名来源',
      businessLicensePlease: '请上传营业执照',
      powerAttorneyPlease: '请上传委托授权书',
      remarkPlease: '请填写签名用途',
      download: '下载',
      powerAttorneyTemplate: '授权委托书模板',
      addTitle: '提交短信签名申请',
      editTitle: '重新提交短信签名申请',
      viewTitle: '查看短信签名申请',
      deleteTips: '确认【删除】该短信签名，短信签名删除后不可恢复，请谨慎操作!',
      addTips: '确认提交该短信签名申请，审核通过后可设置该短信签名',
      editTips: '重新提交该短信签名申请，审核通过后可设置该短信签名',
      tips: '重要提示',
      otherTips3: '短信签名审核为阿里云，系统只负责提交申请至阿里云',
      signLicensee: '被授权方',
      signLicensor: '授权方',
      bindCustomer: '绑定客户'
    }
  },
  user: {
    userListTitle: '用户列表',
    loginName: '登录名',
    realName: '姓名',
    sex: '性别',
    password: '密码',
    confirmPassword: '确认密码',
    email: '电子邮箱',
    phone: '电话',
    mobile: '手机号码',
    org: '所属部门',
    dataPermission: '数据权限',
    loginNamePlaceholder: '请输入登录名',
    pleaseChooseOrg: '请选择所属部门',
    deleteUserTips: '是否确定删除该用户？',
    passwordTip: '编辑用户时，如果不需要修改密码则不填写',
    loginNameTip: '用户名只能为英文字母,数字和"_"，且长度不少于4位',
    entryCurrentLoginName: '请输入正确的用户名',
    addUser: '添加用户',
    editUser: '编辑用户',
    ownDataPermission: '本人数据',
    deptDataPermission: '部门数据',
    allDataPermission: '所有数据',
    setRole: '设置角色',
    setRoleTitle: '设置角色',
    resetPassword: '重置密码',
    createUserMessage: '用户创建成功，该用户密码是：',
    resetPasswordMessage: '密码重置成功，新密码是：',
    resetPasswordTips: '是否确定重置该用户的密码？',
    createUserSuccess: '用户创建成功',
    resetPasswordSuccess: '密码重置成功',
    userTip: '为保证数据的完整性，请不要随意删除用户，除非该用户没有产生业务数据。可以通过禁用或锁定防止该用户登录系统',
    syncWework: '企业微信',
    syncFromWework: '关联用户',
    syncFromWeworkTip: '系统组织机构和用户将会关联企业微信, 企业微信数据不会被修改，是否【确认】操作',
    syncToWework: '同步用户',
    syncToWeworkTip: '将会删除企业微信原有用户，并将本系统用户同步到企业微信，企业微信数据将被覆盖，是否【确认】操作',
    qyWechatSyncStatus: '企业微信关联状态',
    notRelated: '未关联',
    relatedSuccess: '关联成功',
    relatedFail: '关联失败',
    cancelUserRelevance: '取消关联',
    cancelUserRelevanceTips: '是否确认【取消关联】企业微信',
    qyWechatSyncTip: '同步到企业微信，将会删除企业微信原有用户，并将本系统用户同步到企业微信，请谨慎操作',
    contactMe: '联系我',
    deleteContactMeTips: '是否确定删除该用户的「联系我」二维码？',
    qrcode: '企业微信二维码',
    qrcodeTips: '生成企业微信二维码,需配置企微联系人(客户管理-参数设置-企微联系人配置)'
  },
  org: {
    addChild: '添加子部门',
    orgListTitle: '部门列表',
    orgName: '部门名称',
    orgType: '部门类型',
    orgTypeOrg: '机构',
    orgTypeDept: '部门',
    viewOrgTitle: '查看部门',
    editOrgTitle: '编辑部门',
    addOrgTitle: '添加部门',
    orgNamePlaceholder: '请输入部门名称',
    enterOrgNameQuery: '请输入部门名称进行查询',
    deleteOrgTips: '是否确定删除该部门？',
    needChooseOrg: '请先选择部门节点',
    dropOrgTips: '是否确定将该部门移动到指定部门下？'
  },
  role: {
    roleListTitle: '角色列表',
    roleName: '角色名称',
    viewRoleTitle: '查看角色',
    editRoleTitle: '编辑角色',
    addRoleTitle: '添加角色',
    roleNamePlaceholder: '请输入角色名称',
    enterRoleNameQuery: '请输入角色名称进行查询',
    menuPermission: '菜单权限',
    addRole: '添加角色',
    deleteRoleTips: '是否确定删除该角色？'
  },
  menu: {
    menuName: '菜单名称',
    menuUrl: '菜单URL',
    menuIcon: '菜单图标',
    permission: '菜单权限',
    enterMenuNameQuery: '请输入菜单名称进行查询'
  },
  attachment: {
    attachmentName: '文件名',
    attachmentSize: '大小',
    uploadTime: '上传时间',
    creatorId: '上传人',
    deleteAttachmentTips: '是否确定刪除该附件?',
    attahmentNameRename: '附件名称重命名'
  },
  crm: {
    config: {
      newCustomerAutoEnableDistribution: '新客户开启分销',
      distributionConfig: '分销设置',
      distributionInternalEnable: '分销内购',
      commissionConfig: '自定义佣金',
      distributionTitle: '协议标题',
      distributionAgreement: '分销申请协议',
      wechatContactConfig: '企微联系人配置',
      wechatMsgAuditConfig: '企微会话存档配置',
      callbackUrl: '回调地址',
      customerOrder: '客户订单',
      secret: 'secret',
      secretTip: '请填写secret',
      token: '接收事件Token',
      tokenTip: '请填写接收事件Token',
      aeskey: '接收事件EncodingAESKey',
      aeskeyTip: '请填写接收事件EncodingAESKey',
      messageEncryPublicKey: '消息加密公钥',
      messageEncryPrivateKey: '消息加密私钥',
      messageEncryPrivateKeyTip: '请填写消息加密私钥',
      wechatDeleteTip: '企业微信删人提醒',
      wechatAccountDisable: '企业微信账号禁用',
      addMemberState: '添加成员',
      chooseMemberState: '选择成员',
      wechatDeleteTips: '当有企业成员删除好友，配置提醒的企业成员会在企业微信内收到一条通知推送',
      disableText: '企业成员当日删除好友达',
      disableTextTwo: '个，自动将该企业成员的企业微信账号禁用',
      setDisableMember: '设置非风控企业成员（不受企业微信账号禁用风控）',
      empty: '清空',
      wechatAccountDisableTip: '企业微信账号禁用开启，请填写企业成员当日删除好友达到的个数',
      memberOrder: '会员订单',
      firstCommissionRate: '一级佣金比例',
      secondCommissionRate: '二级佣金比例',
      distributionEnable: '分销权限',
      distributionExpireDate: '有效期止',
      customerConfig: '分销',
      byOrderAmount: '按订单金额比例',
      byPriceDifference: '按价差比例',
      commssionCalcMethod: '佣金计算方式',
      performanceRebate: '业绩返点',
      performanceRebateDateConfigName: '业绩返点类型',
      performanceRebateDateConfigDateRange: '返点时间区间',
      errorPerformanceRebateDate: '请选择时间区间',
      configRebateRate: '返点比例',
      rebateRateConfigTitle: '返点比例配置',
      rebateAmountRange: '业绩金额区间',
      rebateRate: '返点比例(%)',
      rateConfigError: '返点比例配置错误',
      commonConfig: '通用设置',
      reconciliationDate: '对账日',
      reconciliationDateTip: '若所选对账日大于当月最大天数，则对账日为当月最后一天',
      accountStatementAutoConfirmDate: '销售对账单自动确认天数',
      accountStatementAutoConfirmDateTip: '从对账单生成之日起多少天后对账单自动更新状态为已确认',
      basicConfig: '基本设置',
      customerInfoRequiredSetting: '客户资料必填项设置',
      notifyConfig: '通知设置',
      customerFollowUpNotify: '客户跟进通知',
      customerVisitNotify: '客户拜访通知',
      commissionExcludeConfig: '佣金计算',
      commissionExcludeFreight: '排除运费部分',
      commissionExcludeVoucher: '排除代金券支付部分',
      commissionExcludePoint: '排除积分支付部分'
    },
    customer: {
      assignment: '客户分配',
      transfer: '批量转移',
      claim: '认领',
      customerCode: '客户编码',
      customerName: '客户名称',
      customerShortName: '简称',
      customerContactsPhone: '联系人手机',
      allCustomer: '全部客户',
      queryCustomer: '已查询客户',
      checkCustomer: '已勾选客户',
      customerPhone: '电话',
      customerFax: '传真',
      customerMobilePhone: '手机号码',
      associateSupplier: '关联供应商',
      associateSupplierPlease: '请选择关联供应商',
      website: '网站',
      country: '国家',
      area: '区域',
      address: '地址',
      customerLevel: '客户级别',
      customerSource: '客户来源',
      customerStatus: '客户状态',
      customerSort: '客户分类',
      settlementType: '结算方式',
      currentSettlement: '现结',
      monthSettlement: '月结',
      billingDay: '账单日',
      accountPeriod: '账期(天)',
      customerManager: '业务员',
      lastContactTime: '最近联系时间',
      addCustomer: '添加客户',
      customerNamePlaceholder: '请输入客户名称',
      editCustomer: '编辑客户',
      viewCustomer: '查看客户',
      deleteCustomerTips: '是否确定删除该客户？',
      claimCustomerTips: '是否确定认领该客户？',
      claimMultipleCustomerTips: '是否确定认领选择的客户？',
      pleaseChooseClaimCustomer: '请选择要认领的客户',
      pleaseChooseAssignmentCustomer: '请选择要分配的客户',
      pleaseChooseManager: '请选择客户经理',
      customerInfo: '客户信息',
      customerContact: '联系人',
      followUpRecord: '跟进记录',
      billingInfo: '开票信息',
      logisticsAddress: '物流地址',
      followUp: '客户跟进',
      customerSelect: '选择客户',
      pleaseChooseCustomer: '请选择客户',
      tag: '标签',
      priceTypeConfig: '价格类型配置',
      defaultPriceType: '结算价格类型',
      relatedPriceType: '可见价格类型',
      priceTypeName: '价格类型',
      customerMember: '商城会员',
      customerCodePlease: '请输入客户编码',
      changeCategory: '调整分类',
      customerSortPlease: '请选择客户分类',
      changeCompany: '调整所属公司',
      customerCompanyPlease: '请选择所属公司',
      customerLevelPlease: '请选择客户级别',
      customerShortNamePlease: '请输入简称',
      customerPhonePlease: '请输入电话',
      customerFaxPlease: '请输入传真',
      customerMobilePhonePlease: '请输入手机号码',
      customerIndustryTypePlease: '请选择所属行业',
      customerAreaPlease: '请选择区域',
      customerAddressPlease: '请输入地址',
      dumallRegisterCode: '嘟麦集肆注册码',
      geneRegisterCode: '生成注册码',
      reGeneCode: '重新生成',
      distributionEnabled: '分销开启',
      updateParticipants: '更新协作人',
      addMode: '协作人添加模式',
      append: '追加',
      cover: '覆盖',
      customerType: '客户类型',
      distributePoint: '发放积分',
      importCustomerPoint: '导入积分',
      customerBilling: {
        invoiceType: '发票类型',
        vatSpecial: '增值税专用发票',
        vatGeneral: '增值税普通发票',
        other: '其他',
        companyName: '企业名称',
        taxNumber: '税号',
        address: '地址',
        phone: '电话号码',
        depositBank: '开户银行',
        bankAccount: '银行账户',
        companyNamePlaceholder: '请输入企业名称',
        taxNumberPlaceholder: '请输入企业税号',
        addBilling: '添加开票信息',
        editBilling: '编辑开票信息',
        viewBilling: '查看开票信息',
        deleteBillingTips: '是否确定删除该开票信息？'
      },
      customerAddress: {
        addressType: '地址类型',
        country: '国家',
        area: '区域',
        address: '地址',
        addressee: '收件人',
        addresseePhone: '收件人电话',
        addressTypePlaceholder: '请选择地址类型',
        countryPlaceholder: '请选择国家',
        areaPlaceholder: '请选择区域',
        addressPlaceholder: '请输入详细地址',
        addAddress: '添加物流地址',
        editAddress: '编辑物流地址',
        viewAddress: '查看物流地址',
        deleteAddressTips: '是否确定删除该物流地址？'
      },
      pleaseChoosetransferCustomer: '请选择要转移的客户',
      salesQuotation: '报价',
      salesOrder: '订单',
      salesDelivery: '发货',
      salesReturn: '退货',
      financeReceivable: '应收',
      financeCollection: '回款',
      businessQueryKeyword: '编码',
      superiorCustomer: '上级',
      pleaseChooseSuperiorCustomer: '请选择客户上级',
      mergerBusinessEnable: '合并业务',
      juniorCustomer: '下级',
      participant: '协作人',
      addParticipant: '添加协作人',
      clearParticipant: '清空协作人',
      priceType: '价格类型',
      industryType: '所属行业',
      creditLimit: '授信额度',
      settlementPeriod: '结算方式',
      customerAuthCode: '客户码',
      customerAuthCodeTip: '客户码自动生成',
      baseInfo: '基本信息',
      otherInfo: '其它信息',
      contactInfo: '联系人',
      saveAndAddContact: '保存并继续添加联系人',
      customerRange: '请选择客户范围'
    },
    contact: {
      nickName: '称呼',
      contactName: '姓名',
      customer: '所属客户',
      position: '职位',
      contactType: '类型',
      businessPhone: '电话',
      mobile: '手机',
      email: '邮箱',
      fax: '传真',
      wechat: '微信',
      qq: 'qq',
      otherInfo: '其他',
      lastContactTime: '最近联系时间',
      addContact: '添加联系人',
      contactNamePlaceholder: '请输入联系人名称',
      nickNamePlaceholder: '请输入联系人称呼',
      editContact: '编辑联系人',
      viewContact: '查看联系人',
      deleteContactTips: '是否确定删除该联系人？',
      contactInfo: '联系人信息',
      contactSelect: '选择联系人',
      birthday: '生日'
    },
    followUp: {
      followUpDateStart: '跟进日期起',
      followUpDateEnd: '跟进日期止',
      customer: '客户名称',
      contact: '联系人',
      followUpType: '跟进类型',
      followUpWay: '跟进方式',
      followUpTime: '跟进时间',
      followUpPerson: '跟进人',
      followUpContent: '跟进内容',
      nextFollowUpTime: '下次跟进时间',
      nextFollowUpWay: '下次跟进方式',
      addFollowUp: '添加跟进记录',
      customerPlaceholder: '请选择要跟进的客户',
      contactPlaceholder: '请选择联系人',
      followUpTypePlaceholder: '请选择跟进类型',
      followUpWayPlaceholder: '请选择跟进方式',
      followUpContentPlaceholder: '请填写跟进内容',
      followUpTimePlaceholder: '请填写跟进时间',
      editFollowUp: '编辑跟进记录',
      viewFollowUp: '查看跟进记录',
      deleteFollowUpTips: '是否确定删除该跟进记录？',
      followUpInfo: '跟进记录',
      syncCustomerVisit: '同步完成拜访计划',
      customerVisit: '拜访计划',
      visitPlanTips: '请选择拜访计划',
      businessType: '关联业务类型',
      businessCode: '关联业务'
    },
    clueFollowUp: {
      followUpDateStart: '跟进日期起',
      followUpDateEnd: '跟进日期止',
      customer: '客户名称',
      contact: '联系人',
      followUpType: '跟进类型',
      followUpWay: '跟进方式',
      followUpTime: '跟进时间',
      followUpPerson: '跟进人',
      followUpContent: '跟进内容',
      nextFollowUpTime: '下次跟进时间',
      nextFollowUpWay: '下次跟进方式',
      addFollowUp: '添加跟进记录',
      customerPlaceholder: '请选择要跟进的客户',
      contactPlaceholder: '请选择联系人',
      followUpTypePlaceholder: '请选择跟进类型',
      followUpWayPlaceholder: '请选择跟进方式',
      followUpContentPlaceholder: '请填写跟进内容',
      followUpTimePlaceholder: '请填写跟进时间',
      editFollowUp: '编辑跟进记录',
      viewFollowUp: '查看跟进记录',
      deleteFollowUpTips: '是否确定删除该跟进记录？',
      followUpInfo: '跟进记录',
      syncCustomerVisit: '同步完成拜访计划',
      customerVisit: '拜访计划',
      visitPlanTips: '请选择拜访计划'
    },
    multi: {
      dateStart: '开始时间',
      dateEnd: '结束时间',
      customerMantenance: '客户维护',
      addedCustomerCount: '新增客户',
      addedContactCount: '新增联系人',
      addedFollowUpCount: '新增跟进',
      addedQuotationCount: '新增报价单',
      schedule: '日程',
      relatedScheduleCount: '关联日程数量',
      salesOrder: '销售订单',
      salesOrderQuantity: '订单数量',
      unAudit: '待审核',
      audit: '已审核',
      finish: '已完成',
      salesOrderAmount: '签约金额',
      salesCollectionAmount: '回款金额',
      allReport: '全部统计',
      customerCount: '客户数量',
      contactCount: '联系人数量',
      followUpCount: '跟进数量',
      quotationCount: '报价单数量',
      scheduleCount: '日程数量',
      orderCount: '订单数量',
      subtotal: '小计',
      pleaseChooseOperator: '请选择业务员'
    },
    visit: {
      visitName: '拜访计划名称',
      customerName: '客户名称',
      contactName: '联系人',
      businessPhone: '联系电话',
      mobile: '手机',
      visitTypeId: '拜访方式',
      visitPurpose: '拜访目的',
      visitTimePlanned: '计划拜访时间',
      directorId: '负责人',
      participantId: '参与人',
      visitStatus: '状态',
      description: '描述',
      isRemind: '是否提醒',
      remindTime: '提醒时间',
      delayTime: '延期时间',
      delayResult: '延期原因',
      delayRemark: '延期备注',
      notFollowUp: '未跟进',
      addTitle: '添加拜访计划',
      viewTitle: '查看拜访计划',
      delayTitle: '延期拜访计划',
      finishTips: '是否确定完成该拜访计划?',
      cancelTips: '是否确定取消该拜访计划?',
      delelteTips: '是否确定删除该拜访计划?',
      delayed: '已延期',
      canceled: '已取消',
      finished: '已完成',
      delay: '延期',
      finish: '完成',
      followUp: '跟进记录',
      addParticipants: '添加参与人',
      queryWords: '客户名称 拜访计划名称',
      visitNameNotEmpty: '拜访计划名称不能为空',
      customerNameNotEmpty: '拜访客户不能为空',
      contactNameNotEmpty: '拜访联系人不能空',
      visitPurposeNotEmpty: '拜访目的不能为空',
      visitTimePlannedNotEmtpy: '拜访计划时间不能为空',
      directorIdNotEmpty: '负责人不能为空',
      participantIdNotEmpty: '参与人不能为空',
      delayTimeNotEmpty: '延期时间不能为空',
      delayResultNotEmpty: '延期原因不能为空',
      delayRemarkNotEmpty: '延期备注不能为空',
      participantsNotEmtpy: '参与人不能为空',
      visitTypeIdNotEmpty: '拜访方式不能为空',
      remindTimeError: '提醒时间不能空且小于计划时间',
      notRemind: '不提醒',
      advance15min: '提前15分钟',
      advance30min: '提前30分钟',
      advance1hour: '提前1小时',
      advance3hours: '提前3小时',
      advance1day: '提前1天',
      custom: '自定义',
      visitSelect: '选择拜访计划',
      operatorName: '操作人'
    },
    customerTag: {
      common: {
        title: '选择标签',
        addTag: '创建新标签',
        tagManage: '标签管理',
        addForTag: '+添加新标签'
      }
    },
    creditLimit: {
      customerName: '客户名称',
      customerCode: '客户编码',
      creditLimit: '授信额度',
      receivable: '当前应收款余额',
      ableCreditLimit: '可用授信额度',
      pleaseChooseCustomer: '请选择用户',
      pleaseInputCreditLimit: '请输入授信额度',
      creditLimitGreaterThanZero: '授信额度必须大于0',
      addCreditLimit: '添加客户授信',
      creditTip: '授信额度按本位币控制，请按本位币金额录入;未设置授信额度或者授信额度为0，默认不进行授信额度的控制'
    },
    distributorApply: {
      queryStatus: '审核状态',
      applyName: '企业名称/个人名称',
      certificate: '证件号码',
      phone: '手机号码',
      member: '会员',
      customer: '客户',
      applyTime: '申请时间',
      auditStatus: '审核状态',
      reject: '驳回',
      pending: '待审核',
      audited: '已审核',
      editTitle: '审核分销申请',
      viewTitle: '查看分销申请',
      rejectReason: '驳回理由',
      expire: '有效期'
    },
    externalContact: {
      name: '名称',
      corpName: '所在企业的简称',
      corpFullName: '所在企业的主体名称',
      type: '类型',
      wx: '微信用户',
      wework: '企业微信用户',
      queryKeyword: '关键字 ',
      sync: '同步企微客户',
      addCustomer: '添加客户',
      bindCustomer: '绑定客户',
      viewCustomer: '查看客户',
      followup: '企业成员',
      customer: '客户',
      tips: '企业微信参数没有配置，请先在【系统设置-参数配置】中完善企业微信设置(企业ID、应用AgentId、应用Secret)',
      wechatCorpInfo: '企微信息',
      relatedCustomer: '关联客户',
      showLost: '显示已流失的',
      lost: '已流失',
      unlost: '未流失',
      addTime: '添加时间',
      lossTime: '丢失时间',
      description: '描述'
    },
    performanceRebate: {
      customerName: '客户名称',
      performanceRebateName: '返点类型名称',
      startDate: '业绩计算开始时间',
      endDate: '业绩计算结束时间',
      performanceAmount: '业绩金额',
      rebateRate: '返点比例（%）',
      rebateAmount: '返点金额',
      queryKeywords: '返点类型',
      performanceRange: '业绩区间(左不包含，右包含)',
      commonRateConfig: '默认返点设置',
      customerSortRateConfig: '指定客户分类返点设置',
      customerRateConfig: '指定客户返点设置',
      notInCustomerSortConfig: '不参与业绩返点的客户分类',
      notInCustomerConfig: '不参与业绩返点的客户',
      selectCustomer: '选择客户',
      performanceRebateNamePlease: '请填写返点类型名称',
      performanceDateRange: '业绩计算时间区间',
      rebateCalcDateRange: '业绩结算时间区间',
      cashRealTime: '实时提现',
      performanceDateRangePlease: '请选择业绩计算时间区间',
      rebateCalcDateRangePlease: '请选择业绩结算时间区间',
      auditStatus: '状态',
      customerSortGroup: '指定客户分类设置',
      customerGroup: '指定客户设置',
      auditTips: '是否确认审核该业绩返点设置',
      commonRateConfigError: '默认返点比例设置错误',
      customerRateConfigError: '客户返点比例设置错误',
      customerSortRateConfigError: '客户分类返点比例设置错误',
      customerNotConfig: '客户未设置',
      customerSortNotConfig: '客户分类未设置',
      deleteTips: '是否确认删除该返点类型设置',
      cancelTips: '是否确认作废该返点类型设置',
      revokeTips: '是否确认撤销该返点类型设置',
      addPerformanceRebateConfig: '添加业绩返点设置',
      editPerformanceRebateConfig: '编辑业绩返点设置',
      viewPerformanceRebateConfig: '查看业绩返点设置',
      resetRebateConfig: '业绩返点设置',
      performanceDateRangeTips: '业绩统计范围为：在时间区间内的有效订单（已审核销售订单/已支付商城订单）',
      rebateCalcDateRangeTips: '时间区间结束后，返点设置将被标记完成，返点金额以完成时计算结果为最终结果',
      notEndRebateAmount: '待结算',
      endRebateAmount: '已结算',
      extractAmount: '提现金额',
      applyingExtractAmount: '申请中',
      extractedAmount: '已提现',
      rebateBalance: '剩余',
      rebateDetails: '返点明细',
      rebateExtract: '提现申请',
      rebateStatus: '提现状态',
      extractType: '提现方式',
      extractStatus: '状态',
      operator: '经办人',
      auditTime: '审核时间',
      rejectReason: '驳回原因',
      toBankCard: '转银行卡',
      toAdvanceReceipt: '转预收款',
      auditRebateExtractApply: '提现申请审核',
      viewRebateExtractApply: '查看提现申请',
      member: '会员',
      customer: '客户',
      extractPassTips: '确认通过该提现申请',
      extractRejectTips: '确认驳回该提现申请',
      emptyRejectReason: '请输入驳回原因',
      paymentInfo: '付款信息',
      rebateConfigInfo: '返点设置',
      rebateExtractInfo: '提现信息',
      customerAccountInfo: '客户账户信息',
      customerAccountName: '户名',
      customerAccountBank: '开户银行',
      customerAccountNumber: '账号',
      paymentInfoPlease: '请填写付款信息',
      rebateCalcDateTipPrefix: '业绩计算日期截止至',
      rebateCalcDateTipSubfix: '，统计范围包含销售订单和商城订单',
      calcDateRangeError: '请正确设置业绩结算时间区间，结算时间区间需大于计算时间区间',
      businessQueryKeywords: '订单编码',
      viewRebateBusiness: '查看关联业务',
      orderCode: '订单编码',
      orderDate: '订单日期',
      orderAmount: '订单金额',
      memberPerformanceDateRangeTips: '业绩统计范围为：在时间区间内已支付商城订单',
      memberRebateCalcDateRangeTips: '时间区间结束后，返点设置将被标记完成，返点金额以完成时计算结果为最终结果',
      memberLevelGroup: '指定会员等级设置',
      memberGroup: '指定会员设置',
      memberRateConfigError: '会员返点比例设置错误',
      memberLevelRateConfigError: '会员等级返点比例设置错误',
      memberNotConfig: '会员未设置',
      memberLevelRateConfig: '指定会员等级返点设置',
      memberRateConfig: '指定会员返点设置',
      notInMemberLevelConfig: '不参与业绩返点的会员等级',
      notInMemberConfig: '不参与业绩返点的会员',
      selectMember: '选择会员',
      customerRebateDetails: '客户返点',
      memberRebateDetails: '会员返点',
      memberName: '会员名称',
      calcPriceLevel: '返点价格标签',
      priceLevelPlease: '请勾选返点价格标签'
    },
    clue: {
      clueCode: '线索编码',
      clueName: '线索名称',
      customerName: '客户名称',
      customerShortName: '简称',
      contactName: '联系人名称',
      allClue: '全部线索',
      queryClue: '已查询线索',
      checkClue: '已勾选线索',
      customerPhone: '客户电话',
      customerMobilePhone: '客户手机号码',
      wechat: '微信',
      email: '邮箱',
      industryType: '所属行业',
      budgetAmount: '项目预算',
      customerDemand: '需求描述',
      country: '国家',
      area: '区域',
      address: '地址',
      clueSource: '线索来源',
      clueStatus: '线索状态',
      clueSort: '线索分类',
      customerManager: '客户经理',
      lastContactTime: '最近联系时间',
      nextContactTime: '下次联系时间',
      addClue: '添加线索',
      clueNamePlaceholder: '请输入线索名称',
      customerNamePlaceholder: '请输入客户名称',
      editClue: '编辑线索',
      clueInfo: '线索详情',
      viewClue: '查看线索',
      deleteCustomerTips: '是否确定删除该线索？',
      pleaseChooseManager: '请选择客户经理',
      crmClueInfo: '线索信息',
      customerContact: '联系人',
      followUpRecord: '跟进记录',
      followUp: '线索跟进',
      customerSelect: '选择客户',
      pleaseChooseCustomer: '请选择客户',
      remark: '备注',
      transfer: '转客户'
    }
  },
  purchase: {
    common: {
      purchaseOrder: '采购订单',
      purchaseReceipt: '采购收货',
      purchaseReturn: '采购退货',
      orderSupplierAmount: '按供应商统计-采购订单',
      orderProductAmount: '按产品统计-采购订单',
      orderExpenseInfo: '费用信息',
      orderSummary: '采购订单金额汇总',
      receiptSummary: '采购收货金额汇总',
      orderAmountDetails: '订单明细',
      receiptAmountDetails: '收货明细',
      outsourcingOrder: '委外订单',
      addressee: '收货人',
      addresseePhone: '收货人电话',
      deliveryAddress: '收货人地址',
      addresseeTips: '请填写收货人',
      addresseePhoneTips: '请填写收货人联系电话',
      countryTips: '请选择国家',
      areaTips: '请选择地区',
      deliveryAddressTips: '请填写详细地址',
      orderExpressInfo: '快递信息',
      receiptWarehouse: '收货仓库',
      exportOrderTitle: '导出采购订单',
      exportOutOrderTitle: '导出委外订单'
    },
    exception: {
      supplierPlease: '请选择供应商',
      purchaserPlease: '请选择采购员',
      purchaseDatePlease: '请选择采购日期',
      currencyPlease: '请选择货币'
    },
    status: {
      draft: '草稿',
      unAudit: '待审核',
      audit: '已审核',
      noOrder: '未下单',
      partialOrder: '部分下单',
      orderPlaced: '下单完成',
      canceled: '已作废'
    },
    product: {
      product: '产品',
      quantity: '数量',
      unitPrice: '单价',
      taxRate: '税率',
      tax: '税金',
      amountExcludedTax: '小计(不含税)',
      amountAddedTax: '小计(含税)',
      productCode: '产品编码',
      productName: '产品名称',
      productModel: '规格型号',
      productUnit: '单位',
      supplier: '供应商'
    },
    supplier: {
      supplierCode: '供应商编码',
      supplierType: '供应商类型',
      supplierName: '供应商名称',
      supplierShortName: '简称',
      supplierPhone: '电话',
      supplierFax: '传真',
      website: '网站',
      remark: '备注',
      country: '国家',
      area: '区域',
      address: '详细地址',
      supplierPayType: '结算方式',
      supplierDeliveryType: '送货方式',
      addSupplierTitle: '添加供应商',
      editSupplierTitle: '编辑供应商',
      viewSupplierTitle: '查看供应商',
      deleteSupplierTips: '是否确定删除该供应商',
      supplierNameNotEmpty: '请输入供应商名称',
      supplierInfo: '供应商信息',
      product: '供应商产品',
      contact: '联系人',
      account: '供应商账户',
      brand: '供应品牌',
      relatedBrand: '关联供应品牌',
      relatedCustomer: '关联客户',
      addRelatedBrand: '添加供应品牌',
      supplierBrandTips: '至少关联一个品牌',
      supplierCodePlease: '请输入供应商编码'
    },
    contact: {
      nickName: '称呼',
      contactName: '姓名',
      customer: '所属供应商',
      position: '职位',
      contactType: '类型',
      businessPhone: '电话',
      mobile: '手机',
      email: '邮箱',
      fax: '传真',
      wechat: '微信',
      qq: 'qq',
      otherInfo: '其他',
      lastContactTime: '最近联系时间',
      pleaseChooseSupplier: '请选择供应商',
      addContact: '添加联系人',
      contactNamePlaceholder: '请输入联系人名称',
      nickNamePlaceholder: '请输入联系人称呼',
      editContact: '编辑联系人',
      viewContact: '查看联系人',
      deleteContactTips: '是否确定删除该联系人？',
      contactInfo: '联系人信息',
      contactSelect: '选择联系人',
      birthday: '生日'
    },
    plan: {
      planSn: '采购计划编码',
      userName: '采购人',
      status: '状态',
      salesOrder: '销售订单',
      deliveryDate: '期望到货日期',
      purpose: '用途',
      remark: '描述',
      addPlanTitle: '添加采购计划',
      editPlanTitle: '编辑采购计划',
      viewPlanTitle: '查看采购计划',
      deletePurchasePlanTips: '是否确定删除该采购计划',
      planInfo: '采购计划信息',
      chooseProduct: '添加采购产品',
      addProductTitle: '选择采购产品',
      purchase: '采购',
      draft: '草稿',
      unconfirm: '待下单',
      confirmed: '已下单',
      userIdNotEmpty: '请选择采购人',
      deliveryDateNotEmpty: '请选择到货日期',
      purposeNotEmpty: '请填写用途',
      remarkNotEmpty: '请填写备注',
      submitAndOrder: '提交并下单',
      requisition: '转请购单',
      purchaseOrder: '转采购订单',
      purchaseOutOrder: '转委外订单'
    },
    order: {
      queryKeyword: '编码、供应商、联系人',
      detailsQueryKeyword: '编码、客户、产品信息',
      orderCode: '采购订单编码',
      orderAmount: '订单金额',
      orderSn: '采购订单编码',
      deliveryDate: '交货日期',
      createTime: '订单下单日期',
      supplier: '供应商名称',
      contactName: '联系人',
      user: '采购员',
      deliveryType: '交货方式',
      auditDate: '审核日期',
      auditId: '审核人',
      payType: '结算方式',
      orderDate: '下单日期',
      currency: '货币',
      invoice: '开票类型',
      delivery: '交货方式',
      address: '交货地址',
      contact: '联系人',
      amountExcludedTax: '总金额(未税)',
      amountAddedTax: '总金额(含税)',
      paidAmount: '已付金额',
      paidBill: '已付票据',
      status: '订单状态',
      remark: '备注',
      taxRate: '税率',
      itemAddedTax: '小计（含税）',
      addOrderTitle: '添加采购订单',
      editOrderTitle: '编辑采购订单',
      viewOrderTitle: '查看采购订单',
      orderInfo: '订单信息',
      deleteOrderTips: '是否确定【刪除】该订单？',
      invalidOrderTips: '是否确定【作废】该订单？',
      stopOrderTips: '是否确定【终止】该订单？',
      chooseProduct: '从供应商产品选择',
      chooseAllProduct: '从全部产品选择',
      number: '数量',
      unitPrice: '单价(不含税)',
      receipt: '收货',
      finish: '完成',
      end: '终止',
      invalid: '作废',
      modify: '变更',
      alter: '变更中',
      draft: '草稿',
      unaudit: '待审核',
      audited: '已审核',
      finishStatus: '完成',
      endStatus: '终止',
      invalidStatus: '作废',
      delivered: '已发货',
      finishTips: '是否确认【完成】该订单？',
      revokeTips: '是否【撤销】该订单',
      userIdNotEmpty: '请选择供应商',
      supplierIdNotEmpty: '请选择供应商',
      warehouseIdNotEmpty: '请选择仓库',
      deliveryTypeNotEmpty: '请选择交货方式',
      invoiceTypeNotEmpty: '请选择发票类型',
      deliveryDateNotEmpty: '请选择交货日期',
      orderDateNotEmpty: '请选择下单日期',
      payTypeNotEmpty: '请选择结算方式',
      currencyTypeNotEmpty: '请选择货币类型',
      deliveryAddressNotEmpty: '请填写交货地址',
      verifyOrderTips: '确认【审核】该订单',
      financePayInfo: '付款信息',
      financePayableDetails: '应付款',
      financePayDetails: '付款详情',
      financePayableInfo: '应付款',
      createFinancePayable: '创建应付款',
      orderHasPayable: '该采购订单已经关联应付款，点击确认【查看】已关联应收款',
      deliveryAddr: '交货地址',
      payableCreateAuto: '应付款由系统自动生成',
      startDate: '下单日期起',
      endDate: '下单日期止',
      supplierName: '供应商',
      amountReceived: '已收货金额',
      amountPaid: '已付金额',
      amountConfirmed: '已确认',
      amountUnConfirmed: '未确认',
      viewInventoryInTitle: '查看入库详情',
      viewOrderPayableTitle: '查看付款详情',
      orderDetails: '订单明细',
      printTitle: '打印采购订单',
      verifySelectedOrdersTips: '确认【审核】已选中采购订单',
      deliverySelectedOrdersTips: '确认【发货】已选中采购订单',
      completeSelectedOrdersTips: '确认【完成】已选中采购订单',
      pleaseSelectVerfyOrder: '请选择已审核采购订单',
      pleaseSelectUnVerifyOrder: '请选择待审核采购订单',
      pleaseSelectDeliveredOrder: '请选择已发货订单',
      inventoryProcessRate: '收货进度',
      paymentProcessRate: '付款进度',
      inventoryQueryKeyword: '编码、供应商',
      finishedQuantity: '已收货数量',
      purchaseReturn: '退货',
      purchaseReturnOrder: '采购退货',
      outOrderCode: '委外订单编码',
      addOutOrderTitle: '添加委外订单',
      editOutOrderTitle: '编辑委外订单',
      viewOutOrderTitle: '查看委外订单',
      salesOrderCode: '销售订单编码',
      exportOrderDetail: '采购订单明细导出',
      enterpriseOrder: '转互联订单',
      enterpriseOrderTips: '是否确认该采购订单转互联订单?',
      enterpriseOrderSuccessTips: '互联订单已生成 待销售方审核',
      alterRecords: '变更记录',
      deliveryTips: '是否确认更新该采购委外订单?',
      requireCode: '委外需求编码',
      outOrderDelivery: '委外订单发货',
      batchInventoryIn: '批量入库',
      deliveryWarehouse: '入库仓库',
      plesaseSelectPurchaseOrder: '请选择采购订单',
      isInventoryIn: '是否收货',
      warehousePlease: '请选择收货仓库',
      purchaseOrderAlter: '订单变更',
      purchaseOutOrderAlter: '订单变更',
      payableReturnAmount: '应付款退款',
      inventoryInDate: '入库日期',
      aboutReturnAmount: '退款金额'
    },
    out: {
      orderCode: '委外订单编码',
      orderDate: '下单日期',
      finishDate: '完成日期',
      settlementMethod: '结算方式',
      invoiceType: '开票类型',
      agent: '经办人',
      audit: '审核人',
      auditTime: '审核时间',
      pleaseChooseAudit: '请选择审核人',
      pleaseChooseAgent: '请选择经办人',
      address: '交货地址',
      amount: '总金额',
      BOM: 'BOM',
      queryKeyword: '编码、供应商、联系人',
      orderInfo: '订单信息',
      orderDetails: '订单明细',
      orderStatus: '状态',
      startDate: '下单时间起',
      endDate: '下单时间止',
      baseInfo: '基本信息',
      addOrder: '添加委外订单',
      editOrder: '编辑委外订单',
      viewOrder: '查看委外订单',
      orderDelivery: '订单发货',
      editProductBom: '编辑产品BOM',
      viewProductBom: '查看产品BOM',
      selectProductBom: '选择产品物料',
      deleteOrderTips: '是否【删除】该订单',
      auditOrderTips: '是否【审核】该订单',
      revokeOrderTips: '是否【撤销】该订单',
      cancelOrderTips: '是否【作废】该订单',
      orderDatePlease: '请选择下单日期',
      finishDatePlease: '请选择完成日期',
      agentPlease: '请选择经办人',
      otherExpense: '其它费用',
      outsourcingOut: '委外领料',
      outsourcingIn: '委外入库',
      addInventoryIn: '添加入库单',
      addInventoryOut: '添加出库单',
      inventoryprocessrate: '入库进度',
      paymentprocessrate: '付款进度',
      exportMultiAddress: '代发订单导出',
      importOutOrderExpress: '委外订单',
      multiAddressOutOrderExpress: '代发委外订单'
    },
    receipt: {
      receiptCode: '编码',
      supplierName: '供应商',
      receiptDate: '收货日期',
      purchaser: '采购员',
      amountExcludedTax: '总额(不含税)',
      amountAddedTax: '总额(含税)',
      return: '退货',
      viewPurchaseReceiptTitle: '查看采购收货',
      receiptBaseInfo: '基本信息',
      purchaseOrder: '采购订单',
      receiptReturnDetails: '退货单详情',
      receiptReturnProductDetails: '退货单产品详情',
      receiptReturnInfo: '退货信息',
      receiptProductSelector: '收货单产品选择器',
      queryKeyword: '编码、供应商',
      startDate: '下单日期起',
      endDate: '下单日期止',
      detailsqueryKeyword: '采购订单编码、入库单编码、供应商、产品信息',
      purchaseReceipt: '采购收货',
      purchaseReceiptDetails: '采购收货明细'
    },
    return: {
      queryKeyword: '编码、供应商',
      returnOrderCode: '退货单编码',
      purchaseOrderCode: '采购编码',
      operator: '经办人',
      supplier: '供应商名称',
      currency: '货币',
      returnDate: '退货日期',
      returnStatus: '退货单状态',
      returnReason: '退货理由',
      orderAmount: '订单金额',
      amountExcludedTax: '总额(不含税)',
      amountAddedTax: '总额(含税)',
      remark: '备注',
      draft: '草稿',
      pending: '待审核',
      audit: '已审核',
      finish: '完成',
      returnDateNotEmpty: '请选择退货日期',
      operatorIdNotEmpty: '请选择经办人',
      returnReasonNotEmpty: '请填写退货原因',
      supplierPlease: '请选择供应商',
      currencyPlease: '请选择货币',
      addTitle: '添加采购退货单',
      editTitle: '编辑采购退货单',
      viewTitle: '查看采购退货单',
      orderTitle: '采购订单',
      startDate: '退货日期起',
      endDate: '退货日期止',
      pleaseChooseSupplier: '请选择供应商',
      supplierProduct: '供应商产品',
      purchaseReceipt: '收货单',
      returnBaseInfo: '基本信息',
      pleaseSelectOperator: '请选择经办人',
      receiptProduct: '收货单产品',
      verifyReturnOrderTips: '确认审核该退货单',
      doneReturnOrderTips: '确认完成该退货单',
      revokeReturnOrderTips: '确认撤销该退货单',
      returnInventoryInfo: '出库信息',
      inventoryOutDetails: '出库单详情',
      inventoryOutProductDetails: '出库单产品详情',
      inventoryOutCode: '出库单编码',
      inventoryOutDate: '出库日期',
      warehouse: '仓库',
      warehouseOperator: '仓库经办人',
      purchaseOrder: '采购订单',
      inInventory: '入库单',
      inventoryOut: '仓库已发货',
      inventoryIn: '仓库已收货',
      toBeCollection: '待财务收款',
      financeCollection: '财务已收款',
      inventoryOutTips: '确认仓库已发货',
      financeCollectionTips: '确认财务已收款',
      purchaseOrderProduct: '采购单产品',
      pleaseChoosePurchaseOrder: '请选择采购订单',
      pleaseChoosePurchaseOutOrder: '请选择委外订单',
      inventoryOutProcessRate: '出库进度',
      inventoryInProcessRate: '入库进度',
      financeProcessRate: '退款进度',
      printTitle: '采购退货打印',
      returnQuantityOver: '退货数量超出订单数量',
      purchaseOutOrderProduct: '委外订单产品',
      purchaseOutOrderProductSelect: '委外订单产品选择',
      addOutReturnTitle: '添加委外退货单',
      editOutReturnTitle: '编辑委外退货单',
      viewOutReturnTitle: '查看委外退货单',
      isOutDelivery: '委外方是否已发货',
      isReturnInventory: '是否退回仓库',
      outNotDelivery: '未发货',
      outHasDelivery: '已发货',
      returnToOut: '退回委外方',
      returnToInventory: '退回仓库'
    },
    expense: {
      addExpense: '添加采购费用',
      editExpense: '编辑采购费用',
      viewExpense: '查看采购费用',
      deleteExpenseTips: '是否删除该费用单'
    },
    price: {
      viewProductPrice: '查看产品采购价格',
      editProductPrice: '编辑产品采购价格',
      addProductPrice: '添加产品采购价格',
      deletePriceTips: '确认【删除】该价格',
      attentionTipsTitle: '*销售价格导入注意事项*',
      attentionTips1: '1.建议使用官方Excel模板导入数据 ',
      attentionTips2: '2.上传EXCEL后，请准确填写标题行号和数据起始行号',
      attentionTips3: '3.请准确填写数据映射关系并检查数据预览是否相符',
      priceImport: '销售价格导入',
      submitData: '提交数据',
      importData: '数据导入',
      dataMap: '数据映射',
      previewData: '数据预览',
      previewResult: '导入结果',
      excelFile: 'Excel文件',
      excelTitleRowNumber: '标题行号',
      excelDataRowNumber: '数据起始行号',
      sourceTitleName: '导入列标题',
      targetTitleName: '映射对象',
      currencyType: '币种',
      taxRate: '税率',
      nextStep: '下一步',
      lastStep: '上一步',
      fileError: '文件类型错误或者未按模板格式填写',
      fileUploading: '文件加载中',
      ignoreEmptyPrice: '忽略价格为空或为0的数据',
      productCodeMapColumnCanNotEmpty: '产品编码映射列不能为空',
      noDataNeedImport: '没有需要导入的数据',
      serialNumber: '序号',
      importStatus: '导入状态',
      importErrorReason: '原因',
      success: '成功',
      fail: '失败',
      executeResultInfo: '共{0}条记录，成功：{1}条，跳过：{2}条'
    },
    account: {
      accountNickname: '账户别称',
      accountBank: '开户银行',
      accountType: '账户类型',
      accountName: '账户户名',
      addTitle: '添加供应商账户',
      editTitle: '编辑供应商账户',
      viewTitle: '查看供应商账户',
      accountNicknameNotEmpty: '账户昵称不能为空',
      accountBankNotEmpty: '开户银行不能为空',
      accountTypeNotEmpty: '账户类型不能为空',
      accountNameNotEmpty: '账户名称不能为空',
      deleteTips: '是否删除该供应商账户 ?',
      accountNumber: '银行账号',
      accountNumberNotEmpty: '银行账号不能为空'
    },
    requisition: {
      queryKeyword: '编码、供应商、关联订单编码',
      startDate: '申请日期起',
      endDate: '申请日期止',
      requisitionCode: '请购单编码',
      suggestSupplier: '建议供应商',
      supplier: '供应商',
      contact: '联系人',
      applicationDate: '申请日期',
      applicant: '申请人',
      salesOrder: '关联销售订单',
      salesOrderStatus: '订购状态',
      contactName: '联系人',
      contactPhone: '联系人电话',
      auditStatus: '审核状态',
      auditor: '审核人',
      auditDate: '审核日期',
      deliveryDate: '交货日期',
      currency: '货币',
      addRequisitionTitle: '添加请购单',
      editRequisitionTitle: '编辑请购单',
      viewRequisitionTitle: '查看请购单',
      baseInfo: '基本信息',
      auditTips: '确认【保存并审核】该请购单',
      deleteTips: '确认【删除】该请购单',
      revokeTips: '确认【撤销】该请购单',
      amountTax: '价税总计',
      amountSubtotal: '价税小计',
      purchase: '采购',
      multiPurchase: '多供应商采购',
      deliveryAddress: '交货地址',
      notOrdered: '未订购',
      partialOrdered: '部分订购',
      fullOrdered: '全部订购',
      purchaseOrder: '采购订单',
      applicantTips: '申请人不能为空',
      applicationDateTips: '申请日期不能为空',
      currencyIdTips: '货币不能为空'
    },
    tooltip: {
      purchaseOrderView: {
        source: '数据来源: 采购管理-采购订单',
        rule: '取数规则: 已入库的采购收货，已确认、待确认的采购付款',
        dimension: '统计维度: 订单按月按统计已收金额、付款金额'
      },
      orderSupplierAmount: {
        source: '数据来源: 采购管理-采购订单',
        rule: '取数规则: 已入库的采购收货，已确认、待确认的采购付款',
        dimension: '统计维度: 供应商按月按统计订单'
      },
      orderProductAmount: {
        source: '数据来源: 采购管理-采购订单',
        rule: '取数规则: 已审核、已完成的采购订单',
        dimension: '统计维度: 产品按月按统计订单'
      },
      orderReceiptSummary: {
        source: '数据来源: 采购管理-采购订单',
        rule: '取数规则: 已审核、已完成的采购订单，已入库的采购收货',
        dimension: '统计维度: 订单按月（默认：半年）按统计订单'
      }
    },
    config: {
      title: '采购参数设置',
      defaultAddress: '默认地址',
      popoverTitle: '收货地址',
      updateInventoryProcessRate: '重新计算全部采购订单收货进度和付款进度',
      updateOutOrderProcessRate: '重新计算全部委外订单付款进度',
      orderCompleteConfig: '订单完成设置',
      autoCompleteTipPrefix: '收货和付款完成时',
      autoCompleteTipSubfix: '天之后自动完成采购订单',
      autoCompleteTip: '自动完成满足条件的采购订单，当设置为0时，表示不自动完成'
    },
    priceType: {
      priceTypeName: '价格名称',
      relatedSupplier: '关联供应商',
      relatedSupplierType: '关联供应商类型',
      addRelatedSupplier: '添加关联供应商',
      addRelatedSupplierType: '添加关联供应商类型',
      addTitle: '添加价格类型',
      editTitle: '编辑价格类型',
      viewTitle: '查看价格类型',
      supplier: '供应商',
      supplierType: '供应商类型',
      priceTypeNameTips: '价格类型名称必填',
      relatedTips: '至少关联一种业务'
    },
    orderAlter: {
      queryKeyword: '关键字',
      alterVersion: '变更版本号',
      versionCreateAuto: '变更版本号自动创建',
      alterUserId: '变更人',
      alterReason: '变更原因',
      alterTime: '变更时间',
      alterStatus: '变更状态',
      rejectReason: '拒绝原因',
      auditorName: '审核人',
      before: '变更前',
      after: '变更后',
      addTitle: '添加采购变更单',
      editTitle: '编辑采购变更单',
      viewTitle: '查看采购变更单',
      pending: '待审核',
      audited: '已审核',
      reject: '驳回',
      alterReasonNotEmpty: '变更原因不能为空',
      deleteTips: '是否确认删除该采购变更单?',
      newPayableAmount: '应付金额（变更后）',
      newQuantityLessThanInventoryQuantity: '变更后数量不能小于已入库数量',
      newPayableAmountLessThenPaymentAmount: '变更后应付款金额不能小于已付款金额',
      payableAmountGreatThenOrderAmountTips: '应付金额大于订单金额，请调整底部应付款金额',
      orderAmountGreatThenPayableAmountTips: '订单金额大于应付金额，变更成功后需手动添加剩余应付款，是否确认变更',
      alterAuditTips: '确认【保存并审核】该变更单，确认后原采购订单将被修改',
      saveSuccessAndAuditTips: '保存成功，是否确定【审核】该变更单，确认后原采购订单将被修改',
      alterAuditPassTips: '确认审核【通过】该变更单，确认后原采购订单将被修改',
      orderAdjusterPlease: '请选择变更人'
    },
    outRequire: {
      orderCode: '委外需求编码',
      businessType: '关联业务类型',
      businessCode: '关联业务编码',
      toOutOrder: '转委外订单',
      finish: '完成',
      end: '终止',
      trash: '作废',
      processing: '处理中',
      batchOutOrder: '批量委外订单',
      addTitle: '添加委外需求',
      editTitle: '编辑委外需求',
      viewTitle: '查看委外需求',
      pleaseChooseOutRequire: '请选择委外需求',
      deleteTips: '是否确认删除该委外需求?',
      finishTips: '是否确认完成该委外需求, 完成后委外需求数量将按委外订单数量之和更新',
      endTips: '是否确认终止该委外需求?',
      trashTips: '是否确认作废该委外需求?',
      addBatchOutOrderTips: '是否确认批量生成委外订单?',
      queryKeyword: '委外需求编码、关联业务编码、收货人',
      process: '委外需求进度',
      summaryProduct: '委外需求产品汇总',
      divideOrder: '委外需求分单',
      outRequire: '委外需求',
      outOrder: '委外订单',
      addOutOrderSuccess: '成功生成委外订单',
      prev: '上一步',
      next: '下一步',
      supplierOrTaxRateOrQuantityTips: '供应商 税率 数量不能为空',
      atLeastOneOutOrder: '至少一条委外订单',
      recommendSupplier: '建议供应商',
      overViewDeleteTips: '是否确认【删除】',
      printTitle: '打印委外单',
      businessOperator: '销售录单人',
      batchSetSupplier: '批量设置',
      pleaseSelectProduct: '请选择产品',
      pleaseSelectSupplier: '请选择供应商',
      suggestedSupplier: '建议供应商',
      addOutOrderBatch: '确认添加所有委外订单，添加后可前往【委外订单】查看详情',
      requireQuantity: '需求数量',
      outOrderQuantity: '委外订单数量',
      supplierEmpty: '未选供应商'
    },
    outOrder: {
      addTitle: '添加委外订单'
    }
  },
  inventory: {
    deliveryType: {
      year: '年',
      month: '月',
      day: '日',
      week: '星期',
      interval: '区间',
      orderSetting: '下单设置',
      notAutomatic: '不自动下单',
      automatic: '发货单审核自动下单',
      defaultShippingCourierCompany: '默认发货快递公司',
      configDeliveryType: '配置交货方式信息',
      dimensionDate: '例外日期',
      dateType: '日期类型',
      addDimensionDate: '添加例外日期',
      everyMonday: '每个星期一',
      everyTuesday: '每个星期二',
      everyWednesday: '每个星期三',
      everyThursday: '每个星期四',
      everyFriday: '每个星期五',
      everySaturday: '每个星期六',
      everySunday: '每个星期天',
      dimensionDateTip: '请选择日期',
      dimensionWeekTip: '请选择星期'
    },
    config: {
      enabledStock: '可用库存',
      allEnabledStock: '全部可用库存',
      realTimeStock: '即时库存',
      addAdvanceStock: '加项-预计入库量',
      reduceAdvanceStock: '减项-预计出库量',
      lockStock: '减项-锁定量',
      mallOrderStockConfig: '减项-商城订单',
      auditedPurchaseOrder: '已审核采购订单',
      pendingInitialIn: '待入库-期初',
      pendingOtherIn: '待入库-其他入库',
      pendingFinishedProduct: '待入库-成品入库',
      auditedInventoryCheck: '已审核盘点单',
      pendingInterconnectOut: '待出库-互联调拨',
      pendingInterconnectIn: '待入库-互联调拨',
      paidWelfareOrder: '已兑换订单',
      auditedSalesOrder: '已审核销售订单',
      pendingOtherOut: '待出库-其他出库',
      none: '无',
      unpaidOrder: '待付款商城订单',
      paidOrder: '已付款商城订单',
      pendingReportOverflow: '待入库-报溢入库',
      pendingReportLoss: '待出库-报损出库',
      salesInventoryLock: '销售-锁定库存',
      confirmDistributionOrder: '已确认代发订单',
      inventoryInAutoEnabled: '入库通知自动生成入库单',
      inventoryInAutoNotConfirmWarehouseId: '生成待确认入库单',
      inventoryInAutoConfirmWarehouseId: '生成已确认入库单',
      inventoryInDefaultOperatorId: '仓库默认经办人',
      inventoryOutAutoEnabled: '出库通知自动生成出库单',
      inventoryOutAutoNotConfirmWarehouseId: '生成待确认出库单',
      inventoryOutAutoConfirmWarehouseId: '生成已确认出库单',
      inventoryOutDefaultOperatorId: '仓库默认经办人',
      inventoryOutAutoChooseWarehouse: '是否自动匹配满足条件的仓库',
      inventoryOutAutoSplitOrder: '是否自动拆单',
      inventoryOutAutoRules: '出库规则',
      defaultInWarehousePlease: '选择默认入库仓库',
      defaultOutWarehousePlease: '选择默认出库仓库',
      autoInventoryInConfig: '自动入库设置',
      autoInventoryOutConfig: '自动出库设置',
      inventoryInAutoGeneStatus: '生成入库单状态',
      inventoryOutAutoGeneStatus: '生成出库单状态',
      inventoryInAutoDefaultWarehouseId: '默认入库仓库',
      inventoryOutAutoDefaultWarehouseId: '默认出库库仓库',
      inventoryInAutoStatusPlease: '请选择生成入库单状态',
      inventoryInDefaultWarehouseIdPlease: '请选择默认入库仓库',
      inventoryInDefaultOperatorIdPlease: '请选择默认入库经办人',
      inventoryOutAutoStatusPlease: '请选择生成出库单状态',
      inventoryOutDefaultWarehouseIdPlease: '请选择默认出库仓库',
      inventoryOutDefaultOperatorIdPlease: '请选择默认出库经办人',
      inventoryInTips1: '默认入库仓库默认为前置业务中选择的仓库，前置业务未选择时使用配置的仓库',
      inventoryOutTips1: '默认出库仓库默认为前置业务中选择的仓库，前置业务未选择时使用配置的仓库',
      inventoryOutTips2: '自动出库时，负库存校验将会失效，可能出现负库存',
      inventoryStockConfig: '库存设置',
      enableStockSplitWarehouse: '可用库存分仓'
    },
    warehouse: {
      warehouseCode: '仓库编码',
      warehouseName: '仓库名称',
      warehousePhone: '仓库电话',
      warehouseFax: '仓库传真',
      warehouseManager: '仓管员',
      area: '所属区域',
      address: '详细地址',
      isEnabled: '启用状态',
      searchPlaceholder: '仓库编码，仓库名称',
      remark: '备注',
      addWarehouseTitle: '添加仓库',
      editWarehouseTitle: '编辑仓库',
      viewWarehouseTitle: '查看仓库',
      deleteWarehouseTips: '是否确定删除该仓库?',
      warehouseNotEmpty: '请输入仓库名称',
      countryIdNotEmpty: '请选择仓库所属国家',
      areaIdNotEmpty: '请选择仓库所属区域',
      managerIdNotEmpty: '请选择仓库员',
      warehouseInfo: '仓库信息',
      isCalcStock: '是否计算可用库存',
      isStoreWarehouse: '是否门店仓库',
      shippeingInfo: '发货信息',
      shipper: '发货人',
      shipperPhone: '发货人电话',
      shipperMobilePhone: '发货人手机',
      shipperArea: '发货所属区域',
      shipperAddress: '发货地址',
      shipperTips: '提示：如需使用电子面单功能，该信息必须填写，否则将无法使用电子面单',
      storeWarehouseTips: '提示：门店仓库不计入可用库存'
    },
    details: {
      purchaseOrder: '采购订单',
      salesReturn: '销售退货单',
      outOrderIn: '委外订单',
      salesDelivery: '销售发货单',
      purchaseReturn: '采购退货单',
      outOrderOut: '委外出库',
      mallDeliveryOrder: '商城发货单',
      allocateOrder: '调拨单',
      checkOrder: '盘点单',
      packOrder: '装配单',
      businessCode: '上级业务编码',
      inventoryType: '出入库类型',
      bussinessType: '业务类型',
      operatorId: '业务经办人',
      warehouseOperatorId: '仓库经办人',
      bussinessTypeNotEmpty: '请选择业务类型',
      operatorIdNotEmpty: '请选择业务经办人',
      warehouseOperatorIdNotEmpty: '请选择仓库经办人',
      warehouseTips: '请选择仓库',
      keywords: '产品编码、产品名称',
      inventoryDate: '出入库日期',
      inventoryCode: '出入库单编码',
      quantity: '数量',
      amount: '小计(含税)',
      currency: '货币类型',
      currencyIdNotEmpty: '货币不能为空',
      exlcudeTaxUnitPrice: '单价(不含税)',
      includeTaxUnitPrice: '单价(含税)',
      warehouse: '仓库',
      targetName: '往来单位',
      productionDate: '生产日期',
      due: '到期日期',
      productionDateTips: '请填写生产日期',
      cargoValue: '按存货核算算法核算',
      costPrice: '单价',
      purchaseCargoValue: '设置采购价格核算',
      mallReturnIn: '商城退换货',
      mallReturnOut: '商城退换货',
      viewExpress: '查看物流信息',
      productionOrder: '生产订单',
      inventoryInInfo: '入库信息',
      inventoryOutInfo: '出库信息',
      clearProductTips: '是否确定清除产品子表',
      overProductOutTips: '产品存在超额出库，是否继续？，点击确定，继续提交，点击取消，则不提交',
      overProductInTips: '产品存在超额入库，是否继续？，点击确定，继续提交，点击取消，则不提交',
      saveSuccessAndWarehousingTips: '保存成功，是否确认入库？',
      saveSuccessAndDeliveryTips: '保存成功，是否确认出库？',
      submitStayInventoryIn: '提交待入库',
      submitStayInventoryOut: '提交待出库',
      updateExpress: '修改快递',
      outProductStockBatches: '出库批次',
      inProductStockBatches: '入库批次',
      outProductStockBatchesQuantity: '出库数量',
      inProductStockBatchesQuantity: '入库数量'
    },
    in: {
      title: '入库单',
      receiptCode: '入库单编码',
      inventoryDate: '入库时间',
      warehouse: '入库仓库',
      inventoryType: '入库类型',
      inventoryStatus: '入库状态',
      supplierName: '供应商名称',
      keywordQuery: '入库单编码、往来单位',
      targetNameNotEmpty: '请选择供应商',
      warehouseIdNotEmpty: '请选择入库仓库',
      inventoryTypeNotEmpty: '请选择入库类型',
      inventoryDateNotEmpty: '请选择入库日期',
      draft: '入库-草稿',
      pending: '待入库',
      finished: '已入库',
      initial: '期初',
      purchaseReceipt: '采购收货',
      salesReturn: '销售退货',
      allocateCallIn: '调拨调入',
      inventoryProfit: '盘点盘盈',
      other: '其他入库',
      finishedProduct: '成品入库',
      materialsReturn: '生产退料',
      outsourcingOrderIn: '委外入库',
      unpackIn: '拆装入库',
      packIn: '组装入库',
      reportOverflow: '报溢入库',
      addTitle: '添加入库单',
      editTitle: '编辑入库单',
      viewTitle: '查看入库单',
      deleteTips: '确认删除该入库单?',
      finishTips: '确认该入库单入库?',
      revokeTips: '确认撤销该入库单?',
      warehouseOperatorIdNotEmpty: '请选择仓库经办人',
      inventoryInfo: '入库单信息',
      inventoryTitle: '入库单',
      submitAndIn: '确认入库',
      quantity: '入库数量',
      businessUnit: '往来单位',
      serialNumber: '序列号',
      generateSerial: '生成序列号',
      productSerialNotEnough: '产品序列号不满足',
      manualCreate: '手工生成',
      autoCreate: '自动生成',
      codeAutoCreate: '按序列号生成规则自动生成',
      serialPrefix: '序列号前缀',
      serialStartNumber: '起始号',
      serialIncrement: '递增量',
      serialTotal: '总数',
      clearSerial: '清空序列号',
      inputManualSerial: '请输入手工序列号',
      replaceSerial: '存在重复序列号',
      onceMaxTips: '一次生成数量不能大于1000',
      productBomTitle: '产品物料',
      mallDelivery: '商城发货',
      orderNumber: '应入库数量',
      deliveryNumber: '待入库+已入库数量'
    },
    out: {
      title: '出库单',
      issueCode: '出库单编码',
      inventoryDate: '出库时间',
      warehouse: '出库仓库',
      inventoryType: '出库类型',
      inventoryStatus: '出库状态',
      customerName: '客户名称',
      keywordQuery: '出库单编码、往來单位',
      targetNameNotEmpty: '请选择客户',
      warehouseIdNotEmpty: '请选择出库仓库',
      inventoryTypeNotEmpty: '请选择出库类型',
      inventoryDateNotEmpty: '请选择出库日期',
      draft: '出库-草稿',
      pending: '待出库',
      finished: '已出库',
      saleDelivery: '销售发货',
      purchaseReturn: '采购退货',
      allocateCallOut: '调拨调出',
      inventoryLoss: '盘点盘亏',
      other: '其他出库',
      workshopPick: '生产领料',
      materialsRequest: '委外领料',
      unpackOut: '拆装出库',
      packOut: '组装出库',
      reportLoss: '报损出库',
      saleDeliveryTitle: '销售发货单',
      saleDeliveryInventoryout: '销售出库单',
      addTitle: '添加出库单',
      editTitle: '编辑出库单',
      viewTitle: '查看出库单',
      deleteTips: '确认删除该出库单?',
      finishTips: '确认该出库单出库?',
      revokeTips: '确认撤销该出库单?',
      warehouseOperatorIdNotEmpty: '请选择仓库经办人',
      orderNumber: '应出库数量',
      deliveryNumber: '待出库+已出库数量',
      currentNumber: '本次出库数量',
      inventoryInfo: '出库单信息',
      inventoryTitle: '出库单',
      submitAndOut: '确认出库',
      quantity: '出库数量',
      expressSettlementMethod: '结算方式',
      recipientPhone: '收件人手机号',
      updateDeliveryWarehouse: '调整发货仓库',
      confirmUpdate: '确认调整',
      pleaseSelectTheDeliveryWarehouse: '请选择发货仓库',
      inventoryTypeOrder: '业务单编码',
      salesOrderAllInventoryOutTips: '确认该订单下的发货单全部出库？',
      salesOrderAllInventoryOut: '全部出库'
    },
    allocate: {
      allocateCode: '调拨单编码',
      allocateDate: '调拨日期',
      callOutWarehouse: '调出仓库',
      callInWarehouse: '调入仓库',
      operator: '经办人',
      callInOperator: '调入经办人',
      callOutOperator: '调出经办人',
      callInDate: '调入日期',
      callOutDate: '调出日期',
      allocateStatus: '调拨状态',
      draft: '草稿',
      unaudit: '待审核',
      audited: '已审核',
      callIn: '已调入',
      callOut: '已调出',
      revokeAudit: '撤销审核',
      revokeCallIn: '撤销调入',
      revokeCallOut: '撤销调出',
      confirmCallIn: '确认调入',
      confirmCallOut: '确认调出',
      addTitle: '添加调拨单',
      editTitle: '编辑调拨单',
      viewTitle: '查看调拨单',
      allocateInfo: '调拨单信息',
      allocateDateNotEmpty: '请选择调拨日期',
      operatorIdNotEmpty: '请选择经办人',
      callOutOperatorIdNotEmpty: '请选择调出确认人',
      callInOperatorIdNotEmpty: '请选择调入确认人',
      callOutDateNotEmpty: '请选择调出日期',
      callInDateNotEmpty: '请选择调入日期',
      callOutWarehouseNotEmpty: '请选择调出仓库',
      callInWarehouseNotEmpty: '请选择调入仓库',
      allocateWarehouseNotEqual: '调入、调出仓库不能相同',
      auditTips: '确认审核该调拨单?',
      deleteTips: '确认删除该调拨单?',
      revokeAuditedTips: '确认撤销审核该调拨单?',
      confirmCallOutTips: '确认调出该调拨单?',
      confirmCallInTips: '确认调入该调拨单?',
      revokeCallOutTips: '确认撤销调出该调拨单?',
      revokeCallInTips: '确认撤销调入该调拨单?',
      enabledOutWarehouseStock: '调出仓库库存',
      saveAndNotAllocate: '提交待调拨',
      saveCompleteAllocate: '完成调拨',
      allocateOutSuccess: '调出成功',
      allocateInSuccess: '调入成功'
    },
    stock: {
      product: '产品',
      warehouse: '仓库',
      onOrder: '新增在途',
      inWarehouse: '新增在库',
      onOrderStock: '在途库存',
      lockStock: '锁定库存',
      inWarehouseStock: '在库库存',
      enabledStock: '可用库存',
      onTheWayStock: '采购在途',
      salesOccupyStock: '销售占用',
      salesPendingShipmentStock: '销售待发',
      otherBeInStock: '其他待入库',
      otherBeOutStock: '其他待出库',
      number: '出入库数量',
      addTitle: '新增库存',
      chooseStockProduct: '选择库存产品',
      warehouseIdNotEmpty: '请选择仓库',
      selectableDate: '可选生产日期',
      allInWarehouseStock: '总库存',
      byProductionDate: '按生产日期',
      details: '明细',
      salesOccupyStockDetails: '销售占用明细',
      salesPendingShipmentStockDetails: '销售待发明细',
      lockStockDetails: '锁定库存明细',
      onTheWayStockDetails: '采购在途明细',
      enableBoxGaugeValue: '可用箱数',
      splitWarehouseStock: '分仓库存',
      allWarehouseStock: '汇总库存'
    },
    check: {
      checkCode: '盘点单号',
      checkDate: '盘点日期',
      operatorId: '经办人',
      warehouseId: '仓库',
      warehouseOperatorId: '仓库经办人',
      checkType: '盘点类型',
      partCheck: '部分盘点',
      allCheck: '全部盘点',
      checkStatus: '盘点状态',
      quantity: '库存数量',
      checkQuantity: '实盘数量',
      profitQuantity: '损益数量',
      checkDateNotEmpty: '请选择盘点日期',
      warehouseIdNotEmpty: '请选择仓库',
      operatorIdNotEmpty: '请选择经办人',
      warehouseOperatorIdNotEmpty: '请选择仓库经办人',
      draft: '草稿',
      finished: '已完成',
      invalided: '已作废',
      addTitle: '添加盘点单',
      editTitle: '编辑盘点单',
      viewTitle: '查看盘点单',
      checkInfo: '盘点信息',
      finish: '完成',
      invalid: '作废',
      saveAndFinish: '保存并完成',
      deleteTips: '确定删除该盘点单?',
      finishTips: '确定完成该盘点单?',
      invalidTips: '确定作废该盘点单?',
      orderTab: '盘点单',
      detailsTab: '盘点明细'
    },
    account: {
      warehouse: '仓库',
      startDate: '开始时间',
      endDate: '结束时间',
      productCode: '产品编码',
      productName: '产品名称',
      productModel: '规格型号',
      productUnit: '计价单位',
      productAssistValue: '辅助属性',
      initialQuantity: '期初库存数量',
      duringInQuantity: '期间入库数量',
      duringOutQuantity: '期间出库数量',
      endQuantity: '期末库存数量'
    },
    common: {
      selectFromPurchaseOrder: '从采购订单选择产品',
      selectFromSalesDelivery: '从销售发货单选择产品',
      selectFromOutsource: '从委外订单选择产品',
      pleaseChooseTarget: '请选择往来单位',
      pleaseChooseOutsourceOrder: '请选择委外订单',
      selectFromSaleOrder: '从销售订单选择产品',
      inPrintTitle: '打印入库单',
      outPrintTitle: '打印出库单'
    },
    business: {
      salesOrder: '销售订单',
      salesDelivery: '销售发货',
      salesReturn: '销售货物',
      purchaseOrder: '采购订单',
      purchaseReturn: '采购退货',
      allocate: '调拨单',
      check: '盘点单',
      pack: '装配单',
      outsourceOrder: '委外订单',
      mallReturn: '商城退换货',
      mallReturnIn: '商城退换货',
      mallReturnOut: '商城退换货',
      distributionDelivery: '代发订单发货',
      inventorySecondmentIn: '库存借调调入',
      inventorySecondmentOut: '库存借调调出',
      exchangeCardOrderOut: '兑换订单',
      exchangeVoucherOrderOut: '兑换发货单'
    },
    scheme: {
      schemeCode: '方案编码',
      schemeName: '方案名称',
      schemeType: '方案类型',
      schemeStatus: '方案状态',
      keywords: '方案编码、方案名称',
      isBackward: '反向拆装',
      addPartProduct: '添加零件产品',
      addFinishedProduct: '添加成品产品',
      addTitle: '添加组合方案',
      editTitle: '编辑组合方案',
      viewTitle: '查看组合方案',
      partProductTips: '零件产品不能为空',
      finishedProductTips: '成品产品不能为空',
      deleteTips: '是否确认删除改组装方案?',
      schemeNameNotEmpry: '方案名称不能为空',
      limitOnefinishedProduct: '只能成品一个产品',
      productQuantityLessThenPackQuantity: '产品数量不能小于组装/拆装数量'
    },
    pack: {
      packCode: '装配单号',
      packType: ' 组装类型',
      packDate: '装配日期',
      packScheme: '组装方案',
      inWarehouse: '入库仓库',
      outWarehouse: '出库仓库',
      packQuantity: '组装数量',
      unPackQuantity: '拆装数量',
      packStatus: '装配状态',
      pack: '组装',
      unPack: '拆装',
      addPack: '添加组装',
      addUnpack: '添加拆装',
      scheme: '组合方案',
      inWarehouseDetails: '入库明细',
      outWarehouseDetails: '出库明细',
      addUnpackTitle: '添加拆装单',
      editUnpackTitle: '编辑拆装单',
      viewUnpackTitle: '查看拆装单',
      addPackTitle: '添加组装单',
      editPackTitle: '编辑组装单',
      viewPackTitle: '查看组装单',
      draft: '草稿',
      pending: '待确认',
      finished: '已确认',
      deleteTips: '是否删除装配单?',
      revokeTips: '是否撤销装配单?',
      packDateTips: '请选择装配日期不能为空',
      packTypeTips: '装配类型不能为空',
      inWarehouseTips: '入库仓库不能为空',
      outWarehouseTips: '出库仓库不能为空',
      inventoryPackSchemeId: '装配方案不为空',
      partProduct: '零件产品',
      finishedProduct: '成品产品',
      saveSuccessAndConfirmPackTips: '保存成功，是否确认完成组/拆装？',
      printTitle: '打印组装拆装单',
      maxUnPackQuantity: '最大可拆装数量',
      maxPackQuantity: '最大可组装数量'
    },
    expireManage: {
      keywords: '请输入产品名称或者产品编码',
      warehouseName: '仓库',
      productName: '产品名称',
      productCode: '产品编码',
      productModel: '规格型号',
      productConfig: '辅助属性',
      productUnit: '计价单位',
      count: '数量',
      InventoryDate: '入库日期',
      productDate: '生产日期',
      expireData: '保质期',
      due: '过期日期',
      expired: '已到期',
      expireAbout: '即将到期'
    },
    tab: {
      saleDelivery: '销售发货',
      purchaseReturn: '采购退货',
      outsourcing: '委外领料',
      outsourcingIn: '委外入库',
      purchaseReceipt: '采购收货',
      saleReturn: '销售退货',
      mallDelivery: '商城发货',
      mallReturn: '商城退换货',
      mallDeliverySelf: '商城自提发货',
      distributionDelivery: '代发订单发货',
      inventorySecondment: '库存借调',
      exchangeCardOrder: '兑换订单',
      exchangeVoucherOrder: '兑换发货单'
    },
    inventoryOutStatus: '出库状态',
    inventoryInStatus: '入库状态',
    inventoryProcessRate: '入库进度',
    status: {
      notStock: '未出库',
      partialStock: '部分出库',
      outOfStock: '已出库',
      notWarehous: '未入库',
      partialWarehous: '部分入库',
      outOfWarehous: '已入库'
    },
    cargoValue: {
      warehouseCargoValue: '仓库货值',
      catsCargoValue: '分类货值',
      brandCargoValue: '品牌货值',
      productCargoValue: '产品货值',
      warehouseQueryKeyword: '仓库名称',
      productQueryKeyword: '产品名称 产品编码 产品型号',
      detail: '货值明细'
    },
    lock: {
      inventoryLockCode: '申请编码',
      applicantId: '申请人',
      applyDate: '申请日期',
      effectType: '锁定类型',
      customer: '客户',
      receiptAmount: '收款金额',
      lockDate: '锁定日期',
      unlockDate: '解锁日期',
      lockReason: '锁定原因',
      lockStatus: '状态',
      direct: '直接生效',
      assignDate: '指定日期生效',
      deliveryOn: '款到生效',
      draft: '草稿',
      pending: '待审核',
      audited: '已审核',
      effected: '已生效',
      unlocked: '已解除',
      deleteTips: '是否删除该锁定库存记录?'
    },
    warning: {
      ltMinStock: '小于最小库存',
      ltSafetyStock: '小于安全库存',
      gtMaxStock: '大于最大库存'
    }
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  basicdata: {
    dataListTitle: '基础数据',
    keyNameZh: '名称(简体)',
    keyNameEn: '名称(英文)',
    keyNameZhTw: '名称(繁体)',
    keyValue: '值',
    remark: '备注',
    dataSort: '排序',
    customerLevel: '客户级别',
    customerSource: '客户来源',
    customerStatus: '客户状态',
    addressType: '地址类型',
    contactType: '联系人类型',
    followUpType: '跟进类型',
    followUpWay: '跟进方式',
    productKind: '产品分类',
    productUnit: '计价单位',
    supplierType: '供应商类型',
    purchaseDeliveryType: '采购送货方式',
    invoiceType: '开票类型',
    receivePayment: '收付款方式',
    settlementMethod: '结算方式',
    currencyType: '货币类型',
    taxRate: '税率',
    accountType: '账户类型'
  },
  syscode: {
    code: '编码',
    nameZh: '名称(简体)',
    nameEn: '名称(英文)',
    nameZhTw: '名称(繁体)',
    express: '表达式',
    sort: '排序',
    remark: '备注',
    statictext: '编号前缀',
    bussinessproperty: '业务属性',
    dateexpress: '日期格式',
    seqexpress: '序列',
    seqminlength: '最小长度',
    seqstartvalue: '起始值',
    seqrandom: '随机数',
    ranvaluetype: '随机数类型',
    ranonlynum: '数字',
    ranonlychar: '字母',
    ranmix: '数字字母混合',
    ranlength: '随机数长度',
    createexpress: '生成表达式',
    fullexpress: '完整表达式',
    example: '示例'
  },
  system: {
    switchLanguageSuccess: '语言切换成功'
  },
  exception: {
    tenantInfoNull: '未获取到租户信息',
    tenantExpired: '租户已过期,请联系管理员',
    noPermission: '您没有相关权限，请联系系统管理员进行授权',
    tenantExpiredOrWebsiteError: '域名不正确或租户已过期',
    jwtAuthFail: '登录超时，请重新登录',
    jwtAuthOverdue: '登录超时，请重新登录',
    networkError: '系统异常，请联系系统管理员或平台运营方',
    unknownError: '未知错误',
    loginNameError: '请输入正确的登录名',
    realNameEmpty: '请输入姓名',
    passwordTooShort: '密码长度不能少于6位',
    passwordErrorLock: '密码错误，超过{0}次将锁定，你还有{1}次机会',
    passwordErrorLock1: '密码错误，超过5次将锁定，你还有4次机会',
    passwordErrorLock2: '密码错误，超过5次将锁定，你还有3次机会',
    passwordErrorLock3: '密码错误，超过5次将锁定，你还有2次机会',
    passwordErrorLock4: '密码错误，超过5次将锁定，你还有1次机会',
    confirmPasswordError: '两次输入的密码不一致',
    tenantKeyError: '租户信息不能为空',
    loginIncorrect: '登录名或密码不正确',
    userNotExist: '用户不存在',
    passwordIncorrect: '密码不正确',
    userDisabled: '用户已被禁用，请联系管理员',
    userLocked: '用户已被锁定，请联系管理员',
    multiTerminalLoginError: '您的帐号于{0}在别处登录。如非本人操作，则密码可能已泄漏，建议修改密码！',
    wechatOrgSyncFail: '企业微信通讯录同步失败，请联系管理员',
    wechatCorpIdEmpty: '未配置企业微信的企业Id，请先配置',
    wechatOrgSyncSecretEmpty: '未配置企业微信通讯录同步Secret，请先配置',
    wechatOrgSyncNotAuth: '企业微信通讯录同步未授权，请先联系系统运维人员进行授权',
    wechatCaracalToolsNotAuth: '狞猫云工具应用未授权，请先联系系统运维人员进行授权',
    getWeworkServiceError: '获取企业微信服务失败',
    weworkLoginError: '开启企业微信登录 需要配置企业id、企业应用id、企业应用Secret',
    weworkConfigError: '请配置企业id、企业应用id、企业应用Secret',
    wechatAppIdEmpty: '未配置企业微信的应用Id，请先配置',
    wechatAppSecretEmpty: '未配置企业微信的应用Secret，请先配置',
    getWechatOrgFail: '获取企业微信部门信息失败',
    getWechatUserFail: '获取企业微信用户信息失败',
    getWechatTagFail: '获取企业微信标签信息失败',
    getWechatTagUserFail: '获取企业微信标签用户信息失败',
    wechatContactmeError: '生成企业微信[联系我]二维码失败,请检查用户是否加入企业微信',
    wechatExternalContactError: '获取外部联系人失败',
    securityAuthFail: '您没有权限，请联系管理员',
    paramError: '参数错误',
    addDataFail: '添加数据失败',
    updateDataFail: '更新数据失败,数据不存在或已被修改，请刷新后重试',
    deleteDataFail: '删除数据失败,数据可能操作过,请刷新后重试',
    getDataFail: '获取数据失败',
    dataNotExist: '数据不存在',
    roleNameEmpty: '角色名称不能为空',
    orgNameEmpty: '部门名称不能为空',
    orgTypeError: '部门类型不正确',
    hasOrgChildren: '该部门下存在子部门，不允许删除',
    hasOrgUser: '该部门下存在用户，不允许删除',
    orgNameRepeatError: '同一部門下部門名稱不能重複',
    loginNameEmpty: '登录名不能为空',
    passwordEmpty: '密码不能为空',
    emailError: '请输入正确的邮箱',
    imageTypeError: '请选择正确的图片(jpg,gif,png)',
    avatarSizeError: '上传头像图片大小不能超过 2MB!',
    logoSizeError: 'Logo图片大小不能超过 2MB!',
    imageSizeError: '图片大小不能超过 {0}MB!',
    fileSizeError: '文件大小不能超过 {0}MB!',
    passwordSame: '新密码和旧密码不能相同',
    loginNameExist: '登录名已存在',
    saveFileFail: '保存文件失败',
    uploadFileError: '上传文件失败',
    noUploadFile: '请选择要上传的文件',
    getFilePathFail: '获取文件路径失败',
    basicDataNameEmpty: '名称不能为空',
    basicDataValueEmpty: '值不能为空',
    attachmentTypeError: '附件不是指定类型的文件',
    attachmentSizeTooLarge: '附件超过指定大小',
    companyNameEmpty: '企业名称不能为空',
    taxNumberEmpty: '企业税号不能为空',
    noChooseAnyData: '未选择任何数据',
    noAddressTypeSelected: '未选择地址类型',
    noCountrySelected: '未选择国家',
    noAreaSelected: '未选择区域',
    addressEmpty: '详细地址不能为空',
    customerNameEmpty: '客户名称不能为空',
    customerNameExisted: '客户名称已存在',
    clueNameEmpty: '线索名称不能为空',
    noCustomerChoose: '没有选择客户',
    managerNoExisted: '客户经理不存在',
    targetManagerNoExisted: '目标客户经理不存在',
    nickNameEmpty: '昵称不能为空',
    noCustomerSelected: '未选择客户',
    noContactSelected: '未选择联系人',
    noFollowUpTypeSelected: '未选择跟进类型',
    noFollowUpWaySelected: '未选择跟进方式',
    followUpContentEmpty: '未填写跟进内容',
    followUpTimeEmpty: '未填写跟进时间',
    resubmitFail: '您操作太快了，请喝口水再操作',
    productTypeValueOrNameExist: '产品分类类名或键值已存在',
    typeExistProduct: '该产品分类存在产品',
    bussinessIdNotEmpty: '业务id不能为空',
    bussinessTypeNotEmpty: '业务类型不能为空',
    inventoryTypeNotEmpty: '出入库类型不能为空',
    inventoryStatusNotEmpty: '出入库状态不能为空',
    targetIdNotEmpty: '往来对象不能为空',
    targetNameNotEmpty: '往来对象不能为空',
    productsNotEmpty: '产品列表不能为空',
    existCollectionOrDelivery: '该订单存在收款或发货',
    exitDelivery: '订单存在发货记录',
    existCollection: '订单存在已产生应收款记录或者存在收款记录',
    existPay: '订单存在付款记录',
    existInventory: '订单存在出入库记录',
    existReturnOrder: '订单存在退货单',
    supplierNameExist: '供应商名称已存在',
    supplierShortNameExist: '供应商简称已存在',
    deleteMaterialFail: '删除产品物料清单失败',
    currencyNeeded: '请选择货币查询对应产品价格',
    productExistCurrencyPrice: '该产品存在当前货币的价格',
    systemReportConfigEmpty: '系统模板为空，请联系管理员',
    getSystemReportError: '该业务未配置打印模版，请联系管理员配置',
    systemReportTypeEmpty: '系统模板类型为空，请联系管理员',
    systemDefaultReportEmpty: '系统默认模板为空，请联系管理员',
    updateRequisitionStatusFail: '更新请购单状态失败',
    existOrderDataNotAllowRevoke: '存在订单数据，不允许撤销',
    orderExistDelivery: '订单存在发货数据',
    orderExistExpense: '订单存在销售费用',
    orderAmountLessThan: '修改后的订单金额不能小于销售发货金额或销售回款金额，请确认',
    selectedOrderAmountLessThan: '选中订单中存在修改后的订单金额小于销售发货金额或销售回款金额，请确认',
    orderExistReceivable: '订单存在应收款记录',
    orderExistAccountReceivable: '订单存在收款记录',
    salesOrderExistInventoryOutProduct: '订单存在关联出库单, 不能删除',
    salesOrderProductDeleteLessThenDelivery: '缺少销售订单关联的发货单中的产品，不能审核通过',
    salesOrderProductQuantityLessThenDelivery: '销售订单的产品数量小于关联的发货单中的产品，不能审核通过',
    salesOrderProductDeleteLessThenInventory: '缺少销售订单关联的出库单中的产品，不能审核通过',
    salesOrderProductQuantityLessThenInventory: '销售订单的产品数量小于关联的出库单中的产品，不能审核通过',
    salesOrderExistInventory: '销售订单存在出库记录，不能撤销，请与仓库协调处理',
    salesOrderDeliveryExistInventory: '销售订单的发货单存在出库记录，不能撤销，请与仓库协调处理',
    salesOrderExistCollection: '销售订单存在收款记录, 请与财务协调处理',
    salesOrderDeliveryExistCollection: '销售订单的发货单存在收款记录，不能撤销, 请与财务协调处理',
    b2cOrderDetailsEmpty: 'b2c订单明细为空',
    b2cCompanyEmpty: 'b2c明细中包含系统不存在公司',
    b2cCustomerEmpty: 'b2c明细中包含系统不存在客户',
    b2cProductCodeEmpty: 'b2c明细中包含系统不存在产品编码',
    b2cCompanyCountError: '文档中包含错误公司名称',
    b2cCustomerCountError: '文档中包含错误客户编码',
    b2cProductCountEmpty: '文档中包含错误产品编码',
    addSalesOrderB2cFail: '添加B2C主表失败',
    addSalesOrderB2cDetailsFail: '添加B2C明细失败',
    b2cOrderAddressEmpty: '文档中包含地址为空数据',
    productQuantityEmpty: '文档中包含产品数量为空数据',
    productStockNotExist: '文档中包含产品开启保质期管理无库存数据',
    unitPriceEmpty: '文档中包含单价为空数据',
    productStockOut: '库存不足',
    addStockFail: '添加库存失败',
    addInventoryOutFail: '添加出库单失败',
    inventoryInIsFinishedError: '该入库单已完成入库',
    inventoryOutIsFinishedError: '该出库单已完成出库',
    companyNameError: '公司名称为空',
    deleteTodoListFail: '删除待办事项失败',
    todoListProcessorEmpty: '待办事项处理人为空',
    addTodoListFail: '添加待办事项失败',
    addTodoListProcessorFail: '添加待办事项处理人失败',
    updateTodoListFail: '更新待办事项失败',
    updateSalesOrderB2cDetailsFail: '更新b2c明细失败',
    deleteSalesOrderB2cDetailsFail: '删除b2c明细失败',
    updateSalesOrderB2cFail: '更新b2c订单失败',
    salesOrderContainDelivery: '部分销售订单已发货，请重新选择',
    businessOrderContainReceivable: '部分单据已存在应收款，请重新选择',
    receivableOrderEmpty: '未生成订单，无法生成应收款',
    receivableDeliveryEmpty: '未生成发货单， 无法成应收款',
    receivableContainCollection: '部分应收款已收款',
    auditProcessorEmpty: '订单审核处理人不能为空',
    salesAuditConfigEmpty: '自由审核流程，审核设置为空，请设置流程',
    triggerConditionError: '自由审核流程，触发条件错误',
    productTypePriceNotExist: '自由审核模式，产品未设置价格类型对应价格',
    salesOrderIsAuditing: '自由审核模式，销售订单正在审核中',
    salesOrderRecallFail: '撤回销售订单失败',
    purchaseOrderExistPayment: '采购订单存在付款记录，请删除付款单后撤销',
    taxRateEmpty: '税率为空',
    orderStatusChange: '订单状态已更改，请刷新页面数据',
    categoryLevelConfigError: '分类层级设置错误',
    parentCategoryNotExist: '父级分类不存在',
    categoryNameOrKeyValueExist: '同级分类下存在名称或键值相同',
    categoryExistChildren: '分类下存在子级，不能删除',
    categoryExistGoods: '分类下存在商品',
    specsStatusEmpty: '商品规格类型为空',
    goodsSingleSkuError: '商品单规格信息错误',
    goodsSpecsTypeEmpty: '商品多规格类型不能为空',
    goodsMultiSkuSpecsEmpty: '商品多规格不能为空',
    specsTypeParamError: '规格类型参数错误',
    goodsMultiSkuSpecsMapError: '商品多规格映射错误',
    noSkuForItem: '没有此商品SKU',
    shoppingCartDataIncorrect: '购物车数据异常',
    orderHasBeenShipped: '订单已发货,不能修改地址',
    mallNameNotConfig: '未配置商城名称',
    overMaxCategoryLevel: '分类层级最大为3级，请重新调整',
    portalPhoneIsRegister: '手机号已注册',
    advanceReceiptIsUsed: '预收款正在被使用',
    collectionAmountLessThanReceivable: '实收金额小于应收款金额总和',
    advanceReceiptNotEnough: '可抵扣预收款不足',
    productAliasExist: '产品别名已存在',
    productBarcodeExist: '产品条形码已存在',
    advancePaymentIsUsed: '预付款正在被使用',
    paymentAmountLessThanPayable: '实付金额小于应付款金额总和',
    advancePaymentNotEnough: '可抵扣预付款不足',
    financePayableNotExist: '应付款不存在',
    payablePaidGreatThenBalance: '存在应付款本次付款金额大于应付款余额',
    financeReceivableNotExist: '应收款不存在',
    receivableCollectionGreatThenBalance: '存在应收款本次收款金额大于应收款余额',
    rechargeAmountDiffPaidAmount: '充值金额与支付金额不一致',
    selfPickUpCodeError: '提货码错误',
    userMobileExist: '用户手机号码已存在',
    notRelatedSalesOrder: '未关联销售订单',
    existOrderIsAdjusting: '该订单存在正在变更记录',
    salesOrderAdjustProductEmpty: '变更订单产品不能为空',
    inventoryQuantityGreatThenOrderQuantity: '变更后数量小于已发货数量',
    advanceReceiptTypeLimitEmpty: '预收款类型使用范围不能为空',
    advanceReceiptTypeIsUsed: '预收款类型已被使用, 不能删除',
    salesDeliveryExistInventory: '销售发货单存在出库记录',
    salesDeliveryExistCollection: '销售发货单存在收款记录',
    advanceReceiptLessThanBalance: '预收款余额不足',
    defaultAdvanceReceiptTypeNotExist: '默认预收款类型不存在',
    getAdvanceReceiptFail: '查询预收款失败',
    receivableItemDirectTypeEmpty: '未选择定向预收款类型',
    orderCollectionGreatThenOrderAmount: '订单变更后金额不能小于订单已收款金额',
    advanceReceiptDefaultAccountEmpty: '预收款类型未设置默认资金账户',
    salesOrderExistOutOrder: '销售订单存在委外发货',
    cancelOutRequireExistOutOrder: '委外需求存在委外订单不能直接作废',
    outRequireExistOrder: '委外需求存在委外订单，请删除数据后操作',
    queryDateRangeEmpty: '查询日期范围不能为空',
    smsAccountNotExist: '短信账号信息不存在',
    smsUsableCountNotEnough: '短信可用剩余量不足',
    addContactIsName: '添加联系人，需填写称呼',
    financeConfirmed: '收款单已确认，不能驳回，请刷新重试',
    financeReceivableConfirmed: '应收款已确认，不能驳回，请刷新重试',
    isAfterSalesOrder: '存在进行中的售后单',
    qrCodeCreateFail: '小程序码生成失败',
    welfareExchangeCardInfoError: '卡券信息错误，兑换失败',
    exchangeCardFail: '兑换卡券失败',
    welfareExchangeCardIsUsed: '卡券已被兑换',
    orderStatusChangedPleaseRefresh: '订单状态状态已变更，请刷新界面',
    noTemplateCompany: '所属公司下不存在选择的微信支付模板，请刷新重试',
    salesOrderNotDelivery: '销售订单未发货',
    salesOrderNotInventory: '销售订单未出库',
    salesOrderExistNotHandleDelivery: '销售订单存在未处理完成的发货单',
    salesOrderExistNotHandleInventory: '销售订单存在未处理完成的出库单',
    salesOrderExistNotHandleOutRequire: '销售订单存在未处理完成的委外需求',
    salesOrderExistNotHandleOutOrder: '销售订单存在未处理完成的委外订单',
    salesOrderNotAllDelivery: '销售订单未完成发货',
    salesOrderNotAllCollection: '销售订单未完成收款',
    salesOrderExistNotHandleCollection: '销售订单存在未处理完成的收款',
    salesOrderAdjustReceivableAmountLessCollectionAmount: '销售订单变更应收款金额小于已收款金额',
    salesDeliveryAdjustReceivableAmountLessCollectionAmount: '销售发货变更应收款金额小于已收款金额',
    salesOrderFinishCanNotOperate: '销售订单已完结不能进行操作',
    salesRetailPriceEmpty: '零售价不能为空',
    appHaveOnlinePlanNotDelete: '小程序存在上线的方案不能删除',
    appNotHave: '关联小程序不存在，请刷新重试',
    salesAccountStatementStatusChanged: '对账单状态已更改，请刷新页面重试',
    salesAccountStatementHasGeneNew: '新对账单已经生成不能撤销',
    planCodeNotNull: '方案编号不能重复',
    existNotDealOutOrder: '存在未处理的委外订单，不能进行完成操作',
    returnOrderStatusChange: '退货单状态已修改，请刷新页面重试',
    salesOrderOutDeliveryQuantityEmpty: '委外数量不能为空',
    salesOrderOutDeliveryQuantityGreatThen: '委外数量大于订单数量',
    mallOrderDeliveredCanNotRevokeFinance: '商城订单已发货，财务不能进行撤销作废操作',
    salesOrderOutDeliveryNotComplete: '销售订单委外发货未完成，销售订单不能操作完成',
    salesOrderInventoryNotComplete: '销售订单出库未完成, 销售订单不能操作完成',
    existMemberBindCanNotDelete: '存在会员绑定关系不允许删除',
    orderPaymentGreatThenNewOrderAmount: '订单变更后金额不能小于订单已付款金额',
    orderAlterPayableAmountLessPaymentAmount: '订单变更应付款金额小于已付款金额',
    outOrderNotDeliveryCanNotComplete: '请选择已发货数据进行批量完成',
    outOrderNotDeliveryCanNotAudit: '请选择待审核订单进行批量审核',
    purchaseOutOrderExistPayment: '委外订单存在付款记录，请删除付款单后撤销',
    syncSupplierProductFailed: '同步供应商产品失败',
    wechatTransferErrorCode: '微信转账失败',
    commissionNotEnough: '可提现佣金余额不足',
    commissionSummaryError: '佣金汇总数据错误',
    orderHasOverReturn: '订单超额退货',
    deliveryHasOverReturn: '发货单超额退货',
    dataStatusChanged: '状态已变更，请刷新界面重试',
    customerRebateNotEnough: '客户可提现返点金额不足',
    existImportingOrder: '存在正在导入的任务，系统繁忙，请稍后重试',
    orderIsAfterSales: '订单正在发起售后，请先完成相关售后单再操作,若已退款请提醒用户在小程序关闭相关售后单',
    orderReturnAmountOver: '订单退款金额大于订单金额，申请退款失败',
    returnGoodsEmpty: '退换商品为空',
    cardHasExchange: '卡券已经发生兑换',
    cardHasActivateNotAllowDelete: '该方案已关联了核销订单，不允许删除',
    activateCardExist: '【{0}】卡券已被核销',
    existChildAttachment: '文件夹内包含附件，请先删除附件',
    appIdNotConfig: '未配置小程序app_id',
    appSecretNotConfig: '未配置小程序secret_id',
    orderDeliveryOver: '订单已超额发货，请检查发货信息后操作',
    customerCreditCheckError: '客户授信额度不足，不能提交订单。请联络客户支付相关款项或者提升授信额度后再来操作',
    installRateConfigError: '分期比例设置错误，全部比例之和为100',
    customerCodeEmpty: '请输入客户编码',
    customerCodeExist: '客户编码已存在',
    partReceivableNotExist: '部分应收款已不存在，请检查应收款数据重新提交',
    partPayableNotExist: '部分应付款已不存在，请检查应付款数据重新提交',
    salesOrderCodeEmpty: '請輸入銷售訂單編碼',
    salesOrderCodeExist: '銷售訂單編碼已存在',
    salesOrderSelfCodeExist: '自編訂單號已存在或與訂單編碼重復',
    overloadInventoryIn: '【{0}】超额入库',
    overloadInventoryOut: '【{0}】超额出库',
    salesReturnNotInventoryInComplete: '销售退货未完成入库',
    salesReturnNotInventoryOutComplete: '销售退货未完成出库',
    salesReturnNotRefundComplete: '销售退货未完成退款',
    inventoryInAutoWarehouseNotConfig: '未配置仓库，无法自动生成入库单',
    inventoryInAutoStatusNotConfig: '未配置自动生成入库单状态',
    inventoryOutAutoWarehouseNotConfig: '未配置仓库，无法自动生成出库单',
    inventoryOutAutoStatusNotConfig: '未配置自动生成出库单状态',
    purchaseReturnExistInventory: '存在出入库数据，不能撤销',
    purchaseReturnExistFinance: '存在财务退款数据，不能撤销',
    salesReturnExistInventory: '销售退货存在出入库数据',
    existFinanceReceivableCanNotOutRequire: '存在应收款，不能转委外需求，请先变更发货单',
    deliveryOutRequireQuantityGreatThen: '超额发货, 请检查仓库发货或委外发货情况',
    salesOrderExistReturn: '销售订单存在销售退货',
    salesDeliveryExistReturn: '发货单存在销售退货',
    purchaseOrderExistReturn: '采购订单存在采购退货',
    memberVoucherIsUsed: '代金券已被使用',
    memberVoucherIsExpired: '代金券已过期',
    memberVoucherNotEnough: '代金券余额不足',
    memberVoucherAvailableNotEnough: '代金券抵扣金额超出可抵扣商品金额',
    memberVoucherDeductError: '代金券抵扣失败',
    memberVoucherExistDeleteError: '代金券已发放，删除失败',
    voucherCardReceived: '代金券卡已被领取',
    voucherCardError: '代金券卡号信息错误',
    outOrderQuantityGreatThenOutRequire: '委外订单数量超出委外需求数量',
    financeHasSalesRefund: '销售退款，财务已退款',
    salesReturnOutQuantityNotEnough: '销售委外可退数量不足，请变更委外订单',
    salesReturnNotInventoryQuantityNotEnough: '未发货可退数量不足',
    salesReturnInventoryQuantityNotEnough: '已发货可退数量不足',
    mallReturnQuantityError: '退换总数错误',
    mallOrderOverReturn: '订单超额退货',
    mallReturnNotInventoryQuantityError: '未出库退货数量错误',
    mallReturnInventoryQuantityError: '已出库退货数量错误',
    mallReturnOutQuantitySumError: '委外退货总数错误',
    mallReturnOutOrderQuantityError: '委外已发退货数量错误',
    mallOrderHasDelivery: '订单已发货',
    orderStatusCanNotFreeGoods: '当前订单状态不能添加赠品',
    orderHasReturnCanNotFreeGoods: '订单存在售后，不能添加赠品',
    exchangeQuantityEmpty: '换品数量不能为空',
    exchangeQuantityOver: '换品数量超出已发货数量',
    orderHasReturnCanNotExchangeGoods: '订单存在售后，不能换品',
    mallOrderExchangeAmountError: '订单换品金额不一致',
    authRelateProductNotExist: '互联授权产品不存在',
    inventoryInError: '入库失败, 请检查库存借调订单',
    inventoryOutError: '出库失败, 请检查库存借调订单',
    inventorySecondmentOutOver: '库存借调超额出库',
    inventorySecondmentInOver: '库存借调超额入库',
    salesReturnNotExistOutReturn: '请先进行委外退货操作',
    mallReturnNotExistOutReturn: '请先进行委外退货操作',
    purchaseOutOrderExistReturn: '委外订单存在退货',
    salesOrderReturnNotDeal: '销售订单退换货未处理',
    salesOrderReturnNotDealInventory: '销售订单退换货未处理出入库',
    salesOrderReturnNotDealFinance: '销售订单退货未处理退款',
    salesDeliveryReturnNotDeal: '销售发货单退换货未处理',
    salesDeliveryReturnNotDealInventory: '销售发货单退换货未处理出入库',
    salesDeliveryReturnNotDealFinance: '销售发货单退货未处理退款',
    salesOrderExistAdjust: '销售订单存在变更单',
    salesDeliveryExistAdjust: '销售发货单存在变更单',
    salesDeliveryNotDealInventory: '销售发货单未处理完出库',
    salesDeliveryNotDealFinance: '销售发货未处理完收款',
    outRequireNotOutOrder: '委外需求未处理，请转委外订单',
    purchaseOrderNotInventory: '采购订单未入库',
    purchaseOrderExistNotHandleInventory: '采购订单存在未完成的入库单',
    purchaseOrderNotInventoryComplete: '采购订单未完成入库',
    purchaseOrderNotFinance: '采购订单未付款',
    purchaseOrderNotCompleteFinance: '采购订单未完成付款',
    purchaseOrderExistAdjust: '采购订单存在变更单',
    purchaseOrderExistNotDealReturn: '采购订单存在未处理退货单',
    purchaseOutOrderExistAdjust: '委外订单存在变更单',
    purchaseOutOrderNotFinance: '委外订单未完成付款',
    customerSuperiorRepeat: '客户上级关系循环',
    mallExchangeGoodsNotDelivery: '换货商品未发货',
    mallOrderOverExchangeReturn: '换货商品已退完',
    mallExchangeGoodsNotOutReturn: '换货商品未进行委外换货',
    mallOrderNotDelivery: '订单未发货',
    multiAccountAmountError: '收付款账户金额合计与收付款总额不相等',
    projectLastDateError: '项目时间区间错误',
    projectAnalysisIsCalc: '项目正在生成数据中',
    projectDateNotCome: '该统计区间未开始或尚未结束，请再统计区间结束后再操作',
    inventorySecondmentInWarehouseStock: '可借调库存不足',
    syscode: {
      editErrorDateCheck: '表达式格式有误，请参考说明',
      submitErrorExpressBlank: '请生成表达式后再提交',
      submitErrorTextCheck: '启用编号前缀后请填写相关值',
      submitErrorDateCheck: '启用日期后请选择日期格式',
      checkExpressTextFailed: '请输入数字或字母'
    },
    inventory: {
      expireDateFieldsLack: '保质期等信息缺失',
      notExistProductCode: '【{0}】产品编码不存在',
      notExistProduct: '【{0}】产品不存在',
      costTypeError: '库存成本类型错误'
    },
    inventoryIn: {
      paramsError: '入库单参数错误',
      addDataFail: '添加入库单错误',
      updateDataFail: '更新入库单错误',
      deleteDataFail: '删除入库单错误',
      existProducts: '入库产品记录已存在',
      purchaseReceiptAmountLess: '确认入库金额不能小于已付款金额',
      repeatProductSerials: '存在重复产品序列号',
      productSerialNumberNotEnough: '产品序号不满足入库数量'
    },
    inventoryOut: {
      paramsError: '出库单参数错误',
      addDataFail: '添加出库单错误',
      updateDataFail: '更新出库单错误',
      deleteDataFail: '删除出库单错误',
      existProducts: '出库产品记录已存在'
    },
    stock: {
      updateDataFail: '更新库存失败',
      emptyProducts: '库存记录不存在',
      overLoadInventoryIn: '【{0}】超额入库',
      overLoadInventoryOut: '【{0}】超额出库'
    },
    existBusinessDataNotAllowDelete: '存在使用该数据的业务数据不允许删除',
    existBusinessDataNotAllowUpdate: '存在使用该数据的业务数据不允许更新',
    existBusinessDataNotAllowDeleteFormat: '{0}, 存在使用该数据的业务数据不允许删除',
    existBusinessDataNotAllowUpdateFormat: '{0}, 存在使用该数据的业务数据不允许更新',
    businessDataPreventAdd: '存在业务数据阻止添加数据',
    financeAccountName: '账户名称不能为空',
    financeAccountUserName: '账户户名不能为空',
    financeAccountNumber: '账户账号不能为空',
    financeAccountDeleteFailed: '数据可能被操作过，请刷新后重试',
    currentTypeIsNull: '没有可用币种',
    financeDepositAddFailed: '存取款添加失败',
    financeDepositUpdateFailed: '数据可能被操作过，请刷新后重试',
    financeDepositDeleteFailed: '数据可能被操作过，请刷新后重试',
    financeCollectBookAddFailed: '收款单添加失败',
    financeCollectBookUpdateFailed: '数据可能被操作过，请刷新后重试',
    collectIdIsNull: '参数为空,删除失败',
    financeCollectBookDeleteFailed: '数据可能被操作过，请刷新后重试',
    financeCollectBookIsNull: '收款单数据为空',
    financeCollectBookFormIsNull: '收款单添加失败,表单不能为空',
    financePayBookAddFailed: '付款单添加失败',
    financePayBookUpdateFailed: '数据可能被操作过，请刷新后重试',
    financePayBookDeleteFailed: '数据可能被操作过，请刷新后重试',
    financePayBookIsNull: '付款单数据为空',
    financePayBookFormIsNull: '付款单添加失败,表单不能为空',
    isNotGetId: '未获取到ID，请联系技术人员',
    isNotGetAmount: '应收金额为空',
    addFailed: '添加失败',
    updateFailed: '数据可能被操作过，请刷新页面重试',
    childDataUpdateFailed: '子表数据更新失败,数据不存在或已被修改,请刷新后重试',
    childDataAddFailed: '子表添加失败,数据可能已存在,请刷新后重试',
    childDataDeleteFailed: '子表删除失败，数据可能已被删除，请刷新后重试',
    collectCodeIsNull: '收款单编码不能为空',
    childDataIsNull: '子表数据为空,操作失败',
    receivableDeleteFailed: '参数为空,删除失败',
    childHaveData: '数据正在进行收款,删除失败',
    receivableFailed: '数据可能被操作过,请刷新后重试',
    parameterIsNull: '传入的参数为空',
    decideSaleId: '销售ID不能空',
    receivableCodeIsNull: '应收款编码不能为空',
    selectCollectCodeIsNull: '收款单编码为空',
    customerOrCurrencyIsNull: '客户参数或者货币参数不能为空',
    addAdvanceReceiptFailed: '添加预收款失败，请检查参数',
    saleOrderIdIsNull: '销售订单ID不能为空',
    saleBusinessIdIsNull: '业务id不能为空',
    saleDeliveryIdIsNull: '销售回款单ID不能为空',
    advanceLessThanBalance: '抵扣金额只能小于可抵扣金额',
    amountLessThanAmountThisTime: '本次收款只能小于等于应收金额',
    typeValueRepeat: '移入的目标级别下面的类别的键值不能重复',
    existNotAllowDelete: '存在使用该数据的业务数据不允许删除',
    addItemFailed: '增值税发票项目数据添加失败',
    updateItemFailed: '增值税发票项目数据更新失败',
    deleteItemFailed: '增值税发票项目数据删除失败',
    addDetailFailed: '行程单数据添加失败',
    updateDetailFailed: '行程单数据更新失败',
    deleteDetailFailed: '行程单数据删除失败',
    serialNumberNeedOnly: '填写的印刷序号已存在',
    invoiceCodeNeedOnly: '填写的发票代码或发票号码已存在',
    invoiceNumberNeedOnly: '填写的发票号码已存在',
    updateCategoryFailed: '批量调整产品分类失败',
    typeExistChildren: '该分类存在子级',
    typeExistName: '分类名称与目标分类同一等级下的分类名称相同',
    notChangeExistTypeName: '与相同等级的分类名称重复',
    notChangeExistTypeValue: '与相同等级的分类键值重复',
    maxNode: '最大分类不可超过5级',
    liveGoodsStatusNoSelect: '直播商品状态未查询到',
    sameProductCode: '产品编码已存在',
    geneReceivableBatchAmountOut: '批量添加/更新应收款时，存在应收款总额大于业务总金额的数据，添加/更新失败',
    geneReceivableAmountOut: '添加/更新应收款时，应收款总额不能大于业务总金额，添加/更新失败',
    deliveryExistExpense: '发货单存在费用',
    deliveryExistReturn: '发货单存在退货单',
    deliveryAmountLessThan: '修改后的发货单金额不能小于销售回款金额，请确认',
    selectedDeliveryAmountLessThan: '选中发货单中存在修改后的发货单金额小于销售回款金额，请确认',
    orderBomEmpty: '物料数据为空，添加失败',
    outBomDeleteFailed: '物料数据删除失败',
    revokeOrderOutFailed: '委外订单撤销失败',
    invalidOrderOutFailed: '委外订单作废失败',
    productEmpty: '产品数据不能空',
    planBomEmpty: '物料数据为空，添加失败',
    planProductDeleteFailed: '生产计划的产品数据删除失败',
    planBomDeleteFailed: '生产计划的物料数据删除失败',
    revokePlanFailed: '生产计划撤销失败',
    production: {
      bom: {
        replaceVersion: '重复的生产版本号'
      }
    },
    orderExistPayable: '订单存在付款记录',
    orderExistAccountPayable: '订单存在应付款记录',
    genePayableBatchAmountOut: '批量添加/更新应付款时，存在应付款总额大于业务总金额的数据，添加/更新失败',
    genePayableAmountOut: '添加/更新应付款时，应付款总额不能大于业务总金额，添加/更新失败',
    purchaseOrderExistExpense: '订单存在费用记录',
    purchaseOrderExistInventory: '订单存在收货记录',
    purchaseOrderAmountLess: '修改后的订单金额不能小于已收货金额或已付款金额，请确认',
    selectedPurchaseOrderAmountLess: '已选择订单中，修改后的订单金额不能小于已收货金额或已付款金额，请确认',
    purchaseReceiptOrderExistReturn: '该采购收货入库单存在退货记录',
    existNotAllowDeleteTag: '改标签有客户引用',
    repeatFiscalYear: '存在重复财年',
    existTagValue: '标签值已重复',
    companyIsExist: '所属公司已存在开票信息配置',
    customerCreditLimitIsNotEnough: '该客户可用授信额度不足，请提高授信额度后再操作',
    salesPriceTypeThreshold: '销售价格类型已有10条,无法再新增',
    expiryDateEnabledError: '存在引用数据,无法更新保质期开关',
    wxSdkError: '小程序码生成失败,请检查小程序是否上线',
    notExistProduct: '产品不存在',
    notExistProductFormat: '【{0}】产品不存在',
    mall: {
      emptyPaymentTemplate: '支付模板不存在',
      emptyPaymentAccountId: '支付模板没有关联账户',
      noExistPaymentAccountId: '支付模板关联账户不存在',
      noRemainPayAccount: '余额支付关联账户不存在',
      noMonthPayAccount: '挂账月结关联账户不存在',
      noDeductionPayAccount: '定向预收款支付关联账户不存在'
    },
    goodsSalesUnitPriceNotEmpty: '商品没有设置零售价，请在【销售管理-基础数据-销售价格】中设置',
    goodsPriceLevelNotEmpty: '商品没有设置客户结算价格标签，请在【销售管理-基础数据-销售价格】中设置',
    bindingPayTemplateFinanceAccount: '支付模板已绑定该资金账户,请先解除绑定再删除',
    bindingOfflinePayMentFinanceAccount: '该帐号已在在线商城—线下付款中绑定,请先解除绑定再删除',
    bindingRemainPayFinanceAccount: '该帐号已在在线商城—余额付款中绑定,请先解除绑定再删除',
    wxRefundFail: '微信退款失败【{0}】',
    deliveryBusinessNoDelete: '发货单已出库,不可删除',
    addressNoteFullNameEmpty: '姓名不能为空',
    addressNoteAddressEmpty: '地址不能为空',
    expressCompanyCodeEmptyError: '请选择快递公司',
    auditOrgIdCanNotBeCurrentEnterprise: '互联方不能是当前企业',
    enterpriseApplyNotExist: '互联申请记录不存在',
    auditEnterpriseNotExist: '接收方企业不存在',
    enterpriseInterconnectNotExist: '互联记录不存在',
    enterpriseInterconnectInvalid: '互联记录已失效',
    oppStandardEmpty: '对方本位币为空',
    standardEmpty: '己方本位币为空',
    enterprise: {
      applyNotExist: '互联申请记录不存在',
      applyAudited: '互联申请记录已审核 无法删除',
      oppStandardEmpty: '对方本位币为空',
      applyNotAudit: '互联申请记录待审核',
      productMappingExist: '产品互联映射关系已存在',
      productMappingNotExist: '【{0}】产品互联映射关系不存在',
      generatedOrder: '当前采购订单已生成互联订单',
      supplierEnterpriseMappingNotEXist: '当前供应商没有对应的互联企业'
    },
    memberLevelAssociatedMemberNoDelete: '该会员等级下存在会员，请先批量调整会员等级后再删除',
    referrerCustomerCanNotBeBindCustomer: '上级(推荐人)不能是当前绑定客户',
    purchaseAlterAmountLess: '采购变更单变更金额不能小于已收款金额',
    purchaseAlterQuantityLess: '采购变更单产品数量更不能小于已入库产品数量',
    purchaseAlterOncePending: '采购订单已存在一条待审核变更记录',
    existExternalContactBinding: '该客户 企微客户绑定关系已存在',
    purchaseRequireProductLessThanDeliveryQuantity: '委外需求数量小于委外订单已发货数量',
    existDeliveryOutOrderNotAllowDelete: '存在已发货委外订单 【{0}】',
    noBoundCustomer: '没有绑定客户',
    b2cShopCodeNotExist: '小店码不存在',
    b2cShopNotExist: 'b2c小店不存在',
    rejectReasonIsEmpty: '拒绝原因不能为空',
    b2cDistributionApplyExist: 'b2c小店分销申请已存在，请等待审核',
    b2cDistributionApplyNoExist: 'b2c小店分销申请记录不存在',
    b2cDistributionDetailExist: '该小店的分销记录已存在',
    b2cDistributionDetailNoExist: '该小店的分销记录不存在',
    salesInventoryLockExistFinanceAccountBook: '【{0}】销售锁定库存存在收款单',
    autoCollectionNotSetDefaultAccount: '默认预收款类型未设置默认账户，不能进行预收款自动抵扣，请前往财务管理-基础数据-预收款类型进行设置',
    queryDateEmpty: '请选择日期范围',
    sendSmsFailFormat: '{0}',
    onlineCount: '上线广告数已达上限',
    customPriceEmpty: '存在未设置价格商品，请设置价格后重新上线',
    imagePathEmpty: '请上传广告图片',
    requestError: '数据请求失败'
  },
  product: {
    productCode: '产品编码',
    productName: '产品名称',
    shortName: '产品简称',
    productCategory: '产品分类',
    productModel: '规格型号',
    productUnit: '计价单位',
    productMultipleUnitEnabled: '多计价单位',
    multipleUnitTips: '请输入多计价单位或单位数量',
    multipleUnitClear: '清空',
    productSize: '包装尺寸',
    packageSizeLength: '包装尺寸长',
    packageSizeWidth: '包装尺寸宽',
    packageSizeHeight: '包装尺寸高',
    productManufacture: '生产商',
    productBrand: '产品品牌',
    productBarcode: '条形码',
    productIncode: '内部条形码',
    productDescription: '备注',
    productProperty: '产品属性',
    productSource: '产品来源',
    productImage: '产品图片',
    productConfig: '辅助属性',
    invoiceName: '开票名称',
    eCommerceLink: '电商链接',
    eCommercePlatform: '电商平台',
    eCommerceUrl: '链接',
    taxRate: '税率',
    taxCategoryCode: '税收分类编码',
    financeInfo: '财务信息',
    shelfLife: '保质期管理',
    shelfLifeUnit: '保质期单位',
    shelfLifeRange: '保质期',
    warningDays: '预警天数',
    stockInfo: '库存信息',
    minStock: '最小库存',
    maxStock: '最大库存',
    safetyStock: '安全库存',
    remark: '备注',
    isEnable: '是否启用',
    isSerialNumber: '序列号管理',
    typeSort: '排序',
    parentTree: '父级',
    typeValue: '键值',
    typeName: '类别名称',
    productCategoryTitle: '产品分类',
    productTypeInfo: '产品类别详情',
    addChild: '添加子级',
    pleaseChooseProducts: '请选择产品',
    pleaseChooseType: '请选择产品分类',
    typeValueSpan: '同一等级键值不可重复',
    typeValueEmpty: '键值不能为空',
    pleaseInputTypeName: '请输入类别名称',
    pleaseInputTypeValue: '请输入键值',
    pleaseChooseParentId: '请选择父级',
    pleaseChooseDeleteProduct: '请选择要删除的产品',
    pleaseInputProductCode: '请输入产品编码',
    pleaseInputProductName: '请输入产品名称',
    pleaseInputInvoiceName: '请输入开票名称',
    pleaseChooseUnit: '请选择计价单位',
    pleaseChooseProductCategory: '请选择产品分类',
    adjust: '调整分类',
    pleaseChooseProduct: '请勾选要调整的产品',
    chooseNeedToType: '选择需要调整的目标分类：',
    updateProductCode: '是否需要同时更新产品编码：',
    previewImage: '预览图片',
    yes: '是',
    no: '否',
    deleteImage: '是否删除该图片？',
    updateProductCodeTips: '同步更新产品编码会导致产品编码发生变更，但不会影响历史业务数据，请确认是否要同步更新产品编码',
    pleaseSelectCate: '（请选择产品分类添加产品）',
    confirmDeleteText: '请确认要删除产品已无库存，否则部分系统数据将受影响，是否【确定】删除',
    purchasePrice: '采购价格',
    salesPrice: '销售价格',
    enabledStatus: '启用状态',
    productionLicenseCode: '生产许可证编码',
    outerBoxSize: '外箱尺寸',
    cartonSizeLength: '外箱尺寸长',
    cartonSizeWidth: '外箱尺寸宽',
    cartonSizeHeight: '外箱尺寸高',
    boxGauge: '箱规',
    weight: '计重重量',
    g: '克',
    mm: '毫米',
    invoiceInfo: '开票信息',
    productStock: '可用库存',
    boxGaugeSubfix: '/箱',
    recentSalesPrice: '最近销售价格',
    minSalesPrice: '最低销售价格',
    maxSalesPrice: '最高销售价格',
    standardPurchasePrice: '采购价格',
    recentPurchasePrice: '最近采购价格',
    minPurchasePrice: '最低采购价格',
    maxPurchasePrice: '最高采购价格',
    orderQuantity: '订单数量',
    outDeliveryQuantity: '委外数量',
    deliveredQuantity: '已发数量',
    productKind: '产品品类',
    pricingMethod: '计价方式',
    pricingByQuantity: '计件',
    pricingByWeight: '计重',
    productCodeCover: '是否导入产品编码',
    sameProductCodeConfig: '相同产品编码导入方式',
    sameProductCodeSkip: '跳过',
    sameProductCodeUpdate: '更新',
    productSources: {
      selfProduce: '自生产',
      outsourcing: '外购',
      outProduce: '委外生产'
    },
    productCodeTypes: {
      systemProduction: '系统生成',
      selfWriting: '自写'
    },
    property: {
      saleProduct: '销售产品',
      purchaseProduct: '采购产品',
      allProduct: '采购产品、销售产品'
    },
    number: '数量',
    price: '单价',
    productInfo: '产品信息',
    editProduct: '产品信息编辑',
    addProduct: '添加产品',
    config: {
      configKey: '属性键名',
      configName: '属性名称',
      configValue: '属性值',
      isEnable: '是否启用辅助属性',
      addConfig: '添加辅助属性',
      tips: '注意：属性值如有多个，请用分号";"隔开',
      baseConfig: '基本设置',
      productCheckBarCode: '是否校验条形码唯一性',
      attribute: {
        attribute1: '辅助属性1',
        attribute2: '辅助属性2',
        attribute3: '辅助属性3',
        attribute4: '辅助属性4'
      }
    },
    shelfLifeUnits: {
      day: '天',
      mouth: '月',
      year: '年'
    },
    supplier: '供应商',
    notSupplier: '暂无供应商',
    alias: {
      productAlias: '产品别名',
      queryKeyword: '产品编码，产品名称，规格型号',
      businessUnitName: '关联客户/供应商',
      deleteTips: '是否确认删除产品别名',
      pleaseSelectOne: '请选择要删除数据',
      businessUnit: '关联客户/供应商',
      customer: '客户',
      supplier: '供应商',
      addProductAlias: '添加产品别名',
      editProductAlias: '编辑产品别名',
      productNamePlease: '请输入产品名称'
    },
    inventoryStock: '库存占用查询',
    viewInventoryReduce: '库存占用',
    viewRelatedBusiness: '关联业务',
    inventoryReduce: {
      businessCode: '业务编码',
      businessType: '业务类型',
      deliveryCode: '发货单编码'
    },
    relatedBusiness: {
      salesOrder: '销售订单',
      salesDelivery: '销售发货单',
      purchaseOrder: '采购订单',
      purchaseOutOrder: '采购委外订单',
      inventoryIn: '入库单',
      inventoryOut: '出库单',
      salesInventoryLock: '销售-锁定库存'
    },
    disabledBatch: '批量不启用',
    disabledTips: '是否确认不启用如下产品?',
    quantityReport: {
      queryKeywords: '产品编码，产品名称',
      salesQuantity: '销售数量',
      purchaseQuantity: '采购数量',
      inventoryOutQuantity: '出库数量',
      inventoryInQuantity: '入库数量'
    }
  },
  sales: {
    name: '名称',
    defaultType: '默认方式',
    salesOrder: '销售订单',
    creatorId: '开单员',
    common: {
      draft: '草稿',
      unVerify: '待审核',
      verify: '已审核',
      quoted: '已报价',
      closedRejected: '已关闭-拒绝',
      closedRequote: '已关闭-重新报价',
      closedToOrder: '已关闭-转订单',
      closeDone: '完成',
      closeStop: '终止',
      closeCancel: '作废',
      canceled: '已作废',
      done: '已完成',
      close: '已关闭',
      orderCustomerAmount: '按客户统计-销售订单',
      deliveryCustomerAmount: '按客户统计-销售发货',
      orderProductAmount: '按产品统计-订单',
      deliveryProductAmount: '按产品统计-发货',
      allOrderCustomerAmount: '按客户统计-销售业绩',
      orderBrandAmount: '按品牌统计-订单',
      salesOrder: '销售订单',
      salesDelivery: '销售发货单',
      salesReturn: '销售退货单',
      pleaseSelectBusinessTypeFirst: '请先选择关联业务类型',
      clearAddress: '清空',
      deliveryExpenseInfo: '费用信息',
      orderExpenseInfo: '费用信息',
      yearMonth: '年月',
      orderSummary: '销售订单金额汇总',
      deliverySummary: '销售发货金额汇总',
      orderAmountDetails: '订单明细',
      deliveryAmountDetails: '发货明细',
      startYearMonth: '开始年月',
      endYearMonth: '结束年月',
      orderProductSelector: '订单产品选择',
      amount: '总金额',
      salesCollectionSummary: '销售回款金额统计',
      salesCollectionAmountDetails: '回款明细',
      collectionCustomertAmount: '按客户统计-销售回款',
      collectionDateStart: '回款日期起',
      collectionDateEnd: '回款日期止',
      orderCollectionOperatorAmount: '按开单业务员统计',
      orderCollectionCustomerManagerAmount: '按客户业务员统计',
      operator: '业务员',
      org: '部门',
      salesOrderAmount: '销售订单金额',
      salesCollectionAmount: '销售回款金额',
      salesOrderQuantity: '销售订单数量',
      exportOrderDetail: '导出销售订单明细数据',
      exportDeliveryDetail: '导出销售发货单明细数据',
      priceTypeName: '价格类型',
      subtotalPriceType: '小计价格类型',
      withTax: '(含税)',
      withoutTax: '(不含税)',
      expressRecordInfo: '快递信息',
      exportOrderTitle: '导出销售订单数据',
      auditor: '审核人',
      auditDate: '审核时间',
      reject: '已驳回',
      pleaseChooseOrderRange: '请选择订单范围',
      allOrder: '全部订单',
      myOrder: '我的订单',
      orgUserOrder: '组织机构用户订单',
      allProduct: '全部产品',
      queryProduct: '查询范围产品',
      selectedProduct: '已选产品',
      needAdjustSalesPriceCount: '需调整价格总数量',
      adjustAddSuccessCount: '新增价格数量',
      adjustUpdateSuccessCount: '更新价格数量',
      adjustFailCount: '跳过数量',
      adjustPriceTips: '价格计算结果小于等于零时，将跳过调价',
      rejected: '已驳回',
      adjusting: '变更中',
      reLoadStock: '重新加载库存',
      returnProduct: '退货',
      exchangeProduct: '换货',
      toAdvanceReceipt: '转预收款',
      toPayment: '退款',
      returnType: '退换货方式',
      returnRefundType: '退款方式',
      returnProductStatus: '客户是否收货',
      isProductInventoryOut: '仓库是否发货',
      isProductInventoryIn: '仓库是否收货',
      isProductLost: '货物状态',
      notLost: '未丢失',
      lost: '已丢失',
      notInventoryOut: '未发货',
      hasInventoryOut: '已发货',
      productReceived: '已收货',
      productNotReceived: '未收货',
      customerReceived: '已收货',
      customerNotReceived: '未收货',
      returnAdvanceReceiptType: '退款去向',
      inventoryIn: '仓库已收货',
      inventoryOut: '仓库已发货',
      toBeRefunded: '待财务退款',
      refunded: '财务已退款',
      returnAdvanceReceiptTypeEmpty: '退款预收款类型不能为空',
      dataPrecision: '计算保留小数位数',
      roundMethod: '取整',
      upRound: '向上取整',
      downRound: '向下取整',
      discountAmount: '优惠金额',
      biller: '开单员',
      toAdvancePayable: '转预付款',
      toCollection: '退款',
      barcodeInputPlaceholder: '商品条码，按回车输入',
      deliveryWarehouse: '发货仓库',
      warehouseProductStock: '发货仓库库存',
      creditBalance: '可用授信额度',
      baseInfo: '基本信息',
      deliveryInfo: '发货信息',
      productInfo: '产品信息',
      amountInfo: '费用及收款',
      otherInfo: '其它信息',
      salesOrderCodeReconciliation: '销售订单编码生成依据',
      purchaser: '采购员',
      autoGenePurchasePlan: '库存不足自动生成采购计划',
      productQuickQueryPlaceholder: '产品快捷输入',
      isProductPurchaseOut: '是否委外退货',
      notPurchaseOut: '否',
      hasPurchaseOut: '是',
      pleaseChooseOutOrderProducts: '请选择委外订单产品',
      outOrderTaxUnitPrice: '委外单价',
      salesOrderTaxUnitPrice: '销售单价',
      purchaseOutOrderCode: '委外订单编码',
      selectPurchaseOutOrderProduct: '委外订单产品',
      outOrderTaxPrice: '委外单价',
      outOrderPrice: '委外单价',
      purchaseOutReturnOrderAmount: '委外退款金额',
      deliveryQuantity: '发货单数量',
      inventoryQuantity: '已出库数量',
      outRequireQuantity: '委外需求数量',
      outOrderQuantity: '委外订单数量',
      hasReturnQuantity: '已退货数量',
      isReturnInventory: '是否退回仓库',
      returnPurchaseOut: '退回委外方',
      returnInventory: '退回仓库',
      orderInfoScreenCapture: '订单信息截屏',
      summaryProduct: '销售发货产品汇总',
      divideOrder: '销售发货分单',
      pleaseSelectWarehouse: '请选择发货仓库',
      warehouseEmpty: '未选发货仓库',
      addDeliveryBatch: '确认添加所有销售发货单',
      productVolumeSum: '预估总体积(m³)',
      refreshVolume: '重新计算体积',
      pleaseChooseQueryRange: '请选择查询范围',
      orderCreator: '开单员',
      customerManager: '客户经理'
    },
    exception: {
      quotationOutTaxPlease: '报价总额(不含税)不能为空',
      quotationWithTaxPlease: '报价总额(含税)不能为空',
      customerPlease: '请选择客户',
      contactPlease: '请选择联系人',
      quotationDatePlease: '请选择报价日期',
      deliveryDatePlease: '请选择交货日期',
      currencyPlease: '请选择货币',
      customerManagerPlease: '请选择业务员',
      operatorPlease: '请选择经办人',
      orderDatePlease: '请选择订单日期',
      invoiceTypePlease: '请选择开票类型',
      deliveryTypePlease: '请选择交货方式',
      paymentMethodPlease: '请选择付款方式',
      settlementMethodPlease: '请选择结算方式',
      productOver: '发货数量超出订单数量',
      productEmpty: '产品表为空',
      addProductFail: '添加产品失败',
      deleteProductFail: '删除产品失败',
      updateProductFail: '更新产品失败',
      existCollectionOrDelivery: '存在收款记录或发货记录',
      operateError: '操作失败',
      orderProductEmpty: '查询销售订单产品失败',
      pleaseChooseDeliveryProduct: '请选择发货单产品',
      returnDatePlease: '请选择退货日期',
      deliveryHasReceivable: '该销售发货单已经关联应收款，点击确认【查看】已关联应收款',
      orderHasReceivable: '该销售订单已经关联应收款，点击确认【查看】已关联应收款',
      receiptCreateAuto: '应收款由系统自动生成',
      occurrenceDatePlease: '请选择发生日期',
      expenseTypePlease: '请选择费用类型',
      expenditureTypePlease: '请选择支出类型',
      businessUnitPlease: '请选择往来单位',
      amonutPlease: '请输入金额',
      businessTypePlease: '请选择关联业务类型',
      businessPlease: '请选择关联业务',
      taxRatePlease: '请选择税率',
      unitPricePlease: '请输入单价',
      priceStatusPlease: '请选择单价是否含税',
      pleaseSelectVerfyOrder: '请选择已审核发货单',
      pleaseSelectUnVerifyOrder: '请选择待审核发货单',
      pleaseChoooseDateToExport: '请先选择导出数据的日期范围',
      exportFail: '导出失败',
      pleaseSelectSaleOrder: '请选择销售订单',
      outInventoryProductCount: '退货产品数量不能大于出库单产品数量',
      saleDeliveryProductCount: '产品数量不能大于销售发货单的产品数量',
      saleOrderProductCount: '产品数量不能大于销售订单的产品数量',
      emptySalesOrderId: '销售订单id不存在',
      amountGreatThenAdvanceReceiptAmount: '预收款余额不足',
      greatThenAvailableAdvanceReceipt: '超出可抵扣产品总额（折扣后）',
      returnAmount: '请输入退款金额',
      returnAmountGreatThenProductAmount: '退款总额不能大于产品总额',
      outReturnAmountGreatThenOutProductAmount: '委外退款总额不能大于委外产品总额',
      quantityGreatThenBusinessOrder: '退换数量不能超过业务单数量',
      returnRefundTypeEmpty: '退款方式不能为空',
      dataPrecisionPlease: '小数位数不能为空',
      roundMethodPlease: '计算取整方式不能为空',
      salesOrderNotEmpty: '请选择销售订单',
      salesOrderCodePlease: '请输入订单编码',
      returnQuantityOver: '退货数量超出订单数量',
      returnOutRequireCheckError: '委外可退数量不足，请先进行委外订单的退货/变更操作',
      returnAmountPlease: '请填写退款金额',
      salesQuotationPriceTypePlease: '请选择价格类型',
      returnProductAmountPlease: '请填写退产品金额',
      returnFreightAmountPlease: '请填写退运费金额'
    },
    salesOperate: {
      draft: '保存为草稿',
      submit: '提交',
      verify: '保存并审核',
      delivery: '发货',
      collection: '收款',
      invoice: '发票',
      done: '完成',
      stop: '终止',
      cancel: '作废',
      rejectQuote: '拒绝报价',
      selectProduct: '选择产品',
      revoke: '撤销',
      quote: '报价',
      toOrder: '转订单',
      requote: '重新报价',
      toInventoryOut: '出库单',
      save: '保存',
      allProduct: '所有产品',
      deliveryProduct: '发货单产品',
      saleOrderProduct: '销售订单产品',
      return: '退货',
      createFinanceReceivable: '创建应收款',
      financeReceivableInfo: '应收款',
      monthlyReport: '月报',
      annualReport: '年报',
      orderMonthlyReport: '销售订单金额统计-月度',
      deliveryMonthlyReport: '销售发货金额统计-月度',
      orderAnnualReport: '销售订单金额统计-年度',
      deliveryAnnualReport: '销售发货金额统计-年度',
      config: '配置',
      selectProductMaterial: '选择物料产品',
      selectOrderProduct: '从订单选择产品',
      multiAddress: '多地址发货',
      afterProductNext: '下一步',
      beforeProduct: '上一步',
      upload: '上传',
      uploadAddress: '导入',
      downloadExcel: '模板下载',
      uploadAgain: '重新上传',
      confirmDelivery: '确认发货',
      deliveryAgain: '继续发货',
      deleteAddress: '删除地址',
      invoiceApplication: '开票',
      recall: '撤回',
      pass: '通过',
      reject: '驳回',
      outRequire: '委外需求',
      adjust: '变更',
      financeCollectionInfo: '收款单',
      step: {
        selectProduct: '选择产品',
        uploadAddress: '地址上传',
        addressInfo: '地址信息',
        addSuccess: '发货成功',
        pleaseUserExcelModel: '请使用官方提供的Excel模板来导入数据',
        pleaseChooseProduct: '至少选择一条产品进行发货',
        addresseeIsNotEmpty: '收件人姓名不能为空',
        deliveryQuantityMoreThanOrder: '产品发货数量不能大于订单产品数量',
        fileError: '文件类型错误或者未按模板格式填写',
        addLine: '添加行',
        deleteLine: '删除行',
        tooltip: {
          attention: '*地址导入注意事项：',
          fileType: '1.请使用官方Excel模板导入数据;',
          dataFormat: '2.使用官方Excel模板时，请勿更改模板里面的英文和中文表头数据'
        }
      },
      address: {
        addressee: '收件人姓名',
        addresseePhone: '收件人电话',
        addresseeCompanyName: '收件人公司名称',
        deliveryAddr: '收件人详细地址',
        addresseePostCode: '邮编'
      }
    },
    saleTooltip: {
      orderMonthlyReport: {
        source: '数据来源: 销售管理-销售订单',
        rule: '取数规则: 已审核、终止、已完成的销售订单',
        dimension: '统计维度: 按每月统计'
      },
      annualOrderReportChart: {
        source: '数据来源: 销售管理-销售订单',
        rule: '取数规则: 已审核、终止、已完成的销售订单',
        dimension: '统计维度: 按每年统计'
      },
      listOfSalesOrders: {
        source: '数据来源: 销售管理-销售订单',
        rule: '取数规则: 已审核、已完成的销售订单',
        dimension: '统计维度: 按日期区间（默认：半年）统计'
      },
      monthlyDeliveryReportChart: {
        source: '数据来源: 销售管理-销售发货',
        rule: '取数规则: 已审核、已完成的销售发货单',
        dimension: '统计维度: 按每月统计'
      },
      annualDeliveryReportChart: {
        source: '数据来源: 销售管理-销售发货',
        rule: '取数规则: 已审核、已完成的销售发货单',
        dimension: '统计维度: 按每年统计'
      },
      orderCustomerAmount: {
        source: '数据来源: 销售管理-销售订单',
        rule: '取数规则: 已审核、已完成的销售订单',
        dimension: '统计维度: 按月份统计客户的销售订单总额'
      },
      deliveryCustomerAmount: {
        source: '数据来源: 销售管理-销售发货',
        rule: '取数规则: 已完成的销售发货单',
        dimension: '统计维度: 按月份统计客户的销售发货总额'
      },
      orderProductAmount: {
        source: '数据来源: 销售管理-销售订单',
        rule: '取数规则: 已审核、已完成的销售订单',
        dimension: '统计维度: 产品按月统计销售总额'
      },
      deliveryProductAmount: {
        source: '数据来源: 销售管理-销售发货',
        rule: '取数规则: 已完成的销售发货单',
        dimension: '统计维度: 产品按月统计销售发货总额'
      },
      saleSummary: {
        source: '数据来源: 销售管理-销售订单、销售发货',
        rule: '取数规则: 已审核、已完成的销售订单，已完成的销售发货单',
        dimension: '统计维度: 按月统计销售订单总额、销售发货总额'
      }
    },
    quotation: {
      queryKeyword: '编码、客户、联系人',
      quotationCode: '报价单编码',
      customer: '客户',
      contact: '联系人',
      quotationDate: '报价日期',
      currency: '货币',
      discount: '总体折扣',
      reductionAmount: '调减金额',
      amountOutTax: '总额(不含税)',
      amountWithTax: '总额(含税)',
      invoiceType: '开票类型',
      paymentMethod: '付款方式',
      settlementMethod: '结算方式',
      deliveryType: '交货方式',
      deliveryDate: '交货日期',
      expiryDate: '报价有效期限',
      customerManager: '业务员',
      deliveryNote: '交付说明',
      paymentNote: '付款说明',
      transportNote: '包装运输说明',
      quotationStatus: '状态',
      quotationEdit: '编辑报价单',
      quotationAdd: '添加报价单',
      quotationView: '查看报价单',
      deleteQuotationTips: '是否删除该报价单',
      revokeQuotationTips: '是否撤销该报价单',
      quoteQuotationTips: '是否确定向客户提供报价单',
      requoteQuotationTips: '是否重新报价该报价单',
      rejectedQuotationTips: '是否拒绝报价该订单',
      quotation2OrderTips: '是否将报价单转订单',
      quotationBaseInfo: '基本信息',
      viewSalesQuotationTitle: '查看销售报价单',
      editSalesQuotationTitle: '编辑销售报价单',
      addSalesQuotationTitle: '添加销售报价单',
      startDate: '报价日期起',
      endDate: '报价日期止',
      productMaterial: '子件',
      selectProductMaterial: '选择子件',
      editProductMaterial: '编辑产品子件',
      viewProductMaterial: '查看产品子件',
      saveProductPackScheme: '保存为组合方案',
      pleaseSelectProductMaterial: '请选择产品子件'
    },
    quotationStatus: {
      draft: '草稿',
      unVerify: '待审核',
      verify: '已审核',
      quoted: '已报价',
      closedRejected: '已关闭-拒绝',
      closedRequote: '已关闭-重新报价',
      closedToOrder: '已关闭-转订单'
    },
    quotationProduct: {
      product: '产品',
      quantity: '数量',
      price: '单价',
      taxRate: '税率',
      tax: '税金',
      amountOutTax: '小计(不含税)',
      amountWithTax: '小计(含税)'
    },
    product: {
      product: '产品名称',
      quantity: '数量',
      deliveredQuantity: '已发货',
      price: '单价(不含税)',
      taxRate: '税率',
      tax: '税金',
      amountOutTax: '小计(不含税)',
      amountWithTax: '小计(含税)',
      productCode: '产品编码',
      productName: '产品名称',
      productModel: '规格型号',
      productUnit: '单位',
      supplier: '供应商',
      productsAmountOutTax: '总额（不含税）',
      productsAmountWithTax: '总额（含税）',
      bussinessCode: '订单编码',
      orderQuantity: '订单数量',
      deliveryQuantity: '发货单数量',
      warehouseName: '仓库',
      productStock: '库存',
      amountExcludedTax: '小计（不含税）',
      amountAddedTax: '小计（含税）',
      taxPrice: '单价（含税）',
      materialCode: '物料编码',
      materialName: '物料名称',
      materialModel: '规格型号',
      materialUnit: '单位',
      materialQuantity: '数量',
      editProductMaterial: '编辑产品物料清单',
      viewProductMaterial: '查看产品物料清单',
      subtotal: '小计',
      currency: '货币',
      orderAmount: '订单总额',
      outQuantity: '已出库',
      advanceReceiptType: '定向抵扣预收款',
      directAdvanceReceiptAmount: '定向抵扣金额',
      directAdvanceReceiptAmountAvailable: '可抵扣产品总额（折扣后）',
      returnOrderAmount: '退款总额',
      productAmount: '产品总额',
      outDelivery: '委外发货数量',
      customerSalesPrice: '销售价格',
      customSalesCustomerPrice: '自定义价格',
      realSalesCustomerPrice: '标准价格',
      recentSalesCustomerPrice: '最近销售价格',
      minSalesCustomerPrice: '最低销售价格',
      maxSalesCustomerPrice: '最高销售价格',
      taxRatePlease: '请选择税率',
      outRequireQuantity: '委外需求数量',
      outOrderQuantity: '委外订单数量',
      inventoryQuantity: '出库数量',
      deliveryQuantitySum: '发货数量',
      inventoryQuantitySum: '出库数量',
      notDeliveryQuantitySum: '未发货数量',
      notInventoryQuantitySum: '未出库数量',
      inventoryInQuantitySum: '入库数量',
      notInventoryInQuantitySum: '未入库数量',
      returnProductAmount: '退产品金额',
      returnFreightAmount: '退运费金额',
      productBrandName: '产品品牌'
    },
    b2cOrder: {
      b2cOrder: 'B2C订单导入',
      beforeStep: '上一步',
      nextStep: '下一步',
      submitData: '提交数据',
      importData: '数据导入',
      dataMap: '数据映射',
      previewData: '数据预览',
      orderConfig: '订单设置',
      previewResult: '导入结果',
      rowNumber: '序号',
      companyName: '单位主体',
      customerCode: '客户编码',
      customerName: '客户名称',
      productCode: '产品编码',
      productName: '产品名称',
      productModel: '规格型号',
      productUnit: '计价单位',
      unitPriceTax: '单价',
      quantity: '数量',
      addressee: '收件人',
      addresseePhone: '收件电话',
      address: '地址',
      customerOrderCode: '第三方订单号',
      expressCompanyName: '承运商',
      expressNumber: '快递单号',
      auditStatus: '销售订单审核状态',
      deliveryStatus: '销售发货状态',
      collectionStatus: '销售回款状态',
      inventoryOutStatus: '销售发货出库状态',
      collected: '已收款',
      unCollected: '未收款',
      inventoryOut: '已出库',
      unInventoryOut: '未出库',
      financeAccount: '收款账户',
      warehouse: '仓库',
      submitDataTips: '确认提交数据',
      fileUploading: '文件加载中',
      collectionAccountEmpty: '收款账号不能为空',
      warehouseEmpty: '仓库不能为空',
      save2Draft: '保存为草稿',
      save2UnAudited: '提交',
      save2Audited: '提交并审核',
      orderCode: '订单编码',
      importDate: '业务日期',
      recordCount: '记录条数',
      orderStatus: '订单状态',
      operator: '经办人',
      queryKeyword: '订单编码',
      startDate: '业务日期起',
      endDate: '业务日期止',
      baseInfo: 'B2C订单',
      salesOrderInfo: '销售订单',
      salesDeliveryInfo: '销售发货',
      inventoryOutInfo: '出库单',
      financeReceivableInfo: '应收款',
      addB2cOrder: '导入B2C订单',
      editB2cOrder: '编辑B2C订单',
      viewB2cOrder: '查看B2C订单',
      deleteTips: '确认【删除】该B2C订单',
      cancelTips: '确认【作废】该B2C订单',
      save2DraftTips: '确认【保存为草稿】,该操作不会自动生成下级业务数据',
      save2UnAuditedTips: '确认【提交】,该操作不会自动生成下级业务数据',
      saveAndAuditedTips: '确认【提交并审核】,该操作将根据设置自动生成下级业务数据',
      geneDelivery: '生成销售发货单',
      geneReceivable: '生成应收款',
      geneInventoryOut: '生成出库单',
      geneCollection: '生成收款单',
      pleaseSelectSalesOrder: '请选择销售订单',
      pleaseSelectSalesDelivery: '请选择销售发货单',
      pleaseSelectReceivable: '请选择应收款',
      updateSalesOrderB2cDetailsFail: '更新b2c明细失败',
      deleteSalesOrderB2cDetailsFail: '删除b2c明细失败',
      updateSalesOrderB2cFail: '更新b2c订单失败',
      salesOrderContainDelivery: '部分销售订单已发货，请重新选择',
      businessOrderContainReceivable: '部分单据已存在应收款，请重新选择',
      receivableOrderEmpty: '未生成订单，无法生成应收款',
      receivableDeliveryEmpty: '未生成发货单， 无法成应收款',
      receivableContainCollection: '部分应收款已收款',
      cancelSuccess: '作废成功',
      geneSalesDeliverySuccess: '生成销售发货单成功',
      geneReceivableSuccess: '生成应收款成功',
      geneCollectionSuccess: '生成收款单成功',
      geneInventoryOutSuccess: '生成出库单成功',
      attentionTipsTitle: '*B2C订单导入注意事项*',
      attentionTips1: '1.建议使用官方Excel模板导入数据 ',
      attentionTips2: '2.上传EXCEL后，请准确填写标题行号和数据起始行号',
      attentionTips3: '3.请准确填写数据映射关系并检查数据预览是否相符',
      excelTitleRowNumber: '导入EXCEL标题行号',
      excelDataRowNumber: '导入EXCEL数据起始行号',
      sourceTitleName: '导入列标题',
      targetTitleName: '映射对象',
      productMap: '产品映射',
      autoGeneProductAlias: '是否自动生成产品别名',
      sysProduct: '系统产品',
      importDataError: '导入数据错误，请检查错误信息',
      productMapError: '映射产品错误，请检查错误信息',
      autoGeneProductAliasTips: 'b2c审核通过后，自动生成不存在产品的别名',
      autoGeneTips: '生成的销售订单中包含委外发货时，将不会按配置生成对应发货单、应收款、收款单、出库单',
      geneOutDelivery: '生成委外需求单',
      existOutDelivery: '是否包含委外发货',
      orderExistOutDeliveryGeneDeliveryError: '含委外发货订单不能进行该操作，请前往【销售管理-销售订单】进行发货操作',
      orderExistOutDeliveryPlease: '请选择包含委外发货的订单'
    },
    promotion: {
      activityName: '活动名称',
      pleaseActivityName: '请填写活动名称',
      pleaseActivityTime: '请填写活动时间',
      activityTime: '活动时间',
      isEnable: '是否启用',
      effectiveCustomer: '有效客户',
      allCustomer: '全部客户',
      theCustomSort: '指定客户分类',
      productWhitelist: '产品白名单',
      productBlacklist: '产品黑名单',
      allProduct: '全部产品',
      theProduct: '指定产品',
      productSort: '产品分类',
      promotionInstructions: '促销说明',
      effectiveCustomerTip: '有效客户请选择客户分类',
      productWhitelistTipOne: '产品白名单,请选择指定产品',
      productWhitelistTipTwo: '产品白名单,请选择产品分类',
      productBlacklistTipOne: '产品黑名单,请选择指定产品',
      productBlacklistTipTwo: '产品黑名单,请选择产品分类',
      promotionType: '促销类型',
      fullAmount: '满量折',
      repeatFullReduction: '重复满额减',
      addActivityPromotion: '添加活动促销',
      editActivityPromotion: '编辑活动促销',
      viewActivityPromotion: '查看活动促销',
      eachFull: '每满',
      yuan: '元',
      jian: '减',
      repeatFullReductionTip: '促销类型，重复满额减请填写金额',
      repeatFullReductionTips: '促销类型，请填写满减区间',
      promotionDiscountTipOne: '促销类型：区间范围左区间不能为空',
      promotionDiscountTipTwo: '促销类型：区间范围右区间不能为空',
      promotionDiscountTipThree: '促销类型：达到区间范围的满减金额（折扣）不为空',
      promotionDiscountTipFour: '促销类型：区间范围左区间不能大于右区间',
      promotionDiscountTipFive: '促销类型：区间范围左区间不能为空和0',
      promotionDiscountTipSix: '促销类型：区间范围不能为空或0',
      promotionDiscountTipSeven: '促销类型：区间范围请闭环添加,不要交叉',
      no: '无'
    },
    order: {
      queryKeyword: '编码、客户、联系人',
      detailsQueryKeyword: '编码、客户、产品信息',
      orderCode: '订单编码',
      orderCodeSelf: '自编订单号',
      orderDate: '签约日期',
      customer: '客户',
      customerName: '客户',
      contact: '联系人',
      customerManager: '业务员',
      confirmedAmount: '已确认',
      unConfirmedAmount: '未确认',
      deliveryAmount: '已发货金额',
      invoiceAmount: '已开票金额',
      deliveryDate: '交货日期',
      orderDateStart: '开始时间',
      orderDateEnd: '结束时间',
      paymentMethod: '付款方式',
      invoiceType: '开票类型',
      deliveryType: '交货方式',
      settlementMethod: '结算方式',
      deliveryAddr: '交货地址',
      currency: '货币',
      amountOutTax: '总额(不含税)',
      amountWithTax: '总额(含税)',
      amountReceived: '已收款金额',
      collectionBill: '收款票据',
      orderStatus: '状态',
      addOrder: '添加销售订单',
      editOrder: '编辑销售订单',
      viewOrder: '查看销售订单',
      addOrderTips: '确认【添加】该订单？',
      updateOrderTips: '确认【更新】该订单？',
      deleteOrderTips: '确认【删除】该订单？',
      revokeOrderTips: '确认【撤销】该订单？ 撤销该订单，订单下的【发货单变成关闭状态】',
      closeDoneOrderTips: '确认【完成】该订单？',
      closeStopOrderTips: '确认【终止】该订单？',
      closeCancelOrderTips: '确认【作废】该订单？',
      verifyOrderTips: '确认【审核】该订单？',
      recallOrderTips: '确认【撤回】该订单到草稿状态？',
      baseInfo: '基本信息',
      salesDeliveryCode: '发货单编号',
      salesReceiptPlanCode: '收款单编号',
      revokeOrderImpossibleTips: '该订单存在收款或发货记录不能撤销',
      financeCollectionInfo: '收款记录',
      financeReceivableDetails: '应收款详情',
      financeCollectionDetails: '收款单详情',
      startDate: '签约日期起',
      endDate: '签约日期止',
      orderSelector: '销售订单选择',
      orderDeliveryInfo: '发货单',
      orderReturnInfo: '退货信息',
      customerAddressSelector: '客户地址选择器',
      customerOrderCode: '客户订单号',
      salesOrderAmount: '销售订单金额',
      verifySelectedOrdersTips: '确认【审核】已选销售订单',
      orderInfo: '订单信息',
      orderDetails: '订单明细',
      lookMore: '查看更多',
      projectName: '项目名称',
      deliveryProcessRate: '发货进度',
      collectionProcessRate: '回款进度',
      inventoryProcessRate: '出库进度',
      expenseByCustomer: '其它费用',
      orderAmount: '订单总额',
      productAmountTax: '产品总额',
      discount: '折扣率（%）',
      amountAfterDiscount: '折后产品总额',
      freight: '运费',
      amountDetails: '其它金额',
      advanceDeposit: '预收定金',
      revokeOrderCheckTips: '撤销操作，将【删除与该销售订单关联的销售发货、应收款等业务数据】，请确认是否要撤销？',
      auditOrder: '订单审核',
      printTitle: '打印销售订单',
      freeProduct: '赠品',
      selectFreeProduct: '选择赠品',
      manualGeneDelivery: '手动生成发货单',
      manualGeneReceivable: '手动生成应收款',
      outDeliveryQuantityPlease: '请填写委外数量',
      outDeliveryQuantityGreatThen: '委外数量不能大于订单数量',
      outDeliveryAddressInfoPlease: '存在委外发货，请完整填写收货人、收货地址等详细信息',
      receiptAmountBalance: '应收款余额',
      customerTip: '更换客户需重新选择促销活动',
      customerCompanyTip: '所属公司与客户关联的所属公司不一致，是否将所属公司更换为客户关联所属公司',
      activityPromotion: '活动促销',
      promotionDiscount: '促销满减折扣',
      orderStopCheck: '销售订单终止确认',
      orderQuantity: '订单总数量',
      outDeliveryQuantity: '委外数量',
      inventoryDeliveredQuantity: '仓库已发数量',
      outDeliveredQuantity: '委外已发数量',
      orderProduct: '订单产品明细',
      orderReceivableInfo: '应收款明细',
      hasCollectionAmount: '已收款金额：',
      stopOrderTips: '确认【终止】该订单？确认操作后，订单将按已收款金额变更订单，操作后不可撤销，请谨慎操作',
      stockLessTips: '存在存库不足产品，是否【确认】继续提交订单',
      defaultAdvanceReceiptBalance: '默认预收款余额',
      customerCreditNotEnoughTips: '客户授信额度不足，不能提交订单。请联络客户支付相关款项或者提升授信额度后再来操作！',
      outsourceManualGeneDeliveryTips: '订单存在委外需求，订单审核通过后请手动生成发货单',
      manualGeneDeliveryTips: '您勾选了手动生成发货单，订单审核通过后请手动生成发货单，点【取消】可返回修改',
      isOutDeliveryOrder: '是否委外',
      receivableReturnAmount: '未收款退款',
      balanceAmount: '余额',
      multiAddressAdd: '添加多地址',
      multiAddress: '多地址查看',
      editProductAddress: '订单地址',
      multiAddressAddDelivery: '添加发货单',
      addressDeliveryMode: '发货方式',
      selectDeliveryProduct: '选择地址',
      deliveryProductPreview: '数据预览',
      deliveryResult: '发货结果',
      deliveryByAddress: '按地址',
      deliveryByProduct: '按产品',
      deliveryProductEmpty: '请选择发货产品',
      selectProduct: '选择产品',
      viewProductAddress: '产品地址',
      orderAdjust: '订单变更',
      deliveryDateLessThenOrderDate: '交货日期不能早于签约日期',
      muiltiAddressOrderDetails: '代发订单',
      addMultiAddressOrder: '添加代发订单',
      orderDeliveryProductInfo: '发货产品',
      updateWarehouse: '修改仓库',
      auditDate: '审核日期',
      parseAddress: '地址识别',
      parseAddressPlease: '请输入要识别的地址'
    },
    orderAdjust: {
      queryKeyword: '订单编码、客户',
      createTime: '变更时间',
      startDate: '变更时间起',
      endDate: '变更时间止',
      adjustVersion: '版本号',
      versionCreateAuto: '版本号自动生成',
      salesOrderCode: '销售订单编码',
      adjuster: '变更发起人',
      auditorName: '审核人',
      status: '审核状态',
      adjustReason: '变更原因',
      rejectReason: '驳回原因',
      oldDeliveryAddr: '收货地址（ 变更前）',
      newDeliveryAddr: '收货地址（变更后）',
      old: '变更前',
      new: '变更后',
      oldAmountInfo: '费用信息（变更前）',
      newAmountInfo: '费用信息（变更后）',
      addOrderAdjustTitle: '添加销售订单变更',
      editOrderAdjustTitle: '编辑销售订单变更',
      viewOrderAdjustTitle: '查看销售订单变更',
      salesOrderAdjusterPlease: '请选择变更人',
      salesOrderAdjustReasonPlease: '请输入变更原因',
      rejectReasonEmpty: '请输入驳回原因',
      adjustAuditTips: '确认【保存并审核】该变更单，确认后原销售订单将被修改',
      deleteAdjustAuditTips: '确认【删除】该变更单',
      adjustAuditPassTips: '确认审核【通过】该变更单，确认后原销售订单将被修改',
      adjustAuditRejectTips: '确认【驳回】该变更单，确认后原销售订单不会被修改，可重新发起变更',
      orderAdjustRecord: '变更记录',
      newQuantityLessThanDeliveriedQuantity: '变更后产品数量不能小于已发货数量',
      newReceivableAmount: '应收金额（变更后）',
      newDirectAdvanceReceiptAmount: '定向抵扣金额（变更后）',
      newReceivableAmountGreatThenCollectionAmount: '变更应收款金额不能大于已收款金额',
      receivableAmountGreatThenOrderAmountTips: '变更后应收款总额大于订单总额，请调整底部应收款金额',
      orderAmountGreatThenReceivableAmountTips: '变更后订单总额大于应收款总额, 确认变更后需手动添加应收款，确认继续变更?',
      autoAdjust: '自动调整',
      autoAdjustSalesOrderReceivableTips: '该订单应收款已发生变更，是否自动调整？',
      autoAdjustSalesDeliveryReceivabelTips: '该发货单应收款已发生变更，是否自动调整？',
      autoAdjustPurchaseOrderPayableTips: '该订单应付款已发生变更，是否自动调整？'
    },
    deliveryAdjust: {
      salesDeliveryCode: '发货单编码',
      editDeliveryAdjustTitle: '编辑发货单变更',
      viewDeliveryAdjustTitle: '查看发货单变更',
      addDeliveryAdjustTitle: '添加发货单变更'
    },
    salesDelivery: {
      queryKeyword: '编码、客户、联系人',
      detailsQueryKeyword: '编码、客户、产品信息',
      inventoryInfo: '出库信息',
      deliveryCode: '发货单编码',
      deliveryDate: '发货日期',
      customer: '客户',
      contact: '联系人',
      currency: '货币',
      deliveryType: '发货方式',
      settlementMethod: '结算方式',
      customerManager: '业务员',
      operator: '经办人',
      deliveryStatus: '发货单状态',
      inventoryInStatus: '入库单状态',
      InventoryOutOrder: '生成出库单',
      addSalesDeliveryTitle: '添加销售发货单',
      multiAddDeliveryTitle: '多地址发货',
      addresseeCompanyName: '收件人公司名称',
      addresseePostCode: '邮编',
      editSalesDeliveryTitle: '编辑销售发货单',
      viewSalesDeliveryTitle: '查看销售发货单',
      salesDeliveryBaseInfo: '基本信息',
      salesDeliveryProductInfo: '销售发货产品信息',
      deleteSalesDeliveryTips: '确认【删除】销售发货单？',
      revokeTips: '确认【撤销】该销售发货单, 撤销后将删除发货单下应收款',
      doneTips: '确认【完成】该销售发货单？',
      inventoryOutTips: '确认生成出库单？',
      inventoryOutDetails: '出库单详情',
      inventoryOutProductDetails: '出库单产品详情',
      startDate: '发货日期起',
      endDate: '发货日期止',
      taxRate: '税率',
      price: '单价',
      deliveryReturnInfo: '退货信息',
      deliveryReturnDetails: '退货单详情',
      deliveryReturnProductDetails: '退货单产品详情',
      deliveryProductSelector: '发货单产品选择器',
      inventoryOutCode: '出库单编码',
      inventoryOutDate: '出库日期',
      inventoryInCode: '入库单编码',
      inventoryInDate: '入库日期',
      warehouse: '仓库',
      warehouseOperator: '仓库经办人',
      deliveryAddr: '收货地址',
      deliveryOrder: '销售发货单',
      deliveryDetails: '销售发货明细',
      deliverySelector: '销售发货单选择',
      reportTitle: '打印发货单',
      reportReturnTitle: '打印退货单',
      selectAddress: '选择地址',
      verifySelectedSalesDeliveryTips: '确认【审核】已选待审核发货单',
      doneSelectedSalesDeliveryTips: '确认【完成】已选已审核发货单',
      autoAddOrderSuccess: '自动生成订单成功',
      autoAddOrderFail: '自动生成订单失败',
      addVerifyTips: '是否确认【保存并审核】发货单',
      cancelDeliveryTips: '是否确认【作废】发货单',
      batchInventory: '批量出库',
      inventoryOutStatus: '出库状态',
      plesaseSelectSalesDelivery: '请选择发货单',
      deliveryAdjust: '发货单变更',
      expressPlaceOrder: '快递下单',
      expressPrintOrder: '打印电子面单',
      expressPrintOrderAndDelivery: '打印电子面单 + 发货单',
      placeAllOrder: '全部下单',
      placeSelectedOrder: '勾选下单',
      printAllOrder: '全部打印',
      printSelectedOrder: '勾选打印',
      cencelExpressOrder: '取消下单',
      allInventoryOut: '全部出库',
      deliveryProductGroupBy: '发货产品',
      batchInventoryOut: '出库预览',
      selectInventoryProductEmpty: '请选择要出库的产品',
      defaultInventoryWarehouse: '默认出库仓库',
      defaultInventoryWarehouseTips: '发货单未配置仓库时，以默认仓库进行出库',
      confirmBatchInventoryOut: '确认出库',
      currentInventoryQuantitySum: '本次出库数量',
      confirmBatchInventoryOutTips: '确认已选产品全部出库',
      hasInventoryQuantity: '已出库数量',
      saleDeliveryQuantity: '发货单数量',
      defaultInventoryWarehousePlease: '请选择默认出库仓库',
      addExpressStatus: '已快递下单'
    },
    salesReturn: {
      queryKeyword: '编码、客户',
      detailsQueryKeyword: '编码、客户、产品信息',
      addSalesReturnTitle: '添加销售退货单',
      viewSalesReturnTitle: '查看销售退货单',
      editSalesReturnTitle: '编辑销售退货单',
      returnBaseInfo: '基本信息',
      returnOrderCode: '退货单编码',
      salesDelivery: '发货单',
      business: '关联业务',
      customer: '客户',
      returnDate: '退货日期',
      warehouseName: '仓库',
      currency: '货币',
      orderAmount: '订单金额',
      amountOutTax: '总额（不含税）',
      amountWithTax: '总额（含税）',
      warehouseManager: '仓管员',
      operator: '经办人',
      returnOrderStatus: '退货状态',
      returnReason: '退货原因',
      remark: '备注',
      startDate: '退货日期起',
      endDate: '退货日期止',
      pleaseChooseCustomerManager: '请选择经办人',
      pleaseChooseDelivery: '请选择发货单',
      verifyReturnOrderTips: '确认【审核】该退货单',
      revokeReturnOrderTips: '确认【撤销】该退货单',
      doneReturnOrderTips: '确认【完成】该退货单',
      deleteReturnOrderTips: '确认【删除】该退货单',
      inventoryOutDetails: '入库单详情',
      inventoryOutProductDetails: '入库单产品详情',
      returnInventoryInfo: '入库信息',
      inventoryInCode: '入库单编码',
      inventoryInDate: '入库日期',
      warehouse: '仓库',
      warehouseOperator: '仓库经办人',
      orderSelector: '销售退货单选择',
      autoAddOrderSuccess: '自动生成订单成功',
      autoAddOrderFail: '自动生成订单失败',
      Updateorderprocessrate: '重算全部订单的发货进度和收款进度',
      Updatedeliveryprocessrate: '重算全部发货单的发货进度和收款进度',
      Update: '更新',
      Recalculation: '重算',
      printTitle: '打印退货单',
      businessType: {
        saleOrder: '销售订单',
        saleDelivery: '销售发货单',
        outInventory: '出库单'
      },
      purchaseOrderSelect: '采购订单选择器',
      pleaseChooseSupplier: '请选择供应商',
      pleaseChooseCurrency: '请选择货币',
      inventoryInTips: '确认该退货单【仓库已收货】',
      inventoryOutTips: '确认该退货单【仓库已发货】',
      refundedTips: '确认该退货单【财务已退款】',
      returnInventoryInInfo: '入库信息',
      returnInventoryOutInfo: '出库信息',
      inventoryInProcessRate: '入库进度',
      returnOrder: '销售退货单',
      returnDetails: '销售退货明细',
      inventoryOutProcessRate: '出库进度',
      financeProcessRate: '退款进度',
      returnAmountCheckTips: '退换金额和产品总额不一致，是否确定按该金额退款？',
      productAmount: '产品总额'
    },
    salesView: {
      deliveryProductTitle: '销售订单已发货产品',
      confirmAmountTitle: '销售订单已收金额',
      unConfirmAmountTitle: '销售订单未确认金额',
      paidAmount: '实收金额',
      relatedBusiness: '关联业务',
      viewCollectionInfo: '查看收款详情'
    },
    expense: {
      queryKeyword: '往来单位、关联业务编码',
      occurrenceDate: '发生日期',
      expenseType: '费用类型',
      expenditureType: '支出类型',
      businessUnitId: '供应商',
      amount: '金额',
      operator: '经办人',
      paymentStatus: '支付状态',
      businessType: '关联业务类型',
      businessCode: '关联业务编码',
      business: '关联业务',
      startDate: '发生日期起',
      endDate: '发生日期止',
      currency: '货币',
      addExpense: '添加销售费用',
      editExpense: '编辑销售费用',
      viewExpense: '查看销售费用',
      deleteExpenseTips: '是否删除该费用单',
      paymentMethod: '支付方式',
      paymentDate: '支付日期',
      payee: '收款方'
    },
    price: {
      viewProductPrice: '查看产品销售价格',
      editProductPrice: '编辑产品销售价格',
      addProductPrice: '添加产品销售价格',
      deletePriceTips: '确认【删除】该价格',
      attentionTipsTitle: '*销售价格导入注意事项*',
      attentionTips1: '1.建议使用官方Excel模板导入数据 ',
      attentionTips2: '2.上传EXCEL后，请准确填写标题行号和数据起始行号',
      attentionTips3: '3.请准确填写数据映射关系并检查数据预览是否相符',
      priceImport: '销售价格导入',
      submitData: '提交数据',
      importData: '数据导入',
      dataMap: '数据映射',
      previewData: '数据预览',
      previewResult: '导入结果',
      excelFile: 'Excel文件',
      excelTitleRowNumber: '标题行号',
      excelDataRowNumber: '数据起始行号',
      sourceTitleName: '导入列标题',
      targetTitleName: '映射对象',
      currencyType: '币种',
      taxRate: '税率',
      nextStep: '下一步',
      lastStep: '上一步',
      fileError: '文件类型错误或者未按模板格式填写',
      fileUploading: '文件加载中',
      ignoreEmptyPrice: '忽略价格为空或为0的数据',
      productCodeMapColumnCanNotEmpty: '产品编码映射列不能为空',
      noDataNeedImport: '没有需要导入的数据',
      serialNumber: '序号',
      importStatus: '导入状态',
      importErrorReason: '原因',
      success: '成功',
      fail: '失败',
      executeResultInfo: '共{0}条记录，成功：{1}条，跳过：{2}条',
      regularPrice: '正价',
      specialPrice: '特价',
      costPrice: '裸价',
      priceLevel: '价格标签',
      importPriceMaxCountTips: '导入价格最大处理条数为1000，如有超出请分批处理'
    },
    deliveryView: {
      startDate: '签约日期起',
      endDate: '签约日期止',
      queryKeyword: '编码、客户、联系人',
      deliveredAmount: '已发货金额',
      receiptAmount: '回款金额'
    },
    config: {
      title: '销售参数设置',
      fiscalYear: '财年设置',
      indexTitle: '销售指标设置',
      orderAmount: '订单金额',
      orderReceiptAmount: '订单回款',
      signedOrderQuantity: '签约订单数量',
      addedCustomerQuantity: '新增客户数量',
      followupTimes: '跟进次数',
      followUpCustomerTimes: '跟进客户数',
      commonSalesConfig: '通用参数',
      autoGeneOrderEnable: '直接添加的销售发货单保存时同步生成已审核销售订单',
      updateOrderProcessRate: '重新计算全部订单发货进度和回款进度',
      updateDeliveryProcessRate: '重新计算全部发货单出库进度和回款进度',
      update: '更新',
      recalculation: '重新计算',
      orderAutoFillAddressEnable: '销售订单自动填充收货地址',
      deliveryAutoFillAddressEnable: '销售发货自动填充收货地址',
      enableSalesPriceAudit: '是否启用价格审核',
      salesOrderAuditModel: '销售订单审核模式',
      freeFlowModel: '自由流程模式',
      orderAuditModel: '审核模式',
      auditProcess: '审核流程',
      businessType: '业务类型',
      salesQuotation: '销售报价',
      salesOrder: '销售订单',
      salesShipment: '销售发货',
      salesReturns: '销售退货',
      sort: '序号',
      auditConfigNodeName: '审核节点名称',
      triggerCondition: '触发条件',
      noneSatisfied: '无条件触发',
      allSatisfied: '全部满足',
      oneSatisfied: '任意一条满足',
      belowSalesPrice: '产品单价低于',
      maxCustomerReceivableBalance: '客户应收款余额大于',
      maxOrderAmount: '订单总额大于',
      up: '上移',
      down: '下移',
      processor: '处理人',
      addAuditConfig: '添加审核流程',
      editAuditConfig: '编辑审核流程',
      priceType: '价格类型',
      salesAuditConfig: '审核设置',
      orderCheckStockEnable: '创建销售订单校验库存',
      deliveryCheckStockEnable: '创建销售发货单校验库存',
      pleaseSelectOne: '请配置触发条件',
      salesPriceScheme: '销售调价方案',
      salesPriceSchemeName: '方案名称',
      targetPriceType: '价格类型',
      basePriceType: '调价依据',
      equal: '等于',
      calcType: '关系',
      calcValue: '数值',
      percentage: '%',
      value: '数值',
      addSalesPriceScheme: '添加调价方案',
      editSalesPriceScheme: '编辑调价方案',
      viewSalesPriceScheme: '查看调价方案',
      adjustAllSalesPrice: '全部调价',
      adjustSalesPriceBatch: '批量调价',
      allProduct: '全部产品',
      selectedProduct: '已选产品',
      pleaseSelectProduct: '请选择产品',
      adjustPriceConfigNotContent: '请正确填写计算规则',
      adjustPriceSubmitTips: '是否【确定】批量调整产品价格，系统将按表达式规则自动计算价格',
      accountStatementConfig: '销售对账单设置',
      opportunityQuotationNotify: '销售机会报价通知',
      opportunityBidNotify: '销售机会投标通知',
      accountStatementAutoGeneDelayDays: '自动生成对账单延迟天数',
      manualReviewAccountStatement: '自动生成对账单是否手动复核',
      accountStatementAutoConfirmDate: '对账单自动确认天数',
      nextConfirmDateTips: '未确认的对账单将在下次生成账单时自动确认',
      salesStockIncludePurchase: '可用库存包含在途',
      orderCompleteConfig: '订单完成设置',
      autoCompleteTipPrefix: '发货和回款完成时',
      autoCompleteTipSubfix: '天之后自动完成销售订单',
      autoCompleteTip: '自动完成满足条件的销售订单，当设置为0时，表示不自动完成',
      priceLevelEnable: '是否开启价格标签（正价、特价、裸价）',
      orderCustomerManagerChangeEnable: '订单业务员是否可修改',
      salesPriceAutoFillRecent: '销售订单价格是否默认获取最近成交价格'
    },
    goal: {
      fiscalYear: '财年',
      fiscalYearSettings: '财年设置',
      fiscalYearBegin: '财年开始月',
      orderAmount: '订单金额',
      orderReceiptAmount: '订单回款',
      signedOrderQuantity: '签约订单数量',
      addedCustomerQuantity: '新增客户数量',
      followUpTimes: '跟进次数',
      followUpCustomerTimes: '跟进客户次数',
      goalSetting: '目标值设置',
      person: '个人目标',
      organization: '部门目标',
      chooseObject: '选择对象',
      checkedObject: '已选择对象',
      fullYear: '全年',
      userName: '姓名',
      orgName: '部门',
      firstHalfYear: '上半年',
      latterHalfYear: '下半年',
      firstQuarter: '一季度',
      secondQuarter: '二季度',
      thirdQuarter: '三季度',
      fourthQuarter: '四季度',
      addTitle: '设置销售目标',
      viewTitle: '查看销售目标',
      goalIndexTypeTips: '请选择销售指标类型',
      businessInfosTips: '请选择对象',
      fiscalYearBeginTips: '请选择财年开始月份',
      fiscalYearTips: '请选择财年',
      deleteFiscalYearTips: '是否确定删除该财年?',
      addFiscalYearTitle: '添加财年',
      editFiscalYearTitle: '编辑财年',
      fiscalYearBeginError: '财年起始月年份需等于财年'
    },
    dynamicSalesRate: {
      warehouseName: '仓库',
      productCategoryCount: '库存商品总数',
      monthOrderProductCategoryCount: '本月动销品种',
      yearOrderProductCategoryCount: '本年动销品种',
      monthDynamicSalesRate: '本月动销率',
      yearDynamicSalesRate: '本年动销率',
      allWarehouse: '所有仓库'
    },
    priceType: {
      priceTypeName: '价格名称',
      relatedCustomer: '关联客户',
      relatedCustomerSort: '关联客户分类',
      relatedCustomerTag: '关联客户标签',
      addRelatedCustomer: '添加关联客户',
      addRelatedCustomerSort: '添加关联客户分类',
      addRelatedCustomerTag: '添加关联客户标签',
      addTitle: '添加价格类型',
      editTitle: '编辑价格类型',
      viewTitle: '查看价格类型',
      customer: '客户',
      customerSort: '客户分类',
      customerTag: '客户标签',
      priceTypeNameTips: '价格类型名称必填',
      relatedTips: '至少关联一种业务',
      deleteTips: '是否删除这条价格类型',
      adjustPriceConfig: '价格自动计算',
      calcRules: '计算规则',
      expressNotContent: '请正确填写计算规则'
    },
    service: {
      pleaseChooseStatus: '请选择服务状态',
      orderMessage: '订单信息',
      addService: '添加服务订单',
      editService: '编辑服务订单',
      viewService: '查看服务订单',
      isDelete: '是否删除该服务订单？',
      assignedAgain: '该订单存已经存在处理人，是否重新指派处理人？',
      chooseAssigned: '请选择要指派的处理人',
      assignedSuccess: '指派成功',
      auditSucess: '审核成功',
      startSuccess: '该服务订单已开始服务',
      stopSuccess: '暂停成功',
      cancelSuccess: '取消成功',
      activationSuccess: '激活成功',
      finishSuccess: '订单已完成',
      editor: '请输入内容',
      uploading: '图片插入中，请稍后',
      specifyTime: '指定时间',
      unSpecifyTime: '不指定时间',
      timeInterval: '时间区间',
      confirm: '确认',
      description: '服务内容',
      addServiceItem: '添加服务项目',
      common: {
        assigned: '指派',
        start: '开始',
        stop: '暂停',
        cancel: '取消',
        finish: '完成',
        activation: '激活',
        saveDraft: '保存草稿',
        submit: '提交审核',
        audit: '审核'
      },
      orderCode: '订单编码',
      serviceItem: '服务项目',
      orderDate: '下单日期',
      customerName: '客户',
      contactName: '联系人',
      currency: '货币',
      amountWithTax: '金额',
      settlementMethod: '结算方式',
      serviceContent: '服务内容',
      timeType: '时间类型',
      serviceStatus: '服务状态',
      startTime: '开始时间',
      endTime: '结束时间',
      auditStatus: '审核状态',
      auditorName: '审核人',
      auditDate: '审核时间',
      currentHandlerName: '当前处理人',
      finishTime: '完成时间',
      serviceRemark: '备注',
      status: {
        draft: '草稿',
        unaudit: '待审核',
        audited: '已审核',
        notStart: '未开始',
        ongoing: '进行中',
        finish: '已完成',
        stoped: '已暂停',
        cancel: '已取消'
      },
      rules: {
        pleaseChooseServiceItem: '请选择服务项目',
        pleaseChooseHandler: '请选择处理人',
        pleaseChooseCustomer: '请选择客户',
        pleaseChooseContact: '请选择联系人',
        pleaseChooseTimeType: '请选择一种时间类型',
        pleaseChooseStartTime: '请选择开始时间',
        pleaseChooseEndTime: '请选择结束时间',
        pleaseChooseCurrency: '请选择货币',
        pleaseChooseAmount: '请输入含税价金额',
        pleaseChooseorderDate: '请选择下单日期',
        pleaseChooseMethod: '请选择付款方式',
        pleaseChooseContent: '请输入服务内容'
      }
    },
    basicData: {
      period: {
        settlementPeriodCode: '编码',
        nameZh: '名称（中文）',
        nameEn: '英文',
        nameZhTw: '名称（繁体）',
        periodType: '期限类别',
        periodData: '结算期限',
        settlementDate: '每月',
        settlementScope: '结算',
        reconciliationDate: '对账日',
        isAutoDelivery: '自动生成销售发货单',
        receiptPlanAuto: '按回款进度',
        orderAuditAuto: '按销售订单审核',
        autoDelivery: '自动生成销售发货单',
        standardPeriod: '标准期限',
        monthPeriod: '月结期限',
        day: '天',
        monthDay: '日, 结算',
        reconciliationDay: '日',
        business: '天前的待结算业务',
        sort: '排序',
        isAutoDeductAdvanceReceipt: '预收款自动扣减',
        useCredit: '是否占用授信额度设置',
        installmentEnable: '是否分期付款',
        installmentName: '分期阶段',
        rate: '比例（%）',
        installmentRateOver: '分期比例超过100，请重新配置',
        installmentRateError: '分期比例设置错误',
        installmentRateConfig: '分期设置',
        autoDeliveryDay: '销售发货单自动生成时间',
        autoDeliveryDayPrefix: '交货日期前',
        autoDeliveryDaySubfix: '天',
        autoDeliveryDayTips: '-1 表示直接生成'
      },
      addPeriod: '添加结算期限',
      editPeriod: '编辑结算期限',
      pleaseInputName: '请输入结算名称',
      pleaseInputCorrectValue: '请输入0-100范围内的值'
    },
    inventoryLock: {
      queryKeyword: '编码、申请人、客户、',
      detailsQueryKeyword: '编码、申请人、客户、产品信息',
      inventoryLockCode: '申请编码',
      applicantId: '申请人',
      applyDate: '申请日期',
      effectType: '锁定类型',
      customer: '客户',
      receiptAmount: '收款金额',
      lockDate: '锁定日期',
      unlockDate: '解锁日期',
      lockReason: '锁定原因',
      lockStatus: '状态',
      direct: '直接生效',
      assignDate: '指定日期生效',
      deliveryOn: '款到生效',
      draft: '草稿',
      pending: '待审核',
      audited: '已审核',
      effected: '已生效',
      unlocked: '已解除',
      lockQuantity: '锁定数量',
      unlockQuantity: '解锁数量',
      addTitle: '添加锁定库存',
      editTitle: '编辑锁定库存',
      viewTitle: '查看锁定库存',
      unlockTitle: '解除锁定库存',
      unlockAll: '全部解除',
      unlock: '解除锁定',
      collect: '收款',
      effect: '确认生效',
      transterSalesOrder: '转销售订单',
      applicatIdTips: '申请人不能为空',
      applyDateTips: '申请日期不能为空',
      effectTypeTips: '锁定类型不能为空',
      lockReasonTips: '锁定原因不能为空',
      auditTips: '是否审核该锁定库存记录?',
      deleteTips: '是否删除该锁定库存记录?',
      unlockAllTips: '是否解除全部该锁定库存记录',
      revokeTips: '是否撤销该锁定库存记录',
      effectTips: '是否确认该锁定库存记录生效',
      lockDateOrUnlockDateTips: '指定日期锁定需要选择锁定日期和解锁日期',
      enabledStockLtLockQuantity: '可用库存小于锁定库存',
      unlockDateLtlockDate: '解除日期不能小于等于锁定日期',
      customerTips: '款到锁定需要选择客户',
      receiptAmountTips: '款到锁定需要输入金额',
      inventoryLockOrder: '锁定库存单',
      inventoryLockDetails: '锁定库存明细',
      locakWarehouse: '锁定仓库',
      lockQuantityLeft: '剩余锁定'
    },
    statement: {
      orderType: '订单类型',
      orderCode: '单据编号',
      companyName: '所属公司',
      createTime: '下单时间',
      orderPersonName: '开单人/下单人',
      orderDate: '单据日期',
      deliveryDate: '交货日期',
      customerName: '客户名称',
      orderRemark: '备注',
      receiptorName: '收件人',
      receiptorPhone: '收件人电话',
      receiptorAddress: '收件地址',
      productRemark: '说明',
      productName: '产品名称/商品名称',
      productModel: '规格型号',
      productUnit: '单位',
      quantity: '订单数量',
      returnQuantity: '已退货数量',
      aboutReturnQuantity: '应退货数量',
      unitPriceTax: '单价(含税)',
      productSubtotal: '金额(含税)',
      freight: '运费',
      productAmountAfterDiscount: '折后产品总额',
      orderAmount: '订单总额',
      returnAmount: '已退款金额',
      aboutReturnAmount: '应退款金额',
      collectionStatus: '收款状态',
      collectionAmount: '已收金额',
      collectionAccount: '收款账户',
      collectionLeftAmount: '欠款金额',
      salesOrderType: '销售订单',
      mallOrderType: '商城订单',
      costUnitPrice: '成本单价(含税)',
      costSubtotal: '成本金额(含税)',
      costAmount: '成本总额',
      profitAmount: '利润',
      profitRate: '利润率(%)',
      aboutOutQuantity: '预计委外数量',
      realOutQuantity: '实际委外数量',
      orderViewDetails: '订单明细',
      byCustomer: '客户',
      byCustomerManager: '开单员',
      byBusinessManager: '业务员',
      customerManagerName: '开单员',
      businessManagerName: '业务员',
      orderCount: '订单数量',
      productAmountSum: '产品总额',
      freightSum: '运费总额',
      orderAmountSum: '订单总额',
      aboutReturnAmountSum: '应退总额',
      returnAmountSum: '已退总额',
      viewSummaryDetails: '明细',
      realOrderAmountSum: '实际成交总额'
    },
    reconciliation: {
      status: '对账状态',
      queryKeyword: '客户名称',
      customerName: '客户名称',
      accountStatementPeriod: '账单周期',
      accountStatementAmount: '账单金额',
      confirmTime: '确认时间',
      review: '复核',
      reGene: '重新生成',
      revoke: '撤销',
      toBeReview: '待复核',
      toBeConfirmed: '待确认',
      confirmedByCustomer: '已确认（客户确认）',
      confirmedAuto: '已确认（自动确认）',
      reviewTips: '确认对账单【已复核】，确认后将发送客户确认',
      reGeneTips: '是否重新生成对账单',
      businessDetails: '业务明细',
      salesOrder: '销售订单',
      salesOrderAdjust: '销售变更单',
      salesReturn: '销售退货单',
      mallOrder: '商城订单',
      mallReturn: '商城退货单',
      collection: '收款单',
      payment: '付款单',
      advanceReceipt: '预收款单',
      accountStatementDetails: '账单业务明细',
      businessType: '业务类型',
      businessDate: '业务日期',
      businessCode: '业务编码',
      transactionAmount: '发生金额',
      advanceReceiptBalance: '预收款余额',
      lastBillAmount: '上期账单金额',
      currentOrderAmount: '本期订单金额',
      currentReturnOrderAmount: '本期退货金额',
      currentCollectionAmount: '本期收款金额',
      currentPaymentAmount: '本期退款金额',
      currentBillAmount: '本期账单金额',
      viewAccountStatementDetails: '查看账单',
      viewAccountStatement: '查看账单业务明细',
      depositBalance: '保证金余额',
      depositCollection: '保证金收款',
      depositPayment: '保证金付款',
      mallRecharge: '商城充值',
      confirm: '确认',
      confirmTips: '是否确认本期账单,确认后将不能更改',
      revokeTips: '是否撤销对账单重新复核',
      reconciliationMail: '账单接收邮箱',
      aboutConfirmTime: '自动确认时间',
      isQueryZero: '是否查询为0的账单',
      reviewBatch: '批量复核',
      confirmBatch: '批量确认',
      batchReviewTips: '确认对账单【已复核】，确认后将发送客户确认',
      batchConfirmTips: '是否批量确认本期账单,确认后将不能更改',
      selectStatement: '已选账单',
      queryRange: '查询范围',
      pleaseSelectStatement: '请选择账单'
    },
    opportunity: {
      queryKeywords: '商机编码 客户 联系人',
      opportunityCode: '商机编码',
      opportunityName: '商机名称',
      customerName: '客户',
      opportunitySource: '商机来源',
      industry: '所属行业',
      operator: '经办人',
      contactName: '联系人',
      contactPosition: '联系人职位',
      contactPhone: '联系人手机',
      contactMobile: '联系人电话',
      contactWechat: '联系人微信',
      contactEmail: '联系人邮箱',
      decisionMakerName: '关键决策人',
      projectBudget: '预算总额',
      projectRequireQuantity: '预计数量',
      deliveryDate: '项目交期',
      invoiceRequire: '开票要求',
      deliveryType: '交货方式',
      deliveryAddr: '交货地址',
      opportunityStatus: '状态',
      requireDesc: '需求描述',
      nonInvoicing: '不开票',
      smallScale: '小规模',
      generalTaxpayer: '一般纳税人',
      centralizedDelivery: '集中发货',
      onePieceDelivery: '一件代发',
      otherDelivery: '其它',
      created: '已创建',
      followingUp: '跟进中',
      toSalesOrder: '已转销售订单',
      completed: '已完成',
      addOpportunity: '添加销售机会',
      editOpportunity: '编辑销售机会',
      viewOpportunity: '查看销售机会',
      addFollowUp: '跟进',
      addSalesOrder: '转订单',
      opportunityNamePlease: '请输入商机名称',
      customerPlease: '请选择客户',
      operatorPlease: '请选择经办人',
      projectBudgetPlease: '请填写预算总额',
      budgetUnitPricePlease: '请填写预算单价',
      biddingDatePlease: '请填写投标日期',
      projectRequireQuantityPlease: '请填写预计数量',
      opportunityInfo: '基本信息',
      followUp: '客户跟进',
      deleteOpportunityTips: '确认删除该销售机会？',
      contactPlease: '请选择联系人',
      isBidding: '是否招投标',
      biddingDate: '招投标日期',
      budgetUnitPrice: '预算单价',
      latestQuotationDate: '最晚报价日期',
      budgetOrUnitPricePlease: '请填写预算单价/预算总额'
    },
    profit: {
      salesOrder: '销售订单',
      mallOrder: '商城订单',
      mallGiftOrder: '礼盒订单',
      profitForOrder: '订单毛利',
      profitForProduct: '产品毛利',
      startDate: '订单日期起',
      endDate: '订单日期止',
      orderType: '订单类型',
      businessCode: '订单编号',
      createTime: '下单时间',
      operatorName: '开单人/下单人',
      orderDate: '单据日期',
      productSubtotal: '产品销售总额',
      orderAmount: '订单金额',
      aboutReturnAmount: '退款金额',
      aboutReturnQuantity: '退货数量',
      quantity: '订单数量',
      costSubtotal: '成本总额',
      freight: '运费',
      productCost: '产品成本总额',
      subtotal: '合计',
      profit: '毛利',
      profitRate: '毛利率',
      orderQueryKeywords: '订单编号、客户',
      productQueryKeywords: '订单编号、客户、产品'
    }
  },
  finance: {
    adjust: '批量确认',
    pleaseChooseData: '请选择要确认的数据',
    amountNeedThanZero: '金额必须大于0',
    confirmAdjustText: '是否确认',
    startDate: '开始日期',
    endDate: '结束日期',
    dateTo: '至',
    accountName: '账户名称',
    accountType: '账户类型',
    accountUserName: '账户户名',
    accountNumber: '账号',
    accountAddress: '开户行',
    currencyType: '币种',
    isEnable: '是否启用',
    remark: '备注',
    accountInfo: '账户信息',
    editFinance: '编辑资金账户',
    addFinance: '添加资金账户',
    viewFinance: '查看资金账户',
    pleaseInputAccountName: '请输入账户名称',
    pleaseInputAccountUserName: '请输入账户户名',
    pleaseInputAccountNumber: '请输入账号',
    pleaseChooseFinanceAccount: '请选择要删除的资金账户',
    pleaseInputAccountAddress: '请输入开户行地址',
    depositCode: '存取款编码',
    depositDate: '日期',
    accountOutId: '转出账户',
    currencyOutId: '转出币种',
    amountOut: '转出金额',
    accountInId: '转入账户',
    currencyInId: '转入币种',
    amountIn: '转入金额',
    currencyCommissionId: '手续费币种',
    amountCommission: '手续费金额',
    depositAbstract: '摘要',
    operatorId: '经办人',
    status: '状态',
    beginBalance: '期初余额',
    currentBalance: '当前余额',
    pleaseInputBeginBalance: '请输入期初余额',
    pleaseInputCurrentBalance: '请输入当前余额',
    purcheseOrder: '采购订单',
    saleAccountBook: '销售回款',
    purchaseReceipt: '采购收货',
    payPlan: '付款计划',
    saleReturn: '销售退货',
    payRequest: '付款申请',
    reimbursement: '费用报销',
    rejectMallRechargeOfflineTips: '是否驳回该收款单',
    financeStutas: {
      draftStutas: '草稿',
      toBeConfirmed: '待确认',
      confirmed: '已确认',
      deserted: '已作废',
      all: '全部'
    },
    common: {
      startYearMonth: '开始年月',
      endYearMonth: '结束年月',
      yearMonth: '年月',
      accountBookSummary: '收入',
      payBookSummary: '支出',
      accountBookAmount: '收入金额',
      payBookAmount: '支出金额',
      accountBookDetails: '收入明细',
      payBookDetails: '支出明细',
      financeTooltip: {
        financeSummary: {
          source: '数据来源：财务管理-销售回款、采购付款',
          rule: '取数规则：已确认的销售回款，已确认的采购付款',
          dimension: '统计维度：按月统计收入、支出'
        }
      },
      audit: '审核',
      revoke: '撤销',
      invoiceConfigSelector: '开票信息选择'
    },
    businessType: {
      purchasePayment: '采购付款',
      salesReceipt: '销售回款',
      salesReturnRefund: '销售退货退款',
      purchaseReturnRefund: '采购退货退款',
      dailyReceipt: '日常收款',
      dailyPayment: '日常付款',
      mallOrderCollection: '商城订单',
      mallOrderRefund: '商城退货/退款单',
      recharge: '商城充值',
      otherCollection: '其他收款',
      otherPayment: '其他付款',
      salesInventoryLock: '销售锁定库存',
      mallOrderMonthPay: '挂账月结',
      depositCollection: '保证金收款',
      depositPay: '保证金付款',
      depositSlip: '存款单',
      withdrawSlip: '取款单',
      advanceReceipt: '预收款',
      advancePayment: '预付款',
      distributionOrder: '代发订单',
      addAccount: '添加资金账户',
      adjustAccountBalance: '账户余额调整',
      purchaseOutReturn: '委外退款',
      mallMemberUpgrade: '会员升级支付',
      mallOrderFreight: '商城订单运费'
    },
    financeOperate: {
      submit: '提交',
      confirm: '确认',
      submitAndReview: '保存并确认',
      revoke: '撤销',
      invalid: '作废',
      print: '打印',
      view: '查看',
      saveAsDraft: '保存为草稿',
      return: '返回',
      reject: '驳回'
    },
    paymentDetails: {
      businessCode: '单据编码',
      detailDate: '日期',
      businessUnitName: '往来单位',
      incomeAmount: '收入金额',
      payAmount: '支出金额',
      balanceAmount: '账户余额',
      operatorName: '经办人',
      startDate: '开始时间',
      endDate: '结束时间',
      details: '查看明细',
      businessType: '单据类型'
    },
    editDeposit: '编辑存取款',
    addDeposit: '添加存取款',
    viewDeposit: '查看存取款',
    editCollectBook: '编辑销售回款',
    addCollectBook: '添加销售回款',
    viewCollectBook: '查看销售回款',
    collectBookInfo: '基本信息',
    editPayBook: '编辑付款单',
    addPayBook: '添加付款单',
    viewPayBook: '查看付款单',
    payBookInfo: '付款单信息',
    confirmText: '暂未保存，是否取消？',
    confirmInvalidText: '是否确认作废？',
    confirmRevokeText: '是否确认撤销？',
    confirmAuditText: '是否确认审核？',
    confirmSaveAsDraftText: '是否确认保存为草稿？',
    confirmSubmitAndReviewText: '是否提交并确认？',
    confirmSubmitText: '是否确认提交？',
    confirmDeleteText: '是否确认删除？',
    pleaseChoosedOperatorId: '请选择经办人',
    pleaseChooseStatus: '请选择状态',
    pleaseInputAmountCommission: '请正确输入手续费',
    pleaseChooseCurrencyCommissionId: '请选择手续费币种',
    pleaseInputAmountIn: '请输入正确的转入金额',
    pleaseChooseCurrencyInId: '请选择转入币种',
    pleaseChooseAccountInId: '请选择转入账户',
    pleaseInputAmountOut: '请正确输入金额',
    pleaseChooseCurrencyOutId: '请选择转出币种',
    pleaseChooseAccountOutId: '请选择转出账户',
    pleaseChooseDepositDate: '请选择日期',
    receivableInfo: '关联应收款',
    financeCollectBookInfo: {
      businessCode: '单据编码',
      company: '所属公司',
      businessDate: '收款日期',
      operatorId: '经办人',
      businessUnitId: '客户ID',
      businessType: '业务类型',
      businessUnitName: '客户名称',
      businessUnitAccount: '客户账号',
      accountId: '资金账户',
      accountNumber: '收款账号',
      currencyId: '币种',
      creditableAmount: '可用预收款',
      receivableAmount: '应收金额',
      amountSum: '实收金额',
      amount: '本次收款',
      mallAmount: '商城订单金额',
      amountAdvance: '抵扣预收款',
      financeAbstract: '摘要',
      status: '状态',
      pleaseChooseBusinessDate: '请选择收款日期',
      pleaseChooseBusinessUnitName: '请选择往来对象',
      pleaseInputBusinessUnitAccount: '请输入账号',
      pleaseChooseAccountId: '请选择资金账户',
      pleaseChooseCurrencyId: '请选择币种',
      pleaseInputAmount: '请输入本次收款金额',
      pleaseChooseOperatorId: '请选择经办人',
      pleaseChooseReceivable: '请选择应收款',
      collectionAccount: '收款账户',
      pleaseChoosePayable: '请选择应付款',
      collectionDateStart: '收款日期起',
      collectionDateEnd: '收款日期止',
      allReceipt: '全部收款',
      salesReceipt: '销售回款',
      dailyReceipt: '日常收款',
      otherReceipt: '收款金额',
      allPayment: '全部付款',
      purchasePayment: '采购付款',
      dailyPayment: '日常付款',
      otherPayment: '其它付款',
      editCollectBook: '编辑收款单',
      addCollectBook: '添加收款单',
      viewCollectBook: '查看收款单',
      collectionTarget: '收款对象',
      pleaseChooesCollectionTarget: '请选择收款对象',
      mallOrderReceipt: '商城订单收款',
      mallOrderCode: '商城订单号',
      mallOrderRefund: '商城退款',
      mallRecharge: '商城充值',
      mallRechargeReceipt: '商城充值收款',
      defaultAdvanceReceiptGreatThenBalance: '抵扣预收款不能大于可用预收款',
      receivableItemGreatThenBalance: '列表【收款总额】不能大于应收款余额',
      directAdvanceReceiptTypeEmpty: '定向抵扣预收款类型未选择',
      receivableSumGreatThenColelctionSum: '列表【收款总额】不能大于实收金额',
      directAdvanceReceiptType: '定向预收款类型',
      directAdvanceReceiptAmount: '定向抵扣金额',
      otherAmount: '收款金额',
      amountNow: '收款总额',
      salesReturnRefund: '销售退款',
      directAdvancePayableAmount: '转预付款金额',
      purchaseReturn: '采购退款',
      directAdvanceReceiptPay: '定向抵扣金额',
      mallPayType: '商城支付方式',
      hasReturnFromWx: '已从微信商户后台退款',
      otherCollection: '其它收款',
      commissionExtract: '佣金提现',
      rebateExtract: '返点提现',
      greatThenDefaultAdvanceReceipt: '可用预收款余额不足',
      toAdvancePayment: '转预付款',
      toAdvanceReceiptAmount: '转预收款(含定向)',
      returnAdvanceReceiptAmount: '退预收款(含定向)',
      returnAmountGreatThen: '实收金额大于列表应退总额，请调整本次收款金额',
      withRefundAmount: '含退款金额：',
      refundPayableList: '退款明细',
      refundAmountGreatThen: '退款明细存在退款金额大于应退款余额',
      otherExpense: '其它费用',
      discountAmount: '优惠金额',
      toAdvanceReceipt: '转预收款金额',
      collectAmountSum: '实收总额',
      isMultiAccount: '多账户',
      collectionAccountAmount: '收款账户金额',
      collectToAdvancePayment: '收款转预付款',
      refundToAdvancePayment: '退款转预付款',
      collectionAmountSumLessThenZero: '实收总额不能为空'
    },
    financePayBookInfo: {
      businessCode: '付款单编码',
      company: '所属公司',
      businessDate: '付款日期',
      operatorId: '经办人',
      businessUnitId: '单位ID',
      businessUnitName: '往来对象',
      businessUnitType: '单位类型',
      businessUnitAccount: '往来对象账号',
      accountId: '资金账户',
      accountNumber: '付款账户',
      currencyId: '币种',
      creditableAmount: '可用预付款',
      receivableAmount: '应付金额',
      amountSum: '实付金额',
      amount: '本次付款',
      amountAdvance: '抵扣预付款',
      financeAbstract: '摘要',
      status: '状态',
      pleaseChooseBusinessDate: '请选择付款日期',
      pleaseChooseBusinessUnitName: '请选择往来对象',
      pleaseInputBusinessUnitAccount: '请输入供应商账号',
      pleaseChooseAccountId: '请选择资金账户',
      pleaseChooseCurrencyId: '请选择币种',
      pleaseInputAmount: '请输入本次付款金额',
      pleaseChooseOperatorId: '请选择经办人',
      paymentAccount: '付款账户',
      supplier: '供应商',
      businessUnitAccountBank: '开户银行',
      businessUnitAccountName: '账户户名',
      expenditureNotEmpty: '支出明细不能为空',
      incomeNotEmpty: '收入明细不能为空',
      paymentTarget: '付款对象',
      pleaseChooesPaymentTarget: '请选择付款对象',
      mallRefundCode: '商城退款单号',
      mallRefundAmount: '退款金额',
      mallRechargeCode: '充值订单号',
      mallRechargeAmount: '充值金额',
      directAdvanceReceiptReturn: '定向抵扣退款',
      otherAmount: '本次付款',
      payAmountSum: '付款总额',
      returnAmountGreatThen: '退款金额大于列表应退总额，请调整本次付款金额',
      payableAmountSumGreatThen: '实付金额大于列表付款总额之和，请调整本次付款金额',
      payableEmpty: '请输入付款金额',
      toAdvancePayment: '转预付款',
      paymentAccountAmount: '付款账户金额',
      payToAdvanceReceipt: '付款转预收款',
      refundToAdvanceReceipt: '退预收款（含定向）',
      paymentAmountSumLessThenZero: '实付总额不能为空'
    },
    financeReceivable: {
      detail: '明细',
      summary: '汇总',
      receivable: '收款',
      status: {
        completed: '已完成',
        receivingMoney: '收款中',
        unCollected: '未收款'
      },
      collect: '添加收款',
      businessType: {
        salesOrder: '销售订单',
        salesDelivery: '销售发货单'
      },
      receivableDateStart: '收款期限起',
      receivableDateEnd: '收款期限止',
      pleaseBusinessType: '请选择业务类型'
    },
    financeReceivableInfo: {
      receivableCode: '应收款编码',
      companyId: '所属公司',
      businessUnitId: '单位ID',
      businessUnitName: '客户名称',
      currencyId: '币种',
      amount: '应收金额',
      amountNow: '本次收款',
      receivableDate: '期限',
      salesOrderId: '销售ID',
      salesOrderCode: '销售单号',
      salesDeliveryId: '销售发货ID',
      salesDeliveryCode: '销售发货单',
      salesReceiptPlanId: '销售回款计划ID',
      salesReceiptPlanCode: '销售回款单',
      businessCode: '业务编码',
      businessType: '关联业务',
      amountReceived: '收款金额',
      receivableBalance: '应收款余额',
      advancePayment: '预收款金额',
      advancePaymentSum: '预收款余额',
      ableAdvancePaymentSum: '可用预收款',
      unConfirmedAmount: '待确认金额',
      confirmedAmount: '已确认金额',
      status: '状态',
      pleaseChooseOperatorId: '请选择经办人',
      addCollectBookSuccess: '收款成功',
      addCollectBookError: '收款失败，请检查数据',
      pleaseChooseData: '请选择期限',
      remark: '备注',
      isConfirmSave: '是否保存',
      pleaseInputBusinessUnitName: '请输入单位名称',
      pleaseInputBusinessUnitId: '请选择客户',
      pleaseChooseCurrencyId: '请选择币种',
      pleaseInputAmount: '请输入应收款金额',
      pleaseChooseReceivableDate: '请选择期限',
      pleaseInputSalesOrderCode: '请输入销售编号',
      pleaseInputSalesDeliveryCode: '请输入销售发货单',
      pleaseInputSalesReceiptPlanCode: '请输入销售回款单',
      receivableInfo: '应收款信息',
      editReceivable: '编辑应收款',
      addReceivable: '添加应收款',
      viewReceivable: '查看应收款',
      delete: '是否删除',
      warningMasseage: '本次收款金额总和，只能小于实收金额',
      amountNowGreaterThanZero: '本次收款金额总和不能为负数',
      advanceLessThanBalance: '预收款余额不足',
      amountLessThanReceivableAmount: '本次收款金额不能大于应收金额',
      saveAdvanceAmount: '实收金额大于本次收款总金额部分，将会存入到预收款里面',
      pleaseInputCollectionAmount: '请输入表格中的本次收款',
      collectionAmountGreaterThanZero: '本次收款金额总和不能为负数',
      receivableCollectGreatThenBalance: '应收款存在本次收款金额大于应收余额',
      directAdvanceReceiptAmount: '抵扣预收款',
      directAdvanceReceiptAmountGreatThenAmountNow: '抵扣预收款金额不能大于本次收款金额',
      directAdvanceReceiptAmountGreatThenReceivable: '抵扣预收款金额不能大于应收金额',
      receivableCollection: '收款单',
      businessDate: '业务日期',
      businessUnitSummary: '汇总',
      businessSummary: '按订单',
      notZero: '应收款余额不为0',
      business: '业务编码',
      collectionAmount: '收款金额',
      aboutReturnAmount: '退款单金额',
      returnAmount: '已退款金额',
      businessTypeEeum: {
        addManully: '手动添加',
        saleOrder: '销售订单',
        saleDelivery: '销售发货单',
        salePlan: '销售回款计划',
        mallOrder: '商城订单',
        mallOrderCollection: '商城订单收款',
        mallOrderRefund: '商城订单退款',
        salesInventoryLock: '销售锁定库存',
        purchaseReturn: '采购退货'
      },
      businessCodeSelect: '业务编码，请先选择关联业务',
      receivableSelect: '应收款选择',
      allReceivable: '全部',
      salesOrderReceivable: '销售订单',
      salesDeliveryReceivable: '销售发货单',
      mallOrderReceivable: '商城订单',
      salesInventoryLockReceivable: '销售锁定库存',
      directAdvancePayableAmount: '转预付款金额'
    },
    financeAdvanceReceipt: {
      viewAdvance: '查看预收款',
      accountCode: '收款单编码',
      businessUnitId: '客户ID',
      businessUnitName: '客户名称',
      businessDate: '发生日期',
      currencyId: '货币',
      amount: '金额',
      amountBalance: '余额',
      remark: '备注'
    },
    financePayable: {
      pleaseChoosePayable: '请选择应付款',
      summaryList: '汇总',
      detail: '明细',
      pay: '付款',
      editPayable: '编辑应付款',
      addPayable: '添加应付款',
      viewPayable: '查看应付款',
      payableInfo: '应付款信息',
      payableCode: '系统自动生成',
      pleaseChooseCustomer: '请选择客户',
      pleaseChooseSupplier: '请选择供应商',
      pleaseChooseUser: '请选择用户',
      isDelete: '是否删除',
      payablePayment: '付款单',
      businessType: {
        saleOrder: '销售订单',
        saleDelivery: '销售发货',
        saleReturn: '销售退货',
        purchaseOrder: '采购订单',
        purchaseReceipt: '采购收货',
        purchaseReturn: '采购退货',
        payRequest: '付款申请',
        reimbursement: '费用报销',
        mallRefundOrder: '商城退款',
        purchaseOutOrder: '委外订单',
        commissionExtract: '佣金提现',
        rebateExtract: '返点提现',
        advanceReceipt: '预收款',
        advacePayment: '预付款',
        depositSlip: '存款单',
        withdrawSlip: '取款单'
      },
      status: {
        all: '全部',
        payNot: '未付款',
        paying: '付款中',
        payFinish: '已完成'
      },
      businessCompanyType: {
        customer: '客户',
        user: '用户',
        supplier: '供应商',
        member: '商城会员'
      },
      rules: {
        pleaseChoosePayableDate: '请选择付款期限',
        pleaseChooeseBusinessCompanyType: '请选择单位类型',
        pleaseChooseBusinessCompanyName: '请选择往来对象',
        pleaseChooseCurrencyId: '请选择币种',
        pleaseInputAmount: '请输入金额',
        pleaseChooseBusinessType: '请选择业务类型',
        pleaseInputBusinessCode: '请选择关联的业务'
      }
    },
    financePayableInfo: {
      payableCode: '应付款编码',
      paymentCode: '关联付款单编码',
      company: '所属公司',
      businessCompanyType: '往来对象类型',
      businessCompanyId: '往来单位ID',
      businessCompanyName: '往来对象',
      currencyId: '货币',
      amount: '应付金额',
      amountPaid: '已付金额',
      confirmedAmount: '已确认金额',
      unConfirmedAmount: '未确认金额',
      amountBalance: '应付余额',
      unConfirmAmountSum: '待确认金额',
      prepayment: '预付款',
      ablePrepayment: '可用预付款',
      advancePayment: '预付款余额',
      payAmount: '本次付款',
      payableDate: '期限',
      businessType: '业务类型',
      businessId: '业务ID',
      businessCode: '关联业务',
      status: '状态',
      remark: '备注',
      amountLessThanPayAmount: '本次付款金额不能大于应付金额',
      warningMasseage: '本次付款金额不能大于实付金额',
      payAmountGreaterThanZero: '本次付款金额总和不能为负数',
      advanceLessThanBalance: '抵扣金额只能小于可抵扣金额',
      pleaseInputPayAmount: '请输入表格中的本次付款',
      saveAdvanceAmount: '实付金额大于本次付款总金额部分，将会存入到预付款里面',
      supplierName: '供应商名称',
      accountName: '账户户名',
      accountNumber: '账号',
      payablePaidGreatThenBalance: '应付款存在本次付款金额大于应付余额',
      payableSelect: '应付款选择',
      allPayable: '全部应付款',
      purchaseOrderPayable: '采购订单',
      salesReturnPayable: '销售退款',
      mallOrderReturnPayable: '商城退款',
      purchaseOutOrderPayable: '委外订单',
      returnAdvanceReceiptType: '退预收款类型',
      returnAdvanceReceiptAmount: '退预收款金额',
      amountRefund: '付款金额',
      confirmedRefundAmount: '已确认金额',
      unConfirmedRefundAmount: '待确认金额',
      paidAmount: '已付金额',
      notZero: '应付款余额不为0'
    },
    financeAdvancePayment: {
      viewPayment: '查看预付款',
      accountCode: '付款单编码',
      businessCompanyType: '单位类型',
      businessCompanyId: '单位ID',
      businessCompanyName: '单位名称',
      businessDate: '发生日期',
      currencyId: '货币',
      amount: '金额',
      amountBalance: '余额',
      remark: '备注'
    },
    financeApportionmentRule: {
      payableInfo: '关联应付款',
      saleApportionmentRule: '销售回款平摊规则:',
      purchaseApportionmentRule: '采购付款平摊规则:',
      dateNearToFar: '按期限由近到远',
      dateFarToNear: '按期限由远到近',
      amountBigToSmall: '按金额从大到小',
      amountSmallToBig: '按金额从小到大',
      pleaseGoOnChooseCustomer: '请选择客户'
    },
    financeApply: {
      applyCode: '申请编码',
      company: '所属公司',
      applyDate: '申请日期',
      applycantName: '申请人',
      departmentName: '部门',
      payeeType: '收款方类型',
      payeeName: '收款方',
      payeeBankNumber: '收款方账号',
      payeeBankName: '收款方银行',
      businessType: '关联业务类型',
      businessCode: '关联业务',
      receive: '付款方式',
      currencyId: '货币',
      totalAmount: '总金额',
      totalAmountWords: '金额大写',
      auditorName: '审核人',
      auditTime: '审核时间',
      status: '状态',
      applyRemark: '备注',
      illegalData: '请输入有效数据',
      businessCodeSelect: '业务编码，请先选择关联业务',
      common: {
        addApply: '添加付款申请',
        editApply: '编辑付款申请',
        viewApply: '查看付款申请',
        isDeleta: '是否删除该付款申请？',
        autoInput: '编码系统自动生成',
        saleOrder: '销售订单',
        saleDelivery: '销售发货单',
        purchaseOrder: '采购订单',
        saveDraft: '保存为草稿',
        submit: '提交审核',
        audit: '审核',
        customer: '客户',
        supplier: '供应商',
        user: '内部用户',
        other: '其他',
        applyMessage: '付款申请信息',
        isNotNull: '上一条记录不能为空，请先填写上一条记录',
        pleaseChooseType: '请先选择收款方类型'
      },
      rules: {
        pleaseChooseDate: '请选择申请日期',
        pleaseChooseCant: '请选择申请人',
        pleaseChooseDepartment: '请选择部门',
        pleaseChoosePayee: '请选择收款方',
        pleaseChooseSettle: '请选择付款方式'
      },
      item: {
        amount: '金额',
        paymentItem: '摘要',
        expenditureType: '支出类别',
        incomeType: '收入类别'
      }
    },
    financeApplyItem: {
      amount: '金额',
      paymentItem: '项目摘要'
    },
    accountBookPurpose: {
      incomeType: '收入类型',
      expenditureType: '支出类型',
      business: '关联业务',
      amount: '金额',
      details: '摘要'
    },
    invoiceApplication: {
      status: {
        draft: '草稿',
        unAudit: '待审核',
        audit: '已审核',
        invoiced: '已开票',
        delivered: '已交付',
        signed: '已签收'
      },
      operate: {
        draft: '保存为草稿',
        unAudit: '提交',
        audit: '提交并审核',
        invoice: '开票',
        delivery: '交付',
        sign: '签收'
      },
      queryKeyword: '申请编码、客户名称',
      applicationCode: '申请编码',
      invoiceCategory: '发票类型',
      customer: '客户',
      buyerInfo: '买方信息',
      buyerName: '买方名称',
      buyerTaxNumber: '买方纳税人识别号',
      buyerAddress: '买方地址',
      buyerPhone: '买方电话',
      buyerBank: '买方开户行',
      buyerAccountNumber: '买方账号',
      sellerInfo: '卖方信息',
      sellerName: '卖方名称',
      sellerTaxNumber: '卖方纳税人识别号',
      sellerAddress: '卖方地址',
      sellerPhone: '卖方电话',
      sellerBank: '卖方开户行',
      sellerAccountNumber: '卖方账号',
      totalAmountTax: '价税合计',
      pleaseItemType: '请选择明细来源',
      selectProduct: '全部产品',
      selectSalesOrder: '销售订单',
      selectSalesDelivery: '销售发货单',
      applicationItemName: '货物或应税劳务、服务名称',
      addApplicationTitle: '添加开票申请',
      editApplicationTitle: '编辑开票申请',
      viewApplicationTitle: '查看开票申请',
      verifyTips: '确认【审核】该开票申请',
      invoicedTips: '确认该申请【开票】',
      deliveredTips: '确认该开票申请【已交付】',
      signedTips: '确认该开票申请【已签收】',
      deleteTips: '确认【删除】该开票申请',
      revokeTips: '确认【撤销】该开票申请',
      pleaseChooseInvoiceCategory: '请选择发票类型',
      pleaseChooseCustomer: '请选择客户',
      pleaseInputBuyerName: '请填写购买方名称',
      pleaseInputBuyerTaxNumber: '请填写购买方纳税人识别号',
      pleaseInputSellerName: '请填写销售方名称',
      pleaseInputSellerTaxNumber: '请填写销售方纳税人识别号',
      pleaseChooseApplicationItem: '请填写开票明细'
    },
    invoiceInfo: {
      invoiceCode: '发票代码',
      invoiceNumber: '发票号码',
      invoiceDate: '开票日期',
      amountTax: '税额合计',
      totalAmountTax: '价税合计',
      totalAmount: '金额合计(不含税)',
      checkStatus: '查验状态',
      filePath: '发票附件',
      addInvoiceTitle: '添加发票',
      editInvoiceTitle: '编辑发票',
      viewInvoiceTitle: '查看发票',
      deleteTips: '确认【删除】该发票',
      normal: '正常',
      error: '异常',
      unCheck: '未查验',
      invoiceSelector: '请选择发票',
      fileName: '文件名',
      pleaseUploadInvoiceFile: '请上传发票附件',
      fileTypeError: '仅支持 PNG/JPG/JPEG/PDF/OFD 格式文件',
      uploadFile: '上传文件',
      ofdNotAllowPreview: 'ofd文件暂不支持预览'
    },
    invoiceRecord: {
      startDate: '登记日期起',
      endDate: '登记日期止',
      makeInvoice: '开票登记',
      receiptInvoice: '收票登记',
      queryKeyword: '登记编码',
      recordDate: '登记日期',
      totalAmountTax: '发票总金额',
      recordCode: '登记编码',
      addInvoiceRecordTitle: '添加开票登记',
      editInvoiceRecordTitle: '编辑开票登记',
      viewInvoiceRecordTitle: '查看开票登记',
      deleteTips: '确认【删除】该开票登记',
      pleaseChooseBusinessType: '选择关联业务单据',
      selectInvoice: '选择发票',
      occurDate: '发生日期',
      totalAmountTaxBusiness: '本次开票金额',
      business: '关联业务',
      addInvoice: '新增发票',
      businessAmount: '业务金额',
      invoicedAmount: '已开票金额'
    },
    invoiceType: {
      vatInovice: '增值税普通发票',
      vatInvoiceSpecial: '增值税专用发票',
      vatInvoiceElectonic: '增值税普通发票（电子)',
      vatInvoiceVolumn: '增值税普通发票（卷式）',
      vatInvoiceSpecialElectonic: '增值税专用发票（电子）',
      motorSalesInvoice: '机动车销售统一发票',
      trainTicket: '火车票',
      passenengerTicket: '客运发票',
      airPassengerTicket: '航空客运机票行程单',
      roadToll: '过路费',
      withholdingTaxInvoice: '代扣代缴税收通用缴款凭证',
      importTaxInvoice: '海关进口增值税专用缴款书',
      agricultralPurchaseInvoice: '农产品收购发票',
      vatInvoiceSpecialTransportation: '货物运输业增值税专用发票',
      other: '其它'
    },
    currencyExchangeRate: {
      currencyName: '货币名称',
      currencySymbol: '符号',
      exchangeRateValue: '固定汇率值',
      remark: '备注',
      add: '添加货币汇率',
      edit: '编辑货币汇率',
      delete: '是否删除该货币汇率?',
      pleaseInputCurrencySymbol: '请输入货币符号',
      pleaseInputRateValue: '请输入固定汇率值',
      pleaseChooseCurrency: '请选择货币'
    },
    config: {
      invoice: {
        headerInfo: '多公司及开票信息',
        company: '公司名称',
        taxpayerName: '纳税人名称',
        taxpayerNumber: '纳税人识别号',
        companyAddress: '公司地址',
        companyPhone: '公司电话',
        bank: '开户银行',
        bankAccount: '银行账号',
        pleaseInputCompleteData: '请选择所属公司和输入纳税人名称、纳税人识别号',
        isDeleteConfig: '是否删除该配置？'
      },
      inventoryConfig: '库存参数设置',
      inventoryCostType: '库存成本核算方式',
      fifo: '先进先出',
      moveWeightedAvg: '移动加权平均'
    },
    deposit: {
      depositDeatil: '保证金明细',
      depositSummary: '保证金汇总',
      addDeposit: '添加保证金',
      editDeposit: '编辑保证金',
      viewDeposit: '查看保证金',
      returnDeposit: '保证金退款',
      refundAmount: '已退金额',
      waitReturnAmount: '待退金额',
      return: '退款',
      amount: '保证金金额',
      balance: '保证金余额',
      ableReturnAmount: '可退金额',
      returnAmount: '退款金额',
      returnSuccess: '退款成功',
      confirmSuccess: '确认成功',
      returnDate: '退款日期',
      returnDetial: '退款明细',
      confirm: '确认',
      priceTip: '退款金额不能大于可退金额',
      customerName: '客户名称',
      bussinessCode: '收/付款编码',
      depositBalanceDeatil: '保证金余额明细',
      bussinessData: '收/付款日期'
    },
    advanceReceipt: {
      advanceReceiptCode: '预收款编码',
      companyName: '所属公司',
      businessDate: '发生日期',
      customerCode: '客户编码',
      customerName: '客户名称',
      customer: '客户',
      managerName: '业务员',
      currencyId: '货币类型',
      amount: '预收金额',
      financialCollectionAmount: '财务收款金额',
      receiptAmount: '预收款金额',
      ableAmountBalance: '可用预收款余额',
      notAuditAmount: '未审核预收款金额',
      advanceDetail: '预收款明细',
      advanceSummary: '预收款余额',
      accountId: '收款账户',
      receiptMethod: '收款方式',
      auditStatus: '审核状态',
      auditId: '审核人',
      auditDate: '审核时间',
      isDirect: '是否定向抵扣',
      writeOffStatus: '核销状态',
      writeOffAmount: '核销金额',
      addAdvance: '添加预收款',
      message: '基本信息',
      remark: '备注',
      manual: '手动添加',
      collectionGene: '收款单生成',
      addMethod: '来源',
      advanceReceiptType: '预收款类型',
      salesReturnPaymentGene: '销售退款付款单生成',
      defaultAdvanceReceiptBalance: '默认预收款余额',
      directAdvanceReceiptBalance: '定向预收款余额',
      balanceDetails: '明细',
      balanceExpnditure: '收支',
      advanceBalanceTitle: '预收款余额明细',
      advanceDetailsTitle: '预收款收支明细',
      transactionTime: '交易时间',
      transactionAmount: '交易金额',
      relatedBusiness: '业务单',
      importAdvanceReceipt: '预收款导入',
      attentionTipsTitle: '预收款导入注意事项',
      attentionTips1: '1.建议使用官方Excel模板导入数据',
      attentionTips2: '2.上传EXCEL后，请准确填写标题行号和数据起始行号',
      attentionTips3: '3.请准确填写数据映射关系并检查数据预览是否相符',
      batchSet: '批量设置',
      executeResultInfo: '共{0}条记录，成功：{1}条，跳过：{2}条',
      batchSetTips: '请批量设置好数据进行下一步导入操作，否则将导入失败',
      commissionExtractGene: '佣金提现生成',
      targetAdvanceReceiptType: '目标预收款类型',
      transferAdvanceReceipt: '转移预收款',
      pleaseSelectAdvanceReceiptBalance: '请选择要转移预收款',
      pleaseSelectSameCompanyAdvanceReceiptBalance: '请选择同一所属公司预收款',
      confirmTransferAdvanceReceipt: '确认转移预收款',
      sourceAdvanceReceiptType: '要转移预收款类型',
      advanceReceiptRefund: '退款',
      receiptRefundAmount: '退款金额',
      addAdvanceRefund: '添加预收款退款',
      status: {
        notAudit: '未审核',
        audit: '已审核',
        notWriteOff: '未核销',
        partialWriteOff: '部分核销',
        writeOff: '已核销',
        productBrand: '产品品牌',
        saleOrder: '销售订单',
        productCategory: '产品分类',
        product: '产品',
        yes: '是',
        no: '否',
        revoked: '已撤销'
      },
      rules: {
        pleaseChooseCompany: '请选择所属公司',
        pleaseChooseDate: '请选择发生日期',
        pleaseChooseCustomer: '请选择客户',
        pleaseChooseCurrency: '请选择货币',
        pleaseInputAmount: '请输入金额',
        pleaseChooseAccount: '请选择收款账户',
        pleaseChooseMethod: '请选择收款方式',
        pleaseChooseAdvanceReceiptType: '请选择预收款类型',
        collectedAmountPlease: '财务收款金额不能为空',
        advanceReceiptAmountPlease: '预收款金额不能为空'
      }
    },
    advancePayment: {
      advancePaymentCode: '预付款编码',
      companyName: '所属公司',
      businessDate: '发生日期',
      businessUnitCode: '供应商编码',
      businessUnitName: '供应商名称',
      businessUnit: '供应商',
      managerName: '业务员',
      currencyId: '货币类型',
      amount: '预付金额',
      paymentAmount: '财务付款金额',
      advancePaymentAmount: '预付款金额',
      ableAmountBalance: '可用预付款余额',
      notAuditAmount: '未审核预付款金额',
      advanceDetail: '预付款明细',
      advanceSummary: '预付款余额',
      accountId: '付款账户',
      paymentMethod: '付款方式',
      auditStatus: '审核状态',
      auditId: '审核人',
      auditDate: '审核时间',
      addAdvance: '添加预付款',
      message: '基本信息',
      remark: '备注',
      manual: '手动添加',
      collectionGene: '付款单生成',
      addMethod: '来源',
      paymentGene: '付款单自动生成',
      purchaseReturnPaymentGene: '采购退款自动生成',
      status: {
        notAudit: '未审核',
        audit: '已审核'
      },
      rules: {
        pleaseChooseCompany: '请选择所属公司',
        pleaseChooseDate: '请选择发生日期',
        pleaseChooseBusinessUnit: '请选择供应商',
        pleaseChooseCurrency: '请选择货币',
        pleaseInputAmount: '请输入金额',
        pleaseChooseAccount: '请选择付款账户',
        pleaseChooseMethod: '请选择付款方式'
      }
    },
    commission: {
      keywordQuery: '业务编码 客户名称 会员名称',
      businessType: '业务类型',
      businessCode: '业务编码',
      orderAmount: '订单金额',
      refundAmount: '退款金额',
      orderBalance: '订单余额',
      member: '会员',
      customer: '客户',
      firstCustomer: '一级客户',
      secondCustomer: '二级客户',
      customerName: '客户名称',
      commissionRate: '分佣比例',
      commission: ' 佣金',
      purchaser: '购买方',
      mallOrder: '商城订单',
      endDate: '佣金计算截至时间',
      salesOrder: '销售订单',
      undivided: '未分佣',
      allocated: '已分佣',
      orderDateStart: '订单时间起',
      orderDateEnd: '订单时间止',
      commissionStatus: '分佣状态',
      orderDate: '订单时间',
      commissionDetails: '佣金明细',
      commissionSummary: '佣金汇总',
      firstCommission: '一级佣金',
      secondCommission: '二级佣金',
      notAvailableCommission: '待分佣',
      availableCommission: '已分佣',
      commissionSubtotal: '小计',
      commissionTotal: '合计',
      summaryQueryKeywords: '客户',
      toAdvanceReceipt: '转预收款',
      toCash: '微信转账',
      commissionExtract: '提现申请',
      extractType: '提现方式',
      extractAmount: '提现金额',
      extractStatus: '提现状态',
      operator: '审核人',
      auditTime: '审核时间',
      rejectReason: '驳回原因',
      applicationTime: '申请时间',
      auditStatus: '审核状态',
      auditCommissionExtractApply: '佣金提现审核',
      passTips: '确认【通过】该提现申请',
      rejectTips: '确认【驳回】该提现审核',
      emptyRejectReason: '请填写驳回原因',
      extractCommissionTotal: '已提现',
      commissionBalance: '余额',
      businessUnitType: '类型',
      businessUnitName: '类型名称',
      detail: '明细',
      memberOrCustomerName: '会员/客户名称',
      commissionCalcMethod: '计算方式',
      extractBankCard: '提现银行卡',
      extractAccountBank: '银行',
      extractAccountName: '户名',
      extractAccountNumber: '账号',
      commissionDetailsExport: '佣金明细导出',
      customerOrMember: '会员/客户',
      freight: '运费',
      voucherDeductAmount: '代金券支付金额',
      pointDeductAmount: '积分支付金额'
    },
    advanceReceiptType: {
      queryKeyword: '类型名称',
      advanceReceiptTypeName: '类型名称',
      product: '产品',
      productBrand: '产品品牌',
      selectProduct: '选择产品',
      selectProductBrand: '选择产品品牌',
      addTitle: '添加预收款类型',
      editTitle: '编辑预收款类型',
      viewTitle: '查看预收款类型',
      advanceReceiptTypeNameNotEmpty: '请填写预收款类型名称',
      limitEmpty: '预收款使用范围不能为空',
      usedRange: '使用范围',
      deleteTips: '是否【删除】该预收款类型',
      financeAccount: '关联账户',
      financeAccountNotEmpty: '关联账户不能为空',
      defaultAccount: '默认资金账户'
    }
  },
  postSale: {
    order: {
      postSaleCode: '售后编码',
      customerName: '客户名称',
      customerCode: '客户编码',
      businessType: '业务类型',
      businessCode: '业务编码',
      auditStatus: '审核状态',
      title: '售后单',
      addTitle: '添加售后单',
      editTitle: '编辑售后单',
      viewTitle: '查看售后单',
      deleteTips: '是否删除该售后单?',
      revokeTips: '是否撤销该售后单?',
      salesOrder: '销售订单',
      salesDelivery: '销售发货单',
      customerNameNotEmtpy: '客户名称不能为空',
      businessTypeNotEmtpy: '业务类型不能为空',
      businessCodeNotEmtpy: '业务编码不能为空',
      pleaseChooseBusinessType: '请选择业务类型',
      keywordPlaceHolder: '售后编码、客户名称、客户编码、业务编码',
      unaudit: '未审核',
      audit: '已审核'
    },
    product: {
      common: {
        startDate: '发货日期开始时间',
        endDate: '发货日期结束时间',
        keywordPlaceHolder: '产品信息、客户名称',
        toExpire: '即将到期',
        expired: '已过期',
        all: '全部',
        normal: '正常',
        day: '天',
        add: '添加售后产品',
        isDeleteProduct: '是否删除该售后产品？',
        chooseCustomer: '请先选择一个客户',
        chooseDelivery: '请先选择一笔发货单',
        productInfo: '产品信息'
      },
      info: {
        productCode: '产品编码',
        serialNumber: '序列号',
        productName: '产品名称',
        productModel: '规格型号',
        productAssistValue: '辅助属性',
        productUnit: '计价单位',
        customerName: '客户名称',
        deliveryDate: '发货日期',
        businessCode: '发货单',
        quantity: '数量',
        duringDate: '售后期间',
        during: '至',
        startDate: '开始日期',
        endDate: '结束日期',
        postSaleStatus: '售后状态',
        remark: '备注'
      },
      rules: {
        pleasechooseCustomer: '请选择客户',
        pleasechooseBusiness: '请选择发货单',
        pleasechooseDeliveryDate: '请选择发货日期',
        pleaseInputProductCode: '请选择产品',
        pleaseInputProductName: '请输入产品名称',
        pleaseInputProductUnit: '请输入计价单位',
        pleaseInputSerialNumber: '请输入序列号',
        pleaseChooseStartDate: '请选开始日期',
        pleaseChooseEndDate: '请选择结束日期'
      }
    }
  },
  production: {
    bom: {
      bomCode: '产品BOM编码',
      bomName: '物料清单名称',
      bomVersion: '版本',
      dwgNo: '图号',
      customerName: '客户',
      productionCost: '参考生产费用',
      laborCost: '参考人工费用',
      auditor: '审核人',
      materielName: '物料名称',
      materielCode: '物料编码',
      materielModel: '物料规格',
      materielUnit: '物料单位',
      quantity: '数量',
      lossQuantity: '损耗数量',
      lossRate: '损耗率',
      bomStatus: 'BOM状态',
      craft: '工艺',
      draft: '草稿',
      unaudit: '待审核',
      audit: '已审核',
      title: '产品BOM',
      addTitle: '添加产品BOM',
      editTitle: '编辑产品BOM',
      viewTitle: '查看产品BOM',
      bomVersionNotEmpty: '版本不能为空',
      bomNameNotEmpty: 'BOM名称不能为空',
      productNameNotEmpty: '产品名称不能为空',
      deleteTips: '是否删除该生产BOM ?',
      revokeTips: '是否撤销该生产产品BOM ?',
      updateVersionTips: '是否该生产BOM更新版本 ?',
      keywordQuery: 'BOM名称、BOM编码、产品信息',
      updateVersion: '更新',
      bomNameCreateAuto: '物料清单名称自动生成',
      historyVersion: '历史版本'
    },
    plan: {
      status: '状态',
      planCode: '计划编码',
      finishDate: '完成日期',
      agentName: '经办人',
      auditorName: '审核人',
      auditTime: '审核时间',
      department: '生产部门',
      common: {
        startDate: '完成时间起',
        endDate: '完成时间止',
        invalid: '作废',
        all: '全部',
        draft: '草稿',
        pending: '待审核',
        invalided: '已作废',
        audited: '已审核',
        addPlan: '添加生产计划',
        editPlan: '编辑生产计划',
        viewPlan: '查看生产计划',
        baseInfo: '基本信息',
        chooseProduct: '选择产品',
        auditTips: '是否确认审核该生产计划？',
        deleteTips: '是否删除该生产计划？',
        planIsEmpty: '请选择要审核的生产计划',
        revokeTips: '是否撤销该生产计划？',
        invalidTips: '是否作废该生产计划？',
        process: '工艺说明',
        editor: '请输入工艺说明',
        uploading: '图片上传中...'
      },
      rules: {
        pleaseChooseDate: '请选择完成时间',
        pleaseChooseAgent: '请选择经办人',
        pleaseChooseDepartment: '请选择部门'
      },
      operates: {
        draft: '保存为草稿',
        submit: '提交',
        audit: '保存并审核'
      },
      product: {
        productCode: '产品编码',
        productName: '产品名称',
        productModel: '规格型号',
        productUnit: '单位',
        quantity: '数量',
        config: 'BOM'
      },
      bom: {
        editProductBom: '编辑产品物料清单',
        viewProductBom: '查看产品物料清单',
        selectProductMateriel: '选择物料产品',
        materielCode: '物料编码',
        materielName: '物料名称',
        materielModel: '规格型号',
        materielUnit: '单位',
        quantity: '数量',
        lossQuantity: '损耗数量'
      }
    },
    order: {
      orderCode: '订单编码',
      salesOrder: '销售订单',
      materiel: '物料',
      audited: '已审核',
      begin: '开始',
      finish: '完成',
      inProduction: '生产中',
      finished: '已完成',
      bomName: 'BOM单名称',
      pending: '待审核',
      pick: '领料',
      return: '退料',
      finishIn: '成品入库',
      addTitle: '添加生产订单',
      editTitle: '编辑生产订单',
      viewTitle: '查看生产订单',
      revokeTips: '是否确认撤销该生产订单',
      deleteTips: '是否确认删除该生产订单',
      pleaseChooseProduct: '请选择产品',
      pleaseChooseBom: '请选择Bom',
      productionOrderInfo: '生产订单信息'
    }
  },
  service: {
    serviceCategoryTitle: '服务分类',
    addCategory: '添加分类',
    categoryMessage: '分类信息',
    amountWithTax: '金额',
    serviceItem: '服务项目',
    revokeTips: '是否确认撤销该服务订单',
    category: {
      categoryCode: '分类编码',
      categoryName: '分类名称',
      parentId: '父级',
      typeSort: '排序',
      isEnable: '是否启用',
      remark: '备注'
    },
    common: {
      systemProduct: '系统自动生成',
      pleaseChooseParent: '选择父级',
      confirmDeleteText: '是否确认删除？'
    },
    rules: {
      pleaseInputCategoryName: '请输入分类名称'
    },
    item: {
      itemCode: '服务项目编码',
      itemName: '服务项目名称',
      categoryName: '类别名称',
      serviceCategory: '服务分类',
      addItem: '添加服务项目',
      editItem: '编辑服务项目',
      viewItem: '查看服务项目',
      itemContent: '服务项目说明',
      itemMessage: '基本信息',
      processMessage: '服务流程',
      remark: '备注',
      isDelete: '是否删除该项目？',
      startTime: '开始时间',
      endTime: '结束时间',
      common: {
        pleaseInputItemName: '请填写项目名称',
        pleaseChooseCategory: '请选择服务分类'
      },
      process: {
        start: '开始',
        finish: '完成',
        nodeName: '节点名称',
        expectedTime: '预计耗时',
        timeType: '时间类型',
        minutes: '分钟',
        hours: '小时',
        naturalDays: '自然日',
        workingDays: '工作日',
        weeks: '周',
        months: '月',
        years: '年',
        processContent: '节点说明',
        isDelete: '是否删除该节点？',
        isMax: '节点不能超过20个',
        pleaseInputNodeName: '请输入节点名称',
        pleaseInputTime: '请输入预计时间'
      }
    }
  },
  schedule: {
    createSchedule: '创建日程',
    scheduleTitle: '日程标题',
    scheduleType: '日程类型',
    startTime: '开始时间',
    endTime: '结束时间',
    advance: '提前',
    participants: '参与人',
    addParticipants: '+ 添加参与人',
    selectParticipants: '选择参与人',
    showMoreInfo: '更多信息',
    hideMoreInfo: '隐藏更多信息',
    addSchedule: '添加日程',
    editSchedule: '编辑日程',
    location: '地点',
    details: '描述',
    today: '今天',
    month: '月',
    week: '周',
    day: '日',
    minutes: '分钟',
    hours: '小时',
    days: '天数',
    relatedBusiness: '相关信息',
    selectRelatedBusiness: '+ 关联业务',
    disassociate: '取消关联',
    exception: {
      scheduleTitlePlease: '请输入日程标题',
      scheduleTypePlease: '请选择日程类型',
      startTimePlease: '请选择日程开始时间',
      endTimePlease: '请选择日程结束时间'
    }
  },
  businessType: {
    customer: '客户',
    product: '产品',
    sales: '销售',
    salesQuotation: '报价单',
    salesOrder: '销售订单',
    salesDelivery: '销售发货',
    salesReturn: '销售退货',
    salesExpense: '销售费用',
    purchase: '采购',
    purchaseSupplier: '供应商',
    purchasePlan: '采购计划',
    purchaseRequisition: '请购单',
    purchaseOrder: '采购订单',
    purchaseOrderOut: '委外订单',
    purchaseExpense: '采购费用'
  },
  project: {
    common: {
      addProject: '创建项目',
      editProject: '设置',
      sysProductCode: '系统自动生成',
      addMembers: '添加项目成员',
      archive: '归档',
      reactivation: '激活',
      projectInfo: '项目信息',
      confirmArchive: '是否确认归档？',
      isDeleteProject: '是否删除该项目？',
      confirmReactivation: '是否确认激活？',
      queryContent: '项目编码、项目名称',
      addTaskMembers: '添加任务成员',
      addTask: '创建任务',
      pleaseChooseProject: '请选择项目',
      notBegin: '未开始',
      finish: '已完成',
      overdue: '已逾期',
      taskInfo: '任务信息',
      isDeleteTask: '是否删除该任务？',
      confirmFinish: '改任务是否确认完成？',
      dateRangePlease: '请选择任务时间',
      ongoing: '进行中',
      hangUp: '已挂起',
      addDirectory: '新建文件夹',
      directoryName: '文件夹名称',
      fileDirectory: '文件夹',
      firstDirectory: '附件文件夹',
      cuurentDirectory: '当前文件夹',
      projectTaskList: '任务',
      startTaskTips: '确认开始任务',
      hangUpTaskTips: '确认挂起任务',
      start: '开始',
      hangUpTask: '挂起',
      customer: '客户',
      startTime: '计划开始时间',
      endTime: '计划结束时间',
      realStartTime: '实际开始时间',
      realEndTime: '实际结束时间',
      updateStartTime: '修改计划开始时间',
      updateEndTime: '修改计划结束时间',
      updateRealStartTime: '修改实际开始时间',
      updateRealEndTime: '修改实际结束时间',
      workingHours: '工时',
      addChildTask: '添加子任务',
      taskProduct: '关联产品',
      projectProductGantt: '产品进度',
      projectTaskGantt: '项目进度',
      rules: {
        pleaseChooseStatus: '请选择项目状态',
        pleaseInputName: '请输入项目名称',
        pleaseChooseStartTime: '请选择开始时间',
        pleaseChooseEndTime: '请选择结束时间',
        pleaseChooseGroup: '请选择项目分组',
        pleaseChooseScope: '请选择项目可见范围',
        pleaseChooseMember: '请选择项目成员',
        pleaseChooesManager: '请选择项目负责人'
      },
      status: {
        normal: '正常',
        risks: '有风险',
        outOfControl: '失控'
      },
      scopes: {
        private: '私有: 只有加入项目的成员才可看见此项目',
        public: '公开: 企业所有成员都可以看见此项目'
      }
    },
    info: {
      projectCode: '项目编号',
      projectName: '项目名称',
      projectGroup: '项目分组',
      projectScope: '可见范围',
      projectManager: '项目负责人',
      startTime: '开始时间',
      endTime: '结束时间',
      projectSchedule: '项目进度',
      projectStatus: '项目状态',
      projectArchive: '归档',
      projectDescription: '项目描述',
      member: '项目成员',
      remark: '备注',
      projectDateRange: '项目时间',
      projectDateRangePlease: '请选择项目时间',
      addTask: '添加任务',
      addChildTask: '添加子任务'
    },
    task: {
      taskCode: '任务编码',
      taskName: '任务名称',
      projectName: '所属项目',
      taskManager: '负责人',
      startTime: '开始时间',
      endTime: '结束时间',
      priority: '优先级',
      taskStatus: '状态',
      description: '描述',
      member: '任务成员',
      business: '关联业务',
      remark: '备注',
      selectRelatedBusiness: '相关信息',
      disassociate: '取消关联',
      taskTime: '任务时间',
      myTaskOnly: '只看我的',
      addBusiness: '添加关联业务',
      parentTask: '上级任务名称',
      common: {
        finish: '完成',
        hight: '高',
        middle: '中',
        low: '低',
        not: '无'
      },
      rules: {
        pleaseInputName: '请输入任务名称',
        pleaseChooseManage: '请选择任务负责人',
        pleaseChooseStartTime: '请选择开始时间',
        pleaseChooseEndTime: '请选择结束时间'
      }
    },
    config: {
      projectPublicMemberConfig: '全部项目可见用户'
    }
  },
  sysTag: {
    common: {
      title: '标签管理',
      addTag: '创建新标签',
      isDeleteTag: '是否确认删除改标签？',
      pleaseInputValue: '输入标签值'
    },
    tagInfo: {
      tableName: '标签',
      businessType: '所属业务',
      isGlobal: '是否全局标签',
      tagValue: '标签值',
      tagColor: '标签颜色'
    },
    businessType: {
      customer: '客户',
      supplier: '供应商',
      serviceOrder: '服务订单',
      saleOrder: '销售订单',
      purchase: '采购订单'
    }
  },
  commonBusinessType: {
    purchaseReceiptInventory: '库存引用采购收货',
    salesReturnInventory: '库存引用销售退货',
    outOrderInventory: '库存引用委外订单',
    purchaseReturnInventory: '库存引用采购退货',
    purchaseReceiptAddInventory: '入库通知的采购收货引用添加入库单',
    selfViewInventory: '库存模块本身引用查看'
  },
  expressRecord: {
    business: '关联业务',
    mailingDate: '寄件日期',
    expressCompany: '快递公司',
    expressNumber: '快递单号',
    currency: '货币',
    freight: '运费',
    recipientName: '收件人姓名',
    recipientPhone: '收件人电话',
    recipientCompany: '收件人公司',
    recipientAddress: '收件人详细地址',
    deleteExpressRecordTips: '确认【删除】该快递信息',
    addExpressRecord: '添加快递信息',
    editExpressRecord: '编辑快递信息',
    viewExpressRecord: '查看快递信息',
    pleaseChooseMailingDate: '请选择寄件日期',
    pleaseChooseExpressCompany: '请选择快递公司',
    pleaseChooseExpressNumber: '请输入快递单号',
    pleaseChooseCurrency: '请选择货币'
  },
  express: {
    config: {
      goOauth: '去往授权页',
      jdSetting: '京东参数设置',
      customerName: '商家名称',
      customerCode: '商家编码'
    },
    order: {
      senderName: '姓名',
      senderMobile: '手机号码',
      senderTel: '固定电话',
      senderAddress: '所在地区',
      receiveName: '姓名',
      receiveMobile: '手机号码',
      receiveTel: '固定电话',
      receiveAddress: '所在地区'
    }
  },
  todoList: {
    businessType: '待办业务类型',
    businessCode: '业务编码',
    applicant: '申请人',
    applicantDate: '申请日期',
    currentNode: '当前节点',
    businessStatus: '业务状态',
    processStatus: '处理状态',
    notHandled: '未处理',
    handled: '已处理',
    salesOrderAudit: '销售订单审核',
    startDate: '申请日期起',
    endDate: '申请日期止',
    queryKeyword: '业务编码，申请人'
  },
  mall: {
    photo: {
      addPhoto: '添加简介相册',
      addPhotoTip: '请上传图片',
      img: '图片',
      addFile: '添加资质文件'
    },
    marketing: {
      coupons: '优惠券',
      couponsType: '优惠券类型',
      couponsName: '优惠券名称',
      couponsImg: '优惠券图片',
      distributionTotal: '发放总量',
      distributionTime: '发放时间',
      distributionStartEnd: '发放起止时间',
      privately: '私有',
      distributionObject: '发放对象',
      pleaseChooseDistributionObject: '请选择发放对象',
      distributionObjectTip: '确定将此优惠券发放给选择的对象吗',
      receiveObject: '领取对象',
      distributionSuccess: '发放成功',
      distribution: '发放',
      chooseMember: '选择会员',
      chooseCustomer: '选择客户',
      memberName: '会员名称',
      customerName: '客户名称',
      distributionCoupons: '发放优惠券',
      haveReceive: '已领取',
      remainingNumber: '剩余数量 ',
      usage: '使用率',
      getWay: '领取方式 ',
      fullVolumeReduction: '满减券',
      discountCoupons: '折扣券',
      nolimit: '不限制',
      limit: '限制',
      spend: '消费满 ',
      yuan: '元',
      reduction: '减',
      enjoy: '享',
      fold: '折',
      limitNumber: '限制张数',
      zhang: '张',
      afterGet: '获得后 ',
      withinDate: '日期内',
      limitDay: '限制天数',
      validFor: '内有效 ',
      day: '天',
      no: '无',
      chooseTime: '选择时间',
      takeTimeLimit: '使用时间限制',
      sort: '排序值',
      perPersonQuantity: '每人领取数量',
      claimantLimit: '领取人限制',
      theCustom: '自定义 ',
      eachLimitGet: '每人限领',
      allCustomer: '全部客户',
      designatedCustomerCategory: '指定客户分类',
      customer: '客户',
      allMember: '全部会员',
      designatedMemberLevel: '指定会员等级',
      member: '会员',
      chooseCustomerCategory: '选择客户分类',
      chooseMemberLevel: '选择会员等级',
      chooseGoodsCategory: '选择商品分类',
      goodsUseLimit: '商品使用限制',
      allGoods: '全部商品',
      allowFollowingGoodsUse: '允许以下商品使用',
      noAllowFollowingGoodsUse: '不允许以下商品使用',
      allowFollowGoodsCategoryUse: '允许以下商品分类使用',
      useDirections: '使用说明',
      addCoupons: '添加优惠券',
      editCoupons: '编辑优惠券',
      viewCoupons: '查看优惠券',
      couponsImgTip: '图片比例1:1',
      pleaseCouponsName: '请填写优惠券名称',
      pleaseCouponsImg: '请填写上传优惠券图片',
      release: '发布',
      releaseTip: '确定发布吗 ',
      releaseSuccess: '发布成功',
      releaseStatus: '发布状态',
      noRelease: '未发布',
      yesRelease: '已发布',
      soldOut: '下架',
      soldOutTip: '确定下架吗 ',
      soldOutSuccess: '下架成功 ',
      soldOutEd: '已下架',
      pleaseWriteCouponsType: '优惠券类型，请填写消费满减金额',
      pleaseWriteDistributionQuantity: '发放总量，请填写限制张数',
      pleaseWriteUsageTime: '使用时间限制，请选择时间',
      pleaseWriteUsageLimitDays: '使用时间限制，请填写限制天数',
      pleaseWritePerPersonQuantity: '每人领取数量，请填写每人限领张数',
      pleaseDesignatedCustomerCategory: '领取人限制，请选择指定客户分类',
      pleaseDesignatedMemberLevel: '领取人限制，请选择指定会员等级',
      pleaseAllowGoodsUse: '商品使用限制，请添加"允许以下商品使用"的商品',
      pleaseNoAllowGoodsUse: '领取人限制，请添加"不允许以下商品使用"的商品',
      pleaseAllowGoodsSortUse: '领取人限制，请添加"允许以下商品分类使用"的商品分类',
      pleaseDistributionTime: '发放时间，请选择起止时间',
      limitDetail: '领取明细',
      useDetail: '使用明细',
      receiveName: '领取人名称',
      receiveTime: '领取时间',
      useName: '使用人名称',
      useTime: '使用时间',
      receiveMember: '领取会员',
      receiveCustomer: '领取客户',
      voucher: '代金券',
      voucherType: '使用方式',
      voucherTitle: '代金券名称',
      voucherAmount: '面值',
      voucherImage: '封面',
      voucherDesc: '使用说明',
      oneTime: '单次使用',
      moreTime: '不限次数',
      limitTime: '限制次数',
      voucherImgTip: '图片比例1：1',
      noLimit: '不限制',
      getDayAfter: '获得后',
      dateRange: '日期内',
      addVoucher: '添加代金券',
      editVoucher: '编辑代金券',
      viewVoucher: '查看代金券',
      voucherDistributionCount: '发放数量',
      voucherOrderCount: '使用数量',
      cardQuantity: '已生成卡数量',
      receivedCardQuantity: '已绑定卡数量',
      phoneImportTitle: '代金券发放',
      phoneImportExchangeVoucher: '兑换券发放',
      pleaseSelectMemberVoucher: '请选择代金券',
      geneQrCodeSuccessTips: '代金券二维码正在后台生成，请稍后重新查询生成情况',
      geneQrCode: '生成二维码',
      cardBindMember: '绑定会员',
      cardBindMemberPlease: '请选择要绑定的会员',
      phoneNumber: '手机号',
      memberVoucherDeductAmount: '已使用金额',
      distributeDetails: '发放明细',
      distributeDetailsQuery: '会员名称 手机号',
      isUsedStatus: '是否使用',
      notUsed: '未使用',
      hasUsed: '已使用',
      isExpiredStatus: '是否过期',
      notExpired: '未过期',
      hasExpired: '已过期',
      isNotCompletedVoucher: '只显示余额大于0的代金券',
      startDate: '开始时间',
      endDate: '结束时间',
      voucherTitlePlease: '请输入代金券名称',
      voucherAmountPlease: '请输入代金券面值',
      voucherImagePlease: '请上传代金券封面',
      distributeCard: '卡券',
      memberImport: '会员发放',
      memberImportTitle: '会员发放代金券',
      selectMember: '选择会员',
      pleaseChooseMember: '请选择会员',
      voucherDistribute: '发放',
      phoneImport: '手机号发放',
      viewCard: '卡券发放',
      viewVoucherCard: '查看代金券卡',
      distributeCardQuery: '卡号 领取人名称 手机号',
      cardNumber: '卡号',
      cardSecret: '卡密',
      notReceived: '未领取',
      hasReceived: '已领取',
      isReceivedStatus: '是否领取',
      geneVoucherCard: '生成卡券',
      geneVoucherCardQuantity: '生成数量',
      voucherCardStatus: '领取状态',
      voucherQrCode: '领取码',
      voucherDetail: '基本信息',
      voucherCardDetail: '卡券明细',
      voucherMemberDetail: '领取明细',
      voucherDeductionDetail: '订单详情',
      queryMallOrderStatus: '订单状态',
      queryMallOrderKeywords: '订单编号 下单人 手机号',
      deductFreightConfig: '是否可抵扣运费',
      userLimitConfig: '使用身份限制',
      userNoLimit: '不限制',
      userLimit: '指定身份使用',
      addCustomerSort: '添加客户分类',
      addMemberLevel: '添加会员等级',
      userLimitType: '身份类型',
      customerSortOrMemberLevel: '客户分类/会员等级',
      userLimitConfigError: '请正确配置使用身份限制，选择客户分类/会员等级',
      exchangeVoucher: '兑换券',
      exchangeVoucherTitle: '兑换券名称',
      isExchanged: '是否已兑换',
      addExchangeVoucher: '添加兑换券',
      editExchangeVoucher: '编辑兑换券',
      viewExchangeVoucher: '查看兑换券',
      batchSetUsageTime: '使用期限设置',
      pleaseSelectData: '请勾选列表数据',
      voucherShareRecord: '代金券赠送记录',
      exchangeVoucherShareRecord: '兑换券赠送记录',
      pointGoods: '积分兑换商品',
      remainingConvertibleQuantity: '剩余可兑换数量',
      customerSort: '客户分类',
      memberLevel: '会员等级',
      notLimit: '不限制',
      includeCustomerSort: '客户分类可兑换',
      excludeCustomerSort: '客户分类不可兑换',
      followGoods: '同商品设置',
      none: '包邮',
      includeMemberLevel: '会员等级可兑换',
      excludeMemberLevel: '会员等级不可兑换',
      onShelf: '上架',
      onShelfEd: '已上架',
      onShelfTips: '确定上架吗？',
      onShelfSuccess: '上架成功',
      addMallPointGoods: '添加积分商品',
      editMallPointGoods: '编辑积分商品',
      viewMallPointGoods: '查看积分商品',
      freightConfig: '运费设置',
      buyLimitConfig: '限购设置',
      customerLimitConfig: '客户兑换设置',
      memberLimitConfig: '会员兑换设置',
      appointCustomerCategoryCan: '指定客户分类可兑换',
      appointCustomerCategoryCannot: '指定客户分类不可兑换',
      appointMemberLevelCan: '指定会员等级可兑换',
      appointMemberLevelCannot: '指定会员等级不可兑换',
      pleaseSelectCustomerCategory: '客户兑换设置，请选择客户分类',
      pleaseSelectMemberLevel: '会员兑换设置，请选择会员等级',
      residueStock: '现库存',
      price: '价格',
      pointPrice: '积分价',
      point: '积分',
      batchDelete: '批量删除',
      batchOnShelf: '批量上架',
      batchSoldOut: '批量下架'
    },
    perfer: {
      limitPerfer: '限时特惠',
      perferName: '特惠名称',
      notTo: '未进行',
      ongoing: '进行中',
      end: '已结束',
      startTime: '开始时间',
      endTime: '结束时间',
      ends: '结束',
      isOver: '是否结束？',
      isOverSuccess: '结束成功',
      goods: '商品',
      goodsSort: '商品分类',
      addGoods: '添加商品',
      addGoodsSort: '添加商品分类',
      pleasePreferName: '请填写特惠名称',
      pleaseStartTime: '请填写特惠起止时间',
      pleaseEndTime: '请填写结束时间',
      preferAmount: '优惠后金额',
      discount: '折扣',
      choosePreferGoods: '选择特惠商品',
      goodsList: '商品列表',
      customerIsParticipate: '客户参与设置',
      participate: '参与',
      participateTip: '提示：参与后客户在活动期间商品价格按照活动价展示，不参与商品价格按自身价格展示',
      addGoodsTip: '请添加特惠商品',
      dataCompare: '开始时间不能大于结束时间',
      downPrice: '降价',
      application: '应用',
      goodsDownPrice: '有商品降价调整后价格为0，请注意是否需要调整',
      addPrefer: '添加限时特惠',
      viewPrefer: '查看限时特惠',
      editPrefer: '编辑限时特惠',
      release: '发布',
      saveAndRelease: '保存并发布',
      draft: '草稿',
      preferTime: '特惠起止时间'
    },
    giftBox: {
      addGroup: '新增分组',
      viewPlanGift: '查看礼盒方案',
      planName: '方案名称',
      planImage: '方案主图',
      planSize: '方案尺寸',
      visible: '可见范围',
      period: '有效期',
      addPlan: '添加礼盒方案',
      specifiedCustomerSort: '指定客户分类',
      specifiedCustomer: '指定客户',
      specifiedArea: '指定区域',
      specifiedLabel: '指定标签',
      memberLevel: '指定会员等级',
      specifiedMember: '指定会员',
      childNumberLimit: '子件总数量限制',
      childLimitNumber: '子件限制数量',
      childNumberTip: '提示：启用限制后输入限制值，在礼盒子件选择时，超过该限制值不能再选择新的子件',
      orderNumberLimit: '下单数量限制',
      userPrice: '用户身份对应价格',
      priceLevel: '价格等级',
      theCustom: '自定义',
      allowedRepeatOrder: '允许重复下单配置',
      allow: '允许',
      noAllow: '不允许',
      priceType: '子件价格获取类型',
      sort: '排序',
      planText: '方案说明',
      memberDiscount: '会员折扣配置',
      customerDiscount: '客户折扣配置',
      fullMinus: '满额减',
      fullFold: '满额折',
      FullAmountMinus: '满量减',
      rangeAmount: '区间范围(含最小区间)',
      rangeDiscountAmount: '达到区间范围的满减金额',
      rangeDiscount: '达到区间范围的折扣',
      memberFreightType: '会员运费配置',
      customerFreightType: '客户运费配置',
      packageQuota: '满额包邮',
      packageNumber: '满量包邮',
      noPackage: '包邮',
      freightTemplate: '运费模板',
      limitValue: '额度值',
      numberValue: '数量值',
      giftBoxConfig: '礼盒包装',
      productName: '礼盒关联商品',
      giftBoxName: '礼盒名称',
      giftBoxImage: '礼盒图片',
      giftBoxAmount: '礼盒金额',
      goodsName: '商品名称',
      goodsSkuImg: '商品sku图片',
      unitPrice: '销售价',
      specsValue: '规格',
      virtual: '虚拟库存',
      willChooseChild: '必选子件',
      quantity: '数量',
      ableChooseChild: '可选子件',
      groupType: '分组类型',
      categoryGroup: '品类分组',
      numberGroup: '数量分组',
      groupName: '分组名称',
      draft: '草稿',
      enable: '已发布',
      planStatus: '方案状态',
      creatTime: '创建时间',
      chooseGiftMaterial: '选择礼盒包装',
      enableSuccess: '启用成功',
      editPlan: '编辑礼盒方案',
      freightTemplateTip: '提示:运费模板必选，未达到包邮的数值，将按照运费模板计算运费',
      visibleTip: '可见范围:（客户，会员）请选择对应的限制类型',
      periodConfigTip: '有效期: 请选择有效期起止时间',
      periodStartEnd: '有效期起止时间',
      orderNumberTip: '提示：填写左区间（右区间为0），订单数量起订多少个；填写右区间（左区间为0），限购多少个；填写左右区间，下单数量在区间数量之内，左区间为最小数量，右区间为最大数量',
      salesPriceLevel: '销售价格等级',
      priceTypeTip: '价格类型:请选择价格等级',
      giftBoxTip: '礼盒配置: 请选择礼盒包装',
      requireChildTip: '必选子件,可选子件中选择其中一个添加商品',
      customerFreightTemplatTip: '客户运费类型: 请选择客户运费模板',
      memberFreightTemplatTip: '会员运费类型: 请选择会员运费模板',
      noDiscount: '无折扣',
      setFreightTemplat: '运费模板',
      addDiscountTip: '请先填写完整区间范围(区间范围不能都为0或空)',
      addDiscountOneTip: '参数右区间请先填写大于0的数值形成闭环，再添加参数',
      addDiscountTwoTip: '区间范围左区间不能为空',
      setOut: '下架',
      setOutTip: '确定下架吗',
      release: '发布',
      setOutSuccess: '下架成功',
      planImageTip: '方案主图不能为空',
      planSizeTip: '方案尺寸不能为空',
      requireGoodsPriceTip: '必选子件: 未填写销售价格',
      willGoodsPriceTip: '可选子件: 未填写销售价格',
      groupCategoryTip: '可选子件: 选择品类分组时，填写数量不能大于添加商品的数量',
      groupQuantityTip: '可选子件：数量的左区间不能大于添加商品的数量',
      childNumberLimitTips: '子件总数量限制不能小于选择的子件数量(可选子件分组填写最小数量之和+必选子件选择的商品数量)',
      groupTypeTip: '分组类型:选择品类，是在添加的商品中选择几种商品；选择数量，是在添加的商品中选择几件商品',
      groupNameTip: '可选子件:请填写分组名称',
      groupGoodsTip: '可选子件:请添加商品',
      planNamePlease: '请输入方案名',
      planImagePlease: '请上传方案主图',
      planSizePlease: '请填写方案尺寸',
      goodsEmpty: '必选子件和可选子件不能同时为空',
      memberDiscountEmptyTip: '会员折扣未添加设置',
      customerDiscountEmptyTip: '客户折扣未添加设置',
      orderNumberTipNull: '下单数量限制:下单数量不能为空,并且左右区间不能同时为0',
      scopeOrderNumber: '下单数量限制:填写范围时，左区间不能大于右区间',
      childNumberLimitTip: '子件总数量限制：请填写子件限制数量(不能为空或0)',
      orderNumberLeftTip: '下单数量限制：下单数量限制填写左区间',
      orderNumberRightTip: '下单数量限制：下单数量限制填写右区间',
      groupNumberTip: '提示: 填写左区间（右区间为0），固定可选子件商品的数量；填写左右区间，可选子件商品数量在填写范围之间，左区间为最小数量，右区间为最大数量',
      categoryGroupNumberTip: '提示：左区间为最小选择商品种类数（0-可不选品类商品）；右区间为最大选择商品种类数（0-不限制选择品类商品数量）',
      quantityGroupNumberTip: '提示：左区间为最小选择商品数（0-可不选商品）；右区间为最大选择商品数（0-不限制选择商品数量）',
      numberNotNull: '可选子件:选择数量分组时，数量不能为空或左右区间都为0',
      numberCategoryNotNull: '可选子件:选择品类分组时，数量不能为空或0',
      numberGroupRightTip: '可选子件：选择数量分组时，右区间不能为空',
      numberNotZero: '可选子件:数量不能为0',
      groupNumberCompareTip: '可选子件: 数量的左区间不能大于右区间',
      groupMaxQuantityCompareTip: '可选子件：选择品类分组时，数量的右区间不能大于商品总数',
      boxAmountTip: '礼盒包装:礼盒金额不能为空',
      boxNameTip: '礼盒包装: 礼盒名称不能为空',
      boxImageTip: '礼盒包装:礼盒图片不能为空',
      requireQuantityTip: '必选子件: 数量不能为空',
      memberDiscountTipOne: '会员折扣配置：区间范围左区间不能为空',
      memberDiscountTipTwo: '会员折扣配置：区间范围右区间不能为空',
      memberDiscountTipThree: '会员折扣配置：折扣金额（折扣）不为空',
      memberDiscountTipFour: '会员折扣配置：区间范围左区间不能大于右区间',
      memberDiscountTipFive: '会员折扣配置：区间范围左区间不能为空和0',
      memberDiscountTipSix: '会员折扣配置：区间范围不能为空或0',
      memberDiscountTipSeven: '会员折扣配置：区间范围请闭环添加,不要交叉',
      customerDiscountTipOne: '客户折扣配置：区间范围左区间不能为空或0',
      customerDiscountTipTwo: '客户折扣配置：区间范围右区间不能为空',
      customerDiscountTipThree: '客户折扣配置：折扣金额（折扣）不为空',
      customerDiscountTipFour: '客户折扣配置：区间范围左区间不能大于右区间',
      customerDiscountTipFive: '客户折扣配置：区间范围左区间不能为空和0',
      customerDiscountTipSix: '客户折扣配置：区间范围不能为空或0',
      customerDiscountTipSeven: '客户折扣配置：区间范围请闭环添加,不要交叉',
      discountTips: '提示：添加多个参数区间范围请闭环填写(示例：1-20,20-40)，闭环区间内左区间不能大于右区间。最后一个参数右区间为0（示例：20-0），代表大于等于20。折扣填写范围为0-10',
      deleteCoupe: '删除分组',
      moneyPackage: '元包邮',
      numberPackage: '件包邮',
      full: '满',
      fullFolds: '满足',
      yuan: '元',
      ge: '个',
      jian: '减',
      da: '打',
      zhe: '折',
      releaseTime: '发布时间',
      noQuantityPrefix: '不限制数量',
      minQuantityPrefix: '最少购买',
      minQuantitySubfix: '件商品',
      maxQuantityPrefix: '最多购买',
      maxQuantitySubfix: '件商品',
      canSelectPrefix: '可选',
      canSelectSubfix: '件商品',
      deliveryDesc: '交期说明',
      unLimitStock: '允许超库存下单'
    },
    live: {
      liveNoConfig: '直播号设置',
      liveNo: '直播房间号',
      pleaseWriteLiveNo: '请填写直播房间号'
    },
    pick: {
      addTitle: '添加自提点',
      editTitle: '编辑自提点',
      viewTitle: '查看自提点',
      contactName: '联系人',
      mobile: '联系电话',
      areaIds: '请输入区域地址',
      addressTips: '请输入详细地址',
      contactNameTips: '请输入联系人',
      mobileTips: '请输入联系电话',
      deleteTips: '是否确认删除该自提点?',
      pickAdress: '自提点详细地址',
      warehousePlease: '请选择发货仓库'
    },
    exception: {
      categoryImagePlease: '分类图片不能为空',
      categoryNamePlease: '分类名不能为空',
      keyValuePlease: '键值不能为空',
      sortPlease: '排序不能为空'
    },
    indexDecorate: {
      tips: '提示文字',
      textAlign: '文字对齐',
      backgroundColor: '背景颜色',
      iconColor: '图标颜色',
      textColor: '文字颜色',
      radius: '圆角'
    },
    config: {
      mallName: '商城名称',
      mallLogo: '商城LOGO',
      mallLogoTip: '图片为正方形，建议尺寸800*800，用于商城首页分享的系统默认宣传图',
      sellOutIcon: '售罄图标',
      sellOutIconTip: '建议尺寸比例为1:1，用于商品售罄后的提示图标',
      mallBrief: '商城简介',
      loadIcon: '加载图标',
      loadIconTip: '图片为正方形，建议尺寸200*200，用于网络较慢图片未加载出来时的占位图',
      mallPicturePreview: '商城图片预览',
      isShowVirtualInventory: '是否显示虚拟库存',
      isAutoDelivery: '到店自提',
      phone: '客服电话',
      phoneTip: '请填写客服电话',
      companyTip: '所属公司有变更，将关闭所有支付方式，需重新配置支付方式',
      onlineMallPrice: '在线商城价格类型',
      isBindPhone: '是否强制绑定手机号',
      isBindPhoneTip: '开启后将在提交订单时强制要求绑定手机号',
      pptCoverPicture: 'PPT封面图片',
      pptCoverPictureTip: '建议尺寸比例为16:9，且不小于 1024 * 768，用于生成多商品海报PPT封面',
      mallStockIncludePurchase: '可用库存是否包含在途',
      include: '包含',
      exclude: '不包含',
      dealAnnouncement: '成交播报',
      open: '开启',
      close: '关闭',
      salesDisplay: '是否显示销量',
      show: '显示',
      noShow: '不显示',
      salesSetting: '销量设置',
      actualSales: '实际销量',
      virtualSales: '虚拟销量',
      viewStock: '显示库存',
      viewSales: '显示销量',
      mallOrderGeneCustomCode: '商城订单生成自定义编号',
      mallOrderGeneCustomCodeTips: '自定义编号仅用于导入第三方系统',
      customerFreightTips: '客户运费提示',
      customerFreightAfterOrderPay: '客户运费后结',
      customerFreightAfterOrderPayTips: '当开启客户运费后结时，客户下单时，可以选择运费在下单之后再结算',
      mallSmsLoginEnable: '短信验证码登录',
      mallSmsLoginEnableTips: '需要联系平台进行短信充值',
      mallWxPhoneLoginEnable: '微信手机号快捷登录',
      mallWxPhoneLoginEnableTips: '需要在微信公众平台中【付费管理】中购买【手机号快速验证组件】',
      voucherGiftTime: '代金券赠送领取时效',
      orderRewardType: '下单奖励',
      noneReward: '无',
      voucherReward: '代金券',
      orderRewardVoucher: '奖励代金券',
      standardAmountReward: '固定金额',
      orderAmountRateReward: '按订单金额比例',
      orderRewardAmountType: '奖励金额计算方式',
      orderRewardAmountExclude: '订单金额',
      rewardAmountRange: '订单金额区间(左不包含，右包含，右为0代表无穷)',
      rewardAmount: '奖励金额',
      addMallOrderReward: '添加自定设置',
      excludeFreight: '排除运费',
      excludeVoucher: '排除代金券支付部分',
      excludePoint: '排除积分支付部分',
      orderRewardAmountRate: '奖励订单比例',
      orderRewardAmountPrecision: '奖励计算精度',
      editRewardConfig: '编辑下单奖励奖励',
      orderRewardVoucherPlease: '请选择奖励代金券',
      customerSortPlease: '请选择客户分类',
      memberLevelPlease: '请选择会员等级',
      orderRewardAmountRangePlease: '请填写订单范围奖励金额',
      enableKf: '是否开启在线客服',
      appletKf: '小程序客服',
      weworkKf: '企业微信客服',
      weworkCorpIdPlease: '请输入企业ID',
      weworkKfIdPlease: '请输入客服ID',
      goodsParamConfig: '商品参数设置',
      goodsStockConfig: '商城库存设置',
      viewEnableStockTotal: '在库可用库存（总数）',
      viewEnableStockWarehouseTotal: '在库可用库存（按仓库）',
      viewPurchaseStockTotal: '在途可用库存（总数）',
      viewPurchaseStockTotalAboutTime: '预计到货时间（取最早的采购订单到货时间）',
      viewPurchaseStockWarehouseTotal: '在途可用库存（按仓库',
      viewPurchaseStockWarehouseAboutTime: '预计到货时间（取最早的采购订单到货时间）',
      viewStockConfig: '库存显示设置'
    },
    dealConfig: {
      autoCompleteTime: '订单自动完成时间',
      autoCompleteTimePrefix: '订单全部发货后',
      autoCompleteTimeSuffix: '天内未确认将自动完成',
      autoCompleteTimeTips: '订单全部发货后0-30天内自动完成',
      unpaidOrder: '未付款订单',
      automaticReceiving: '自动收货',
      notAutomaticReceiving: '不自动收货',
      customizeDeliveryTime: '自定义收货时间',
      tradingEnhance: '交易增强',
      rradingEnhanceTip: '开启后进入商城首页时会自动弹出未支付订单提示。',
      invoiceSetting: '发票设置',
      paperInvoice: '纸质发票',
      electronicInvoice: '电子发票',
      orderEvaluate: '订单评价',
      accordingEvaluation: '显示评价',
      evaluationAudit: '评价审核',
      takeUnpaidOrder: '拍下未付款订单 ',
      minute: '分钟',
      takeUnpaidOrderText: '内未付款，自动关闭订单',
      takeUnpaidOrderTip: ' 订单取消时间必须在10-1440分钟之间',
      theDeliver: '发货',
      day: '天',
      theDeliverText: '后自动收货',
      theDeliverTip: '自动确认收货时间必须在7-30天之间。',
      undeliveredRefundReason: '未收到货退款原因',
      deliveredRefundReason: '收到货退款原因',
      exChangeReason: '换货原因',
      refundReason: '退款原因',
      reasonTip: '每一行表示一个退换货原因',
      noInPackage: '不参与满额包邮的地区',
      customerPackage: '自定义满额包邮设置',
      yuan: '元',
      package: '包邮',
      discountAmout: '商品优惠后金额满',
      packageConfig: '订单满额包邮设置',
      pleaseWritePackageAmount: '请填写满额包邮金额',
      commissionExtractToWxConfig: '佣金提现到微信零钱',
      commissionExtractToWxConfigTip: '启用佣金提现到微信零钱条件：商城连续交易记录满30天',
      allowViewAllOrder: '允许查看所有订单',
      allowViewAllOrderTip: '设置后，该用户可以在【在线商城-订单管理】中查看全部的订单，含客户订单和会员订单，如果用户的数据权限为【所有数据】，则无需单独设置该项',
      allowViewMemberOrder: '允许查看会员订单',
      allowViewMemberOrderTip: '设置后，该用户可以在【在线商城-订单管理】中查看会员提交的订单，如果用户的数据权限为【所有数据】，则无需单独设置该项'
    },
    pointConfig: {
      pointName: '积分单位名称',
      pointNamePrompt: '请输入单位名称',
      pointConversionRatio: '积分兑换比例',
      point: '积分',
      yuan: '元',
      pointOrderRatio: '订单积分支付比例',
      pointOrderRatioPrompt: '请输入支付比例',
      pointOrderRatioTips: '可输入0~100的数值， 默认：0 即，不可以使用积分',
      orderPointRewardType: '下单积分奖励',
      none: '无',
      theOrderAmountRatio: '按订单金额比例',
      thePaymentAmountRatio: '按实际支付金额比例',
      orderAmountRatio: '订单金额比例',
      paymentAmountRatio: '实际支付金额比例',
      orderPointRewardMax: '积分奖励上限',
      orderPointRewardMaxPrompt: '请输入积分奖励上限',
      orderPointRewardMaxTips: '默认0，表示无上限',
      orderPointRewardPrecision: '积分奖励精度',
      one: '个位',
      ten: '十位',
      hundreds: '百位',
      thousands: '千位',
      orderPointRewardPrecisionTips: '如果按个位，则表示256* 10% 按个位取整，=25，即表示可以获得25积分',
      orderPointRewardPrecisionTipss: '如果按十位，则表示256* 10% 按十位取整，=20，即表示可以获得20积分 .依次类推',
      orderPointRewardTypeMember: '下单积分奖励（会员）',
      orderPointRewardTypeCustomer: '下单积分奖励（客户）',
      customerPointDeductEnable: '客户订单支付时使用积分',
      memberPointDeductEnable: '会员订单支付时使用积分'
    },
    afterSalesConfig: {
      afterSalesApply: '售后维权申请',
      notAfterSales: '完成订单后无售后',
      customAfterSales: '自定义时间',
      refundInstructions: '退款说明',
      refundInstructionsTip: '用户在申请退款页面的说明',
      singleReturnExchange: '单品退换货',
      singleReturnExchangeTip: '开启后，订单支持单件商品退换，订单优惠金额按比例分摊至每件商品',
      timeoutCancelActivist: '超时取消维权',
      orderFinish: '订单完成',
      orderFinishText: '内可发起售后维权',
      orderFinishTip: '售后维权时间必须在订单完成0-30天之间。',
      agreeReturnExchange: '商家同意退货退款/换货申请后',
      agreeReturnExchangeText: '内买家未提交快递单号的，自动取消售后维权',
      agreeReturnExchangeTip: '取消售后维权时间必须在1-30天之间。',
      closeTime: '售后订单关闭',
      notAutoCloseTime: '退货/退款后不关闭',
      autoCloseTime: '自定义时间',
      autoCloseTimePrefix: '退货/退款',
      autoCloseTimeText: '后自动关闭',
      closeTimeTips: '售后订单退货/退款完成后1-30天内自动关闭',
      orderCompany: '订单固定所属公司'
    },
    news: {
      coverPicture: '封面图片',
      sort: '资讯排序',
      sortTip: '请输入资讯排序',
      title: '资讯标题',
      titleTip: '请输入资讯标题',
      state: '显示状态',
      digest: '资讯摘要',
      digestTip: '提示：未填写摘要，默认取资讯内容前200个字符',
      content: '资讯内容',
      createTime: '创建时间',
      addNews: '添加资讯',
      editNews: '编辑资讯',
      viewNews: '查看资讯',
      coverPictureTip: '请上传封面图片',
      coverPictureTips: '提示：图片长宽比例 2：1 , 最小尺寸 100像素 * 50像素',
      userLimitConfig: '咨询可见范围设置',
      userNoLimit: '不限制',
      userLimit: '指定身份可见'
    },
    packageRuleConfig: {
      sort: '规则排序',
      ruleName: '包邮规则',
      ruleNameTip: '请填写包邮规则',
      activeType: '作用对象',
      rangeType: '作用范围',
      isFullPackageType: '是否参与满额包邮',
      fullPackageAmount: '满额包邮金额',
      addPackageRule: '添加规则',
      editPackageRule: '编辑规则',
      viewPackageRule: '查看规则',
      all: '所有',
      allCustomer: '全部客户',
      appointCustomerCategory: '指定客户分类',
      appointCustomer: '指定客户',
      allMember: '全部会员',
      appointMemberLevel: '指定会员等级',
      appointMember: '指定会员',
      notFullPackage: '否',
      isFullPackage: '是',
      customer: '客户',
      member: '会员',
      defaultRule: '默认规则',
      sortTips: '数字越小, 排名越靠前, 默认排序为规则修改时间'
    },
    announcementManage: {
      sort: '公告排序',
      title: '公告标题',
      state: '公告状态',
      content: '公告内容',
      createTime: '发布时间',
      addAnnouncement: '添加公告',
      editAnnouncement: '编辑公告',
      viewAnnouncement: '查看公告'
    },
    payConfig: {
      paymentMonthAccount: '挂账月结资金账户',
      paymentMonthStatement: '挂账月结',
      advancePayment: '定向预收款支付 ',
      paymentMonthStatementTip: '挂账月结只支持绑定系统客户的会员，且绑定客户时开启了挂账月结设置',
      weChatApplet: '微信小程序',
      weChatPay: '微信支付',
      remainPay: ' 余额支付',
      balancePaymentTip: '余额支付只支持绑定系统客户的会员，余额数据来源于预收款数据。会员也需要同时开通余额支付才能使用',
      rechargeOfflinePayment: '余额充值线下转账',
      balanceRechargeAccount: '线下转账充值可用资金账户',
      deliveryCash: '货到付款',
      deliveryCashTip: '如果需支持货到付款，也需要将商品设置成货到付款.',
      offlinePayment: '线下转账',
      offlineAccount: '资金账户',
      remainPayAccount: '余额支付资金账户',
      offlinePaymentTip: '如果需支持线下付款，也需要将商品设置成线下付款',
      payType: '支付类型',
      merchantName: '商户名称',
      serviceProviderOfficial: '服务商公众号 (AppId)',
      serviceProviderPayMerchant: '服务商支付商户号 (Mch_Id)',
      publicAppID: '公众号/小程序 (AppID)',
      payMerchant: '支付商户号 (Mch_Id)',
      payKey: '支付密钥',
      certificateFile: 'CERT文件证书(P12格式)',
      keyFile: 'KEY密钥文件',
      rootFile: 'ROOT文件',
      paymentName: '支付名称',
      writePaymentName: '请输入支付名称',
      writePaymentTemplate: '请选择支付模板',
      paymentMethod: '支付类型',
      templateName: '模板名称',
      time: '创建时间',
      weChatPayChild: '微信支付子商户',
      alipayPay: '支付宝支付',
      edit: '支付模板编辑',
      add: '支付模板添加',
      weChatPayType: '请选择支付类型',
      accountId: '资金账户',
      accountIdTips: '请选择资金账户',
      offlineAccountIdTips: '请选择线下付款资金账户',
      remainPayAccountIdTips: '请选择余额付款资金账户',
      offlineAccountPlease: '请选择线下支付资金账户',
      remainPayAccountPlease: '请选择余额支付资金账户',
      monthPayAccountPlease: '请选择挂账月结资金账户',
      weChatCertNotEmpty: '请上传CERT证书证书',
      directAdvanceReceiptPayment: '定向抵扣',
      directAdvanceReceiptPaymentTip: '定向抵扣只支持绑定系统客户的会员，且商品在定向抵扣类型范围内',
      directAdvanceReceiptCompanyIdPlease: '请选择所属公司',
      accountName: '资金账户名称',
      account: '账号',
      payKeyV2: '支付密钥V2',
      payKeyV3: '支付密钥V3',
      otherPayment: '其它支付',
      rechargeAdvanceReceiptType: '充值预收款类型',
      rechargeAdvanceReceiptTypePlease: '请选择充值预收款类型',
      balanceRecharge: '余额充值',
      balanceRechargeSecondConfirmEnable: '财务二次确认',
      balanceRechargeSecondConfirmEnableTips: '财务二次确认：无论微信支付还是线下转账都需要财务确认。如果微信转账被驳回，则款项原路退回'
    },
    paramConfig: {
      Parameters: '参数配置',
      SmallProgram: '小程序APPID',
      SmallSecret: '小程序APP Secret',
      paramConfig: '支付设置',
      Jump: '立即前往设置',
      Skip: '跳过当前小程序',
      add: ' 添加你当前小程序站点需要跳转的Appid 信息可在装修中进行配置跳转小程序。',
      Gdecoration: '前往装修配置',
      SmallPrograms: '小程序名单',
      WeChatSmallProgram: '微信小程序',
      UnderlyingSettings: '基础设置',
      MaintainSmallProgram: '维护小程序',
      common: {
        SmallProgram: '请输入小程序APPID',
        SmallSecret: '请输入小程序APP Secret'
      }
    },
    registerConfig: {
      register: '注册登录',
      registerTip: '用户访问商城时，是否需要授权/登录后才可进行流程商城。',
      mandatoryLogin: '强制授权登录',
      mandatoryLoginTip1: '系统默认关闭强制授权登录，当触发加入购物车、立即购买、提交表单、领取优惠券时需要获取用户用户登录信息。',
      mandatoryLoginTip2: '开启强制授权登录时，用户访问商城必须授权后才可进行商城浏览及下单操作。',
      logWay: '手机浏览器H5 登录方式',
      logWayOne: '短信验证码登录',
      logWayTwo: '账号密码登录',
      bindPhone: '绑定手机号',
      bindPhoneExplain: '（适用于全渠道账号统一）使用全网渠道商城，强烈推荐绑定手机号',
      bindPhoneTip: '提示：买家通过微信授权登录商城后，引导买家绑定手机号，识别买家唯一身份，解决买家在微信（公众号和小程序）等不同渠道账号不同的问题',
      bindingType: '绑定类型',
      bindingTypeOne: '强制绑定',
      triggerTypeOne: '点击加入购物车',
      triggerTypeTwo: '点击立即购买',
      triggerTypeThird: '买家进行分享',
      triggerTypeFour: '买家提交表单',
      triggerTypeFive: '买家领取优惠券',
      bindingTypeOneTip: '买家进入商城授权后，必须进行绑定手机号成功后才可访问商城',
      bindingTypeTwo: '手动绑定',
      bindingTypeTwoTip: '买家通过授权登录商城后，需要用户在个人中心主动操作绑定手机号',
      weChatAccount: '微信账号体系',
      weChatAccountExplain: '（需配置微信开放平台）',
      zeroOne: '01',
      zeroOneText: '需要同时开启微信小程序渠道 、开启微信公众号渠道',
      zeroOneTip: '同时开启时，用户可进行多渠道访问商城。',
      zeroTwo: '02',
      zeroTwoText: '在微信开放平台进行绑定配置',
      zeroTwoTip: '未配置开放平台前，同一个微信生成的不同会员，配置开放平台后也无法统一账号，只能通过手机号进行绑定。',
      zeroTwoTips: '注：未配置开发平台前，同一个微信生成一个会员时，配置开放平台后生成另一个会员时会自动执行微信账号统一，识别买家唯一微信身份。',
      immediatelyConfiguration: '立即配置',
      configurationGuide: '配置引导'
    },
    tab: {
      mallconfig: '商城设置',
      store: '首页设计',
      dealConfig: '交易设置',
      pointConfig: '积分设置',
      afterSalesConfig: '售后设置',
      packageConfig: '包邮设置',
      announcementManage: '公告管理',
      payConfig: '支付设置',
      registeredConfig: '注册设置',
      smallProgramConfig: '小程序设置',
      paymentTemplateManagement: '支付模板管理',
      pickPoint: '自提点 ',
      notifyConfig: '通知配置',
      msgTemplateConfig: '小程序通知配置',
      mallOrderSubmit: '订单提交模板',
      mallOrderPaySuccess: '订单支付成功模板',
      mallOrderConfirm: '订单确认模板',
      mallOrderDelivery: '订单发货模板',
      mallOrderPreparedCompleted: '订单备货完成模板',
      addressManage: '地址管理',
      returnAddress: '退货地址',
      orderRewardConfig: '下单奖励',
      goodsParamConfig: '商品详情设置',
      clearGoodsParam: '清空商品参数',
      clearGoodsParamTips: '是否确定清空所有商品【{0}】属性的参数值'
    },
    returnAddress: {
      returnAddressName: '地址标题',
      receiverName: '收件人',
      receiverPhone: '收件人电话',
      address: '收件地址',
      addTitle: '添加退货地址',
      editTitle: '编辑退货地址',
      returnAddressNamePlease: '地址标题不能为空',
      areaPlease: '区域不能为空',
      addressPlease: '地址不能为空',
      receiverNamePlease: '收件人不能为空',
      receiverPhonePlease: '收件人电话不能为空',
      deleteTips: '确认删除该退货地址'
    },
    common: {
      open: '开启',
      close: '关闭',
      save: '保存',
      allowSwitching: '是否允许切换店铺',
      neverShutDown: '永不关闭',
      customShutdownTime: '自定义关闭时间',
      day: '天',
      show: '显示',
      hide: '隐藏',
      remark: '备注',
      isDelete: '是否删除',
      announcemenTitle: '请填写公告标题',
      writeMallName: '请填写商城名称',
      writeMallLogo: '请填写商城LOGO',
      writeMallBrief: '请填写商城简介',
      writeTakeUnpaidOrder: '请填写订单关闭时间',
      writeTheDeliver: '请填写发货时间',
      uploadFile: '上传文件',
      againUpload: '重新上传',
      writeMerchantName: '请填写商户名称',
      writeServiceProviderOfficial: '请填写服务商公众号 (AppId)',
      writeServiceProviderPayMerchant: '请填写服务商支付商户号 (Mch_Id)',
      writePublicAppID: '请填写公众号/小程序 (AppID)',
      writePayMerchant: '请填写支付商户号 (Mch_Id)',
      writePayKey: '请填写支付密钥',
      writePayType: '请选择支付类型',
      customer: '客户',
      member: '会员',
      chooseGoods: '选择商品',
      selectSku: '选择sku',
      selectSpu: '选择商品spu',
      goodsCategory: '商品分类',
      generalOrder: '普通订单',
      giftOrder: '礼盒订单'
    },
    goods: {
      categoryTree: '分类树',
      categoryDetails: '分类详情',
      goodsType: '商品类型',
      realGoods: '实体产品',
      virtualGoods: '虚拟产品',
      goodsTitle: '商品标题',
      goodsSubtitle: '商品副标题',
      goodsShortName: '商品简称',
      mainImage: '商品主图',
      rotationImage: '商品轮播图',
      video: '商品视频',
      goodsCategory: '商品分类',
      salesLabel: '营销标签',
      isHot: '热卖',
      isNew: '新品',
      isRecommend: '推荐',
      sort: '排序',
      specsConfig: '规格设置',
      singleSpecs: '单规格',
      multiSpecs: '多规格',
      salesUnitPrice: '销售价',
      markingUnitPrice: '划线价',
      goodsStatusConfig: '上架设置',
      price: '价格',
      stock: '库存',
      createTime: '创建时间',
      addGoodsTitle: '添加商品',
      editGoodsTitle: '编辑商品',
      relatedProduct: '关联产品',
      skuSpecsValues: '规格',
      skuCode: 'SKU 编码',
      quantity: '数量',
      subtotal: '小计',
      picture: '图片',
      startDate: '修改时间起',
      endDate: '修改时间止',
      modifyDate: '修改时间',
      goodsPushdownConfig: '小店必选商品',
      discountConfig: '折扣设置',
      morePrice: '更多价格',
      pleaseSelectRelatedProduct: '请选择关联产品',
      pleaseChooseGoods: '请选择商品',
      allCategory: '全部分类',
      notDisplaySalesQuantity: '不显示销量',
      actualSalesQuantity: '实际销量',
      virtualSalesQuantity: '虚拟销量',
      sumSalesQuantity: '实际销量 + 虚拟销量',
      salesQuantityType: '显示销量',
      salesQuantityTips: '是：显示销量=实际销量+虚拟销量   否：虚拟销量',
      sortTips: '数字越大, 排名越靠前, 默认排序为商品修改时间',
      imageTips: '建议图片比例为1：1',
      tradeConfig: '交易设置',
      goodsTitlePlease: '请输入商品标题',
      mainImagePlease: '请上传商品主图',
      virtualStock: '虚拟库存',
      realStock: '实际库存',
      goodsStatus: '商品状态',
      viewStockConfig: '显示库存设置',
      reduceStockConfig: '库存扣减设置',
      orderedReduce: '下单减库存',
      notReduce: '永不减库存',
      viewStockConfigTips: '虚拟库存：商品设置的库存 实际库存：关联系统产品商品对应的库存',
      reduceStockConfigTips: '下单减库存：买家提交订单, 扣减库存数量, 可能会存在 恶意占用库存风险 永不减库存：无论在下单还是在支付成功, 该商品永远不会扣减库存数量',
      configGoodsTitle: '商品信息配置',
      mallName: '门户名称',
      viewGoodsTitle: '查看商品信息',
      batchPriceSet: '批量设置',
      applyPriceSet: '应用',
      memberFreightConfig: '会员运费模板设置',
      defaultFreightTemplate: '默认运费模板',
      pleaseSelectFreightTemplate: '请选择运费模板',
      customerFreightConfig: '客户运费模板设置',
      memberCustomFreightConfig: '会员运费模板自定义设置',
      customerCustomFreightConfig: '客户运费模板自定义设置',
      customFreightBusinessEmpty: '自定义运费模板请选择会员等级/客户分类',
      addCustomFreightConfig: '添加自定义设置',
      customFreightConfig: '自定义设置',
      freeFreight: '包邮',
      notFreeFreight: '不包邮',
      goodsVisibleConfig: '商品可见范围设置',
      noLimit: '不限制',
      specifiedCustomerVisible: '指定客户可见',
      specifiedCustomerSortVisible: '指定客户分类可见',
      goodsSortVisibleConfigTip: '指定客户分类可见:添加后，只有会员绑定了指定客户分类才可浏览该商品',
      goodsVisibleConfigTip: '指定客户可见:添加后，只有会员绑定了指定客户才可浏览该商品',
      goodsVisibleConfigNoTip: '都不可见: 设置后对所有人都不可见，对礼盒商品无影响',
      noVisible: '都不可见',
      noChooseGoodsVisibleConfig: '商品可见范围设置中,您未指定客户',
      noChooseGoodsSortVisibleConfig: '商品可见范围设置中,您未指定客户分类',
      noLimitBuy: '不限购',
      limitBuy: '限购',
      limitBuyConfig: '限购设置',
      limitDay: '天内限购',
      limitBuyTip: '限购：天数输入0为永久限购; 输入1为当天,按自然天计算',
      limitQutity: ' 件',
      limitBuySaveTip: '限购设置：选择了限购，需填写天数和件数',
      pleaseDay: '请输入天数',
      pleaseQutity: '请输入个数',
      submitProgramLiveLibrary: '小程序直播商品',
      remandTheCase: '提审',
      priceType: '价格类型',
      priceOne: '一口价',
      priceRange: '价格区间',
      displayDiscountPrices: '显示折扣价',
      priceTypeTip: '提示：价格区间（左输入框为左边界，右输入框为右边界,左边界不能大于右边界,左右输入框都不能为空）,显示折扣价（左输入框为原价，右输入框为现价,现价不能大于原价,左右输入框都不能为空）',
      remandTheCaseEdit: '提审编辑',
      remandTheCaseSuccess: '提审成功',
      notAudit: '未审核',
      auditing: '审核中',
      audited: '审核通过',
      auditFail: '审核失败',
      withdrawAudit: '撤回审核',
      withdrawSuccess: '撤回成功',
      againRemand: '重新提审',
      deleteTip: '确定删除小程序的直播商品吗？删除后不可恢复（每天限1000次）',
      modifyPrice: '修改价格',
      withdrawTip: '确定撤销直播商品的审核吗？本次提审不生效（每天限500次）',
      priceTip: '价格区间，左边界不能大于右边界',
      priceTipTwo: '显示折扣价，现价不能大于原价',
      checkGoodsAudit: '勾选后，同时提交商品审核',
      more: '更多',
      yuan: '元',
      mainImageTip: '建议尺寸：300像素 * 300像素，图片大小不得超过1M',
      submitTip: '确认提示：新增商品审核，每天限500次；更新商品，每天限1000次',
      addGoodsAudit: '添加直播商品',
      editGoodsAudit: '编辑直播商品',
      againRemandTip: '确定重新提交直播商品审核吗，审核需要1-7天（每天限500次）',
      presentPrice: '现价',
      originalPrice: '原价',
      priceNotNull: '左右输入框都不能为空',
      auditSucess: '审核已通过,不能进行撤回',
      batchMpcode: '生成商品码',
      batchAllSpuMpcode: '生成全部商品码',
      batchSelectSpuMpcode: '生成已查询商品码',
      batchCheckSpuMpcode: '生成已勾选商品码',
      spuMpcode: '商品码',
      batchmpCodeTips: '请选择商品',
      batchDeletePosterTips: '请选择需删除的海报',
      batchOutSuccess: '批量下架成功',
      batchOffShelf: '下架',
      batchSellout: '售罄',
      batchOffShelfTips: '确认下架已选择的商品?',
      isDeleteChooseGoods: '确认删除已选择的商品?',
      isDeleteChoosePoster: '确认删除已选择的海报?',
      batchSellOutTips: '确认已选商品已售罄',
      isRecoveryChooseGoods: '确认将已选择的商品恢复到待上架状态？',
      recoverySuccess: '恢复成功',
      allRemove: '清除回收站全部商品',
      deleteChoose: '清除回收站勾选商品',
      removeGoods: '清空回收站',
      isAllRemove: '确认清空回收站全部商品吗? 清空后将不可恢复',
      isDeleteChoose: '确认删除回收站勾选商品吗? 删除后将不可恢复',
      noRemoveGoods: '没有可清除的商品',
      removeSuccess: '清空成功',
      batchAllSpuMpcodeTips: '是否确认全部已上架商品生成商品码?',
      inputNotNull: '输入框不能为空',
      minimumQuantityConfig: '起订数量设置',
      minimumQuantityTip: '起订数量：0即无限制，可设置起订数量范围或单独设置起订数量,最小起订数量不能为0',
      minimumQuantityLimit: '起订数量不能大于限购数量',
      minimumLimit: '限制起订数量',
      noMinimumLimit: '不限制起订数量',
      minimumLimitTip: '限制起订数量,最小起订数量不能大于最大起订数量',
      minimumNoNullTip: '起订数量设置，请填写要限制的起订数量',
      minMinimumTip: '最小起订数量不能为0',
      min: '最小',
      max: '最大',
      mallCategory: '商品分类',
      productName: '产品名称',
      batchAdjustment: '批量调整',
      adjustCategory: '调整分类',
      selectGoodsEmpty: '请选择商品',
      selectedCategoryEmpty: '请选择分类',
      sellOutStatus: '是否售罄',
      isSellOut: '已售罄',
      notSellOut: '未售罄',
      batchFreightTemplateSet: '调整运费模板',
      batchQuantityConfigInfo: '调整销量或库存',
      batchPresale: '调整预售',
      selectEmpty: '请选择商品',
      batchFreightTemplateSetTips: '确认批量调整运费模板,已选商品数量：',
      batchQuantityConfigInfoTips: '确认批量调整销量或库存,已选商品数量：',
      batchPresaleTips: '确认批量调整预售,已选商品数量：',
      stockNullTips: '虚拟库存为空则不更新库存信息，需要清空库存则输入0',
      productBrand: '品牌',
      pleaseSelectRelatedProductBrand: '请选择商品品牌',
      relatedMallGoods: '引入商城商品',
      batchOnShelf: '上架',
      batchOnShelfTips: '是否确认上架商品，未配置零售价的商品将会跳过',
      aboutSaleTimePlease: '预售商品，请选择预计到货时间',
      presale: '是否预售',
      aboutSaleTime: '预计到货时间',
      customerPriceLevel: '客户结算价格',
      isOutDelivery: '委外发货',
      adjustIsOutDelivery: '调整委外发货商品',
      batchIsOutDeliveryTips: '确认批量调整委外发货商品,已选商品数量：',
      virtualDeliveryType: '虚拟发货方式',
      virtualDeliveryGoodsType: '虚拟发货商品类型',
      virtualDeliveryGoods: '虚拟发货商品',
      selectVoucher: '选择代金券',
      noneDelivery: '无需发货',
      autoDelivery: '自动发货',
      virtualVoucher: '代金券',
      virtualResource: '网络资源',
      virtualDeliveryQuantity: '发放数量',
      virtualDeliveryGoodsPlease: '请配置虚拟发货商品',
      expressDeliveryEnable: '可快递',
      selfPickEnable: '可自提',
      deliveryType: '发货方式',
      goodsDeliveryTypePlease: '请配置发货方式',
      adjustDeliveryType: '调整发货方式',
      batchAdjustDeliveryTypeTips: '确认批量调整商品发货方式,已选商品数量：',
      skuBindProductPlease: 'SKU未绑定产品',
      param: {
        editParam: '商品参数编辑',
        enable: '开启',
        disabled: '关闭',
        paramName: '参数',
        paramValue: '内容',
        addParam: '添加参数',
        writePatam: '未填写参数',
        brand: '品牌',
        productionPlace: '产地',
        netWeight: '净含量',
        internalConfig: '内配',
        custom: '自定义',
        barcode: '条形码',
        boxGauge: '箱规',
        internalName: '名称',
        internalNetWeight: '净含量',
        internalQuantity: '数量',
        addInternalConfig: '添加内配',
        otherParam: '其它参数',
        syncSkuProductInfo: '同步SKU产品参数信息',
        sync: '同步',
        internalConfigParse: '内配解析规则',
        parse: '解析',
        batchSetInternal: '批量设置',
        parseTips1: '内配输入格式 = 名称 + 空格 + 净含量 + * + 数量',
        parseTips2: '内配之间用换行进行区分',
        parseTips3: '如：',
        parseTips4: '内配名称1  100g  * 1',
        parseTips5: '内配名称2  200g  * 1',
        parseTips6: '内配名称3 * 1',
        parseTips7: '内配名称4'
      },
      tabs: {
        onShelfGoods: '出售中',
        soldOutGoods: '已售罄',
        offShelfGoods: '待上架',
        deletedGoods: '回收站',
        baseInfo: '基本信息',
        detailsInfo: '详情',
        paramInfo: '参数',
        categoryInfo: '分类信息',
        categoryConfig: '分类设置',
        discount: '会员折扣',
        promotionPoster: '推广海报'
      },
      posters: {
        download: '下载',
        poster: '海报',
        code: '海报编码',
        title: '标题',
        mainImage: '主图',
        endTime: '有效期止',
        visibleRange: '可见范围 ',
        createTime: '创建时间',
        creatPeople: '创建人',
        updateTime: '修改时间',
        automaticFilling: '自动生成',
        uploadImg: '请上传图片',
        tip: '建议尺寸：比例1：1',
        titleTip: '请填写标题',
        close: '关闭',
        customer: '客户',
        customerSort: '客户分类',
        member: '会员',
        generatePoster: '生成海报',
        generatePpt: '生成PPT',
        errorTips: '加载超时，请稍后再试',
        chooseGoods: '选择商品',
        remove: '移除',
        posterGoods: '海报商品',
        lookCode: '微信扫描查看',
        downloadSuccess: '下载成功',
        setMainImage: '设为主图',
        visibleRangeChoose: '可见范围选择',
        addPostersGoods: '请添加海报商品',
        selectPptTemplate: 'PPT模板配置',
        pptTemplate: 'PPT模板',
        pptTemplatePlease: '请选择PPT模板'
      },
      tips: {
        deleteGoodsTips: '确认【删除】该商品',
        onShelfTips: '确认【上架】该商品',
        offShelfTips: '确认【下架】该商品',
        pushdownTips: '该商品将在所有小店可见',
        salesPriceNotEmpty: '请前往【销售管理-销售价格】,设置产品价格。设置完成后，请重新选择产品',
        recoveryTips: '商品将恢复到待上架状态'
      },
      operate: {
        onShelf: '上架',
        offShelf: '下架',
        recovery: '恢复',
        pushdown: '小店必选',
        saveAndOnShelf: '保存并上架'
      },
      status: {
        offShelf: '待上架',
        onShelf: '已上架'
      },
      specs: {
        specsType: '规格项',
        specsValue: '规格值',
        addSpecsValue: '添加规格值',
        addSpecsType: '添加规格项'
      },
      menu: {
        goodsInfo: '商品管理',
        categoryInfo: '商品分类',
        categoryConfig: '设置分类层级'
      },
      category: {
        categoryImage: '分类图片',
        categoryName: '分类名称',
        keyValue: '键值',
        parentCategory: '父级分类',
        sort: '排序',
        isEnable: '是否启用',
        categoryLevel: '分类级别',
        primaryLevel: '一级分类',
        secondaryLevel: '二级分类',
        tertiaryLevel: '三级分类',
        deleteTips: '确认删除该分类',
        tertiaryLevelWithGoods: '分类+产品',
        firstLevelWithGoods: '一级分类+产品'
      },
      discount: {
        none: '无折扣',
        memberDiscount: '会员折扣'
      }
    },
    userLevel: {
      memberLevel: '请输入关键字进行查询',
      nameOfLevel: '名称',
      gradeValue: '值',
      gradeState: '默认会员',
      stateEnabled: '启用',
      notEnabled: '未启用',
      Information: '备注',
      addRating: '添加',
      editRating: '编辑',
      deleteRating: '是否删除该等级',
      memberLevelName: '名称',
      sort: '排序',
      pleaseGradeValue: '请输入等级值',
      pleasememberName: '请输入名称',
      remark: '备注',
      enable: '是否启用',
      limitsize: '最大限制127',
      discountTip: '折扣权益，请填写折扣数值',
      discountTip2: '折扣权益，请选择价格类型'
    },
    delivery: {
      deliveryCode: '发货单编码',
      receiptorName: '收件人',
      receiptorPhone: '收件人电话',
      express: '快递',
      address: '收件地址',
      buyerRemark: '买家备注',
      sellerRemark: '卖家备注',
      createTime: '单据时间',
      mallDeliveryBaseInfo: '基本信息',
      inventoryInfo: '出库信息',
      viewTitle: '查看商城发货单',
      startDate: '创建时间起',
      endDate: '创建时间止',
      editMallDelivery: '编辑商城发货单',
      giftDeliveryQuantity: '礼盒发货数量',
      giftOrderQuantity: '订单礼盒数量',
      orderQuantity: '订单数量',
      editTips: '确认修改发货单',
      mallDelliveryRevokeTips: '订单已确认收货，确认撤销发货单',
      completeRevokeTips: '订单已确认收货，是否撤销重新发货单'
    },
    return: {
      returnId: '退换货单号',
      returnType: '退换货单类型',
      returnStatus: '退换货单状态',
      returnAmount: '退款金额',
      createTime: '创建时间',
      toConfirmed: '待确认',
      confirmed: '确认',
      rejected: '驳回',
      beConfirmed: '已确认',
      beRejected: '已驳回',
      refunds: '商品退货退款',
      exchange: '商品换货',
      refund: '商品退款(仅退款)',
      orderReturn: '订单退款',
      freightReturn: '运费退款',
      lookDeail: '查看详情',
      returnReason: '退款原因',
      exchangeReason: '换货原因',
      orderNumber: '订单号',
      goodsStatus: '货物状态',
      additionalDescription: '补充描述',
      credentials: '凭证 ',
      address: '买家地址',
      confirmedSuccess: '确认成功',
      rejectedSuccess: '驳回成功',
      rejectedReseaon: '驳回理由',
      sellAddress: '商家退货地址',
      tip: '提示',
      confirmTip: '此退换货单是否确认审核通过',
      beCancel: '已取消',
      queryTip: '退换货单号 订单号',
      returnGoodsInfo: '退换商品信息',
      completed: '已完成',
      buyerNotDelivery: '买家待发货',
      buyerDelivered: '买家已发货',
      sellerReceipt: '商家已收货',
      sellerDelivered: '商家已发货',
      buyerReceipt: '买家已收货',
      notRefund: '待退款',
      refunded: '已退款',
      canceled: '用户取消',
      viewTitle: '查看退换货单号',
      mallReturnBaseInfo: '退换货信息',
      inventoryInInfo: '入库单',
      inventoryOutInfo: '出库单',
      startDate: '创建时间起',
      endDate: '创建时间止',
      through: '通过',
      keyPlaceholder: '订单号 姓名 电话号码 商品名称 退换货单号',
      courier: '快递',
      otherWay: '其他方式',
      returnWay: '退货方式',
      wayRemark: '方式说明',
      SendBackExpressInformation: '快递信息',
      returnAddress: '退换货地址',
      returnGoodsTable: '退换货商品',
      deliveryGoodsTable: '已发货商品',
      returnQuantity: '退换数量',
      deliveryQuantity: '发货单数量',
      inventoryQuantity: '已出库数量',
      giftItemQuantity: '子件数量',
      deliveryCode: '发货单编码',
      alterDeliveryQuantityTips: '订单退货退款后可调整发货单商品数量，请按实际情况调整',
      returnAddressPlease: '请选择退货地址',
      orderPaymentType: '支付方式',
      userName: '下单会员',
      customerName: '客户',
      orderPaidAmount: '订单总额',
      freight: '运费',
      orderQuantity: '订单数量',
      returnedQuantity: '已退数量',
      abountReturnAmount: '预计可退金额',
      returnTypeEmpty: '请选择退换类型',
      returnQuantityEmpty: '请填写退换商品数量',
      returnAmountEmpty: '请填写退款金额',
      returnAddressEmpty: '请选择退换货地址',
      fillReturnAmount: '填入金额',
      refundBalance: '退差价',
      abountReturnGoodsAmount: '已选退款商品总额',
      hasReturnAmount: '已退款金额',
      returnAmountGreatThenAbaleReturnAmount: '退款金额大于订单可退金额',
      deliveredQuantity: '已发数量',
      completeTip: '确认关闭改售后单？',
      closeReturnOrder: '关闭',
      deliveryGoodsInfo: '发货明细',
      outDeliveryGoodsInfo: '委外发货明细',
      returnQuantitySum: '退换总数',
      purchaseOutReturn: '委外退货',
      outRequireQuantity: '委外需求数量',
      outOrderQuantity: '已发数量',
      outReturnQuantitySum: '委外退货总数量',
      outOrderReturnQuantity: '委外已发退数量',
      mallDeliveryReturn: '商城发货退货',
      mallDeliveryQuantity: '发货单数量',
      mallInventoryQuantity: '已出库数量',
      notInventoryReturnQuantity: '未出库退数量',
      inventoryReturnQuantity: '已出库退数量',
      splitReturnQuantityMallDeliveryFirst: '优先退商城发货单',
      splitReturnQuantityOutFirst: '优先退委外单',
      greatThenAbleReturnQuantity: '超出可退数量',
      returnQuantityNotEqual: '退换总数 != 未发货退数量 + 商城发货退数量 + 委外发货退数量',
      notDeliveryReturnQuantity: '未发货退',
      inventoryReturnExchangeQuantity: '换货数量',
      outReturnExchangeQuantity: '换货数量',
      mallOrderInventoryProcessRate: '订单出库进度',
      rollBackTip: '确认撤回售后单，撤回后需重新审核',
      addReturnTips: '确认通过后，订单包含代金券/积分支付将实时退还，剩余部分需财务操作付款单退回',
      maxReturnAmount: '最大可退金额',
      rejectedAndComplete: '驳回并关闭',
      rejectReturnTips: '确认驳回该售后',
      rejectAndCompleteReturnTips: '确认驳回并关闭该售后？',
      returnPointAmount: '退积分数量',
      hasReturnPoint: '已退积分'
    },
    recharge: {
      memberName: '会员',
      customerName: '客户',
      rechargeMethod: '充值方式',
      rechargeAmount: '充值金额',
      status: '充值状态',
      accountName: '账户名',
      accountNumber: '账号',
      weChatOrderCode: '微信支付号',
      certificate: '支付凭证',
      online: '微信支付',
      offline: '线下转账',
      submit: '已提交',
      notPaid: '未付款',
      cancaled: '已取消',
      success: '充值成功',
      fail: '充值失败',
      viewRecharge: '查看充值信息'
    },
    memberVoucherShareRecord: {
      shareMemberName: '赠送代金券人',
      receiveMemberName: '领取代金券人',
      receiveStatus: '领取状态',
      noReceiveRemark: '未领取原因',
      voucherTitle: '代金券标题',
      voucherAmount: '代金券面值',
      shareTime: '赠送时间',
      notReceive: '未领取',
      receive: '已领取',
      recover: '已收回'
    },
    memberExchangeVoucherShareRecord: {
      shareMemberName: '赠送兑换券人',
      receiveMemberName: '领取兑换券人',
      receiveStatus: '领取状态',
      noReceiveRemark: '未领取原因',
      voucherTitle: '兑换券标题',
      shareTime: '赠送时间',
      notReceive: '未领取',
      receive: '已领取',
      recover: '已收回'
    },
    browseRecord: {
      recordType: '类型',
      goods: '商品',
      poster: '海报',
      title: '标题',
      sourceType: '来源',
      clearBrowseRecord: '清空浏览记录',
      clearRange: '清空范围',
      all: '全部',
      someSourceType: '指定来源',
      dateRange: '时间区间',
      queryKeyword: '标题',
      sourceTypePlease: '请选择来源',
      dateRangePlease: '请选择时间区间',
      subtotal: '合计',
      visitor: '游客',
      viewUserDetailsInfo: '用户访问明细',
      user: '用户昵称',
      customerName: '客户名称',
      recordQuantity: '访问次数',
      recentRecordDate: '最近访问时间',
      viewUserDetailsQuantityInfo: '用户访问数量明细',
      recordDate: '访问时间',
      customerSort: '客户分类',
      quantityDetailQueryKeyword: '用户昵称、客户名称',
      detailQueryKeyword: '用户昵称、标题',
      recordDetailsCount: '访问统计',
      recordDetails: '访问明细'
    },
    searchHistory: {
      searchHistoryDetails: '搜索明细',
      queryKeyword: '分类名 关键字',
      searchKeyword: '搜索关键字',
      category: '搜索分类',
      searchPriceRange: '搜索价格区间',
      lowestPrice: '查询最小价格',
      searchDate: '搜索时间'
    },
    promotionalPoster: {
      background: '背景图片',
      backgroundTip: '背景图片建议尺寸 1024*1625',
      miniProgramCodePosition: '小程序码位置',
      showMemberAvatar: '显示会员头像',
      miniProgramLeft: '左边距',
      miniProgramTop: '上边距',
      miniProgramWidth: '宽度',
      miniProgramHeight: '高度',
      miniProgramCircle: '裁剪为圆形',
      addText: '新增文本',
      textContentTip: '样式设置（模板实际效果以预览为准）',
      textContent: '文本内容',
      fontSize: '字体大小',
      fontAlign: '对齐方式',
      fontStyle: '字体样式',
      fontColor: '字体颜色',
      deleteText: '删除文本',
      deleteTextTip: '是否确定删除该文本？'
    }
  },
  welfare: {
    exchangeReduce: '兑换减库存',
    exchangedQuantity: '已兑换数量',
    exchangeQuantityReport: '兑换数量',
    generateCode: '生成方案二维码',
    addWelfareIssue: '添加福利发放',
    editWelfareIssue: '编辑福利发放',
    viewWelfareIssue: '查看福利发放',
    exchangeAddress: '兑换地址',
    exchangeAddressPlease: '请填写兑换地址',
    planName: '方案名称',
    planStartTime: '方案开始时间',
    planEndTime: '方案结束时间',
    addPlan: '添加卡券方案',
    editPlan: '编辑卡券方案',
    viewPlan: '查看福卡券方案',
    planText: '方案描述',
    goods: '卡券商品',
    chooseGoods: '选择卡券商品',
    addGoods: '添加商品',
    startEndTime: '方案起止时间',
    releaseTime: '发布时间',
    release: '发布',
    saveAndRelease: '保存并发布',
    addGoodsTip: '未添加商品',
    pleasePlanName: '请填写方案名称',
    pleasePlanTime: '请选择方案起止时间',
    draft: '草稿',
    offline: '下线',
    waitStart: '未开始',
    ongoing: '进行中',
    ended: '已结束',
    offlineed: '已下线',
    planInfo: '方案信息',
    planConfig: '方案设置',
    exchangeInformation: '兑换信息',
    exchangeOrder: '兑换订单',
    exchangeSuccess: '兑换成功',
    delivered: '已发货',
    received: '已收货',
    smsPlanConfig: '福利方案短信发送节点设置',
    cardNumber: '卡券编号',
    passWord: '卡券密码',
    appCode: '小程序码',
    appCodeStatus: '小程序码状态',
    exchangeStatus: '兑换状态',
    notGenerate: '未生成',
    inGenerate: '已生成',
    generateFail: '生成失败',
    hasChange: '已兑换',
    noChange: '未兑换',
    exchangeNumber: '生成卡券数量',
    generateSuccess: '生成成功',
    exchangeNumberTip: '请填写生成卡券数量',
    generateCard: '生成卡券',
    generateAll: '全部小程序码',
    generateNo: '未生成的小程序码',
    generateFails: '生成失败的小程序码',
    batchGenerateAppCode: '批量生成小程序码',
    exChange: '兑换',
    generateAppCode: '生成卡券兑换码',
    skuImage: '商品sku图片',
    speci: '规格',
    exchangeWelfare: '兑换福利',
    chooseAppCodeStatus: '选择要生成小程序码的状态',
    all: '全部',
    noAppCodeNeedGene: '没有要生成小程序码的卡券',
    appCodeIsGenerate: '小程序码正在后台生成，请稍后进行查看',
    submitTip: '请选择兑换的商品',
    deliveryTip: '请填写快递公司和快递单号',
    isOver: '确认【下线】该福利方案',
    isOverSuccess: '方案下线成功',
    associatedApplets: '关联小程序',
    pleaseAssociatedApplets: '请选择关联小程序',
    associatedAppletsName: '关联小程序名称',
    pleaseWriteSmsCode: '请填写短信编码',
    smsCodeTip: '提示：需勾选短信发送节点并正确填写短信编码，短信发送才会生效',
    customCardId: '自定义卡券编号',
    cardCodeLength: '编码流水号长度',
    cardCodeStart: '编码流水号起始值',
    cardAfter: '编码后缀',
    expression: '表达式',
    noChinese: '编号前缀，不能输入汉字',
    cardCodeLengthTip: '编码流水号长度不能为空',
    cardCodeStartTip: '编码流水号起始值不能为空',
    planCode: '方案编码',
    pleasePlanCode: '请填写方案编码',
    planAppCode: '方案兑换码',
    batchGoods: '批量关联商品',
    pleaseRelatedGoods: '请选择要关联的商品',
    relateGoods: '关联商品',
    noChooseCard: '未选择卡券',
    successCount: '成功数量',
    repeatCount: '重复数量',
    cardBefore: '编号前缀',
    cardRepeatTip: '同一方案中，不能有重复卡券编号，重复时将跳过',
    noCardGenerate: '没有卡券生成',
    exchangeProgress: '兑换进度',
    exchangeUserNotifyConfig: '兑换人小程序通知配置',
    receiverUserNotifyConfig: '收件人短信通知配置',
    deliveryDelay: '延迟发货',
    appSmsTip: '提示：需勾选发送节点并选择发送模板，小程序通知才会生效',
    appExpressSmsConfigTip: '提示：未选择物流短信发送策略将按默认策略进行短信发送',
    activateTotalRate: '发放进度',
    activateRate: '激活进度',
    exchangeRate: '兑换进度',
    viewActivateRate: '查看进度',
    addActivate: '发放',
    appTips: '小程序温馨提示',
    appTipsPlease: '请输入小程序温馨提示语',
    smsSignConfig: '短信签名',
    relateRealStockConfig: '关联系统库存',
    sellOutConfig: '售罄',
    relateRealStockTip: '兑换商品库存取自系统库存，下架/售罄将不能兑换该商品',
    sellOutTip: '标记售罄后将不能兑换该商品',
    geneSelectedAppCode: '已选择卡券',
    geneAllAppCode: '全部卡券',
    importCard: '卡券导入',
    smsEnableConfig: '是否发送短信',
    genePlanAppCode: '生成方案码',
    geneCardAppCode: '生成卡券兑换码',
    addExchangeOrder: '添加兑换订单',
    exchangeCard: '兑换券',
    exchangeVoucher: '代金券',
    addVoucher: '添加代金券方案',
    editVoucher: '编辑代金券方案',
    viewVoucher: '查看代金券方案',
    voucherOrderFreight: '兑换每单运费',
    freeFreightOrderAmount: '满额包邮金额',
    voucherPriceType: '商品价格',
    voucherGoods: '可兑商品',
    goodsCategory: '分类',
    goodsCategoryName: '分类名称',
    voucherCardAmount: '卡券面值',
    voucherCardUsedAmount: '已使用金额',
    voucherGoodsPrice: '默认价格',
    voucherGoodsCustomPrice: '自定义价格',
    addVoucherCategory: '添加分类',
    addVoucherGoods: '添加商品',
    deleteVoucherGoodsTips: '确认删除该商品',
    alterCategory: '调整分类',
    alterVoucherGoodsCategoryTips: '确认调整商品分类',
    voucherCard: '代金券',
    isRequired: '必选商品',
    setIsRequired: '设为必选',
    unSetIsRequired: '取消必选',
    selectGoodsPlease: '请选择商品',
    goodsTitle: '商品名称',
    goodsSpecs: '商品规格',
    orderUnitPrice: '单价',
    quantity: '数量',
    subtotal: '小计',
    exchangeCardOrderOrder: '兑换券订单',
    exchangeVoucherOrderOrder: '代金券订单',
    warehouseDeliveryOrder: '仓库发货单',
    outDeliveryOrder: '委外发货单',
    inventoryOutInfo: '出库单列表',
    welfareReturnInfo: '售后',
    detailInfo: '订单明细',
    cancelOrder: '取消订单',
    orderReturn: '订单退款',
    notAudit: '待审核',
    refunded: '已退款',
    rejected: '已驳回',
    returnOrderId: '售后单号',
    returnType: '退换类型',
    returnAmount: '退款金额',
    returnReason: '退货原因',
    rejectReason: '驳回原因',
    returnStatus: '售后状态',
    returnQueryKeywords: '订单号，售后单号',
    viewReturn: '售后详情',
    rejectReturnTips: '是否驳回该售后申请',
    passReturnTips: '通过该售后申请，通过后将会退换用户兑换卡券或支付金额',
    exportOrderTitle: '导出兑换订单',
    exportWelfareOutOrderTitle: '导出卡券委外发货单',
    goodsCategoryPlease: '请输入分类名称',
    deliveryType: '发货方式',
    notInventoryOut: '虚拟发货',
    inventoryOut: '仓库发货',
    notInventoryOutTips: '虚拟发货：仅标记发货状态，需上传快递单号，无需操作仓库出库/委外发货',
    inventoryOutTips: '仓库发货：需操作仓库出库/委外发货',
    purchaseOut: '委外发货',
    prepareGoods: '备货',
    orderQuantity: '订单数量',
    deliveryQuantity: '发货数量',
    hasDeliveryQuantity: '已发货数量',
    notDeliveryQuantity: '未发货数量',
    hasInventoryOutQuantity: '已出库数量',
    deliveryCode: '发货单编码',
    addDelivery: '添加发货',
    editDelivery: '编辑发货单',
    viewDelivery: '查看发货单',
    deliveryGoodsEmpty: '请选择发货商品',
    deliveryGoodsIsOver: '订单超额发货',
    deliveryQueryKeywords: '订单编码 发货单编码 方案 收件人',
    voucherPageConfig: '首页设置',
    pageTopCategoryConfig: '首页显示商品分类',
    pageSlideCategoryConfig: '首页显示商品',
    editVoucherCategory: '编辑分类',
    importOneGoods: '单个引入',
    importMultiGoods: '批量引入',
    selectImportGoodsEmpty: '请选择要引入商品',
    batchImportMallGoodsTips: '确认引入商城商品，操作将在后台进行，请稍后刷新查询',
    batchImportMallGoodsResult: '共{0}条记录，成功：{1}条，跳过：{2}条',
    appBackgroundConfig: '卡券背景',
    customBackgroundImage: '自定义背景图片',
    backgroundImagePlease: '请上传背景图片',
    customProductBackgroundImage: '自定义产品列表背景图',
    customLogingroundImage: '自定义登录背景图',
    customLoginBackgroundTips: '背景尺寸为750*1624',
    customBackgroundTips: '背景尺寸为750*500',
    customAppCode: '兑换码',
    geneBackgroundCode: '生成兑换码',
    noCategory: '未分类',
    welfareStockIncludePurchase: '可用库存是否包含在途',
    plesaseSelectWelfareOrder: '请选择兑换订单',
    deliveryWarehouse: '发货仓库',
    pleaseSelectNotPreparedOrder: '请选择待备货订单',
    pleaseSelectWaitDeliveryOrder: '请选择仓库发货的待发货订单',
    allOrderGoodsDelivery: '所有订单商品已发货',
    voucherPriceTypePlease: '请选择商品价格',
    baseInfo: '基本信息',
    requiredGoodsCheck: '必选商品校验规则',
    perOrderRequireGoodsCheck: '每单校验',
    firstOrderRequireGoodsCheck: '首单校验',
    addressLimitConfig: '地址拦截设置',
    welfareExchangeActivateAddressLimitTips: '拦截提示语',
    addLimitAddress: '添加拦截地址',
    addressLimit: '拦截地址',
    addressLimitArea: '拦截区域',
    addressLimitKeywords: '详细地址关键字（多个用，隔开）',
    addressLimitAreaIdError: '请选择拦截区域',
    addressLimitKeywordsError: '请输入拦截地址关键字',
    privacyAgreement: '隐私协议',
    activate: {
      activateInfo: '发放订单',
      activateDetailsInfo: '卡券明细',
      queryStatus: '状态',
      queryKeywords: '订单编码、客户名称、方案名称',
      activateCode: '订单编码',
      customer: '客户',
      exchangeDateRange: '兑换起止时间',
      planName: '方案名称',
      enableStatus: '状态',
      activateStatus: '激活状态',
      planAppCode: '方案兑换码',
      cardAppCode: '卡券兑换码',
      cardQuantity: '卡券数量',
      activateRate: '激活进度',
      exchangeRate: '兑换进度',
      activateDate: '发放日期',
      customerPlease: '请选择客户',
      contact: '联系人',
      contactPlease: '请输入联系人',
      dateRange: '兑换起止时间',
      dateRangePlease: '请选择兑换起始日期',
      checkExchangeUserConfig: '是否校验兑换人信息',
      collectExchangeUserConfig: '是否收集兑换人信息',
      operator: '经办人',
      notCollect: '不收集',
      collect: '收集',
      notCheck: '不校验',
      checkAfterCodeScanning: '扫码后校验',
      checkAfterOrdered: '下单后校验',
      jobNumber: '工号',
      userName: '姓名',
      phoneNumber: '手机号码',
      email: '邮箱',
      checkOrCollectUserInfoConfig: '校验/收集兑换人信息',
      cardPlanPlease: '请选择卡券方案',
      cardPlan: '卡券方案',
      selectCard: '选择卡券',
      selectCardQuantity: '随机选择',
      selectCardTitle: '请选择卡券',
      saveAndActivate: '保存并生效',
      notEnable: '未生效',
      enabled: '已生效',
      notActivate: '未激活',
      activated: '已激活',
      notExchange: '未兑换',
      exchanged: '已兑换',
      exchangeStatus: '兑换状态',
      geneCardAppCode: '生成兑换码',
      geneCardAppCodeTips: '兑换码正在后台生成，请稍后查看',
      activateCard: '激活',
      deactivateCard: '取消激活',
      activateCardTips: '确认激活该卡券，卡券激活后用户可在小程序兑换',
      deactivateCardTips: '确认取消激活卡券，取消激活后用户在小程序只能浏览不能兑换',
      activateSuccessTips: '卡券激活成功',
      deactivateSuccessTips: '取消激活成功',
      addActivate: '添加发放订单',
      editActivate: '编辑发放订单',
      viewActivate: '查看发放订单',
      baseInfo: '基本信息',
      cardInfo: '卡券信息',
      revokeTips: '确认撤销该发放订单',
      deleteTips: '确认删除该发放订单',
      cardInfoQuery: '卡券编号',
      detailsQuery: '客户名称、方案信息、卡券信息',
      activateAll: '全部激活',
      deactivateAll: '全部取消激活',
      activateAllTips: '激活卡券发放单下全部卡券，将会跳过已激活卡券',
      deactivateAllTips: '卡券发放单内卡券全部取消激活，存在已兑换卡券时将不能进行取消操作',
      checkOrCollectUserTips: '保存卡券发放订单后，请在查看页面导入兑换人信息',
      importUser: '导入校验信息',
      attentionTipsTitle: '校验信息导入注意事项',
      userImportResult: '共{0}条记录，成功：{1}条，跳过：{2}条',
      deleteUserTips: '确认删除该校验信息',
      clearAllUserTips: '确认清空兑校验信息',
      exchangeUserInfo: '校验信息',
      clearAll: '清空',
      checkInfoConfigEmpty: '请勾选要校验/收集的兑换人信息',
      activateConfig: '订单设置',
      deleteSelected: '已选择',
      notSend: '不发送',
      sameAsPlan: '同方案设置',
      selfConfig: '自定义',
      activateCardEnable: '是否激活卡券',
      replaceCard: '替换',
      deleteCardTips: '确认删除卡券',
      addActivateCard: '添加卡券',
      viewPlanName: '显示名称',
      userSignatureEnable: '订单提交附加手绘签名',
      checkPhoneNumber: '是否凭手机号领取',
      importCheckPhoneNumber: '导入领取手机号',
      updateExchangePhone: '修改领取手机号',
      exchangePhoneNumber: '领取手机号'
    },
    order: {
      withdrawTip: '确定将订单撤回到待发货吗',
      withdraw: '撤回',
      logistic: '物流信息',
      code: '订单编码',
      queryStatus: '订单状态',
      startDate: '兑换日期起',
      endDate: '兑换日期止',
      queryKeyword: '卡券编号、卡券密码、收件人、电话、商品',
      queryKeywords: '卡券编号、方案名称、收件人、电话、商品',
      queryKeywordissus: '收件人、电话、商品',
      receiverName: '收件人',
      receiverPhone: '收件电话',
      receiveAddress: '收件地址',
      areaIds: '所属地区',
      goodsTitle: '商品',
      orderStatus: '状态',
      confirm: '确认',
      delivery: '发货',
      toBeConfirm: '待确认',
      waitDelivery: '待发货',
      confirmed: '已确认',
      delivered: '已发货',
      received: '已收货',
      completed: '已完成',
      complete: '完成',
      completeTip: '订单是否确认完成',
      distribute: '派件中',
      expressCompany: '快递公司',
      expressNumber: '快递单号',
      addLogistics: '添加物流',
      changeGoods: '修改商品',
      editOrder: '编辑订单',
      viewOrder: '查看订单',
      orderDelivery: '订单发货',
      comfireOrder: '确认订单',
      confirmOrderTips: '是否确认该兑换订单有效',
      deleteOrderTips: '是否删除该兑换订单',
      selectGoods: '选择商品',
      deliveryTime: '发货时间',
      payType: '支付方式',
      voucherPay: '代金券支付',
      wxPay: '微信支付',
      orderTotal: '订单总额',
      voucherDeductAmount: '代金券支付金额',
      paidAmount: '微信支付金额',
      merchantOrderCode: '支付商户订单号',
      paymentOrderCode: '微信支付交易号',
      createTime: '创建时间',
      orderId: '订单编码',
      importOrderExpress: '快递信息导入',
      orderExpressImport: '订单导入快递信息',
      importData: '数据导入',
      dataMap: '数据映射',
      previewData: '数据预览',
      previewResult: '导入结果',
      excelFile: 'Excel 文件',
      attentionTipsTitle: '订单快递信息导入注意事项',
      downloadExpressTemplate: '下载快递公司模板',
      attentionTips2: '建议使用官方Excel模板导入数据',
      attentionTips3: '上传EXCEL后，请准确填写标题行号和数据起始行号',
      attentionTips4: '请准确填写数据映射关系并检查数据预览是否相符',
      excelTitleRowNumber: '标题行号',
      excelDataRowNumber: '数据起始行号',
      nextStep: '下一步',
      lastStep: '上一步',
      targetTitleName: '映射对象',
      sourceTitleName: '导入列标题',
      serialNumber: '序号',
      success: '成功',
      fail: '跳过',
      executeResultInfo: '共{0}条记录，成功：{1}条，跳过：{2}条',
      importStatus: '导入状态',
      importErrorReason: '跳过原因',
      batchSetExpressCompany: '批量设置快递公司',
      config: '设置',
      rollBack: '恢复',
      importModeConfig: '导入模式',
      coverMode: '覆盖模式',
      appendMode: '追加模式',
      skipEmptyExpress: '跳过快递单号为空数据',
      notPrepared: '待备货',
      prepareRangeOperate: '批量备货',
      selectedOrderRange: '勾选订单',
      queryOrderRange: '查询范围订单',
      preprareOrderTips: '确认订单已备货',
      queryExpressStatus: '快递状态',
      expressInfo: '快递信息',
      viewExpressDetails: '查看快递信息',
      notPaid: '待支付',
      refunded: '已退款',
      canceled: '已取消',
      outDelivery: '委外发货',
      exchangeCardOrderOutDeliveryTips: '确认订单转委外发货，确认后将生成委外需求',
      printOrder: '打印兑换订单',
      orderReturnStatus: '售后状态',
      afterSalesOngoing: '售后中',
      productCode: '产品编码',
      productName: '产品名称',
      productModel: '产品规格',
      productUnit: '单位',
      quantity: '数量',
      orderUnitPrice: '单价',
      subtotal: '小计',
      exchangeTime: '兑换时间',
      cardType: '订单类型',
      detailQueryKeywords: '产品编码，产品名称，订单编号',
      batchDelivery: '批量发货',
      userSignature: '兑换人手绘签名'
    },
    config: {
      logo: '小程序logo',
      logoPlease: '请上传小程序logo',
      name: '名称',
      namePlease: '请填写小程序名称',
      describe: '描述',
      addConfig: '添加小程序设置',
      editConfig: '编辑小程序设置',
      viewConfig: '查看小程序设置',
      servicePhone: '客服电话'
    }
  },
  member: {
    config: {
      commonConfig: '通用参数',
      customerBindExpireRemindPrefix: '会员客户绑定到期前',
      customerBindExpireRemindSubfix: '天提醒，向会员手机发送短信提醒',
      distributionConfig: '分销设置',
      memberDistributionEnable: '会员分销',
      memberDistributionRelationAutoTransfer: '分销关系自动转移',
      memberPerformanceCalcLevel: '业绩计算层级',
      memberDistributionEnableTips: '如果没有开启会员分销，会员分享的推荐关系将自动转移到该会员的推荐客户上',
      memberDistributionRelationAutoTransferTips: '若会员存在分销关系，当会员绑定客户时，是否自动将会员的分销关系绑定到客户上',
      memberPerformanceCalcLevelTips: '0：表示该会员业绩仅计算自己。1：表示将本身和直接下级的交易作为自己的业绩。以此类推。-1：表示不限层级',
      distributionDisable: '关闭分销',
      distributionRelationAutoTransfer: '分销关系是否自动向上转移',
      distributionDisableTips: '关闭分销，则该会员等级不会绑定分销关系',
      distributionRelationAutoTransferTips: '如果自动向上转移，当该会员没有分销权限时，会员的推荐关系将转移到该会员的上级',
      performanceCalcLevelTips: '0：表示该会员业绩仅计算自己。1：表示将本身和直接下级的交易作为自己的业绩。以此类推。-1：表示不限层级',
      customerPerformanceCalcLevelTips: '0：表示该客户业绩仅计算自己。1：表示将本身和直接下级的交易作为自己的业绩。以此类推。-1：表示不限层级',
      performanceCalcLevel: '业绩计算层级',
      distributionRewardConfig: '会员升级分销奖励',
      memberUpgradeLevelName: '会员升级等级',
      firstDistributionReward: '一级分销奖励',
      secondDistributionReward: '二级分销奖励',
      agreement: '升级协议',
      memberPointAutoTransferCustomer: '积分转移',
      memberPointAutoTransferCustomerTips: '会员绑定客户后，将会员全部积分转移到客户'
    },
    memberLevel: '会员等级',
    enableMember: '设为默认会员',
    defaultMember: '默认会员',
    list: {
      keyWords: '关键词',
      userSource: '用户来源',
      userLevel: '会员等级',
      labelSet: '标签组',
      registrationTime: '注册时间',
      blackList: '黑名单',
      member: '会员',
      integral: '积分',
      memberIntegral: '会员/积分',
      balance: '余额',
      clinchDeal: '成交',
      coupons: '优惠券',
      nickName: '昵称',
      phone: '手机号码',
      order: '成交订单数',
      money: '成交金额',
      edit: '会员编辑',
      view: '会员查看',
      startDate: '注册时间起',
      endDate: '注册时间止',
      name: '名称',
      level: '会员等级',
      yes: '是',
      no: '否',
      levelTip: '请选择会员等级',
      classify: '分类',
      relatedPlatformClients: '关联客户 ',
      connectedPlatformSuppliers: '关联平台供应商 ',
      shop: '小店id',
      customerManage: '服务经理',
      bindTime: '签约时间',
      expire: '有效期止',
      indefinite: '无期限',
      customerSort: '客户分类',
      paymentMonth: '挂账月结 ',
      balancePayment: '余额支付',
      distributionRecommender: '分销推荐人',
      administrator: '是否为管理员',
      superior: '上级(推荐人)',
      referMember: '推荐会员',
      referCustomer: '推荐客户',
      subordinate: ' 下级',
      applyCustomerName: '申请客户名称',
      customerType: '客户类型',
      chooseCustomer: '请先选择关联客户',
      syncCustomerSuperior: '同步修改客户上级为【上级(推荐人)】',
      showAdminOnly: '只显示管理员',
      memberCustomerUnbind: '解除绑定',
      unbindTips: '确认解除该会员的客户绑定关系,解除绑定后会员将不在享有客户优惠',
      isMemberExpire: '会员已到期',
      queryKeywords: '会员昵称 会员手机号 客户名称',
      memberKeywords: '会员',
      refererKeywords: '推荐人',
      setMemberBlack: '加入黑名单',
      removeMemberBlack: '解除黑名单',
      setMemberBlackTips: '确认将该会员加入黑名单',
      removeMemberBlackTips: '确认将该会员移除黑名单',
      distributionMemberBindCustomerTips: '下级分销会员重新绑定到当前客户',
      distributionMemberBind: '会员分销关系转移到客户',
      memberPointGive: '积分赠送',
      receiverMember: '赠送会员',
      givePointAmount: '赠送积分',
      givePointAmountPlease: '请输入赠送积分',
      receiverMemberPlease: '请选择赠送会员',
      memberVoucherGive: '代金券赠送',
      isDefault: '是否默认',
      autoChangeCustomer: '同步将小程序会员身份切换至默认客户',
      importVoucherQuantity: '导入数量',
      newPassword: '新密码',
      confirmNewPassword: '确认密码',
      newPasswordPlease: '请输入密码',
      passwordNotEqual: '新密码和确认密码不一致',
      changePasswordTips: '确认修改会员密码',
      changePassword: '修改密码'
    },
    integral: {
      startDate: '开始时间',
      endDate: '结束时间',
      queryContent: '业务编号、业务单号、会员名称',
      businessType: '业务类型',
      businessId: '业务编号',
      businessCode: '业务单号',
      memberName: '会员名称',
      transactionType: '交易类型',
      transactionAmount: '交易积分',
      createTime: '交易时间',
      pointRecharge: '充值单号',
      mallOrder: '商城订单号',
      platformAdd: '后台添加',
      recharge: '积分充值',
      upgrade: '升级奖励',
      mallConsume: '商城消费',
      payOrderReward: '下单奖励',
      upgradeFirstOrder: '升级首单奖励',
      otherMemberGive: '会员积分互赠',
      distributionReward: '分销推荐奖励',
      orderRefundReturn: '订单退款返还',
      distributionPoint: '发放积分',
      memberPointTransferCustomer: '会员积分转客户积分'
    },
    orderCode: '订单编号',
    user: '用户',
    customerName: '客户名称',
    orderTime: '下单时间',
    orderMoney: '订单金额',
    orderStatus: '订单状态',
    consumptionAmount: '消费满足金额',
    reductionAmount: '减免金额',
    orderList: '订单列表',
    couponsList: '优惠券列表',
    voucherList: '代金券列表',
    memberInfo: '会员信息',
    integralInfo: '积分信息',
    binding: {
      memberName: '会员名称',
      customerName: '绑定客户名称',
      bindingStatus: '绑定状态',
      applyTime: '申请时间',
      binding: '绑定',
      unbundling: '解绑',
      bindingSuccess: '绑定成功',
      unbindingSuccess: '解绑成功',
      noBinding: '未绑定',
      beBinding: '已绑定',
      applicationCode: '申请编号',
      rejectionReason: '驳回理由',
      beReject: '已驳回',
      reject: '驳回',
      customerCode: '绑定客户编码',
      audit: '审核',
      waitAudit: '待审核',
      through: '通过',
      memberInfo: '会员信息',
      haveUnbundling: '已解绑 ',
      applicationCustomerName: '申请客户名称',
      auditStatus: '审核状态',
      pleaseChooseCustomer: '请选择关联客户',
      throughed: '已通过'
    },
    discountInterest: '折扣权益',
    noDiscountSet: '不设置折扣',
    priceType: '指定价格类型',
    theCustom: '自定义折扣',
    discountText: '当前等级可享受:',
    discount: '折',
    discountTip: '提示：折扣在0.1 - 9.9之间',
    allMember: '全部会员',
    queryMember: '查询范围会员',
    selectedMember: '已选择会员',
    checkMember: '已勾选会员',
    batchAdjustmentGrade: '批量调整会员等级',
    batchDistributionPoint: '批量发放积分',
    pleaseNewMemberLevel: '请选择调整后的会员等级',
    pleaseEnterCorrectPoints: '请输入正确发放积分',
    pleaseAdjustMember: '请选择要调整的会员',
    pleaseDistributionMember: '请选择要发放的会员',
    isDetermineAdjust: '是否确定调整？',
    isDetermineDistribution: '是否确定发放？',
    adjustSuccess: '调整成功',
    distributionSuccess: '发放成功',
    noMemberAdjust: '没有会员可以调整',
    noMemberDistribution: '没有会员可以发放',
    batchAdjustmentExpireTip: '请选择调整后的客户绑定有效期，如果不填写则有效期为永久',
    batchAdjustmentExpire: '批量调整有效期',
    upgradeConditions: '升级条件',
    orderAmount: '订单金额',
    orderNumber: '订单数量',
    buySpecifiedGoods: '购买指定商品',
    noUpgrade: '不自动升级',
    completeOrderAmount: '完成订单金额',
    completeOrderNumber: '完成订单数量',
    yuan: '元',
    dan: '单',
    mang: '满',
    upgradeTip: '满足升级条件后可升级为此会员等级',
    orderAmountTip: '升级条件请填写订单金额',
    orderNumberTip: '升级条件请填写订单数量',
    buySpecifiedGoodsTip: '升级条件请选择指定商品',
    addSpecifiGoods: '添加指定商品',
    noUpgradeTip: '您设置的等级不会自动升级到该等级下，请谨慎设置',
    orderPaidAmount: '支付金额',
    orderPaidAmountTipPrefix: '完成支付金额',
    upgradeContent: '权益描述',
    noReward: '无',
    rewardPoint: '积分',
    rewardCoupon: '优惠券',
    rewardVoucher: '代金券',
    memberExpiration: '会员有效期',
    permanentValidity: '永久有效',
    effectiveDays: '指定天数有效',
    expirationTip: '天后更新为：',
    upgradeReward: '升级奖励',
    rewardPointPrefix: '升级后可获得',
    selectCoupon: '选择优惠券',
    selectVoucher: '选择代金券',
    rewardType: '奖励类型',
    rewardItemName: '奖励名称',
    firstUpgradeReward: '升级首单奖励',
    upgradeFirstOrderReward: '奖励内容',
    firstRewardPointPrefix: '升级后首单完成可获得',
    replaceSpecificGoods: '替换商品',
    addSpecificGoods: '添加商品',
    specificGoodsName: '指定商品名称',
    upgradeRewardPointPlease: '请填写升级奖励积分',
    upgradeRewardCouponPlease: '请选择升级奖励优惠券',
    upgradeRewardVoucherPlease: '请选择升级奖励代金券',
    upgradeFirstRewardPointPlease: '请填写升级首单奖励积分',
    upgradeFirstRewardCouponPlease: '请选择升级首单奖励优惠券',
    upgradeFirstRewardVoucherPlease: '请选择升级首单奖励代金券'
  },
  order: {
    noNeedReturn: '无需退货',
    noReceivedGoods: '未收到货',
    receivedGoods: '已收到货',
    pending: '待发货',
    obligation: '待付款',
    waitReceiving: '待收货',
    completed: '已完成',
    closed: '已关闭',
    allOrders: '全部订单',
    payWays: '支付方式',
    orderTime: '下单时间',
    goodsType: '商品类型',
    keyWords: '关键词',
    goods: '商品',
    unitPrice: '单价',
    quantity: '数量',
    buyer: '买家',
    paidAmount: '实付款',
    paymentType: '支付',
    deliveryType: '配送',
    consignee: '收货人',
    consigneeAddress: '收货地址',
    phone: '手机号码',
    area: '所属地区',
    address: '详细地址',
    disposeWay: '处理方式',
    courierCompany: '快递公司',
    writeCourierCompany: '请选择快递公司',
    courierNumber: '快递单号',
    writeCourierNumber: '请输入快递单号',
    singlePackageDeliver: '整单包裹',
    multiplepackages: '分包裹发货',
    deliverySuccess: '发货成功',
    paySuccess: '付款成功',
    shippingSuccess: '收货成功',
    cancelSuccess: '取消成功',
    cancelSuccessTip: '订单取消退款成功，前往财务管理-付款单进行确认',
    commodityTitle: '商品标题',
    buyers: '买家',
    receiptorInfo: '收件人信息',
    realPayment: '金额',
    payment: '支付',
    delivery: '配送',
    operation: '操作',
    temporarilyNoData: '暂无数据',
    theOrderTime: '下单时间',
    orderCode: '订单编号',
    copy: '复制',
    invoice: '发货单',
    orderDetails: '订单详情',
    lookMore: '查看更多',
    remark: '备注',
    real: '实',
    theFreight: '含运费:',
    weChatPay: '微信支付',
    courier: '快递',
    stayPayment: '待付款',
    stayDelivery: '待发货',
    stayGoods: '待收货',
    partDelivery: '部分发货',
    confirmDelivery: '确认发货',
    confirmHasDelivery: '确认收货',
    confirmHasDeliveryAndInventoryOut: '确认收货并生成出库单',
    hasInventoryConfirmDeliveryTips: '确认该订单已收货，将订单状态改为已完成吗？',
    notInventoryConfirmDeliveryTips: '该订单未完成出库操作，可能会导致库存余额不准确，是否确认收货？',
    rejectReason: '驳回理由',
    rejectedSuccess: '驳回成功',
    modifyShippingAddress: '修改收件地址',
    confirmTheGoods: '确认收货',
    confirmpayment: '确认付款',
    closeOrder: '关闭订单',
    buyerRemark: '买家备注',
    goodsNumberTip: '商品总数量',
    lookTip: '可前往商品详情查看',
    orderChangePrice: '订单改价',
    refund: '退款',
    buyerTheOrder: '买家下单',
    theBuyerPayment: '买家付款',
    merchantShipping: '商家发货',
    orderComplete: '订单完成',
    orderClosed: '订单关闭',
    theOrderStatus: '订单状态',
    deliveryInformation: '发货信息',
    inDistribution: '配送方式',
    nameConsignee: '收货人',
    contact: '联系方式',
    logisticsSingleNumber: '物流单号',
    theParcel: '包裹',
    invoiceInformation: '发票信息',
    member: '会员',
    orderType: '订单类型',
    ordinaryOrder: '普通订单',
    TransactionNumber: '交易单号',
    sellerRemark: '卖家备注',
    commodityInformation: '商品信息',
    virtualDeliveryGoodsInfo: '虚拟发货信息',
    externalTransactionNumber: '外部交易单号',
    specs: '规格',
    salePrice: '售卖价',
    subtotal: '小计',
    status: '状态',
    deliveryStatus: '出货状态',
    orderInfo: '订单信息',
    goodsTitle: '商品标题',
    writeReceiptorName: '请填写收货人',
    writeReceiptorPhone: '请填写手机号码',
    writeAddress: '请填写详细地址',
    writeArea: '请选择所属地区',
    toDelivery: '已发货',
    noDelivery: '未发货',
    choose: '选择',
    yesChoose: '已选择',
    changePrice: '改价',
    determine: '确定',
    cancel: '取消',
    enterNumber: '请输入价格',
    tip: '提示',
    businessmenNote: '商家备注',
    closeReason: '关闭原因',
    deliveryContent: '确认此单已收到货了吗 ?',
    payContent: '确认此单已付款吗？',
    confirmClose: '确认关闭',
    cashDelivery: '货到付款 ',
    tobeConfirmed: '待确认',
    confirme: '确认',
    through: '通过',
    confirmThrough: '确认通过',
    rejected: '驳回 ',
    startDate: '下单时间起',
    endDate: '下单时间止',
    keyPlaceholder: '订单号 姓名 电话号码 商品名称',
    activistStatus: '维权状态',
    copySuccess: '复制成功',
    comfirmeContent: '此订单确认进行了"线下支付"吗',
    rejectedContent: '此订单确认驳回吗，驳回后订单将关闭',
    confirmRejected: '确认驳回',
    friendlyTip: '友情提示',
    placeholderText: '请输入内容',
    moneyTip: '您未输入金额',
    deliveryTip: '请选择发货的包裹',
    goodsTip: '选择需要发货的商品',
    logisticsInfo: '物流信息',
    totalAmount: '订单金额',
    deliveryInfo: '出库信息',
    inventory: '出库单',
    time: '时间',
    payMoney: '支付金额',
    second: '秒',
    viewLogisticsInfo: '查看物流信息',
    refresh: '刷新',
    realGoods: '实',
    virtualGoods: '虚',
    el: '单号或代码错误',
    noRoute: '暂无轨迹',
    expReceive: '快递收件',
    expWay: '在途中',
    expErrorPackage: '问题件',
    expTroublePackage: '疑难件',
    expBack: '退回',
    expSigned: '签收',
    expBackSigned: '退件签收',
    sending: '派送中',
    unknown: '未知状态',
    remainingNumber: '剩余数量',
    deliveryNumber: '发货数量',
    PleaseEnterNumber: '请输入数字 ',
    PleaseEnterDeliveryNumber: '请填写发货数量',
    residueQuantityInsufficient: '商品剩余数量不足',
    superDelivery: '超发',
    beenCancelled: '已取消',
    confirmShipment: '确认发货吗 ',
    di: '第',
    hang: '行“',
    superDeliveryTip: '”商品超发, 是否继续?',
    pick: '自提',
    weChat: '微信支付',
    delivetyPay: '货到付款',
    shopPick: '到店自提',
    competed: '已完成',
    readyComplete: '备货完成 ',
    verification: '确认提货',
    checkPick: '查看提货码 ',
    pickCode: '提货码：',
    pleasePickCode: '请输入提货码',
    pickSuccess: '提货成功',
    pickError: '提货失败',
    StockSuccess: '确认完成备货吗？',
    stockCode: '备货成功，提货码为：',
    payCertificate: '支付凭证',
    offlinePayment: '线下转账',
    printOrderDelivery: '打印商城发货单',
    printOrder: '打印商城订单',
    mallOrderCode: '商城订单号',
    afterSalesProtection: '售后/维权 ',
    refundCompleted: '已退款完成 ',
    afterSalesDeail: '售后详情',
    afterSalesApplicationTime: '售后申请时间 ',
    activisting: '维权中',
    deliveryDeail: '发货单详情',
    deliverysTip: '选择的商品中，至少选择一件数量进行发货',
    activisted: '维权完成',
    selfDelivery: '仓库备货',
    selfDeliverySubmit: '提交备货，待仓库处理',
    orderCloseReason: '关闭原因',
    changeTip: '优惠金额不能超过订单金额,且应付金额不能为0',
    orderTotalAmount: '订单总额',
    discountAmount: '优惠金额',
    pointDeductAmount: '积分抵扣',
    pointAmount: '积分',
    payableAmount: '应付金额',
    paidAmounts: '实付金额',
    deductibleAmount: '定向预付',
    changeTipNull: '请输入大于0的优惠金额',
    changeText: '确认修改订单价格吗?',
    mang: '满',
    couponsDetails: '优惠使用',
    deductionUse: '抵扣使用',
    emptyText: '暂无信息',
    preferentialPrice: '优惠单价',
    goodsAmount: '商品总额',
    freight: '运费',
    goodsDiscountAmount: '商品优惠金额',
    freightDiscountAmount: '运费优惠金额',
    adjustGoodsAmount: '调整商品总额',
    adjustFreight: '调整运费金额',
    deliveryTips: '发货数量大于商品数量',
    giftChildGoods: '子件商品标题',
    productName: '产品名称',
    orderOverDelivery: '订单已超额发货，请检查确认发货信息',
    takeDelivery: '确认收货',
    takeDeliveryTip: '确认该订单已收货，将订单状态改为已完成吗？',
    takeDeliverySuccess: '确认收货成功',
    takeDeliverySelfPickupTip: '该订单是否确认提货？',
    closeOrderReturn: '关闭订单并退款',
    comfireCloseOrderReturn: '确认关闭并退款',
    isOutDelivery: '是否委外发货',
    outRequireCode: '委外需求编码',
    outOrderCode: '委外订单编码',
    errorExpressNumber: '单号或代码错误',
    exportMallOrder: '商城订单导出',
    purchaser: '下单人',
    addReturn: '退换货',
    addReturnTitle: '添加退换货',
    noInventoryInfo: '暂无出库信息',
    orderDeliveryCode: '商城发货单',
    inventoryStatus: '出库状态',
    outOrderStatus: '委外单状态',
    voucherDeductAmount: '代金券抵扣',
    refundPartAmount: '订单部分退款',
    refundAllAmount: '订单全额退款',
    buyerDelete: '买家已删除',
    addFreeGoods: '赠品',
    exchangeGoods: '换品',
    addFreeGoodsTitle: '添加赠品',
    selectSku: '选择商品',
    addExchangeGoodsTitle: '订单换品',
    orderGoods: '订单商品',
    exchangeQuantity: '换品数量',
    orderExchangeAmount: '原订单更换商品合计',
    newExchangeAmount: '新品合计',
    retailPrice: '零售价',
    memberPrice: '会员价',
    memberSetttlementPrice: '结算价',
    exchangeUnitPrice: '换品单价',
    autoAdjustPrice: '调整单价',
    confirmExchangeGoods: '确认进行订单换品，换品后需重新操作发货',
    confirmExchangeGoodsLessAmountGeneReturn: '更换商品与原单商品存在价差，退差价将同步生成售后单，换品后需重新操作发货',
    confirmExchangeGoodsLessAmountNotReturn: '更换商品与原单商品存在价差，如需退差价勾选退差价或手动操作售后进行差价退款，换品后需重新操作发货',
    confirmExchangeGoodsOverAmount: '更换商品比原单商品贵，是否确认更换',
    confirmAddFreeGoods: '确认添加赠品',
    freeGoodsPlease: '请选择赠品',
    exchangeGoodsPlease: '请选择订单商品进行更换',
    newExchangeGoodsPlease: '请选择更换的新品',
    newExchangeRealAmount: '新品会员价合计',
    exchangeGoodsAmountLessThen: '原订单更换商品合计大于新品合计，请调整新商品单价',
    exchangeGoodsAmountGreatThen: '原订单更换商品合计小于新品合计, 请调整商品单价',
    exchangeGoodsDifferenceAmount: '换品价差',
    isReturnDifferenceAmount: '是否生成价差售后单',
    editReturnTitle: '审核售后单',
    updateWarehouse: '发货仓库',
    updaetWarehouseTips: '请调整商品的发货仓库，调整后将影响系统可用库存',
    batchUpdateWarehouse: '批量设置',
    orderDeliveryType: '发货方式',
    queryOrderRange: '订单范围',
    customerOrder: '客户订单',
    memberOrder: '会员订单',
    importOrderExpress: '订单发货导入',
    warehouseMap: '仓库映射',
    expressCompanyMapTips: '无需快递公司映射请直接跳转下一步',
    warehouseMapTips: '无需仓库映射请直接跳转下一步',
    sourceWarehouse: '导入仓库名称',
    targetWarehouse: '映射仓库名称',
    importDeliveryConfig: '导入设置',
    batchSetExpressCompany: '批量设置快递公司',
    batchSetWarehouse: '批量设置仓库',
    batchSetDataPlease: '请选择批量设置订单',
    batchSetExpressCompanyPlease: '请选择要设置的快递公司',
    batchSetWarehousePlease: '请选择要设置的仓库',
    mapExpressCompanyPlease: '请选择映射快递公司',
    mapWarehousePlease: '请选择映射仓库',
    isPresale: '预售商品',
    customOrderCode: '自定义编码',
    updateFreight: '运费设置',
    viewFreightCertificate: '运费凭证',
    updateFreightTips: '是否确定提交运费金额，提交之后将不允许修改',
    notConfirm: '未确认',
    hasPaid: '已支付',
    notPaid: '未支付',
    adjustPriceMode: '改价方式',
    adjustOrderAmount: '按订单',
    adjustProductAmount: '按产品',
    oldOrderUnitPrice: '订单原价',
    oldDiscountUnitPrice: '优惠单价（改价前）',
    newDiscountUnitPrice: '优惠单价（改价后）',
    oldSubtotal: '小计（改价前）',
    newSubtotal: '小计（改价后）',
    allGoods: '全部商品',
    includeFreight: '含运费',
    buyerHasReturn: '买家已退回',
    buyerReturnMethod: '买家退回方式',
    buyerReturnByExpress: '快递',
    buyerReturnByOther: '其它',
    realGoodsAmount: '应付商品总额',
    realFreight: '应付运费',
    buyerHasReturnTips: '确认用户已退回商品',
    paidAmountSum: '实付总额',
    customerManager: '业务员',
    deliveryOperator: '发货经办人'
  },
  expressSettlement: {
    cashByPost: '寄付现结',
    payOnDelivery: '到付',
    monthlyByPost: '寄付月结'
  },
  portal: {
    protocol: '小店入驻协议',
    storeSettings: '小店设置',
    storeAgreement: '小店协议',
    isAuditShopGoods: '是否审核小店商品',
    save: '保存',
    setting: '基础设置',
    registerCode: '小店申请码',
    generate: '生成',
    regenerate: '重新生成',
    promotionCode: '推广码',
    productReview: {
      audit: '审核',
      overrule: '驳回',
      goodsDetails: '详情',
      reasonDismissal: '请填写驳回理由',
      passAudit: '确定要通过审核吗',
      notAudit: '未审核',
      pendingReview: '待审核',
      review: '已审核',
      reject: '已驳回',
      withdraw: '撤回',
      sureDelete: '确定要删除吗',
      sureWithdraw: '确定要撤回吗',
      sureOverrile: '确定要驳回吗'
    },
    manage: {
      portalLogo: '小店Logo',
      portalTitle: '小店名称',
      nickName: '小店店主',
      createTime: '创建时间',
      auditDate: '审核时间',
      auditorName: '审核人',
      deleteTips: '是否确认【删除】该门户申请？',
      auditTips: '是否确定保存数据并【通过】审核？',
      portalStatus: '状态',
      startDate: '申请时间起',
      endDate: '申请时间止',
      queryKeyword: '门户名称 证件号码 手机号码',
      addPortal: '添加门户',
      editPortal: '编辑门户',
      viewPortal: '查看门户',
      notAudit: '待审核',
      audited: '已审核',
      saveData: '保存',
      saveAndAuditData: '保存并通过',
      reject: '驳回',
      rejectRemark: '驳回理由',
      phone: '手机号码',
      pushdownGoods: '添加必选',
      portalGoodsManage: '必选商品',
      cancelPortalGoods: '取消必选',
      loginName: '登录名',
      password: '密码',
      newAccount: '新增小店账号',
      goodsManage: '必选商品管理',
      subMallRequiredGoods: '小店必选商品',
      applicantName: '名称',
      certificateNumber: '证件号码',
      attachmentList: '身份证/营业执照',
      portalInfo: '门户信息',
      portalGoodsInfo: '小店必选商品',
      applicantNameTips: '姓名/企业名称',
      certificateNumberTips: '身份证号/统一社会信用代码',
      phoneTips: '用来创建门户账号',
      rejectTips: '是否确认【驳回】该门户申请',
      rowAuditTips: '是否确认审核【通过】该门户申请',
      revokeTips: '是否确认【撤回】该门户申请到待审核状态',
      revoke: '撤回',
      deletePublicGoodsTips: '是否确定【移除】选中的商品，删除后小店将不展示',
      deleteGoods: '移除',
      productSort: '产品分类',
      productBrand: '产品品牌',
      specifiedProduct: '指定产品',
      permissionsConfig: '权限设置 ',
      allProduct: '全部产品',
      custom: '自定义 ',
      passWordTip: '确认重置密码吗',
      passWordText: '您的新密码是',
      passWordSuccess: '密码重置成功',
      remove: '清空'
    },
    exception: {
      applicantNamePlease: '请输入姓名/企业名称',
      certificateNumberPlease: '请输入身份证/统一社会信用代码',
      phonePlease: '请输入手机号码',
      correctPhonePlease: '请输入正确手机号码'
    },
    distributionOrder: {
      importTaskDetails: '订单信息',
      importOrderDetails: '订单明细',
      waiting: '等待导入',
      importing: '导入中',
      completed: '已完成',
      importCompleted: '导入完成',
      portalSubmit: '待确认',
      tenantConfirm: '已确认',
      portalCanceled: '已取消',
      tenantDelivery: '已发货',
      importSuccess: '导入成功',
      importFail: '导入失败',
      notConfirm: '待确认',
      notDelivery: '待发货',
      delivered: '已发货',
      confirmOrderBatch: '订单确认',
      confirmDeliveryBatch: '订单发货',
      taskId: '代发订单编号',
      customer: '客户',
      attachment: '附件',
      importCount: '订单数量',
      successCount: '成功数量',
      failCount: '失败数量',
      importTime: '创建时间',
      importStatus: '状态',
      orderId: '子订单编号',
      orderDate: '订单日期',
      receiptorName: '收件人',
      receiptorPhone: '收件人电话',
      receiptorAddress: '收件地址',
      productCode: '产品编码',
      productName: '产品名称',
      goodsTitle: '商品名称',
      productModel: '规格型号',
      goodsModel: '商品规格',
      productUnit: '计价单位',
      unitPrice: '单价',
      quantity: '数量',
      outDeliveryQuantity: '委外数量',
      subtotal: '订单金额',
      orderStatus: '订单状态',
      confirmOrder: '确认订单',
      batchSet: '批量设置',
      setCompany: '设置所属公司',
      sourceSku: '原商品',
      targetSku: '替换商品',
      setProductType: '商品替换方式',
      setProductTypeDirect: '直接替换',
      setProductTypeBySelect: '相同商品替换',
      pleaseSelectOrder: '请选择订单后批量操作',
      deliveryOrder: '订单发货',
      productStock: '库存',
      orderQuantity: '订单数量',
      outDelivery: '委外数量',
      lastStep: '上一步',
      nextStep: '下一步',
      isGeneDelivery: '是否生成发货单',
      deliveryType: '发货方式',
      oneSkuOneDelivery: '一单一品（每个发货单商品数量为1）',
      oneOrderOneDelivery: '按订单发货（每个订单生成一个发货单）',
      companyPlease: '请选择所属公司',
      dataEmpty: '数据为空',
      taskOrderTab: '订单明细',
      taskDeliveryTab: '发货明细',
      taskOutRequireTab: '委外需求',
      taskOutOrderTab: '委外订单',
      taskReceivableTab: '应收款',
      deliveryId: '发货单编号',
      orderDetails: '订单明细',
      productMap: '产品映射',
      orderConfirm: '订单确认',
      confirmOrderTips: '代发订单信息核对完成，是否确认订单？',
      deliveryOrderTips: '确认发货？',
      isGeneReceivable: '是否生成应收款',
      currency: '货币',
      deliveryDate: '发货日期',
      taxRate: '税率',
      taxRatePlease: '请选择税率',
      stockNotEmpty: '库存不足',
      orderAmount: '订单总额',
      productQuantitySum: '产品总数量',
      inventoryBatch: '批量出库',
      deliveryDetails: '发货单明细',
      quantityMap: '数量确认',
      confirmInventory: '确认出库',
      saveInventory: '保存出库单',
      confirmInventoryTips: '是否确认批量生成出库单并完成出库？',
      saveInventoryTips: '是否确认批量生成出库单？',
      selectDataEmpty: '请勾选发货单后操作',
      queryTaskIdEmpty: '请输入代发订单编号后操作',
      selectedRange: '勾选发货单范围',
      queryTaskIdRange: '代发订单编号范围',
      enterQueryTaskId: '请输入代发订单编号',
      productQuantitySumOver: '相同产品数量之和不能大于产品总数量',
      completeTaskTips: '确认该代发订单已完成？',
      notInventory: '未出库',
      partInventoried: '部分出库',
      inventoried: '已出库',
      distributionDelivery: '代发发货单',
      viewTaskOrder: '查看代发订单',
      quantityRate: '已出库数量/产品总数',
      dataImport: '数据导入',
      dataMap: '数据映射',
      goodsMap: '商品映射',
      dataPreview: '数据预览',
      excelFile: 'Excel文件',
      upLoad: '上传',
      tipsTitle: '导入注意事项',
      downLoadTemplate: '下载模板',
      tips1: '建议使用官方Excel模板导入数据',
      tips2: '上传EXCEL后，请准确填写标题行号和数据起始行号',
      tips3: '请准确填写数据映射关系并检查数据预览是否相符',
      titleRowNum: '标题行号',
      dataRowNum: '数据行号',
      last: '上一步',
      next: '下一步',
      targetTitleName: '导入列标题',
      sourceTitleName: '映射对象',
      sourceGoodsTitle: '导入商品名称',
      sourceGoodsModel: '导入商品规格',
      targetGoodsTitle: '关联商品名称',
      targetGoodsModel: '关联商品规格',
      rowNum: '序号',
      confirmSubmit: '确认提交',
      fileError: '上传文件错误',
      mapGoodsPlease: '请选择映射商品',
      emptyImportData: '导入数据为空',
      quantityPlease: '请填写订单数量',
      taskRecordTab: '导入记录',
      recordQueryKeywords: '请输入查询条件',
      reImport: '重新导入',
      recordImportStatus: '导入状态',
      failMsg: '失败原因',
      failImportRecordPlease: '请选择导入失败订单',
      importOrder: '导入订单',
      expressCompanyMap: '快递公司映射',
      addSalesOrder: '添加订单',
      mapProductPlease: '请选择映射产品',
      mapExpressPlease: '请选择映射快递',
      addressQueryKeywords: '产品 收件人信息',
      sourceProductName: '导入产品名称',
      sourceProductModel: '导入产品规格型号',
      targetProductName: '导入产品名称',
      targetProductModel: '导入产品规格型号',
      splitDeliveryType: '自动拆分发货单方式',
      splitDeliveryByAddress: '按收件人信息拆分（相同收件人信息拆分到一个发货单）',
      splitDeliveryByProduct: '按产品拆分（每个产品拆分到一个发货单）',
      splitDeliveryNone: '不拆分（只生成一个发货单）',
      parseAddress: '解析收件地址',
      parseAddressPlease: '请先进行收件地址解析操作',
      parseAddressGoing: '正在解析收件地址',
      parseAddressPart: '部分收件地址解析失败，请重新解析/手动选择省市区',
      parseAddressFinish: '收件地址解析完成，请点击下一步进行',
      parseAddressArea: '解析收件地址省市区',
      parseAddressDetails: '解析收件详细地址'
    }
  },
  fitment: {
    pageInfo: '页面信息',
    save: '保存',
    search: '搜索框',
    banner: '轮播图',
    menus: '菜单',
    notice: '公告',
    product: '商品（多列）',
    threeProduct: '商品（三列）',
    pageTitle: '页面标题',
    singleText: '单行文本',
    textAlignment: '文字对齐',
    center: '居中',
    left: '居左',
    textColor: '文字颜色',
    iconColor: '图标颜色',
    backgroundColor: '背景颜色',
    componentBack: '组件背景',
    imgTip: '添加图片 (最多10张，可拖动排序)',
    numberTip: '最多10张，可拖动排序',
    sort: '排序',
    name: '名称',
    addLink: '添加链接',
    updateLink: '修改链接',
    deleteLink: '删除链接',
    addImg: '添加图片',
    size: '建议尺寸',
    marginTop: '上边距',
    marginBottom: '下边距',
    marginLeftRight: '左右边距',
    rounde: '圆角',
    lineNum: '每行个数',
    number: '显示数量',
    goodsColor: '商品名称颜色',
    markingUnitColor: '划线价颜色',
    salesColor: '售价颜色',
    quantityColor: '销量颜色',
    third: '3个',
    four: '4个',
    addMenu: '添加菜单',
    speed: '滚动速度',
    chooseGoods: '选择商品',
    manuallyChoose: '手动选择',
    chooseCategory: '选择分类',
    marketingAttributes: '营销属性',
    limitPerfer: '限时特惠',
    distributionApplication: '分销申请',
    goodsList: '商品列表',
    addGoods: '添加商品',
    addCategory: '添加分类',
    newGoods: '新品商品',
    hotGoods: '热卖商品',
    recommendGoods: '推荐商品',
    categoryName: '分类名称',
    operation: '操作',
    titleCup: '标题组件',
    titleFontSize: '标题大小',
    titleText: '标题文本',
    update: '变更',
    delete: '删除',
    goodsTitle: '商品标题',
    goodsImg: '商品图片',
    goodsPrice: '商品价格',
    goodsInventory: '商品库存',
    categoryImg: '分类图片',
    categoryLevel: '分类层级',
    oneLevel: '一级',
    twoLevel: '二级',
    threeLevel: '三级',
    choose: '选择',
    shoppingCart: '购物车 ',
    order: '订单管理',
    address: '地址管理',
    goodsCategory: '商品分类',
    myInfo: '我的信息',
    giftPlan: '礼盒定制',
    distributionCenter: '分销中心',
    giftList: '礼盒列表',
    coupons: '领券中心',
    page: '商城页面',
    goods: '商品',
    category: '分类',
    titlePage: '页面标题',
    pageNotes: '页面备注',
    backGrountPage: '页面背景',
    newProducts: '新品商品',
    hotsGoods: '热卖商品',
    recommendedProducts: '推荐商品',
    shoppingCentre: '商城',
    announcement: '资讯',
    sold: '销量',
    item: '件',
    nameProduct: '这是商品名称',
    dashPrice: '999',
    salePrice: '划线价',
    inventory: '99',
    goodsCup: '商品组件',
    goodsBackgroundColor: '商品背景颜色',
    goodsStyle: '商品内容样式',
    goodsPaddingTopBottom: '商品上下间距',
    goodsPaddingLeftRight: '商品左右间距',
    singlePicture: '单图',
    video: '视频',
    imageSpace: '图片间距 ',
    videos: '视频',
    coverPhoto: '封面图片',
    singleProduct: '商品（单列）',
    editGoods: '更换商品',
    videoTip: '您的浏览器不支持视频播放',
    componentHeight: '组件高度',
    isDisplayTitle: '显示标题组',
    isMoreLink: '显示链接文本',
    show: '显示',
    hidden: '隐藏',
    linkText: '链接文本',
    jumpLink: '跳转链接',
    titleTextHeight: '文本高度',
    titleTextLocation: '文本对齐',
    right: '居右',
    pleaseCategory: '请选择分类',
    pleaseJumpMallPage: '请选择跳转的商城页面',
    pleaseJumpGoods: '请选择跳转的商品',
    pleaseJumpCategory: '请选择跳转的分类',
    pleaseJumpGift: '请选择跳转的礼盒',
    more: '更多',
    reduction: '还原',
    goodsColumn: '商品样式',
    doubleColumn: '双列',
    threeColumn: '三列',
    picture: '图片魔方',
    pictureStyle: '样式',
    customConfig: '自定义筛选',
    priceRange: '价格区间',
    maxGoodsTips: '添加商品 (最多50种，可拖动排序)',
    brand: '品牌',
    brandTitleComponent: '标题组件',
    paddingLeftRight: '左右边距',
    brandComponent: '品牌组件',
    blockItemMarginTopBottom: '上下间距',
    blockItemPaddingLeftRight: '左右间距',
    blockImageHeight: '图片高度',
    blockTextHeight: '文本高度',
    showBrandSelect: '选择品牌',
    addPriceRange: '添加价格区间',
    orderByRule: '排序方式',
    default: '默认',
    priceAsc: '按价格从小到大',
    priceDesc: '按价格从大到小',
    salesQuantityAsc: '按销量从小到大',
    salesQuantityDesc: '按销量从大到小',
    pointZone: '积分专区'
  },
  lock: {
    addLock: '添加库存锁定',
    viewLock: '查看库存锁定',
    editLock: '编辑库存锁定',
    lockNumber: '锁定数量',
    lockReason: '锁定理由',
    lockProduct: '锁定产品',
    lockNumberTip: '请填写锁定数量',
    lockReasonTip: '请填写锁定理由',
    lockProductTip: '请填写锁定产品',
    selectProduct: '请选择产品'
  },
  tinymce: {
    video: '视频',
    size: '尺寸',
    forSize: '按尺寸',
    forScale: '按百分比',
    image: '图像',
    imageUpload: '图片上传',
    localUpload: '本地上传',
    webImage: '网络图片',
    imageUploadTip: '只能上传jpg/png/gif文件，且不超过',
    pleaseEntryWebImageUrl: '请填写网络图片链接',
    pleaseWaitImageUploadCompleted: '请等待图片上传完成'
  },
  kf: {
    avatar: '客服头像',
    kfName: '客服名称',
    openKfid: '客服ID',
    default: '默认',
    servicer: '接待人',
    addTitle: '添加微信客服',
    editTitle: '编辑微信客服',
    kfInfo: '客服信息',
    deleteTips: '是否确认删除该微信客服?',
    kfNameNotEmpty: '客服名称不能为空',
    mediaIdNotEmpty: '缺少mediaId,请重新上传头像',
    servicerNotEmpty: '请选择客服接待人',
    setDefault: '设置默认'
  },
  notifyConfig: {
    scene: '场景',
    customer: '客户订单',
    member: '会员订单',
    mallOrderOfflineSubmit: '订单-线下转账提交',
    paySuccess: '订单支付成功',
    rechargeOfflineSubmit: '余额充值-线下转账提交',
    rechargeSuccess: '余额充值成功',
    financeConfirm: '财务确认',
    refundApply: '退换货申请',
    businessManage: '客户业务员',
    assign: '指定人',
    role: '角色',
    monthPay: '订单-挂账月结提交',
    inventoryIn: '入库通知',
    inventoryOut: '出库通知',
    pendingFinanceReceipt: '待确认收款单',
    finishedFinanceReceipt: '已确认收款单',
    pendingSalesOrder: '待审核销售订单',
    auditedSalesOrder: '已审核销售订单',
    dailyPaper: '业绩日报',
    wework: '企业微信',
    customTemplate: '自定义模板',
    templateCode: '模板编码',
    templateName: '模板名称',
    templateType: '模板类型',
    templateContent: '模板内容',
    formEmptyText: '从左侧拖拽来添加内容',
    component: '组件',
    property: '属性',
    title: '标题',
    titleBold: '标题加粗',
    titleColor: '标题颜色',
    bold: '加粗',
    value: '文本',
    valueBold: '文本加粗',
    valueColor: '文本颜色',
    hrefTitle: '链接文本',
    hrefValue: '链接地址',
    primaryTitleNotEmpty: '一级标题不能为空',
    secondaryTxtNotEmpty: '二级普通文本不能为空',
    secondaryTitleTxtNotEmpty: '二级标题、二级文本不能为空',
    secondaryHrefNotEmpty: '二级链接不能为空',
    setting: '设置',
    notifyObject: '通知对象',
    systemPresetParam: '系统预设参数'
  },
  enterprise: {
    common: {
      sales: '销售方',
      purchase: '采购方',
      pending: '待审核',
      audited: '已审核',
      reject: '驳回',
      executeResultInfo: '共{0}条记录，成功：{1}条，跳过：{2}条',
      supplyPrice: '供货价'
    },
    config: {
      inventorySecondmentSetting: '库存借调参数设置',
      enableStockIncludeOnTheWay: '可用库存是否包含在途'
    },
    apply: {
      apply: '互联申请',
      current: '当前',
      identify: '我方企业身份',
      enterpriseName: '我方企业名称',
      orgId: '我方OrgID',
      oppositeIdentify: '对方企业身份',
      oppositeEnterpriseName: '对方企业名称',
      oppositeOrgId: '对方OrgID',
      salesOrgId: '客户企业组织ID',
      purchaseOrgId: '供应商 企业组织ID',
      enterpriseTypeNotEmpty: '请选择企业身份',
      relatedCustomer: '关联客户',
      relatedSupplier: '关联供应商',
      addTitle: '添加企业互联申请',
      auditTitle: '审核企业互联申请',
      revokeTitle: '撤销企业互联申请',
      viewTitle: '查看互联申请',
      auditOrgIdNotEmpty: '请输入互联企业组织ID',
      auditOrgIdCanNotBeCurrentEnterprise: '互联方不能是当前企业',
      customerIdNotEmpty: '请关联客户',
      supplierNotEmpty: '请关联供应商',
      rejectReason: '驳回原因',
      applyTime: '申请时间',
      rejectReasonNotEmpty: '请输入驳回理由',
      deleteTips: '是否确认删除该互联申请?',
      rejectTips: '是否确认拒绝该互联申请?',
      authorization: '授权',
      range: '产品授权范围',
      none: '无',
      all: '全部产品',
      custom: '自定义',
      assignedBrand: '指定产品品牌',
      assignedProduct: '指定产品',
      assignedSort: '指定产品分类',
      mallRange: '商品授权范围',
      mallAssignedProduct: '指定商品',
      mallAssignedSort: '指定商品分类',
      assignedSalesLabel: '指定营销标签',
      authorizationInfo: '授权信息',
      productRelation: '产品关联',
      goodsRelation: '商品关联',
      selfProduct: '我方产品',
      oppositeProduct: '对方产品',
      addSysProduct: '添加系统产品',
      addSpu: '添加商品',
      our: '我方',
      opposite: '对方',
      selfSpuName: '我方商品名称',
      oppositeSpuName: '对方商品名称',
      selfSpu: '我方商品',
      oppositeSpu: '对方商品',
      chooseSpu: '选择商品',
      pleaseChooseProduct: '请选择产品',
      authorizedProduct: '授权产品',
      authorizedSpu: '授权商品',
      oppositeAuthorizedProduct: '对方授权产品',
      oppositeAuthorizedSpu: '对方授权商品',
      associate: '关联',
      drawerInto: '引入',
      pass: '审核通过',
      enterpriseApplyAudited: '互联申请已审核 无法删除',
      syncPrice: '同步价格',
      syncAllPrice: '同步全部产品价格',
      syncEmptyPrice: '仅同步产品价格为空',
      queryKeyword: '产品编码、产品名称',
      syncPurchasePriceConfirmTips: '是否确定同步互联产品价格到采购价格?',
      syncSalesPriceConfirmTips: '是否确定同步互联产品价格到销售价格?',
      productCategoryTips: '请选择产品分类',
      auto: '自动',
      manual: '手动',
      pleseaChooseProduct: '请选择商品',
      productUnitTips: '请选择计价单位',
      batchQuote: '批量引入',
      selectedSpuEmpty: '请选择要引入商品',
      spuConfig: '引入商品设置',
      pullBatch: '批量引入',
      hasPullSpuNames: '以下商品已被引入',
      errorPullSpuNames: '以下商品引入失败',
      productNotRelatedSpuNames: '系统产品未关联',
      productUnitMatch: '计价单位匹配类型',
      productUnitMatchTips: '计价单位自动匹配说明：计价单位默认按名称进行自动匹配,如果没有匹配上,则自动创建',
      autoAssociate: '自动关联',
      syncType: '同步类型',
      matchWithRelation: '按互联方映射关系自动匹配',
      matchWithCustom: '自定义匹配',
      matchFields: '匹配字段',
      matchSuccessTip: '匹配完成，共自动匹配{0}条产品数据',
      disassociate: '取消关联',
      disassociateTips: '确认取消关联产品'
    },
    interconnect: {
      data: '数据',
      iamSales: '我是销售方',
      iamPurchase: '我是采购方',
      noConnect: '未连接',
      connect: '已连接',
      auth: '授权',
      viewTitle: '查看互联信息',
      editTitle: '编辑互联信息',
      authTitle: '数据授权',
      syncSalesPrice: '同步销售价格',
      syncPurchasePrice: '同步采购价格',
      productQuote: '产品引入',
      unboundEnterpriseInterconnectTips: '是否确定取消对该企业的互联？',
      authSetting: '授权设置',
      stockShare: '库存共享',
      stockShareTip: '开启库存共享后将共享可用库存数据给互联方',
      businessAnalysisShareTip: '该数据共享后，互联方可以获取到相关的销售数据用于经营分析',
      warehouseShare: '仓库授权',
      warehouseRelation: '仓库互联',
      salesWarehouse: '对方授权仓库',
      purchaseWarehouse: '我方关联仓库',
      businessAnalysisShare: '经营数据共享',
      batchSyncStock: '批量同步可用库存到虚拟库存',
      salesEnterpriseName: '供应商',
      interconnentEnableStock: '供应商可用库存',
      purchaseVirtualStock: '商品虚拟库存'
    },
    order: {
      orderCode: '互联订单编号',
      businessCode: '采购订单编码',
      customer: '客户名称',
      queryKeyword: '采购单编码、客户名称',
      editTitle: '编辑互联订单',
      viewTitle: '查看互联订单',
      pending: '待确认',
      audited: '已确认',
      save: '保存',
      saveAndConfirm: '保存并确认',
      confirmTips: '该订单是否已确认,确认后将生成待审核销售订单',
      successTips: '该订单已确认,已生成待审核的销售订单,请联系相关人审核',
      confirmOrder: '确认订单',
      purchaser: '采购方',
      supplier: '供应商',
      salesOrderCode: '销售订单编码',
      deliveryDate: '交货日期'
    },
    inventorySecondment: {
      id: '借调单号',
      secondmentForPurchase: '调入方',
      secondmentForSales: '调出方',
      secondmentForSalesEnterprise: '调出方',
      secondmentForPurchaseEnterprise: '调入方',
      addTitle: '添加库存借调',
      editTitle: '编辑库存借调',
      viewTitle: '查看库存借调',
      auditTitle: '库存借调审核',
      secondmentCustomer: '调出方',
      secondmentPurchase: '调入方',
      purchaseAuditTips: '确认审核借调单，审核通过后请等待被借调方受理并出库',
      salesRecallTips: '确认撤回到草稿状态',
      deleteTips: '确认删除该借调单',
      salesAuditTips: '确认审核该借调单，通过后请在【出库通知-库存借调】安排出库',
      salesRejectTips: '确认驳回该借调单',
      purchaseOperator: '经办人',
      inventoryInRate: '入库进度',
      inventoryOutRate: '出库进度',
      salesInWarehouseStock: '在库库存',
      printTitle: '库存借调打印',
      salesEnableStock: '可用库存',
      orderDate: '借调日期',
      secondmentCustomerPlease: '请选择调出方',
      baseInfo: '基本信息',
      inventoryOutInfo: '出库信息',
      inventoryInInfo: '入库信息',
      salesRevokeTips: '确认撤销借调单',
      orderDatePlease: '请选择借调日期',
      operatorName: '经办人',
      inventoryInWarehousePlease: '请选择入库仓库',
      secondmentDetailsForPurchase: '调入方明细',
      secondmentDetailsForSales: '调出方明细',
      inWarehouse: '调入仓库'
    }
  },
  excelImportSetting: {
    excelFile: 'Excel文件',
    upLoad: '上传',
    tipsTitle: '导入注意事项',
    downLoadTemplate: '下载模板',
    tips1: '建议使用官方Excel模板导入数据',
    tips2: '上传EXCEL后，请准确填写标题行号和数据起始行号',
    tips3: '请准确填写数据映射关系并检查数据预览是否相符',
    titleRowNum: '标题行号',
    dataRowNum: '数据行号',
    fileError: '文件类型错误或者未按模板格式填写',
    fileUploading: '文件加载中',
    pleaseSelectTheExcelToImport: '请选择要导入的Excel'
  },
  dataMappingSetting: {
    targetTitleName: '映射对象',
    sourceTitleName: '导入列标题'
  },
  expressCompanyMappingSetting: {
    expressCompanyMap: '快递公司映射',
    sourceExpressCompany: '导入快递公司名称',
    targetExpressCompany: '映射快递公司名称'
  },
  expressManage: {
    placeOrder: '快递下单',
    config: {
      jdExpressConfig: '京东物流配置',
      customerName: '客户名称',
      customerCode: '客户编码',
      customerCodeTip: '商家编码/青龙业主号/配送编码/月结编码',
      refreshToken: 'Refresh Token',
      auth: '授权',
      refreshTokenTip: '请点击授权按钮获取Refresh Token',
      sfExpressConfig: '顺丰物流配置',
      sfMonthlyCard: '月结卡号',
      monitorConfig: '监控设置',
      flywireStartAreaConfig: '飞线图起始位置',
      overtimeConfig: '超时设置',
      firstDeliveryOvertime: '首次走件超时',
      deliveryStopOvertime: '运输停止超时',
      dispatchOvertime: '派送超时',
      signOvertime: '签收超时',
      smsConfig: '通知设置',
      defaultSmsScheme: '物流短信配置',
      smsSign: '短信签名',
      smsSignTips: '如没有选择短信签名，则使用客户短信签名或系统默认短信签名',
      afterCollection: '揽收后',
      afterSign: '签收后',
      signTimeout: '签收超时48H',
      smsScheme: '物流短信自定义策略',
      addExpressSmsConfig: '添加物流短信策略',
      editExpressSmsConfig: '编辑物流短信策略',
      expressSmsScheme: '策略名称',
      expressSmsSchemeNamePlease: '请填写策略名称',
      noExpressScheme: '未设置通知策略',
      expressSmsConfigTips1: '如没有选择短信签名，则使用客户短信签名或系统默认短信签名',
      expressSmsConfigTips2: '勾选物流节点后将会发送相应通知短信',
      expressSmsConfigTips3: '短信内容可以自定义，将预留参数插入文本内容即可',
      expressSmsConfigTips4: '预留参数 :收件人姓名: ${receiptorName}，订单号: ${orderNumber}， 快递公司: ${expressCompanyName}，快递单号: ${expressNumber}',
      smsNotifyType: '通知设置',
      smsNotifyTypePlease: '请配置短信通知人',
      notifyBuyer: '通知下单人',
      notifyReceiver: '通知收件人',
      expressSender: '发件人',
      expressSenderPlease: '请选择发件人',
      senderName: '发件人名称',
      senderNamePlease: '请输入发件人名称',
      companyName: '发件人公司',
      senderPhone: '发件人号码',
      senderPhonePlease: '请输入发件人号码',
      senderMobile: '发件人电话',
      senderAddressArea: '发件人地址省市区',
      senderAddressAreaPlease: '请输入发件人地址省市区',
      senderAddress: '发件人详细地址',
      senderAddressPlease: '请输入发件人详细地址',
      addExpressSender: '添加发件人',
      editExpressSender: '编辑发件人',
      deleteExpressSenderTips: '确认删除该发件人',
      expressAccountPlease: '请选择下单账号'
    },
    payType: {
      shipper: '寄付',
      consignee: '到付',
      monthly: '月结',
      thirdParty: '第三方支付'
    },
    orderStatus: {
      fail: '下单失败',
      wait: '待下单',
      success: '已下单',
      cancel: '已取消'
    },
    order: {
      orderCode: '订单编码',
      recipient: '收件人',
      sender: '寄件人',
      companyName: '快递公司',
      cargoName: '物品名称',
      payType: '支付方式',
      orderStatus: '订单状态',
      printStatus: '打印状态',
      orderTime: '下单时间',
      createTime: '创建时间',
      addOrder: '添加订单',
      editOrder: '编辑订单',
      viewOrder: '查看订单',
      expressCompany: '快递公司',
      businessType: '业务类型',
      quantity: '数  量',
      weight: '重  量',
      piece: '件',
      packageQuantity: '包裹件数',
      postage: '邮费',
      yuan: '元',
      returnSignBill: '签收回单',
      need: '需要',
      noNeed: '不需要',
      isInsured: '是否保价',
      insured: '保价',
      noInsured: '不保价',
      insuredAmount: '保价金额',
      insuredCost: '保价费用',
      collection: '代收货款',
      expandMoreInfo: '展开更多信息',
      foldMoreInfo: '收起更多信息',
      pleaseEntryRecipientName: '请输入收件人姓名',
      pleaseEntrySenderName: '请输入寄件人姓名',
      pleaseEntryCargoName: '请输入物品名称',
      cancelOrderTips: '是否确定取消该订单？',
      printFaceInvoke: '打印电子面单',
      clearAddressNoteInfo: '清空已填信息',
      printTemplate: '打印模板',
      printLogo: '打印Logo',
      noPrintTempalteUse: '暂未配置该快递公司的打印模板',
      pleaseChoosePrintTemplate: '请选择打印模板',
      pleaseChooseExpressCompany: '请选择快递公司',
      pleaseChooseBusinessType: '请选择业务类型',
      deleteOrderTips: '是否确定删除该订单？',
      addExpressOrder: '快递下单',
      expressCompanyAccount: '下单账号',
      addExpressOrderTips: '确认快递下单，下单成功后可打印电子面单',
      selectAddOrderPlease: '请选择要快递下单的单据',
      addExpressOrderSuccessTips: '共{0}条记录，成功：{1}条，失败：{2}条',
      addExpressOrderFail: '快递下单失败',
      notPrintExpressOrder: '请先使用系统快递下单，下单成功后才能打印电子面单',
      expressOrderWarehouseEmpty: '请先调整发货仓库'
    },
    addressNote: {
      addAddressNote: '添加联系人',
      editAddressNote: '编辑联系人',
      viewAddressNote: '查看联系人',
      recipientAddressNote: '收件人地址簿',
      senderAddressNote: '寄件人地址簿',
      name: '姓  名',
      mobile: '手机号码',
      phone: '固定电话',
      company: '公  司',
      area: '所在地区',
      address: '地  址',
      setDefault: '设为默认',
      isDefault: '是否默认',
      pleaseEntryFullName: '请输入姓名',
      pleaseEntryMobileOrPhone: '请输入手机号码或固定电话',
      pleaseChooseArea: '请选择所在地区',
      pleaseEntryAddress: '请输入地址',
      deleteAddressNoteTips: '是否确定删除该联系人？'
    },
    account: {
      accountTitle: '账号信息',
      accountId: '账号ID',
      accountFlag: '账号状态',
      totalRechargeAmount: '总充值金额',
      totalRechargeCount: '总充值数量',
      usedCount: '已使用数量',
      surplusCount: '剩余数量',
      preDeductCount: '预扣除数量',
      usableCount: '可用数量'
    },
    companyAccount: {
      expCode: '快递公司代码',
      expName: '快递公司名称',
      monthCode: '月份编码',
      customerName: '客户名称',
      customerPwd: '客户密码',
      sendSite: '发送站点',
      sendStaff: '派遣工作人员',
      wareHouseId: '仓库编码',
      freight: '默认运费',
      payType: '运费支付方式',
      volume: '体积',
      weight: '重量',
      expBusinessType: '业务类型',
      expOrderTemplate: '电子面单模板',
      addAccount: '添加账号信息',
      editAccount: '编辑账号信息',
      viewAccount: '查看账号信息',
      deleteAccountTips: '是否确定删除该账号信息？'
    },
    freightPayType: {
      cashPay: '现付',
      arrivedPay: '到付',
      monthlyPay: '月结',
      thirdPay: '第三方支付/回单付'
    },
    recharge: {
      startDate: '查询时间起',
      endDate: '查询时间止',
      rechargeTime: '充值时间',
      rechargeAmount: '充值金额',
      rechargeQuantity: '充值数量',
      expressAbleNumber: '物流可用条数',
      viewCharge: '查看充值记录',
      rechargeAccount: '充值账号',
      rechargeOrderCode: '充值订单号'
    },
    monitor: {
      monitorStatus: '监控状态',
      expressStatus: '物流状态',
      expressCompany: '快递公司',
      sourceType: '来源',
      startDate: '监控时间起',
      endDate: '监控时间止',
      orderCode: '订单编号',
      deliveryCode: '发货单编号',
      outCode: '出库单编号',
      logisticCode: '快递单号',
      receiverName: '收货人名称',
      receiverPhone: '收货人手机号',
      receiverCountry: '国家',
      receiverArea: '收货区域',
      receiverAddr: '收货地址',
      monitorTime: '监控时间',
      errorReason: '失败原因',
      expressStatusEx: '详细物流状态',
      latestTrack: '最新物流轨迹',
      latestTrackTime: '最新轨迹时间',
      takeUpTime: '耗时',
      pleaseSelectExpCompany: '请选择物流公司',
      pleaseEntryExpressNumber: '请输入物流单号',
      addExpressMonitor: '添加监控信息',
      editExpressMonitor: '编辑监控信息',
      viewExpressMonitor: '查看物流监控信息',
      orderCodeTip: '当没有填写订单号时，将自动生成订单号',
      receiverPhoneTip: '当快递公司是顺丰系时，必须填写手机号码，且不能为虚拟号码，否则可能无法获取物流轨迹',
      deleteExpressMonitorTip: '是否确定删除该监控信息？',
      pleeaseSelectMonitorNeedOperateTip: '请选择要操作的监控信息',
      dataImport: '导入数据',
      dataMap: '数据映射',
      expressCompanyMap: '快递公司映射',
      dataPreview: '数据预览',
      complete: '完成',
      mapExpressPlease: '请选择映射快递',
      emptyImportData: '导入数据为空',
      importExpressMonitor: '导入快递信息',
      executeResultInfo: '共{0}条记录，成功：{1}条，跳过：{2}条',
      importMode: '导入模式',
      expressTrace: '物流轨迹',
      monitor: '监控',
      cancelMonitor: '取消监控',
      refreshMonitor: '刷新监控',
      setSpecial: '特别关注',
      unsetSpecial: '取消特别关注',
      pleaseSelectExpress: '请选择快递单号',
      allMonitor: '全部监控',
      specialMonitor: '特别关注',
      overtimeMonitor: '异常件',
      overtimeType: '异常件类型',
      pleaseSelectExpPayType: '请选择运费支付方式',
      pleaseSelectExpBusinessType: '请选择业务类型',
      pleaseSelectExpOrderTemplate: '请选择电子面单模板',
      pleaseInputDefaultFreight: '请填写默认运费',
      pleaseInputVolumn: '请填写体积',
      pleaseInputWeight: '请填写重量',
      salesOrder: '销售订单',
      mallOrder: '商城订单',
      thirdOrder: '三方订单',
      outSourceOrder: '委外订单',
      exchangeOrder: '兑换订单',
      invoice: '发票',
      batchQuery: '批量查询',
      manually: '手动添加/导入'
    },
    batchQuery: {
      batchTasksName: '批量任务名称',
      pleaseInputBatchTasksName: '请输入批量任务名称',
      expressBatchQueryExport: '快递批量查询导出'
    }
  },
  distribution: {
    b2c: {
      common: {
        shopCode: '小店码',
        shopName: '小店名称',
        shopkeeperName: '小店店主',
        phoneNumber: '手机号码',
        idCardOrMerchantName: '姓名/企业名称',
        idCardOrBusinessLicenseNumber: '证件号码',
        organizationType: '主体类型',
        invitationCode: '邀请码',
        invitationName: '邀请人',
        invitationType: '邀请人类型',
        user: '员工',
        customer: '客户',
        pending: '待审核',
        audited: '通过',
        reject: '拒绝',
        relatedCustomer: '关联客户',
        phoneNumberCustomerName: '手机号客户名称',
        expire: '有效期',
        customerIdNotEmpty: '请关联客户',
        pleaseEntryShopCode: '请输入小店码',
        smallBusinesses: '小微商户',
        individualSellers: '个人卖家',
        individualBusiness: '个体工商户',
        enterprise: '企业',
        partyAndGovernment: '党政、机关及事业单位',
        otherOrganizations: '其他组织',
        disablePaymentMonth: '关闭挂账月结',
        disableBalancePayment: '关闭余额支付'
      },
      apply: {
        applyTime: '申请时间',
        auditTime: '审核时间',
        auditStatus: '审核状态',
        viewTitle: '分销申请',
        rejectReason: '拒绝理由',
        rejectTips: '是否确认【拒绝】该小店分销申请',
        rowAuditTips: '是否确认审核【通过】该小店分销申请',
        rejectReasonNotEmpty: '请填写拒绝原因'
      },
      detail: {
        addTitle: '添加B2C小店分销',
        editTitle: '编辑B2C小店分销',
        authTitle: 'B2C小店分销授权',
        mallRange: '商品授权范围',
        mallAssignedProduct: '指定商品',
        mallAssignedSort: '指定商品分类',
        assignedSalesLabel: '指定营销标签',
        auth: '授权'
      }
    }
  },
  message: {
    recharge: {
      rechargeTime: '充值时间',
      rechargeAmount: '充值金额',
      rechargeQuantity: '充值数量',
      smsAbleNumber: '短信可用条数',
      viewCharge: '查看充值记录',
      rechargeAccount: '充值账号',
      rechargeOrderCode: '充值订单号'
    },
    template: {
      templateName: '模板名称',
      templateCode: '模板编码',
      templateContext: '模板内容',
      applyInstructions: '申请说明',
      templateStatus: '模板状态',
      templateNameTip: '请输入名称 不超过30个字符',
      templateContextPlease: '请输入模板内容',
      applyInstructionsPlease: '请输入申请说明',
      applyInstructionsTip: '请提供您已上线业务的对应链接（必填）、使用场景说明；暂时无法支持未上线应用、未备案域名的模板申请。',
      templateContextTip: '(短信70个字数含以内，按1条短信计费；超出70个字为长短信，按照67个字数记为1条短信费用。)',
      sms: '短信',
      smsTip: '预计收费为',
      tiao: '条',
      addTips: '确认提交该短信模板申请，审核通过后可设置该短信模板',
      editTips: '重新提交该短信模板申请，审核通过后可设置该短信模板',
      toAudit: '待审核',
      auditSuccess: '审核通过',
      auditError: '审核失败',
      reason: '审核备注',
      creatTime: '创建时间',
      deleteTips: '确认【删除】该短信模板，短信模板删除后不可恢复，请谨慎操作!',
      addTemplate: '添加短信模板',
      updateTemplate: '修改短信模板',
      viewTemplate: '查看短信模板',
      otherTips3: '短信模板审核为阿里云，系统只负责提交申请至阿里云',
      templateType: '短信类型',
      templateTypeTips: '建议优先选择通知短信',
      notification: '短信通知',
      promotion: '推广短信'
    },
    send: {
      sendTaskId: '任务id',
      sendTaskName: '任务名称',
      smsContent: '短信内容',
      sendNumber: '发送数量',
      successNumber: '成功数量',
      failNumber: '失败数量',
      billingCount: '计费条数',
      sendStatus: '发送状态',
      revicePhone: '接收号码',
      smsSign: '短信签名',
      smsTemplate: '短信模板',
      phoneTypeOne: '导入接收号码文件',
      phoneTypeTwo: '手动输入接收号码',
      phoneTypeThree: '选择手机号码',
      inputPhone: '输入号码',
      importPhone: '导入号码',
      system: '系统',
      customer: '客户',
      member: '会员',
      taskNamePlease: '请输入任务名称',
      pleaseInputSmsContent: '请输入要发送的短信内容',
      templatePlease: '请选择短信模板',
      signPlease: '请选择短信签名',
      endSend: '终止发送',
      waitSend: '等待发送',
      isSend: '正在发送',
      completeSend: '完成发送',
      detailed: '明细',
      phoneNumber: '手机号码',
      sendTime: '发送时间',
      receiveTime: '接收时间',
      failReason: '失败原因',
      toSend: '待发送',
      waitingReceipt: '等待回执',
      sendSuccess: '发送成功',
      sendFail: '发送失败',
      addPhone: '添加号码',
      addSendTask: '添加发送任务',
      pleaseChoose: '请选择',
      upload: '点击上传',
      uploadTip: '只能上传txt/xlsx文件,文件大小不超过2M',
      templateDownload: '模板下载',
      chooseSysPhone: '选择系统手机号码',
      chooseCustomerPhone: '选择客户手机号码',
      chooseMemberPhone: '选择会员手机号码',
      phoneList: '号码列表',
      clearData: '清空数据',
      templateContextCount: '计费说明：当前内容字数为',
      according: '按照',
      billingTip: '条短信发送并计费',
      templateContextTip: '此功能仅作为内容预览，具体情况以实际发送为准',
      addPhoneTip: '提示：一行输入一个号码，多个手机号请换行隔开。最多输入1000个手机号码',
      phoneNotNull: '手机号不能为空',
      phoneIsTrue: '请输入正确的手机号，格式有误号码：',
      uploadFileType: '上传头像图片只能是 txt,xlsx 格式!',
      uploadFileSize: '上传头像图片大小不能超过 2MB!',
      sendTipBefore: '本次发送短信',
      sendTip: '条，确认发送吗',
      billingInstructions: '计费规则：短信70个字数含以内，按1条短信计费；超出70个字为长短信，按照67个字数记为1条短信费用',
      billingInstructionsTip: '规则详情',
      phoneNullTip: '未选择手机号码',
      addMember: '筛选会员',
      addCustomer: '筛选客户',
      addCustomerContact: '筛选客户联系人',
      allCustomer: '全部客户',
      checkCustomer: '勾选客户',
      queryCustomer: '查询客户',
      uploadTips: '导入的号码数据，会显示在下方文本框，可进行修改',
      smsNoCount: '短信可用条数不足，请联系平台充值',
      tiao: '条',
      sendCount: '短信数量计算：预计发送短信',
      smsAbleCount: '短信可用条数',
      allContact: '全部联系人',
      checkContact: '勾选联系人',
      queryContact: '查询联系人',
      noData: '未查询到数据',
      dataMaxSizeTip: '手机号最多添加1000个',
      contactName: '联系人姓名',
      contactNickName: '联系人称呼',
      contactCustomer: '联系人所属客户',
      selectPrefix: '已选',
      selectSubfix: '个手机号码',
      totalPrefix: '共',
      totalSubfix: '个',
      orderCode: '订单编号',
      userName: '用户',
      userPhone: '手机号',
      sendPeopleCount: '发送总人数',
      retrySend: '失败发送重试'
    }
  },
  thirdPartOrder: {
    sysOrderStatus: '订单状态',
    startDate: '支付时间起',
    endDate: '支付时间止',
    queryKeyword: '订单编号、收件信息、商品信息',
    platformType: '三方平台',
    sysCompanyName: '所属公司',
    platformOrderCode: '三方订单编号',
    buyerName: '买家姓名',
    buyerPhone: '买家手机号',
    receiverName: '收件人姓名',
    receiverPhone: '收件人手机号',
    receiverAddress: '收件地址',
    paymentTime: '支付时间',
    productAmount: '产品总额',
    paidAmount: '支付金额',
    importGuanaitongOrder: '关爱通订单导入',
    importData: '数据导入',
    dataMap: '数据映射',
    previewData: '数据预览',
    previewResult: '导入结果',
    excelFile: 'Excel 文件',
    attentionTipsTitle: '导入注意事项',
    attentionTips1: '请使用关爱通系统导出的数据导入',
    attentionTips2: '上传EXCEL后，请准确填写标题行号和数据起始行号',
    attentionTips3: '请准确填写数据映射关系并检查数据预览是否相符',
    excelTitleRowNumber: '标题行号',
    excelDataRowNumber: '数据行号',
    lastStep: '上一步',
    nextStep: '下一步',
    targetTitleName: '映射对象',
    sourceTitleName: '导入列标题',
    dataCompanyName: '订单归属公司',
    serialNumber: '序号',
    importStatus: '导入状态',
    importErrorReason: '跳过原因',
    fileError: '导入文件错误',
    noDataNeedImport: '导入数据为空',
    executeResultInfo: '共{0}条记录，成功：{1}条，失败：{2}条',
    expressCompanyMap: '快递公司映射',
    sourceExpressCompany: '导入快递公司名称',
    targetExpressCompany: '映射快递公司名称',
    sysOrderInfo: '订单信息',
    guanaitongOrderInfo: '导入明细',
    expressCompanyMapTips: '无快递公司映射请直接跳转下一步',
    enterpriseWechatNotification: '订单超时提醒',
    orderNotPrepareOvertime: '订单超时未备货',
    orderNotDeliveryOvertime: '订单超时未发货',
    orderNotUpdateExpressOvertime: '物流超时未更新轨迹',
    orderNotSignOvertime: '物流超时未签收',
    hour: '小时',
    shoppingCode: '购物编号',
    externalOrderCode: '外部交易号',
    sellerOrderCode: '商家订单编号',
    paymentOrderCode: '支付编号',
    buyerUserName: '买家用户名',
    orderSourceName: '订单来源名称',
    realPayAmount: '订单实际支付金额',
    orderStatus: '订单状态',
    deliveryTime: '发货时间',
    productBrand: '所属品牌',
    productName: '商品名称',
    productAttr: '销售属性',
    buyQuantity: '购买数量',
    productSalesUnitPrice: '商品成交价',
    productExpressCompanyName: '商品物流公司',
    productExpressNumber: '商品物流单号',
    orderTotalAmount: '商品成交总价',
    notDeliveryOrder: '超时未发货',
    notUpdateExpressOrder: '物流进度未更新',
    guanaitong: '关爱通',
    statusUpdateTime: '物流更新时间',
    expressStatus: '快递状态',
    expressCompanyName: '物流公司',
    expressNumber: '物流单号',
    notPrepare: '待备货',
    notDelivery: '待发货',
    delivered: '已发货',
    completed: '已完成',
    closed: '已关闭',
    allExpressStatus: '物流状态',
    recentStatusUpdateTime: '物流更新时间',
    sysOrderQueryKeyword: '订单编号, 收件信息',
    viewImportRecord: '导入记录',
    importing: '导入中',
    importCompleted: '导入完成',
    importError: '导入失败',
    importTime: '导入时间',
    importOperatorName: '操作人',
    importCount: '导入数量',
    importRecord: '导入记录',
    sendSms: '发送短信',
    selectedRange: '已选择订单',
    queryRange: '查询范围订单',
    sendSmsBatch: '批量发送短信',
    pleaseSelectOrder: '请选择订单',
    isSendSms: '是否发送短信',
    taskImportStatus: '任务状态',
    viewImportTask: '导入任务',
    taskImportStatusEnum: {
      waiting: '等待导入',
      importing: '导入中',
      completed: '导入完成'
    },
    taskRecordImportStatusEnum: {
      waiting: '等待导入',
      success: '导入成功',
      fail: '导入失败'
    },
    importTask: {
      createTime: '创建时间',
      dataOperationType: '导入类型',
      sendSms: '发送短信',
      importCount: '导入数量',
      successCount: '成功数量',
      failCount: '失败数量',
      startTime: '开始时间',
      endTime: '完成时间',
      status: '状态',
      overwrite: '覆盖',
      append: '追加',
      detailRecord: '任务明细'
    }
  },
  dumall: {
    config: {
      parameterSettings: '参数设置',
      advertisement: '首页广告',
      authorizedGoods: '授权商品',
      automaticAuthorizationStore: '自动授权店铺',
      invitationRegistrationCode: '邀请注册码',
      goodsPrice: '商品价格',
      customPrice: '自定义价格',
      mainImage: '商品主图',
      goodsTitle: '商品标题',
      priceTypeName: '价格类型',
      priceConfig: '价格设置',
      advertisementStatus: '上线状态',
      deleteTips: '是否删除该首页商品?',
      addAdvertisementTitle: '添加首页广告',
      editAdvertisementTitle: '编辑首页广告',
      viewAdvertisementTitle: '查看首页广告',
      priceLevel: '价格等级',
      allGoods: '全部商品',
      theCustom: '自定义',
      salesPriceLevel: '销售价格等级',
      goodsName: '商品名称',
      goodsImg: '商品图片',
      specsValue: '规格',
      unitPrice: '销售价',
      quantity: '数量',
      requireGoodsPriceTip: '未填写自定义价格',
      editCustomPrice: '编辑自定义价格',
      notOnline: '未上线',
      online: '已上线',
      offline: '下线',
      beOnline: '上线',
      onlineTips: '是否下线该首页商品?',
      notOnlineTips: '是否上线该首页商品?',
      advertisementImage: '广告图片',
      advertisementImageTip: '建议图片比例为710 : 300',
      sort: '排序',
      retailPrice: '零售价'
    },
    exception: {
      sortPlease: '排序不能为空',
      advertisementImagePathPlease: '广告图片不能为空'
    },
    shop: {
      all: '全部',
      authorized: '已授权',
      expired: '已过期'
    },
    invitation: {
      user: '员工',
      customer: '客户',
      invitationCode: '邀请码',
      createInvitationCode: '生成邀请海报',
      invitationPoster: '邀请注册海报',
      invitationText: '邀请您注册开通嘟麦集肆',
      invitationCodeText: '邀请注册码：'
    }
  },
  open: {
    authorized: {
      customerName: '客户名称',
      apiKey: 'Api Key',
      apiSecret: 'Api Secret',
      callbackUrl: '回调地址',
      status: '有效状态',
      effective: '有效',
      loseEfficacy: '失效',
      expire: '有效期止',
      addAuthorized: '添加授权',
      editAuthorized: '编辑授权',
      deleteAuthorizedTips: '确认删除该开放授权？',
      customerPlease: '请选择客户'
    }
  },
  businessAnalysis: {
    digital: {
      projectName: '项目名称',
      projectDateRange: '统计区间',
      lastProjectStartDate: '同比区间开始日期',
      lastProjectEndDate: '同比区间结束日期',
      isDefault: '是否默认',
      isVisible: '是否展示',
      dataRange: '数据范围',
      dataMerge: '互联数据合并',
      visibleRangeConfig: '可见范围设置',
      priceRangeConfig: '价位段设置',
      priceRange: '价位段（左不包含，右包含）',
      allData: '全部数据',
      customerData: '仅客户数据',
      inCustomerSort: '可见客户分类',
      inCustomer: '可见客户',
      outCustomerSort: '不可见客户分类',
      outCustomer: '不可见客户',
      visibleType: '可见类型',
      visibleName: '客户/客户分类',
      projectNamePlease: '请输入项目名称',
      dateRangePlease: '请选择统计区间',
      lastStartDatePlease: '请选择同比区间开始时间',
      lastEndDatePlease: '请选择同比区间结束时间',
      isDefaultPlease: '请选择是否默认',
      isVisiblePlease: '请选择是否展示',
      dataRangePlease: '请选择数据范围',
      dataMergePlease: '请选择互联数据合并',
      baseConfig: '基本设置',
      brandName: '品牌名称',
      brandNameTips: '品牌名称用于经营白皮书上展示，可以使用公司简称或品牌名称，当没有填写时，将使用系统企业名称',
      addProjectTips: '确认添加项目经营分析，经营分析数据要在统计区间之后才能生成',
      editProjectTips: '修改内容不会即时更新已生成的经营分析数据，需手动重新生成',
      deleteProjectTips: '确认删除项目经营分析',
      addProjectTitle: '添加项目经营分析',
      editProjectTitle: '编辑项目经营分析',
      viewProjectTitle: '查看项目经营分析',
      lastStartDateError: '同比区间开始日期应小于统计区间开始日期',
      calc: '分析',
      reCalc: '重新分析',
      noCalc: '未生成',
      calcProcessing: '生成中',
      calcComplete: '已生成',
      calcFail: '生成失败',
      viewProjectDigital: '数据查看',
      clacProjectTips: '确认生成数据，如已生成的数据将会被删除后重新生成',
      clacProcessingProjectTips: '系统正在生成数据，请稍后刷新进行数据查看',
      geneStatus: '数据生成状态',
      dataRank: '排名',
      lastDateRange: '同比区间',
      currentDateRange: '统计区间',
      salesAmount: '销售额（元）',
      salesQuantity: '销售数量',
      amountRate: '占比',
      increaseRate: '同比增长',
      productName: '产品名称',
      sort: '序号',
      currentOrderDate: '统计日期',
      lastOrderDate: '同比日期',
      lastAmount: '同比销售额',
      currentAmount: '当前销售额',
      weekCount: '周数',
      customerSortName: '客户分类',
      currentWeekDateRange: '统计周区间',
      lastWeekDateRange: '同比周区间',
      before: '前',
      week: '周'
    }
  },
  invoice: {
    generalInvoice: '普通发票',
    specialInvoice: '专项发票',
    companyInvoice: '企业单位',
    personalInvoice: '个人/非事业单位',
    notDeal: '未处理',
    invoiced: '已开票',
    digitalInvoice: '电子发票',
    paperInvoice: '纸质发票',
    email: '电子邮箱',
    express: '快递',
    mallOrder: '商城订单',
    salesOrder: '销售订单',
    applicant: '申请人',
    invoiceType: '发票类型',
    titleType: '发票抬头类型',
    titleContent: '发票抬头',
    taxNumber: '税号',
    invoiceAmount: '开票金额',
    bankName: '开户银行',
    bankAccountNumber: '银行账户',
    registAddress: '开户银行地址',
    uploadInvoice: '发票交付',
    registPhone: '注册手机号',
    invoiceStatus: '开票状态',
    invoiceDeliveryType: '交付方式',
    applyTime: '申请时间',
    invoiceAttachment: '发票附件',
    receiverEmail: '收件邮箱',
    receverAddress: '收件地址',
    sendExpressCompany: '寄件快递公司',
    sendExpressNumber: '寄件快递单号',
    freight: '运费',
    attachmentPlease: '请上传发票附件',
    uploadInvoiceTips: '确认提交发票附件，确认后申请人可在小程序查看',
    businessType: '关联业务类型',
    businessCode: '关联业务',
    businessInvoiceAmount: '关联业务开票金额',
    viewInvoiceApply: '查看开票申请',
    invoiceBusiness: '关联业务',
    applyCode: '申请编码'
  },
  databoard: {
    databoardTitle: '标题',
    defaultTitle: '默认标题',
    customTitle: '自定义标题',
    databoardUrl: 'Url',
    databoardParam: '参数',
    restoreTitle: '还原标题',
    editTitle: '修改标题',
    editParams: '修改参数',
    restoreParams: '还原参数',
    restoreTitleTip: '是否确定还原大屏标题为默认标题？',
    restoreParamsTip: '是否确定还原大屏参数为默认参数？'
  },
  store: {
    info: {
      imageUrl: '门店照片',
      name: '门店名称',
      companyName: '所属公司',
      storeCategory: '门店分类',
      userName: '门店店长',
      phone: '门店电话',
      address: '门店地址',
      businessStatus: '营业状态',
      open: '营业中',
      stop: '停业',
      addStore: '添加门店信息',
      editStore: '修改门店信息',
      deleteStoreTips: '确认删除该门店？',
      warehouse: '关联仓库',
      orderPriceType: '订单价格类型',
      storeType: '门店类型',
      self: '自营',
      join: '加盟',
      selfPickup: '到店自提',
      storeUser: '门店店员',
      addStoreUser: '添加门店店员',
      storeCollectionAccount: '收银账户',
      rules: {
        pleaseInputName: '请输入门店名称',
        pleaseSelectUserName: '请选择门店店长',
        pleaseInputPhone: '请输入门店电话',
        pleaseUploadImage: '请上传门店照片',
        pleaseSelectCompany: '请选择所属公司',
        pleaseSelectStoreCategory: '请选择门店分类',
        pleaseSelectWarehouse: '请选择关联仓库',
        pleaseSelectOrderPriceType: '请选择订单价格类型',
        pleaseSelectStoreType: '请选择门店类型',
        pleaseInputAddress: '请输入门店地址',
        pleaseSelectAccount: '请选择收银账户'
      }
    },
    config: {
      storePriceType: '门店价格类型'
    },
    order: {
      orderCode: '订单号',
      storeName: '门店',
      buyerName: '买家名称',
      payableAmountSum: '订单金额',
      orderStatus: '订单状态',
      paymentType: '支付方式',
      operateName: '收银店员',
      orderDate: '下单时间',
      pleaseChooseOrderOperator: '请选择要查询的收银店员',
      queryKeywords: '订单号 买家',
      cashPay: '现金支付',
      advanceReceiptPay: '预收款支付',
      wechatNativePay: '微信支付(收款码)',
      wechatScanPay: '微信支付(支付码)',
      notPaid: '未支付',
      paid: '已支付',
      canceled: '已取消',
      refuned: '已退款',
      buyerType: '买家类型',
      visitor: '散客',
      member: '会员',
      customer: '客户',
      viewOrder: '查看订单',
      orderUnitPrice: '单价',
      quantity: '数量',
      subtotal: '小计',
      rePayOrder: '提单',
      payOrder: '支付订单',
      monthPay: '挂账月结',
      storeOrder: '门店订单',
      storeMonthPayOrder: '门店月结订单',
      storeOrderCollection: '门店订单收款',
      storeOrderInventoryOut: '门店发货',
      printOrder: '打印门店订单',
      partPaid: '部分支付',
      exportOrderTitle: '订单导出'
    }
  }
}
