import { login, logout, getTenantHost, getTenantInfo } from '@/api/user'
import { getUserInfo, getFullInfo } from '@/api/profile'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { setStore, getStore, removeStore } from '@/utils/store'
import { weworkLogin } from '@/api/wework'

const state = {
  token: getToken(),
  userInfo: {},
  tenantInfo: {},
  loginTenantInfo: { tenantEnabled: false },
  roles: [],
  permissions: [],
  menus: [],
  pageType: 0, // 1:登录页面， 2： 错误页面，0：常规页面
  isLockScreen: getStore({
    name: 'isLockScreen'
  }) || false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_TENANT_INFO: (state, tenantInfo) => {
    state.tenantInfo = tenantInfo
  },
  SET_LOGIN_TENANT_INFO: (state, loginTenantInfo) => {
    state.loginTenantInfo = loginTenantInfo
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus
  },
  SET_USER_AVATAR: (state, avatar) => {
    state.userInfo.userImg = avatar
  },
  SET_USER_SEX: (state, sex) => {
    state.userInfo.sex = sex
  },
  SET_USER_EMAIL: (state, email) => {
    state.userInfo.userEmail = email
  },
  SET_USER_PHONE: (state, phone) => {
    state.userInfo.userPhone = phone
  },
  SET_USER_MOBILE: (state, mobile) => {
    state.userInfo.userMobile = mobile
  },
  SET_USER_QY_QRCODE: (state, qrcode) => {
    state.userInfo.qyQrCode = qrcode
  },
  SET_SCREEN_LOCK: (state) => {
    state.isLockScreen = true
    setStore({
      name: 'isLockScreen',
      content: state.isLockScreen,
      type: 'session'
    })
  },
  SET_SCREEN_UNLOCK: (state) => {
    state.isLockScreen = false
    removeStore({
      name: 'isLockScreen'
    })
  },
  PAGE_TYPE: (state, pageType) => {
    state.pageType = pageType
  }
}

const actions = {
  getTenantHost({ commit }) {
    return new Promise((resolve, reject) => {
      getTenantHost().then(response => {
        const { data } = response
        commit('SET_LOGIN_TENANT_INFO', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  getTenantInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getTenantInfo().then(response => {
        const { data } = response
        commit('SET_TENANT_INFO', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user login
  setUserToken({ commit }, token) {
    commit('SET_TOKEN', token)
    setToken(token)
  },
  weworkLogin({ commit }, { code, state }) {
    return new Promise((resolve, reject) => {
      weworkLogin(code, state).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getFullInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getFullInfo().then(response => {
        const { data } = response

        const { permissions, roles, menus } = data
        commit('SET_ROLES', roles)
        commit('SET_PERMISSIONS', permissions)
        commit('SET_MENUS', menus)
        const userInfo = JSON.parse(JSON.stringify(data))
        delete userInfo.roles
        delete userInfo.permissions
        delete userInfo.menus
        if (userInfo.userImg) {
          if (!userInfo.userImg.startsWith('http://') && !userInfo.userImg.startsWith('https://')) {
            userInfo.userImg = process.env.VUE_APP_BASE_API + '/upload/' + userInfo.userImg
          }
        }
        if (userInfo.qyQrCode) {
          if (!userInfo.qyQrCode.startsWith('http://') && !userInfo.qyQrCode.startsWith('https://')) {
            userInfo.qyQrCode = process.env.VUE_APP_BASE_API + '/upload/' + userInfo.qyQrCode
          }
        }
        commit('SET_USER_INFO', userInfo)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(response => {
        const { data } = response
        if (data.userImg) {
          if (!data.userImg.startsWith('http://') && !data.userImg.startsWith('https://')) {
            data.userImg = process.env.VUE_APP_BASE_API + '/upload/' + data.userImg
          }
        }
        commit('SET_USER_INFO', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      if (state.token) {
        logout().then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        }).finally(() => {
          dispatch('localLogout')
        })
      } else {
        dispatch('localLogout')
        resolve()
      }
    })
  },
  localLogout({ commit, state, dispatch }) {
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    commit('SET_PERMISSIONS', [])
    commit('SET_MENUS', [])
    commit('SET_SCREEN_UNLOCK')
    removeToken()
    resetRouter()
    // reset visited views and cached views
    // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
    dispatch('tagsView/delAllViews', null, { root: true })
  },
  // remove token
  resetToken({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('localLogout')
      resolve()
    })
  },
  changeUserAvatar({ commit }, avatarPath) {
    commit('SET_USER_AVATAR', avatarPath)
  },
  changeUserSex({ commit }, sex) {
    commit('SET_USER_SEX', sex)
  },
  changeUserEmail({ commit }, email) {
    commit('SET_USER_EMAIL', email)
  },
  changeUserPhone({ commit }, phone) {
    commit('SET_USER_PHONE', phone)
  },
  changeUserMobile({ commit }, mobile) {
    commit('SET_USER_MOBILE', mobile)
  },
  screenLock({ commit }) {
    commit('SET_SCREEN_LOCK')
  },
  screenUnlock({ commit }) {
    commit('SET_SCREEN_UNLOCK')
  },
  setPageType({ commit }, pageType) {
    commit('PAGE_TYPE', pageType)
  },
  changeUserQyQrcode({ commit }, qrcodePath) {
    commit('SET_USER_QY_QRCODE', qrcodePath)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
