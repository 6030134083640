import request from '@/utils/request'

export function getFinancePage(data) {
  return request({
    url: '/finance/accountPage',
    method: 'post',
    data
  })
}

export function getDataForPage(id, data) {
  return request({
    url: `/finance/account/details/page/${id}`,
    method: 'post',
    data
  })
}

export function getAccountBasicDatas() {
  return request({
    url: '/finance/getAccountBasicDatas',
    method: 'get'
  })
}

// 保证金添加收款单
export function depositAddFinanceCollect(data) {
  return request({
    url: '/financeCollect/deposit-add',
    method: 'post',
    data
  })
}

export function getAccountDataById(id) {
  return request({
    url: `/finance/getAccountDataById/${id}`,
    method: 'get'
  })
}

export function updateFinance(id, data) {
  return request({
    url: `/finance/update/${id}`,
    method: 'post',
    data
  })
}

export function addFinance(data) {
  return request({
    url: `/finance/add`,
    method: 'post',
    data
  })
}

export function deleteFinance(id) {
  return request({
    url: `/finance/delete/${id}`,
    method: 'delete'
  })
}

export function getFinanceDepositPage(data) {
  return request({
    url: '/finance/deposit/page',
    method: 'post',
    data
  })
}
export function getOtherAllDatas() {
  return request({
    url: '/finance/deposit/otherDatas',
    method: 'get'
  })
}

export function getDepositDataById(id) {
  return request({
    url: `/finance/deposit/getDataById/${id}`,
    method: 'post'
  })
}

export function updateDeposit(id, data) {
  return request({
    url: `/finance/deposit/${id}`,
    method: 'put',
    data
  })
}

export function auditDeposit(id, data) {
  return request({
    url: `/finance/deposit/audit/${id}`,
    method: 'put',
    data
  })
}

export function revokeDeposit(id) {
  return request({
    url: `/finance/deposit/revoke/${id}`,
    method: 'put'
  })
}

export function invalidDeposit(id) {
  return request({
    url: `/finance/deposit/invalid/${id}`,
    method: 'put'
  })
}

export function addDeposit(data) {
  return request({
    url: `/finance/deposit`,
    method: 'post',
    data
  })
}

export function addAndAuditDeposit(data) {
  return request({
    url: `/finance/deposit/addAndAudit`,
    method: 'post',
    data
  })
}

export function deleteDeposit(id) {
  return request({
    url: `/finance/deposit/${id}`,
    method: 'delete'
  })
}

export function getFinanceCollectPage(data) {
  return request({
    url: '/financeCollect/page',
    method: 'post',
    data
  })
}

export function getFinanceDepositCollectPage(data) {
  return request({
    url: '/financeCollect/deposit-page',
    method: 'post',
    data
  })
}
// 收款单基础数据
export function getOtherDatasOfReceipt() {
  return request({
    url: '/financeCollect/otherDatas',
    method: 'get'
  })
}
// 付款单基础数据
export function getOtherDatasOfPayment() {
  return request({
    url: '/financePayBook/otherDatas',
    method: 'get'
  })
}

export function updateCollectBook(id, data) {
  return request({
    url: `/financeCollect/${id}`,
    method: 'put',
    data
  })
}

export function addCollectBook(data) {
  return request({
    url: `/financeCollect`,
    method: 'post',
    data
  })
}

export function deleteCollectBook(ids) {
  return request({
    url: `/financeCollect/${ids}`,
    method: 'delete'
  })
}

export function getFinancePayBookPage(data) {
  return request({
    url: '/financePayBook/page',
    method: 'post',
    data
  })
}

export function getFinancePayBookById(id) {
  return request({
    url: `/financePayBook/getFinancePayBookById/${id}`,
    method: 'post'
  })
}

export function getPayIdByPurposeBusinessItemId(businessItemId) {
  return request({
    url: `/financePayBook/getPayIdByPurposeBusinessItemId/${businessItemId}`,
    method: 'post'
  })
}

export function getPayBookOtherDatas() {
  return request({
    url: '/financePayBook/otherDatas',
    method: 'get'
  })
}

export function updatePayBook(id, data) {
  return request({
    url: `/financePayBook/${id}`,
    method: 'put',
    data
  })
}
export function revokePayBook(id) {
  return request({
    url: `/financePayBook/revoke/${id}`,
    method: 'put'
  })
}
export function invalidPayBook(id) {
  return request({
    url: `/financePayBook/invalid/${id}`,
    method: 'put'
  })
}

export function addPayBook(data) {
  return request({
    url: `/financePayBook`,
    method: 'post',
    data
  })
}

export function deletePayBook(ids) {
  return request({
    url: `/financePayBook/${ids}`,
    method: 'delete'
  })
}
// 列表查询
export function getFinanceReceivablePage(data) {
  return request({
    url: '/financeReceivable/page',
    method: 'post',
    data
  })
}
// 导出
export function exportFinanceReceivable(data) {
  return request({
    url: '/financeReceivable/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 全页合计
export function getFinanceReceivablePageSum(data) {
  return request({
    url: '/financeReceivable/page-sum',
    method: 'post',
    data
  })
}

// 全页合计
export function getSummaryTablePageSum(data) {
  return request({
    url: '/financeReceivable/summaryPage-sum',
    method: 'post',
    data
  })
}
// 收款对象汇总
export function getSummaryTablePage(data) {
  return request({
    url: '/financeReceivable/summaryPage',
    method: 'post',
    data
  })
}

// 导出(汇总)
export function exportReceivableSummary(data) {
  return request({
    url: '/financeReceivable/summary/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 业务汇总
export function getBusinessSummaryTablePage(data) {
  return request({
    url: '/financeReceivable/business-summary/page',
    method: 'post',
    data
  })
}
// 业务汇总合计
export function getBusinessSummaryTablePageSum(data) {
  return request({
    url: '/financeReceivable/business-summary/page-sum',
    method: 'post',
    data
  })
}

export function getBasicData() {
  return request({
    url: '/financeReceivable/getBasicData',
    method: 'get'
  })
}

export function getDetailPage(data) {
  return request({
    url: '/financeReceivable/getDetailPage',
    method: 'post',
    data
  })
}

export function getAddCollectData() {
  return request({
    url: '/financeReceivable/getAddCollectData',
    method: 'get'
  })
}

export function getReceivableById(id) {
  return request({
    url: `/financeReceivable/getReceivableById/${id}`,
    method: 'post'
  })
}

export function updateReceivable(id, data) {
  return request({
    url: `/financeReceivable/${id}`,
    method: 'put',
    data
  })
}

export function addReceivable(data) {
  return request({
    url: `/financeReceivable`,
    method: 'post',
    data
  })
}

export function getCollectById(id) {
  return request({
    url: `/financeCollect/getCollectById/${id}`,
    method: 'post'
  })
}

export function selectReceivable(data) {
  return request({
    url: '/financeReceivable/selectReceivable',
    method: 'post',
    data
  })
}
// 应收款选择器
export function getReceivableForPageSelect(data) {
  return request({
    url: '/financeReceivable/select/page',
    method: 'post',
    data
  })
}

export function getFinanceAdvanceReceiptForPage(data) {
  return request({
    url: '/financeAdvanceReceipt/page',
    method: 'post',
    data
  })
}

export function selectOneBySaleOrderId(id) {
  return request({
    url: `/financeReceivable/selectOneBySaleOrderId/${id}`,
    method: 'post'
  })
}

export function selectOneBySalesDeliveryId(id) {
  return request({
    url: `/financeReceivable/selectOneBySalesDeliveryId/${id}`,
    method: 'post'
  })
}

export function deleteFinanceReceivable(id) {
  return request({
    url: `/financeReceivable/${id}`,
    method: 'delete'
  })
}

export function getCollectInfoByReceivableId(code) {
  return request({
    url: `/financeCollect/getCollectInfoByReceivableId/${code}`,
    method: 'post'
  })
}

export function revokeFinanceCollect(id) {
  return request({
    url: `/financeCollect/revoke/${id}`,
    method: 'put'
  })
}

export function invalidFinanceCollect(id) {
  return request({
    url: `/financeCollect/invalid/${id}`,
    method: 'put'
  })
}
// 分页查询
export function getPayablePage(data) {
  return request({
    url: '/payable/page',
    method: 'post',
    data
  })
}
// 导出
export function exportFinancePayable(data) {
  return request({
    url: '/payable/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 全页合计
export function getPayablePageSum(data) {
  return request({
    url: '/payable/page-sum',
    method: 'post',
    data
  })
}
// 付款对象汇总
export function getSummaryPayablePage(data) {
  return request({
    url: '/payable/summaryPage',
    method: 'post',
    data
  })
}
// 付款对象汇总 合计
export function getSummaryPayablePageSum(data) {
  return request({
    url: '/payable/summaryPage-sum',
    method: 'post',
    data
  })
}
// 业务单汇总
export function getBusinessSummaryPayablePage(data) {
  return request({
    url: '/payable/business-summary/page',
    method: 'post',
    data
  })
}
// 业务单汇总 合计
export function getBusinessSummaryPayablePageSum(data) {
  return request({
    url: '/payable/business-summary/page-sum',
    method: 'post',
    data
  })
}
export function getDetailPayablePage(data) {
  return request({
    url: '/payable/getDetailPayablePage',
    method: 'post',
    data
  })
}

export function getPayableInfoById(id) {
  return request({
    url: `/payable/getPayableInfoById/${id}`,
    method: 'post'
  })
}

export function addFinancePayable(data) {
  return request({
    url: '/payable',
    method: 'post',
    data
  })
}

export function updateFinancePayable(id, data) {
  return request({
    url: `/payable/${id}`,
    method: 'put',
    data
  })
}

export function deleteFinancePayable(id) {
  return request({
    url: `/payable/${id}`,
    method: 'delete'
  })
}

export function loadBasicData() {
  return request({
    url: '/payable/loadBasicData',
    method: 'get'
  })
}

export function selectPayable(data) {
  return request({
    url: '/payable/selectPayable',
    method: 'post',
    data
  })
}
// 应付款选择器
export function getPayableForPageSelect(data) {
  return request({
    url: '/payable/select/page',
    method: 'post',
    data
  })
}

export function getDataByBusinessIds(businessIds) {
  return request({
    url: `/payable/getDataByBusinessIds/${businessIds}`,
    method: 'post'
  })
}
export function getPayableRelatedPayment(payableId) {
  return request({
    url: `/payable/related-payment/${payableId}`,
    method: 'get'
  })
}

export function getFinanceAdvancePaymentForPage(data) {
  return request({
    url: '/financeAdvancePayment/page',
    method: 'post',
    data
  })
}

export function selectByPayableId(payableId) {
  return request({
    url: `/financePayBook/selectByPayableId/${payableId}`,
    method: 'post'
  })
}

export function selectByBusinessId(businessType, businessId) {
  return request({
    url: `/payable/business/${businessType}/${businessId}`,
    method: 'post'
  })
}

export function getDataByCustomerIdCurrencyId(businessUnitId, currencyId, companyId) {
  return request({
    url: `/financeReceivable/getDataByCustomerIdCurrencyId/${businessUnitId}/${currencyId}/${companyId}`,
    method: 'post'
  })
}

export function getReceivableRelatedCollection(receivableId) {
  return request({
    url: `/financeReceivable/related-collection/${receivableId}`,
    method: 'get'
  })
}

export function getApplyPage(data) {
  return request({
    url: '/financeApply/page',
    method: 'post',
    data
  })
}

export function getApplyBasic() {
  return request({
    url: '/financeApply/basicData',
    method: 'get'
  })
}

export function deleteApply(id) {
  return request({
    url: `/financeApply/${id}`,
    method: 'delete'
  })
}

export function addApply(data) {
  return request({
    url: '/financeApply',
    method: 'post',
    data
  })
}

export function loadApplyById(id) {
  return request({
    url: `/financeApply/getDataById/${id}`,
    method: 'get'
  })
}

export function editApply(id, data) {
  return request({
    url: `/financeApply/${id}`,
    method: 'put',
    data
  })
}

export function submitApply(id, data) {
  return request({
    url: `/financeApply/submit/${id}`,
    method: 'post',
    data
  })
}

export function auditApply(id, data) {
  return request({
    url: `/financeApply/audit/${id}`,
    method: 'post',
    data
  })
}

export function getOrgByUserId(id) {
  return request({
    url: `/financeApply/getOrgByUserId/${id}`,
    method: 'get'
  })
}

export function adjustCollect(data) {
  return request({
    url: `/financeCollect/adjust`,
    method: 'post',
    data
  })
}

export function adjustPayable(data) {
  return request({
    url: `/financePayBook/adjust`,
    method: 'post',
    data
  })
}

// 业务id 查询应收款余额
export function getReceivableBalanceByBusinessId(data) {
  return request({
    url: `/financeReceivable/receivable-balance`,
    method: 'post',
    data
  })
}
// 业务id 查询应付款余额
export function getPayableBalance(data) {
  return request({
    url: `/payable/payable-balance`,
    method: 'post',
    data
  })
}
// 客户档案-应收
export function getCustomerReceivableForPage(id, data) {
  return request({
    url: `/financeReceivable/customer/${id}`,
    method: 'post',
    data
  })
}
// 客户档案-回款
export function getCustomerCollectionForPage(id, data) {
  return request({
    url: `/financeCollect/customer/${id}`,
    method: 'post',
    data
  })
}

export function getFinanceBasicData() {
  return request({
    url: '/financeCommonController/getBasicData',
    method: 'get'
  })
}

export function getReceivableList(data) {
  return request({
    url: '/financeReceivable/customer/receivable-sum',
    method: 'post',
    data
  })
}

export function getExchangeRate() {
  return request({
    url: '/financeCommonController/get-exchange-rate',
    method: 'get'
  })
}

/**
 * 更新资金账户期初余额（单独的一个处理方法）
 * @param {*} data 更新的期初余额数据
 * @returns data
 */
export function updateCurrentBalanceAmount(data) {
  return request({
    url: `/finance/update-current-balance`,
    method: 'put',
    data
  })
}
// 批量确认商城订单-收款单
export function batchFinishedFinanceBooksForMallOrder(data) {
  return request({
    url: `/financeCollect/batch-finished`,
    method: 'put',
    data
  })
}

export function exportAccountDetails(id, data) {
  return request({
    url: `/finance/account/details/export/${id}`,
    method: 'post',
    responseType: 'blob',
    data
  })
}
