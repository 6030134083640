<template>
  <div id="app">
    <!-- <Watermark :text="currentUserId"></Watermark> -->
    <router-view />
    <div v-if="needUpdate" class="el-notification right update-notification">
      <div class="el-notification__group">
        <h2 class="el-notification__title">
          <div class="title-contain">
            <img class="title-logo" src="./assets/images/system-update-logo.png">
            <span class="title">{{ $t('common.systemUpdateTitle') }}</span>
          </div>
        </h2>
        <div class="el-notification__content">
          <div class="update-logo">
            <img class="update-img" src="./assets/images/system-update.png">
          </div>
          <div class="update-content">
            <p>{{ $t('common.systemUpdateContent') }}</p>
            <div class="operation-btn">
              <el-button size="mini" plain @click="reloadBrower()">{{ $t('common.update') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('common.systemUpdateTitle')"
      :visible.sync="needUpdateDialogVisible"
      width="450px"
      :close-on-click-modal="false"
      append-to-body
    >
      <div>
        <div class="el-notification__content">
          <div class="update-logo">
            <img class="update-img" src="./assets/images/system-update.png">
          </div>
          <div class="update-content">
            <p>{{ $t('common.systemUpdateContent') }}</p>
            <p class="tip">{{ $t('common.systemUpdateContentTip') }}</p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="needUpdateDialogVisible = false">{{ $t('common.updateLater') }}</el-button>
        <el-button size="mini" type="primary" @click="reloadBrower()">{{ $t('common.update') }}</el-button>
      </span>
    </el-dialog></div>
</template>

<script>
import axios from 'axios'
import Watermark from '@/views/watermark/index'
export default {
  name: 'App',
  components: { Watermark },
  data() {
    return {
      firstLoad: true,
      needUpdate: false,
      needUpdateDialogVisible: false,
      notifyIcon: ''
    }
  },
  computed: {
    currentUserId() {
      return (this.$store.getters.userInfo.id || '') + ''
    }
  },
  created() {
    // this.notifyIcon = require('./assets/images/notify.png')
    // if (window.Notification) {
    //   // 浏览器通知--window.Notification
    //   if (Notification.permission === 'granted') {
    //     console.log('允许通知')
    //   } else if (Notification.permission !== 'denied') {
    //     console.log('需要通知权限')
    //     Notification.requestPermission((permission) => {})
    //   }
    // } else {
    //   console.error('浏览器不支持Notification')
    // }
    const accessToken = this.getQueryString('access_token')
    if (accessToken) {
      this.$store.dispatch('user/setUserToken', accessToken)
    }
    this.checkVersion()
    setInterval(() => {
      // 30秒检测一次
      this.checkVersion()
    }, 30000)
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
    checkVersion() {
      axios.get(`${process.env.VUE_APP_BASE}/version.json`, { headers: { 'Cache-Control': 'no-cache' }}) // 反正就是要请求到json文件的内容, 并且禁止缓存
        .then(res => {
          const { data } = res
          if (!data || !data.version) {
            return
          }
          const version = data.version
          if (this.firstLoad) {
            localStorage.setItem('_version_', version)
            // this.popNotice('更新通知', `最新版本>>${version}`)
            this.firstLoad = false
          } else {
            const clientVersion = localStorage.getItem('_version_')

            // 和最新版本不同，刷新页面
            if (version !== clientVersion) {
              this.needUpdate = true
              this.needUpdateDialogVisible = true
            } else {
              this.needUpdate = false
              this.needUpdateDialogVisible = false
            }
          }
        })
    },
    popNotice(title, content) {
      const that = this
      if (Notification.permission === 'granted') {
        const notification = new Notification(title, {
          body: content,
          icon: that.notifyIcon
        })

        notification.onclick = function(e) {
          that.$nextTick(() => {
            setTimeout(() => {
              // 具体操作
            }, 500)
          })
          // 可直接打开通知notification相关联的tab窗
          window.focus()
          notification.close()
        }
      }
    },
    reloadBrower() {
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.update-notification {
  bottom: 16px;
  z-index: 2022;
  .title-contain {
    .title-logo {
      width: 16px;
      height: 16px;
      position: absolute;
    }
    .title {
      margin-left: 28px;
    }
  }
}
.el-notification__content {
    .update-logo {
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      width: 60px;
      height: 60px;
      box-sizing: border-box;
      .update-img {
        width:100%;
        height:100%;
      }
    }
    .update-content {
      line-height: 40px;
      position: relative;
      font-size: 14px;
      margin-left: 70px;
      p {
        line-height: 1.15;
      }
      .tip {
        font-size: 10px;
        color: #f00;
        margin-top: 10px;
        line-height: 20px;
      }
    }
    .operation-btn {
      text-align: right;
    }
  }

</style>
