import store from '../index'
import { getMallCategoryConfig } from '@/api/mall/mallCategory'

const state = {
  mallCategoryConfig: {
    categoryLevel: 1
  }
}

const mutations = {
  SET_CONFIG: (state, mallCategoryConfig) => {
    if (mallCategoryConfig) {
      mallCategoryConfig.categoryLevel = mutations.CONVERT_INT(mallCategoryConfig.categoryLevel, 1)
      state.mallCategoryConfig = Object.assign({}, state.mallCategoryConfig, mallCategoryConfig)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getMallCategoryConfig().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
