<template>
  <el-select
    v-model="newValue"
    placeholder="请选择"
    size="small"
    v-bind="attrs"
    @input="handleChange"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.text"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableSelect',
  mixins: [EleEditableMixin]
}
</script>
