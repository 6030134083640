import { getPlatformInfo } from '@/api/platform'

const state = {
  platformInfo: {}
}

const mutations = {
  SET_PLATFORM_INFO: (state, platformInfo) => {
    const version = 'V1.1.0'
    if (platformInfo) {
      let platformVersion = platformInfo.platformVersion
      if (platformVersion === null || platformVersion === undefined || platformVersion === '') {
        platformVersion = version
      }
      platformInfo.platformVersion = platformVersion
    } else {
      platformInfo = {}
      platformInfo.platformVersion = version
    }
    state.platformInfo = platformInfo
  }
}

const actions = {
  getPlatformInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getPlatformInfo().then(response => {
        const { data } = response
        commit('SET_PLATFORM_INFO', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
