const _import = require('@/router/_import_' + process.env.NODE_ENV)
import Layout from '@/layout'

export function traverseRoutes(menus, root) {
  const routes = menus.map(menu => {
    const route = {}
    route.path = menu.menuPath
    route.targetBlank = menu.targetBlank
    if (menu.menuRedirect) {
      route.redirect = menu.menuRedirect
    }
    route.name = menu.menuName
    if (menu.menuComponent && menu.menuComponent !== 'Layout') {
      try {
        const component = menu.menuComponent
        route.component = _import(component)
        if (menu.menuComponent === 'common/empty/index') {
          route.redirect = 'noRedirect'
        }
      } catch (e) {
        return null
      }
    } else {
      if (!route.redirect || route.redirect === 'noRedirect') {
        if (root) {
          route.component = Layout
          route.redirect = 'noRedirect'
        } else {
          route.component = _import('common/empty/index')
          route.redirect = 'noRedirect'
        }
      }
    }

    if (menu.menuHidden) {
      route.hidden = menu.menuHidden
    }
    route.meta = {
      title: menu.menuTitle,
      noCache: menu.menuNoCache ? menu.menuNoCache : false
    }
    if (menu.menuIcon) {
      route.meta.icon = menu.menuIcon
    }
    if (menu.permission) {
      const permissions = menu.permission.split(';')
      const pers = []
      permissions.forEach(p => {
        if (p) {
          pers.push(p)
        }
      })
      route.meta.permissions = pers
    }

    if (menu.activeMenu) {
      route.meta.activeMenu = menu.activeMenu
    }
    if (menu.children && menu.children.length) {
      route.children = traverseRoutes(menu.children, false)
      route.alwaysShow = true
    }
    return route
  })
  return routes.filter(route => route)
}
