<template>
  <el-date-picker
    v-model="newValue"
    :clearable="true"
    :format="format"
    size="small"
    style="max-width: 200px"
    type="datetime"
    v-bind="attrs"
    @input="handleTimeChange"
  />
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableDatetime',
  mixins: [EleEditableMixin],
  data() {
    return {
      defaultAttrs: {
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  methods: {
    setNewValue() {
      this.setTimeNewValue()
    }
  }
}
</script>
