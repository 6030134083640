import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import { traverseRoutes } from '@/utils/routes' // get routes from menus
import { redirectToLogin } from '@/utils/common'
import getPageTitle from '@/utils/get-page-title'
import i18n from '@/lang'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/error', '/404', '/401'] // no redirect whitelist
const errorPageList = ['/error', '/404', '/401']
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  if (to.path === '/login') {
    if (to.query.code && to.query.state) {
      const params = {
        code: to.query.code,
        state: to.query.state
      }
      await store.dispatch('user/weworkLogin', params).then(() => {
        next('/')
      }).catch(() => {
        next('/login')
      })
    }
  }
  if (errorPageList.includes(to.path)) {
    const key = to.meta.title
    const hasKey = i18n.te(`route.${key}`)
    if (hasKey) {
      const pageName = i18n.t(`route.${key}`)
      document.title = pageName
    } else {
      document.title = key
    }
  } else {
    await getPageTitle(to.meta.title).then((title) => {
      document.title = title
    })
  }

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (store.getters.isScreenLock && to.path !== '/lock') {
      next({
        path: '/lock'
      })
      NProgress.done()
    } else if (to.path === '/login') {
      // if is logged in, redirect to the home page
      // next({ path: '/login' })
      // NProgress.done()
      next()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasPermissions = store.getters.permissions && store.getters.permissions.length > 0
      if (hasPermissions) {
        if (to.path !== '/change-password' && store.getters.userInfo.needChangePassword) {
          next('/change-password')
          NProgress.done()
        } else {
          next()
        }
      } else {
        try {
          // get user info
          await store.dispatch('user/getTenantHost')
          await store.dispatch('user/getFullInfo')
          if (store.getters.loginTenantInfo.tenantEnabled) {
            await store.dispatch('user/getTenantInfo')
          }
          await store.dispatch('config/getAllConfigs')
          await store.dispatch('productConfig/getAllConfigs')
          await store.dispatch('purchaseConfig/getAllConfigs')
          const permissions = store.getters.permissions
          const menus = store.getters.menus
          const routes = traverseRoutes(menus, true)
          routes.push(
            { path: '*', redirect: '/404', hidden: true }
          )

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', { routes, permissions })

          // dynamically add accessible routes
          accessRoutes.forEach(item => {
            router.addRoute(item)
          })
          // router.addRoutes(accessRoutes)
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error({
            message: error || 'Has Error'
          })
          // next(`/login?redirect=${to.path}`)
          // next('/login')

          // 重定向到登录页
          redirectToLogin()
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/login?redirect=${to.path}`)
      // next('/login')
      // 重定向到登录页

      redirectToLogin()
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
