import store from '../index'
import { getCommonMallConfig } from '@/api/mall/mallConfig'

const state = {
  mallRegisterConfig: {
    mandatoryLogin: 2,
    logWay: 2,
    bindingType: 2,
    clickCat: false,
    clickBuy: false,
    buyerShare: false,
    buyerSubmitForm: false,
    buyerSetCoupons: false
  }
}

const mutations = {
  SET_CONFIG: (state, mallRegisterConfig) => {
    if (mallRegisterConfig) {
      mallRegisterConfig.mandatoryLogin = mutations.CONVERT_INT(mallRegisterConfig.mandatoryLogin, 2)
      mallRegisterConfig.logWay = mutations.CONVERT_INT(mallRegisterConfig.logWay, 2)
      mallRegisterConfig.bindingType = mutations.CONVERT_INT(mallRegisterConfig.bindingType, 2)
      mallRegisterConfig.clickCat = mutations.CONVERT_INTS(mallRegisterConfig.clickCat, false)
      mallRegisterConfig.clickBuy = mutations.CONVERT_INTS(mallRegisterConfig.clickBuy, false)
      mallRegisterConfig.buyerShare = mutations.CONVERT_INTS(mallRegisterConfig.buyerShare, false)
      mallRegisterConfig.buyerSubmitForm = mutations.CONVERT_INTS(mallRegisterConfig.buyerSubmitForm, false)
      mallRegisterConfig.buyerSetCoupons = mutations.CONVERT_INTS(mallRegisterConfig.buyerSetCoupons, false)
      state.mallRegisterConfig = Object.assign({}, state.mallRegisterConfig, mallRegisterConfig, false)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value, defaultValue) => {
    if (value) {
      if (value === 'true') {
        return true
      } else {
        return false
      }
    } else {
      return defaultValue
    }
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      const type = 'register'
      getCommonMallConfig(type).then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
