<template>
  <a
    v-if="computedValue"
    :href="computedValue"
    v-bind="attrs"
  >{{ displayValue }}</a>
</template>

<script>
import EleEditableMixin from '../EleEditableMixin'

export default {
  name: 'EleEditableUrl',
  mixins: [EleEditableMixin],
  data() {
    return {
      defaultAttrs: {
        target: '_blank'
      }
    }
  }
}
</script>
