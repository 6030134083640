<template>
  <div>
    <div class="navbar">
      <hamburger
        id="hamburger-container"
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />

      <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

      <div class="right-menu">
        <template v-if="device!=='mobile'">
          <screen-lock class="right-menu-item hover-effect" />
          <screenfull id="screenfull" class="right-menu-item hover-effect" />
          <lang-select class="right-menu-item hover-effect" />
          <div class="right-menu-item hover-effect">
            <i class="el-icon-message-solid" @click="showMessage">
              <el-badge :value="message.unreadTotal" />
            </i>
          </div>
        </template>

        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <img
              :src="(userInfo.userImg ? userInfo.userImg : defaultAvatar())"
              class="user-avatar"
              @error="avatarLoadError($event)"
            >
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link to="/profile/index">
                {{ $t('navbar.profile') }}
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/">
                {{ $t('navbar.dashboard') }}
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item
              disabled
            >{{ $t('common.version') + ' ' + platformInfo.platformVersion }}</el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              <span style="display:block;">{{ $t('navbar.logOut') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import LangSelect from '@/components/LangSelect'
import ScreenLock from '@/components/ScreenLock'
import { getDefaultAvatar } from '@/utils/constant'
import { redirectToLogin } from '@/utils/common'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    ScreenLock,
    LangSelect
  },
  data() {
    return {
      msgVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'userInfo',
      'device',
      'platformInfo',
      'message'
    ])
  },
  mounted() {
    this.$store.dispatch('message/getUnreadTotal')
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      // this.$router.push('/login')
      redirectToLogin()
      // location.reload()
    },
    defaultAvatar() {
      return getDefaultAvatar()
    },
    avatarLoadError(e) {
      // 图片加载出错
      e.target.src = this.defaultAvatar()
    },
    showMessage() {
      this.$store.dispatch('message/setVisible', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 20px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
