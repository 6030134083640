import request from '@/utils/request'

export function getMallLiveConfig() {
  return request({
    url: `/mall/config/live/list`,
    method: 'get'
  })
}

export function saveMallLiveConfig(data) {
  return request({
    url: `/mall/config/live/save`,
    method: 'post',
    data
  })
}
