import i18n from '@/lang'
const fileTypeEnum = ['jpg', 'jpeg', 'gif', 'png', 'zip', 'rar', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'avi', 'mp4', 'html']

/**
 * 空数据填充(一般用于查看表单)
 * @param {*} val 数据
 * @param {*} fillValue 如果数据为空 则需要填充的数据， 默认是'--'
 */
export function emptyContentFill(val, fillValue) {
  if (val === null || val === undefined || val === '') {
    if (fillValue === null || fillValue === undefined) {
      fillValue = '--'
    }
    return fillValue
  }
  return val
}

/**
 * 多语言空数据填充(一般用于查看表单)
 * @param {*} val 多语言对象
 * @param {*} nameKey 多语言Map，默认是'nameMap'
 * @param {*} fillValue 如果数据为空 则需要填充的数据， 默认是'--'
 */
export function emptyContentFillWithMultilingual(val, nameKey, fillValue) {
  if (!val) {
    if (fillValue === null || fillValue === undefined) {
      fillValue = '--'
    }
    return fillValue
  }

  if (!nameKey) {
    nameKey = 'nameMap'
  }

  const multilingualMap = val[nameKey]

  if (!multilingualMap) {
    return emptyContentFill(val, fillValue)
  }

  let multilingualVal = multilingualMap[i18n.locale]

  if (multilingualVal) {
    return multilingualVal
  }

  if (i18n.locale !== 'zh') {
    multilingualVal = multilingualMap['zh']
  }

  if (multilingualVal) {
    return multilingualVal
  }

  for (const key in multilingualMap) {
    if (multilingualMap[key]) {
      return multilingualMap[key]
    }
  }

  if (fillValue === null || fillValue === undefined) {
    fillValue = '--'
  }

  return fillValue
}

/**
 * 枚举空数据填充(一般用于查看表单)
 * @param {*} val 数据,可以是数组
 * @param {*} constantName 枚举名
 * @param {*} fillValue 如果数据为空 则需要填充的数据， 默认是'--'
 * @param {*} arraySeparator 数组分隔符，如果传入的是数组，则使用该分隔符分割数据，， 默认是','
 */
export function emptyContentFillWithEnum(val, constantName, fillValue, arraySeparator) {
  if (fillValue === null || fillValue === undefined) {
    fillValue = '--'
  }
  if (arraySeparator === null || arraySeparator === undefined) {
    arraySeparator = ','
  }

  if (val === null || val === undefined || val === '' || !constantName) {
    return fillValue
  }
  if (Array.isArray(val)) {
    const itemArray = []
    val.forEach(item => {
      const itemLable = this.$enum.getLabelByValue(constantName, item)
      if (itemLable) {
        itemArray.push(i18n.t(itemLable))
      }
    })
    if (itemArray.length > 0) {
      return itemArray.join(arraySeparator)
    }
    return fillValue
  } else {
    const label = this.$enum.getLabelByValue(constantName, val)
    if (label) {
      return i18n.t(label)
    }
    return fillValue
  }
}

export function fileSizeFormat(fileSize) {
  let formatFileSize = fileSize
  if (formatFileSize < 1024) {
    return formatFileSize + 'B'
  }

  formatFileSize = Math.round(formatFileSize / 1024 * 100) / 100
  if (formatFileSize < 1024) {
    return formatFileSize + 'KB'
  }

  formatFileSize = Math.round(formatFileSize / 1024 * 100) / 100
  if (formatFileSize < 1024) {
    return formatFileSize + 'MB'
  }

  formatFileSize = Math.round(formatFileSize / 1024 * 100) / 100
  if (formatFileSize < 1024) {
    return formatFileSize + 'GB'
  }

  return '--'
}

export function getFileTypeIcon(fileType) {
  if (fileTypeEnum.indexOf(fileType) > -1) {
    return fileType + '.png'
  }
  return 'other.png'
}

export function format(formatStr, args) {
  return !!formatStr && formatStr.replace(/\{(\d+)\}/g, function(t, r) {
    return (args && args.length) > r ? args[r] : t
  })
}
// 字符转数值
export function str2Int(str, defaultVal) {
  if (str) {
    return Number.parseInt(str)
  } else {
    return Number.parseInt(defaultVal)
  }
}
// 字符转数值
export function str2Array(str, defaultVal) {
  if (str) {
    return JSON.parse(str)
  } else {
    return defaultVal
  }
}
