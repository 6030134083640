import { getAreasByCountryIdAndLevel, getAllCountries } from '@/api/area'
const state = {
  countries: [],
  areaData: []
}
const mutations = {
}
const actions = {
  // 获取配置信息
  getAllCountries({ commit }) {
    return new Promise((resolve, reject) => {
      if (state.countries && state.countries.length > 0) {
        resolve(state.countries)
      } else {
        getAllCountries().then(response => {
          const { data } = response
          state.countries = data
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  getAreasByCountryIdAndLevel({ commit }, data) {
    const { countryId, level } = data
    return new Promise((resolve, reject) => {
      if (countryId <= 0 || level <= 0) {
        resolve([])
      }
      const data = state.areaData.find(e => e.countryId === countryId && e.level === level)
      if (data) {
        resolve(data.data)
      } else {
        getAreasByCountryIdAndLevel(countryId, level).then(response => {
          const { data } = response
          state.areaData.push({
            countryId: countryId,
            level: level,
            data: data
          })
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
