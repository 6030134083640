import { getProductAssistConfig } from '@/api/pdm/common'
import store from '../index'
import { getProductConfigList } from '@/utils/constant'
const state = {
  productConfig: {
    assistConfig: 0,
    configList: []
  }
}
const mutations = {
  SET_CONFIG: (state, productConfig) => {
    if (productConfig) {
      productConfig.assistConfig = mutations.CONVERT_INT(productConfig.assistConfig, 0)
      productConfig.configList = mutations.INI_CONF_LIST(productConfig.configList)
      state.productConfig = Object.assign({}, state.productConfig, productConfig)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  INI_CONF_LIST(configList) {
    const constantList = getProductConfigList()
    if (!configList) {
      return constantList
    }
    configList.forEach(item => {
      constantList.forEach(item2 => {
        if (item.configKey === item2.configKey) {
          item2.configName = item.configName
          item2.configValue = item.configValue
        }
      })
    })
    return constantList
  },
  SET_CONFIG_ITEM: (state, item) => {
    if (item && item.configKey) {
      state.productConfig[item.configKey] = item.configValue
    }
  }
}
const actions = {
  // 获取配置信息
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getProductAssistConfig().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateConfigItem({ commit }, item) {
    commit('SET_CONFIG_ITEM', item)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
