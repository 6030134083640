import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import i18n from '@/lang'
import { getToken } from '@/utils/auth'
import { format } from '@/utils/data'
import moment from 'moment'
import { redirectToLogin, redirectToError } from '@/utils/common'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (!(res instanceof Blob) && res.code !== 'success') {
      if (store.state.user.pageType === 2) {
        return Promise.reject(new Error((res.code || res.message) || 'Error'))
      }
      let message = i18n.t('exception.networkError')
      if (res.code) {
        if (i18n.te('exception.' + res.code)) {
          message = i18n.t('exception.' + res.code)
        } else {
          message = res.message
        }
      } else if (res.message) {
        message = res.message
      }
      if (res.code === 'multiTerminalLoginError') {
        const loginTime = res.data
        redirectToLogin('multi=1&loginTime=' + moment(loginTime).valueOf())
        return Promise.reject(new Error(message || 'Error'))
      } else {
        // authFail
        if (res.code === 'jwtAuthFail' || res.code === 'jwtAuthOverdue' || res.code === 'tenantExpiredOrWebsiteError') {
          if (store.state.user.pageType !== 1) {
            Message({
              message: message,
              type: 'error',
              duration: 5 * 1000
            })
            // to re-login
            store.dispatch('user/resetToken').then(() => {
              // location.reload()
              // router.push({ path: '/login' })
              redirectToLogin()
            })
          } else {
            if (res.code === 'tenantExpiredOrWebsiteError') {
              Message({
                message: message,
                type: 'error',
                duration: 5 * 1000
              })
            }
          }

          // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          //   confirmButtonText: 'Re-Login',
          //   cancelButtonText: 'Cancel',
          //   type: 'warning'
          // }).then(() => {
          //   store.dispatch('user/resetToken').then(() => {
          //     location.reload()
          //   })
          // })
        } else {
          if (res.format) {
            message = format(message, res.data)
          } else {
            if (res.data) {
              message = res.data + ' ' + message
            }
          }

          Message({
            message: message,
            type: 'error',
            duration: 5 * 1000
          })
        }
        return Promise.reject(new Error((res.code || res.message) || 'Error'))
      }
    } else {
      return res
    }
  },
  error => {
    if (error.response && error.response.status === 500) {
      // router.push({ path: '/error' })
      redirectToError()
    } else {
      // console.log('err' + error) // for debug
      if (error.message) {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
    }
    return Promise.reject(error)
  }
)

export default service
