import store from '../index'
import { getMemberConfig } from '@/api/member/memberConfig'

const state = {
  memberConfig: {
    customerBindExpireRemindDate: 6
  }
}

const mutations = {
  SET_CONFIG: (state, memberConfig) => {
    if (memberConfig) {
      memberConfig.customerBindExpireRemindDate = mutations.CONVERT_INT(memberConfig.customerBindExpireRemindDate, 1)
      state.memberConfig = Object.assign({}, state.memberConfig, memberConfig)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value) => {
    return value
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      getMemberConfig().then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
