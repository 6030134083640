import request from '@/utils/request'

export function getMallConfig(type) {
  return request({
    url: `/mall/config/list/${type}`,
    method: 'get'
  })
}

export function saveMallConfig(type, data) {
  return request({
    url: `/mall/config/${type}`,
    method: 'post',
    data
  })
}

// 单独保存所属公司 （保存单个字段用）
export function saveMallSingleConfig(data) {
  return request({
    url: `/mall/config/save-single`,
    method: 'post',
    data
  })
}

export function getMallAccountListConfig() {
  return request({
    url: `/mall/config/account-list`,
    method: 'get'
  })
}
// 查询所有的微信订阅消息模板
export function getAllPrivateTemplate() {
  return request({
    url: `/mall/config/msg/all`,
    method: 'get'
  })
}
// 查询模板配置
export function getMallMsgTemplateConfig() {
  return request({
    url: `/mall/config/msg`,
    method: 'get'
  })
}
// 保存模板配置
export function saveMallMsgTemplateConfig(data) {
  return request({
    url: `/mall/config/msg`,
    method: 'post',
    data
  })
}

// 查询商品参数设置
export function getMallGoodsParamConfigList() {
  return request({
    url: `/mall/config/goods/param`,
    method: 'get'
  })
}

// 保存商品参数设置
export function saveMallGoodsParamConfig(data) {
  return request({
    url: `/mall/config/goods/param/save`,
    method: 'post',
    data
  })
}
// 删除商品参数
export function deleteMallGoodsParam(paramType, paramName) {
  return request({
    url: `/mall/config/goods/param/${paramType}/${paramName}`,
    method: 'delete'
  })
}
