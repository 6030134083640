import request from '@/utils/request'

export function notificationPoll() {
  return request({
    url: '/notification/poll',
    method: 'get'
  })
}

export function getOrgUserTree(emptyOrgContain) {
  return request({
    url: `/sys/common/orgusertree/${emptyOrgContain}`,
    method: 'get'
  })
}

export function generateRsaKeyPair() {
  return request({
    url: `/sys/common/rsa-generate`,
    method: 'get'
  })
}

export function getLastUseCustomers(dataType, data) {
  return request({
    url: `/common/select/last-use/customer/${dataType}`,
    method: 'post',
    data
  })
}

export function getSelectCustomersForPage(data) {
  return request({
    url: '/common/select/customer',
    method: 'post',
    data
  })
}

export function getSelectCustomersForPhonePage(data) {
  return request({
    url: '/common/select/customer-phone',
    method: 'post',
    data
  })
}

export function getSelectCustomersForPhoneList(data) {
  return request({
    url: '/common/select/customer-phone-list',
    method: 'post',
    data
  })
}

export function getSelectContactsForPage(data) {
  return request({
    url: '/common/select/contact',
    method: 'post',
    data
  })
}

export function getproductDatasPageByProperty(data) {
  return request({
    url: '/common/select/productSelect',
    method: 'post',
    data
  })
}

export function getSelectSuppliersForPage(data) {
  return request({
    url: '/common/select/supplier',
    method: 'post',
    data
  })
}

export function getLastUseSupplier(dataType, data) {
  return request({
    url: `/common/select/last-use/supplier/${dataType}`,
    method: 'post',
    data
  })
}

// 用户组织机构树带数据权限
export function getOrgUserTreeWithPermission(emptyOrgContain) {
  return request({
    url: `/sys/common/orgusertree-permission/${emptyOrgContain}`,
    method: 'get'
  })
}

// 客户拜访计划
export function getSelectVisitForPage(data) {
  return request({
    url: '/common/select/visit',
    method: 'post',
    data
  })
}

// get 部门组织树
export function getOrgTree() {
  return request({
    url: '/sys/common/orgtree',
    method: 'get'
  })
}

export function getTenantId() {
  return request({
    url: '/caracal/tenant/common/id',
    method: 'get'
  })
}
// 校验模块权限 data [ 模块code]
export function isModulePermission(data) {
  return request({
    url: '/caracal/tenant/common/module-permission',
    method: 'post',
    data
  })
}
// 解析地址
export function parseAddress(data) {
  return request({
    url: '/caracal/tenant/common/address-parse',
    method: 'post',
    data
  })
}
// 批量解析地址
export function parseAddressBatch(data) {
  return request({
    url: '/caracal/tenant/common/address-parse/batch',
    method: 'post',
    data
  })
}
