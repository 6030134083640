import request from '@/utils/request'
export function getSalesDeliveryList(data) {
  return request({
    url: `/sales/common/delivery/list`,
    method: 'post',
    data
  })
}

// 统计打印次数
export function calPrintCount(id) {
  return request({
    url: `/sales/common/delivery/print-count/${id}`,
    method: 'put'
  })
}

export function getSalesDeliveryById(id) {
  return request({
    url: `/sales/common/delivery/${id}`,
    method: 'get'
  })
}
// 发货单选择器
export function getSelectSalesDeliveryForPage(data) {
  return request({
    url: `/sales/common/delivery/select`,
    method: 'post',
    data
  })
}
// 发货单选择器，不带数据权限
export function getSelectSalesDeliveryNotPermissionForPage(data) {
  return request({
    url: `/sales/common/delivery/select/not-permission`,
    method: 'post',
    data
  })
}
// 发货单选择器-会将对应产品带出来
export function getSelectSalesDeliveryWithProduct(data) {
  return request({
    url: `/sales/common/delivery-with-product/select`,
    method: 'post',
    data
  })
}
// 发货单选择器-会将对应产品带出来(不带权限过滤)
export function getSelectSalesDeliveryWithProductNotPermission(data) {
  return request({
    url: `/sales/common/delivery-with-product/not-permission/select`,
    method: 'post',
    data
  })
}
// 订单选择器（带产品）
export function getSalesOrderSelectWithProduct(data) {
  return request({
    url: `/sales/common/order-with-product/select`,
    method: 'post',
    data
  })
}
// 订单选择器（带产品）
export function getSalesOrderSelectWithProductNotPermission(data) {
  return request({
    url: `/sales/common/order-with-product/not-permission/select`,
    method: 'post',
    data
  })
}
// 发货单产品选择器
export function getSelectSalesDeliveryProductForPage(data) {
  return request({
    url: `/sales/common/delivery-product/select`,
    method: 'post',
    data
  })
}
// 退货单产品选择器
export function getSalesReturnProductSelect(data) {
  return request({
    url: `/sales/common/return-product/select`,
    method: 'post',
    data
  })
}
// 销售订单产品选择器
export function getSelectSalesOrderProductForPage(data) {
  return request({
    url: `/sales/common/order-product/select`,
    method: 'post',
    data
  })
}
// 发货单查询退货单详情
export function getSalesReturnBySalesDeliveryId(id) {
  return request({
    url: `/sales/common/delivery-return/${id}`,
    method: 'get'
  })
}
export function getSalesReturnProductByReturnOrderId(id) {
  return request({
    url: `/sales/common/return-product/${id}`,
    method: 'get'
  })
}
// 销售退货单分页（库存使用）
export function getSalesReturnOrderList(data) {
  return request({
    url: `/sales/common/return/list`,
    method: 'post',
    data
  })
}
// 销售退货单（库存使用）
export function getSalesReturnOrderById(id) {
  return request({
    url: `/sales/common/return/${id}`,
    method: 'get'
  })
}
// 销售订单选择器
export function getSelectSalesOrderForPage(data) {
  return request({
    url: `/sales/common/order/select`,
    method: 'post',
    data
  })
}
// 销售订单选择器(不带数据权限)
export function getSelectSalesOrderForPageNotPermission(data) {
  return request({
    url: `/sales/common/order/not-permission/select`,
    method: 'post',
    data
  })
}

// 销售订单查询发货单产品明细
export function getSalesOrderDeliveryProductInfos(id, data) {
  return request({
    url: `/sales/common/order-delivery/product/${id}`,
    method: 'post',
    data
  })
}
// 销售订单查询发货单产品明细
export function getSalesDeliveryProductGroupByProductId(id, data) {
  return request({
    url: `/sales/common/order-delivery/product/group-by/${id}`,
    method: 'post',
    data
  })
}

// 销售订单查询发货单明细
export function getSalesOrderDeliveryInfos(id) {
  return request({
    url: `/sales/common/order-delivery/${id}`,
    method: 'get'
  })
}

// 销售订单查询出库信息
export function getSalesOrderInventoryInfos(id) {
  return request({
    url: `/sales/common/order-inventory/${id}`,
    method: 'get'
  })
}
// 销售订单查询发货单信息
export function getSalesOrderReturnInfos(id) {
  return request({
    url: `/sales/common/order-return/${id}`,
    method: 'get'
  })
}
// 销售订单是否包含应收款
export function salesOrderHasFinanceReceivable(id) {
  return request({
    url: `/sales/common/order/has-receivable/${id}`,
    method: 'get'
  })
}
// 销售发货单是否包含应收款
export function salesDeliveryHasFinanceReceivable(id) {
  return request({
    url: `/sales/common/delivery/has-receivable/${id}`,
    method: 'get'
  })
}
// 销售订单客户地址填充
export function getOrderCustomerAddress(id) {
  return request({
    url: `/sales/common/order/customer-address/${id}`,
    method: 'get'
  })
}
// 销售订单客户地址填充
export function getDeliveryCustomerAddress(id) {
  return request({
    url: `/sales/common/delivery/customer-address/${id}`,
    method: 'get'
  })
}
// 销售退货选择器
export function getSalesReturnSelectForPage(data) {
  return request({
    url: `/sales/common/return/select`,
    method: 'post',
    data
  })
}
// 产品选择器+销售价格
export function salesProductPriceSelect(data) {
  return request({
    url: `/sales/common/product-price/select`,
    method: 'post',
    data
  })
}
// 选择器基础数据
export function getSelectBasicData() {
  return request({
    url: `/sales/common/product-price/select/basicdata`,
    method: 'get'
  })
}
// 产品选择器+销售价格
export function getCustomerSalesOrderProductSelect(id, data) {
  return request({
    url: `/sales/common/order-product-select/${id}`,
    method: 'post',
    data
  })
}

// 发货单已发货详情（已发货数量和订单数量）
export function getDeliveryRelatedInfoByDeliveryId(id) {
  return request({
    url: `/sales/common/delivered-quantity-delivery/${id}`,
    method: 'get'
  })
}
// 订单已发货详情（已发货数量和订单数量）
export function getDeliveryRelatedInfoByOrderId(id) {
  return request({
    url: `/sales/common/delivered-quantity-order/${id}`,
    method: 'get'
  })
}
// 销售报价单选择器
export function getSalesQuotationSelectForPage(data) {
  return request({
    url: `/sales/common/quotation/select`,
    method: 'post',
    data
  })
}
// 销售费用择器
export function getSalesExpenseSelectForPage(data) {
  return request({
    url: `/sales/common/expense/select`,
    method: 'post',
    data
  })
}

export function getSalesConfigList() {
  return request({
    url: '/sales/common/config/list',
    method: 'get'
  })
}

// 返回 销售订单ids 符合 出入库产品子表
export function getInventoryProductInfosForSalesOrder(data) {
  return request({
    url: `/sales/common/order/inventory-products`,
    method: `post`,
    data
  })
}

// 返回 销售发货单ids 符合 出入库产品子表
export function getInventoryProductInfosForSalesDelivery(data) {
  return request({
    url: `/sales/common/delivery/inventory-products`,
    method: `post`,
    data
  })
}

// 返回 销售退回单ids 符合 出入库产品子表
export function getInventoryProductInfosForSalesOrderReturn(data) {
  return request({
    url: `/sales/common/return/inventory-products`,
    method: `post`,
    data
  })
}
// 批量查询订单已开票金额 data-list<orderId>
export function getSalesOrderInvoicedAmount(data) {
  return request({
    url: `/sales/common/order-invoiced`,
    method: `post`,
    data
  })
}
// 批量查询发货单已开票金额 data-list<deliveryId>
export function getSalesDeliveryInvoicedAmount(data) {
  return request({
    url: `/sales/common/delivery-invoiced`,
    method: `post`,
    data
  })
}

// 用户数据权限范围内ids
export function getCurrentUserPermission() {
  return request({
    url: `/sales/common/user-permission`,
    method: `get`
  })
}

// 获取销售基础数据
export function getBasicData() {
  return request({
    url: `/sales/common/basicdata`,
    method: 'get'
  })
}
// 获取已启用销售价格类型
export function getEnabledPriceType() {
  return request({
    url: `/sales/common/price-type`,
    method: 'get'
  })
}
// 获取已启用销售价格类型
export function getCustomerPriceType(customerId) {
  return request({
    url: `/sales/common/customer/price-type/${customerId}`,
    method: 'get'
  })
}
// 获取已启用销售价格类型
export function getCustomerMultiInfo(customerId, data) {
  return request({
    url: `/sales/common/customer/multi-info/${customerId}`,
    method: 'post',
    data
  })
}
// 获取组织机构用户 containChildOrg 是否包含下级
export function getOrgUserIds(containChildOrg, data) {
  return request({
    url: `/sales/common/org-user/${containChildOrg}`,
    method: 'post',
    data
  })
}
// 查询产品品牌
export function getProductBrand(data) {
  return request({
    url: `/sales/common/product-brand`,
    method: 'post',
    data
  })
}

// 销售-锁定库存
export function getSalesInventoryLockInfoById(id) {
  return request({
    url: `/sales/common/inventory-lock/${id}`,
    method: 'get'
  })
}

// 产品-关联业务-销售订单
export function getSalesOrderInfosForPageByProductId(id, data) {
  return request({
    url: `/sales/common/order-page/${id}`,
    method: 'post',
    data
  })
}
// 产品-关联业务-销售发货订单
export function getSalesDeliveryInfosForPageByProductId(id, data) {
  return request({
    url: `/sales/common/delivery-page/${id}`,
    method: 'post',
    data
  })
}

// 产品-关联业务-销售锁定库存订单
export function getSalesInventoryLockInfosForPageByProductId(id, data) {
  return request({
    url: `/sales/common/inventory-lock-page/${id}`,
    method: 'post',
    data
  })
}

// 根据条码和币种获取产品信息
export function getSalesProductByBarcode(barcode, currencyId) {
  return request({
    url: `/sales/common/product-price/barcode/${barcode}/${currencyId}`,
    method: 'get'
  })
}
// 根据产品编码、条码、名称和币种获取产品信息
export function getSalesProductForQuickQuery(queryKeywords, currencyId) {
  return request({
    url: `/sales/common/product-price/quick-query/${currencyId}/${queryKeywords}`,
    method: 'get'
  })
}

// 委外需求转销售发货
export function outRequire2SalesDelivery(outRequireId) {
  return request({
    url: `/sales/common/out-require/sales-delivery/${outRequireId}`,
    method: 'get'
  })
}

// 查询销售订单委外订单列表（退货使用）
export function getSalesOrderOutOrderList(salesOrderId) {
  return request({
    url: `/sales/common/out-order/${salesOrderId}`,
    method: 'get'
  })
}

// 委外订单产品+销售订单价格（退货使用）
export function getOutOrderProductWithSalesOrderPriceForPage(outOrderId, data) {
  return request({
    url: `/sales/common/out-order/product/${outOrderId}`,
    method: 'post',
    data
  })
}

// 销售查询产品可用库存（data: {产品ids}）
export function getSalesEnabledStock(data) {
  return request({
    url: `/sales/common/enable-stock`,
    method: 'post',
    data
  })
}

// 销售查询产品可用库存（data: {productIds, warehouseId}）
export function getSalesEnabledStockBatch(data) {
  return request({
    url: `/sales/common/enable-stock-batch`,
    method: 'post',
    data
  })
}

// 客户授信额度分页查询
export function getCustomerCreditLimitForPage(data) {
  return request({
    url: `/sales/common/customer/credit-limit/page`,
    method: 'post',
    data
  })
}

// 价格类型查询产品价格
export function getProductPriceByPriceType(priceTypeId, data) {
  return request({
    url: `/sales/common/product-price/${priceTypeId}`,
    method: 'post',
    data
  })
}

// 订单的发货单列表
export function getSalesDeliveryForListByOrderId(orderId) {
  return request({
    url: `/sales/common/order-delivery/${orderId}`,
    method: 'get'
  })
}
