import store from '../index'
import { getCommonMallConfig } from '@/api/mall/mallConfig'

const state = {
  mallBaseConfig: {
    mallName: '',
    mallLogo: '',
    mallBrief: '',
    sellOutIcon: '',
    loadIcon: '',
    mallPicturePreview: 1,
    allowSwitching: 1
  }
}

const mutations = {
  SET_CONFIG: (state, mallBaseConfig) => {
    if (mallBaseConfig) {
      mallBaseConfig.mallName = mutations.CONVERT_INTS(mallBaseConfig.mallName)
      mallBaseConfig.mallBrief = mutations.CONVERT_INTS(mallBaseConfig.mallBrief)
      mallBaseConfig.allowSwitching = mutations.CONVERT_INT(mallBaseConfig.allowSwitching, 1)
      state.mallBaseConfig = Object.assign({}, state.mallBaseConfig, mallBaseConfig, false)
    }
  },
  CONVERT_INT: (value, defaultValue) => {
    if (value) {
      return Number.parseInt(value)
    } else {
      return defaultValue
    }
  },
  CONVERT_INTS: (value) => {
    return value
  }
}

const actions = {
  getAllConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET_CONFIG', {})
        resolve()
        return
      }
      const type = 'base'
      getCommonMallConfig(type).then(response => {
        const { data } = response
        commit('SET_CONFIG', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
