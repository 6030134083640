import request from '@/utils/request'

export function getTenantHost() {
  return request({
    url: '/auth/host',
    method: 'get'
  })
}

export function getTenantInfo() {
  return request({
    url: '/auth/tenant',
    method: 'get'
  })
}

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export function getAllUserInfos() {
  return request({
    url: '/user/list',
    method: 'get'
  })
}

export function addUser(data) {
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

export function updateUser(id, data) {
  return request({
    url: `/user/${id}`,
    method: 'put',
    data
  })
}

export function deleteUser(id) {
  return request({
    url: `/user/${id}`,
    method: 'delete'
  })
}

export function resetPassword(id) {
  return request({
    url: `/user/resetpassword/${id}`,
    method: 'post'
  })
}

export function getSetRoleData(id) {
  return request({
    url: `/user/role/${id}`,
    method: 'get'
  })
}

export function setRole(id, data) {
  return request({
    url: `/user/role/${id}`,
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}
// 用户选择器
export function getUserSimpleInfos(data) {
  return request({
    url: '/user/info-simple',
    method: 'post',
    data
  })
}

// 同步到企业微信(覆盖方式)
export function syncToWework() {
  return request({
    url: '/user/sync-wework',
    method: 'post'
  })
}
// 同步企业微信(同步方式)
export function syncFromWework() {
  return request({
    url: '/user/sync-from-wework',
    method: 'post'
  })
}
// 取消同步企业微信
export function cancelUserRelevance(id) {
  return request({
    url: `/user/cancel-user-relevance/${id}`,
    method: 'post'
  })
}

