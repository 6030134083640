import store from '../index'

const state = {
  todoListParams: {
    businessId: ''
  }
}

const mutations = {
  SET: (state, businessId) => {
    state.todoListParams.businessId = businessId
  }
}

const actions = {
  set({ commit }, businessId) {
    return new Promise((resolve, reject) => {
      if (!store.getters.token) {
        commit('SET', '')
        resolve()
        return
      }
      commit('SET', businessId)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
